import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { pure, compose } from 'react-recompose';
import { withFormik, FieldArray } from 'formik';
import { createStructuredSelector } from 'reselect';
// components
import { FormFooter2 } from '../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../forms';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import {
  DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
} from '../../../../helpers/options';
// icons
import * as I from '../../../../svgs';
// ui
import { Box, Flex, RelativeBox } from '../../../../ui';
// feature fleet-profile
import { selectDriverGeneralDetails } from '../../selectors';
import { updateDriverGeneralDetailsRequest } from '../../actions';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const whiteGreyColor = G.getTheme('colors.whiteGrey');

const inputWrapperStyles = { width: 120 };
const inputWrapperStyles2 = { width: 100 };
const rateInputWrapperStyles = { mb: 25, width: 120 };

const emptyRow = () => ({
  id: G.genShortId(),
  [GC.FIELD_RATE]: null,
  [GC.FIELD_VALUE_TO]: null,
  [GC.FIELD_VALUE_FROM]: null,
  [GC.FIELD_CHARGE_NAME]: null,
});

const defaultValues = {
  [GC.FIELD_RATE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CHARGE_NAME]: null,
  [GC.FIELD_WORKING_DAYS]: null,
  [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_TIME,
  [GC.FIELD_RATE_UNIT]: GC.CHARGE_RATE_UNIT_TYPE_HOUR,
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: R.of(Array, emptyRow()),
};

const defaultValues2 = {
  [GC.FIELD_RATE]: null,
  [GC.FIELD_CURRENCY]: null,
  [GC.FIELD_CHARGE_NAME]: null,
  [GC.FIELD_WORKING_DAYS]: null,
  [GC.FIELD_RATE_UNIT]: GC.UOM_MILE,
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: null,
  [GC.FIELD_RATE_TYPE]: GC.CHARGE_RATE_TYPE_DISTANCE,
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CURRENCY]: G.yupStringRequired,
  [GC.FIELD_RATE_UNIT]: G.yupStringRequired,
  [GC.FIELD_RATE_TYPE]: G.yupStringRequired,
  [GC.FIELD_WORKING_DAYS]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_TIME),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_RATE]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_CHARGE_NAME]: Yup.string().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE),
      then: (schema: Object) => schema.required(G.getRequiredLocaleTxt()),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
  [GC.FIELD_PAYROLL_VARIABLE_VALUES]: Yup.array().when(
    GC.FIELD_RATE_TYPE,
    {
      is: (value: any) => R.equals(value, GC.CHARGE_RATE_TYPE_TIME),
      then: (schema: Object) => schema.of(Yup.object().shape({
        [GC.FIELD_RATE]: G.yupStringRequired,
        [GC.FIELD_VALUE_FROM]: G.yupStringRequired,
        [GC.FIELD_VALUE_TO]: G.yupStringNotRequired,
        [GC.FIELD_CHARGE_NAME]: G.yupStringRequired,
      })),
      otherwise: (schema: Object) => schema.notRequired(),
    },
  ),
});

const customChangeHandler = (event: Object, field: Object, props: Object) => {
  const { values, setValues } = props;

  const { currency, driverGuid } = values;

  const value = G.getEventTargetValue(event);

  if (R.equals(value, GC.CHARGE_RATE_TYPE_DISTANCE)) {
    return setValues({
      ...defaultValues2,
      driverGuid,
      [GC.FIELD_RATE_TYPE]: value,
      [GC.FIELD_CURRENCY]: currency,
    });
  }

  return setValues({
    ...defaultValues,
    driverGuid,
    [GC.FIELD_RATE_TYPE]: value,
    [GC.FIELD_CURRENCY]: currency,
  });
};

const getFieldSettings1 = ({ version }: Object) => [
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    customChangeHandler,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_RATE_TYPE,
    disabled: R.not(R.isNil(version)),
    options: DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:rate-unit'],
    fieldName: GC.FIELD_RATE_UNIT,
    options: R.of(Array, { label: 'Per Hour', value: GC.CHARGE_RATE_UNIT_TYPE_HOUR }),
  },
  {
    type: 'select',
    isRequired: true,
    label: ['titles:currency'],
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
    inputWrapperStyles: { width: 210 },
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:working-days'],
    fieldName: GC.FIELD_WORKING_DAYS,
  },
];

const getFieldSettings2 = ({ version }: Object) => [
  {
    type: 'select',
    isRequired: true,
    customChangeHandler,
    shouldCustomChange: true,
    label: ['titles:rate-type'],
    fieldName: GC.FIELD_RATE_TYPE,
    disabled: R.not(R.isNil(version)),
    inputWrapperStyles: inputWrapperStyles2,
    options: DEFAULT_PAYROLL_COMPENSATION_RATE_TYPE_OPTIONS,
  },
  {
    type: 'select',
    isRequired: true,
    label: ['titles:rate-unit'],
    fieldName: GC.FIELD_RATE_UNIT,
    inputWrapperStyles: inputWrapperStyles2,
    options: DEFAULT_DISTANCE_RATE_UNIT_OPTIONS,
  },
  {
    type: 'select',
    isRequired: true,
    label: ['titles:currency'],
    options: GC.CURRENCY_OPTIONS,
    fieldName: GC.FIELD_CURRENCY,
    inputWrapperStyles: { width: 150 },
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:charge-name'],
    fieldName: GC.FIELD_CHARGE_NAME,
    inputWrapperStyles: inputWrapperStyles2,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:rate'],
    fieldName: GC.FIELD_RATE,
    inputWrapperStyles: inputWrapperStyles2,
  },
];

const rateFieldSettings = [
  {
    type: 'text',
    isRequired: true,
    label: ['titles:charge-name'],
    fieldName: GC.FIELD_CHARGE_NAME,
    inputWrapperStyles: { mb: 25, width: 200 },
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:rate'],
    fieldName: GC.FIELD_RATE,
    inputWrapperStyles: rateInputWrapperStyles,
  },
  {
    type: 'text',
    isRequired: true,
    label: ['titles:from'],
    fieldName: GC.FIELD_VALUE_FROM,
    inputWrapperStyles: rateInputWrapperStyles,
  },
  {
    type: 'text',
    label: ['titles:to'],
    fieldName: GC.FIELD_VALUE_TO,
    inputWrapperStyles: rateInputWrapperStyles,
  },
];

const VariableValues = (props: Object) => {
  const { form, push, remove } = props;

  const variableValues = R.pathOr([], ['values', GC.FIELD_PAYROLL_VARIABLE_VALUES], form);

  const handleRemove = (index: number) => {
    if (R.equals(R.length(variableValues), 1)) return;

    remove(index);
  };

  return (
    <RelativeBox overflowY='auto' maxHeight={400}>
      <Flex
        pl={20}
        my={25}
        height={30}
        width='100%'
        fontWeight='bold'
        bg={whiteGreyColor}
        color={darkBlueColor}
      >
        {G.getWindowLocale('titles:add-rate', 'Add Rate')}
        <Box ml={10} onClick={() => push(emptyRow())}>
          {I.plusRound(darkBlueColor)}
        </Box>
      </Flex>
      {
        variableValues.map((_: any, index: number) => (
          <Flex mx={15} key={index}>
            <Box
              mr={10}
              mb={25}
              cursor='pointer'
              onClick={() => handleRemove(index)}
            >
              {I.trash(darkBlueColor)}
            </Box>
            <Fieldset2
              {...form}
              itemIndex={index}
              fieldsetType='array'
              fields={rateFieldSettings}
              arrayName={GC.FIELD_PAYROLL_VARIABLE_VALUES}
              fieldsWrapperStyles={{ width: 587, justifyContent: 'space-between' }}
            />
          </Flex>
        ))
      }
    </RelativeBox>
  );
};

const PayrollCompensationForm = (props: Object) => {
  const { values, isSubmitting, handleSubmit } = props;

  const { rateType } = values;

  const fields = G.ifElse(
    R.equals(rateType, GC.CHARGE_RATE_TYPE_TIME),
    getFieldSettings1,
    getFieldSettings2,
  )(values);

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={fields}
        fieldsWrapperStyles={{ mx: 15, pt: 15, width: 610, justifyContent: 'space-between' }}
      />
      {
        R.equals(rateType, GC.CHARGE_RATE_TYPE_TIME) &&
        <FieldArray
          name={GC.FIELD_PAYROLL_VARIABLE_VALUES}
          render={(arrayHelpers: Object) => <VariableValues {...arrayHelpers} />}
        />
      }
      <FormFooter2 boxStyles={{ px: 15, mt: 15 }} submitDisabled={isSubmitting} />
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  generalDetails: selectDriverGeneralDetails,
});

const enhance = compose(
  connect(mapStateToProps, { updateDriverGeneralDetailsRequest }),
  withFormik({
    validationSchema,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultValues,
      initialValues,
    ),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { version } = values;

      const { submitAction, generalDetails, updateDriverGeneralDetailsRequest } = props;

      const successCallback = () => {
        setSubmitting(false);

        if (R.isNil(version)) {
          updateDriverGeneralDetailsRequest({ values: R.assoc(GC.FIELD_DRIVER_PAID_BY_TRIP, false, generalDetails) });
        }
      };

      const failCallback = () => setSubmitting(false);

      submitAction(values, { failCallback, successCallback });
    },
  }),
  pure,
);

export default enhance(PayrollCompensationForm);

import React from 'react';
import * as R from 'ramda';
// components
import { InfoPair } from '../../../components/info-pair';
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetFileAndOpenInWindow } from '../../../helpers/api-async';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// ui
import { Box, Span } from '../../../ui';
//////////////////////////////////////////////////

const orangeColor = G.getTheme('colors.orange');
const blueColor = G.getTheme('colors.light.blue');
const darkRedColor = G.getTheme('colors.darkRed');
const redColor = G.getTheme('colors.light.mainRed');
const yellowColor = G.getTheme('colors.light.yellow');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const priorityColorsMap = {
  [GC.PRIORITY_TYPE_LOW]: yellowColor,
  [GC.PRIORITY_TYPE_HIGH]: darkRedColor,
  [GC.PRIORITY_TYPE_MEDIUM]: orangeColor,
};

const columnSettings = {
  [GC.FIELD_NAME]: {
    width: 250,
    name: 'titles:name',
  },
  failed: {
    width: 80,
    name: 'titles:passed',
    type: 'booleanReverse',
  },
  [GC.FIELD_TEMPLATE_DESCRIPTION]: {
    width: 450,
    name: 'titles:template-description',
  },
  [GC.FIELD_COMMENTS]: {
    width: 200,
    name: 'titles:comment',
  },
  [GC.FIELD_DOCUMENTS]: {
    width: 200,
    name: 'titles:documents',
    customComponent: ({ data }: Object) => {
      const documents = R.path([GC.FIELD_DOCUMENTS], data);

      if (G.isNilOrEmpty(documents)) return null;

      const PopperContent = () => (
        <Box p={15}>
          {
            documents.map(({ guid, fileUri, documentFilename }: Object, i: number) => (
              <Box
                key={i}
                py='4px'
                cursor='pointer'
                color={blueColor}
                wordBreak='break-word'
                onClick={() => asyncGetFileAndOpenInWindow(endpointsMap.getFleetInspectionComponentDocument(guid))}
              >
                {documentFilename}
              </Box>
            ))
          }
        </Box>
      );

      const title = G.getWindowLocale('titles:see-documents', 'See Documents');

      return (
        <PopperComponent type='click' content={PopperContent}>
          <TextComponent
            title={title}
            maxWidth={184}
            display='block'
            color={blueColor}
            withEllipsis={true}
          >
            {title}
          </TextComponent>
        </PopperComponent>
      );
    },
  },
  issue: {
    width: 150,
    name: 'titles:issue',
    customComponent: ({ data }: Object) => {
      const { issue } = data;

      if (R.isNil(issue)) return null;

      const {
        status,
        summary,
        issueId,
        priority,
        location,
        description,
      } = issue;

      const PopperContent = () => (
        <Box p='8px'>
          <Box mb='6px' fontSize={13} fontWeight='bold' color={greyMatterhornColor}>
            {issueId}
          </Box>
          <InfoPair
            text={summary}
            label={G.getWindowLocale('titles:summary', 'Summary')}
          />
          <InfoPair
            text={G.getEnumLocale(status)}
            label={G.getWindowLocale('titles:status', 'Status')}
          />
          <InfoPair
            text={G.getEnumLocale(priority)}
            label={G.getWindowLocale('titles:priority', 'Priority')}
          />
          <InfoPair
            text={G.renderSmallAddressString(location)}
            label={G.getWindowLocale('titles:location', 'Location')}
          />
          <InfoPair
            text={description}
            label={G.getWindowLocale('titles:description', 'Description')}
          />
        </Box>
      );

      return (
        <PopperComponent type='click' content={PopperContent}>
          <TextComponent
            maxWidth={134}
            display='block'
            withEllipsis={true}
            title={summary}
            color={priorityColorsMap[priority]}
          >
            {issue.summary}
          </TextComponent>
        </PopperComponent>
      );
    },
  },
  options: {
    width: 400,
    name: 'titles:options',
    customComponent: ({ data }: Object) => {
      const options = R.compose(
        R.filter(R.prop('selected')),
        R.pathOr([], ['options']),
      )(data);

      if (G.isNilOrEmpty(options)) return null;

      const length = R.length(options);

      return (
        <Box>
          {
            options.map(({ label, failItemIfSelected }: Object, i: number) => (
              <Span key={i} mr='6px' color={failItemIfSelected ? redColor : greyMatterhornColor}>
                {label}{G.notEquals(R.inc(i), length) && ','}
              </Span>
            ))
          }
        </Box>
      );
    },
  },
};

const report = {
  fields: [
    { sequence: 1, name: GC.FIELD_NAME },
    { sequence: 2, name: 'failed' },
    { sequence: 3, name: GC.FIELD_COMMENTS },
    { sequence: 4, name: GC.FIELD_DOCUMENTS },
    { sequence: 5, name: 'issue' },
    { sequence: 6, name: 'options', p: '3px 8px' },
    { sequence: 7, name: GC.FIELD_TEMPLATE_DESCRIPTION },
  ],
};

const tableSettings = {
  cellFontSize: 12,
  titleFontSize: 12,
  titleRowHeight: 30,
  withTitleRow: false,
  checkBoxCellWidth: 0,
  allowSelectItems: false,
  minHeight: 'fit-content',
};


export {
  report,
  tableSettings,
  columnSettings,
};

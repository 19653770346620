import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'react-recompose';
// common
import { sendTelToExternalSystemRequest } from '../../../common/actions';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// dispatch-board-new
import { sendTelsToExternalSystemRequest } from '../load/actions';
//////////////////////////////////////////////////

const modalProps = {
  p: 15,
  options: { width: 330 },
};

const fieldLabel = G.getWindowLocale('titles:integration-type', 'Integration Type');

const externalSystems = [
  // TODO: uncomment when be will be done here and handleSendTelsToExternalSystem
  // {
  //   label: 'MacroPoint',
  //   value: GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT,
  // },
  {
    label: 'Hub Tran',
    value: GC.DOCUMENT_TYPE_MAPPING_TYPE_HUB_TRAN,
  },
  {
    label: 'Trucker Tools',
    value: GC.DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS,
  },
  {
    label: 'FourKites',
    value: GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES,
  },
  {
    label: 'VeraCore',
    value: GC.TERMINAL_INTEGRATION_TYPE_VERACORE,
  },
  {
    label: 'Samsara',
    value: GC.GPS_INTEGRATION_TYPE_SAMSARA,
  },
];

const externalSystemsForMassAction = [GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES];

export const withSendTelsToExternalSystem = compose(
  connect(null, {
    openModal,
    closeModal,
    sendTelToExternalSystemRequest,
    sendTelsToExternalSystemRequest,
  }),
  withHandlers({
    handleSendTelToExternalSystem: (props: Object) => (data: object) => {
      const { openModal, sendTelToExternalSystemRequest } = props;

      const { telGuid, fromPage } = data;

      const component = (
        <SelectDropdownForm
          optionRequired={true}
          fieldLabel={fieldLabel}
          options={externalSystems}
          cancelAction={closeModal}
          submitAction={(integrationType: string) =>
            sendTelToExternalSystemRequest({ telGuid, fromPage, integrationType })}
        />
      );

      const modal = {
        ...modalProps,
        component,
      };

      openModal(modal);
    },
    handleSendTelsToExternalSystem: (props: Object) => (guid: string) => {
      const {
        openModal,
        closeModal,
        selectedList,
        sendTelsToExternalSystemRequest,
      } = props;

      const options = R.filter((item: Object) =>
        R.includes(R.path(['value'], item), externalSystemsForMassAction), externalSystems);

      const telGuids = G.ifElse(
        G.isString(guid),
        R.of(Array, guid),
        selectedList,
      );

      if (G.isNilOrEmpty(selectedList)) {
        return G.showToastrMessageSimple(
          'info',
          `${G.getWindowLocale('titles:please-select-a', 'Please, Select a')} ${
            R.toUpper(G.getWindowLocale('titles:tel', 'TEL'))}!`,
        );
      }

      const component = ((
        <SelectDropdownForm
          options={options}
          optionRequired={true}
          fieldLabel={fieldLabel}
          cancelAction={closeModal}
          submitAction={(integrationType: string) =>
            sendTelsToExternalSystemRequest({ integrationType, [GC.FIELD_TEL_GUIDS]: R.join(', ', telGuids) })}
        />
      ));

      const modal = {
        ...modalProps,
        component,
      };

      openModal(modal);
    },
  }),
);

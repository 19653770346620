import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Table } from '../../../components/table';
import TextComponent from '../../../components/text';
import { openModal } from '../../../components/modal/actions';
import { FormFooter2 } from '../../../components/form-footer';
import { ConfirmComponent } from '../../../components/confirm';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// forms
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ActionButton, ZOrderWrapper } from '../../../ui';
// feature fleet-list
import {
  createFleetServiceIssueDocumentRequest,
  deleteFleetServiceIssueDocumentRequest,
  downloadFleetServiceIssueDocumentRequest,
} from '../actions';
//////////////////////////////////////////////////

const documentsTableSettings = {
  maxHeight: 320,
  titleFontSize: 12,
  titleRowHeight: 30,
  tableRowHeight: 30,
  useMainColors: true,
  moreBtnCellWidth: 0,
  allowEditBtn: false,
  checkBoxCellWidth: 0,
  allowSelectAll: false,
  expandableItems: false,
  allowSelectItems: false,
  emptyListPadding: '10px 0',
};

const settings = {
  [GC.FIELD_FILE_NAME]: {
    width: 500,
    name: 'titles:documents',
    customComponent: ({ callbackData, data: { guid, fileUri, documentFilename } }: Object) => {
      const {
        shared,
        disabled,
        entityType,
        handleDeleteDocument,
        downloadFleetServiceIssueDocumentRequest,
      } = callbackData;

      const handleDownloadDocument = ({ isPreview }: Object) =>
        downloadFleetServiceIssueDocumentRequest({ guid, fileUri, isPreview, documentFilename });

      return (
        <Flex>
          <Flex mr={15}>
            <Box cursor='pointer' onClick={() => handleDownloadDocument({ isPreview: true })}>
              {I.eye()}
            </Box>
            <Box mx={10} cursor='pointer' onClick={handleDownloadDocument}>
              {I.download()}
            </Box>
            {
              R.and(R.not(shared), G.isFalse(disabled)) &&
              <AuthWrapper
                has={G.ifElse(R.equals(entityType, GC.FIELD_TRUCK), [PC.FLEET_TRUCK_WRITE], [PC.FLEET_TRAILER_WRITE])}
              >
                <Box cursor='pointer' onClick={() => handleDeleteDocument(guid)}>
                  {I.trash()}
                </Box>
              </AuthWrapper>
            }
          </Flex>
          <TextComponent
            display='block'
            withEllipsis={true}
            title={documentFilename}
            maxWidth='calc(100% - 40px)'
          >
            {documentFilename}
          </TextComponent>
        </Flex>
      );
    },
  },
};

const report = {
  fields: [{ sequence: 0, name: GC.FIELD_FILE_NAME }],
};

const AddDocumentsButton = ({ entityType, handleCreateDocument }: Object) => (
  <AuthWrapper has={G.ifElse(R.equals(entityType, GC.FIELD_TRUCK), [PC.FLEET_TRUCK_WRITE], [PC.FLEET_TRAILER_WRITE])}>
    <ActionButton
      px='8px'
      mb={15}
      height={30}
      fontSize={11}
      borderRadius='5px'
      onClick={handleCreateDocument}
    >
      {G.getAddTitle(['titles:documents', 'Documents'])}
    </ActionButton>
  </AuthWrapper>
);

const fields = [
  {
    type: 'files',
    isRequired: true,
    fieldName: GC.FIELD_FILES,
    label: ['titles:upload-files', 'Upload Files'],
    inputWrapperStyles: { mt: 15, mb: 25, width: '100%' },
  },
];

const documentFormEnhance = compose(
  withFormik({
    mapPropsToValues: () => ({ [GC.FIELD_FILES]: [] }),
    validationSchema: Yup.object().shape({ [GC.FIELD_FILES]: G.yupArrayRequired }),
    handleSubmit: (values: Object, { props: { submitAction } }: Object) => submitAction(values),
  }),
  pure,
);

const AddDocumentForm = documentFormEnhance((props: Object) => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2 {...G.getFormikPropsToFieldset(props)} fields={fields} />
      <FormFooter2 />
    </form>
  );
});

const enhance = compose(
  connect(null, {
    openModal,
    createFleetServiceIssueDocumentRequest,
    deleteFleetServiceIssueDocumentRequest,
    downloadFleetServiceIssueDocumentRequest,
  }),
  withHandlers({
    handleCreateDocument: (props: Object) => () => {
      const {
        openModal,
        documentList,
        successCallback,
        serviceIssueGuid,
        createFleetServiceIssueDocumentRequest,
      } = props;

      const component = (
        <AddDocumentForm
          serviceIssueGuid={serviceIssueGuid}
          submitAction={(values: Object) => createFleetServiceIssueDocumentRequest({
            values,
            serviceIssueGuid,
            successCallback: G.isFunction(successCallback)
              ? (newDocuments: Object) => successCallback(R.concat(documentList, newDocuments))
              : null,
          })}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 430,
          title: G.getAddTitle(['titles:issue-documents', 'Issue Documents']),
        },
      };

      openModal(modal);
    },
    handleDeleteDocument: (props: Object) => (guid: string) => {
      const {
        openModal,
        documentList,
        successCallback,
        serviceIssueGuid,
        deleteFleetServiceIssueDocumentRequest,
      } = props;

      const textLocale = `${G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')} ${
        G.getWindowLocale('titles:issue-document', 'Issue Document')
      }?`;

      const component = <ConfirmComponent textLocale={textLocale} />;

      const modal = {
        component,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => deleteFleetServiceIssueDocumentRequest({
                serviceIssueGuid,
                documentGuid: guid,
                successCallback: G.isFunction(successCallback)
                  ? () => successCallback(R.reject(R.propEq(guid, GC.FIELD_GUID), documentList))
                  : null,
              }),
            },
          ],
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

export const FleetServiceIssueDocuments = enhance((props: Object) => {
  const { p, shared, disabled, entityType, documentList, handleCreateDocument } = props;

  return (
    <Box p={R.or(p, 20)} maxWidth={600}>
      {
        R.and(R.not(shared), G.isFalse(disabled)) &&
        <AddDocumentsButton entityType={entityType} handleCreateDocument={handleCreateDocument} />
      }
      <ZOrderWrapper zIndex='1'>
        <Table
          report={report}
          callbackData={props}
          hasSelectable={false}
          itemList={documentList}
          columnSettings={settings}
          tableSettings={documentsTableSettings}
        />
      </ZOrderWrapper>
    </Box>
  );
});

import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { gpsIntegrationConfigTypes, notificationLevelOptions } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

// NOTE: uncomment after adding new driver cards
// export const driverCardsSection = {
//   title: 'titles:driver-cards',
//   fields: {
//     [GC.DRIVER_CARDS_WARNING]: {
//       type: 'input',
//       validate: G.isEmptyOrInteger,
//       name: 'titles:show-warning-hours',
//       nameForAttribute: GC.DRIVER_CARDS_WARNING,
//     },
//   },
// };

const DRIVER_GENERAL_SETTINGS = [
  {
    title: 'titles:required-document-types',
    fields: {
      [GC.DRIVER_REQUIRED_DOCUMENT_TYPES]: {
        type: 'list',
        options: 'documentTypes',
        component: MultiselectFieldComponent,
        name: 'titles:required-document-types',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.DRIVER_REQUIRED_DOCUMENT_TYPES,
      },
      [GC.DRIVER_REQUIRED_DOCUMENT_WARNING_LEVEL]: {
        type: 'select',
        options: notificationLevelOptions(),
        name: 'titles:required-documents-warning-level',
        nameForAttribute: GC.DRIVER_REQUIRED_DOCUMENT_WARNING_LEVEL,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:unemployed-reason',
    configName: GC.DRIVER_UNEMPLOYED_REASON,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:domicile-terminal',
    configName: GC.DRIVER_DOMICILE_TERMINAL,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:assigned-division',
    configName: GC.DRIVER_ASSIGNED_DIVISION,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:out-of-duty-reason',
    configName: GC.DRIVER_OUT_DUTY_REASON,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:pending-activity-status',
    configName: GC.DRIVER_PENDING_ACTIVITY_STATUS,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:citizen',
    editPopupTitle: 'titles:edit',
    configName: GC.DRIVER_CITIZEN,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:contact-type',
    editPopupTitle: 'titles:edit',
    configName: GC.DRIVER_CONTACT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:document-type',
    editPopupTitle: 'titles:edit',
    configName: GC.DRIVER_DOCUMENT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:certification-type',
    configName: GC.DRIVER_CERTIFICATION_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:certification-name',
    configName: GC.DRIVER_CERTIFICATION_NAME,
  },
];

const DRIVER_TRACKING_SETTINGS = [
  {
    title: 'titles:general',
    fields: {
      [GC.DRIVER_MINIMUM_BREAK_MINUTES]: {
        type: 'input',
        name: 'titles:minimum-break-minutes',
        nameForAttribute: GC.DRIVER_MINIMUM_BREAK_MINUTES,
      },
    },
  },
  {
    title: 'titles:auto-arrive',
    fields: {
      [GC.DRIVER_AUTO_ARRIVE_ENABLED]: {
        type: 'switcher',
        name: 'titles:enabled',
        nameForAttribute: GC.DRIVER_AUTO_ARRIVE_ENABLED,
      },
      [GC.DRIVER_AUTO_ARRIVE_RADIUS_METERS]: {
        type: 'input',
        // TODO: remove or uncomment after testing
        // validate: G.isEmptyOrInteger,
        name: 'titles:radius-meters',
        validation: G.yupIntegerNotRequired,
        nameForAttribute: GC.DRIVER_AUTO_ARRIVE_RADIUS_METERS,
      },
    },
  },
  {
    title: 'titles:gps-integration',
    fields: {
      [GC.DRIVER_GPS_STATUS_CODE]: {
        type: 'select',
        name: 'titles:status-code',
        options: 'gpsStatusMessageCodes',
        nameForAttribute: GC.DRIVER_GPS_STATUS_CODE,
      },
      [GC.DRIVER_GPS_HOURS_BEFORE_FIRST_EVENT_EARLY_DATE]: {
        type: 'text',
        name: 'titles:hours-before-pickup-eld',
        // validate: [G.isPositiveValue, G.isEmptyOrInteger],
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 1000000000),
        nameForAttribute: GC.DRIVER_GPS_HOURS_BEFORE_FIRST_EVENT_EARLY_DATE,
      },
      [GC.DRIVER_GPS_HOURS_BEFORE_LAST_EVENT_LATE_DATE]: {
        type: 'text',
        name: 'titles:hours-after-drop-eld',
        // validate: [G.isPositiveValue, G.isEmptyOrInteger],
        validation: G.yupIntegerNotRequiredLessOrEqual(0, 1000000000),
        nameForAttribute: GC.DRIVER_GPS_HOURS_BEFORE_LAST_EVENT_LATE_DATE,
      },
    },
  },
  {
    showRemove: true,
    configType: 'custom',
    title: 'titles:gps-integration-configs',
    configName: GC.CUSTOM_GPS_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_GPS_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
        customLogic: (field: Object, { integrationType }: Object) => R.compose(
          R.pathOr('', [GC.FIELD_LABEL]),
          R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
        )(gpsIntegrationConfigTypes),
      },
      {
        name: 'titles:enabled',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:auto-assigment-enabled',
        nameForAttribute: GC.FIELD_AUTO_ASSIGMENT_ENABLED,
      },
      {
        name: 'titles:group-id',
        nameForAttribute: GC.FIELD_GROUP_ID,
      },
      {
        name: 'titles:enabled-daily-hours-storage',
        nameForAttribute: GC.FIELD_ENABLED_DAILY_HOURS_STORAGE,
        customLogic: (field: Object, { integrationType, enabledDailyHoursStorage }: Object) => {
          if (R.includes(
            integrationType,
            [
              GC.GPS_INTEGRATION_TYPE_SAMSARA,
              GC.GPS_INTEGRATION_TYPE_GEO_TAB,
              GC.GPS_INTEGRATION_TYPE_VERIZON,
            ],
          )) {
            return G.ifElse(
              enabledDailyHoursStorage,
              I.uiTrue,
              I.uiFalse,
            )();
          }

          return '';
        },
      },
      {
        name: 'titles:enabled-update-daily-hours',
        nameForAttribute: GC.FIELD_ENABLED_UPDATE_DAILY_HOURS,
        customLogic: (field: Object, { integrationType, enabledUpdateDailyHours }: Object) => {
          if (R.includes(
            integrationType,
            [
              GC.GPS_INTEGRATION_TYPE_SAMSARA,
              GC.GPS_INTEGRATION_TYPE_GEO_TAB,
              GC.GPS_INTEGRATION_TYPE_VERIZON,
            ],
          )) {
            return G.ifElse(
              enabledUpdateDailyHours,
              I.uiTrue,
              I.uiFalse,
            )();
          }
        },
      },
      {
        name: 'titles:enabled-hos-logs-storage',
        nameForAttribute: GC.FIELD_ENABLED_HOS_LOGS_STORAGE,
        customLogic: (_: any, { integrationType, enabledHosLogsStorage }: Object) => {
          if (G.notEquals(integrationType, GC.GPS_INTEGRATION_TYPE_SAMSARA)) return '';

          return G.ifElse(
            enabledHosLogsStorage,
            I.uiTrue,
            I.uiFalse,
          )();
        },
      },
    ],
  },
];

const DRIVER_SAFETY_TAB = [
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:safety-violation-type',
    configName: GC.DRIVER_SAFETY_VIOLATION_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:safety-violation-severity',
    configName: GC.DRIVER_SAFETY_VIOLATION_SEVERITY,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:safety-violation-disciplinary-action',
    configName: GC.DRIVER_SAFETY_VIOLATION_DISCIPLINARY_ACTION,
  },
];

const DRIVER_ONBOARDING_INTEGRATION = [
  {
    showRemove: true,
    hideInherited: true,
    configType: 'custom',
    title: 'titles:onboarding-integration',
    configName: GC.CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG,
    customConfigName: GC.CUSTOM_DRIVER_ONBOARDING_INTEGRATION_CONFIG,
    fields: [
      {
        name: 'titles:integration-type',
        nameForAttribute: GC.FIELD_INTEGRATION_TYPE,
      },
      {
        tableColumnWith: 100,
        name: 'titles:active',
        nameForAttribute: GC.FIELD_ENABLED,
      },
      {
        name: 'titles:used',
        tableColumnWith: 100,
        nameForAttribute: GC.FIELD_USED,
      },
      {
        name: 'titles:company-id',
        nameForAttribute: GC.FIELD_COMPANY_ID,
      },
      {
        name: 'titles:preferred-username-format',
        nameForAttribute: GC.FIELD_PREFERRED_USERNAME_FORMAT,
        customLogic: (_: any, { preferredUsernameFormat }: Object) => G.getEnumLocale(preferredUsernameFormat),
      },
    ],
  },
];

const ConfigDriverGroup = {
  DRIVER_GENERAL_SETTINGS,
  DRIVER_TRACKING_SETTINGS,
  DRIVER_SAFETY_TAB,
  DRIVER_ONBOARDING_INTEGRATION,
};

export {
  ConfigDriverGroup,
};

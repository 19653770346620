import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const fields = {
  [GC.FIELD_CARRIER_CERTIFICATE_CERTIFIED]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:certified',
    additionalInputWrapperStyles: { width: '100%' },
  },
  [GC.FIELD_CARRIER_CERTIFICATE_CLASS]: {
    width: 400,
    isMulti: true,
    isRequired: true,
    type: 'reactSelect',
    name: 'titles:class',
    additionalInputWrapperStyles: { width: '100%' },
    options: [
      // TODO add locale and new value
      {
        value: '1 - Explosives',
        label: '1 - Explosives',
      },
      {
        value: '1.1 Explosives_Mass explosion hazard',
        label: '1.1 Explosives_Mass explosion hazard',
      },
      {
        value: '1.2 - Explosives_Projection hazard',
        label: '1.2 - Explosives_Projection hazard',
      },
      {
        value: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
        label: '1.3 - Explosives_Fire, minor blast, andor projection hazard',
      },
      {
        value: '1.4 - Explosives_No significant hazard',
        label: '1.4 - Explosives_No significant hazard',
      },
      {
        value: '1.5 - Explosives_Insensitive material with mass explosion hazard',
        label: '1.5 - Explosives_Insensitive material with mass explosion hazard',
      },
      {
        value: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
        label: '1.6 - Explosives_Insenitive articles with no mass explosion hazard',
      },
      {
        value: '2 - Gases',
        label: '2 - Gases',
      },
      {
        value: '2.1 Gases_Flammable Gas',
        label: '2.1 Gases_Flammable Gas',
      },
      {
        value: '2.2 - Gases_Non-flammable non poisonous',
        label: '2.2 - Gases_Non-flammable non poisonous',
      },
      {
        value: '2.3 - Gases_Poisonous or toxic gas',
        label: '2.3 - Gases_Poisonous or toxic gas',
      },
      {
        value: '3 - Flammable and Combustible Liquids',
        label: '3 - Flammable and Combustible Liquids',
      },
      {
        value: '4 - Flammable Solids',
        label: '4 - Flammable Solids',
      },
      {
        value: '4.1 - Flammable Solids_Flammable Solid',
        label: '4.1 - Flammable Solids_Flammable Solid',
      },
      {
        value: '4.2 - Flammable Solids_Spontaneously combustible material',
        label: '4.2 - Flammable Solids_Spontaneously combustible material',
      },
      {
        value: '4.3 - Flammable Solids_Dangerous when wet material',
        label: '4.3 - Flammable Solids_Dangerous when wet material',
      },
      {
        value: '5 - Oxidizers and Organic Peroxides',
        label: '5 - Oxidizers and Organic Peroxides',
      },
      {
        value: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
        label: '5.1 - Oxidizers and Organic Peroxides_Oxidizer',
      },
      {
        value: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
        label: '5.2 - Oxidizers and Organic Peroxides_Organic Peroxide',
      },
      {
        value: '6 - Poisonous Toxic and Infectious Substances',
        label: '6 - Poisonous Toxic and Infectious Substances',
      },
      {
        value: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
        label: '6.1 - Poisonous Toxic and Infectious Substances_Poisonus or toxic substances',
      },
      {
        value: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
        label: '6.2 - Poisonous Toxic and Infectious Substances_Infectious substances',
      },
      {
        value: '7 - Radioactive Materials',
        label: '7 - Radioactive Materials',
      },
      {
        value: '8 - Corrosive Materials',
        label: '8 - Corrosive Materials',
      },
      {
        value: '9 - Miscellaneous Dangerous Substances or Articles',
        label: '9 - Miscellaneous Dangerous Substances or Articles',
      },
    ],
  },
  [GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE]: {
    width: 100,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:effective-date',
    calendarInputWrapperStyles: {
      width: '100%',
    },
    maxDate: ({ values: { expirationDate } }: Object) => (
      G.isNotNilAndNotEmpty(expirationDate) ? G.subtractMomentTime(expirationDate, 1, GC.FIELD_DAYS) : null
    ),
  },
  [GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE]: {
    width: 100,
    isRequired: true,
    type: 'datePicker',
    name: 'titles:expiration-date',
    calendarInputWrapperStyles: {
      width: '100%',
    },
    minDate: ({ values: { effectiveDate } }: Object) => (
      G.isNotNilAndNotEmpty(effectiveDate) ? G.addMomentTime(effectiveDate, 1, GC.FIELD_DAYS) : null
    ),
  },
  [GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT]: {
    width: 100,
    type: 'text',
    name: 'titles:driver-count',
  },
  [GC.FIELD_CARRIER_CERTIFICATE_PERMIT]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:permit-hm232',
    additionalInputWrapperStyles: { ml: '5px', width: 'max-content' },
  },
  [GC.FIELD_CARRIER_CERTIFICATE_SAFETY_PLAN]: {
    width: 100,
    type: 'toggle',
    uiType: 'boolean',
    name: 'titles:safety-plan',
    additionalInputWrapperStyles: { width: 'max-content' },
  },
};

const validationSchema = Yup.object().shape({
  [GC.FIELD_CARRIER_CERTIFICATE_CLASS]: G.yupArrayRequired,
  [GC.FIELD_CARRIER_CERTIFICATE_EFFECTIVE_DATE]: G.yupStringRequired,
  [GC.FIELD_CARRIER_CERTIFICATE_EXPIRATION_DATE]: G.yupStringRequired,
  [GC.FIELD_CARRIER_CERTIFICATE_DRIVER_COUNT]: G.yupNumberNotRequired.min(0, G.getShouldBePositiveLocaleTxt()),
});

const inputWrapperStyles = {
  mb: 25,
  width: 230,
};

const fieldsWrapperStyles = {
  width: 480,
};

export const certificateSettings = {
  fields,
  validationSchema,
  inputWrapperStyles,
  fieldsWrapperStyles,
  groupName: 'certificate',
  itemTitleArr: ['titles:certificate', 'Certificate'],
  formGroupTitleArr: ['titles:certificates', 'Certificates'],
  endpoints: {
    list: 'carrierCertificate',
    createOrUpdate: 'carrierCertificate',
    remove: 'getCarrierCertificateEndpoint',
  },
};

import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// common
import { globalCleanReports } from '../../common/actions';
// components
import { getLoadStatusOptions } from '../../components/filter/settings';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { sendLogOutRequest } from '../auth/actions';
import { getRoleListRequest } from '../role/actions';
import { getConfigByGroupSaga } from '../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { socketStatisticDocumentGeneratedRequest } from '../sockets/actions';
import {
  checkReportFunction,
  transformFiltersValueForCharts,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { generateDefaultReport } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature chart
import * as A from './actions';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectFilterParams,
  makeSelectAvailableReports } from './selectors';
//////////////////////////////////////////////////

function* handleGetChartListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(payload)) {
      yield put(openLoader({ showDimmer: true }));
      yield put(A.setListLoading(true));
    }
    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());
    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('chartDefault', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));
      return yield put(closeLoader());
    }
    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };
    const data = R.or(G.setSearchCriteria({ filterParams: newFilterParams, reqBody }), reqBody);
    const res = yield call(sendRequest, 'post', endpointsMap.chartList, { data });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getListOfChartsSuccess(res.data));
      yield put(A.setListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetChartListSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetChartListSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.setListLoading(false));
    yield put(closeLoader());
  }
}

function* handleAvailableReportsRequest({ payload, notSetUsedReport }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    if (G.isNilOrEmpty(currentBranchGuid)) return false;
    const reportType = payload.reportType;
    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);
      yield put(A.setReports(checkedReports));
      if (R.not(R.prop(GC.FIELD_LENGTH, reports))) {
        return yield put(A.setUsedReport(generateDefaultReport(GC.CHART_REPORT)));
      }
      if (R.not(notSetUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(GC.CHART_REPORT));
        yield put(A.setUsedReport(usedReport));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
  }
}

function* handleCreateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path([GC.FIELD_SEARCH_CRITERIA], payload));
    const newPayload = R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, payload);
    const data = R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload);

    const res = yield call(sendRequest, 'post', endpointsMap.report, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.head(checkReportFunction(R.of(Array, res.data))))));

      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.CHART_REPORT,
            pathname: GC.ROUTE_PATH_CHARTS_LIST,
          },
        },
      );
      yield call(handleGetChartListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleUpdateReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.assoc(GC.FIELD_SEARCH_CRITERIA, searchCriteria, payload);

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: newPayload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setUsedReport(G.getReportSortedBySeqFreez(R.head(checkReportFunction(R.of(Array, data))))));

      yield call(
        handleAvailableReportsRequest,
        {
          notSetUsedReport: true,
          payload: {
            reportType: GC.CHART_REPORT,
            pathname: GC.ROUTE_PATH_CHARTS_LIST,
          },
        },
      );
      yield call(handleGetChartListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeDefaultReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(
        handleAvailableReportsRequest,
        {
          payload: {
            reportType: GC.CHART_REPORT,
            pathname: GC.ROUTE_PATH_CHARTS_LIST,
          },
        },
      );
      yield call(handleGetChartListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const reqBody = {
      fields: payload.fields,
      orderFields: payload.orderFields,
      searchCriteria: payload.searchCriteria,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };
    const data = G.setSearchCriteria({ filterParams: newFilterParams, reqBody });
    const params = { format: payload.fileType };
    const options = {
      data,
      params,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.chartExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

function* handleGetChartConfigsSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        group: GC.UI_CONFIG_GROUP,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      options,
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getChartConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetChartConfigsSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetChartConfigsSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

function* handleGetChartPreviewSaga({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filters = transformSearchCriteriaBeforeReportPost(R.path(['filters'], payload));
    const newPayload = R.assoc('filters', filters, payload);
    const options = {
      data: {
        ...newPayload,
        [GC.FIELD_CURRENT_BRANCH_GUID]: currentBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.chartData, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getChartPreviewSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetChartPreviewSaga fail', true);
      yield put(A.getChartPreviewFailed());
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetChartPreviewSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(A.getChartPreviewFailed());
  }
}

function* handleUpdateChartSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filters = transformSearchCriteriaBeforeReportPost(R.path(['filters'], payload));
    const newPayload = R.assoc('filters', filters, payload);
    const options = {
      data: {
        currentBranchGuid,
        ...newPayload,
      },
    };
    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.chart,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.historyGoBack();

      yield put(A.getChartSuccess({}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateChartSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleUpdateChartSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

function* handleGetChartSaga({ guid }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.getChartEndpoint(guid),
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      const filters = transformFiltersValueForCharts(R.path(['filters'], data), getLoadStatusOptions());
      yield put(A.getChartSuccess(R.assoc('filters', R.values(filters), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetChartSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleGetChartSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

function* handleCreateChartSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const filters = transformSearchCriteriaBeforeReportPost(R.path(['filters'], payload));
    const newPayload = R.assoc('filters', filters, payload);
    const options = {
      data: {
        ...newPayload,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        [GC.FIELD_OWNING_BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.chart,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      G.historyGoBack();

      yield put(A.getChartSuccess({}));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateChartSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleCreateChartSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}
function* handleDeleteChartSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      data: payload,
    };
    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getChartEndpointDelete,
      options,
    );
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.deleteChartSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteChartSaga', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleDeleteChartSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield put(closeLoader());
  }
}

function* handleChangeBranch() {
  try {
    yield put(A.setInitialState());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeBranch');
  }
}

function* handleVisitChartsListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CHART_LIST_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(socketStatisticDocumentGeneratedRequest(G.getAmousCurrentUserFromWindow()));
    yield put(A.resetListAndPagination());
    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);
    yield put(A.setIgnorePromptStatus(false));
    const usedReport = yield select(makeSelectUsedReport());
    if (G.isNilOrEmpty(usedReport)) {
      yield put(A.setReportPending());
      yield call(handleAvailableReportsRequest, { payload });
    }
    yield call(handleGetChartListSaga, { payload: true });
    yield put(closeLoader());
    break;
  }
}

function* handleVisitCreateChartPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CHART_CREATE_PAGE);
    yield put(A.getChartSuccess({}));
    yield put(A.getChartPreviewSuccess({}));
    yield put(getRoleListRequest());
    yield call(handleGetChartConfigsSaga);
    break;
  }
}

function* handleVisitEditChartPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { guid } = payload;
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CHART_EDIT_PAGE);
    yield put(A.getChartPreviewSuccess({}));
    yield put(A.setRequestPending(true));
    yield put(getRoleListRequest());
    yield call(handleGetChartConfigsSaga);
    yield call(handleGetChartSaga, { guid });
    yield put(A.setRequestPending(false));
    break;
  }
}


export function* chartsWatcherSaga() {
  yield takeLatest(A.getChartRequest, handleGetChartSaga);
  yield takeLatest(sendLogOutRequest, handleChangeBranch);
  yield takeLatest(globalCleanReports, handleChangeBranch);
  yield takeLatest(A.createChartRequest, handleCreateChartSaga);
  yield takeLatest(A.updateChartRequest, handleUpdateChartSaga);
  yield takeLatest(A.deleteChartRequest, handleDeleteChartSaga);
  yield takeLatest(A.getListOfChartsRequest, handleGetChartListSaga);
  yield takeLatest(A.getChartPreviewRequest, handleGetChartPreviewSaga);
  yield takeLatest(A.getChartConfigsRequest, handleGetChartConfigsSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(GC.VISIT_CHART_EDIT_PAGE, handleVisitEditChartPageSaga);
  yield takeLatest(GC.VISIT_CHART_LIST_PAGE, handleVisitChartsListPageSaga);
  yield takeLatest(GC.VISIT_CHART_CREATE_PAGE, handleVisitCreateChartPageSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
}

export default chartsWatcherSaga;

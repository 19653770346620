import * as R from 'ramda';
// components
import {
  getDriverTypeOptions,
  getTripStatusOptions,
  getOrderStatusOptions,
  getLoadSubStatusOptions,
} from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
/////////////////////////////////////////////////

const RC = {
  LOAD_CANCEL_REASON: `${GC.FIELD_LOAD_CANCEL_REASON}.${GC.FIELD_DISPLAYED_VALUE}`,
};

export const getCLOFilterParams = ({ modeOptions = [], serviceOptions = [] }: Object) => {
  const params = [
    {
      type: 'string',
      value: GC.FIELD_BRANCH_DOT_ACCOUNT_NAME,
      name: G.getWindowLocale('titles:account-number', 'Account Number'),
    },
    {
      type: 'string',
      value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
      name: G.getWindowLocale('titles:branch', 'Branch'),
    },
    {
      type: 'boolean',
      value: GC.FIELD_HOT,
      name: G.getWindowLocale('titles:hot-order', 'Hot Order'),
    },
    {
      type: 'selectMultiple',
      value: GC.FIELD_STATUS,
      options: getOrderStatusOptions(),
      name: G.getWindowLocale('titles:status', 'Status'),
      placeholder: {
        text: 'Choose a status',
        key: 'titles:choose-a-status',
      },
    },
    {
      type: 'string',
      value: RC.LOAD_CANCEL_REASON,
      name: G.getWindowLocale('titles:load-cancel-reason', 'Load Cancel Reason'),
    },
    {
      type: 'string',
      value: GC.GRC.CUSTOM_STATUS_NAME,
      name: G.getWindowLocale('titles:custom-status', 'Custom Status'),
    },
    {
      type: 'selectMultiple',
      value: GC.FIELD_SUB_STATUS,
      options: getLoadSubStatusOptions(),
      name: G.getWindowLocale('titles:sub-status', 'Sub Status'),
      placeholder: {
        text: 'Choose a status',
        key: 'titles:choose-a-status',
      },
    },
    {
      type: 'string',
      value: GC.GRC.ROUTE_NAME,
      name: G.getWindowLocale('titles:route-name', 'Route Name'),
    },
    {
      collection: true,
      type: 'string:array',
      value: GC.GRC.SALE_PERSONS_NAME,
      name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      collection: true,
      type: 'string:array',
      value: GC.GRC.SALE_PERSONS_ID,
      name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
    },
    {
      type: 'selectMultiple',
      options: serviceOptions,
      value: GC.GRC.SERVICES_DROPDOWN_OPTION_GUID,
      name: G.getWindowLocale('titles:services', 'Services'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      options: modeOptions,
      type: 'selectMultiple',
      value: GC.GRC.RATE_MODE_DROPDOWN_OPTION_GUID,
      name: G.getWindowLocale('titles:mode', 'Mode'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_LOCATION_NAME,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:name', 'Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_COUNTRY,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_STATE,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:state', 'State')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_CITY,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:city', 'City')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_ADDRESS1,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_ZIP,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_COMMENTS,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:comments', 'Comments')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_PAYMENT_TERM,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
        G.getWindowLocale('titles:payment-term', 'Payment Terms')}`,
    },
    {
      type: 'string',
      value: GC.GRC.BILL_TO_LOCATION_TYPE_DISPLAYED,
      name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
    },
    {
      type: 'string',
      value: GC.FIELD_CREATED_BY,
      name: G.getWindowLocale('titles:created-by', 'Created By'),
    },
    {
      type: 'date',
      value: GC.FIELD_CREATED_DATE,
      name: G.getWindowLocale('titles:created-date', 'Created Date'),
    },
    {
      type: 'string',
      value: GC.GRC.REFERENCES_NAME,
      name: G.getWindowLocale('titles:reference-name', 'Reference Name'),
    },
    {
      type: 'string',
      value: GC.GRC.REFERENCES_VALUE,
      name: G.getWindowLocale('titles:reference-value', 'Reference Value'),
    },
    {
      type: 'string',
      value: GC.GRC.PRIMARY_REFERENCE_NAME,
      name: G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name'),
    },
    {
      type: 'string',
      value: GC.FIELD_PRIMARY_REFERENCE_VALUE,
      name: G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value'),
    },
    {
      type: 'string',
      value: GC.GRC.EQUIPMENTS,
      name: G.getWindowLocale('titles:equipments', 'Equipments'),
    },
    {
      type: 'string',
      value: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
      name: G.getWindowLocale('titles:special-instructions', 'Special Instructions'),
    },
    {
      type: 'string',
      value: GC.FIELD_PINNED_NOTE_TEXT,
      name: `${G.getWindowLocale('titles:pinned-note', 'Pinned Note')}: ${G.getWindowLocale('titles:text', 'Text')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_PRIMARY_REFERENCE_VALUE,
      name: G.getWindowLocale('titles:tel-load-id', 'Load ID'),
    },
    {
      type: 'number',
      value: GC.GRC.RATE_SERVICE_DAYS,
      name: G.getWindowLocale('titles:service-days', 'Service Days'),
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LOGIN_ID,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:login-id', 'Login ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_EMAIL,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:email', 'Email')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:first-name', 'First Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:login-id', 'Login ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:email', 'Email')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:first-name', 'First Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
      name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
      name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.TELS_CARRIER_NAME,
      name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
    },
    {
      type: 'string',
      value: GC.GRC.TELS_CARRIER_SCAC,
      name: G.getWindowLocale('titles:carrier-scac', 'Carrier SCAC'),
    },
    {
      type: 'number',
      value: GC.GRC.RATE_TOTAL,
      name: G.getWindowLocale('titles:total-rate', 'Total Rate'),
    },
    {
      type: 'number',
      value: GC.FIELD_INVOICE_COUNT,
      name: G.getWindowLocale('titles:invoice-count', 'Invoice Count'),
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_COMPLETE_DATE,
      name: G.getWindowLocale('titles:in-transit-date', 'In Transit Date'),
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_COMPLETE_DATE,
      name: G.getWindowLocale('titles:delivered-date', 'Delivered Date'),
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:template-id', 'Template ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_NAME,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:location-name', 'Location Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_PICKUP_NUMBER,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:pick-up-number', 'Pick Up Number')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:address1', 'Address1')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_CITY,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:city', 'City')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_STATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:state', 'State')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_COUNTRY,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:country', 'Country')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_ZIP,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:template-id', 'Template ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_NAME,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:location-name', 'Location Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS1,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:address1', 'Address1')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_CITY,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:city', 'City')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_STATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:state', 'State')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_COUNTRY,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:country', 'Country')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_ZIP,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:early-date', 'Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_LATE_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:late-date', 'Late Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_APPOINTMENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:early-date', 'Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_LATE_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:late-date', 'Late Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_APPOINTMENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
    },
  ];
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  if (useContainers) {
    return R.insert(6, {
      collection: true,
      type: 'string:array',
      value: GC.GRC.CONTAINER_NUMBERS_WITH_INITIAL,
      name: G.getWindowLocale('titles:containers', 'Containers'),
    }, params);
  }

  return params;
};

export const getTELFilterParams = ({
  modeOptions = [],
  serviceOptions = [],
  crossDockLocationOptions = [],
}: Object) => {
  const params = [
    {
      type: 'string',
      value: GC.FIELD_BRANCH_DOT_ACCOUNT_NAME,
      name: G.getWindowLocale('titles:account-number', 'Account Number'),
    },
    {
      type: 'string',
      value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
      name: G.getWindowLocale('titles:branch', 'Branch'),
    },
    {
      type: 'boolean',
      value: GC.FIELD_HOT,
      name: G.getWindowLocale('titles:hot-trip', 'Hot Trip'),
    },
    {
      value: GC.FIELD_STATUS,
      type: 'selectMultiple',
      options: getTripStatusOptions(),
      name: G.getWindowLocale('titles:status', 'Status'),
      placeholder: {
        text: 'Choose a status',
        key: 'titles:choose-a-status',
      },
    },
    {
      type: 'string',
      value: RC.LOAD_CANCEL_REASON,
      name: G.getWindowLocale('titles:load-cancel-reason', 'Load Cancel Reason'),
    },
    {
      type: 'string',
      value: GC.GRC.CUSTOM_STATUS_NAME,
      name: G.getWindowLocale('titles:custom-status', 'Custom Status'),
    },
    {
      type: 'selectMultiple',
      value: GC.FIELD_SUB_STATUS,
      options: getLoadSubStatusOptions(),
      name: G.getWindowLocale('titles:sub-status', 'Sub Status'),
      placeholder: {
        text: 'Choose a status',
        key: 'titles:choose-a-status',
      },
    },
    {
      type: 'string',
      value: GC.GRC.ROUTE_NAME,
      name: G.getWindowLocale('titles:route-name', 'Route Name'),
    },
    {
      type: 'selectMultiple',
      options: serviceOptions,
      value: GC.GRC.SERVICES_DROPDOWN_OPTION_GUID,
      name: G.getWindowLocale('titles:services', 'Services'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      options: modeOptions,
      type: 'selectMultiple',
      value: GC.GRC.RATE_MODE_DROPDOWN_OPTION_GUID,
      name: G.getWindowLocale('titles:mode', 'Mode'),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      collection: true,
      type: 'string:array',
      value: GC.GRC.BROKER_AGENTS_NAME,
      name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      collection: true,
      type: 'string:array',
      value: GC.GRC.BROKER_AGENTS_ID,
      name: `${G.getWindowLocale('titles:broker-agents', 'Broker Agents')}: ${
        G.getWindowLocale('titles:id', 'ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_LOGIN_ID,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:login-id', 'Login ID')}`,
    },
    {
      type: 'selectMultiple',
      options: getDriverTypeOptions(),
      value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_DRIVER_TYPE,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:driver-type', 'Driver Type')}`,
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_EMAIL,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:email', 'Email')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:first-name', 'First Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
      name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:login-id', 'Login ID')}`,
    },
    {
      type: 'selectMultiple',
      options: getDriverTypeOptions(),
      value: GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:driver-type', 'Driver Type')}`,
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:email', 'Email')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:first-name', 'First Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
      name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
        G.getWindowLocale('titles:last-name', 'Last Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
      name: `${G.getWindowLocale('titles:truck', 'Truck')}: ${G.getWindowLocale('titles:unit-id', 'Unit ID')} `,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
      name: `${G.getWindowLocale('titles:trailers', 'Trailers')}: ${G.getWindowLocale('titles:unit-id', 'Unit ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_CHASSIS_NUMBER,
      name: G.getWindowLocale('titles:chassis-number', 'Chassis Number'),
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_DRAY_CONTAINER_PICK_UP_NUMBER,
      name: G.getWindowLocale('titles:dray-container-pick-up-number', 'Dray Container Pick Up Number'),
    },
    {
      type: 'string',
      value: GC.GRC.FLEET_ASSIGNMENT_DRAY_CONTAINER_DROP_NUMBER,
      name: G.getWindowLocale('titles:dray-container-drop-number', 'Dray Container Drop Number'),
    },
    {
      type: 'string',
      value: GC.GRC.CARRIER_ASSIGNMENT_NAME,
      name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
    },
    {
      type: 'string',
      value: GC.GRC.CARRIER_ASSIGNMENT_SCAC,
      name: G.getWindowLocale('titles:carrier-scac', 'Carrier SCAC'),
    },
    {
      type: 'string',
      value: GC.FIELD_CREATED_BY,
      name: G.getWindowLocale('titles:created-by', 'Created By'),
    },
    {
      type: 'date',
      value: GC.FIELD_CREATED_DATE,
      name: G.getWindowLocale('titles:created-date', 'Created Date'),
    },
    {
      type: 'number',
      value: GC.GRC.RATE_SERVICE_DAYS,
      name: G.getWindowLocale('titles:service-days', 'Service Days'),
    },
    {
      type: 'number',
      value: GC.GRC.RATE_TOTAL,
      name: G.getWindowLocale('titles:total-rate', 'Total Rate'),
    },
    {
      type: 'string',
      value: GC.GRC.REFERENCES_NAME,
      name: G.getWindowLocale('titles:reference-name', 'Reference Name'),
    },
    {
      type: 'string',
      value: GC.GRC.REFERENCES_VALUE,
      name: G.getWindowLocale('titles:reference-value', 'Reference Value'),
    },
    {
      type: 'string',
      value: GC.GRC.PRIMARY_REFERENCE_NAME,
      name: G.getWindowLocale('titles:primary-ref-name', 'Primary Reference Name'),
    },
    {
      type: 'string',
      value: GC.FIELD_PRIMARY_REFERENCE_VALUE,
      name: G.getWindowLocale('titles:primary-ref-value', 'Primary Reference Value'),
    },
    {
      type: 'string',
      value: GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS,
      name: G.getWindowLocale('titles:special-instructions', 'Special Instructions'),
    },
    {
      type: 'string',
      value: GC.FIELD_PINNED_NOTE_TEXT,
      name: `${G.getWindowLocale('titles:pinned-note', 'Pinned Note')}: ${G.getWindowLocale('titles:text', 'Text')}`,
    },
    {
      type: 'string',
      value: GC.GRC.CLOS_PRIMARY_REFERENCE_VALUE,
      name: G.getWindowLocale('titles:load-id', 'Load ID'),
    },
    {
      type: 'number',
      value: GC.FIELD_INVOICE_COUNT,
      name: G.getWindowLocale('titles:invoice-count', 'Invoice Count'),
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_COMPLETE_DATE,
      name: G.getWindowLocale('titles:in-transit-date', 'In Transit Date'),
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_COMPLETE_DATE,
      name: G.getWindowLocale('titles:delivered-date', 'Delivered Date'),
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:template-id', 'Template ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_NAME,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:location-name', 'Location Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_PICKUP_NUMBER,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:pick-up-number', 'Pick Up Number')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:address1', 'Address1')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_CITY,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:city', 'City')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_STATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:state', 'State')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_COUNTRY,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:country', 'Country')}`,
    },
    {
      type: 'string',
      value: GC.GRC.FIRST_EVENT_LOCATION_ZIP,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:template-id', 'Template ID')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_NAME,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:location-name', 'Location Name')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_ADDRESS1,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:address1', 'Address1')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_CITY,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:city', 'City')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_STATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:state', 'State')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_COUNTRY,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:country', 'Country')}`,
    },
    {
      type: 'string',
      value: GC.GRC.LAST_EVENT_LOCATION_ZIP,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:early-date', 'Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_LATE_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:late-date', 'Late Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.FIRST_EVENT_APPOINTMENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
        G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:early-date', 'Early Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_LATE_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:late-date', 'Late Date')}`,
    },
    {
      type: 'date',
      value: GC.GRC.LAST_EVENT_APPOINTMENT_EARLY_DATE,
      name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
        G.getWindowLocale('titles:appointment-early-date', 'Appointment Early Date')}`,
    },
    {
      type: 'selectMultiple',
      value: GC.GRC.INBOUND_TERMINALS,
      options: R.or(crossDockLocationOptions, []),
      name: G.getWindowLocale('titles:inbound-terminals', 'IT'),
      placeholder: {
        text: 'Choose locations',
        key: 'titles:choose-locations',
      },
    },
    {
      type: 'selectMultiple',
      value: GC.GRC.OUTBOUND_TERMINALS,
      options: R.or(crossDockLocationOptions, []),
      name: G.getWindowLocale('titles:outbound-terminals', 'OT'),
      placeholder: {
        text: 'Choose locations',
        key: 'titles:choose-locations',
      },
    },
    {
      type: 'date',
      value: GC.GRC.RATE_DISPATCH_OR_CANCEL_DATE,
      name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
        G.getWindowLocale('titles:dispatch-or-cancel-date', 'Dispatch Or Cancel Date')}`,
    },
    {
      type: 'string',
      value: GC.GRC.RATE_DISPATCHED_OR_CANCELED_BY,
      name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${
        G.getWindowLocale('titles:dispatched-or-canceled-by', 'Dispatched Or Canceled By')}`,
    },
    {
      type: 'boolean',
      disableSort: true,
      value: GC.FIELD_LATE,
      name: G.getWindowLocale('titles:late-arrival', 'Late Arrival'),
    },
  ];
  const useContainers = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_CONTAINERS);

  if (useContainers) {
    return R.insert(6, {
      collection: true,
      type: 'string:array',
      value: GC.GRC.EVENT_CONTAINERS_CONTAINER_NUMBER_WITH_INITIAL,
      name: G.getWindowLocale('titles:container-number', 'Container Number'),
    }, params);
  }

  return params;
};

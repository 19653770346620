import * as R from 'ramda';
import React from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
import { HoveringTitle } from '../../../components/hovering-title';
// features
import { DispatchButton } from '../../rate/ui';
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
import { StatusCell } from '../../dispatch-board-new/components/table-cells';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, CustomButton } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature dispatch-planner
import * as H from '../helpers';
import HeaderActions from './header-actions';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const getPrimaryDriverNameFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'primaryDriverName'], tel))) {
    return R.pathOr('', [GC.SYSTEM_OBJECT_RATE, 'primaryDriverName'], tel);
  }

  return G.getFirstDriverInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getSecondaryDriverNameFromLoad = (tel: Object) => {
  if (G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, 'secondaryDriverName'], tel))) {
    return R.pathOr('', [GC.SYSTEM_OBJECT_RATE, 'secondaryDriverName'], tel);
  }

  return G.getTeamDriverInfo(R.prop(GC.SYSTEM_OBJECT_RATE, tel));
};

const getChargesTotalFromRate = (rate: Object, keyToCharges: string) => {
  const { currency } = rate;

  const charges = R.pathOr([], [keyToCharges], rate);

  const currencySymbol = G.getCurrencySymbolFromRate(rate);
  const total = G.getCalcTelRateChargesTotal(charges, currency);

  return `${currencySymbol} ${G.toFixed(total)}`;
};

const isSecondaryDriverPresent = R.ifElse(
  R.propEq(true, 'isNew'),
  R.prop(GC.FIELD_SECONDARY_DRIVER_GUID),
  R.path([GC.FIELD_FLEET_ASSIGNMENT, GC.FIELD_SECONDARY_DRIVER_GUID]),
);

const getDriverInfoFromTel = (tel: Object) => `
  ${getPrimaryDriverNameFromLoad(tel)}, ${
    getChargesTotalFromRate(R.prop(GC.SYSTEM_OBJECT_RATE, tel), GC.FIELD_PRIMARY_DRIVER_CHARGES)}
`;

const getSecondaryDriverInfoFromTel = (tel: Object) => `
  ${getSecondaryDriverNameFromLoad(tel)}, ${
    getChargesTotalFromRate(R.prop(GC.SYSTEM_OBJECT_RATE, tel), GC.FIELD_SECONDARY_DRIVER_CHARGES)}
`;

const DriversInfo = ({ tel }: Object) => (
  <Box mr='5px' fontSize={11}>
    <TextComponent
      mb='2px'
      maxWidth={250}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={getDriverInfoFromTel(tel)}
    >
      {getDriverInfoFromTel(tel)}
    </TextComponent>
    {
      isSecondaryDriverPresent(R.prop(GC.SYSTEM_OBJECT_RATE, tel)) &&
      <TextComponent
        mb='2px'
        maxWidth={250}
        fontWeight='bold'
        withEllipsis={true}
        display='inline-block'
        title={getSecondaryDriverInfoFromTel(tel)}
      >
        {getSecondaryDriverInfoFromTel(tel)}
      </TextComponent>
    }
  </Box>
);

const getCarrierInfo = ({ rate }: Object) => {
  const carrierTitle = G.getWindowLocale('titles:carrier', 'Carrier');
  const carrierName = R.pathOr('', [GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, GC.FIELD_NAME], rate);
  const chargesTotal = getChargesTotalFromRate(rate, 'carrierRateCharges');

  return `${carrierTitle}: ${carrierName}, ${chargesTotal}`;
};

const CarrierInfo = (props: Object) => (
  <Box mr='5px' fontSize={11}>
    <TextComponent
      mb='2px'
      maxWidth={250}
      fontWeight='bold'
      withEllipsis={true}
      display='inline-block'
      title={getCarrierInfo(props)}
    >
      {getCarrierInfo(props)}
    </TextComponent>
  </Box>
);

const ActionsButton = (props: Object) => (
  <Box zIndex={11}>
    <PopperComponent
      zi={21}
      version={2}
      type='hover'
      rotate={true}
      position='left'
      content={<HeaderActions {...props} />}
      borderColor={G.getTheme('colors.boxShadowBlue')}
    >
      {I.threePointer(blueColor)}
    </PopperComponent>
  </Box>
);

const UpdateRate = (props: Object) => {
  const {
    tel,
    telChanged,
    isCarrierRate,
    handleUpdateTelDriverRate,
    handleUpdateTelCarrierRate,
  } = props;

  return (
    <Flex>
      <CustomButton
        p='4px'
        mr={10}
        height={20}
        type='button'
        fontSize={12}
        minWidth={84}
        bgColor={G.getTheme('buttons.assignBtn.bgColor')}
        onClick={() => {
          if (isCarrierRate) return handleUpdateTelCarrierRate(tel, telChanged);

          return handleUpdateTelDriverRate(tel, telChanged);
        }}
      >
        {G.getWindowLocale('actions:update-rate', 'Update Rate')}
      </CustomButton>
    </Flex>
  );
};

const DispatchBlock = (props: Object) => {
  const {
    tel,
    telChanged,
    handleDispatch,
    handleCancelDispatch,
  } = props;

  if (telChanged) {
    return (
      <Flex
        mr={10}
        p='0 4px'
        height={20}
        borderRadius='2px'
        cursor='not-allowed'
        color={G.getTheme('colors.white')}
        bg={G.getTheme('colors.dark.grey')}
        title={G.getWindowLocale('messages:save-route-before-dispatch', 'Please, save your route before dispatch')}
      >
        {G.getWindowLocale('titles:dispatch', 'Dispatch')}
      </Flex>
    );
  }
  const status = R.path([GC.FIELD_STATUS], tel);

  if (R.includes(status, GC.TEL_STATUSES_TO_DISPATCH)) {
    return (
      <AuthWrapper zIndex={10} has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton
          mr={10}
          onClick={handleDispatch}
        >
          {G.getWindowLocale('titles:dispatch', 'Dispatch')}
        </DispatchButton>
      </AuthWrapper>
    );
  } else if (R.includes(status, GC.TEL_STATUSES_TO_CANCEL)) {
    return (
      <AuthWrapper zIndex={10} has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton mr={10} onClick={handleCancelDispatch}>
          {G.getWindowLocale('titles:cancel-dispatched', 'Cancel Dispatched')}
        </DispatchButton>
      </AuthWrapper>
    );
  } else if (R.includes(status, GC.TEL_NEGATIVE_STATUSES)) {
    return (
      <AuthWrapper zIndex={10} has={[PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_WRITE]}>
        <DispatchButton
          mr={10}
          onClick={handleDispatch}
        >
          {G.getWindowLocale('titles:redispatch', 'Redispatch')}
        </DispatchButton>
      </AuthWrapper>
    );
  }

  return null;
};

const renderRateInfo = (props: Object, isCarrierRate: boolean) => {
  const { tel: { rate } } = props;

  if (isCarrierRate) return <CarrierInfo rate={rate} />;

  return <DriversInfo {...props} />;
};

const RightActions = (props: Object) => {
  const {
    tel,
    telChanged,
    branchGuid,
    isExpanded,
    cleanTelRate,
    sortTelEvents,
    setTelVisibility,
    handleClickLoadMap,
    handleAddNewCloStop,
    withoutHeaderActions,
    handleClickRemoveLoad,
    handleClickAddNewStop,
    handleClickAddTerminal,
    handleAddTelDriverRate,
    handleAddTelCarrierRate,
    handleMultiCarrierRates,
    handleRecalculateLoadDistances,
  } = props;

  const { guid, rate, isNew, status } = tel;

  const withRate = G.isNotNilAndNotEmpty(rate);
  const isCarrierRate = G.isNotNilAndNotEmpty(G.getPropFromObject(GC.SYSTEM_OBJECT_CARRIER_ASSIGNMENT, rate));

  const shownStatusesArray = [
    GC.LOAD_STATUS_PLANNED,
    GC.LOAD_STATUS_DELIVERED,
    GC.LOAD_STATUS_IN_TRANSIT,
    GC.LOAD_STATUS_BOOKED_STATUS,
  ];

  const statusShown = R.includes(status, shownStatusesArray);

  return (
    <Flex>
      {
        withoutHeaderActions &&
        <Flex>
          <Box
            p='0 3px'
            cursor='pointer'
            onClick={() => handleRecalculateLoadDistances(guid)}
            title={G.getWindowLocale('actions:recalculate-distances', 'Recalculate Distances')}
          >
            {I.reloadCircle(blueColor, 24, 24)}
          </Box>
          <Box
            p='0 3px'
            cursor='pointer'
            title={G.getWindowLocale('actions:sort-by-date', 'Sort by Date')}
            onClick={() => {
              sortTelEvents(guid);
              handleRecalculateLoadDistances(guid);
            }}
          >
            {I.sortByDate(blueColor, 30, 25)}
          </Box>
        </Flex>
      }
      {
        statusShown &&
        <Box mr={10} fontSize={12} whiteSpace='nowrap'>
          <StatusCell data={{ status }} />
        </Box>
      }
      {
        withRate &&
        <Flex>
          <DispatchBlock {...props} />
          <AuthWrapper
            has={G.ifElse(
              isCarrierRate,
              [PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
              [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE],
            )}
          >
            {renderRateInfo(props, isCarrierRate)}
          </AuthWrapper>
          {
            G.isTrue(isNew) &&
            <Box
              mr='5px'
              cursor='pointer'
              onClick={() => cleanTelRate(guid)}
              title={G.getWindowLocale('actions:clean-tel-rate', 'Clean TEL Rate')}
            >
              {I.crossInRound(blueColor)}
            </Box>
          }
        </Flex>
      }
      {
        R.and(withRate, R.not(withoutHeaderActions)) &&
        <AuthWrapper has={G.ifElse(isCarrierRate, [PC.CARRIER_RATE_WRITE], [PC.FLEET_RATE_WRITE])}>
          <UpdateRate {...props} isCarrierRate={isCarrierRate} />
        </AuthWrapper>
      }
      <HoveringTitle
        handleClick={() => setTelVisibility(G.ifElse(isExpanded, 'hidden', 'visible'))}
        positionConfig={{
          zIndex: 16,
          right: '100%',
          width: 'max-content',
        }}
        title={G.ifElse(
          isExpanded,
          G.getWindowLocale('actions:hide', 'Hide'),
          G.getWindowLocale('actions:show', 'Show'),
        )}
      >
        <Flex
          p='3px'
          mr='8px'
          cursor='pointer'
          flexDirection={G.ifElse(isExpanded, 'column-reverse', 'column')}
          onClick={() => setTelVisibility(G.ifElse(isExpanded, 'hidden', 'visible'))}
        >
          {I.arrowUpSimple(blueColor, 12, 8)}
          {I.arrowDownSimple(blueColor, 12, 8)}
        </Flex>
      </HoveringTitle>
      {
        R.not(withoutHeaderActions) &&
        <ActionsButton
          remove={() => handleClickRemoveLoad(tel)}
          showOnMap={() => handleClickLoadMap(tel)}
          withoutHeaderActions={withoutHeaderActions}
          addTerminal={() => handleClickAddTerminal(tel)}
          handleMultiCarrierRates={handleMultiCarrierRates}
          assignDriver={() => handleAddTelDriverRate(tel, telChanged)}
          assignCarrier={() => handleAddTelCarrierRate(tel, telChanged)}
          recalculateDistances={() => handleRecalculateLoadDistances(guid)}
          addCloDrop={() => handleAddNewCloStop({
            branchGuid,
            telGuid: guid,
            eventType: GC.EVENT_TYPE_DROP,
          })}
          addCloPickup={() => handleAddNewCloStop({
            branchGuid,
            telGuid: guid,
            eventType: GC.EVENT_TYPE_PICKUP,
          })}
          addDrop={() => handleClickAddNewStop({
            branchGuid,
            loadGuid: guid,
            loadType: GC.LOAD_TYPE_TEL,
            eventType: GC.EVENT_TYPE_DROP,
          })}
          addPickup={() => handleClickAddNewStop({
            branchGuid,
            loadGuid: guid,
            loadType: GC.LOAD_TYPE_TEL,
            eventType: GC.EVENT_TYPE_PICKUP,
          })}
          sortByDate={() => {
            sortTelEvents(guid);
            handleRecalculateLoadDistances(guid);
          }}
        />
      }
    </Flex>
  );
};

const renderLoadTotal = (total: Object) => {
  const totalItemsCount = `${G.getWindowLocale('titles:total', 'Total', { caseAction: 'upperCase' })}:
   ${total.itemsTotal}`;

  const itemsLocale = G.getWindowLocale('titles:items', 'Items', { caseAction: 'lowerCase' });
  const quantity = `${total.totalQuantity[GC.FIELD_ITEM_QUANTITY]} ${total.totalQuantity[GC.FIELD_PACKAGE_TYPE]}`;
  const weight = `${total.totalWeight[GC.FIELD_ITEM_WEIGHT]} ${total.totalWeight[GC.FIELD_ITEM_WEIGHT_TYPE]}`;

  const distance = `${G.getWindowLocale('titles:distance', 'Distance', { caseAction: 'upperCase' })}:
    ${G.ifElse(G.isNilOrEmpty(total.totalDistance), 0, R.path(['totalDistance', GC.FIELD_TOTAL_TRIP_DISTANCE], total))}
    ${
      G.ifElse(
        G.isNilOrEmpty(total.totalDistance),
        '',
        R.path(['totalDistance', GC.FIELD_TOTAL_TRIP_DISTANCE_UOM], total),
      )
    }
  `;

  return `${totalItemsCount} ${itemsLocale}, ${quantity}, ${weight}, ${distance}`;
};

const renderErrorContent = (error: Object) => {
  const color = G.getTheme('colors.light.mainRed');

  return (
    <Box p={10} color={color}>
      {
        G.isNotNil(error.event) &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:wrong-date-sequence-for-stops', 'Wrong date sequence for some stops')}
        </Box>
      }
      {
        error.distanceError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:recalculate-stop-distances', 'Some stops need to recalculate distances')}
        </Box>
      }
      {
        error.cloItemsError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:invalid-clo-items', 'There are invalid clo items')}
        </Box>
      }
      {
        error.eventsCountError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:tel-should-contain-min-two-stops', 'TEL should contain at least two stops')}
        </Box>
      }
      {
        error.terminalCloItemsError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:terminal-should-contain-clo-items', 'Terminal should contain CLO items')}
        </Box>
      }
      {
        G.isNotNilAndNotEmpty(error.eventsErrors) &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:check-events-errors', 'Double check the events for errors')}
        </Box>
      }
      {
        error.containersError &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale('validation:invalid-containers', 'There are invalid containers')}
        </Box>
      }
    </Box>
  );
};

const renderErrorsInfo = (error: Object, tel: Object) => {
  if (G.isFalse(error)) return null;

  return (
    <PopperComponent
      zi={21}
      type='hover'
      position='bottom'
      content={renderErrorContent(error, tel)}
      borderColor={G.getTheme('listActions.borderColor')}
    >
      <Box cursor='pointer'>
        {I.warningIcon()}
      </Box>
    </PopperComponent>
  );
};

const enhance = compose(
  withHandlers({
    handleOpenTelDetails: ({ tel }: Object) => () => {
      const { isNew } = tel;

      if (isNew) return;

      const route = G.getLoadRouteByConfigAndLoadType(routesMap, G.getGuidFromObject(tel));
      window.open(
        `${window.location.origin}${route}`,
        'Details',
        'width:200,height:200',
      );
    },
  }),
  pure,
);

const LoadHeader = enhance((props: Object) => {
  const {
    tel,
    error,
    loadTotal,
    handleOpenTelDetails,
    handleShowLinkedOrders,
  } = props;

  return (
    <Flex
      p='5px 10px'
      width='100%'
      borderTop='1px solid'
      borderBottom='1px solid'
      justifyContent='space-between'
      bg={G.getTheme('colors.light.lightGrey')}
      boxShadow='0 0 10px 0 rgba(204, 204, 204, 0.5)'
      borderColor={G.getTheme('tables.rows.borderColor')}
    >
      <Box width='100%'>
        <Flex width='100%'>
          <Box cursor='pointer'>
            {I.truck(blueColor)}
          </Box>
          <Flex
            m='0 8px'
            color={blueColor}
            onClick={handleOpenTelDetails}
            cursor={G.ifElse(R.path(['isNew'], tel), 'auto', 'pointer')}
          >
            <TextComponent
              maxWidth={250}
              withEllipsis={true}
              display='inline-block'
              title={`${G.getWindowLocale('titles:tel', 'TEL')}: ${H.getLoadName(tel, 'Unknown')}`}
            >
              {`${G.getWindowLocale('titles:tel', 'TEL')}: ${H.getLoadName(tel, 'Unknown')}`}
            </TextComponent>
          </Flex>
          <Box fontSize={11} color={G.getTheme('colors.light.black')}>
            {loadTotal && renderLoadTotal(loadTotal)}
          </Box>
          {
            G.isNotNilAndNotEmpty(R.path(['linkedCloGuids'], tel)) &&
            <Box ml={10} cursor='pointer' color={blueColor} onClick={handleShowLinkedOrders}>
              {G.getWindowLocale('titles:crossdock-clos', 'Cross Dock Orders')} - {R.length(tel.linkedCloGuids)}
            </Box>
          }
          <Box mr={10} ml='auto'>
            {renderErrorsInfo(error, tel)}
          </Box>
        </Flex>
      </Box>
      <RightActions {...props} />
    </Flex>
  );
});

export default LoadHeader;

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import { makeSelectDailyLog } from '../../selectors';
import { getDailyLogListRequest } from '../../actions';
import { TableCell } from '../../components/table-cells';
import FormGroupTable from '../../components/form-group-table';
//////////////////////////////////////////////////

export const settings = {
  [GC.FIELD_SOURCE]: {
    width: 120,
    name: 'titles:source',
  },
  [GC.FIELD_LOG_DATE]: {
    width: 120,
    name: 'titles:date',
  },
  [GC.FIELD_TOTAL_DISTANCE]: {
    width: 120,
    name: 'titles:total-distance',
    customComponent: (row: Object) => {
      const { totalDistance, totalDistanceUom } = R.propOr({}, 'data', row);

      if (G.isNilOrEmpty(totalDistance)) return null;

      return <TableCell text={`${totalDistance} ${G.getUomLocale(totalDistanceUom)}`} />;
    },
  },
  [GC.FIELD_ODOMETER]: {
    width: 120,
    name: 'titles:odometer',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 150,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 150,
    name: 'titles:updated-by',
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
  },
};

const report = {
  fields: G.mapIndexed((name: string, sequence: number) => ({ name, sequence }), R.keys(settings)),
};

export const DailyLogList = (props: Object) => {
  const { tabsCollapsedView, primaryObjectGuid } = props;

  const dailyLog = useSelector(makeSelectDailyLog());

  const { offset, itemList, totalCount } = R.or(dailyLog, {});

  const dispatch = useDispatch();

  const handleGetDailyLogListRequest = (data: Object) => dispatch(getDailyLogListRequest(data));

  useEffect(() => {
    if (R.isNil(itemList)) {
      handleGetDailyLogListRequest({ offset: 0, limit: 20, [GC.FIELD_TRUCK_GUID]: primaryObjectGuid });
    }
  }, []);

  const pagination = { offset, limit: 20 };

  const additionalTableSettings = {
    minHeight: 570,
    emptyListPadding: '50px 0',
    maxHeight: `calc(100vh - ${G.ifElse(tabsCollapsedView, 681, 200)}px)`,
  };

  const handleLoadMoreEntities = () => handleGetDailyLogListRequest({
    ...pagination,
    [GC.FIELD_TRUCK_GUID]: primaryObjectGuid,
  });

  return (
    <FormGroupTable
      {...props}
      report={report}
      actionsPicker={[]}
      itemList={itemList}
      groupName='dailyLog'
      totalCount={totalCount}
      pagination={pagination}
      columnSettings={settings}
      hasFormGroupTitle={false}
      handleLoadMoreEntities={handleLoadMoreEntities}
      additionalTableSettings={additionalTableSettings}
    />
  );
};

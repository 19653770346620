import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { getBranchConfigsByNamesSuccess } from '../branch/actions';
// feature new-do
import {
  isPageCreateDO,
  getPrimaryReference,
  isSourceTypeEdiOrManual,
  createCustomerLoadNumber,
  getStopPointsLatLonStringsArray,
  mapRateInitChargesWithFuelIndexInfo,
} from '../new-do/helpers';
import {
  PAGE_TYPE_CREATE_DO,
  TAB_NAME_CLO_SUMMARY,
  PAGE_TYPE_CLO_TEMPLATE,
} from '../new-do/constants';
import {
  getNewItemFields,
  getDefaultDropFields,
  getNewContainerFields,
  getDefaultPickupFields,
} from '../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature lite-new-do
import * as A from './actions';
import { isValidBillToCustomerRateForm } from './validation';
import { defaultBillToFields, allCustomerFormFields, referenceFormInitData } from './settings';
//////////////////////////////////////////////////

// TODO: refactor with new-do common helpers

const staticData = {
  branchList: [],
  fromPage: null,
  loadGuid: null,
  hotOrder: false,
  orderType: null,
  leftActiveTad: 1,
  branchRefTypes: {},
  divisionPrefix: '',
  divisionGuid: null,
  branchConfigs: null,
  telDriverRate: null,
  ignoreWarnings: false,
  saveAndDuplicateQty: 0,
  validationErrors: null,
  customerLoadNumber: '',
  routeTemplateGuid: null,
  primaryRefSequence: null,
  validationWarnings: null,
  sharedAccessorialsList: [],
  loadType: GC.LOAD_TYPE_CLO,
  pageType: PAGE_TYPE_CREATE_DO,
  branchInfo: GC.EMPTY_OPTION_OBJECT,
  rightActiveTad: TAB_NAME_CLO_SUMMARY,
  primaryReference: GC.EMPTY_OPTION_OBJECT,
  referenceFormData: referenceFormInitData,
  // For Containers
  useContainers: false,
  branchDefaultItem: null,
  addedNewFirstDrop: false,
  addedNewFirstPickup: false,
  autoCreateContainerItem: false,
};

const createDOData = {
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  ratePreviewFiles: null,
  pageType: PAGE_TYPE_CREATE_DO,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  telCreationMode: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const templateData = {
  rateBackup: null,
  [GC.FIELD_RATE]: null,
  ratePreviewFiles: null,
  pageType: PAGE_TYPE_CLO_TEMPLATE,
  [GC.FIELD_LOAD_NUMBER_OF_LOADS]: 1,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  telCreationMode: GC.TEL_CREATION_MODE_SINGLE_TEL,
  [GC.SYSTEM_OBJECT_BILL_TO]: R.assoc('isValid', false, defaultBillToFields),
};

const createNewDOPickup = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultPickupFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
});

const createNewDODrop = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultDropFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
});

const createNewStop = (stopType: string, stopOrder: string, store: Object) => {
  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewDOPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);

    return R.assoc('formData', newFormData, newStop);
  }
  const newStop = createNewDODrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);

  return R.assoc('formData', newFormData, newStop);
};

const createNewStopWithContainers = (stopType: string, stopOrder: string, store: Object) => {
  const { branchDefaultItem } = store;

  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewDOPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);
    const firstPickupContainerInternalId = P.$get('stops.1.formData.pickedUpContainers.0.containerInternalId', store);
    const itemInternalId = G.generateGuid();

    const item = {
      ...branchDefaultItem,
      itemInternalId,
      containerInternalId: firstPickupContainerInternalId,
    };

    const formDataWithItem = R.assoc('items', R.of(Array, item), newFormData);

    return R.assoc('formData', formDataWithItem, newStop);
  }

  const newStop = createNewDODrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);

  return R.assoc('formData', newFormData, newStop);
};

const pageTypeToInitialDataMap = {
  [PAGE_TYPE_CREATE_DO]: createDOData,
  [PAGE_TYPE_CLO_TEMPLATE]: templateData,
};

const getInitialState = (pageType: string, sourceType: string = GC.CREATE_DO_SOURCE_TYPE_MANUAL) => {
  const pickup1 = createNewDOPickup(1);
  const drop1 = createNewDODrop(2);
  const initial = R.prop(pageType, pageTypeToInitialDataMap);
  const initData = R.mergeRight(staticData, initial);

  return R.mergeRight(
    initData,
    {
      sourceType,
      stops: {
        1: pickup1,
        2: drop1,
      },
    },
  );
};

const setValueToStore = (state: Object, { path, value }: Object) => (
  P.$set(path, value, state)
);

const setActiveLeftTab = (state: Object, data: Object) => (
  P.$set('leftActiveTad', data, state)
);

const setActiveRightTab = (state: Object, data: Object) => (
  P.$set('rightActiveTad', data, state)
);

const setNumberOfLoads = (state: Object, data: string) => (
  P.$set('numberOfLoads', data, state)
);

const toggleHotOrder = (state: Object) => (
  P.$toggle('hotOrder', state)
);

const setBranchBillTo = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const payTermsConfig = G.getConfigValueFromStore(GC.CLO_GENERAL_PAYMENT_TERMS, state.branchConfigs);
  const payTerms = G.ifElse(
    G.isNotNilAndNotEmpty(payTermsConfig),
    payTermsConfig,
    '',
  );

  return P.$set('billTo', R.assoc(GC.FIELD_PAYMENT_TERM, payTerms, data), state);
};

const setBranchShipFrom = (state: Object, data: Object) => (
  P.$set(
    'stops.1.formData',
    R.mergeRight(
      R.mergeRight(R.pathOr({}, ['stops', 1, 'formData'], state), data),
      G.getBranchPickupDateTimeDefaultValues(state.branchConfigs),
    ),
    state,
  )
);

const setBranchShipTo = (state: Object, data: Object) => (
  P.$set(
    'stops.2.formData',
    R.mergeRight(
      R.mergeRight(R.pathOr({}, ['stops', 2, 'formData'], state), data),
      G.getBranchDropDateTimeDefaultValues(state.branchConfigs),
    ),
    state,
  )
);

const setBranchDefaultItem = (state: Object, data: Object) => {
  const itemInternalId = G.generateGuid();
  const itemWithInternalId = R.assoc(GC.FIELD_ITEM_INTERNAL_ID, itemInternalId, data);
  const configs = G.getItemFromWindow('amousNewDoBranchConfigs');
  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, configs);

  if (G.isTrue(useContainers)) return P.$set('branchDefaultItem', data, state);

  return P.$all(
    P.$set('stops.1.formData.items', R.of(Array, itemWithInternalId)),
    P.$set('stops.2.formData.items', R.of(Array, itemInternalId)),
    state,
  );
};

const stopDatesApptsFields = [
  GC.FIELD_LOAD_FCFS,
  GC.FIELD_STOP_NUMBER,
  GC.FIELD_LOAD_EVENT_LATE_TIME,
  GC.FIELD_LOAD_EVENT_LATE_DATE,
  GC.FIELD_LOAD_EVENT_EARLY_TIME,
  GC.FIELD_LOAD_EVENT_EARLY_DATE,
  GC.FIELD_LOAD_APPOINTMENT_DATE,
  GC.FIELD_LOAD_APPOINTMENT_NUMBER,
  GC.FIELD_LOAD_APPOINTMENT_REQUIRED,
  GC.FIELD_LOAD_APPOINTMENT_LATE_TIME,
  GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME,
];


const getContainersFromEvent = (event: Object) => {
  const droppedContainers = R.pathOr([], [GC.FIELD_STOP_DROPPED_CONTAINERS], event);
  const pickedUpContainers = R.pathOr([], [GC.FIELD_STOP_PICKED_UP_CONTAINERS], event);

  return { droppedContainers, pickedUpContainers };
};

const mapDataEvent = (event: Object, withOutData: boolean = false) => {
  const {
    items,
    location,
    eventType,
    internalId,
    references,
    stopNumber,
    cloEventIndex,
    droppedTrailerGuids,
    pickedUpTrailerGuids,
  } = event;

  const datesAppts = R.pick(stopDatesApptsFields, event);
  const containers = getContainersFromEvent(event);

  const createStopFunc = G.ifElse(
    G.isEventTypePickup(eventType),
    createNewDOPickup,
    createNewDODrop,
  );

  const itemsToUse = G.ifElse(
    G.isEventTypePickup(eventType),
    items,
    R.map((item: Object) => R.prop(GC.FIELD_ITEM_INTERNAL_ID, item), items),
  );

  const init = createStopFunc(cloEventIndex);

  if (withOutData) {
    const newFormData = {
      ...init.formData,
      ...G.resetLocationTypeFromDropdownOption(location),
      ...containers,
      items: itemsToUse,
      droppedTrailerGuids,
      pickedUpTrailerGuids,
    };

    return R.assoc('formData', newFormData, init);
  }

  const newFormData = {
    ...init.formData,
    ...G.resetLocationTypeFromDropdownOption(location),
    ...datesAppts,
    ...containers,
    internalId,
    references,
    stopNumber,
    items: itemsToUse,
    droppedTrailerGuids,
    pickedUpTrailerGuids,
  };

  return R.assoc('formData', newFormData, init);
};

const mapDataEvents = (events: Array, withOutData: boolean = false) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ORDER)),
  R.map((item: Object) => mapDataEvent(item, withOutData)),
)(events);

const getBillToFromData = (state: Object, dataBillTo: Object, branchConfigs: Object) => {
  const { billTo } = state;

  const payTerms = G.getConfigValueFromStore(GC.CLO_GENERAL_PAYMENT_TERMS, branchConfigs);
  const billToWithTerms = R.assoc(GC.FIELD_PAYMENT_TERM, payTerms, billTo);

  if (G.isObject(dataBillTo)) {
    return G.resetLocationTypeFromDropdownOption(R.mergeRight(billToWithTerms, dataBillTo));
  }

  return G.resetLocationTypeFromDropdownOption(billToWithTerms);
};

const getRateFromData = (rate: Object, branchConfigs: Object, isValid: boolean, billTo: Object, shared: Array) => {
  if (G.isNilOrEmpty(rate)) return null;

  const currency = G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs);
  const mode = G.getConfigValueFromStore(GC.CLO_GENERAL_RATE_TRANSPORTATION_MODE, branchConfigs);

  const rateContact = R.pick(
    [GC.FIELD_FAX, GC.FIELD_PHONE, GC.FIELD_EMAIL, GC.FIELD_CONTACT_NAME],
    rate,
  );

  const init = {
    isValid,
    rateContact,
    [GC.FIELD_MODE]: mode,
    [GC.FIELD_CURRENCY]: currency,
  };

  const rateWithCharges = R.mergeRight(init, mapRateInitChargesWithFuelIndexInfo(rate, shared));

  return R.mergeRight(rateWithCharges, billTo);
};

const getReferenceFormDataFromTemplate = (template: Object) => ({
  isValid: true,
  [GC.FIELD_LOAD_REFERENCES]: R.map(
    (item: Object) => R.pick([GC.FIELD_VALUE, GC.FIELD_REFERENCE_TYPE_GUID], item),
    R.pathOr([], [GC.FIELD_LOAD_REFERENCES], template),
  ),
  [GC.FIELD_FAST_LOAD]: R.path([GC.FIELD_FAST_LOAD], template),
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], template),
});

const getBranchConfigsWithTemplateSuccess = (state: Object, data: Object) => {
  const { branchGuid, templateData, branchConfigs } = data;

  const { hot, rate, billTo, events, orderType, divisionGuid } = templateData;

  const { branchList, sharedAccessorialsList } = state;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);

  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };

  const billToUse = getBillToFromData(state, billTo, branchConfigs);

  const rateToUse = getRateFromData(rate, branchConfigs, false, billToUse, sharedAccessorialsList);


  const orderTypeToUse = G.getDropdownOptionGuidFromObject(orderType);

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('hotOrder', hot),
    P.$set('rate', rateToUse),
    P.$set('rateBackup', rateToUse),
    P.$set('templateData', templateData),
    P.$set('divisionGuid', divisionGuid),
    P.$set('stops', mapDataEvents(events)),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('referenceFormData', getReferenceFormDataFromTemplate(templateData)),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], templateData)),
    P.$set('orderType', R.or(orderTypeToUse, G.getPropFromObject('orderType', state))),
    state,
  );
};

const getReferenceFormDataFromDuplicateDO = (data: Object, branchConfigs: Object) => {
  let references = [];

  const defaultReferences = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_REFERENCES, branchConfigs);

  if (G.isNotNilAndNotEmpty(defaultReferences)) {
    references = R.map((item: string) => ({
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_REFERENCE_TYPE_GUID]: item,
    }), defaultReferences);
  }

  return ({
    isValid: true,
    [GC.FIELD_LOAD_REFERENCES]: references,
    [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], data),
  });
};

const getBranchConfigsOnDuplicateDOSuccess = (state: Object, data: Object) => {
  const { branchGuid, branchConfigs, duplicateDOData } = data;

  const { rate, billTo, events } = duplicateDOData;

  const { branchList } = state;

  const branchInfo = R.find(R.propEq(branchGuid, GC.FIELD_GUID), branchList);

  const branchInfoMapped = {
    value: R.prop(GC.FIELD_GUID, branchInfo),
    label: R.prop(GC.FIELD_NAME, branchInfo),
  };

  const billToUse = getBillToFromData(state, billTo, branchConfigs);

  const rateToUse = getRateFromData(rate, branchConfigs, true, billToUse);

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  return P.$all(
    P.$set('rate', rateToUse),
    P.$set('rateBackup', rateToUse),
    P.$set('duplicateDO', duplicateDOData),
    P.$set('branchInfo', branchInfoMapped),
    P.$set('branchConfigs', branchConfigs),
    P.$set('stops', mapDataEvents(events, true)),
    P.$set('sourceType', GC.CREATE_DO_SOURCE_TYPE_COPY),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], duplicateDOData)),
    P.$set('referenceFormData', getReferenceFormDataFromDuplicateDO(duplicateDOData, branchConfigs)),
    state,
  );
};

const mapEventsWithTimes = (events: Array) => (
  R.map((item: Object) => G.setEventEarlyLateTimeFromEarlyLateDate2(item),
  events,
));

const copyTemplateDataToStore = (state: Object, data: Object) => {
  const { branchConfigs, sharedAccessorialsList } = state;

  const { rate, billTo, events, orderType } = data;

  const eventsToUse = mapEventsWithTimes(events);

  const billToUse = getBillToFromData(state, billTo, branchConfigs);

  const rateToUse = getRateFromData(rate, branchConfigs, false, billToUse, sharedAccessorialsList);

  const orderTypeToUse = G.getDropdownOptionGuidFromObject(orderType);

  return P.$all(
    P.$set('rate', rateToUse),
    P.$set('templateData', data),
    P.$set('rateBackup', rateToUse),
    P.$set('stops', mapDataEvents(eventsToUse)),
    P.$set('referenceFormData', getReferenceFormDataFromTemplate(data)),
    P.$set('numberOfLoads', R.pathOr(1, [GC.FIELD_NUMBER_OF_LOADS], data)),
    P.$set('orderType', R.or(orderTypeToUse, G.getPropFromObject('orderType', state))),
    state,
  );
};

const setItemOrContainer = (useContainers: any, branchConfigs: Object, state: Object) => {
  if (G.isTrue(useContainers)) {
    const withContainers = R.assocPath(
      [GC.FIELD_STOP_PICKED_UP_CONTAINERS, 0],
      getNewContainerFields(),
      state,
    );

    return R.assoc('items', [], withContainers);
  }

  return R.assocPath(
    ['items', 0],
    getNewItemFields(branchConfigs),
    state,
  );
};

const setDefaultLocationTypeToValues = (values: Object, locationType: any) => {
  if (G.isNilOrEmpty(locationType)) return values;

  return R.assoc(GC.FIELD_LOCATION_TYPE, locationType, values);
};

const getBranchConfigsSuccess = (state: Object, data: Object) => {
  if (R.includes(
    state.pageType,
    [
      PAGE_TYPE_CLO_TEMPLATE,
    ],
  )) return state;

  const { branchRefTypes } = state;

  const { branchConfigs } = data;

  const primaryReference = getPrimaryReference(branchConfigs, branchRefTypes);

  const useContainers = G.getConfigValueFromStore(GC.CLO_GENERAL_USE_CONTAINERS, branchConfigs);
  const autoCreateContainerItem = G.getConfigValueFromStore(GC.CLO_GENERAL_AUTO_CREATE_CONTAINER_ITEM, branchConfigs);
  const defaultReferences = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_REFERENCES, branchConfigs);
  const defaultEquipment = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_EQUIPMENT, branchConfigs);
  const defaultOrderType = G.getConfigValueFromStore(GC.CLO_GENERAL_DEFAULT_ORDER_TYPE, branchConfigs);
  const defaultPickupLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_PICKUP_LOCATION_TYPE, branchConfigs);
  const defaultSpecialInstruction = G.getConfigValueFromStore(GC.CLO_DEFAULT_SPECIAL_INSTRUCTION, branchConfigs);
  const defaultDropLocationType = G.getConfigValueFromStore(GC.CLO_DEFAULT_DROP_LOCATION_TYPE, branchConfigs);

  const withPickupDates = R.mergeRight(
    R.pathOr({}, ['stops', 1, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchPickupDateTimeDefaultValues(branchConfigs), defaultPickupLocationType),
  );

  const withDefaultItemOrContainer = setItemOrContainer(useContainers, branchConfigs, withPickupDates);

  const withDropDates = R.mergeRight(
    R.pathOr({}, ['stops', 2, 'formData'], state),
    setDefaultLocationTypeToValues(G.getBranchDropDateTimeDefaultValues(branchConfigs), defaultDropLocationType),
  );

  G.setItemToWindow('amousNewDoBranchConfigs', branchConfigs);

  if (G.isNotNilAndNotEmpty(defaultReferences)) {
    const references = R.map((item: string) => ({
      [GC.FIELD_VALUE]: null,
      [GC.FIELD_REFERENCE_TYPE_GUID]: item,
    }), defaultReferences);

    return P.$all(
      P.$set('orderType', defaultOrderType),
      P.$set('useContainers', useContainers),
      P.$set('branchConfigs', branchConfigs),
      P.$set('stops.2.formData', withDropDates),
      P.$set('primaryReference', primaryReference),
      P.$set('referenceFormData.references', references),
      P.$set('stops.1.formData', withDefaultItemOrContainer),
      P.$set('autoCreateContainerItem', autoCreateContainerItem),
      P.$set('billTo', getBillToFromData(state, null, branchConfigs)),
      P.$set('referenceFormData.equipment', R.or(defaultEquipment, null)),
      P.$set('referenceFormData.specialInstructions', R.or(defaultSpecialInstruction, null)),
      state,
    );
  }

  return P.$all(
    P.$set('orderType', defaultOrderType),
    P.$set('useContainers', useContainers),
    P.$set('branchConfigs', branchConfigs),
    P.$set('stops.2.formData', withDropDates),
    P.$set('primaryReference', primaryReference),
    P.$set('stops.1.formData', withDefaultItemOrContainer),
    P.$set('autoCreateContainerItem', autoCreateContainerItem),
    P.$set('billTo', getBillToFromData(state, null, branchConfigs)),
    P.$set('referenceFormData.equipment', R.or(defaultEquipment, null)),
    P.$set('referenceFormData.specialInstructions', R.or(defaultSpecialInstruction, null)),
    state,
  );
};

const setEdiFileToStore = (state: Object, data: Object) => (
  P.$set('ediFile', data, state)
);

const setRatePreviewFiles = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data)) {
    G.setItemToWindow('amousCreateDoRateConfirmationFiles', data);

    return P.$set('ratePreviewFiles', data, state);
  }

  const currentFiles = R.or(G.getItemFromWindow('amousCreateDoRateConfirmationFiles'), []);
  const files = R.concat(currentFiles, data);

  const filesWithPreview = R.map((file: Object) => R.assoc('preview', URL.createObjectURL(file), file), files);

  G.setItemToWindow('amousCreateDoRateConfirmationFiles', files);

  return P.$set('ratePreviewFiles', filesWithPreview, state);
};

const setFormDataToStop = (state: Object, data: Object) => P.$all(
  P.$set(`stops.${data.stopOrder}.isValid`, data.isValid),
  P.$set(`stops.${data.stopOrder}.formData`, data.formData),
  state,
);

const setFormDataToStore = (state: Object, data: Object) => {
  const { dataName, formData } = data;

  if (R.equals(dataName, 'rate')) {
    return P.$all(
      P.$set('rate', formData),
      P.$set('rateBackup', formData),
      state,
    );
  }

  return P.$set(dataName, formData, state);
};

const setCustomerRateToStore = (state: Object, data: Object) => (
  P.$set('rate', data, state)
);

const setCustomerRateChargesToStore = (state: Object, data: Object) => {
  const { rate, telDriverRate } = state;

  if (G.isNilOrEmpty(rate)) {
    const fields = R.map(R.prop('fieldName'), allCustomerFormFields);
    const rate = R.assoc(
      GC.FIELD_CHARGES,
      data,
      R.pick(fields, telDriverRate),
    );

    return P.$set('rate', rate, state);
  }

  return P.$all(
    P.$set('rate.charges', data),
    P.$set('rateBackup.charges', data),
    state,
  );
};

const setDriverRateToStore = (state: Object, data: Object) => (
  P.$set('telDriverRate', data, state)
);

const addNewStopToStore = (state: Object, stopType: Object) => {
  const {
    stops,
    pageType,
    sourceType,
    useContainers,
    branchDefaultItem,
    addedNewFirstDrop,
    addedNewFirstPickup,
    autoCreateContainerItem,
  } = state;

  if (R.and(
    G.isAllTrue(
      useContainers,
      autoCreateContainerItem,
      isPageCreateDO(pageType),
      G.isEventTypePickup(stopType),
      isSourceTypeEdiOrManual(sourceType),
      G.isNotNilAndNotEmpty(branchDefaultItem),
    ),
    G.isAllFalse(
      addedNewFirstDrop,
      addedNewFirstPickup,
    ),
  )) {
    const stopOrder = 2;
    const stop = createNewStopWithContainers(stopType, stopOrder, state);
    const stopsWithAdded = R.insert(1, stop, R.values(stops));
    const newStops = R.compose(
      R.indexBy(R.prop(GC.FIELD_ORDER)),
      G.mapIndexed((stop: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), stop)),
    )(stopsWithAdded);

    const firstPickupContainerInternalId = P.$get('1.formData.pickedUpContainers.0.containerInternalId', newStops);
    const secondPickupItemInternalId = P.$get('2.formData.items.0.itemInternalId', newStops);
    const secondPickupEventEarlyDate = P.$get('2.formData.eventEarlyDate', newStops);
    const secondPickupEventLateDate = P.$get('2.formData.eventLateDate', newStops);
    const dropEventEarlyDate = P.$get('3.formData.eventEarlyDate', newStops);
    const dropEventLateDate = P.$get('3.formData.eventLateDate', newStops);

    const newStops2 = P.$all(
      P.$set('3.formData.items', R.of(Array, secondPickupItemInternalId)),
      P.$set('3.formData.droppedContainers', R.of(Array, firstPickupContainerInternalId)),
      P.$set('3.formData.eventEarlyDate', secondPickupEventEarlyDate),
      P.$set('3.formData.eventLateDate', secondPickupEventLateDate),
      P.$set('2.formData.eventEarlyDate', dropEventEarlyDate),
      P.$set('2.formData.eventLateDate', dropEventLateDate),
      newStops,
    );

    return P.$all(
      P.$set('stops', newStops2),
      P.$toggle('addedNewFirstPickup'),
      state,
    );
  }

  if (R.and(
    G.isAllTrue(
      useContainers,
      autoCreateContainerItem,
      isPageCreateDO(pageType),
      G.isEventTypeDrop(stopType),
      isSourceTypeEdiOrManual(sourceType),
      G.isNotNilAndNotEmpty(branchDefaultItem),
    ),
    G.isAllFalse(
      addedNewFirstDrop,
      addedNewFirstPickup,
    ),
  )) {
    const stopOrder = 3;
    const stop = createNewStopWithContainers(stopType, stopOrder, state);
    const stopsWithAdded = R.append(stop, R.values(stops));
    const newStops = R.compose(
      R.indexBy(R.prop(GC.FIELD_ORDER)),
      G.mapIndexed((stop: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), stop)),
    )(stopsWithAdded);

    const firstPickupContainerInternalId = P.$get('1.formData.pickedUpContainers.0.containerInternalId', newStops);

    const itemInternalId = G.generateGuid();
    const item = {
      ...branchDefaultItem,
      itemInternalId,
      containerInternalId: firstPickupContainerInternalId,
    };

    const newStops2 = P.$set('1.formData.items', R.of(Array, item), newStops);

    const newStops3 = P.$all(
      P.$set('2.formData.items', R.of(Array, itemInternalId)),
      P.$set('3.formData.droppedContainers', R.of(Array, firstPickupContainerInternalId)),
      newStops2,
    );

    return P.$all(
      P.$set('stops', newStops3),
      P.$toggle('addedNewFirstDrop'),
      state,
    );
  }

  const stopOrder = R.inc(R.length(R.values(state.stops)));
  const stop = createNewStop(stopType, stopOrder, state);

  return P.$set(`stops.${stopOrder}`, stop, state);
};

const removeStopFromStore = (state: Object, stopOrder: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    G.mapIndexed((item: Object, index: number) => R.assoc(GC.FIELD_ORDER, R.inc(index), item)),
    R.reject((item: Object) => R.equals(R.prop(GC.FIELD_ORDER, item), stopOrder)),
    R.sortBy(R.prop(GC.FIELD_ORDER)),
    R.values(),
  )(state.stops);

  return P.$all(
    P.$set('stops', stops),
    P.$set('leftActiveTad', 1),
    state,
  );
};

const removeItemFromStore = (state: Object, itemInternalId: Object) => {
  const stops = R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    R.map((stop: Object) => {
      const { formData: { items }, eventType } = stop;

      if (G.isEventTypeDrop(eventType)) {
        const newItems = R.without(itemInternalId, items);

        return R.assocPath(['formData', GC.FIELD_LOAD_ITEMS], newItems, stop);
      }

      return stop;
    }),

    R.values(),
  )(state.stops);

  return P.$set('stops', stops, state);
};

const setReorderedStops = (state: Object, data: Object) => (
  P.$set('stops', data, state)
);

const recalculateLoadDistancesSuccess = (state: Object, data: Object) => {
  const { distance, stopPoints, mappedStops } = data;

  if (G.isNotNilAndNotEmpty(distance)) {
    const rate = R.mergeRight(state.rateBackup, distance);

    const rateWithValid = R.assoc('isValid', isValidBillToCustomerRateForm(rate), rate);

    const stopPointsStrings = getStopPointsLatLonStringsArray(stopPoints);

    return P.$all(
      P.$set('stops', mappedStops),
      P.$set('rate', rateWithValid),
      P.$set('rateBackup', rateWithValid),
      P.$set('stopPointsStrings', stopPointsStrings),
      state,
    );
  }

  return P.$set('stops', mappedStops, state);
};

const getBranchListSuccess = (state: Object, data: Array) => (
  P.$set('branchList', data, state)
);

const getPrimaryRefSequenceSuccess = (state: Object, data: string) => P.$all(
  P.$set('primaryRefSequence', data),
  P.$set('customerLoadNumber', createCustomerLoadNumber({
    sequence: data,
    configs: state.branchConfigs,
    divisionPrefix: state.divisionPrefix,
  })),
  state,
);

const setCustomerLoadNumberByBranchConfigs = (state: Object, { configs }: Object) => {
  const divisionPrefix = G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
    configs,
  );

  return P.$all(
    P.$set('divisionPrefix', divisionPrefix),
    P.$set('customerLoadNumber', createCustomerLoadNumber({
      divisionPrefix,
      configs: state.branchConfigs,
      sequence: state.primaryRefSequence,
    })),
    state,
  );
};

const getAllAvBranchRefTypesSuccess = (state: Object, { scopeName, data }: Object) => (
  P.$set(`branchRefTypes.${scopeName}`, data, state)
);

const setCustomerLoadNumber = (state: Object, value: string) => (
  P.$set('customerLoadNumber', value, state)
);

const setDivisionGuid = (state: Object, value: string) => (
  P.$set('divisionGuid', value, state)
);

const setPageTypeToStore = (state: Object, value: string) => (
  P.$set('pageType', value, state)
);

const saveAndDuplicateSuccess = (state: Object) => (
  P.$all(
    P.$set('ignoreWarnings', false),
    P.$set('validationWarnings', null),
    P.$set('referenceFormData.references', []),
    P.$set('saveAndDuplicateQty', R.inc(state.saveAndDuplicateQty)),
    state,
  )
);

const setBranchInfo = (state: Object, data: object) => G.ifElse(
  R.isNil(data),
  P.$set('branchInfo', GC.EMPTY_OPTION_OBJECT, state),
  P.$set('branchInfo', data, state),
);

const setPrimaryReference = (state: Object, data: object) => (
  G.ifElse(
    R.isNil(data),
    P.$set('primaryReference', GC.EMPTY_OPTION_OBJECT, state),
    P.$set('primaryReference', data, state),
  )
);

const setValidationErrors = (state: Object, data: Object) => {
  if (G.isNotNilAndNotEmpty(data)) {
    return P.$set('validationErrors', data, state);
  }

  return state;
};

const setValidationErrorsAndWarnings = (state: Object, { errors, warnings }: Object) => (
  P.$all(
    P.$set('validationErrors', errors),
    P.$set('validationWarnings', warnings),
    state,
  )
);

const removeValidationErrors = (state: Object) => (
  P.$set('validationErrors', null, state)
);

const removeValidationWarnings = (state: Object) => (
  P.$set('validationWarnings', null, state)
);

const setIgnoreWarnings = (state: Object, value: boolean = false) => (
  P.$all(
    P.$set('ignoreWarnings', value),
    P.$set('validationWarnings', G.ifElse(G.isTrue(value), null, state.validationWarnings)),
    state,
  )
);

const cleanNewDOStore = (state: Object, data: string) => {
  const guid = R.path([GC.FIELD_GUID], data);
  const pageType = R.pathOr(state.pageType, ['pageType'], data);
  const sourceType = G.getPropFromObject(GC.FIELD_SOURCE_TYPE, data);

  let customerInfo = GC.EMPTY_OPTION_OBJECT;

  if (G.isNotNil(guid)) {
    customerInfo = {
      value: guid,
      label: data[GC.FIELD_BRANCH_NAME],
    };
  }
  const branchList = P.$get('branchList', state);

  return P.$all(
    P.$set('branchList', branchList),
    P.$set('branchInfo', customerInfo),
    getInitialState(pageType, sourceType),
  );
};

const setInitialStateToStore = () => (
  getInitialState(PAGE_TYPE_CREATE_DO)
);

const getBranchSharedAccessorialListSuccess = (state: Object, data: Array) => (
  P.$set('sharedAccessorialsList', data, state)
);

export default createReducer({
  [A.setBranchInfo]: setBranchInfo,
  [A.toggleHotOrder]: toggleHotOrder,
  [A.setDivisionGuid]: setDivisionGuid,
  [A.setBranchShipTo]: setBranchShipTo,
  [A.cleanNewDOStore]: cleanNewDOStore,
  [A.setBranchBillTo]: setBranchBillTo,
  [A.setValueToStore]: setValueToStore,
  [A.setNumberOfLoads]: setNumberOfLoads,
  [A.setActiveLeftTab]: setActiveLeftTab,
  [A.setEdiFileToStore]: setEdiFileToStore,
  [A.setActiveRightTab]: setActiveRightTab,
  [A.setBranchShipFrom]: setBranchShipFrom,
  [A.setReorderedStops]: setReorderedStops,
  [A.setFormDataToStop]: setFormDataToStop,
  [A.addNewStopToStore]: addNewStopToStore,
  [A.setIgnoreWarnings]: setIgnoreWarnings,
  [A.setPageTypeToStore]: setPageTypeToStore,
  [A.setFormDataToStore]: setFormDataToStore,
  [A.setRatePreviewFiles]: setRatePreviewFiles,
  [A.removeItemFromStore]: removeItemFromStore,
  [A.setValidationErrors]: setValidationErrors,
  [A.removeStopFromStore]: removeStopFromStore,
  [A.setPrimaryReference]: setPrimaryReference,
  [A.setBranchDefaultItem]: setBranchDefaultItem,
  [A.getBranchListSuccess]: getBranchListSuccess,
  [A.setDriverRateToStore]: setDriverRateToStore,
  [A.setCustomerLoadNumber]: setCustomerLoadNumber,
  [A.setInitialStateToStore]: setInitialStateToStore,
  [A.removeValidationErrors]: removeValidationErrors,
  [A.setCustomerRateToStore]: setCustomerRateToStore,
  [A.saveAndDuplicateSuccess]: saveAndDuplicateSuccess,
  [A.getBranchConfigsSuccess]: getBranchConfigsSuccess,
  [A.copyTemplateDataToStore]: copyTemplateDataToStore,
  [A.removeValidationWarnings]: removeValidationWarnings,
  [A.getPrimaryRefSequenceSuccess]: getPrimaryRefSequenceSuccess,
  [A.setCustomerRateChargesToStore]: setCustomerRateChargesToStore,
  [A.getAllAvBranchRefTypesSuccess]: getAllAvBranchRefTypesSuccess,
  [A.setValidationErrorsAndWarnings]: setValidationErrorsAndWarnings,
  [A.recalculateLoadDistancesSuccess]: recalculateLoadDistancesSuccess,
  [A.getBranchConfigsWithTemplateSuccess]: getBranchConfigsWithTemplateSuccess,
  [A.getBranchConfigsOnDuplicateDOSuccess]: getBranchConfigsOnDuplicateDOSuccess,
  [A.getBranchSharedAccessorialListSuccess]: getBranchSharedAccessorialListSuccess,
  [getBranchConfigsByNamesSuccess]: setCustomerLoadNumberByBranchConfigs,
}, getInitialState(PAGE_TYPE_CREATE_DO));

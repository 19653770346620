import * as R from 'ramda';
import * as Yup from 'yup';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
//////////////////////////////////////////////////

const settings = {
  [GC.FIELD_CERTIFICATION_TYPE]: {
    width: 150,
    isRequired: true,
    fieldType: 'select',
    name: 'titles:type',
    options: 'certificationTypeOptions',
    customComponent: R.path(['data', GC.FIELD_CERTIFICATION_TYPE, GC.FIELD_DISPLAYED_VALUE]),
  },
  [GC.FIELD_CERTIFICATION_NAME]: {
    width: 150,
    isRequired: true,
    fieldType: 'select',
    name: 'titles:name',
    options: 'certificationNameOptions',
    customComponent: R.path(['data', GC.FIELD_CERTIFICATION_NAME, GC.FIELD_DISPLAYED_VALUE]),

  },
  [GC.FIELD_CERTIFICATION_DATE]: {
    width: 150,
    isRequired: true,
    fieldType: 'datePicker',
    name: 'titles:certification-date',
    maxDate: (props: Object) => {
      const expirationDate = R.path(['values', GC.FIELD_EXPIRATION_DATE], props);

      return R.and(G.isNotNilAndNotEmpty(expirationDate), G.makeMomentInstance(expirationDate));
    },
  },
  [GC.FIELD_EXPIRATION_DATE]: {
    width: 150,
    isRequired: true,
    fieldType: 'datePicker',
    name: 'titles:expiration-date',
    minDate: (props: Object) => {
      const certificationDate = R.path(['values', GC.FIELD_CERTIFICATION_DATE], props);

      return G.isNotNilAndNotEmpty(certificationDate) ? G.makeMomentInstance(certificationDate) : null;
    },
  },
  [GC.FIELD_NOTES]: {
    width: 150,
    name: 'titles:notes',
    fieldType: 'textarea',
    additionalInputWrapperStyles: { width: 400 },
  },
  [GC.FIELD_CERTIFICATION_OOS_ITEM]: {
    width: 100,
    type: 'boolean',
    fieldType: 'toggle',
    defaultValue: false,
    name: 'titles:oos-item',
    additionalInputWrapperStyles: { width: 400 },
  },
  [GC.FIELD_DOCUMENT_UPLOAD]: {
    width: 500,
    fieldType: 'file',
    name: 'titles:document-template',
    additionalInputWrapperStyles: { width: 400 },
    customComponent: R.path(['data', GC.FIELD_FILE_NAME]),
  },
};

const validationSchema = Yup.lazy(({ fileUri }: Object) => Yup.object().shape({
  [GC.FIELD_EXPIRATION_DATE]: G.yupStringRequired,
  [GC.FIELD_CERTIFICATION_TYPE]: G.yupStringRequired,
  [GC.FIELD_CERTIFICATION_NAME]: G.yupStringRequired,
  [GC.FIELD_CERTIFICATION_DATE]: G.yupStringRequired,
  [GC.FIELD_NOTES]: Yup.string().nullable(true).max(500, G.getShouldBeFromToCharLocaleTxt(0, 500)),
  [GC.FIELD_DOCUMENT_UPLOAD]: G.ifElse(G.isNotNil(fileUri), G.yupStringRequired, G.yupStringNotRequired),
}));

const defaultInputWrapperStyles = {
  mb: 25,
  width: 190,
};

const report = {
  fields: R.compose(
    G.mapIndexed((name: string, sequence: number) => ({ name, sequence })),
    R.keys,
  )(settings),
};

const defaultValues = R.map(({ defaultValue = null }: Object) => defaultValue, settings);

const fieldSettings = R.compose(
  R.values,
  R.mapObjIndexed(({
    name,
    minDate,
    maxDate,
    options,
    fieldType = 'text',
    additionalInputWrapperStyles,
  }: Object, fieldName: string) => ({
    options,
    minDate,
    maxDate,
    fieldName,
    label: R.of(Array, name),
    [GC.FIELD_TYPE]: fieldType,
    inputWrapperStyles: { ...defaultInputWrapperStyles, ...additionalInputWrapperStyles },
  })),
)(settings);

const columnSettings = R.map(R.pick(['name', 'width', 'type', 'customComponent']), settings);

export const certificationSettings = {
  report,
  defaultValues,
  fieldSettings,
  columnSettings,
  validationSchema,
  groupName: 'certifications',
  additionalTableSettings: { checkBoxCellWidth: 100 },
  itemTitleArr: ['titles:certification', 'Certification'],
  formGroupTitleArr: ['titles:certifications', 'Certifications'],
  actionsPicker: ['edit', 'remove', 'downloadDocument', 'previewDocument'],
  endpoints: {
    list: 'driverCertificationList',
    remove: 'getCurrentCertificateEndpoint',
    downloadDocument: 'downloadDriverCertificationFile',
  },
  downloadOptions: {
    requestParamsPicker: [GC.FIELD_FILE_URI],
    primaryObjectGuidKey: GC.FIELD_DRIVER_GUID,
    endpointName: 'downloadDriverCertificationFile',
  },
  fieldsWrapperStyles: {
    width: 400,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // helpers
  makeOptionsForSelect: ({ configGroup }: Object) => ({
    certificationNameOptions: R.path(['dropdownOptions', GC.DRIVER_CERTIFICATION_NAME], configGroup),
    certificationTypeOptions: R.path(['dropdownOptions', GC.DRIVER_CERTIFICATION_TYPE], configGroup),
  }),
  makeInitialValues: (initialValues: Object) => {
    const { fileName, certificationName, certificationType } = initialValues;

    return {
      ...initialValues,
      [GC.FIELD_DOCUMENT_UPLOAD]: fileName,
      [GC.FIELD_CERTIFICATION_NAME]: G.getDropdownOptionGuidFromObject(certificationName),
      [GC.FIELD_CERTIFICATION_TYPE]: G.getDropdownOptionGuidFromObject(certificationType),
    };
  },
  customCreateOrUpdateHandler: (props: Object, additionalOptions: Object) => {
    const { values, createOrUpdateEntityRequest } = props;

    createOrUpdateEntityRequest({
      method: 'post',
      additionalOptions,
      groupName: 'certifications',
      values: G.makeDataForDocument(values),
      endpoint: G.ifElse(
        G.isNotNilAndNotEmpty(G.getGuidFromObject(values)),
        endpointsMap.updateDriverCertification,
        endpointsMap.driverCertification,
      ),
    });
  },
};

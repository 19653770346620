import React from 'react';
import * as R from 'ramda';
// ui
import { Box, Flex } from '../../../ui';
// feature dashboards
import ChartFilters from './chart-filters';
import BarChartComponent from './bar-chart';
import SimpleBarChartComponent from './simple-bar-chart';
import Composed2LineChart from './composed-2-line-chart';
import ComposedAreaAndLineChart from './composed-area-and-line-chart';
import ComposedLineWithAreaChart from './composed-line-with-area-chart';
//////////////////////////////////////////////////

const Chart = (props: Object) => {
  const { chartType } = props;

  if (R.equals(chartType, 'BAR')) {
    return <BarChartComponent {...props} />;
  }

  if (R.equals(chartType, 'SIMPLE_BAR')) {
    return <SimpleBarChartComponent {...props} />;
  }

  if (R.equals(chartType, '2_LINE')) {
    return <Composed2LineChart {...props} />;
  }

  if (R.equals(chartType, 'AREA_AND_LINE')) {
    return <ComposedAreaAndLineChart {...props} />;
  }

  if (R.equals(chartType, 'LINE_WITH_AREA')) {
    return <ComposedLineWithAreaChart {...props} />;
  }

  return null;
};

const LargeCard = (props: Object) => {
  const { mb, title, height, filterChartType } = props;

  return (
    <Flex
      p={15}
      bg='white'
      width={1060}
      mb={mb || 20}
      borderRadius={10}
      alignItems='start'
      height={height || 460}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Flex>
        <Box
          fontSize={14}
          fontWeight='bold'
          color='dark.mainDark'
        >
          {title}
        </Box>
        { filterChartType && <ChartFilters chartType={filterChartType} /> }
      </Flex>
      <Chart {...props} />
    </Flex>
  );
};

export default LargeCard;

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, branch, compose, withHandlers, renderNothing } from 'react-recompose';
// common
import {
  makeSelectDocumentTemplates,
  makeSelectInitialDataLoaded } from '../../common/selectors';
// components
import { Table } from '../../components/table';
import { TitlePanel } from '../../components/title-panel';
import { EditReport } from '../../components/edit-report';
import { ConfirmComponent } from '../../components/confirm';
import { openModal, closeModal } from '../../components/modal/actions';
import { transformPropDataFromSelectToString } from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withFixedPopover } from '../../hocs';
// icons
import * as I from '../../svgs';
// ui
import { Box, PageWrapper, ZOrderWrapper } from '../../ui';
// feature ifta report
import RowActions from './components/row-actions';
import { tableSettings } from './components/details';
import { FILTER_PARAMS } from './settings/filter-params';
import { report, columnSettings } from './settings/column-settings';
import {
  makeSelectItemList,
  makeSelectUsedReport,
  makeSelectPagination,
  makesSelectTotalCount,
  makeSelectListLoading,
  makeSelectReportStatus,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
import {
  setReports,
  setUsedReport,
  changeActiveTab,
  cleanQuickFilter,
  printIFTARequest,
  removeIFTARequest,
  getIFTAXmlRequest,
  setTableTitleSort,
  getIftaListRequest,
  setTableTitleFilter,
  updateReportRequest,
  createReportRequest,
  setQuickFilterParams,
  setAdditionalFilters,
  resetListAndPagination,
  toggleIftaReportDetails,
  changeDefaultReportRequest,
} from './actions';
//////////////////////////////////////////////////

const additionalFilterOptions = [
  {
    value: GC.ADDITIONAL_FILTER_TYPE_TRUCK,
    label: G.getWindowLocale('titles:truck', 'Truck'),
  },
];

const renderRowActions = (data: Object, handleClickEditIcon: Function) => (
  <Box px={12} cursor='pointer' onClick={(e: Object) => handleClickEditIcon(e, data)}>
    {I.threeDots()}
  </Box>
);

const IftaListComponent = (props: Object) => {
  const {
    pl,
    title,
    height,
    loading,
    itemList,
    minHeight,
    maxHeight,
    totalCount,
    pagination,
    reportList,
    filterParams,
    tableMaxHeight,
    selectedReport,
    titleSortValues,
    tableTitleFilters,
    getIftaListRequest,
    fromTruckDetailPage,
    handleClickEditIcon,
    openedFromFleetProfile,
    handleTableTitleFilter,
    handleToggleIFTADetails,
    getAdditionalFilteredListRequest,
  } = props;

  const tableSettingsToUse = G.isTrue(openedFromFleetProfile)
    ? R.assoc('maxHeight', tableMaxHeight, tableSettings)
    : G.getTableSettingsWithMaxHeightByConditions({
      reportList,
      filterParams,
      tableSettings,
      selectedReport,
    });

  const actionButtons = [
    {
      iconName: 'trash',
      action: props.handleRemoveIFTA,
    },
  ];

  return (
    <PageWrapper pt={15} pb={15} pr={15} pl={pl} height={height} minHeight={minHeight} maxHeight={maxHeight}>
      <ZOrderWrapper zIndex={2}>
        <TitlePanel
          {...props}
          pt='0'
          withCount={true}
          noExportable={true}
          type={GC.IFTA_REPORT}
          popperWithCount={true}
          totalCount={totalCount}
          filterProps={FILTER_PARAMS}
          hiddenRightFilterInfo={false}
          additionalFilterOptions={additionalFilterOptions}
          withAdditionalFilters={R.not(fromTruckDetailPage)}
          getItemListRequest={() => getIftaListRequest(true)}
          additionalFiltersApplyHandler={getAdditionalFilteredListRequest}
          title={R.or(title, G.getWindowLocale('titles:ifta-list', 'IFTA Report'))}
        />
      </ZOrderWrapper>
      <ZOrderWrapper zIndex={1}>
        <Table
          report={report}
          itemList={itemList}
          totalCount={totalCount}
          pagination={pagination}
          useSearchableColumns={true}
          actionButtons={actionButtons}
          useNewTitleFilterInputs={true}
          columnSettings={columnSettings}
          toggle={handleToggleIFTADetails}
          titleSortValues={titleSortValues}
          tableSettings={tableSettingsToUse}
          tableTitleFilters={tableTitleFilters}
          handleLoadMoreEntities={getIftaListRequest}
          handleTableTitleFilter={handleTableTitleFilter}
          loading={R.and(loading, G.isNilOrEmpty(itemList))}
          renderRightStickedComponent={(data: Object) => renderRowActions(data, handleClickEditIcon)}
          filterProps={R.indexBy(R.prop(GC.FIELD_VALUE), transformPropDataFromSelectToString(FILTER_PARAMS))}
        />
      </ZOrderWrapper>
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  itemList: makeSelectItemList(state),
  loading: makeSelectListLoading(state),
  pagination: makeSelectPagination(state),
  totalCount: makesSelectTotalCount(state),
  selectedReport: makeSelectUsedReport(state),
  filterParams: makeSelectFilterParams(state),
  reportList: makeSelectAvailableReports(state),
  requestPending: makeSelectReportStatus(state),
  titleSortValues: makeSelectTitleSortValues(state),
  tableTitleFilters: makeSelectTableTitleFilters(state),
  initialDataLoaded: makeSelectInitialDataLoaded(state),
  documentTemplates: makeSelectDocumentTemplates(state),
});

const enhance = compose(
  connect(
    mapStateToProps,
    {
      openModal,
      closeModal,
      setReports,
      setUsedReport,
      changeActiveTab,
      cleanQuickFilter,
      printIFTARequest,
      setTableTitleSort,
      getIFTAXmlRequest,
      removeIFTARequest,
      getIftaListRequest,
      setTableTitleFilter,
      createReportRequest,
      updateReportRequest,
      setQuickFilterParams,
      setAdditionalFilters,
      resetListAndPagination,
      toggleIftaReportDetails,
      changeDefaultReportRequest,
    },
  ),
  withFixedPopover,
  withHandlers({
    handleListRequest: ({ getIftaListRequest }: Object) => (isInitial: boolean = false) => (
      getIftaListRequest(isInitial)
    ),
    handleTableTitleFilter: (props: Object) => {
      const { setTableTitleSort, getIftaListRequest, setTableTitleFilter, resetListAndPagination } = props;

      return G.handleTableTitleFilter({
        setTableTitleSort,
        setTableTitleFilter,
        resetListAndPagination,
        getItemListRequest: getIftaListRequest,
      });
    },
    getQuickFilteredListRequest: ({ getIftaListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getIftaListRequest(true);
    },
    handleCleanFilter: ({ cleanQuickFilter, getIftaListRequest }: Object) => () => {
      cleanQuickFilter();
      getIftaListRequest(true);
    },
    handleSelectReport: ({ reportList, setUsedReport, getIftaListRequest  }: Object) => (reportGuid: string) => {
      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);
      setUsedReport(selectedReport);
      getIftaListRequest(true);
    },
    handleEditReport: (props: Object) => (fields: Array) => {
      const {
        openModal,
        setUsedReport,
        selectedReport,
        requestPending,
        getIftaListRequest,
        createReportRequest,
        updateReportRequest,
      } = props;

      const component = (
        <EditReport
          fields={fields}
          disablePrompt={true}
          setReport={setUsedReport}
          usedReport={selectedReport}
          disableSetReportFields={true}
          requestPending={requestPending}
          createReportRequest={createReportRequest}
          updateReportRequest={updateReportRequest}
          onReportSet={() => getIftaListRequest(true)}
        />
      );

      const modal = G.getDefaultReportModal(component, 1150);

      openModal(modal);
    },
    handleRemoveIFTA: (props: Object) => (guid: string) => {
      const { openModal, removeIFTARequest } = props;

      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('messages:delete-confirmation', 'Do you want to delete this')}
        />
      );

      const modal = {
        component,
        options: {
          width: 600,
          height: 'auto',
          controlButtons: [
            {
              type: 'button',
              action: () => removeIFTARequest(R.of(Array, guid)),
              name: G.getWindowLocale('actions:delete', 'Delete'),
            },
          ],
        },
      };

      openModal(modal);
    },
    handleClickEditIcon: (props: Object) => (event: Object, { guid }: Object) => (
      props.openFixedPopup({
        position: 'right',
        el: event.currentTarget,
        content: (
          <RowActions
            guid={guid}
            openModal={props.openModal}
            closeModal={props.closeModal}
            closeFixedPopup={props.closeFixedPopup}
            printIFTARequest={props.printIFTARequest}
            getIFTAXmlRequest={props.getIFTAXmlRequest}
            removeIFTARequest={props.removeIFTARequest}
            documentTemplates={props.documentTemplates} />
        ),
      })
    ),
    getAdditionalFilteredListRequest: (props: Object) => (filters: Object) => {
      props.setAdditionalFilters(filters);
      props.resetListAndPagination();
      props.getIftaListRequest(true);
    },
    handleToggleIFTADetails: (props: Object) => (data: Object) => {
      const { changeActiveTab, fromTruckDetailPage, toggleIftaReportDetails } = props;

      toggleIftaReportDetails(data);

      if (R.and(G.isTrue(fromTruckDetailPage), G.isFalse(data.expanded))) {
        const row = R.pick(
          [
            GC.FIELD_GUID,
            GC.SYSTEM_LIST_TELS,
            GC.FIELD_IFTA_TEL_GUIDS,
          ],
          data,
        );

        changeActiveTab({ row, tabName: GC.SYSTEM_LIST_TELS });
      }
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  pure,
);

export const Component = enhance(IftaListComponent);

export default enhance(IftaListComponent);

import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const compensationCommonFieldStyles = {
  width: '220px',
  afterTop: '15px',
  errorTop: '110%',
  errorLeft: '5px',
  afterRight: '15px',
  flexDirection: 'column',
  errorPosition: 'absolute',
  labelWidth: 'max-content',
  inputsFlexDirection: 'column',
};

export const createContractFormFields = [
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_NAME,
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 13,
    width: '256px',
    hideIcon: true,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:effective-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 12,
    width: '256px',
    hideIcon: true,
    isRequired: true,
    type: 'calendar',
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    minDate: G.getCurrentDate(),
    loc: 'titles:expiration-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '214px',
    loc: 'titles:active',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_ACTIVE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:mode',
    shouldHandle: true,
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_MODE,
    options: 'transportationMode',
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:currency',
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 11,
    shouldHandle: true,
    type: 'multiselect',
    loc: 'titles:roles',
    alignItems: 'center',
    flexDirection: 'row',
    options: 'roleOptions',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_ROLE_GUIDS,
  },
];

export const createContractFormFieldsAsync = [
  {
    ...compensationCommonFieldStyles,
    type: 'text',
    isRequired: true,
    loc: 'titles:name',
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_NAME,
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 13,
    isRequired: true,
    shouldHandle: true,
    type: 'searchselect',
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:carrier',
    options: 'carrierOptions',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_GUID,
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 12,
    width: '256px',
    hideIcon: true,
    type: 'calendar',
    isRequired: true,
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:effective-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EFFECTIVE_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    zIndex: 11,
    width: '256px',
    hideIcon: true,
    isRequired: true,
    type: 'calendar',
    isClearable: true,
    timeSelection: false,
    alignItems: 'center',
    flexDirection: 'row',
    minDate: G.getCurrentDate(),
    loc: 'titles:expiration-date',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_CONTRACT_EXPIRATION_DATE,
    maxDate: G.addMomentTime(G.getCurrentDate(), 3, 'years'),
  },
  {
    ...compensationCommonFieldStyles,
    type: 'toggle',
    width: '214px',
    loc: 'titles:active',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fieldName: GC.FIELD_CARRIER_ACTIVE,
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    loc: 'titles:mode',
    shouldHandle: true,
    alignItems: 'center',
    flexDirection: 'row',
    fieldName: GC.FIELD_MODE,
    options: (values: Object, optionsForSelect: Object) => {
      const carrierGuid = R.path([GC.FIELD_CARRIER_GUID], values);
      return R.prepend(
        GC.EMPTY_OPTION_OBJECT,
        G.mapDropdownObjectPropsToLabelValueObject(
          R.pathOr([], [GC.FIELD_MODE, carrierGuid, 'transportationModes'], optionsForSelect),
        ),
      );
    },
    justifyContent: 'space-between',
  },
  {
    ...compensationCommonFieldStyles,
    type: 'select',
    isRequired: true,
    alignItems: 'center',
    flexDirection: 'row',
    loc: 'titles:currency',
    fieldName: GC.FIELD_CURRENCY,
    options: GC.CURRENCY_OPTIONS,
    justifyContent: 'space-between',
  },
];

import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import React, { Fragment } from 'react';
import {
  pure,
  compose,
  withProps,
  withState,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { Tabs2, withTabs2 } from '../../../components/tabs-mui';
import { FormSectionHeader } from '../../../components/form-section-header';
import { SelectFleetEntity, withSelectedFleetEntity } from '../../../components/select-fleet-entity';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { priorityTypeOptions, fleetServiceIssueStatusOptions } from '../../../helpers/options';
// hocs
import { withAsyncConfigs } from '../../../hocs';
import withAsyncSequence from '../../../hocs/with-async-sequence';
import { withAsyncGetUserGeneralListFullName } from '../../../hocs/with-async-get-endpoint-hocs';
// ui
import { Box, ModalCancelButton } from '../../../ui';
import { scrollableContainerCss4px } from '../../../ui/common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-list
import { FleetServiceIssueDocuments } from './fleet-service-issue-documents';
//////////////////////////////////////////////////

const locationFields = [
  GC.FIELD_ZIP,
  GC.FIELD_CITY,
  GC.FIELD_STATE,
  GC.FIELD_ADDRESS,
  GC.FIELD_COUNTRY,
  GC.FIELD_LATITUDE,
  GC.FIELD_LONGITUDE,
];

const createFleetServiceIssueValidationSchemaObject = ({ values, isTruck }: Object) => {
  const coordinatesValidation = Yup.string().nullable(true).required(G.getSelectLocationLocaleTxt());

  const isCoordinatesRequired = R.compose(
    R.any(G.isNotNilAndNotEmpty),
    R.values,
    R.pick(locationFields),
  )(values);

  return {
    [GC.FIELD_SUMMARY]: G.yupStringRequired,
    [GC.FIELD_ISSUE_ID]: G.yupStringRequired,
    [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: G.yupStringRequired,
    [GC.FIELD_LATITUDE]: G.ifElse(isCoordinatesRequired, coordinatesValidation, G.yupStringNotRequired),
    [GC.FIELD_LONGITUDE]: G.ifElse(isCoordinatesRequired, coordinatesValidation, G.yupStringNotRequired),
  };
};

const defaultLocationFields = {
  [GC.FIELD_ZIP]: null,
  [GC.FIELD_CITY]: null,
  [GC.FIELD_STATE]: null,
  [GC.FIELD_COUNTRY]: null,
  [GC.FIELD_ADDRESS]: null,
  [GC.FIELD_LATITUDE]: null,
  [GC.FIELD_LONGITUDE]: null,
};

const defaultValues = {
  ...defaultLocationFields,
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_SUMMARY]: '',
  [GC.FIELD_ISSUE_ID]: '',
  [GC.FIELD_PRIORITY]: '',
  [GC.FIELD_TRUCK_GUID]: '',
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_TRAILER_GUID]: '',
  [GC.FIELD_ASSIGNEE_GUID]: '',
};

const inputWrapperStyles = {
  mb: 25,
  width: 250,
};

const getFieldSettings = ({
  isEdit,
  isTruck,
  selectedFleetEntity,
  setSelectedFleetEntity,
  openedFromFleetProfile,
}: Object) => [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_SUMMARY,
    label: ['titles:summary', 'Summary'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      width: '100%',
    },
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ISSUE_ID,
    label: ['titles:issue-id', 'Issue Id'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'customComponent',
    fieldName: G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID),
    label: G.ifElse(isTruck, ['titles:truck', 'Truck'], ['titles:trailer', 'Trailer']),
    Component: ({ name, value, hasError, setFieldValue }: Object) => (
      <SelectFleetEntity
        value={value}
        fieldName={name}
        isTruck={isTruck}
        isClearable={true}
        hasError={hasError}
        useMenuPortalTarget={true}
        handleSelect={setFieldValue}
        selectedFleetEntity={selectedFleetEntity}
        setSelectedFleetEntity={setSelectedFleetEntity}
        isDisabled={G.isAnyTrue(isEdit, openedFromFleetProfile)}
      />
    ),
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_TYPE,
    options: 'serviceIssueTypeOptions',
    label: ['titles:issue-type', 'Issue Type'],
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'assigneeOptions',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_ASSIGNEE_GUID,
    label: ['titles:assigned-to', 'Assigned To'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_PRIORITY,
    options: priorityTypeOptions,
    label: ['titles:priority', 'Priority'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_STATUS,
    label: ['titles:status', 'Status'],
    options: fleetServiceIssueStatusOptions,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: G.ifElse(isEdit, 'block', 'none'),
    },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: {
      width: '100%',
      mb: G.ifElse(isEdit, 0, inputWrapperStyles.mb),
    },
  },
  {
    type: 'files',
    fieldName: GC.FIELD_FILES,
    label: ['titles:upload-files', 'Upload Files'],
    inputWrapperStyles: {
      mb: 0,
      width: '100%',
      display: G.ifElse(isEdit, 'none', 'block'),
    },
  },
];

const locationFieldSettings = [
  {
    type: 'addressAutocomplete',
    fieldName: GC.FIELD_ADDRESS,
    label: ['titles:address', 'titles:address'],
    inputWrapperStyles: { ...inputWrapperStyles, width: '100%' },
    locationMapper: (location: Object) =>
      R.assoc(GC.FIELD_ADDRESS, G.getPropFromObject(GC.FIELD_ADDRESS_1, location), location),
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_CITY,
    label: ['titles:city', 'City'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_STATE,
    label: ['titles:state', 'State'],
  },
  {
    type: 'text',
    inputWrapperStyles,
    fieldName: GC.FIELD_ZIP,
    label: ['titles:zip', 'Zip'],
  },
  {
    inputWrapperStyles,
    type: 'countrySelect',
    fieldName: GC.FIELD_COUNTRY,
    label: ['titles:country', 'Country'],
  },
  {
    type: 'text',
    disabled: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LATITUDE,
    label: ['titles:latitude', 'Latitude'],
  },
  {
    type: 'text',
    disabled: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_LONGITUDE,
    label: ['titles:longitude', 'Longitude'],
  },
];

const enhance = compose(
  withSelectedFleetEntity,
  withProps(() => ({ configsNamesArray: [GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE] })),
  withAsyncConfigs,
  withProps(({ asyncConfigs }: Object) => {
    const serviceIssueTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.FLEET_GENERAL_SERVICE_ISSUE_TYPE,
    );

    return { serviceIssueTypeOptions };
  }),
  withAsyncGetUserGeneralListFullName,
  withPropsOnChange(['asyncUserGeneralListFullName'], ({ asyncUserGeneralListFullName }: Object) => {
    let assigneeOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUserGeneralListFullName)) {
      assigneeOptions = R.map((item: Object) => {
        const { fullText } = G.getUserInfo(item);

        return {
          [GC.FIELD_LABEL]: fullText,
          [GC.FIELD_VALUE]: G.getGuidFromObject(item),
        };
      }, asyncUserGeneralListFullName);
    }

    return { assigneeOptions };
  }),
  withFormik({
    validationSchema: ({ isTruck }: Object) => Yup.lazy((values: Object) =>
      Yup.object().shape(createFleetServiceIssueValidationSchemaObject({ values, isTruck })),
    ),
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues, asyncSequence }: Object) => {
      const defaultFields = G.ifElse(
        G.isNotNilAndNotEmpty(asyncSequence),
        R.assoc(GC.FIELD_ISSUE_ID, asyncSequence, defaultValues),
        defaultValues,
      );

      let initialValuesToUse = initialValues;

      if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_LOCATION, initialValues))) {
        const location = R.compose(
          R.pick(locationFields),
          R.prop(GC.FIELD_LOCATION),
        )(initialValues);

        initialValuesToUse = R.compose(
          R.mergeRight(location),
          R.omit(GC.FIELD_LOCATION),
        )(initialValues);
      }

      return G.setInitialFormikValues(defaultFields, initialValuesToUse);
    },
    handleSubmit: (values: Object, { props }: Object) => {
      const { entityType, submitAction, asyncSequence } = props;

      const { latitude, longitude } = values;

      let location = null;
      let valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);

      if (R.pathEq(asyncSequence, [GC.FIELD_ISSUE_ID], values)) {
        valuesToUse = R.assoc(GC.FIELD_ISSUE_ID, null, valuesToUse);
      }

      if (R.and(G.isNotNilAndNotEmpty(latitude), G.isNotNilAndNotEmpty(longitude))) {
        location = R.pick(locationFields, values);
      }

      valuesToUse = R.compose(
        R.assoc(GC.FIELD_LOCATION, location),
        R.omit([...locationFields, GC.FIELD_ADDRESS_1, 'formattedAddress', 'originCity']),
      )(valuesToUse);

      submitAction({ entityType, values: valuesToUse });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getAsyncUserGeneralListFullName } = this.props;

      getAsyncUserGeneralListFullName();
    },
  }),
  pure,
);

const enhanceCreate = compose(
  withProps(() => ({
    sequenceConfigName: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE,
    autogenerateConfigName: GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED,
    configsNamesArray: [GC.FLEET_GENERAL_SERVICE_ISSUE_ID_SEQUENCE, GC.FLEET_GENERAL_SERVICE_ISSUE_ID_AUTOGENERATED],
  })),
  withAsyncSequence,
  enhance,
);

const enhanceEdit = compose(
  withTabs2,
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withState('documents', 'setDocuments', ({ initialDocumentList }: Object) => R.or(initialDocumentList, [])),
  withHandlers({
    handleGetInitialValues: (props: Object) => async () => {
      const { guid, isTruck, openLoader, closeLoader, setInitialValues } = props;

      G.callFunction(openLoader);

      const endpoint = G.getPropFromObject(
        G.ifElse(isTruck, 'getTruckServiceIssueEndpoint', 'getTrailerServiceIssueEndpoint'),
        endpointsMap,
      )(guid);

      const res = await sendRequest('get', endpoint);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setInitialValues({
          ...data,
          type: R.path([GC.FIELD_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID], data),
        });
      } else {
        G.handleFailResponseSimple(res, true, 'handleGetInitialValues fail');
      }

      G.callFunction(closeLoader);
    },
  }),
  enhance,
  lifecycle({
    componentDidMount() {
      const { guid, handleGetInitialValues } = this.props;

      if (G.isNotNilAndNotEmpty(guid)) handleGetInitialValues();
    },
  }),
);

const tabs = [
  { text: G.getWindowLocale('titles:issue', 'Issue') },
  { text: G.getWindowLocale('titles:documents', 'Documents') },
];

const FleetServiceIssueForm = (props: Object) => {
  const { handleSubmit, assigneeOptions, serviceIssueTypeOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Box
        pt={15}
        px='5px'
        width={530}
        overflowY='auto'
        overflowX='hidden'
        maxHeight='calc(100vh - 230px)'
        css={scrollableContainerCss4px}
      >
        <Fieldset2
          {...G.getFormikPropsToFieldset(props)}
          fields={getFieldSettings(props)}
          assigneeOptions={assigneeOptions}
          serviceIssueTypeOptions={serviceIssueTypeOptions}
          fieldsWrapperStyles={{ justifyContent: 'space-between' }}
        />
        <FormSectionHeader
          expanded={true}
          additionalStyles={{ mt: 15 }}
          title={G.getWindowLocale('titles:location', 'Location')}
        >
          <Fieldset2
            {...G.getFormikPropsToFieldset(props)}
            fields={locationFieldSettings}
            fieldsWrapperStyles={{ mt: 25, mb: '5px', justifyContent: 'space-between' }}
          />
        </FormSectionHeader>
      </Box>
      <FormFooter2 boxStyles={{ mt: 25 }} />
    </form>
  );
};

const CreateFleetServiceIssueForm = enhanceCreate(FleetServiceIssueForm);

const EditFleetServiceIssueForm = enhanceEdit((props: Object) => {
  const { values, activeTab, documents, entityType, closeModal, setDocuments, handleSetActiveTab } = props;

  return (
    <Fragment>
      <Tabs2
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        tabStyles={GC.COMMON_MUI_TAB_STYLES}
        tabsStyles={{ ...GC.COMMON_MUI_TABS_STYLES, mb: '15px' }}
      />
      {
        R.equals(activeTab, 0) && <FleetServiceIssueForm {...props} />
      }
      {
        R.equals(activeTab, 1) &&
        <Fragment>
          <FleetServiceIssueDocuments
            p='0px'
            entityType={entityType}
            documentList={documents}
            successCallback={setDocuments}
            serviceIssueGuid={G.getGuidFromObject(values)}
          />
          <Box>
            <ModalCancelButton mt={25} width={120} fontSize={14} onClick={closeModal}>
              {G.getWindowLocale('actions:close', 'Close')}
            </ModalCancelButton>
          </Box>
        </Fragment>
      }
    </Fragment>
  );
});

export {
  EditFleetServiceIssueForm,
  CreateFleetServiceIssueForm,
};

import * as R from 'ramda';
// features
import { FILTER_PARAMS as ITEM_FILTER_PARAMS } from '../../../item/settings/filter-params';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
//////////////////////////////////////////////////

const itemFilterParams = R.compose(
  R.map((item: Object) => {
    const { name, value } = item;

    return {
      ...item,
      collection: true,
      [GC.FIELD_VALUE]: `${GC.FIELD_CLO}.${GC.SYSTEM_LIST_ITEMS}.${value}`,
      [GC.FIELD_NAME]: `${G.getWindowLocale('titles:items', 'Items')}: ${name}`,
    };
  }),
  R.reject(({ value }: Object) => R.includes(
    value,
    [
      GC.FIELD_ACTIVE,
      GC.FIELD_OPERATIONAL,
      GC.FIELD_ITEM_HAZARDOUS,
      GC.FIELD_BRANCH_DOT_BRANCH_NAME,
    ],
  )),
)(ITEM_FILTER_PARAMS);

export const FILTER_PARAMS = [
  {
    type: 'string',
    name: G.getWindowLocale('titles:branch', 'Branch'),
    value: `${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_BRANCH_NAME}`,
  },
  {
    type: 'string',
    value: `${GC.FIELD_CLO}.${GC.FIELD_BRANCH_DOT_ACCOUNT_NAME}`,
    name: G.getWindowLocale('titles:account-number', 'Account Number'),
  },
  {
    type: 'number',
    value: `${GC.FIELD_CLO}.${GC.FIELD_TOTAL_PICKUP_QUANTITY}`,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:quantity', 'Quantity')
    }`,
  },
  {
    type: 'string',
    value: GC.FIELD_TYPE,
    name: G.getWindowLocale('titles:type', 'Type'),
  },
  {
    type: 'string',
    value: GC.GRC.STATUS_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:status', 'Status'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL,
    name: G.getWindowLocale('titles:invoice-total', 'Invoice Total'),
  },
  {
    type: 'number',
    value: GC.FIELD_MAIN_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:linehaul', 'Linehaul'),
  },
  {
    type: 'number',
    value: GC.FIELD_DISCOUNT_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:discount', 'Discount'),
  },
  {
    type: 'number',
    value: GC.FIELD_FUEL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:fuel', 'Fuel'),
  },
  {
    type: 'number',
    value: GC.FIELD_ADDITIONAL_CHARGES_TOTAL,
    name: G.getWindowLocale('titles:other-accessorials', 'Other Accessorials'),
  },
  {
    type: 'number',
    value: GC.FIELD_BALANCE,
    name: G.getWindowLocale('titles:balance', 'Balance'),
  },
  {
    type: 'number',
    value: GC.FIELD_PAID_TOTAL,
    name: G.getWindowLocale('titles:paid-total', 'Paid Total'),
  },
  {
    type: 'string',
    value: GC.FIELD_CURRENCY,
    name: G.getWindowLocale('titles:currency', 'Currency'),
  },
  {
    type: 'number',
    value: GC.GRC.NORMALIZED_TOTAL_TOTAL,
    name: G.getWindowLocale('titles:normalized-total', 'Normalized Total'),
  },
  {
    type: 'string',
    value: GC.GRC.NORMALIZED_TOTAL_CURRENCY,
    name: G.getWindowLocale('titles:normalized-currency', 'Normalized Currency'),
  },
  {
    type: 'number',
    value: `${GC.FIELD_CLO}.${GC.GRC.RATE_TOTAL}`,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:total', 'Total')}`,
  },
  {
    type: 'string',
    value: `${GC.FIELD_CLO}.${GC.GRC.RATE_CURRENCY}`,
    name: `${G.getWindowLocale('titles:rate', 'Rate')}: ${G.getWindowLocale('titles:currency', 'Currency')}`,
  },
  {
    type: 'string',
    value: GC.FIELD_COMMENTS,
    name: G.getWindowLocale('titles:comments', 'Comments'),
  },
  {
    type: 'string',
    value: GC.GRC.MODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:mode', 'Mode'),
  },
  {
    type: 'string',
    value: GC.GRC.SERVICE_TYPE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_ORDER_TYPE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:order-type', 'Order Type'),
  },
  {
    type: 'number',
    value: GC.FIELD_SERVICE_DAYS,
    name: G.getWindowLocale('titles:service-days', 'Service Days'),
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_LOCATION_NAME,
    name: G.getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_STATE,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_CITY,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_ZIP,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.BILL_TO_PAYMENT_TERM,
    name: `${G.getWindowLocale('titles:bill-to', 'Bill To')}: ${
      G.getWindowLocale('titles:payment-terms', 'Payment Terms')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_LOCATION_NAME,
    name: G.getWindowLocale('titles:remit-to', 'Remit To'),
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_LOCATION_TYPE_DISPLAYED,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:type', 'Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_COUNTRY,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_STATE,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_CITY,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_ADDRESS1,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_ZIP,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'string',
    value: GC.GRC.REMIT_TO_COMMENTS,
    name: `${G.getWindowLocale('titles:remit-to', 'Remit To')}: ${G.getWindowLocale('titles:comments', 'Comments')}`,
  },
  {
    type: 'number',
    value: GC.GRC.CLO_STORED_TOTAL_WEIGHT,
    name: G.getWindowLocale('titles:order-total-weight', 'Order Total Weight'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_TRIP_WEIGHT,
    name: G.getWindowLocale('titles:total-invoice-weight', 'Total Invoice Weight'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    name: G.getWindowLocale('titles:total-invoice-weight-uom', 'Total Invoice Weight UOM'),
  },
  {
    type: 'number',
    value: GC.FIELD_TOTAL_TRIP_DISTANCE,
    name: G.getWindowLocale('titles:total-trip-distance', 'Total Trip Distance'),
  },
  {
    type: 'string',
    value: GC.FIELD_TOTAL_TRIP_DISTANCE_UOM,
    name: G.getWindowLocale('titles:total-trip-distance-uom', 'Total Trip Distance UOM'),
  },
  {
    type: 'number',
    value: GC.GRC.CLO_TOTAL_SPENDING,
    name: ` ${G.getWindowLocale('titles:clo', 'Order')}:
      ${G.getWindowLocale('titles:total-spend', 'Total Spend')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_PAYMENT_DUE_DATE,
    name: G.getWindowLocale('titles:payment-due-date', 'Payment Due Date'),
  },
  {
    type: 'number',
    value: GC.FIELD_INVOICE_NET_DAYS,
    name: G.getWindowLocale('titles:net-days', 'Net Days'),
  },
  {
    type: 'string',
    value: GC.FIELD_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:invoice-number', 'Invoice #'),
  },
  {
    type: 'string',
    value: GC.GRC.GL_CODE_DISPLAYED_VALUE,
    name: G.getWindowLocale('titles:gl-code', 'GL Code'),
  },
  {
    type: 'date',
    value: GC.FIELD_INVOICE_DATE,
    name: G.getWindowLocale('titles:invoice-date', 'Invoice Date'),
  },
  {
    type: 'date',
    value: GC.FIELD_INTEGRATED_DATE,
    name: G.getWindowLocale('titles:integrated-date', 'Integrated Date'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_LAST_MODIFIED_BY,
    name: G.getWindowLocale('titles:integrated-by', 'Integrated By'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_DEPOSIT_DATE,
    name: G.getWindowLocale('titles:deposit-date', 'Deposit Date'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_NUMBER,
    name: G.getWindowLocale('titles:check-number', 'Check Number'),
  },
  {
    type: 'date',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_DATE,
    name: G.getWindowLocale('titles:check-date', 'Check Date'),
  },
  {
    type: 'number',
    collection: true,
    value: GC.GRC.PAYMENTS_CHECK_AMOUNT,
    name: G.getWindowLocale('titles:check-amount', 'Check Amount'),
  },
  {
    type: 'string',
    value: GC.FIELD_MASTER_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:master-invoice-number', 'Master Invoice Number'),
  },
  {
    type: 'string',
    value: GC.FIELD_PHONE,
    name: G.getWindowLocale('titles:phone', 'Phone'),
  },
  {
    type: 'string',
    value: GC.FIELD_FAX,
    name: G.getWindowLocale('titles:fax', 'Fax'),
  },
  {
    type: 'string',
    value: GC.FIELD_EMAIL,
    name: G.getWindowLocale('titles:email', 'Email'),
  },
  {
    type: 'string',
    value: GC.FIELD_CONTACT_NAME,
    name: G.getWindowLocale('titles:contact-name', 'Contact Name'),
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_SALE_PERSONS_NAME,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:name', 'Name')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_SALE_PERSONS_ID,
    name: `${G.getWindowLocale('titles:sale-persons', 'Sale Persons')}: ${
      G.getWindowLocale('titles:id', 'ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_ROUTE_NAME,
    name: G.getWindowLocale('titles:route-name', 'Route Name'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${
      G.getWindowLocale('titles:primary-reference', 'Primary Reference')}`,
  },
  {
    type: 'date',
    value: GC.FIELD_CREATED_DATE,
    name: G.getWindowLocale('titles:created-date', 'Created Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_CREATED_BY,
    name: G.getWindowLocale('titles:created-by', 'Created By'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_STATUS,
    name: G.getWindowLocale('titles:integration-status', 'Integration Status'),
  },
  {
    type: 'string',
    value: GC.GRC.ACCOUNTING_INTEGRATION_TYPE,
    name: G.getWindowLocale('titles:integration-type', 'Integration Type'),
  },
  {
    type: 'date',
    value: GC.GRC.ACCOUNTING_INTEGRATION_GL_POSTED_DATE,
    name: G.getWindowLocale('titles:gl-posted-date', 'GL Posted Date'),
  },
  {
    type: 'string',
    value: GC.FIELD_EXTERNAL_INVOICE_NUMBER,
    name: G.getWindowLocale('titles:external-invoice-number', 'External Invoice Number'),
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_DOCUMENT_TYPE,
    name: `${G.getWindowLocale('titles:clo', 'CLO')}: ${G.getWindowLocale('titles:document-type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CARRIER_NAME,
    name: G.getWindowLocale('titles:carrier-name', 'Carrier Name'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CARRIER_US_DOT_NUMBER,
    name: G.getWindowLocale('titles:carrier-us-dot-number', 'Carrier USDOT #'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CARRIER_MC_NUMBER,
    name: G.getWindowLocale('titles:carrier-mc-number', 'Carrier MC #'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CARRIER_TRUCK,
    name: G.getWindowLocale('titles:carrier-truck', 'Carrier Truck'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_CARRIER_TRAILER,
    name: G.getWindowLocale('titles:carrier-trailer', 'Carrier Trailer'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PRIMARY_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:primary-driver', 'Primary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_SECONDARY_DRIVER_FIRST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:first-name', 'First Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_SECONDARY_DRIVER_LAST_NAME,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:last-name', 'Last Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_SECONDARY_DRIVER_LOGIN_ID,
    name: `${G.getWindowLocale('titles:secondary-driver', 'Secondary Driver')}: ${
      G.getWindowLocale('titles:login-id', 'Login ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_DRIVER_TRUCK,
    name: G.getWindowLocale('titles:driver-truck', 'Driver Truck'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_DRIVER_TRAILER,
    name: G.getWindowLocale('titles:driver-trailer', 'Driver Trailer'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:template-id', 'Template ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_FIRST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_FIRST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:first-event', 'First Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_TYPE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-type', 'Location Type')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_TEMPLATE_ID,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:template-id', 'Tempate ID')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_NAME,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:location-name', 'Location Name')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS1,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address1', 'Address1')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ADDRESS2,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:address2', 'Address2')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_CITY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:city', 'City')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_STATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:state', 'State')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_COUNTRY,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:country', 'Country')}`,
  },
  {
    type: 'string',
    value: GC.GRC.CLO_LAST_EVENT_LOCATION_ZIP,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:zip', 'Zip')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_EARLY_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:early-date', 'Early Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_LATE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:late-date', 'Late Date')}`,
  },
  {
    type: 'date',
    value: GC.GRC.CLO_LAST_EVENT_COMPLETE_DATE,
    name: `${G.getWindowLocale('titles:last-event', 'Last Event')}: ${
      G.getWindowLocale('titles:complete-date', 'Complete Date')}`,
  },
  {
    collection: true,
    type: 'string:array',
    value: GC.GRC.CLO_CONTAINERS_CONTAINER_NUMBER,
    name: `${G.getWindowLocale('titles:clo', 'Order')}: ${
      G.getWindowLocale('titles:container-number', 'Container Number')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.PAYMENTS_ACH_PAYMENT_CONFIRMATION_NUMBER,
    name: G.getWindowLocale('titles:ach-payment-confirmation', 'ACH Payment Confirmation'),
  },
  {
    type: 'string',
    value: GC.GRC.CLO_PINNED_NOTE_TEXT,
    name: ` ${G.getWindowLocale('titles:clo', 'Order')}:
      ${G.getWindowLocale('titles:pinned-note', 'Pinned Note')}`,
  },
  {
    type: 'string',
    collection: true,
    value: GC.GRC.CLO_TELS_FLEET_VENDOR_INVOICES_PAY_TYPE,
    name: ` ${G.getWindowLocale('titles:vendor-invoices')}:
      ${G.getWindowLocale('titles:pay-to-type', 'Pay To Type')}`,
  },
  ...itemFilterParams,
];

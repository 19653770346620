import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { changeSalesOrAgentsRequest } from '../../../../common/actions';
// features
import OrderTypeForm from '../../forms/order-type-form';
import SpecialInstructionsForm from '../../forms/special-instructions-form';
import InternalInstructionsForm from '../../forms/internal-instructions-form';
// forms
import { AsyncOrderTypeForm } from '../../../../forms/forms/async-order-type-form';
import { AsyncLoadServicesForm } from '../../../../forms/forms/async-load-services-form';
import { AsyncLoadEquipmentsForm } from '../../../../forms/forms/async-load-equipments-form';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature dispatch details new
import {
  makeSelectPinnedNote,
  makeSelectLoadCustomStatuses,
} from '../selectors';
import {
  updateOrderNoteRequest,
  updateOrderDetailsRequest,
  getAvailableLoadCustomStatusListSuccess,
} from '../actions';
//////////////////////////////////////////////////

export const withRightLoadHeaderActionsWithoutConnect = compose(
  withHandlers({
    handleSetServices: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        loadConfigs,
        updateOrderDetailsRequest,
        transportationModeGroupingList,
        customerRateTransportationModeGuid,
        getTransportationModeGroupingListSuccess,
      } = props;

      const defaultServices = R.path([GC.FIELD_LOAD_SERVICES], load);

      const component = (
        <AsyncLoadServicesForm
          closeModal={closeModal}
          loadConfigs={loadConfigs}
          defaultServices={defaultServices}
          submitAction={updateOrderDetailsRequest}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, load)}
          transportationModeGroupingList={transportationModeGroupingList}
          customerRateTransportationModeGuid={customerRateTransportationModeGuid}
          getTransportationModeGroupingListSuccess={getTransportationModeGroupingListSuccess}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:services', 'Services'),
        },
      };

      openModal(modal);
    },
    handleSetEquipments: (props: Object) => () => {
      const {
        load,
        openModal,
        closeModal,
        loadConfigs,
        updateOrderDetailsRequest,
        transportationModeGroupingList,
        customerRateTransportationModeGuid,
        getTransportationModeGroupingListSuccess,
      } = props;

      const defaultEquipment = R.path([GC.FIELD_LOAD_EQUIPMENT], load);

      const component = (
        <AsyncLoadEquipmentsForm
          closeModal={closeModal}
          loadConfigs={loadConfigs}
          defaultEquipment={defaultEquipment}
          submitAction={updateOrderDetailsRequest}
          branchGuid={R.prop(GC.FIELD_BRANCH_GUID, load)}
          transportationModeGroupingList={transportationModeGroupingList}
          customerRateTransportationModeGuid={customerRateTransportationModeGuid}
          getTransportationModeGroupingListSuccess={getTransportationModeGroupingListSuccess}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:equipments', 'Equipments'),
        },
      };

      openModal(modal);
    },
    handleSetOrderType: (props: Object) => () => {
      const { load, openModal, closeModal, loadConfigs, updateOrderDetailsRequest } = props;

      const defaultOrderType = R.path([GC.FIELD_LOAD_ORDER_TYPE], load);

      let component;

      let modal = {
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:order-type', 'Order Type'),
        },
      };

      if (R.isNil(loadConfigs)) {
        component = (
          <AsyncOrderTypeForm
            closeModal={closeModal}
            defaultOrderType={defaultOrderType}
            submitAction={updateOrderDetailsRequest}
            branchGuid={R.prop(GC.FIELD_BRANCH_GUID, load)}
          />
        );

        modal = R.mergeRight(modal, { p: '0px', component });
      } else {
        const orderTypeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
          R.path(['configGroups', GC.CLO_CONFIG_GROUP], loadConfigs),
          GC.CLO_ORDER_TYPE_DROPDOWN,
        );

        const fullOptions = R.path(
          ['configGroups', GC.CLO_CONFIG_GROUP, 'dropdowns', GC.CLO_ORDER_TYPE_DROPDOWN, 'options'],
          loadConfigs,
        );

        component = (
          <OrderTypeForm
            closeModal={closeModal}
            fullOptions={fullOptions}
            defaultOrderType={defaultOrderType}
            orderTypeOptions={orderTypeOptions}
            handleActionUpdateLoad={updateOrderDetailsRequest}
          />
        );

        modal = R.mergeRight(modal, { p: 15, component });
      }
      openModal(modal);
    },
    handleSetSpecialInstructions: (props: Object) => () => {
      const { load, openModal, closeModal, updateOrderDetailsRequest } = props;

      const initialValues = {
        specialInstructions: R.path([GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS], load),
      };

      const component = (
        <SpecialInstructionsForm
          closeModal={closeModal}
          initialValues={initialValues}
          handleActionUpdateLoad={updateOrderDetailsRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:special-instructions', 'Special Instructions'),
        },
      };

      openModal(modal);
    },
    handleSetInternalInstructions: (props: Object) => () => {
      const { load, openModal, closeModal, updateOrderDetailsRequest } = props;

      const initialValues = {
        internalInstructions: R.path([GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS], load),
      };

      const component = (
        <InternalInstructionsForm
          closeModal={closeModal}
          initialValues={initialValues}
          handleActionUpdateLoad={updateOrderDetailsRequest}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:internal-instructions', 'Internal Instructions'),
        },
      };

      openModal(modal);
    },
    handlePinMessage: (props: Object) => (entity: Object) => {
      const { updateOrderNoteRequest } = props;

      const { pinned } = entity;

      const requestData = R.assoc('pinned', R.not(pinned), entity);

      updateOrderNoteRequest(requestData);
    },
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  pinnedNote: makeSelectPinnedNote(state),
  loadCustomStatuses: makeSelectLoadCustomStatuses(state),
});

export const withRightLoadHeaderActions = compose(
  connect(
    mapStateToProps,
    {
      updateOrderNoteRequest,
      updateOrderDetailsRequest,
      changeSalesOrAgentsRequest,
      getAvailableLoadCustomStatusListSuccess,
    },
  ),
  withRightLoadHeaderActionsWithoutConnect,
  pure,
);

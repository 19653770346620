import * as R from 'ramda';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature configs
import { CONFIG_PAGE_FORM, CONFIG_DROPDOWN_POPUP_FORM } from './constants';
//////////////////////////////////////////////////

const selectConfigStore = (state: Object) => state.config;

// COMMON
const makeSelectCurrentGroupName = () => createSelector(
  selectConfigStore,
  ({ currentGroupName }: Object) => currentGroupName,
);

const makeSelectFinancial = () => createSelector(
  selectConfigStore,
  ({ financial }: Object) => financial,
);

const makeSelectConfigByName = (configName: Object) => createSelector(
  [
    selectConfigStore,
    R.path(['config', 'currentGroupName']),
  ],
  (state: Object, currentGroupName: string) => R.or(
    R.path([currentGroupName, 'configs', configName], state),
    R.path([currentGroupName, 'dropdowns', configName], state),
  ),
);

const makeSelectConfigInitialValues = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'initialValues'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectConfigInheritedValues = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'inheritedValues'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectConfigGuidsToItemsMap = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'guidsToItemsMap'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectConfigOptions = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'options'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectConfigDropdowns = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'dropdowns'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectConfigWarning = createCachedSelector(
  selectConfigStore,
  (store: Object, name: string) => name,
  (store: Object, name: string) => R.path([name, 'warning'], store),
)(
  (store: Object, name: string) => name,
);

const makeSelectTabsVisited = () => createSelector(
  selectConfigStore,
  ({ currentGroupName, ...config }: Object) => R.pathOr([], [currentGroupName, 'tabsVisited'], config),
);
// COMMON

const selectFormStore = ({ form }: Object) => form;
const selectCLOStore = (state: Object) => state.config.clo;
const selectTELStore = (state: Object) => state.config.tel;
const selectUIConfigStore = (state: Object) => state.config.ui;
const selectDriverConfigStore = (state: Object) => state.config.driver;
const selectGeneralConfigStore = (state: Object) => state.config.general;
const selectCarrierConfigStore = (state: Object) => state.config.carrier;
const selectInvoicesConfigStore = (state: Object) => state.config.invoice;
const selectRateEngineConfigStore = (state: Object) => state.config.rateEngine;
const selectTaskManagementStore = (state: Object) => state.config.taskManagement;
export const selectIntegrationStore = (state: Object) => state.config.integration;
const selectCommunicationConfigStore = (state: Object) => state.config.communication;
const selectConfigPopupFormStore = (state: Object) => state.form[CONFIG_DROPDOWN_POPUP_FORM];

const makeSelectFormValues = () => createSelector(
  selectFormStore,
  R.pathOr({}, [CONFIG_PAGE_FORM, 'values']),
);

const makeSelectAccessorialsList = () => createSelector(
  selectGeneralConfigStore,
  ({ accessorialsList }: Object) => accessorialsList,
);

const makeSelectDistanceCalculatorConfigList = () => createSelector(
  selectGeneralConfigStore,
  ({ distanceCalculatorConfigList }: Object) => distanceCalculatorConfigList,
);

const makeSelectDeactivationRuleList = () => createSelector(
  selectCarrierConfigStore,
  ({ deactivationRuleList }: Object) => deactivationRuleList,
);

const makeSelectOnboardingPackageList = () => createSelector(
  selectCarrierConfigStore,
  ({ onboardingPackageList }: Object) => onboardingPackageList,
);

const makeSelectOnboardingPackageDocumentList = () => createSelector(
  selectCarrierConfigStore,
  ({ onboardingPackageDocumentList }: Object) => R.values(onboardingPackageDocumentList),
);

const makeSelectTermsAndConditionsDocument = () => createSelector(
  selectCarrierConfigStore,
  ({ termsAndConditionsDocument }: Object) => termsAndConditionsDocument,
);

const makeSelectOrderQuoteTermsAndConditionsDocument = () => createSelector(
  selectCLOStore,
  ({ termsAndConditionsDocument }: Object) => termsAndConditionsDocument,
);

const makeSelectCustomerInvoiceStatuses = () => createSelector(
  selectInvoicesConfigStore,
  ({ customerInvoiceStatuses }: Object) => customerInvoiceStatuses,
);

const makeSelectCarrierInvoiceStatuses = () => createSelector(
  selectInvoicesConfigStore,
  ({ carrierInvoiceStatuses }: Object) => R.map((item: Object) => R.mergeRight(
    item,
    {
      [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
      [GC.FIELD_VALUE]: G.getPropFromObject(GC.FIELD_ORIGINAL_CONFIG_GUID, item),
    },
  ), carrierInvoiceStatuses),
);

const makeSelectFleetServiceInvoiceStatuses = () => createSelector(
  selectInvoicesConfigStore,
  ({ fleetServiceInvoiceStatuses }: Object) => fleetServiceInvoiceStatuses,
);

const makeSelectDriverPayrollStatuses = () => createSelector(
  selectInvoicesConfigStore,
  ({ driverPayrollStatuses }: Object) => driverPayrollStatuses,
);

const makeSelectConnectedToQB = () => createSelector(
  selectInvoicesConfigStore,
  (state: Object) => ({
    ownAuth: R.path(['initialValues', GC.INVOICE_QB_AUTHORIZATION], state),
    parentAuth: R.path(['inheritedValues', GC.INVOICE_QB_AUTHORIZATION], state),
  }),
);

const makeSelectQBAccountMappingList = () => createSelector(
  selectInvoicesConfigStore,
  ({ qbAccountMappingList, qbAccountMappingFilter }: Object) => R.compose(
    R.filter(({ scope }: Object) => R.or(
      G.isNilOrEmpty(qbAccountMappingFilter),
      R.equals(qbAccountMappingFilter, scope),
    )),
    R.flatten,
    R.values,
  )(R.or(qbAccountMappingList, {})),
);

const makeSelectSageAccountMappingList = () => createSelector(
  selectInvoicesConfigStore,
  ({ sageAccountMappingList, sageAccountMappingFilter }: Object) => R.compose(
    R.filter(({ scope }: Object) => R.or(
      G.isNilOrEmpty(sageAccountMappingFilter),
      R.equals(sageAccountMappingFilter, scope),
    )),
    R.flatten,
    R.values,
  )(R.or(sageAccountMappingList, {})),
);

const makeSelectCustomerIdMappingList = () => createSelector(
  selectInvoicesConfigStore,
  ({ customerIdMappingList }: Object) => customerIdMappingList,
);

const makeSelectDepartmentIdMappingList = () => createSelector(
  selectInvoicesConfigStore,
  ({ departmentIdMappingList }: Object) => departmentIdMappingList,
);

const makeSelectGLCodeMappingList = () => createSelector(
  selectInvoicesConfigStore,
  ({ glCodeMappingList, glCodeMappingFilter }: Object) => R.compose(
    R.filter(({ scope }: Object) => R.or(
      G.isNilOrEmpty(glCodeMappingFilter),
      R.equals(glCodeMappingFilter, scope),
    )),
    R.flatten,
    R.values,
  )(R.or(glCodeMappingList, {})),
);

const makeSelectInvoiceStatusRuleList = () => createSelector(
  selectInvoicesConfigStore,
  ({ invoiceStatusRuleList, invoiceStatusRuleFilter }: Object) => R.compose(
    R.sortBy(R.prop(GC.FIELD_PRIORITY)),
    R.filter(({ scope }: Object) => R.or(
      G.isNilOrEmpty(invoiceStatusRuleFilter),
      R.equals(invoiceStatusRuleFilter, scope),
    )),
    R.flatten,
    R.values,
  )(R.or(invoiceStatusRuleList, {})),
);

const makeSelectInvoiceStatusPriorityList = () => createSelector(
  selectInvoicesConfigStore,
  ({ invoiceStatusRuleList }: Object) => {
    const list = R.compose(
      G.mapIndexed((_: any, index: number) => index),
      R.filter(R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID)),
      R.flatten,
      R.values,
    )(invoiceStatusRuleList);
    const lastElement = R.length(list);
    const priorityList = R.compose(
      R.map((item: number) => ({ value: R.inc(item), label: `${R.inc(item)}` })),
      R.append(lastElement),
    )(list);

    return priorityList;
  },
);

const makeSelectQBAccountMappingFilter = () => createSelector(
  selectInvoicesConfigStore,
  ({ qbAccountMappingFilter }: Object) => qbAccountMappingFilter,
);

const makeSelectSageAccountMappingFilter = () => createSelector(
  selectInvoicesConfigStore,
  ({ sageAccountMappingFilter }: Object) => sageAccountMappingFilter,
);

const makeSelectGLCodeMappingFilter = () => createSelector(
  selectInvoicesConfigStore,
  ({ glCodeMappingFilter }: Object) => glCodeMappingFilter,
);

const makeSelectInvoiceStatusRuleFilter = () => createSelector(
  selectInvoicesConfigStore,
  ({ invoiceStatusRuleFilter }: Object) => invoiceStatusRuleFilter,
);

const makeSelectExpenseTypeList = () => createSelector(
  selectInvoicesConfigStore,
  ({ expenseTypeList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(expenseTypeList),
);

const makeSelectStatusMessagesList = () => createSelector(
  selectCommunicationConfigStore,
  ({ statusMessages }: Object) => statusMessages,
);

const makeSelectServicesOptions = () => createSelector(
  selectGeneralConfigStore,
  (configStore: Object) => G.getFullOptionsFromDropdownConfigStore(
    configStore,
    GC.GENERAL_SERVICES,
  ),
);

const makeSelectReasonCodesOptions = () => createSelector(
  selectCommunicationConfigStore,
  (configStore: Object) => G.getFullOptionsFromDropdownConfigStore(
    configStore,
    GC.COMMUNICATION_REASON_CODE,
  ),
);

const makeSelectNotificationTriggers = () => createSelector(
  selectCommunicationConfigStore,
  ({ notificationConfig }: Object) => notificationConfig,
);

const makeSelectNotificationListTriggers = () => createSelector(
  selectCommunicationConfigStore,
  ({ notificationList }: Object) => notificationList,
);

const makeSelectFilteredNotificationList = () => createSelector(
  selectCommunicationConfigStore,
  ({ notificationList, notificationTriggersFilter, notificationObjectTypeFilter }: Object) => {
    const filterByObjectType = R.filter(
      ({ objectType }: Object) => R.or(
        G.isNilOrEmpty(notificationObjectTypeFilter),
        R.equals(objectType, notificationObjectTypeFilter),
      ),
    );

    const filterByTrigger = R.filter(
      ({ trigger }: Object) => R.or(
        G.isNilOrEmpty(notificationTriggersFilter),
        R.equals(trigger, notificationTriggersFilter),
      ),
    );

    return R.compose(
      filterByObjectType,
      filterByTrigger,
      R.values,
    )(notificationList);
  },
);

const makeSelectNotificationTriggersFilter = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr('', ['notificationTriggersFilter']),
);

const makeSelectNotificationObjectTypeFilter = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr('', ['notificationObjectTypeFilter']),
);

const makeSelectFuelCardList = () => createSelector(
  selectIntegrationStore,
  ({ fuelCardList }: Object) => fuelCardList,
);

const makeSelectEdiConfigsList = () => createSelector(
  selectIntegrationStore,
  ({ ediConfigsList }: Object) => ediConfigsList,
);

const makeSelectEdiExtractorConfigList = () => createSelector(
  selectIntegrationStore,
  ({ ediExtractorConfigList }: Object) => ediExtractorConfigList,
);

const makeSelectEdiExporterConfigList = () => createSelector(
  selectIntegrationStore,
  ({ ediExporterConfigList }: Object) => ediExporterConfigList,
);

const makeSelectIntegrationConfigList = () => createSelector(
  selectIntegrationStore,
  ({ integrationConfigList }: Object) => integrationConfigList,
);

const makeSelectCarrierRateIntegrationConfigList = () => createSelector(
  selectIntegrationStore,
  ({ carrierRateIntegrationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(carrierRateIntegrationList),
);

const makeSelectUserMonitoringIntegrationConfigList = () => createSelector(
  selectIntegrationStore,
  ({ userMonitoringIntegrationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(userMonitoringIntegrationList),
);


const makeSelectServiceMappingConfigList = () => createSelector(
  selectIntegrationStore,
  ({ serviceMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(serviceMappingList),
);

const makeSelectFilteredServiceMappingConfigList = () => createSelector(
  selectIntegrationStore,
  ({ serviceMappingList, serviceMappingFilter }: Object) => R.filter(
    ({ integrationType }: Object) => R.or(
      G.isNilOrEmpty(serviceMappingFilter),
      R.equals(integrationType, serviceMappingFilter),
    ),
    R.values(serviceMappingList),
  ),
);

const makeSelectServiceMappingFilter = () => createSelector(
  selectIntegrationStore,
  R.pathOr('', ['serviceMappingFilter']),
);

const makeSelectAdvancePaymentList = () => createSelector(
  selectIntegrationStore,
  ({ advancePaymentList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(advancePaymentList),
);

const makeSelectLoadBoardConfigList = () => createSelector(
  selectIntegrationStore,
  ({ loadBoardConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(loadBoardConfigList),
);

const makeSelectTollChargeList = () => createSelector(
  selectIntegrationStore,
  ({ tollChargeList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(tollChargeList),
);

const makeSelectGpsIntegrationList = () => createSelector(
  selectIntegrationStore,
  ({ gpsIntegrationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(gpsIntegrationList),
);

const makeSelectDispatchIntegrationList = () => createSelector(
  selectIntegrationStore,
  ({ dispatchIntegrationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(dispatchIntegrationList),
);

const makeSelectServicesForAccessorials = () => createSelector(
  selectGeneralConfigStore,
  selectConfigPopupFormStore,
  (configStore: Object) => {
    let options = [];
    let optionsGuids = [];
    const configValue = R.pathOr(null, ['dropdowns', GC.GENERAL_SERVICES, 'options'], configStore);
    if (G.isNotNil(configValue)) {
      options = configValue.map((item: Object) => ({
        label: `${item.displayedValue} (${item.storedValue})`,
        value: R.or(R.prop('parentGuid', item), R.prop('guid', item)),
      }));
      optionsGuids = configValue.map((item: Object) => R.or(
        R.prop('parentGuid', item),
        R.prop('guid', item),
      ));
    }
    return {
      options: ['', ...options],
      optionsGuids,
    };
  },
);

const makeSelectReasonsForStatusCode = () => createSelector(
  selectCommunicationConfigStore,
  selectConfigPopupFormStore,
  (configStore: Object) => {
    let multiOptions = [];
    let reasonGuids = [];

    const configValue = R.pathOr(
      null,
      ['dropdowns', GC.COMMUNICATION_REASON_CODE, 'options'],
      configStore,
    );

    if (G.isNotNil(configValue)) {
      multiOptions = R.map(({ guid, storedValue, parentGuid, displayedValue }: Object) => ({
        label: `${displayedValue} (${storedValue})`,
        value: R.or(parentGuid, guid),
      }), configValue);

      reasonGuids = R.map(({ guid, parentGuid }: Object) => R.or(parentGuid, guid), configValue);
    }

    return { multiOptions, reasonGuids };
  },
);

const makeSelectDocumentList = () => createSelector(
  selectCommunicationConfigStore,
  (configStore: Object) => configStore.documentsConfig,
);

const makeSelectDataSourceList = () => createSelector(
  selectCommunicationConfigStore,
  ({ dataSourceList }: Object) => dataSourceList,
);

const makeSelectFilteredDocumentList = () => createSelector(
  selectCommunicationConfigStore,
  ({ documentsConfig, documentsFilter }: Object) => R.filter(
    ({ dataSource }: Object) => R.or(
      G.isNilOrEmpty(documentsFilter),
      R.equals(dataSource, documentsFilter),
    ),
    R.values(documentsConfig),
  ),
);

const makeSelectDocumentFilter = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr('', ['documentsFilter']),
);

const makeSelectIndexedDataSourceList = () => createSelector(
  selectCommunicationConfigStore,
  ({ indexedDataSourceList }: Object) => indexedDataSourceList,
);

const makeSelectDocumentTypes = () => createSelector(
  selectCommunicationConfigStore,
  (configStore: Object) => ({
    documentTypeGuid: G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configStore, GC.COMMUNICATION_DOCUMENT_TYPE, true,
    ),
  }),
);

const makeSelectPopupFormValues = () => createSelector(
  selectConfigPopupFormStore,
  (form: Object) => (
    G.ifElse(
      G.isNotNil(form),
      G.getOrElse(form, 'values', {}),
      {},
    )),
);

const makeSelectAvailableDocumentTypes = () => createSelector(
  selectCommunicationConfigStore,
  ({ availableDocumentTypes }: Object) => availableDocumentTypes,
);

const makeSelectCloDispatchBoardActionPanel = () => createSelector(
  selectUIConfigStore,
  ({ cloDispatchBoardActionPanel }: Object) => R.or(cloDispatchBoardActionPanel, []),
);

const makeSelectTelDispatchBoardActionPanel = () => createSelector(
  selectUIConfigStore,
  ({ telDispatchBoardActionPanel }: Object) => R.or(telDispatchBoardActionPanel, []),
);

const makeSelectStatusCodeMappingList = () => createSelector(
  selectIntegrationStore,
  ({ statusCodeMappingList }: Object) => R.values(statusCodeMappingList),
);

const makeSelectStatusReasonCodeMappingList = () => createSelector(
  selectIntegrationStore,
  ({ statusReasonCodeMappingList }: Object) => R.values(statusReasonCodeMappingList),
);

const makeSelectStatusReasonCodeOutboundMappingList = () => createSelector(
  selectIntegrationStore,
  ({ statusReasonCodeOutboundMappingList }: Object) => R.values(statusReasonCodeOutboundMappingList),
);

const makeSelectPublicCLOConfig = () => createSelector(
  selectIntegrationStore,
  ({ publicCLOConfig }: Object) => publicCLOConfig,
);

const makeSelectServiceTypeMappingList = () => createSelector(
  selectIntegrationStore,
  ({ serviceTypeMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(serviceTypeMappingList),
);

const makeSelectDefaultOrderAccessorialList = () => createSelector(
  selectCLOStore,
  ({ defaultOrderAccessorialList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(defaultOrderAccessorialList),
);

const makeSelectDefaultCarrierAccessorialList = () => createSelector(
  selectTELStore,
  ({ defaultCarrierAccessorialList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(defaultCarrierAccessorialList),
);

const makeSelectCarrierSynchronizationConfig = () => createSelector(
  selectIntegrationStore,
  ({ carrierSynchronization }: Object) => carrierSynchronization,
);

const makeSelectCarrierIntegrationOverrideList = () => createSelector(
  selectIntegrationStore,
  ({ carrierIntegrationOverrideList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(carrierIntegrationOverrideList),
);

const makeSelectIntegrationMailConfig = () => createSelector(
  selectCommunicationConfigStore,
  ({ integrationMailConfig }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(integrationMailConfig),
);

const makeSelectDocumentTypeMappingList = () => createSelector(
  selectIntegrationStore,
  ({ documentTypeMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(documentTypeMappingList),
);

const makeSelectDocumentHubConfigList = () => createSelector(
  selectIntegrationStore,
  ({ documentHubConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(documentHubConfigList),
);

const makeSelectAccountingConfigList = () => createSelector(
  selectIntegrationStore,
  ({ accountingConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(accountingConfigList),
);

const makeSelectCarrierSequences = () => createSelector(
  selectIntegrationStore,
  ({ carrierSequences }: Object) => carrierSequences,
);

const makeSelectCarrierPickupRequesterListList = () => createSelector(
  selectIntegrationStore,
  ({ carrierPickupRequesterList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(carrierPickupRequesterList),
);

const makeSelectCarrierEdiLoaderConfigList = () => createSelector(
  selectIntegrationStore,
  ({ carrierEdiLoaderConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(carrierEdiLoaderConfigList),
);

const makeSelectCarrierEdiExporterConfigList = () => createSelector(
  selectIntegrationStore,
  ({ carrierEdiExporterConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(carrierEdiExporterConfigList),
);

const makeSelectTransportationModeConfigMappingList = () => createSelector(
  selectGeneralConfigStore,
  ({ transportationModeConfigMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(transportationModeConfigMappingList),
);

const makeSelectIntegrationTransportationModeConfigMappingList = () => createSelector(
  selectIntegrationStore,
  ({ transportationModeConfigMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(transportationModeConfigMappingList),
);

const makeSelectVinLookupConfigList = () => createSelector(
  selectIntegrationStore,
  ({ vinLookupConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(vinLookupConfigList),
);

const makeSelectLoadCustomStatusConfigList = () => createSelector(
  selectGeneralConfigStore,
  ({ loadCustomStatusConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(loadCustomStatusConfigList),
);

const makeSelectFactoringIntegrationConfigList = () => createSelector(
  selectIntegrationStore,
  ({ factoringIntegrationConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(factoringIntegrationConfigList),
);

const makeSelectEquipmentMappingConfigList = () => createSelector(
  selectIntegrationStore,
  ({ equipmentMappingConfigList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(equipmentMappingConfigList),
);

const makeSelectCommunicationMails = () => createSelector(
  selectCommunicationConfigStore,
  R.compose(
    R.filter(G.isNotNilAndNotEmpty),
    R.pick([GC.COMMUNICATION_BRANCH_EMAIL, GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL]),
    R.pathOr({}, ['initialValues']),
  ),
);

const makeSelectCurrentCommunicationMails = () => createSelector(
  selectFormStore,
  R.compose(
    R.pick([GC.COMMUNICATION_BRANCH_EMAIL, GC.COMMUNICATION_ACCOUNTING_DEPARTMENT_EMAIL]),
    R.pathOr({}, [CONFIG_PAGE_FORM, 'values']),
  ),
);

const makeSelectStatusCodeOutboundMappingList = () => createSelector(
  selectIntegrationStore,
  ({ statusCodeOutboundMappingList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(statusCodeOutboundMappingList),
);

const makeSelectMarginViolationRuleList = () => createSelector(
  selectRateEngineConfigStore,
  ({ marginViolationRuleList }: Object) => marginViolationRuleList,
);

const makeSelectOnboardingIntegrationList = () => createSelector(
  selectDriverConfigStore,
  ({ onboardingIntegrationList }: Object) => onboardingIntegrationList,
);

const makeSelectTrailerTrackingIntegrationList = () => createSelector(
  selectIntegrationStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['trailerTrackingIntegrationList']),
  ),
);

const makeSelectLosSubscriptionConfig = () => createSelector(
  selectIntegrationStore,
  ({ losSubscriptionConfig }: Object) => losSubscriptionConfig,
);

const makeSelectLosPricingPlanList = () => createSelector(
  selectIntegrationStore,
  ({ losPricingPlanList }: Object) => R.compose(G.sortByIndex, R.values)(losPricingPlanList),
);

const makeSelectCarrierScoreCardConfigList = () => createSelector(
  selectCarrierConfigStore,
  R.pathOr([], ['carrierScorecardConfigList']),
);

const makeSelectTerminalIntegrationList = () => createSelector(
  selectIntegrationStore,
  ({ terminalIntegrationList }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(terminalIntegrationList),
);

const makeSelectMailTemplateConfig = () => createSelector(
  selectCommunicationConfigStore,
  ({ mailTemplateConfig }: Object) => R.compose(
    R.sortBy(R.prop('index')),
    R.values,
  )(mailTemplateConfig),
);

const makeSelectMailTemplateTypes = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr([], ['mailTemplateTypes']),
);

const makeSelectMailTemplateNames = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr([], ['mailTemplateNames']),
);

const makeSelectMailTemplateByKey = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr({}, ['mailTemplateByKey']),
);

const makeSelectTaskTypeList = () => createSelector(
  selectTaskManagementStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['taskTypeList']),
  ),
);

const makeSelectTaskStatusList = () => createSelector(
  selectTaskManagementStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['taskStatusList']),
  ),
);

const makeSelectDocumentSendingList = () => createSelector(
  selectCommunicationConfigStore,
  R.pathOr([], ['documentSendingList']),
);

const makeSelectTransportationModeGroupingList = () => createSelector(
  selectGeneralConfigStore,
  R.pathOr([], ['transportationModeGroupingList']),
);

const makeSelectCrossBorderIntegrationConfigList = () => createSelector(
  selectIntegrationStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['crossBorderIntegrationConfigList']),
  ),
);

const makeSelectTruckTypeMappingList = () => createSelector(
  selectIntegrationStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['truckTypeMappingList']),
  ),
);

const makeSelectTrailerTypeMappingList = () => createSelector(
  selectIntegrationStore,
  R.compose(
    R.sortBy(R.prop('index')),
    R.values,
    R.pathOr({}, ['trailerTypeMappingList']),
  ),
);

export {
  selectConfigStore,
  makeSelectFinancial,
  makeSelectFormValues,
  makeSelectTabsVisited,
  makeSelectDocumentList,
  makeSelectConfigByName,
  makeSelectFuelCardList,
  makeSelectTaskTypeList,
  makeSelectConfigWarning,
  makeSelectConnectedToQB,
  makeSelectDocumentTypes,
  makeSelectConfigOptions,
  makeSelectDataSourceList,
  makeSelectTollChargeList,
  makeSelectEdiConfigsList,
  makeSelectDocumentFilter,
  makeSelectTaskStatusList,
  makeSelectPopupFormValues,
  makeSelectServicesOptions,
  makeSelectConfigDropdowns,
  makeSelectPublicCLOConfig,
  makeSelectExpenseTypeList,
  makeSelectCarrierSequences,
  selectConfigPopupFormStore,
  makeSelectCurrentGroupName,
  makeSelectAccessorialsList,
  makeSelectGLCodeMappingList,
  makeSelectMailTemplateTypes,
  makeSelectMailTemplateByKey,
  makeSelectMailTemplateNames,
  makeSelectMailTemplateConfig,
  makeSelectLosPricingPlanList,
  makeSelectGpsIntegrationList,
  makeSelectAdvancePaymentList,
  makeSelectCommunicationMails,
  makeSelectStatusMessagesList,
  makeSelectReasonCodesOptions,
  makeSelectGLCodeMappingFilter,
  makeSelectConfigInitialValues,
  makeSelectLoadBoardConfigList,
  makeSelectVinLookupConfigList,
  makeSelectDocumentSendingList,
  makeSelectReasonsForStatusCode,
  makeSelectQBAccountMappingList,
  makeSelectNotificationTriggers,
  makeSelectServiceMappingFilter,
  makeSelectFilteredDocumentList,
  makeSelectAccountingConfigList,
  makeSelectDeactivationRuleList,
  makeSelectTruckTypeMappingList,
  makeSelectDocumentHubConfigList,
  makeSelectStatusCodeMappingList,
  makeSelectOnboardingPackageList,
  makeSelectLosSubscriptionConfig,
  makeSelectConfigInheritedValues,
  makeSelectIntegrationConfigList,
  makeSelectIndexedDataSourceList,
  makeSelectIntegrationMailConfig,
  makeSelectEdiExporterConfigList,
  makeSelectConfigGuidsToItemsMap,
  makeSelectDriverPayrollStatuses,
  makeSelectInvoiceStatusRuleList,
  makeSelectCustomerIdMappingList,
  makeSelectServiceTypeMappingList,
  makeSelectCarrierInvoiceStatuses,
  makeSelectQBAccountMappingFilter,
  makeSelectEdiExtractorConfigList,
  makeSelectAvailableDocumentTypes,
  makeSelectSageAccountMappingList,
  makeSelectTrailerTypeMappingList,
  makeSelectDispatchIntegrationList,
  makeSelectCustomerInvoiceStatuses,
  makeSelectDocumentTypeMappingList,
  makeSelectServicesForAccessorials,
  makeSelectInvoiceStatusRuleFilter,
  makeSelectDepartmentIdMappingList,
  makeSelectMarginViolationRuleList,
  makeSelectTerminalIntegrationList,
  makeSelectServiceMappingConfigList,
  makeSelectNotificationListTriggers,
  makeSelectSageAccountMappingFilter,
  makeSelectFilteredNotificationList,
  makeSelectOnboardingIntegrationList,
  makeSelectCurrentCommunicationMails,
  makeSelectInvoiceStatusPriorityList,
  makeSelectEquipmentMappingConfigList,
  makeSelectTermsAndConditionsDocument,
  makeSelectCarrierEdiLoaderConfigList,
  makeSelectCarrierScoreCardConfigList,
  makeSelectNotificationTriggersFilter,
  makeSelectLoadCustomStatusConfigList,
  makeSelectDefaultOrderAccessorialList,
  makeSelectCloDispatchBoardActionPanel,
  makeSelectFleetServiceInvoiceStatuses,
  makeSelectTelDispatchBoardActionPanel,
  makeSelectStatusReasonCodeMappingList,
  makeSelectNotificationObjectTypeFilter,
  makeSelectCarrierEdiExporterConfigList,
  makeSelectDistanceCalculatorConfigList,
  makeSelectCarrierSynchronizationConfig,
  makeSelectDefaultCarrierAccessorialList,
  makeSelectStatusCodeOutboundMappingList,
  makeSelectOnboardingPackageDocumentList,
  makeSelectCarrierIntegrationOverrideList,
  makeSelectFactoringIntegrationConfigList,
  makeSelectCarrierPickupRequesterListList,
  makeSelectTransportationModeGroupingList,
  makeSelectTrailerTrackingIntegrationList,
  makeSelectFilteredServiceMappingConfigList,
  makeSelectCarrierRateIntegrationConfigList,
  makeSelectCrossBorderIntegrationConfigList,
  makeSelectStatusReasonCodeOutboundMappingList,
  makeSelectUserMonitoringIntegrationConfigList,
  makeSelectTransportationModeConfigMappingList,
  makeSelectOrderQuoteTermsAndConditionsDocument,
  makeSelectIntegrationTransportationModeConfigMappingList,
};

import * as R from 'ramda';
import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// components
import { EditReport } from '../../../components/edit-report';
import { ConfirmComponent } from '../../../components/confirm';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import { getCreateOrUpdateItemModal } from '../helpers';
import {
  makeSelectUsedReportByGroupName,
  makeSelectReportStatusByGroupName,
  makeSelectAvailableReportsByGroupName,
} from '../selectors';
import {
  removeEntityRequest,
  resetListAndPagination,
  getReportItemListRequest,
  createOrUpdateEntityRequest,
  // report
  setReports,
  setUsedReport,
  setReportPending,
  setTableTitleFilter,
  createReportRequest,
  updateReportRequest,
  setTableTitleSortValues,
  changeDefaultReportRequest,
  getAvailableReportsRequest,
  toggleFormGroupTableDetails,
} from '../actions';
//////////////////////////////////////////////////

export const useReportFormGroupOperations = (props: Object) => {
  const {
    isReport,
    itemList,
    groupName,
    endpoints,
    openModal,
    reportType,
    openLoader,
    closeLoader,
    defaultValues,
    branchConfigs,
    makeInitialValues,
    primaryObjectGuid,
    accessorialConfigs,
    makeRequestPayload,
    customRemoveHandler,
    primaryObjectGuidKey,
  } = props;

  const dispatch = useDispatch();

  const selectedReport = useSelector(makeSelectUsedReportByGroupName(groupName));
  const reportList = useSelector(makeSelectAvailableReportsByGroupName(groupName));
  const requestPending = useSelector(makeSelectReportStatusByGroupName(groupName));

  const handleResetListAndPagination = () => dispatch(resetListAndPagination({ groupName }));

  const getItemListRequest = useCallback(async () => {
    const endpointName = G.getPropFromObject('list', endpoints);

    if (G.isNilOrEmpty(endpointName)) return;

    dispatch(getReportItemListRequest({
      groupName,
      reportType,
      reqParams: makeRequestPayload(props),
      endpoint: G.getPropFromObject(endpointName, endpointsMap),
    }));
  }, [groupName]);

  const getEntityRequest = async (guid: string) => {
    openLoader();

    const endpoint = R.path([G.getPropFromObject('entity', endpoints)], endpointsMap)(guid);

    const res = await sendRequest('get', endpoint);

    const { data, status } = res;

    let entity = {};

    if (G.isResponseSuccess(status)) {
      entity = data;
    } else {
      G.handleFailResponseSimple(res);
    }

    closeLoader();

    return entity;
  };

  const handleCreateOrUpdateItem = useCallback(async (entity: Object) => {
    const entityGuid = G.getGuidFromObject(entity);
    const isEditMode = G.isNotNil(entityGuid);
    const endpointName = G.getPropFromObject('createOrUpdate', endpoints);

    let initialValues = R.assoc(primaryObjectGuidKey, primaryObjectGuid, defaultValues);

    if (isEditMode) {
      initialValues = await getEntityRequest(entityGuid);

      if (R.isEmpty(initialValues)) return;
    }

    if (G.isFunction(makeInitialValues)) initialValues = makeInitialValues(initialValues, props);

    const method = G.ifElse(isEditMode, 'put', 'post');

    const submitAction = (values: Object, additionalOptions: Object) => dispatch(createOrUpdateEntityRequest({
      method,
      values,
      isReport,
      groupName,
      additionalOptions,
      endpoint: G.getPropFromObject(endpointName, endpointsMap),
      getItemListRequest: () => {
        handleResetListAndPagination();

        getItemListRequest();
      },
    }));

    const modal = getCreateOrUpdateItemModal({
      ...props,
      isEditMode,
      submitAction,
      initialValues,
    });

    openModal(modal);
  }, [groupName, branchConfigs, accessorialConfigs]);

  const handleRemoveItem = useCallback((guid: string) => {
    const { itemTitleArr } = props;

    const component = (
      <ConfirmComponent
        name={G.getWindowLocale(...itemTitleArr)}
        textLocale={G.getWindowLocale('messages:before:remove', 'Are you sure you want to remove')}
      />
    );

    const endpointName = G.getPropFromObject('entity', endpoints);

    let endpoint = G.getPropFromObject(endpointName, endpointsMap);

    if (G.isFunction(endpoint)) endpoint = endpoint(guid);

    let removeAction = () => dispatch(removeEntityRequest({ guid, isReport, endpoint, groupName }));

    if (G.isFunction(customRemoveHandler)) {
      removeAction = () => customRemoveHandler(props);
    }

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            action: removeAction,
            name: G.getWindowLocale('actions:remove', 'Remove'),
          },
        ],
      },
    };

    openModal(modal);
  }, [groupName, customRemoveHandler]);

  const handleToggleFormGroupTableDetails = ({ guid }: Object) =>
    dispatch(toggleFormGroupTableDetails({ guid, groupName }));

  const handleSetReports = (reports: Array) => dispatch(setReports({ reports, groupName }));
  const handleSetUsedReport = (usedReport: Object) => dispatch(setUsedReport({ groupName, usedReport }));

  const handleCreateReportRequest = (newReport: Object) =>
    dispatch(createReportRequest({ groupName, newReport, reportType, getItemListRequest }));

  const handleUpdateReportRequest = (newReport: Object) =>
    dispatch(updateReportRequest({ groupName, newReport, reportType, getItemListRequest }));

  const handleChangeDefaultReportRequest = (data: Object) =>
    dispatch(changeDefaultReportRequest({ data, groupName, reportType, getItemListRequest }));

  const handleSelectReport = (reportGuid: string, asDefault: boolean) => {
    const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

    handleSetUsedReport(selectedReport);

    if (R.not(asDefault)) getItemListRequest();
  };

  const handleTableTitleFilter = useCallback((data: Object) => {
    const { sortData, isSorting } = data;

    if (isSorting) {
      dispatch(setTableTitleSortValues({ groupName, data: sortData }));
    } else {
      const filter = G.createReportFilterFromTitleSearch(data);

      dispatch(setTableTitleFilter({ groupName, data: filter }));
    }

    handleResetListAndPagination();
    getItemListRequest();
  }, [groupName]);

  const handleEditReport = (fields: Array) => {
    const component = (
      <EditReport
        fields={fields}
        hasIssues={false}
        usedReport={selectedReport}
        setReport={handleSetUsedReport}
        requestPending={requestPending}
        onReportSet={getItemListRequest}
        updateReportRequest={handleUpdateReportRequest}
        createReportRequest={handleCreateReportRequest}
      />
    );

    const modal = G.getDefaultReportModal(component);

    openModal(modal);
  };

  useEffect(() => {
    if (R.and(R.isNil(itemList), R.isNotNil(reportType))) {
      dispatch(setReportPending({ groupName }));

      dispatch(getAvailableReportsRequest(
        { groupName, reportType, setUsedReport: true, callback: getItemListRequest },
      ));
    } else {
      handleResetListAndPagination();
      getItemListRequest();
    }
  }, []);

  return {
    handleRemoveItem,
    getItemListRequest,
    handleCreateOrUpdateItem,
    handleResetListAndPagination,
    handleToggleFormGroupTableDetails,
    // report
    handleSetReports,
    handleEditReport,
    handleSelectReport,
    handleSetUsedReport,
    handleTableTitleFilter,
    handleCreateReportRequest,
    handleUpdateReportRequest,
    handleChangeDefaultReportRequest,
  };
};

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
// feature load-board
import { getLBDisplayValue } from '../helpers';
import { activateSearchFilterState } from '../actions';
import { makeSelectSearchFilterStates } from '../selectors';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.dark.blue');

const State = ({ data, onActivate }: Object) => {
  const { guid, type, status, description } = data;

  const failed = R.equals(status, GC.LB_STATUS_FAILED);
  const failedText = R.or(description, G.getWindowLocale('titles:failed', 'Failed'));

  return (
    <Flex alignItems='flex-start'>
      <Flex flexDirection='column' alignItems='flex-start'>
        <Box>
          {getLBDisplayValue(type)} ({G.getEnumLocale(status)})
        </Box>
        { failed && (
          <StyledLink
            cursor='pointer'
            color={blueColor}
            textTransform='lowercase'
            onClick={() => onActivate(guid)}
          >{G.getWindowLocale('titles:activate', 'Activate')}</StyledLink>
        )}
      </Flex>
      {
        failed &&
        <Box mx={10} title={failedText}> - {failedText}</Box>
      }
    </Flex>
  );
};

const LoadBoardStates = ({
  states,
  filter,
  activateSearchFilterState,
}: Object) => {
  const lbStates = R.propOr([], G.getGuidFromObject(filter), states);

  return (
    <Box>
      <Flex
        gap={16}
        width='100%'
        flexDirection='column'
        alignItems='flex-start'
      >
        {
          lbStates.map(
            (state: Object) => <State key={state.type} data={state} onActivate={activateSearchFilterState} />,
          )
        }
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  states: makeSelectSearchFilterStates(state),
});

export default connect(mapStateToProps, {
  activateSearchFilterState,
})(LoadBoardStates);

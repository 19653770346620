import * as R from 'ramda';
import { sub, format, endOfToday, startOfDay } from 'date-fns';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const getTotalsForCard = (chartType: string, dashboard: Object) => {
  const total = R.pathOr(0, [chartType, 'value'], dashboard);
  const prevTotal = R.pathOr(0, [chartType, 'previousPeriodValue'], dashboard);

  return { total, prevTotal };
};

const getFormattedDateRangeFilters = (days: number, dateFormat: any = 'MM/dd/yyyy') => {
  const to = endOfToday();

  const from = startOfDay(sub(to, { days: R.dec(days) }));

  const dateTo = format(to, dateFormat);

  const dateFrom = format(from, dateFormat);

  return { dateTo, dateFrom };
};

const getDataForAreaAndLineChartFromDashboard = (data: Array) => R.map(
  ({ name, groups }: Object) => {
    const ret = { name };

    groups.forEach((g: Object) => {
      ret[g.name] = g.value;
    });

    return ret;
  },
)(data);

const chartDateMapper = (item: Object) => R.assoc(
  'name',
  G.checkAndConvertMomentInstanceOrStringToFormattedDate(item.name, 'MMM D'),
  item,
);

const getDomainYAndTicksYByChartData = (data: Object) => {
  const max = R.compose(
    R.reduce(R.max, 0),
    R.map((item: Object) => R.reduce((acc: number, { value }: Object) => R.add(acc, value), 0, item.groups)),
    R.pathOr([], ['groups']),
  )(data);

  const magnitude = G.getOrderOfMagnitude(max);

  const nearest = 10 ** magnitude;

  const nearestMax = G.roundToNearest(max, nearest);

  const min = R.divide(nearestMax, 5);

  const ticksY = R.range(0, 5).map(R.multiply(min));

  return { ticksY: [...ticksY, Math.round(max)], domainY: [0, Math.round(max)] };
};

const getLabelFormatter = R.curry((labelFormatter: any, props: Object) => {
  const value = G.getValueFromObject(props);

  if (G.isFunction(labelFormatter)) return labelFormatter(value);

  return value;
});

const getChartNumberRangeFilter = (value: number) => {
  let from = 0;
  let to = 50000;

  if (R.equals('all', value)) return null;

  if (G.isZero(value)) {
    to = 200;
  } else if (R.equals(value, 200)) {
    from = 200;
    to = 500;
  } else if (R.equals(value, 500)) {
    from = 500;
  }

  return { to, from };
};

const getChartTitleWithCurrency = (title: string, currencySymbol: string) => {
  if (R.equals(currencySymbol, GC.DEFAULT_UI_CURRENCY_SYMBOL)) return title;

  return `${title} (${currencySymbol})`;
};

const getChartTickFormatterWithCurrency = R.curry((currencySymbol: string, tick: number) => {
  if (G.isZero(tick)) return '';

  if (R.equals(currencySymbol, GC.DEFAULT_UI_CURRENCY_SYMBOL)) return `${tick} ${currencySymbol}`;

  return tick;
});

export {
  chartDateMapper,
  getTotalsForCard,
  getLabelFormatter,
  getChartNumberRangeFilter,
  getChartTitleWithCurrency,
  getFormattedDateRangeFilters,
  getDomainYAndTicksYByChartData,
  getChartTickFormatterWithCurrency,
  getDataForAreaAndLineChartFromDashboard,
};

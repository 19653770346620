import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
//////////////////////////////////////////////////

const AutodialButtonUI = ({ styles, iconOnly, configs, toggleAutodial }: Object) => {
  let text;
  let icon;
  let color;

  if (configs.autodialOn) {
    text = G.getWindowLocale('actions:stop-loadboard-autodial', 'Stop Autodial');
    color = G.getTheme('colors.light.mainRed');
    icon = 'pauseInRound';
  } else {
    text = G.getWindowLocale('actions:start-loadboard-autodial', 'Start Autodial');
    color = G.getTheme('colors.light.blue');
    icon = 'playInRound';
  }

  return (
    <Flex
      title={text}
      cursor='pointer'
      justifyContent='center'
      onClick={toggleAutodial}
      m={R.pathOr(13, ['margin'], styles)}
    >
      {R.not(iconOnly) && <Box mr={6} color={color}>{text}</Box>}
      {I[icon](color, 24, 24)}
    </Flex>
  );
};

export default AutodialButtonUI;

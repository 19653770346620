import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Flex } from '../../../ui';
// features dashboards
import LargeCard from './large-card';
import TotalCard from './total-card';
import MiddleCard from './middle-card';
import { TITLES_MAP } from '../constants';
import { mockResData } from '../trailers-mock-data';
import {
  chartDateMapper,
  getTotalsForCard,
  getChartTitleWithCurrency,
  getDomainYAndTicksYByChartData,
  getChartTickFormatterWithCurrency,
} from '../helpers';
//////////////////////////////////////////////////

const dashboard = R.indexBy(R.prop('chartType'), mockResData);

const currencySymbol = G.getCurrencySymbol2(GC.CURRENCY_TYPE_USD);

const days = 30;

const TrailersCharts = () => {
  const inServiceTrailers = R.compose(
    R.map((item: Object) => R.assoc('name', TITLES_MAP[item.name], item)),
    R.pathOr([], ['IN_SERVICE_TRAILERS', 'groups']),
  )(dashboard);

  const trailersByCarrier = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['TRAILERS_BY_CARRIER', 'groups']),
  )(dashboard);

  const mostServicedTrailers = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['MOST_SERVICED_TRAILERS', 'groups']),
  )(dashboard);

  const openedIssuesBySeverity = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['OPENED_ISSUES_BY_SEVERITY', 'groups']),
  )(dashboard);

  const serviceCost = R.compose(
    R.map(chartDateMapper),
    R.pathOr([], ['SERVICE_COST', 'groups']),
  )(dashboard);

  const top5Services = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['TOP_5_SERVICES', 'groups']),
  )(dashboard);

  const serviceByVendor = R.compose(
    R.map((item: Object) => R.assoc('name', item.name, item)),
    R.pathOr([], ['SERVICE_BY_VENDOR', 'groups']),
  )(dashboard);

  const createdResolvedIssues = R.compose(
    R.map(chartDateMapper),
    R.pathOr([], ['CREATED_RESOLVED_ISSUES', 'groups']),
  )(dashboard);


  const fromLastDaysDescription = `${TITLES_MAP.fromLast} ${days} ${TITLES_MAP.days}`;

  return (
    <Flex p={20} alignItems='start'>
      <Box width={1080}>
        <Flex mb={20}>
          <TotalCard
            description={fromLastDaysDescription}
            title={TITLES_MAP.availableTrailers}
            {...getTotalsForCard('AVAILABLE_TRAILERS', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.accidents}
            revertLowPercentageTextColor={true}
            description={fromLastDaysDescription}
            {...getTotalsForCard('ACCIDENTS', dashboard)}
          />
          <TotalCard
            title={TITLES_MAP.incidents}
            revertLowPercentageTextColor={true}
            description={fromLastDaysDescription}
            {...getTotalsForCard('INCIDENTS', dashboard)}
          />
          <TotalCard
            withoutPercentage={true}
            description={TITLES_MAP.days}
            title={TITLES_MAP.nextMaintenance}
            {...getTotalsForCard('NEXT_MAINTENANCE', dashboard)}
          />
        </Flex>
        <Flex mb={20}>
          <TotalCard
            totalPrefix={currencySymbol}
            title={TITLES_MAP.totalCost}
            description={fromLastDaysDescription}
            {...getTotalsForCard('TOTAL_COST', dashboard)}
          />
          <TotalCard
            totalPrefix={currencySymbol}
            description={fromLastDaysDescription}
            title={TITLES_MAP.averageCostPerTrailer}
            {...getTotalsForCard('AVERAGE_COST_PER_TRAILER', dashboard)}
          />
          <TotalCard
            withoutPercentage={true}
            title={TITLES_MAP.costPerMile}
            description={TITLES_MAP.cents}
            {...getTotalsForCard('COST_PER_MILE', dashboard)}
          />
          <TotalCard
            withoutPercentage={true}
            totalPrefix={currencySymbol}
            description={TITLES_MAP.month}
            title={TITLES_MAP.budgetForecast}
            {...getTotalsForCard('BUDGET_FORECAST', dashboard)}
          />
        </Flex>
        <Flex alignItems='start' flexDirection='column'>
          <LargeCard
            // {...getDomainYAndTicksYByChartData(G.getPropFromObject('SERVICE_COST', dashboard))}
            height={480}
            data={serviceCost}
            chartType='SIMPLE_BAR'
            tickFormatter={getChartTickFormatterWithCurrency(currencySymbol)}
            title={getChartTitleWithCurrency(TITLES_MAP.serviceCost, currencySymbol)}
          />
          {/* SimpleBarChartComponent */}
          <Flex mb={20}>
            <TotalCard
              description={fromLastDaysDescription}
              title={TITLES_MAP.completedInspections}
              {...getTotalsForCard('COMPLETED_INSPECTIONS', dashboard)}
            />
            <TotalCard
              title={TITLES_MAP.averageInspectionTime}
              {...getTotalsForCard('AVERAGE_INSPECTION_TIME', dashboard)}
            />
            <TotalCard
              title={TITLES_MAP.issuesFound}
              description={fromLastDaysDescription}
              {...getTotalsForCard('ISSUES_FOUND', dashboard)}
            />
            <TotalCard
              withoutPercentage={true}
              description={TITLES_MAP.days}
              title={TITLES_MAP.averageIssuesAge}
              {...getTotalsForCard('AVERAGE_ISSUE_AGE', dashboard)}
            />
          </Flex>
          <LargeCard
            {...getDomainYAndTicksYByChartData(G.getPropFromObject('CREATED_RESOLVED_ISSUES', dashboard))}
            height={480}
            lineDataKey='created'
            areaDataKey='resolved'
            chartType='AREA_AND_LINE'
            data={createdResolvedIssues}
            lineName={TITLES_MAP.created}
            areaName={TITLES_MAP.resolved}
            title={TITLES_MAP.createdResolvedIssues}
          />
        </Flex>
      </Box>
      <Box width={520}>
        <Flex alignItems='start' flexDirection='column'>
          <MiddleCard
            height={360}
            chartType='SCORE'
            data={trailersByCarrier}
            title={TITLES_MAP.trailersByCarrier}
          />
          <MiddleCard
            height={380}
            chartType='PIE'
            showPercent={true}
            data={serviceByVendor}
            title={TITLES_MAP.serviceByVendor}
          />
          <MiddleCard
            height={380}
            chartType='PIE'
            data={top5Services}
            title={TITLES_MAP.top5services}
            pieProps={{ innerRadius: 70, paddingAngle: 10 }}
          />
          <MiddleCard
            height={310}
            chartType='SCORE'
            data={mostServicedTrailers}
            title={TITLES_MAP.mostServicedTrailers}
          />
          <MiddleCard
            height={380}
            chartType='PIE'
            showPercent={true}
            data={openedIssuesBySeverity}
            title={TITLES_MAP.openedIssuesBySeverity}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default TrailersCharts;

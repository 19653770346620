import React from 'react';
import * as R from 'ramda';
// components
import {
  getTruckingMethodOptions,
  getInspectionTypeOptions,
  getInspectionStatusOptions,
  getInspectionSourceOptions,
} from '../../../components/filter/settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { asyncGetFileAndOpenInWindow } from '../../../helpers/api-async';
import { priorityTypeOptions, fleetServiceIssueStatusOptions } from '../../../helpers/options';
// utilities
import endpointsMap from '../../../utilities/endpoints';
// ui
import { Span } from '../../../ui';
// report-common
import { getFilterParamsFromColumnSettings } from '../../../report-common';
// feature inspections
import { createdByMap } from '../constants';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');

const CreatedBy = ({ name }: Object) => {
  if (G.isNilOrEmpty(name)) return null;

  return (
    <div>{R.propOr(name, name, createdByMap)}</div>
  );
};

const columnSettings = {
  [GC.FIELD_ID]: {
    width: 150,
    name: 'titles:id',
  },
  [GC.FIELD_NAME]: {
    width: 150,
    name: 'titles:name',
  },
  [GC.FIELD_STATUS]: {
    width: 150,
    type: 'enum',
    name: 'titles:status',
    filter: {
      type: 'selectMultiple',
      options: getInspectionStatusOptions(),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  },
  [GC.FIELD_START_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:start-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_END_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:end-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_SOURCE]: {
    width: 200,
    type: 'enum',
    name: 'titles:source',
    filter: {
      type: 'selectMultiple',
      options: getInspectionSourceOptions(),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  },
  [GC.FIELD_TRUCKING_METHOD]: {
    width: 200,
    type: 'enum',
    name: 'titles:trucking-method',
    filter: {
      type: 'selectMultiple',
      options: getTruckingMethodOptions(),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  },
  [GC.FIELD_TYPE]: {
    width: 200,
    type: 'enum',
    name: 'titles:type',
    filter: {
      type: 'selectMultiple',
      options: getInspectionTypeOptions(),
      placeholder: {
        text: 'Choose',
        key: 'titles:choose',
      },
    },
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: 'titles:created-by',
    customComponent: CreatedBy,
  },
  [GC.FIELD_CREATED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:created-date',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: 'titles:updated-by',
    customComponent: CreatedBy,
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    width: 200,
    type: 'date',
    name: 'titles:update-on',
    filter: {
      type: 'date',
    },
  },
  [GC.FIELD_DESCRIPTION]: {
    width: 200,
    name: 'titles:description',
  },
  [GC.FIELD_SIGNATURE_FILE_NAME]: {
    width: 150,
    name: 'titles:signature',
    customComponent: ({ data }: Object) => {
      const { guid, signatureFileName } = data;

      if (G.isNilOrEmpty(signatureFileName)) return null;

      return (
        <Span
          cursor='pointer'
          color={blueColor}
          wordBreak='break-word'
          onClick={() => asyncGetFileAndOpenInWindow(endpointsMap.getFleetInspectionsDownloadSignature(guid))}
        >
          {signatureFileName}
        </Span>
      );
    },
  },
  [GC.FIELD_TEMPLATE_NAME]: {
    width: 150,
    name: 'titles:template',
  },
  [GC.GRC.FLEET_TRAILER_UNIT_ID]: {
    width: 150,
    name: 'titles:trailer',
  },
  [GC.GRC.FLEET_TRUCK_UNIT_ID]: {
    width: 150,
    name: 'titles:truck',
  },
  [GC.GRC.LOCATION_ZIP]: {
    width: 200,
    name: ['titles:location', 'titles:zip'],
  },
  [GC.GRC.LOCATION_CITY]: {
    width: 200,
    name: ['titles:location', 'titles:city'],
  },
  [GC.GRC.LOCATION_STATE]: {
    width: 200,
    name: ['titles:location', 'titles:state'],
  },
  [GC.GRC.LOCATION_COUNTRY]: {
    width: 200,
    name: ['titles:location', 'titles:country'],
  },
  [GC.GRC.LOCATION_ADDRESS]: {
    width: 200,
    name: ['titles:location', 'titles:address'],
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_ID]: {
    name: ['titles:issues', 'titles:issue-id'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_SUMMARY]: {
    name: ['titles:issues', 'titles:summary'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_STATUS]: {
    type: 'collectionOfEnums',
    name: ['titles:issues', 'titles:status'],
    filter: {
      collection: true,
      type: 'selectMultiple',
      options: fleetServiceIssueStatusOptions,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_PRIORITY]: {
    type: 'collectionOfEnums',
    name: ['titles:issues', 'titles:priority'],
    filter: {
      collection: true,
      type: 'selectMultiple',
      options: priorityTypeOptions,
    },
  },
  [GC.GRC.FLEET_SERVICE_ISSUES_DESCRIPTION]: {
    name: ['titles:issues', 'titles:description'],
    filter: {
      type: 'string',
      collection: true,
    },
  },
};

const tableSettings = {
  minHeight: 320,
  cellFontSize: 13,
  titleFontSize: 14,
  titleRowHeight: 45,
  tableRowHeight: 40,
  allowEditBtn: true,
  useMainColors: true,
  allowSelectAll: true,
  checkBoxCellWidth: 90,
  allowSelectItems: true,
  searchableTitles: true,
  maxHeight: 'calc(100vh - 155px)',
  checkBoxCellJustifyContent: 'unset',
};

const FILTER_PARAMS = getFilterParamsFromColumnSettings(columnSettings);

export {
  FILTER_PARAMS,
  tableSettings,
  columnSettings,
};

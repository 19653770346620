import * as R from 'ramda';
import { put, call, fork, select, takeEvery, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { generateDefaultReport } from '../../../report-common';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import * as A from '../actions';
import fleetProfileTruckWatcherSaga from './truck';
import fleetProfileDriverWatcherSaga from './driver';
import fleetProfileVendorWatcherSaga from './vendor';
import fleetProfileTrailerWatcherSaga from './trailer';
import {
  makeSelectEntityBranchGuid,
  makeSelectPaginationByGroupName,
  makeSelectUsedReportByGroupName,
  makeSelectTitleSortValuesByGroupName,
  makeSelectAvailableReportsByGroupName,
  makeSelectTableTitleFiltersByGroupName,
} from '../selectors';
//////////////////////////////////////////////////

function* getItemListRequest({ payload }: Object) {
  try {
    const { endpoint, groupName, requestOptions } = payload;

    const res = yield call(sendRequest, 'get', endpoint, requestOptions);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, groupName }));
    } else {
      yield call(G.handleFailResponse, res, 'getItemListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getItemListRequest exception');
  }
}

function* getReportItemListRequest({ payload }: Object) {
  try {
    const { endpoint, groupName, reqParams, reportType } = payload;

    yield put(A.setListLoading({ groupName, loading: true }));

    const reportParams = yield select(makeSelectUsedReportByGroupName(groupName));
    const availableReports = yield select(makeSelectAvailableReportsByGroupName(groupName));

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.pathEq(`${reportType}Default`, [GC.FIELD_GUID], reportParams),
    )) return yield put(A.setListLoading({ groupName, loading: false }));

    const pagination = yield select(makeSelectPaginationByGroupName(groupName));
    const titleOrderFields = yield select(makeSelectTitleSortValuesByGroupName(groupName));
    const tableTitleFilters = yield select(makeSelectTableTitleFiltersByGroupName(groupName));

    const searchCriteria = G.ifElse(
      G.isNotEmpty(tableTitleFilters),
      R.values(tableTitleFilters),
      R.pathOr([], ['searchCriteria'], reportParams),
    );

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      R.pathOr([], ['orderFields'], reportParams),
    );

    const { reportFieldsToOmit, ...reqPayload } = reqParams;

    const reportFields = R.or(R.prop('fields', reqPayload), R.pathOr([], ['fields'], reportParams));
    const searchCriteriaToUse = R.concat(searchCriteria, R.propOr([], 'searchCriteria', reqPayload));

    const reqData = {
      ...reqPayload,
      ...pagination,
      orderFields,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteriaToUse, reportType),
      fields: G.isAnyNilOrEmpty([reportFields, reportFieldsToOmit])
        ? reportFields
        : R.reject(({ name }: Object) => R.includes(name, reportFieldsToOmit), reportFields),
    };

    const res = yield call(sendRequest, 'post', endpoint, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getReportItemListSuccess({ data, groupName }));
    } else {
      yield call(G.handleFailResponse, res, 'getReportItemListRequest fail');
    }

    yield put(A.setListLoading({ groupName, loading: false }));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getReportItemListRequest exception');
  }
}

function* getAvailableReportsRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { callback, groupName, reportType, setUsedReport } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const params = {
      reportType,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = G.getReportsSortedBySeqFreez(data);
      const checkedReports = checkReportFunction(reports);

      yield put(A.setReports({ groupName, reports: checkedReports }));

      if (R.not(R.prop('length', reports))) {
        yield put(closeLoader());

        return yield put(A.setUsedReport({ groupName, usedReport: generateDefaultReport(reportType) }));
      }

      if (G.isTrue(setUsedReport)) {
        const defaultReport = G.findDefaultReport(checkedReports);
        const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

        yield put(A.setUsedReport({ groupName, usedReport }));
      }

      G.callFunction(callback);
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableReportsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getAvailableReportsRequest exception');
  }
}

function* createReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { groupName, newReport, reportType, getItemListRequest } = payload;

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newReport),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport({ groupName, usedReport: G.getReportSortedBySeqFreez(report) }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'createReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createReportRequest exception');
  }
}

function* updateReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { groupName, newReport, reportType, getItemListRequest } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.report, { data: newReport });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const report = R.head(checkReportFunction(R.of(Array, data)));

      yield put(A.setUsedReport({ groupName, usedReport: G.getReportSortedBySeqFreez(report) }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'updateReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'updateReportRequest exception');
  }
}

function* changeDefaultReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, groupName, reportType, getItemListRequest } = payload;

    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination({ groupName }));

      yield put(A.getAvailableReportsRequest({
        groupName,
        reportType,
        setUsedReport: false,
        callback: getItemListRequest,
      }));
    } else {
      yield call(G.handleFailResponse, res, 'changeDefaultReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeDefaultReportRequest exception');
  }
}

function* createOrUpdateEntityRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const {
      values,
      method,
      isReport,
      endpoint,
      groupName,
      requestPayload,
      getItemListRequest,
      additionalOptions = {},
    } = payload;

    const { failCallback, successCallback, shouldCloseModal = true } = additionalOptions;

    const options = G.ifElse(G.isNotNilAndNotEmpty(requestPayload), requestPayload, { data: values });

    const res = yield call(sendRequest, method, endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (shouldCloseModal) yield put(closeModal());

      if (G.isFunction(successCallback)) successCallback();


      if (R.and(isReport, G.isNotNil(R.prop(GC.FIELD_VERSION, values)))) {
        yield put(A.setPagination({ groupName, increase: true }));
      }

      if (G.isFunction(getItemListRequest)) {
        getItemListRequest();
      } else {
        yield put(A.createOrUpdateEntitySuccess({
          groupName,
          data: G.ifElse(G.isArray(data), R.head(data), data),
        }));
      }

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateEntityRequest fail');

      if (G.isFunction(failCallback)) failCallback();
    }

    yield put(closeLoader());
  } catch (error) {
    if (G.isFunction(payload.failCallback)) payload.failCallback();

    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateEntityRequest exception');
  }
}

function* removeEntityRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, isReport, endpoint, groupName } = payload;

    if (isReport) yield put(A.setListLoading({ groupName, loading: true }));

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeEntitySuccess({ guid, groupName }));

      if (isReport) yield put(A.setListLoading({ groupName, loading: false }));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeEntityRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeEntityRequest exception');
  }
}

function* changeAssignedToDivisionRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { type, values, driverAssignmentGuid } = payload;

    const options = { data: values };

    const endpoints = {
      truck: endpointsMap.changeTruckAssignedToDivision,
      driver: endpointsMap.changeDriverAssignedToDivision,
      trailer: endpointsMap.changeTrailerAssignedToDivision,
    };

    const endpoint = R.prop(type, endpoints);

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield put(A.changeAssignedToDivisionSuccess({
        type,
        [GC.FIELD_ASSIGNED_TO_DIVISION_GUID]: R.pathOr(null, [GC.FIELD_GUID], data),
      }));

      if (R.isNotNil(driverAssignmentGuid)) yield put(A.getDriverAssignmentRequest(driverAssignmentGuid));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'changeAssignedToDivisionRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'changeAssignedToDivisionRequest exception');
  }
}

function* assignOrUnAssignVendorRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { type, unassign, vendorGuid, failCallback, successCallback, primaryObjectGuid } = payload;

    const endpoint = R.prop(
      G.ifElse(unassign, 'unassignVendor', 'assignVendor'),
      endpointsMap,
    )(type, primaryObjectGuid);

    const options = {
      params: { vendorGuid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.callFunction(successCallback);

      yield put(closeModal());
      yield put(A.assignOrUnAssignVendorSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      G.callFunction(failCallback);

      yield call(G.handleFailResponse, res, 'assignOrUnAssignVendorRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    G.callFunction(payload.failCallback);

    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'assignOrUnAssignVendorRequest exception');
  }
}

// accessorial configs
function* getAccessorialConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectEntityBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.accessorialsListEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAccessorialConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getAccessorialConfigListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialConfigListRequest exception');
  }
}

// configs
function* getFleetProfileConfigsRequest({ payload }: Object) {
  try {
    const options = {
      params: payload,
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetProfileConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleException, 'error', 'getFleetProfileConfigsRequest');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getFleetProfileConfigByGroupSaga');
  }
}

export function* fleetProfileWatcherSaga() {
  // common
  yield takeLatest(A.getItemListRequest, getItemListRequest);
  yield takeLatest(A.removeEntityRequest, removeEntityRequest);
  yield takeLatest(A.createReportRequest, createReportRequest);
  yield takeLatest(A.updateReportRequest, updateReportRequest);
  yield takeEvery(A.getReportItemListRequest, getReportItemListRequest);
  yield takeEvery(A.getAvailableReportsRequest, getAvailableReportsRequest);
  yield takeLatest(A.changeDefaultReportRequest, changeDefaultReportRequest);
  yield takeLatest(A.createOrUpdateEntityRequest, createOrUpdateEntityRequest);
  // working division
  yield takeLatest(A.changeAssignedToDivisionRequest, changeAssignedToDivisionRequest);
  // assign vendor
  yield takeLatest(A.assignOrUnAssignVendorRequest, assignOrUnAssignVendorRequest);
  // accessorial configs
  yield takeLatest(A.getAccessorialConfigListRequest, getAccessorialConfigListRequest);
  // configs
  yield takeLatest(A.getFleetProfileConfigsRequest, getFleetProfileConfigsRequest);
  // driver
  yield fork(fleetProfileDriverWatcherSaga);
  // vendor
  yield fork(fleetProfileVendorWatcherSaga);
  // truck
  yield fork(fleetProfileTruckWatcherSaga);
  // trailer
  yield fork(fleetProfileTrailerWatcherSaga);
}

export default fleetProfileWatcherSaga;

import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { socketTelRateReceived } from '../../sockets/actions';
import {
  selectDriverCarrierRateSuccess,
  updateLoadCarrierInvoiceSuccess,
} from '../../dispatch-details-new/load/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature invoice/service-vendor
import * as A from './actions';
import { changeInvoicesSuccess } from '../helpers';
//////////////////////////////////////////////////

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
  filterParams: {},
  usedReport: null,
  titleSortValues: {},
  reportPending: false,
  tableTitleFilters: {},
  invoiceStatusList: [],
  availableReports: null,
  invoiceStatusOptions: [],
  currentBranchConfigs: null,
  pagination: { limit: 20, offset: 0 },
};

const setInitialState = () => (
  initialState
);

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setReports = (state: Object, reports: Array) => (
  P.$all(
    P.$set('availableReports', reports),
    state,
  )
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('reportPending', false),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setQuickFilterParams = (state: Object, filterParams: Object) => (
  P.$set('filterParams', filterParams, state)
);

const getConfigsByNamesSuccess = (state: Object, data: Object) => (
  P.$set('currentBranchConfigs', data, state)
);

const setListLoading = (state: Object, loading: boolean) => (
  P.$set('loading', loading, state)
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const approveOrRejectServiceVendorInvoiceSuccess = (state: Object, data: Object) => {
  const { guid, status } = data;
  const reportStatus = `${GC.FIELD_STATUS}.${GC.FIELD_DISPLAYED_VALUE}`;
  const invoice = P.$get(`itemList.${guid}`, state);
  const newInvoice = R.mergeDeepRight(
    invoice,
    {
      details: { currentInvoice: data },
      [reportStatus]: R.pathOr('', [GC.FIELD_DISPLAYED_VALUE], status),
    },
  );

  return P.$set(`itemList.${guid}`, newInvoice, state);
};

const getItemListSuccess = (state: Object, data: Object) => {
  const { itemList, pagination } = state;
  const newOffset = R.add(pagination.offset, pagination.limit);
  const indexAdditional = G.ifElse(
    R.isNil(itemList),
    0,
    R.length(R.values(itemList)),
  );
  const newCarrierInvoices = data.results.map((p: Object, index: number) => R.mergeRight(
    p,
    {
      selected: false,
      expanded: false,
      index: R.add(index, indexAdditional),
    },
  ));
  const newList = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newCarrierInvoices));

  return P.$all(
    P.$set('itemList', newList),
    P.$set('pagination.limit', 10),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, id: string) => {
  if (R.equals(id, 'all')) {
    const { itemList } = state;
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));

    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc('selected', value, item),
        itemList,
      ),
      state,
    );
  }

  return P.$toggle(
    `itemList.${id}.selected`,
    state,
  );
};

const toggleServiceVendorInvoiceDetails = (state: Object, id: string) => (
  P.$toggle(
    `itemList.${id}.expanded`,
    state,
  )
);

const updateCIReconciliationSuccess = (state: Object, data: Object) => {
  const invoices = P.$get('itemList', state);
  const updatedInvoice = invoices[data.guid];
  const payments = G.transformPaymentsFromArrayToObjectWithReportKeys(data);
  const relatedInvoiceGuids = R.map(R.prop('guid'), updatedInvoice.details.relatedInvoices);
  const newItemList = R.map(
    (invoice: Object) => {
      if (R.equals(invoice.guid, data.guid)) {
        const mappedInvoice =  R.mapObjIndexed(
          (value: any, key: 'string') => R.pathOr(value, key.split('.'), data),
          invoice,
        );
        const newInvoice = R.mergeRight(mappedInvoice, payments);

        return R.assoc(
          'details',
          R.mergeDeepRight(invoice.details, { currentInvoice: data }),
          newInvoice,
        );
      }

      if (R.or(
          R.not(relatedInvoiceGuids.includes(invoice.guid)),
          G.isNilOrEmpty(invoice.details),
      )) return invoice;

      const relatedInvoices = invoice.details.relatedInvoices.map((rInvoice: Object) => {
        if (R.equals(rInvoice.guid, data.guid)) return data;

        return rInvoice;
      });

      return R.assoc('details', R.mergeDeepRight(invoice.details, { relatedInvoices }), invoice);
    },
    invoices,
  );

  return P.$set('itemList', newItemList, state);
};

const setServiceVendorInvoiceDetailsLoading = (state: Object, id: string) => (
  P.$set(`itemList.${id}.detailsLoading`, true, state)
);

const getServiceVendorInvoiceDetailsSuccess = (state: Object, data: Object) => (
  P.$set(
    `itemList.${data.guid}`,
    R.mergeRight(
      R.path(['itemList', data.guid], state),
      {
        detailsError: false,
        detailsLoading: false,
        details: data.details,
      },
    ),
    state,
  )
);

const getServiceVendorInvoiceDetailsError = (state: Object, id: string) => (
  P.$all(
    P.$set(`itemList.${id}.detailsError`, true),
    P.$set(`itemList.${id}.detailsLoading`, false),
    state,
  )
);

const collapseAndResetCarrierInvoices = (state: Object, invoice: string) => (
  P.$set(
    `itemList.${invoice.guid}`,
    R.mergeRight(
      R.path(['itemList', invoice.guid], state),
      {
        ...invoice,
        details: null,
        expanded: false,
      },
    ),
    state,
  )
);

const getServiceVendorInvoiceStatusesSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set('invoiceStatusList', [], state);

  const invoiceStatusOptions = R.compose(
    R.groupBy(R.prop(GC.FIELD_SYSTEM_STATUS)),
    R.map(({ guid, parentGuid, systemStatus, displayedValue }: Object) => ({
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_SYSTEM_STATUS]: systemStatus,
      [GC.FIELD_VALUE]: R.or(parentGuid, guid),
    })),
  )(data);

  return P.$all(
    P.$set('invoiceStatusList', data),
    P.$set('invoiceStatusOptions', invoiceStatusOptions),
    state,
  );
};

const synchronizeInvoiceFields = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(state.itemList)) return state;

  const updatedInvoiceGuid = G.getGuidFromObject(data);
  const payments = G.transformPaymentsFromArrayToObjectWithReportKeys(data);

  const invoicesToUpdate = R.compose(
    R.map((item: Object) => {
      const { guid, details } = item;

      const currentInvoice = G.ifElse(
        R.pathEq(updatedInvoiceGuid, ['currentInvoice', GC.FIELD_GUID], details),
        data,
        G.getPropFromObject('currentInvoice', details),
      );
      const relatedInvoices = R.compose(
        R.map((item: Object) => G.ifElse(
          R.propEq(updatedInvoiceGuid, GC.FIELD_GUID, item),
          data,
          item,
        )),
        R.pathOr([], ['relatedInvoices']),
      )(details);
      const newDetails = R.mergeRight(details, { currentInvoice, relatedInvoices });

      if (R.equals(guid, updatedInvoiceGuid)) {
        const newInvoice = R.mapObjIndexed((item: string, key: string) => {
          const path = R.split('.', key);
          const value = R.pathOr(item, path, data);

          return value;
        }, item);

        return {
          ...newInvoice,
          ...payments,
          details: newDetails,
        };
      }

      return R.assoc('details', newDetails, item);
    }),
    R.filter((item: Object) => {
      const { details } = item;

      if (G.isNilOrEmpty(details)) return false;

      const { currentInvoice, relatedInvoices } = details;

      const hasInvoiceToUpdate = R.compose(
        R.includes(updatedInvoiceGuid),
        R.map(G.getGuidFromObject),
        R.append(currentInvoice),
      )(R.or(relatedInvoices, []));

      return hasInvoiceToUpdate;
    }),
    R.pathOr([], ['itemList']),
  )(state);

  if (G.isNilOrEmpty(invoicesToUpdate)) return state;

  const itemList = R.mergeRight(state.itemList, invoicesToUpdate);

  return P.$set('itemList', itemList, state);
};

const synchronizeRateFields = (state: Object, source: Object) => {
  const { itemList } = state;

  if (G.isNilOrEmpty(itemList)) return state;

  const data = R.pathOr(source, ['data'], source);

  if (G.isRateTypeFleetRate(data)) return state;

  const entityOperation = G.getPropFromObject('entityOperation', source);
  const isSelectedRate = G.isNotNilAndNotEmpty(G.getGuidFromObject(source));

  if (R.and(R.pathEq(false, [GC.FIELD_SELECTED], data), G.isFalse(isSelectedRate))) return state;

  const hasNotRateFields = R.compose(
    G.isNilOrEmpty,
    R.pick([
      GC.GRC.TEL_RATE_TOTAL,
      GC.GRC.TEL_RATE_MAIN_CHARGES_TOTAL,
      GC.GRC.TEL_RATE_FUEL_CHARGES_TOTAL,
      GC.GRC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL,
    ]),
    R.head,
    R.values,
  )(itemList);

  if (G.isTrue(hasNotRateFields)) return state;

  if (R.equals(entityOperation, 'DELETE')) {
    const synchronizedList = R.map((item: Object) => {
      if (R.propEq(data, GC.GRC.TEL_RATE_GUID, item)) {
        return R.mergeRight(
          item,
          {
            [GC.GRC.TEL_RATE_GUID]: null,
            [GC.GRC.TEL_RATE_TOTAL]: null,
            [GC.GRC.TEL_RATE_MAIN_CHARGES_TOTAL]: null,
            [GC.GRC.TEL_RATE_FUEL_CHARGES_TOTAL]: null,
            [GC.GRC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL]: null,
          },
        );
      }

      return item;
    }, itemList);

    return P.$set('itemList', synchronizedList, state);
  }

  const {
    guid,
    total,
    telGuid,
    mainChargesTotal,
    fuelChargesTotal,
    additionalChargesTotal } = data;

  const synchronizedList = R.map((item: Object) => {
    if (R.propEq(telGuid, GC.FIELD_TEL_GUID, item)) {
      return R.mergeRight(
        item,
        {
          [GC.GRC.TEL_RATE_GUID]: guid,
          [GC.GRC.TEL_RATE_TOTAL]: total,
          [GC.GRC.TEL_RATE_MAIN_CHARGES_TOTAL]: mainChargesTotal,
          [GC.GRC.TEL_RATE_FUEL_CHARGES_TOTAL]: fuelChargesTotal,
          [GC.GRC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL]: additionalChargesTotal,
        },
      );
    }

    return item;
  }, itemList);

  return P.$set('itemList', synchronizedList, state);
};

export default createReducer({
  [A.selectItem]: selectItem,
  [A.setReports]: setReports,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [socketTelRateReceived]: synchronizeRateFields,
  [A.changeInvoicesSuccess]: changeInvoicesSuccess,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getConfigsByNamesSuccess]: getConfigsByNamesSuccess,
  [selectDriverCarrierRateSuccess]: synchronizeRateFields,
  [updateLoadCarrierInvoiceSuccess]: synchronizeInvoiceFields,
  [A.updateCIReconciliationSuccess]: updateCIReconciliationSuccess,
  [A.collapseAndResetCarrierInvoices]: collapseAndResetCarrierInvoices,
  [A.toggleServiceVendorInvoiceDetails]: toggleServiceVendorInvoiceDetails,
  [A.getServiceVendorInvoiceDetailsError]: getServiceVendorInvoiceDetailsError,
  [A.setServiceVendorInvoiceDetailsLoading]: setServiceVendorInvoiceDetailsLoading,
  [A.getServiceVendorInvoiceDetailsSuccess]: getServiceVendorInvoiceDetailsSuccess,
  [A.getServiceVendorInvoiceStatusesSuccess]: getServiceVendorInvoiceStatusesSuccess,
  [A.approveOrRejectServiceVendorInvoiceSuccess]: approveOrRejectServiceVendorInvoiceSuccess,
}, initialState);

import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// features
import { socketCarrierPortalReceived } from '../sockets/actions';
import { getTermsAndConditionsDocumentSuccess } from '../configurations/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature carrier-portal
import * as A from './actions';
import * as H from './helpers';
//////////////////////////////////////////////////

const initialState = {
  portal: {},
  cloRefTypes: [],
  portalType: null,
  declineReasons: [],
  acceptedDispatch: false,
  declinedDispatch: false,
  carrierResponseDetailPage: {
    guid: null,
    events: [],
    chatData: [],
    documents: {},
    mobView: false,
    carrierName: null,
    googleApiKey: null,
    statusMessages: [],
    primaryReference: {},
    statusCodeConfigs: [],
    availableDocumentTypes: [],
    allowStopCompleteWithoutArrival: null,
  },
};

const setPortalType = (state: Object, data: Object) => P.$set('portalType', data, state);

const acceptDispatchSuccess = (state: Object) => P.$set('acceptedDispatch', true, state);

const declineDispatchSuccess = (state: Object) => P.$set('declinedDispatch', true, state);

const getBranchLogoUrlSuccess = (state: Object, data: Object) => P.$set('portal.logoUrl', data, state);

const getCarrierResponseStatus = (state: Object, status: boolean) => (
  P.$set('portal.status', status, state)
);

const getAllAvailableCloRefTypesSuccess = (state: Object, data: Object) => (
  P.$set('cloRefTypes', data, state)
);

const getPreviousPathName = (state: Object, path: string) => (
  P.$set('portal.previousPathName', path, state)
);

const setMessageStatus = (state: Object, message: string) => (
  P.$set('portal.messageStatus', message, state)
);

const getCarrierResponseTokenSuccess = (state: Object, token: Object) => (
  P.$set('portal.token', token, state)
);

const getDeclineOptionsConfigsSuccess = (state: Object, data: Object) => (
  P.$set(
    'declineReasons',
    R.pathOr(data.options, [0, 'options'], data),
    state,
  )
);

const getCarrierPortalInfoSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set('carrierResponseDetailPage.guid', data.guid),
    P.$set('carrierResponseDetailPage.carrierName', data.carrierName),
    P.$set('carrierResponseDetailPage.events', R.or(data.events, [])),
    P.$set('carrierResponseDetailPage.googleApiKey', data.googleApiKey),
    P.$set('carrierResponseDetailPage.enterpriseGuid', data.enterpriseGuid),
    P.$set('carrierResponseDetailPage.statusMessages', R.or(data.statusMessages, [])),
    P.$set('carrierResponseDetailPage.primaryReference', R.or(data.primaryReference, {})),
    P.$set('carrierResponseDetailPage.statusReasonCodes', R.or(data.statusReasonCodes, [])),
    P.$set('carrierResponseDetailPage.statusCodeConfigs', R.or(data.statusCodeConfigs, [])),
    P.$set('carrierResponseDetailPage.availableDocumentTypes', R.or(data.documentTypes, [])),
    P.$set('carrierResponseDetailPage.allowStopCompleteWithoutArrival', data.allowStopCompleteWithoutArrival),
    state,
  )
);

const carrierMobView = (state: Object, data: Object) => (
  P.$set('carrierResponseDetailPage.mobView', data, state)
);

const addCarrierDocObject = (state: Object, data: Object) => (
  P.$set('carrierResponseDetailPage.documents', data, state)
);

const getChatMessageCarrierSuccess = (state: Object, data: Object) => (
  P.$set('carrierResponseDetailPage.chatData', data, state)
);

const checkChatMessageCarrierReceived = (state: Object, { data, entityOperation }: Object) => {
  if (R.equals(entityOperation, 'DELETE')) {
    return P.$set(
      'carrierResponseDetailPage.chatData',
      R.filter(
        (chatMessage: Object) => R.not(R.eqProps(GC.FIELD_GUID, data, chatMessage)),
        R.path(['carrierResponseDetailPage', 'chatData'], state),
      ),
      state,
    );
  }
  if (R.equals(entityOperation, 'UPDATE')) {
    return P.$set(
      'carrierResponseDetailPage.chatData',
      R.map(
        (chatMessage: Object) => {
          if (R.eqProps(GC.FIELD_GUID, data, chatMessage)) return data;
          return chatMessage;
        },
        R.path(['carrierResponseDetailPage', 'chatData'], state),
      ),
      state,
    );
  }
  return (
    P.$set(
      'carrierResponseDetailPage.chatData',
      R.append(data, R.path(['carrierResponseDetailPage', 'chatData'], state)),
      state,
    )
  );
};

const getOriginDestinationInfoFromEvent = (event: Object) => {
  const address = G.getPropFromObject(GC.FIELD_ADDRESS, event);
  const startDate = R.pathOr(null, [GC.FIELD_LOAD_EVENT_EARLY_DATE], event);
  const endDate = R.pathOr(startDate, [GC.FIELD_LOAD_EVENT_LATE_DATE], event);

  return { address, endDate, startDate };
};

const getPortalInfoByPortalTypeSuccess = (state: Object, data: Object) => {
  const { portalType } = state;

  if (H.isPortalTypeCustomer(portalType)) {
    const { events, services, equipment, internalExpirationDate } = data;

    const eventsToUse = R.compose(
      R.sortBy(R.prop(GC.FIELD_CLO_EVENT_INDEX)),
      R.map((event: Object) => R.assoc(
        GC.FIELD_ADDRESS,
        R.filter(G.isNotNilAndNotEmpty, R.pathOr({}, [GC.FIELD_LOCATION], event)),
        event,
      )),
    )(events);
    const firstEvent = R.head(eventsToUse);
    const lastEvent = R.last(eventsToUse);
    const totalRate = R.path([GC.FIELD_RATE, GC.FIELD_TOTAL], data);
    const currency = R.path([GC.FIELD_RATE, GC.FIELD_CURRENCY], data);
    const portal = R.mergeRight(
      data,
      {
        currency,
        totalRate,
        orderedServices: services,
        orderedEquipment: equipment,
        [GC.FIELD_LOAD_STOPS]: eventsToUse,
        [GC.FIELD_EXPIRATION_DATE]: internalExpirationDate,
        [GC.FIELD_ORIGIN]: getOriginDestinationInfoFromEvent(firstEvent),
        [GC.FIELD_DESTINATION]: getOriginDestinationInfoFromEvent(lastEvent),
      },
    );

    return P.$set('portal', portal, state);
  }

  return P.$set('portal', data, state);
};

const setExistsOrderQuoteTermsAndConditions = (state: Object, data: Object) => (
  P.$set('portal.existsTermsAndConditions', G.isObject(data), state)
);

export default createReducer({
  [A.setPortalType]: setPortalType,
  [A.carrierMobView]: carrierMobView,
  [A.setMessageStatus]: setMessageStatus,
  [A.addCarrierDoc]: addCarrierDocObject,
  [A.getPreviousPathName]: getPreviousPathName,
  [A.acceptDispatchSuccess]: acceptDispatchSuccess,
  [A.declineDispatchSuccess]: declineDispatchSuccess,
  [A.getBranchLogoUrlSuccess]: getBranchLogoUrlSuccess,
  [A.getCarrierResponseStatus]: getCarrierResponseStatus,
  [A.getCarrierPortalInfoSuccess]: getCarrierPortalInfoSuccess,
  [A.getChatMessageCarrierSuccess]: getChatMessageCarrierSuccess,
  [socketCarrierPortalReceived]: checkChatMessageCarrierReceived,
  [A.getCarrierResponseTokenSuccess]: getCarrierResponseTokenSuccess,
  [A.getDeclineOptionsConfigsSuccess]: getDeclineOptionsConfigsSuccess,
  [A.getPortalInfoByPortalTypeSuccess]: getPortalInfoByPortalTypeSuccess,
  [A.getAllAvailableCloRefTypesSuccess]: getAllAvailableCloRefTypesSuccess,
  [getTermsAndConditionsDocumentSuccess]: setExistsOrderQuoteTermsAndConditions,
}, initialState);

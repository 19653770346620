import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box } from '../../../ui';
// feature load-board
import {
  loginFormFields,
  logoutFormFields,
  defaultLoginValues,
  defaultLogoutValues,
  loginValidationSchema,
  logoutValidationSchema,
} from '../settings/lb-auth-settings';
//////////////////////////////////////////////////

const enhanceLogin = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'LOGIN_LOAD_BOARD_FORM',
    validationSchema: loginValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => {
      const { login: userName, password, type } = values;

      let data = {
        type,
        password,
        userName,
      };

      if (R.equals(type, GC.EXTERNAL_LOAD_BOARD_TRUCK_STOP)) {
        data = { type };
      } else if (R.equals(type, GC.EXTERNAL_LOAD_BOARD_TRUCKER_TOOLS)) {
        data = { userName, type };
      }

      props.submit(data);
    },
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultLoginValues,
      initialValues,
    ),
  }),
  pure,
);

export const LoginForm = enhanceLogin((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box py='8px'>
      <FieldsetComponent
        {...props}
        labelFontSize={12}
        fields={loginFormFields}
        onChange={props.handleChange}
        justifyContent='space-between'
        setOptionsFunction={(field: Object) => field.options}
      />
    </Box>
    <FormFooter closeModal={props.closeModal} />
  </form>
));

const enhanceLogout = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'LOGOUT_LOAD_BOARD_FORM',
    validationSchema: logoutValidationSchema,
    handleSubmit: (values: Object, { props }: Object) => props.submit(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultLogoutValues,
      initialValues,
    ),
  }),
  pure,
);

export const LogoutForm = enhanceLogout((props: Object) => (
  <form onSubmit={props.handleSubmit}>
    <Box py='8px'>
      <FieldsetComponent
        {...props}
        labelFontSize={12}
        fields={logoutFormFields}
        onChange={props.handleChange}
        justifyContent='space-between'
        setOptionsFunction={(field: Object) => field.options}
      />
    </Box>
    <FormFooter closeModal={props.closeModal} />
  </form>
));

import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, Flex } from '../../../ui';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

// TODO: check for negative totals

const calculatePercentageChange = (newValue: number, oldValue: number) => {
  if (R.or(G.isZero(oldValue), G.isZero(oldValue))) {
    return 'N/A';
  }

  if (R.or(R.lt(oldValue, 0), R.lt(newValue, 0))) {
    return 'N/A';
  }

  const change = R.compose(
    R.multiply(R.__, 100),
    R.divide(R.__, Math.abs(oldValue)),
    R.subtract(newValue),
  )(oldValue);

  return parseFloat(change.toFixed(2));
};

const Description = (props: Object) => {
  const { total, prevTotal, description, withoutPercentage, revertLowPercentageTextColor } = props;

  if (G.isNilOrEmpty(description)) {
    return (
      <Flex>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          -
        </Box>
      </Flex>
    );
  }

  if (G.isTrue(withoutPercentage)) {
    return (
      <Flex>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  const percentage = calculatePercentageChange(total, prevTotal);

  if (R.equals(percentage, 'N/A')) {
    return (
      <Flex>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          {`N/A ${description}`}
        </Box>
      </Flex>
    );
  }

  if (R.gt(percentage, 0)) {
    return (
      <Flex>
        <Box>{I.trendingUp()}</Box>
        <Box
          mx='5px'
          fontSize={14}
          color='softGreen'
        >
          {`${percentage}%`}
        </Box>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  if (R.lt(percentage, 0)) {
    const percentageColor = revertLowPercentageTextColor ? 'softGreen' : 'darkRed2';

    return (
      <Flex>
        <Box>{I.trendingDown()}</Box>
        <Box
          mx='5px'
          fontSize={14}
          color={percentageColor}
        >
          {`${percentage}%`}
        </Box>
        <Box
          fontSize={14}
          color='dark.mainDark'
        >
          {description}
        </Box>
      </Flex>
    );
  }

  return (
    <Box
      fontSize={14}
      color='dark.mainDark'
    >
      {description}
    </Box>
  );
};

const TotalCard = (props: Object) => {
  const {
    mr,
    width,
    title,
    total,
    prevTotal,
    boxShadow,
    totalPrefix,
    totalSuffix,
    description,
    withoutPercentage,
    revertLowPercentageTextColor,
  } = props;

  return (
    <Flex
      p={15}
      bg='white'
      height={120}
      mr={mr || 20}
      borderRadius={10}
      alignItems='center'
      width={width || 250}
      boxShadow={boxShadow}
      flexDirection='column'
      justifyContent='space-between'
    >
      <Box
        fontSize={14}
        color='titleGrey2'
      >
        {title}
      </Box>
      <Box
        fontSize={20}
        fontWeight='bold'
        color='dark.mainDark'
      >
        {totalPrefix} {G.toLocaleString(total)} {totalSuffix}
      </Box>
      <Description
        total={total}
        prevTotal={prevTotal}
        description={description}
        withoutPercentage={withoutPercentage}
        revertLowPercentageTextColor={revertLowPercentageTextColor}
      />
    </Flex>
  );
};

export default TotalCard;

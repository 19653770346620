import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setInitialState = createAction('setInitialState');
// report
export const selectItem = createAction('selectItem');
export const setReports = createAction('setReports');
export const setUsedReport = createAction('setUsedReport');
export const setReportType = createAction('setReportType');
export const setListLoading = createAction('setListLoading');
export const setFilterProps = createAction('setFilterProps');
export const setReportPending = createAction('setReportPending');
export const setTableTitleSort = createAction('setTableTitleSort');
export const getItemListRequest = createAction('getItemListRequest');
export const getItemListSuccess = createAction('getItemListSuccess');
export const setTableTitleFilter = createAction('setTableTitleFilter');
export const createReportRequest = createAction('createReportRequest');
export const updateReportRequest = createAction('updateReportRequest');
export const setGlobalFilterValue = createAction('setGlobalFilterValue');
export const resetListAndPagination = createAction('resetListAndPagination');
export const exportReportDataRequest = createAction('exportReportDataRequest');
export const changeDefaultReportRequest = createAction('changeDefaultReportRequest');
export const getAvailableReportsRequest = createAction('getAvailableReportsRequest');
// item
export const setItemDetails = createAction('setItemDetails');
export const printItemRequest = createAction('printItemRequest');
export const removeItemRequest = createAction('removeItemRequest');
export const removeItemSuccess = createAction('removeItemSuccess');
export const getItemXMLRequest = createAction('getItemXMLRequest');
export const toggleItemDetails = createAction('toggleItemDetails');
export const updateFleetItemRequest = createAction('updateFleetItemRequest');
export const updateFleetItemSuccess = createAction('updateFleetItemSuccess');
export const createReferenceRequest = createAction('createReferenceRequest');
export const createFleetItemRequest = createAction('createFleetItemRequest');
export const createFleetItemSuccess = createAction('createFleetItemSuccess');
export const massCreateFleetRequest = createAction('massCreateFleetRequest');
// sync by vin
export const syncItemsByVINRequest = createAction('syncItemsByVINRequest');
// ifta
export const generateIftaRequest = createAction('generateIftaRequest');
// shared component
export const assignSharedComponentRequest = createAction('assignSharedComponentRequest');
// fleet profile
export const setFleetProfileSettings = createAction('setFleetProfileSettings');
export const visitFleetListOnFleetProfilePage = createAction('visitFleetListOnFleetProfilePage');
// send to integration
export const sendListToIntegrationRequest = createAction('sendListToIntegrationRequest');
// driver
export const setFleetDriverActiveTab = createAction('setFleetDriverActiveTab');
// driver onboarding
export const getDriverListByDriverOnboardingGuidRequest = createAction('getDriverListByDriverOnboardingGuidRequest');
export const getDriverListByDriverOnboardingGuidSuccess = createAction('getDriverListByDriverOnboardingGuidSuccess');
// trailer
export const shareOrCancelSharingTrailerRequest = createAction('shareOrCancelSharingTrailerRequest');
// configs
export const getConfigsByNamesRequest = createAction('getConfigsByNamesRequest');
export const getConfigsByNamesSuccess = createAction('getConfigsByNamesSuccess');
export const getAccessorialConfigListRequest = createAction('getAccessorialConfigListRequest');
export const getAccessorialConfigListSuccess = createAction('getAccessorialConfigListSuccess');
// payroll accessorial
export const getPayrollAccessorialChargesByPayrollAccessorialGuidRequest =
  createAction('getPayrollAccessorialChargesByPayrollAccessorialGuidRequest');
export const getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess =
  createAction('getPayrollAccessorialChargesByPayrollAccessorialGuidSuccess');
// fleet service issue
export const changeFleetServiceIssueStatusRequest = createAction('changeFleetServiceIssueStatusRequest');
export const createFleetServiceIssueDocumentRequest = createAction('createFleetServiceIssueDocumentRequest');
export const createFleetServiceIssueDocumentSuccess = createAction('createFleetServiceIssueDocumentSuccess');
export const deleteFleetServiceIssueDocumentRequest = createAction('deleteFleetServiceIssueDocumentRequest');
export const deleteFleetServiceIssueDocumentSuccess = createAction('deleteFleetServiceIssueDocumentSuccess');
export const downloadFleetServiceIssueDocumentRequest = createAction('downloadFleetServiceIssueDocumentRequest');

import React from 'react';
import * as R from 'ramda';
import { css } from 'styled-components';
// helpers/constants
import * as G from '../../../helpers';
// ui
import {
  Box,
  Flex,
  AbsoluteBox,
  RelativeBox,
  pulseOpacity } from '../../../ui';
//////////////////////////////////////////////////

const Dimmer = (props: Object) => (
  <Box
    width='100%'
    overflow={R.and(props.withHeader, 'auto')}
  >
    <Box
      minWidth='max-content'
      css={css`animation: ${pulseOpacity} 2s infinite linear;`}
    >
      {
        props.withHeader &&
        <Box
          mb={26}
          height={40}
          width='100%'
          borderRadius={10}
          bg={G.getTheme('colors.light.darksGrey')} />
      }
      <Flex minWidth='max-content'>
        {
          G.getComponentCountTimes(
            props.count,
            (_: undefined, i: number) => (
              <RelativeBox
                key={i}
                mx={10}
                width={320}
              >
                <AbsoluteBox
                  top={-16}
                  height={32}
                  width='100%'
                  borderRadius={50}
                  bg={G.getTheme('colors.light.darksGrey')} />
                <Box
                  mt='2px'
                  width='100%'
                  height={150}
                  borderRadius={5}
                  bg={G.getTheme('colors.lightGrey')} />
              </RelativeBox>
            )
            ,
          )
        }
      </Flex>
    </Box>
  </Box>
);

export default Dimmer;

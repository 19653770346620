import * as Yup from 'yup';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const inputWrapperStyles = {
  mb: 25,
  width: 200,
};

export const getIsTriggerConditionTypeNumberField = (trigger: string) => {
  const triggers = [
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_LATE_DELIVERY,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_LATE_DATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_UNPLANNED_EVENT,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_DETENTION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_EARLY_DATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_SERVICE_DATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_TERMINATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_SERVICE_DATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_APPOINTMENT_REQUIRED,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EVENT_NO_STATUS_UPDATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_LICENSE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_DOCUMENT_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_INSURANCE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_DOCUMENTS_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_CONTRACT_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_DOCUMENT_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_DOCUMENT_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_BRANCH_CREDIT_LIMIT_PERCENT,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_INSURANCE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_INSURANCE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CERTIFICATE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_REGISTRATION_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_CARRIER_CERTIFICATE_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_REGISTRATION_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_EDI_TRANSACTION_ORDER_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_APPOINTMENT_REQUIRED_AFTER_CREATE,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_VISA_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRUCK_COMPONENT_WARRANTY_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_TRAILER_COMPONENT_WARRANTY_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_PASSPORT_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_COMPENSATION_DETAILS_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_WORK_AUTH_EXPIRATION,
    GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_DRIVER_CITIZENSHIP_GREEN_CARD_EXPIRATION,
  ];

  return R.includes(trigger, triggers);
};

export const getFirstNotificationFieldset = ({ trigger, messageTypes }: Object, { objectType }: Object) => [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    label: ['titles:name', 'Name'],
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_NAME,
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    shouldCustomChange: true,
    label: ['titles:trigger', 'Trigger'],
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER,
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    shouldCustomChange: true,
    disabled: G.isNilOrEmpty(objectType),
    label: ['titles:object-type', 'Object Type'],
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE,
  },
  {
    isMulti: true,
    inputWrapperStyles,
    type: 'reactSelect',
    label: ['titles:roles', 'Roles'],
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE,
    isRequired: R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_INTERNAL, messageTypes),
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    disabled: G.isNilOrEmpty(trigger),
    label: ['titles:notification-level', 'Notification Level'],
    closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
    options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL,
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL,
  },
  {
    type: 'toggle',
    inputWrapperStyles,
    label: ['titles:notification-sound', 'Notification Sound'],
    fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ALARM_ENABLED,
  },
];

export const getSecondNotificationFieldset = (props: Object, optionsForSelect: Object) => {
  const { values, triggers, condition } = props;
  const { conditionType, mailTemplateGuid } = optionsForSelect;
  const { trigger, operation, emailTypes, messageTypes } = values;

  const isConditionTypeNotNilAndNotEmpty = G.isNotNilAndNotEmpty(conditionType);
  const disabled = R.and(G.isNotNilAndNotEmpty(triggers), G.isNilOrEmpty(condition));
  const isTriggerConditionTypeNumberField = getIsTriggerConditionTypeNumberField(trigger);

  let secondNotificationFieldset = [
    {
      disabled,
      inputWrapperStyles,
      type: 'reactSelect',
      shouldCustomChange: true,
      isRequired: isConditionTypeNotNilAndNotEmpty,
      label: ['titles:condition-type', 'Condition Type'],
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE,
    },
    {
      disabled,
      inputWrapperStyles,
      type: 'reactSelect',
      shouldCustomChange: true,
      label: ['titles:operation', 'Operation'],
      isRequired: isConditionTypeNotNilAndNotEmpty,
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION,
    },
    {
      disabled,
      inputWrapperStyles,
      useMenuPortalTarget: true,
      label: ['titles:value', 'Value'],
      isRequired: isConditionTypeNotNilAndNotEmpty,
      isMulti: R.equals(operation, GC.FIELD_OPERATION_IN),
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      shouldCustomChange: G.isFalse(isTriggerConditionTypeNumberField),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE,
      type: G.ifElse(isTriggerConditionTypeNumberField, 'number', 'reactSelect'),
    },
    {
      isMulti: true,
      isRequired: true,
      inputWrapperStyles,
      type: 'reactSelect',
      shouldCustomChange: true,
      useMenuPortalTarget: true,
      label: ['titles:message-types', 'Message Types'],
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES,
    },
    {
      isMulti: true,
      inputWrapperStyles,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      label: ['titles:email-types', 'Email Types'],
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES,
      isRequired: R.and(
        G.isNotNilAndNotEmpty(trigger),
        R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL, messageTypes),
      ),
      disabled: R.or(
        G.isNilOrEmpty(trigger),
        G.notContain(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL, messageTypes),
      ),
    },
    {
      type: 'multiEmail',
      inputWrapperStyles,
      shouldOverrideEmails: true,
      label: ['titles:static-emails', 'Static Emails'],
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS,
      isRequired: R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_STATIC, emailTypes),
      disabled: G.notContain(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_STATIC, emailTypes),
    },
    {
      isMulti: true,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      label: ['titles:references', 'References'],
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES,
      inputWrapperStyles: {
        ...inputWrapperStyles,
        display: ({ values }: Object) => {
          const { objectType } = values;

          const loadObjectTypes = [
            GC.NOTIFICATION_OBJECT_TYPE_CLO,
            GC.NOTIFICATION_OBJECT_TYPE_TEL,
          ];

          if (R.includes(objectType, loadObjectTypes)) return 'flex';

          return 'none';
        },
      },
    },
  ];

  if (R.and(
    R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL, messageTypes),
    G.isNotNilAndNotEmpty(mailTemplateGuid),
  )) {
    secondNotificationFieldset = R.append({
      inputWrapperStyles,
      type: 'reactSelect',
      useMenuPortalTarget: true,
      label: ['titles:template', 'Template'],
      closeMenuOnScroll: R.pathEq('form_wrapper', ['target', 'id']),
      options: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID,
      fieldName: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID,
    }, secondNotificationFieldset);
  }

  return secondNotificationFieldset;
};

export const getOptions = (options: Object) => (
  R.compose(
    R.map((option: Object) => ({ value: option.value, label: G.getEnumLocale(option.label) })),
    R.filter((filteredOption: Object) => G.isNotNilAndNotEmpty(filteredOption)),
  )(options)
);

export const getEmailTypesOptions = (
  notificationOptions: Object,
  triggerName: string,
) => (
  R.compose(
    R.map((item: Object) => ({ value: item, label: G.getEnumLocale(item) })),
    R.pathOr([], ['0', GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES]),
    R.filter(({ trigger }: Object) => R.equals(trigger, triggerName)),
  )(notificationOptions)
);

export const getConditionValuesOptions = (
  options: Object,
  triggerName: string,
  conditionType: string,
) => {
  if (G.isNilOrEmpty(options)) return;

  const condition = R.and(
    R.equals(triggerName, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER_STATUS_MESSAGE),
    G.notEquals(conditionType, GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE_WARNING_LEVEL),
  );

  if (condition) return options;

  return R.map(({ value, label }: Object) => (
    { value, label: G.getEnumLocale(label) }
  ), options);
};

export const getDataTriggers = ({ setObjType, setCondition, notificationOptions }: Object, value: string) =>
  R.forEach(
    ({ trigger, objectTypes, conditions }: Object) => {
      if (R.equals(trigger, value)) {
        const typeObj = R.map(
          (typeName: string) => ({ label: typeName, value: typeName }),
          objectTypes,
        );

        const conditionType = R.map(
          (conditionName: string) => ({ label: conditionName, value: conditionName }),
          conditions,
        );

        setObjType(typeObj);

        setCondition(conditionType);

        if (G.isNilOrEmpty(conditionType)) setCondition(null);
      }
    },
    notificationOptions,
  );

export const notificationMessageTypesOptions = [
  {
    label: G.getWindowLocale('titles:internal', 'Internal'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_INTERNAL,
  },
  {
    label: G.getWindowLocale('titles:email', 'Email'),
    value: GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL,
  },
];

const defaultSchema = {
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL]: G.yupStringRequired,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER]: G.yupStringRequired,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE]: G.yupStringRequired,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES]: G.yupArrayRequired,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_NAME]: G.yupStringRequired
    .min(3, G.getShouldBeFromToLocaleTxt(3, 40))
    .max(40, G.getShouldBeFromToLocaleTxt(3, 40)),
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: Yup.array()
    .nullable(true)
    .notRequired()
    .max(10, G.getShouldBePickOnlyLocaleTxt(10)),
};

export const getValidationSchema = (values: Object, notificationOptions: Object) => {
  const { trigger, operation, emailTypes, messageTypes } = values;

  let schema = defaultSchema;

  if (R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_INTERNAL, messageTypes)) {
    schema = R.assoc(
      GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE,
      G.yupArrayRequired,
      schema,
    );
  }

  if (R.and(
    G.isNotNilAndNotEmpty(trigger),
    R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES_EMAIL, messageTypes),
  )) {
    if (R.includes(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES_STATIC, emailTypes)) {
      schema = R.assoc(
        GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS,
        G.yupArrayRequired,
        schema,
      );
    }

    schema = R.assoc(
      GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES,
      G.yupArrayRequired,
      schema,
    );
  }
  const conditions = R.compose(
    R.path(['0', 'conditions']),
    R.filter((item: Object) => R.equals(
      G.getPropFromObject(GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER, item),
      trigger,
    )),
  )(notificationOptions);

  if (G.isNilOrEmpty(conditions)) {
    return schema;
  }

  let notificationValueFieldSchema = {
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE]: G.ifElse(
      R.equals(operation, GC.FIELD_OPERATION_IN),
      G.yupArrayRequired,
      G.yupStringRequired,
    ),
  };

  if (getIsTriggerConditionTypeNumberField(trigger)) {
    notificationValueFieldSchema = {
      [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE]: Yup.number()
        .nullable(true)
        .required(G.getRequiredLocaleTxt())
        .positive(G.getShouldBePositiveLocaleTxt()),
    };
  }

  return R.mergeRight(schema, {
    ...notificationValueFieldSchema,
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE]: G.yupStringRequired,
    [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION]: G.yupStringRequired,
  });
};

export const defaultNotificationValues = {
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_NAME]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_ROLE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_LEVEL]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_VALUE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_TRIGGER]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_OBJ_TYPE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_EMAIL_TYPES]: [],
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MESSAGE_TYPES]: [],
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_STATIC_EMAILS]: [],
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_REFERENCE_TYPES]: [],
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_TYPE]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_MAIL_TEMPLATE_GUID]: null,
  [GC.FIELD_CONFIGURATION_COMMUNICATION_NOTIFICATION_CONDITION_OPERATION]: null,
};

import React from 'react';
import * as R from 'ramda';
import {
  all,
  put,
  fork,
  call,
  delay,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAccessorialForConfigRequest } from '../configurations/actions';
import { getAllAvailableRefTypesByScopeRequestSaga } from '../reference/sagas';
import { getAvailableReportGeoFencingZoneListRequest } from '../geo-fencing-zone/report/actions';
import { getLoadDetailsRequest, setDispatchPlannerEventsOpened } from '../dispatch-details-new/load/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dispatch-planner
import * as A from './actions';
import * as H from './helpers';
import RefreshDataModal from './components/refresh-data-modal';
import { cloEventsMapWatcherSaga } from './sagas/clo-events-map';
import { CLO_NAME, MAP_MODE, DELETED_TEL_GUIDS } from './constants';
import {
  makeSelectTelByGuid,
  makeSelectTelReport,
  makeSelectHidePlanned,
  makeSelectCloEventList,
  makeSelectCurrentRoute,
  makeSelectBuilderStore,
  makeSelectSelectedTels,
  makeSelectTelPagination,
  makeSelectOpenedFromPage,
  makeSelectCloEventByGuid,
  makeSelectCloEventReport,
  makeSelectEditedCloGuids,
  makeSelectRouteTemplates,
  makeSelectTelFilterParams,
  makeSelectInitPlannerData,
  makeSelectInboundTelReport,
  makeSelectInboundTerminals,
  makeSelectOutboundTerminals,
  makeSelectOutboundTelReport,
  makeSelectCurrentRouteItems,
  makeSelectGlobalFilterValue,
  makeSelectDeletedEventGuids,
  makeSelectCloEventPagination,
  makeSelectUnassignedCloEvents,
  makeSelectGlobalFilterValueET,
  makeSelectGlobalFilterValueIT,
  makeSelectGlobalFilterValueOT,
  makeSelectCloEventFilterParams,
  makeSelectBranchGuidForRequest,
  makeSelectInboundTelPagination,
  makeSelectTelTableTitleFilters,
  makeSelectCrossDockLocationList,
  makeSelectOutboundTelPagination,
  makeSelectTelTableTitleSortValues,
  makeSelectInboundTableTitleFilters,
  makeSelectCurrentRouteInitialState,
  makeSelectOutboundTableTitleFilters,
  makeSelectCloEventsTableTitleFilters,
  makeSelectInboundTableTitleSortValues,
  makeSelectInitPlannerDataFromTemplate,
  makeSelectOutboundTableTitleSortValues,
  makeSelectCloEventsTableTitleSortValues,
  makeSelectPrimaryReferenceAutogeneratedConfig,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetCloEventDetailsRequest(payload: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const { guid, cloGuid } = payload;
    const event = yield select(makeSelectCloEventByGuid(guid));
    if (G.isNotNilAndNotEmpty(R.prop('details', event))) return yield put(closeLoader());
    const params = { cloGuid };
    const res = yield call(sendRequest, 'get', endpointsMap.cloEventList, { params });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCloEventDetailsSuccess({ data, guid, cloGuid }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloEventDetailsRequest fail');
    }
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetCloEventDetailsRequest exception');
  }
}

function* handleSelectCloEventForTelRequest({ payload }: Object) {
  const cloGuid = R.path(['returnObject', 'cloGuid'], payload);
  const eventGuid = R.path(['returnObject', 'eventGuid'], payload);
  const telGuid = R.path(['selectedValue', 'value'], payload);

  try {
    if (R.and(G.isNotNilAndNotEmpty(cloGuid), G.isNotNilAndNotEmpty(telGuid))) {
      yield put(openLoader({ showDimmer: true }));

      yield call(handleGetCloEventDetailsRequest, { cloGuid, guid: eventGuid });

      const event = yield select(makeSelectCloEventByGuid(eventGuid));

      if (G.isNotNilAndNotEmpty(R.prop('details', event))) {
        yield put(A.addTerminalOnEventAddOrMove({ telGuid, eventGuid }));

        let matchEvent = R.find(R.eqProps(GC.FIELD_GUID, event), event.details);

        matchEvent = R.assoc(
          GC.FIELD_LOAD_ITEMS,
          R.map(
            R.assoc(CLO_NAME, R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event)),
            R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
          ),
          matchEvent,
        );

        const cloName = R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event);

        matchEvent = R.mergeRight(
          matchEvent,
          {
            [CLO_NAME]: cloName,
            [GC.FIELD_LOAD_ITEMS]: R.map(
              R.assoc(CLO_NAME, cloName),
              R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
            ),
          },
        );

        const templateId = R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, event);

        yield put(A.addTerminalOnEventAddOrMove({ telGuid, eventGuid }));
        yield put(A.selectCloEventForTelSuccess({ event, telGuid, templateId, matchEvent }));
        yield put(closeLoader());
        yield put(A.recalculateTelDistancesRequest(telGuid));
      } else {
        yield put(closeLoader());
      }
    }
  } catch (err) {
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleSelectCloEventForTelRequest exception');
  }
}

function* handleAddCloEventToInterTerminalTel({ payload }: Object) {
  const { templateId, returnObject, selectedValue } = payload;

  const cloGuid = R.path(['event', GC.FIELD_CLO_GUID], returnObject);
  const eventGuid = R.path(['event', GC.FIELD_GUID], returnObject);
  const telGuid = R.path([GC.FIELD_VALUE], selectedValue);

  try {
    if (R.and(G.isNotNilAndNotEmpty(cloGuid), G.isNotNilAndNotEmpty(telGuid))) {
      yield put(openLoader({ showDimmer: true }));

      yield call(handleGetCloEventDetailsRequest, { cloGuid, guid: eventGuid });

      const event = yield select(makeSelectCloEventByGuid(eventGuid));
      const currentRoute = yield select(makeSelectCurrentRoute());
      const crossDocks = yield select(makeSelectCrossDockLocationList());

      if (G.isNotNilAndNotEmpty(R.prop('details', event))) {
        let matchEvent = R.find(R.eqProps(GC.FIELD_GUID, event), event.details);

        matchEvent = R.assoc(
          GC.FIELD_LOAD_ITEMS,
          R.map(
            R.assoc(CLO_NAME, R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event)),
            R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
          ),
          matchEvent,
        );

        const lastTerminalDate = R.path([GC.GRC.LAST_TERMINAL_DROP_LATE_DATE], event);

        if (G.isNotNil(templateId)) {
          const returnObject = {
            dropTels: R.of(Array, telGuid),
          };
          const dates = {
            eventLateDate: G.addMomentTimeWithFormat(lastTerminalDate, 16),
            eventEarlyDate: G.addMomentTimeWithFormat(lastTerminalDate, 16),
          };
          const terminal = R.mergeRight(R.prop(templateId, crossDocks), dates);
          const terminalLocation = R.assoc(
            GC.FIELD_LOCATION_TYPE,
            R.omit([GC.FIELD_GUID], R.prop(GC.FIELD_LOCATION_TYPE, terminal)),
            terminal,
          );

          yield put(A.addTerminalToLoadPlannerTelUI({
            returnObject,
            [GC.FIELD_LOCATION]: terminalLocation,
            [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
          }));
        }

        const lastTDtemplateId = R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, event);
        const terminalExist = R.compose(
          R.find((telEvent: Object) => G.isAllTrue(
            G.isStopPickup(telEvent),
            G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
            R.pathEq(lastTDtemplateId, [GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent),
          )),
          R.values,
          R.path([GC.SYSTEM_LIST_TELS, telGuid, GC.FIELD_LOAD_STOPS]),
        )(currentRoute);

        if (R.isNil(terminalExist)) {
          const returnObject = {
            pickupTels: R.of(Array, telGuid),
          };
          const dates = {
            eventLateDate: G.addMomentTimeWithFormat(lastTerminalDate, 8),
            eventEarlyDate: G.addMomentTimeWithFormat(lastTerminalDate, 8),
          };
          const terminal = R.mergeRight(R.prop(lastTDtemplateId, crossDocks), dates);
          const terminalLocation = R.assoc(
            GC.FIELD_LOCATION_TYPE,
            R.omit([GC.FIELD_GUID], R.prop(GC.FIELD_LOCATION_TYPE, terminal)),
            terminal,
          );

          yield put(A.addTerminalToLoadPlannerTelUI({
            returnObject,
            [GC.FIELD_LOCATION]: terminalLocation,
            [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
          }));
        }

        yield put(A.addCloEventToInterTerminalTelSuccess({ event, telGuid, eventGuid, templateId, matchEvent }));
        yield put(closeLoader());
        yield put(A.recalculateTelDistancesRequest(telGuid));
      } else {
        yield put(closeLoader());
      }
    }
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleAddCloEventToInterTerminalTel exception');
  }
}

function* handleToggleCloEventDetails({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(handleGetCloEventDetailsRequest, payload);
    yield put(A.toggleCloEvent(R.prop(GC.FIELD_GUID, payload)));
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleToggleCloEventDetails exception');
  }
}

function* handleToggleTelDetails({ payload }: Object) {
  const { guid } = payload;
  yield put(A.toggleTel(payload));
  if (G.isNilOrEmpty(R.prop('details', payload))) {
    try {
      yield put(openLoader({ showDimmer: true }));
      const res = yield call(sendRequest, 'get', endpointsMap.getTelLoadPlannerEndpoint(guid));
      const { data, status } = res;
      if (G.isResponseSuccess(status)) {
        yield put(A.setTelDetails(data));
      } else {
        yield call(G.handleFailResponse, res, 'handleToggleTelDetails fail');
      }
      yield put(closeLoader());
    } catch (err) {
      yield put(closeLoader());
      yield call(G.handleException, err, 'handleToggleTelDetails exception');
    }
  }
}

const requiredCloEventFields = [
  GC.FIELD_CLO_GUID,
  GC.FIELD_TEL_GUID,
  GC.FIELD_EVENT_TYPE,
  GC.GRC.CLO_BRANCH_GUID,
  GC.GRC.CLO_PRIMARY_REFERENCE_VALUE,
  GC.GRC.LAST_TERMINAL_DROP_LATE_DATE,
  GC.GRC.LAST_TERMINAL_DROP_EARLY_DATE,
  GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID,
];

export function* handleGetActiveCloEventListSaga(report: Object) {
  try {
    yield put(A.setCloEventListLoading(true));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const pagination = {
      offset: 0,
      limit: 200,
      hidePlanned: false,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const fields = G.addRequiredFields(G.getOrElse(report, 'fields', []), requiredCloEventFields);
    const options = { data: R.mergeRight(R.assoc('fields', fields, report), pagination) };

    const res = yield call(sendRequest, 'post', endpointsMap.cloEventListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setSelectedCloEventList(data));

      const uniqByCloGuid = R.uniqBy(R.prop(GC.FIELD_CLO_GUID), data.results);
      yield all(uniqByCloGuid.map(({ guid, cloGuid }: string) => (
        call(handleGetCloEventDetailsRequest, { guid, cloGuid })
      )));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetActiveCloEventListSaga fail');
      yield put(A.setCloEventListLoading(false));
    }

    yield put(A.setCloEventListLoading(false));
  } catch (err) {
    yield put(A.setCloEventListLoading(false));
    yield call(G.handleException, err, 'handleGetActiveCloEventListSaga exception');
  }
}

function* handleGetCloEventListByCloGuidSaga(cloGuid: string) {
  try {
    yield put(A.setCloEventListLoading(true));
    const report = yield select(makeSelectCloEventReport());
    const reportToUse = R.compose(
      R.assoc(GC.FIELD_CLO_GUID, cloGuid),
      R.dissoc('searchCriteria'),
    )(report);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const pagination = {
      offset: 0,
      limit: 100,
      hidePlanned: false,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const fields = G.addRequiredFields(G.getOrElse(reportToUse, 'fields', []), requiredCloEventFields);
    const options = { data: R.mergeRight(R.assoc('fields', fields, reportToUse), pagination) };
    const res = yield call(sendRequest, 'post', endpointsMap.cloEventListLoadPlanner, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const branchGuid = yield select(makeSelectCurrentBranchGuid());
      const telInfo = {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
        primaryReference: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-1` },
      };
      yield put(A.addNewTelToRouteBuilder({ telInfo }));
      yield put(A.setSelectedCloEventList(data));
      yield put(A.setCloEventListLoading(false));
      const currentRoute = yield select(makeSelectCurrentRoute());
      const value = R.compose(
        G.getGuidFromObject,
        R.head,
        R.values,
        R.prop(GC.SYSTEM_LIST_TELS),
      )(currentRoute);
      yield all(data.results.map(({ guid }: string) => {
        const eventData = {
          selectedValue: { value },
          returnObject: {
            cloGuid,
            eventGuid: guid,
          },
        };

        return call(handleSelectCloEventForTelRequest, { payload: eventData });
      }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloEventListByCloGuidSaga fail');
      yield put(A.setCloEventListLoading(false));
    }
  } catch (err) {
    yield put(A.setCloEventListLoading(false));
    yield call(G.handleException, err, 'handleGetCloEventListByCloGuidSaga exception');
  }
}

function* handleGetCloEventListSaga(isInitial: any) {
  try {
    yield put(A.setCloEventListLoading(true));

    const pagination = yield select(makeSelectCloEventPagination());

    const { total, limit, offset } = pagination;

    if (R.gte(offset, total)) return;

    const report = yield select(makeSelectCloEventReport());
    const hidePlanned = yield select(makeSelectHidePlanned());
    const filterParams = yield select(makeSelectCloEventFilterParams());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const branchGuidForRequest = yield select(makeSelectBranchGuidForRequest());
    const titleFilterParams = yield select(makeSelectCloEventsTableTitleFilters());
    const titleOrderFields = yield select(makeSelectCloEventsTableTitleSortValues());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    if (G.isNotNilAndNotEmpty(branchGuidForRequest)) currentBranchGuid = branchGuidForRequest;

    const fields = G.addRequiredFields(G.getOrElse(report, 'fields', []), requiredCloEventFields);

    const reqBody = {
      limit,
      fields,
      offset,
      hidePlanned,
      globalFilterValue,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };

    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloEventListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.setCloEventList(data));
      yield put(A.setCloEventListLoading(false));

      const { offset } = yield select(makeSelectCloEventPagination());

      if (R.and(R.gt(G.getPropFromObject('totalCount', data), offset), G.isTrue(isInitial))) {
        yield put(A.getCloEventsNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloEventListSaga fail');

      yield put(A.setCloEventListLoading(false));
    }
  } catch (err) {
    yield put(A.setCloEventListLoading(false));

    yield call(G.handleException, err, 'handleGetCloEventListSaga exception');
  }
}

function* handleGetInitialTelSaga(telGuid: string) {
  try {
    yield put(A.setTelListLoading(true));
    const pagination = yield select(makeSelectTelPagination());
    const report = yield select(makeSelectTelReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const reqBody = {
      limit: pagination.limit,
      offset: pagination.offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: R.of(Array, {
        operation: 'in',
        dataType: 'string',
        stringValue: telGuid,
        propertyName: GC.FIELD_GUID,
      }),
    };
    const options = {
      data: reqBody,
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setTelList(R.assoc('totalCount', 0, data)));
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetInitialTelSaga fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'handleGetInitialTelSaga exception');
  }
}

function* handleGetTelListSaga({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));
    const report = yield select(makeSelectTelReport());
    const pagination = yield select(makeSelectTelPagination());
    const filterParams = yield select(makeSelectTelFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValueET());
    const titleFilterParams = yield select(makeSelectTelTableTitleFilters());
    const titleOrderFields = yield select(makeSelectTelTableTitleSortValues());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const reqBody = {
      globalFilterValue,
      limit: pagination.limit,
      offset: pagination.offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };
    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setTelList(data));
      yield put(A.setTelListLoading(false));
      const newPagination = yield select(makeSelectTelPagination());
      if (R.and(R.gt(R.prop('totalCount', data), newPagination.offset), G.isTrue(payload))) {
        yield put(A.getTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelListSaga fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'handleGetTelListSaga exception');
  }
}

function* handleGetInboundTelListSaga({ payload }: Object) {
  try {
    yield put(A.setInboundTelListLoading(true));
    const report = yield select(makeSelectInboundTelReport());
    const filterParams = yield select(makeSelectTelFilterParams());
    const pagination = yield select(makeSelectInboundTelPagination());
    const terminalDropIds = yield select(makeSelectInboundTerminals());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValueIT());
    const titleFilterParams = yield select(makeSelectInboundTableTitleFilters());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const titleOrderFields = yield select(makeSelectInboundTableTitleSortValues());
    const reqBody = {
      terminalDropIds,
      globalFilterValue,
      limit: pagination.limit,
      offset: pagination.offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };
    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setInboundTelList(data));
      yield put(A.setInboundTelListLoading(false));
      const newPagination = yield select(makeSelectInboundTelPagination());
      if (R.and(R.gt(R.prop('totalCount', data), newPagination.offset), G.isTrue(payload))) {
        yield put(A.getInboundTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetInboundTelListSaga fail');
      yield put(A.setInboundTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setInboundTelListLoading(false));
    yield call(G.handleException, err, 'handleGetInboundTelListSaga exception');
  }
}

function* handleGetOutboundTelListSaga({ payload }: Object) {
  try {
    yield put(A.setOutboundTelListLoading(true));

    const report = yield select(makeSelectOutboundTelReport());
    const filterParams = yield select(makeSelectTelFilterParams());
    const pagination = yield select(makeSelectOutboundTelPagination());
    const terminalPickupIds = yield select(makeSelectOutboundTerminals());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const globalFilterValue = yield select(makeSelectGlobalFilterValueOT());
    const titleFilterParams = yield select(makeSelectOutboundTableTitleFilters());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const titleOrderFields = yield select(makeSelectOutboundTableTitleSortValues());
    const reqBody = {
      globalFilterValue,
      terminalPickupIds,
      limit: pagination.limit,
      offset: pagination.offset,
      fields: G.getOrElse(report, 'fields', []),
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      orderFields: G.ifElse(
        G.isNotEmpty(titleOrderFields),
        R.values(titleOrderFields),
        G.getOrElse(report, 'orderFields', []),
      ),
      searchCriteria: transformSearchCriteriaBeforeReportPost(
        G.ifElse(
          G.isNotEmpty(titleFilterParams),
          R.values(titleFilterParams),
          G.getOrElse(report, 'searchCriteria', []),
        ),
      ),
    };
    const options = {
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setOutboundTelList(data));
      yield put(A.setOutboundTelListLoading(false));
      const newPagination = yield select(makeSelectOutboundTelPagination());
      if (R.and(R.gt(R.prop('totalCount', data), newPagination.offset), G.isTrue(payload))) {
        yield put(A.getOutboundTelNextPage());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleGetOutboundTelListSaga fail');
      yield put(A.setOutboundTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setOutboundTelListLoading(false));
    yield call(G.handleException, err, 'handleGetOutboundTelListSaga exception');
  }
}

function* handleRefreshCloEventListWithSelectedEvents() {
  try {
    const cloEventReport = yield select(makeSelectCloEventReport());
    const currentRoute = yield select(makeSelectCurrentRoute());
    const eventList = yield select(makeSelectCloEventList());
    const selectedEvents = R.filter(R.prop('selected'), eventList);
    const eventGuids = H.getEventGuidsFromListAndRoute(selectedEvents, currentRoute);
    yield put(A.resetCloEventListAndPagination());

    if (G.isNotNilAndNotEmpty(eventGuids)) {
      const data = R.compose(
        R.assoc('eventGuids', eventGuids),
        R.dissoc('searchCriteria'),
      )(cloEventReport);
      yield call(handleGetActiveCloEventListSaga, data);
    }

    yield call(handleGetCloEventListSaga, true);
  } catch (err) {
    yield call(G.handleException, err, 'handleRefreshCloEventListWithSelectedEvents exception');
  }
}

function* handleUpdateCloEventListWithSelectedEvents() {
  try {
    const cloEventReport = yield select(makeSelectCloEventReport());
    const currentRoute = yield select(makeSelectCurrentRoute());
    const eventList = yield select(makeSelectCloEventList());

    const selectedEventGuids = R.compose(
      R.map(G.getGuidFromObject),
      R.filter(R.prop('selected')),
    )(eventList);

    const eventGuids = H.getCloEventGuidsFromCurrentRouteWithoutSelected(currentRoute, selectedEventGuids);

    if (G.isNotNilAndNotEmpty(eventGuids)) {
      const data = R.compose(
        R.assoc('eventGuids', eventGuids),
        R.dissoc('searchCriteria'),
      )(cloEventReport);

      yield call(handleGetActiveCloEventListSaga, data);
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleUpdateCloEventListWithSelectedEvents exception');
  }
}

function* handleGetTelDetailsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const tel = yield select(makeSelectTelByGuid(payload));
    if (G.isNotNilAndNotEmpty(R.prop('details', tel))) return yield put(closeLoader());
    const res = yield call(sendRequest, 'get', endpointsMap.getTelLoadPlannerEndpoint(payload));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.setTelDetails(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelDetailsSaga fail');
    }
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetTelDetailsSaga exception');
  }
}

function* handleGetTelByGuidAndMergeSaga({ order, payload, withoutRefreshCloList }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    yield call(handleGetTelDetailsSaga, { payload });

    const tel = yield select(makeSelectTelByGuid(payload));

    if (G.isNotNilAndNotEmpty(R.prop('details', tel))) {
      const { details } = tel;

      const currentRoute = yield select(makeSelectCurrentRoute());
      const selectedTels = yield select(makeSelectSelectedTels());

      const newSelectedTels = R.compose(
        R.uniq,
        R.concat(R.of(Array, G.getGuidFromObject(details))),
        R.values,
      )(selectedTels);

      yield put(A.selectTelOnAllLists(newSelectedTels));

      const currentItems = yield select(makeSelectCurrentRouteItems());

      yield put(A.setAvailableItems(R.mergeRight(currentItems, H.mapRouteItems(R.of(Array, details)))));

      const currentTels = currentRoute[GC.SYSTEM_LIST_TELS];

      const condition = R.or(
        R.equals(R.length(R.values(currentTels)), 0),
        R.and(
          R.equals(R.length(R.values(currentTels)), 1),
          R.equals(R.length(R.values(R.path([0, GC.FIELD_LOAD_STOPS], R.values(currentTels)))), 0),
        ),
      );

      const detailsWithOrder = R.assoc(GC.FIELD_ORDER, order, details);

      if (condition) {
        let newRoute = H.convertTelResponse({ [GC.FIELD_TEL]: detailsWithOrder });

        const deletedTelGuids = G.getPropFromObject(DELETED_TEL_GUIDS, currentRoute);

        if (G.isNotNilAndNotEmpty(deletedTelGuids)) {
          newRoute = R.assoc(DELETED_TEL_GUIDS, deletedTelGuids, newRoute);
        }

        yield put(A.setCurrentRoute(newRoute));
      } else {
        const incomeRoute = H.convertTelResponse({
          [GC.FIELD_TEL]: detailsWithOrder,
          indexAdditional: R.inc(H.getMaxTelOrder(currentTels)),
        });

        const newRoute = R.assoc(
          GC.SYSTEM_LIST_TELS,
          R.mergeRight(currentTels, incomeRoute[GC.SYSTEM_LIST_TELS]),
          currentRoute,
        );

        yield put(A.setCurrentRoute(newRoute));
      }

      if (R.not(withoutRefreshCloList)) yield call(handleUpdateCloEventListWithSelectedEvents);
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleGetTelByGuidAndMergeSaga exception');
  }
}

function* handleGetTelForListAndAddToPlannerSaga({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));
    const report = yield select(makeSelectTelReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        limit: 1,
        offset: 0,
        telGuids: R.of(Array, payload),
        fields: G.getOrElse(report, 'fields', []),
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.addTelsToList(data));
      yield put(A.addTelToPlanner(payload));
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelForListAndAddToPlannerSaga fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'handleGetTelForListAndAddToPlannerSaga exception');
  }
}

function* setTelsToPlannerSaga({ payload }: Object) {
  try {
    let currentRoute = yield select(makeSelectCurrentRoute());
    const currentItems = yield select(makeSelectCurrentRouteItems());
    yield put(A.setAvailableItems(R.mergeRight(currentItems, H.mapRouteItems(payload))));
    payload.forEach((tel: Object) => {
      const currentTels = currentRoute[GC.SYSTEM_LIST_TELS];
      const condition = R.or(
        R.equals(R.length(R.values(currentTels)), 0),
        R.and(
          R.equals(R.length(R.values(currentTels)), 1),
          R.equals(R.length(R.values(R.path([0, GC.FIELD_LOAD_STOPS], R.values(currentTels)))), 0),
        ),
      );
      if (condition) {
        currentRoute = H.convertTelResponse({ tel });

        const deletedTelGuids = G.getPropFromObject(DELETED_TEL_GUIDS, currentRoute);

        if (G.isNotNilAndNotEmpty(deletedTelGuids)) {
          currentRoute = R.assoc(DELETED_TEL_GUIDS, deletedTelGuids, currentRoute);
        }
      } else {
        let indexAdditional;

        const telGuid = G.getGuidFromObject(tel);

        if (R.includes(telGuid, R.keys(currentTels))) {
          indexAdditional = R.path([telGuid, GC.FIELD_ORDER], currentTels);
        } else {
          indexAdditional = R.inc(H.getMaxTelOrder(currentTels));
        }

        const incomeRoute = H.convertTelResponse({ tel, indexAdditional });

        currentRoute = R.assoc(
          GC.SYSTEM_LIST_TELS,
          R.mergeRight(currentTels, incomeRoute[GC.SYSTEM_LIST_TELS]),
          currentRoute,
        );
      }
    });
    yield put(A.setCurrentRoute(currentRoute));
    yield call(handleRefreshCloEventListWithSelectedEvents);
  } catch (err) {
    yield call(G.handleException, err, 'setTelsToPlannerSaga exception');
  }
}

function* handleGetCloTelsAndAddToPlannerSaga({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));
    const report = yield select(makeSelectTelReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        offset: 0,
        limit: 100,
        cloGuid: payload,
        fields: G.getOrElse(report, 'fields', []),
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      },
    };
    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.addTelsToList(data));
      const params = { cloGuid: payload };
      const telsRes = yield call(sendRequest, 'get', endpointsMap.telListLoadPlanner, { params });
      if (G.isNotNilAndNotEmpty(telsRes.data)) {
        yield put(A.selectTelOnAllLists(R.map(G.getGuidFromObject, telsRes.data)));
        yield all(telsRes.data.map((tel: Object) => (
          put(A.setTelDetails(tel))
        )));
        yield call(setTelsToPlannerSaga, { payload: telsRes.data });
      }
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCloTelsAndAddToPlannerSaga fail');
      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));
    yield call(G.handleException, err, 'handleGetCloTelsAndAddToPlannerSaga exception');
  }
}

function* getCloTelsAndSetToStoreRequest({ payload }: Object) {
  try {
    yield put(A.setCloRelatedTelListLoading(true));

    const report = yield select(makeSelectTelReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        offset: 0,
        limit: 100,
        cloGuid: payload,
        fields: G.getOrElse(report, 'fields', []),
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.addTelsToList(data));

      const params = { cloGuid: payload };

      const telsRes = yield call(sendRequest, 'get', endpointsMap.telListLoadPlanner, { params });

      if (G.isNotNilAndNotEmpty(telsRes.data)) {
        yield put(A.getCloTelsAndSetToStoreSuccess({ cloGuid: payload, tels: telsRes.data }));

        yield all(telsRes.data.map((tel: Object) => (
          put(A.setTelDetails(tel))
        )));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getCloTelsAndSetToStoreRequest fail');
    }

    yield put(A.setCloRelatedTelListLoading(false));
  } catch (err) {
    yield put(A.setCloRelatedTelListLoading(false));

    yield call(G.handleException, err, 'getCloTelsAndSetToStoreRequest exception');
  }
}

function* handleGetTelsByGuidsAndAddToPlannerSaga({ payload }: Object) {
  try {
    yield put(A.setTelListLoading(true));

    const report = yield select(makeSelectTelReport());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        offset: 0,
        limit: 100,
        telGuids: payload,
        fields: G.getOrElse(report, 'fields', []),
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telListLoadPlanner, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.addTelsToList(data));

      if (R.gt(R.length(payload), 1)) {
        const telGuidsWithOrder = R.init(payload).map((telGuid: string, i: number) => ({ telGuid, order: i }));

        yield all(telGuidsWithOrder.map(({ order, telGuid }: Object) => (
          call(handleGetTelByGuidAndMergeSaga, { order, payload: telGuid, withoutRefreshCloList: true })
        )));
      }

      yield put(A.addTelToPlanner(R.last(payload)));
      yield put(A.setTelListLoading(false));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelsByGuidsAndAddToPlannerSaga fail');

      yield put(A.setTelListLoading(false));
    }
  } catch (err) {
    yield put(A.setTelListLoading(false));

    yield call(G.handleException, err, 'handleGetTelsByGuidsAndAddToPlannerSaga exception');
  }
}

function* handleGetAvailableCloEventsReportSaga({ params, currentBranchGuid, withoutListRefresh }: Object) {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = checkReportFunction(data);
      const reportData = H.getInitialReportFromResponse(reports, currentBranchGuid);

      if (R.not(reportData.report)) {
        yield put(A.getAvailableCloEventsReportSuccess());

        return;
      }

      yield put(A.setAvailableCloReports(reports));
      yield put(A.setCurrentCloEventReport(reportData.report));

      if (R.not(withoutListRefresh)) {
        yield call(handleGetCloEventListSaga, true);

        if (R.equals(G.getItemFromLocalStorage('plannerView'), MAP_MODE)) {
          yield put(A.getCloEventsListForMapRequest());
        }
      }
      yield put(A.getAvailableCloEventsReportSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableCloEventsReportSaga fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetAvailableCloEventsReportSaga exception');
  }
}

function* handleCreateCloEventsReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);

    const params = {
      reportType: GC.CLO_EVENT_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };

    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload) };

    const res = yield call(sendRequest, 'post', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(
        handleGetAvailableCloEventsReportSaga,
        { params, currentBranchGuid, withoutListRefresh: true },
      );

      yield put(A.setCurrentCloEventReport(R.head(checkReportFunction(R.of(Array, data)))));

      yield call(handleRefreshCloEventListWithSelectedEvents);

      if (R.equals(G.getItemFromLocalStorage('plannerView'), MAP_MODE)) {
        yield put(A.getCloEventsListForMapRequest());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCloEventsReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateCloEventsReportRequestSaga exception');
  }
}

function* handleUpdateCloEventsReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = { data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload) };

    const res = yield call(sendRequest, 'put', endpointsMap.report, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.CLO_EVENT_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };

      yield call(
        handleGetAvailableCloEventsReportSaga,
        { params, currentBranchGuid, withoutListRefresh: true },
      );

      yield put(A.setCurrentCloEventReport(R.head(checkReportFunction(R.of(Array, data)))));

      yield call(handleRefreshCloEventListWithSelectedEvents);

      if (R.equals(G.getItemFromLocalStorage('plannerView'), MAP_MODE)) {
        yield put(A.getCloEventsListForMapRequest());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCloEventsReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCloEventsReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetAvailableTelReportSaga({ params }: Object) {
  try {
    const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const reports = checkReportFunction(data);
      const report = H.getReportFromReportsResponse(reports);

      if (R.not(report)) return;

      yield put(A.setAvailableTelReports(reports));
      yield put(A.setCurrentTelReportWithRoute(report));
      yield put(A.setCurrentInboundTelReport(report));
      yield put(A.setCurrentOutboundTelReport(report));
      const { status, telGuid, cloGuid } = yield select(makeSelectInitPlannerData());

      if (G.isNotNil(telGuid)) {
        yield put(A.setInitialPlannerData({}));
        yield call(handleGetInitialTelSaga, telGuid);
        yield put(A.addTelToPlanner(telGuid));
      }

      if (G.isNotNil(cloGuid)) {
        yield put(A.setInitialPlannerData({}));

        if (R.equals(status, GC.LOAD_STATUS_UNSCHEDULED)) {
          yield call(handleGetCloEventListByCloGuidSaga, cloGuid);
        } else {
          yield put(A.getCloTelsAndAddToPlannerRequest(cloGuid));
        }
      }

      yield put(A.getTelNextPage(true));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetAvailableTelReportSaga fail');
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleGetAvailableTelReportSaga exception');
  }
}

function* handleCreateTelReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const newPayload = R.set(R.lensProp('searchCriteria'), searchCriteria, payload);
    const options = { data: R.assoc(GC.FIELD_BRANCH_GUID, currentBranchGuid, newPayload) };
    const res = yield call(sendRequest, 'post', endpointsMap.report, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(handleGetAvailableTelReportSaga, { params });
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTelReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateTelReportRequestSaga exception');
  }
}

function* handleUpdateTelReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const params = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));
    const options = { data: R.set(R.lensProp('searchCriteria'), searchCriteria, payload) };
    const res = yield call(sendRequest, 'put', endpointsMap.report, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(handleGetAvailableTelReportSaga, { params });
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTelReportRequestSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleUpdateTelReportRequestSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleAvailableReportRequestSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const cloParams = {
      reportType: GC.CLO_EVENT_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    const telParams = {
      reportType: GC.TEL_REPORT,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
    };
    yield put(A.setRouteBuilderInitialState());
    yield put(A.setInitialCurrentRoute(currentBranchGuid));
    yield fork(
      handleGetAvailableCloEventsReportSaga,
      { params: cloParams, currentBranchGuid },
    );
    yield fork(
      handleGetAvailableTelReportSaga,
      { params: telParams, currentBranchGuid },
    );
    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleAvailableReportRequestSaga exception');
  }
}

function* handleSavePlannerRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { withClose, currentRoute, currentRouteItems } = payload;

    const openedFromPage = yield select(makeSelectOpenedFromPage());
    const initialRoute = yield select(makeSelectCurrentRouteInitialState());
    const telPrimaryRefAuto = yield select(makeSelectPrimaryReferenceAutogeneratedConfig());

    let reqData = H.transformCurrentRouteForRequest(currentRoute, currentRouteItems, telPrimaryRefAuto, initialRoute);

    if (G.isNilOrEmpty(reqData[GC.FIELD_BRANCH_GUID])) {
      reqData = R.assoc(
        GC.FIELD_BRANCH_GUID,
        yield select(makeSelectCurrentBranchGuid()),
        reqData,
      );
    }

    const editedCloGuids = yield select(makeSelectEditedCloGuids());
    const deletedEventGuids = yield select(makeSelectDeletedEventGuids());
    const unassignedCloEvents = yield select(makeSelectUnassignedCloEvents());

    reqData = R.mergeRight(reqData, {
      editedCloGuids,
      deletedEventGuids,
      unassignedCloEvents: R.map(
        (event: Object) => {
          const eventData = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(event));

          if (event.isNew) {
            return R.omit(['isNew', GC.FIELD_GUID], eventData);
          }

          return eventData;
        },
        unassignedCloEvents,
      ),
    });
    const isNotNewRoute = G.isNotNilAndNotEmpty(R.prop(GC.FIELD_GUID, reqData));
    const method = G.ifElse(isNotNewRoute, 'put', 'post');

    const res = yield call(sendRequest, method, endpointsMap.loadPlanner, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (R.equals(openedFromPage, 'DISPATCH_DETAILS_NEW')) {
        yield put(setDispatchPlannerEventsOpened(false));
        yield put(getLoadDetailsRequest());

        return yield put(closeLoader());
      }

      const { deletedTelGuids } = currentRoute;

      if (G.isNotNilAndNotEmpty(deletedTelGuids)) {
        yield put(A.removeTelsFromList(G.getPropFromObject('deletedTelGuids', currentRoute)));
      }

      const branchGuid = yield select(makeSelectCurrentBranchGuid());

      yield put(A.resetEditedCloEvents());
      yield put(A.setDeletedEventGuids([]));
      yield put(A.setInitialCurrentRoute(branchGuid));
      yield put(A.setInitialPlannerData({}));
      yield put(A.uncheckItemsOnAllLists());

      yield call(G.showToastrMessage, 'success', 'toasts:success:create');

      if (R.and(G.isNotNilAndNotEmpty(data), R.not(withClose))) {
        yield put(A.getTelsByGuidsAndAddToPlannerRequest(data));
      }
    } else {
      if (G.isOptimisticLocking(status)) {
        const message = `${G.getWindowLocale('message:data-changed', 'The data was changed')}. ${
          G.getWindowLocale('message:refresh-data', 'Please, refresh the data')}`;

        yield put(A.setRouteErrors({ 'requestErrors': { message } }));

        yield put(openModal({
          p: 15,
          component: <RefreshDataModal />,
          options: { width: 'auto', height: 'auto' },
        }));
      } else {
        yield put(A.setRouteErrors({ 'requestErrors': res.data }));
      }
      yield call(G.handleFailResponse, res, 'handleSavePlannerRequest fail');
    }

    yield put(closeLoader());
  } catch (err) {
    yield put(closeLoader());
    yield call(G.handleException, err, 'handleSavePlannerRequest exception');
  }
}

function* handleRecalculateTelDistances({ payload }: Object) {
  try {
    const currentRoute = yield select(makeSelectCurrentRoute());

    const currentTel = R.path(['tels', payload], currentRoute);
    const loadType = R.prop('loadType', currentTel);
    const stopPoints = H.getLoadEditModeStopsGeodata(currentTel);

    if (R.gt(R.length(stopPoints), 1)) {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: currentTel[GC.FIELD_BRANCH_GUID],
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      const events = H.mapLoadEventsWithDistances(currentTel, loadType, data);

      if (G.isResponseSuccess(status)) {
        yield put(A.recalculateTelDistancesSuccess({
          events,
          guid: currentTel.guid,
        }));
      }
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleRecalculateTelDistances exception');
  }
}

function* handleUpdateStopOnRouteBuilderSaga({ payload }: Object) {
  const { stop, telGuid } = payload;
  yield put(A.updateStopOnRouteBuilderTelSuccess(payload));
  if (G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop))) {
    const currentRoute = yield select(makeSelectCurrentRoute());
    const telGuids = R.keys(R.prop(GC.SYSTEM_LIST_TELS, currentRoute));
    yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));
  } else {
    yield put(A.recalculateTelDistancesRequest(telGuid));
  }
}

function* handleMoveTelStop({ guid, routeBuilderStore }: Object) {
  try {
    const currentRoute = R.path(['currentRoute'], routeBuilderStore);
    const currentTel = R.path(['tels', guid], currentRoute);
    const loadType = R.prop('loadType', currentTel);
    const stopPoints = H.getLoadEditModeStopsGeodata(currentTel);

    if (G.isNilOrEmpty(stopPoints)) return true;

    if (R.equals(R.length(stopPoints), 1)) {
      const events = H.mapLoadEventsWithDistances(currentTel, loadType, { stopResults: [] });

      yield put(A.recalculateTelDistancesWithMoveItemSuccess({
        events,
        routeBuilderStore,
        [GC.FIELD_GUID]: G.getGuidFromObject(currentTel),
      }));
    } else {
      const options = {
        data: {
          stopPoints,
          [GC.FIELD_BRANCH_GUID]: G.getBranchGuidFromObject(currentTel),
        },
      };

      const res = yield call(sendRequest, 'post', endpointsMap.distanceCalculation, options);

      const { data, status } = res;

      const stopResults = R.pathOr([], ['stopResults'], data);
      const events = H.mapLoadEventsWithDistances(currentTel, loadType, { stopResults });

      if (G.isResponseSuccess(status)) {
        yield put(A.recalculateTelDistancesWithMoveItemSuccess({
          events,
          routeBuilderStore,
          [GC.FIELD_GUID]: G.getGuidFromObject(currentTel),
        }));
      }
    }
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleMoveTelStop exception');
  }
}

function* handleAddTerminalOnEventAddOrMove({ payload }: Object) {
  try {
    const { telGuid, eventGuid } = payload;
    const event = yield select(makeSelectCloEventByGuid(eventGuid));
    const currentRoute = yield select(makeSelectCurrentRoute());
    let matchEvent = R.find(R.eqProps(GC.FIELD_GUID, event), event.details);
    matchEvent = R.assoc(
      GC.FIELD_LOAD_ITEMS,
      R.map(
        R.assoc(CLO_NAME, R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event)),
        R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
      ),
      matchEvent,
    );
    const templateId = R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, event);
    if (R.and(G.isStopDrop(matchEvent), G.isNotNilAndNotEmpty(templateId))) {
      const itemsPickedUp = H.isEventItemsPickedUp(
        matchEvent,
        R.path([GC.SYSTEM_LIST_TELS, telGuid], currentRoute),
      );
      const terminalExist = R.compose(
        R.find((telEvent: Object) => G.isAllTrue(
          G.isStopPickup(telEvent),
          G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
          R.pathEq(templateId, [GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent),
        )),
        R.values,
        R.path([GC.SYSTEM_LIST_TELS, telGuid, GC.FIELD_LOAD_STOPS]),
      )(currentRoute);
      const autoAddTerminal = R.and(
        R.not(itemsPickedUp),
        R.isNil(terminalExist),
      );
      if (autoAddTerminal) {
        const crossDocks = yield select(makeSelectCrossDockLocationList());
        const returnObject = {
          pickupTels: R.of(Array, telGuid),
        };
        const lastTerminalDate = R.path([GC.GRC.LAST_TERMINAL_DROP_LATE_DATE], event);
        const dates = {
          eventLateDate: G.addMomentTimeWithFormat(lastTerminalDate, 8),
          eventEarlyDate: G.addMomentTimeWithFormat(lastTerminalDate, 8),
        };
        const terminal = R.mergeRight(R.prop(templateId, crossDocks), dates);
        const terminalLocation = R.assoc(
          GC.FIELD_LOCATION_TYPE,
          R.omit([GC.FIELD_GUID], R.prop(GC.FIELD_LOCATION_TYPE, terminal)),
          terminal,
        );
        yield put(A.addTerminalToLoadPlannerTelUI({
          returnObject,
          [GC.FIELD_LOCATION]: terminalLocation,
          [GC.FIELD_EVENT_TYPE]: GC.STOP_TYPE_TERMINAL,
        }));
      }
    }
  } catch (err) {
    yield call(G.handleException, err, 'handleAddTerminalOnEventAddOrMove exception');
  }
}

function* handleRecalculateTelDistancesWithMoveStop({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    let routeBuilderStore = yield select(makeSelectBuilderStore());

    if (H.isEqualSourceAndDestinationLoad(payload)) {
      routeBuilderStore = H.reorderTelEventsInState(routeBuilderStore, payload);

      yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['source', 'droppableId'], payload),
      });
    } else {
      const eventGuid = R.compose(
        R.last,
        R.split('_'),
        R.path(['draggableId']),
      )(payload);

      const event = yield select(makeSelectCloEventByGuid(eventGuid));

      let matchEvent = R.find(R.eqProps(GC.FIELD_GUID, event), G.getPropFromObject('details', event));

      matchEvent = R.assoc(
        GC.FIELD_LOAD_ITEMS,
        R.map(
          R.assoc(CLO_NAME, R.prop(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event)),
          R.prop(GC.FIELD_LOAD_ITEMS, matchEvent),
        ),
        matchEvent,
      );

      routeBuilderStore = H.moveEventFromTelToTel(routeBuilderStore, payload, matchEvent);

      const isSourceTelWithoutEvents = yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['source', 'droppableId'], payload),
      });

      if (R.not(isSourceTelWithoutEvents)) routeBuilderStore = yield select(makeSelectBuilderStore());

      yield call(handleMoveTelStop, {
        routeBuilderStore,
        [GC.FIELD_GUID]: R.path(['destination', 'droppableId'], payload),
      });

      yield put(A.addTerminalOnEventAddOrMove({
        eventGuid,
        telGuid: R.path(['destination', 'droppableId'], payload),
      }));
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleRecalculateTelDistancesWithMoveStop');
  }
}

function* handleChangeDefaultCloReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.CLO_EVENT_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };
      yield call(
        handleGetAvailableCloEventsReportSaga,
        {
          params,
          routeGuid: null,
          currentBranchGuid,
        },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultCloReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'handleChangeDefaultCloReportSaga exception');
    yield put(closeLoader());
  }
}

function* handleChangeDefaultTelReportSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
      const params = {
        reportType: GC.TEL_REPORT,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };
      yield call(
        handleGetAvailableTelReportSaga,
        {
          params,
          routeGuid: null,
          currentBranchGuid,
        },
      );
    } else {
      yield call(G.handleFailResponse, res, 'handleChangeDefaultTelReportSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleChangeDefaultTelReportSaga exception');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: { names, [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      const defaultCrossDocks = R.path(
        ['configs', GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK, GC.FIELD_VALUE],
        mappedConfigs,
      );

      if (G.isNotNilAndNotEmpty(defaultCrossDocks)) {
        yield put(A.setInboundTerminals(defaultCrossDocks));
        yield put(A.setOutboundTerminals(defaultCrossDocks));
        yield put(A.getInboundTelNextPage(true));
        yield put(A.getOutboundTelNextPage(true));
      }

      yield put(A.getConfigsByNamesSuccess(mappedConfigs));

      yield delay(1000);

      const currentRoute = yield select(makeSelectCurrentRoute());
      const { telGuid } = yield select(makeSelectInitPlannerData());
      const initPlannerDataFromTemplate = yield select(makeSelectInitPlannerDataFromTemplate());

      if (G.isNotNilAndNotEmpty(initPlannerDataFromTemplate)) {
        yield put(A.setInitPlannerDataFromTemplate({}));
        yield put(A.addRouteTemplateStops(initPlannerDataFromTemplate));
      }

      if (G.isAllTrue(
        R.isNil(telGuid),
        G.isNilOrEmpty(initPlannerDataFromTemplate),
        G.isNilOrEmpty(R.values(G.getPropFromObject(GC.SYSTEM_LIST_TELS, currentRoute))),
      )) {
        const autoTelPrimaryRef = G.getConfigValueFromStore(
          GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
          mappedConfigs,
        );

        if (G.isTrue(autoTelPrimaryRef)) {
          const telInfo = {
            [GC.FIELD_BRANCH_GUID]: branchGuid,
            [GC.FIELD_PRIMARY_REFERENCE]: { value: `${G.getWindowLocale('titles:tel', 'Trip')}-1` },
          };

          yield put(A.addNewTelToRouteBuilder({ telInfo }));
        }
      }
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
  }
}

function* handleDispatchTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const { guid, order, dispatchAction } = payload;

    yield put(A.cleanTelDetails(guid));

    const endpoint = endpointsMap.getTelActionEndpoint(guid, dispatchAction);

    const res = yield call(sendRequest, 'put', endpoint);

    const { status} = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleGetTelByGuidAndMergeSaga, { order, payload: guid });
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchTelRateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchTelRateSaga exception');
  }
}

function* handleDispatchCarrierTelRateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const endpoint = endpointsMap.telCarrierRateDispatch;
    const res = yield call(sendRequest, 'put', endpoint, { data: payload });
    const { status } = res;
    yield put(closeModal());
    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableReportsRequest());
    } else {
      yield call(G.handleFailResponse, res, 'handleDispatchCarrierTelRateSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDispatchCarrierTelRateSaga  exception');
  }
}

function* handleAddExchangeTerminalSaga({ payload }: Object) {
  try {
    const { tel, templateId } = payload;
    const currentRoute = yield select(makeSelectCurrentRoute());
    const crossDocks = yield select(makeSelectCrossDockLocationList());
    const terminal = R.assoc('stopId', G.generateGuid(), R.prop(templateId, crossDocks));
    const terminalWithoutDates = H.omitLocationFields(terminal);
    const newCurrentRoute = H.getCurrentRouteWithExchangeTerminal(tel, currentRoute, terminalWithoutDates);
    yield put(A.setCurrentRoute(newCurrentRoute));
    const telGuids = R.compose(
      R.map(R.prop(GC.FIELD_GUID)),
      R.values,
      R.prop(GC.SYSTEM_LIST_TELS),
    )(newCurrentRoute);
    yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));
    yield put(closeModal());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddExchangeTerminalSaga  exception');
  }
}

function* handleAddStopsToExchangeTerminalSaga({ payload }: Object) {
  try {
    const { tel, split, events, templateId } = payload;
    const currentRoute = yield select(makeSelectCurrentRoute());
    const crossDocks = yield select(makeSelectCrossDockLocationList());
    const terminal = R.prop(templateId, crossDocks);
    const terminalWithoutDates = R.mergeRight(terminal, {
      stopId: G.generateGuid(),
      [GC.FIELD_LOCATION_TYPE]: R.omit([GC.FIELD_GUID], R.prop(GC.FIELD_LOCATION_TYPE, terminal)),
    });
    let newCurrentRoute;
    if (split) {
      newCurrentRoute = H.getCurrentRouteWithSplitEvents({
        tel,
        events,
        currentRoute,
        terminalWithoutDates,
      });
    } else {
      newCurrentRoute = H.getCurrentRouteWithEventsOnExchangeTerminal({
        tel,
        events,
        currentRoute,
        terminalWithoutDates,
      });
    }
    yield put(A.setCurrentRoute(newCurrentRoute));
    const telGuids = R.compose(
      R.map(R.prop(GC.FIELD_GUID)),
      R.values,
      R.prop(GC.SYSTEM_LIST_TELS),
    )(newCurrentRoute);
    yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));
    yield put(closeModal());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddStopsToExchangeTerminalSaga  exception');
  }
}

function* handleAddRouteTemplateStopsSaga({ payload }: Object) {
  try {
    const { tel, lateDate, earlyDate, templateGuid } = payload;

    const currentRoute = yield select(makeSelectCurrentRoute());
    const routeTemplates = yield select(makeSelectRouteTemplates());
    const locationList = yield select(makeSelectCrossDockLocationList());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const routeTemplate = G.getPropFromObject(templateGuid, routeTemplates);

    const locations = R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      R.values,
    )(locationList);

    const newCurrentRoute = H.getCurrentRouteWithRouteTemplateEvents({
      tel,
      locations,
      currentRoute,
      routeTemplate,
      currentBranchGuid,
      firstEventDates: { lateDate, earlyDate },
    });

    yield put(A.setCurrentRoute(newCurrentRoute));

    const telGuids = R.keys(R.pathOr({}, [GC.SYSTEM_LIST_TELS], newCurrentRoute));

    yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));

    yield put(closeModal());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddRouteTemplateStopsSaga  exception');
  }
}

function* handleUpdateCloStopsOnPlanner({ payload }: Object) {
  try {
    yield put(A.updateCloStopsOnPlannerSuccess(payload));
    yield put(A.recalculateTelDistancesRequest(payload.telGuid));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleUpdateCloStopsOnPlanner  exception');
  }
}

function* handleAddNewStopToRouteBuilderTel({ payload }: Object) {
  try {
    yield put(A.addNewStopToRouteBuilderTelSuccess(payload));
    yield put(A.recalculateTelDistancesRequest(payload.loadGuid));
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddNewStopToRouteBuilderTel  exception');
  }
}

function* handleAddTerminalToLoadPlanner({ payload }: Object) {
  try {
    yield put(A.addTerminalToLoadPlannerTelUI(payload));

    const { returnObject } = payload;

    const telGuids = R.concat(
      R.pathOr([], ['dropTels'], returnObject),
      R.pathOr([], ['pickupTels'], returnObject),
    );

    if (G.isNotNilAndNotEmpty(telGuids)) {
      yield all(telGuids.map((guid: string) => put(A.recalculateTelDistancesRequest(guid))));
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleAddTerminalToLoadPlanner  exception');
  }
}

function* getRateListByGuidsSaga(guids: Array) {
  try {
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.rateListByGuidsLoadPlanner,
      { data: guids },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getRateListByGuidsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getRateListByGuidsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getRateListByGuidsSaga  exception');
  }
}

function* getEventListByGuidsSaga(guids: Array) {
  try {
    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.eventListByGuidsLoadPlanner,
      { data: guids },
    );
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getEventListByGuidsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getEventListByGuidsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getEventListByGuidsSaga  exception');
  }
}

function* getRatesAndEventsByGuids() {
  const currentRoute = yield select(makeSelectCurrentRoute());
  const { rateGuids, eventGuids } = H.getRateAndEventGuidsFromCurrentRoute(currentRoute);
  if (G.isNotNilAndNotEmpty(rateGuids)) {
    yield fork(getRateListByGuidsSaga, rateGuids);
  }
  if (G.isNotNilAndNotEmpty(eventGuids)) {
    yield fork(getEventListByGuidsSaga, eventGuids);
  }
}

function* handleRefreshPlannerDataSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));
    yield call(getRatesAndEventsByGuids);
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleRefreshPlannerDataSaga  exception');
  }
}

function* handleCreateRouteTemplateRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.loadPlannerTemplate, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createRouteTemplateSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:create');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateRouteTemplateRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateRouteTemplateRequestSaga exception');
  }
}

function* handleGetRouteTemplatesRequestSaga() {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.loadPlannerTemplateListAvailable, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getRouteTemplatesSuccess(R.indexBy(R.prop(GC.FIELD_GUID), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetRouteTemplatesRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleGetRouteTemplatesRequestSaga exception');
  }
}

const CONFIGS_ARR = [
  // trailer
  GC.TRAILER_MANAGE_TRAILERS_FROM,
  // templates
  GC.TEMPLATES_LOCATION_TYPE,
  // clo
  GC.CLO_GENERAL_DROP_INTERVAL,
  GC.CLO_GENERAL_EVENTS_INTERVAL,
  GC.CLO_GENERAL_PICKUP_INTERVAL,
  // clo item
  GC.CLO_ITEM_MAX_WIDTH,
  GC.CLO_ITEM_MAX_LENGTH,
  GC.CLO_ITEM_MAX_HEIGHT,
  GC.CLO_ITEM_DEFAULT_TYPE,
  GC.CLO_ITEM_DIMENSION_UOM,
  GC.CLO_ITEM_REQUIRED_FIELDS,
  GC.CLO_ITEM_DEFAULT_COUNTRY,
  GC.CLO_ITEM_DEFAULT_WEIGHT_UOM,
  GC.CLO_ITEM_USE_DIFFERENT_TYPES,
  GC.CLO_ITEM_DEFAULT_PACKAGE_TYPE,
  GC.CLO_UI_ADDITIONAL_ITEM_FIELDS,
  GC.CLO_ITEM_DEFAULT_TEMPERATURE_UOM,
  // tel
  GC.TEL_PRIMARY_REFERENCE_TYPE,
  GC.TEL_PRIMARY_REFERENCE_PREFIX,
  GC.TEL_PRIMARY_REFERENCE_SEQUENCE,
  GC.TEL_PRIMARY_REFERENCE_COPY_FROM_CLO,
  GC.TEL_PRIMARY_REFERENCE_AUTOGENERATED,
  GC.TEL_ROUTE_BUILDER_DEFAULT_CROSSDOCK,
  // general
  GC.GENERAL_SERVICES,
  GC.GENERAL_EQUIPMENTS,
  GC.GENERAL_MODE_TRANSPORTATION,
  GC.GENERAL_ROUTE_NAME_AUTOGENERATED,
  GC.GENERAL_TRANSPORTATION_SERVICE_TYPE,
  GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
  // ui
  GC.UI_LOAD_PLANNER_HIDE_INBOUND_OUTBOUND_TABS,
];

function* handleGetCrossDockLocationsSaga(branchGuid: string) {
  try {
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.crossDockLocationList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCrossDockLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCrossDockLocationsSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCrossDockLocationsSaga exception');
  }
}

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_LOAD_PLANNER_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(getAccessorialForConfigRequest());
    yield put(A.getAvailableReportsRequest());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield call(handleGetCrossDockLocationsSaga, branchGuid);
    yield call(handleGetRouteTemplatesRequestSaga, branchGuid);

    yield put(A.getConfigsByNamesRequest({
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    }));

    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_LOAD_EVENT });
    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_CLO });
    yield call(getAllAvailableRefTypesByScopeRequestSaga, { payload: GC.REF_SCOPE_NAME_TEL });

    yield put(getAvailableReportGeoFencingZoneListRequest());
    yield put(closeLoader());

    break;
  }
}

function* handleOpenDispatchPlannerEventsSaga() {
  try {
    yield put({
      type: GC.VISIT_LOAD_PLANNER_PAGE,
      payload: {
        route: GC.ROUTE_PATH_LOAD_PLANNER,
      },
    });
  } catch (error) {
    yield call(G.handleException, error, 'handleOpenDispatchPlannerEventsSaga exception');
  }
}


function* dispatchPlannerWatcherSaga() {
  yield takeLatest(A.getTelNextPage, handleGetTelListSaga);
  yield takeLatest(A.toggleTelDetails, handleToggleTelDetails);
  yield takeLatest(A.savePlannerRequest, handleSavePlannerRequest);
  yield takeLatest(GC.VISIT_LOAD_PLANNER_PAGE, handleVisitPageSaga);
  yield takeLatest(A.getTelDetailsRequest, handleGetTelDetailsSaga);
  yield takeLatest(A.setTelsToPlannerRequest, setTelsToPlannerSaga);
  yield takeEvery(A.addTelToPlanner, handleGetTelByGuidAndMergeSaga);
  yield takeLatest(A.getCloEventsNextPage, handleGetCloEventListSaga);
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeLatest(A.dispatchTelRateRequest, handleDispatchTelRateSaga);
  yield takeLatest(A.getInboundTelNextPage, handleGetInboundTelListSaga);
  yield takeLatest(A.toggleCloEventDetails, handleToggleCloEventDetails);
  yield takeLatest(A.addExchangeTerminal, handleAddExchangeTerminalSaga);
  yield takeLatest(A.getOutboundTelNextPage, handleGetOutboundTelListSaga);
  yield takeLatest(A.addRouteTemplateStops, handleAddRouteTemplateStopsSaga);
  yield takeLatest(A.refreshPlannerDataRequest, handleRefreshPlannerDataSaga);
  yield takeLatest(A.createTelReportRequest, handleCreateTelReportRequestSaga);
  yield takeLatest(A.updateTelReportRequest, handleUpdateTelReportRequestSaga);
  yield takeLatest(A.getCloEventDetailsRequest, handleGetCloEventDetailsRequest);
  yield takeLatest(A.addTerminalToLoadPlannerTel, handleAddTerminalToLoadPlanner);
  yield takeLatest(A.getRouteTemplatesRequest, handleGetRouteTemplatesRequestSaga);
  yield takeLatest(A.getAvailableReportsRequest, handleAvailableReportRequestSaga);
  yield takeEvery(A.recalculateTelDistancesRequest, handleRecalculateTelDistances);
  yield takeLatest(A.updateCloStopsOnPlannerRequest, handleUpdateCloStopsOnPlanner);
  yield takeLatest(A.selectCloEventForTelRequest, handleSelectCloEventForTelRequest);
  yield takeLatest(A.addTerminalOnEventAddOrMove, handleAddTerminalOnEventAddOrMove);
  yield takeLatest(A.addNewStopToRouteBuilderTel, handleAddNewStopToRouteBuilderTel);
  yield takeLatest(A.getCloTelsAndSetToStoreRequest, getCloTelsAndSetToStoreRequest);
  yield takeLatest(A.changeDefaultTelReportRequest, handleChangeDefaultTelReportSaga);
  yield takeLatest(A.dispatchCarrierTelRateRequest, handleDispatchCarrierTelRateSaga);
  yield takeLatest(A.addStopsToExchangeTerminal, handleAddStopsToExchangeTerminalSaga);
  yield takeLatest(A.createRouteTemplateRequest, handleCreateRouteTemplateRequestSaga);
  yield takeLatest(A.addTelToLoadPlannerByGuid, handleGetTelForListAndAddToPlannerSaga);
  yield takeLatest(A.changeDefaultCloEventReportRequest, handleChangeDefaultCloReportSaga);
  yield takeLatest(A.createCloEventsReportRequest, handleCreateCloEventsReportRequestSaga);
  yield takeLatest(A.updateCloEventsReportRequest, handleUpdateCloEventsReportRequestSaga);
  yield takeLatest(A.openDispatchPlannerEventsRequest, handleOpenDispatchPlannerEventsSaga);
  yield takeLatest(A.getCloTelsAndAddToPlannerRequest, handleGetCloTelsAndAddToPlannerSaga);
  yield takeLatest(A.updateStopOnRouteBuilderTelRequest, handleUpdateStopOnRouteBuilderSaga);
  yield takeLatest(A.addCloEventToInterTerminalTelRequest, handleAddCloEventToInterTerminalTel);
  yield takeLatest(A.getTelForListAndAddToPlannerRequest, handleGetTelForListAndAddToPlannerSaga);
  yield takeLatest(A.getTelsByGuidsAndAddToPlannerRequest, handleGetTelsByGuidsAndAddToPlannerSaga);
  yield takeLatest(A.refreshCloEventListWithSelectedEvents, handleRefreshCloEventListWithSelectedEvents);
  yield takeLatest(A.recalculateTelDistancesWithMoveStopRequest, handleRecalculateTelDistancesWithMoveStop);

  yield fork(cloEventsMapWatcherSaga);
}

export default dispatchPlannerWatcherSaga;

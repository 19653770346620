import * as R from 'ramda';
import moment from 'moment';
import * as P from 'plow-js';
// features
import PC from '../permission/role-permission';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-board-new
import * as C from './constants';
// utilities
import routesMap from '../../utilities/routes';
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
/////////////////////////////////////////////////

const getCarrierFleetData = (groupingObject: Object) => {
  if (R.pathEq(GC.ORGANIZE_BY_DRIVER, ['type'], groupingObject)) {
    const firstName = R.pathOr('', ['driver', 'firstName'], groupingObject);
    const lastName = R.pathOr('', ['driver', 'lastName'], groupingObject);
    if (R.or(G.isNotNilAndNotEmpty(firstName), G.isNotNilAndNotEmpty(lastName))) {
      return `${firstName} ${lastName}`;
    }
  } else {
    const firstName = R.pathOr('', ['carrierName'], groupingObject);
    if (G.isNotNilAndNotEmpty(firstName)) {
      return firstName;
    }
  }
  return G.getWindowLocale('titles:no-driver-carrier', 'No Driver/Carrier');
};

const getUserTitleName = (groupingObject: Object) => {
  return `${R.pathOr('', [GC.FIELD_FIRST_NAME], groupingObject)} ${
    R.pathOr('', [GC.FIELD_LAST_NAME], groupingObject)} (${R.pathOr('', [GC.FIELD_USER_LOGIN_ID], groupingObject)})`;
};

export const getTableTitleName = (groupingObject: any, groupBy: string) => {
  const titleForGroups = {
    [GC.ORGANIZE_BY_CARRIER]: getCarrierFleetData(groupingObject),
    [GC.ORGANIZE_BY_DRIVER]: getCarrierFleetData(groupingObject),
    [GC.ORGANIZE_BY_TRUCK]: G.ifElse(
      G.isString(groupingObject),
      groupingObject,
      G.getWindowLocale('titles:no-truck', 'No Truck'),
    ),
    [GC.ORGANIZE_BY_TRAILER]: G.ifElse(
      G.isString(groupingObject),
      groupingObject,
      G.getWindowLocale('titles:no-trailer', 'No Trailer'),
    ),
    [GC.ORGANIZE_BY_LAST_EVENT_DATE]: R.pathOr(
      G.getWindowLocale('titles:no-group', 'No Group'),
      [GC.FIELD_DATE],
      groupingObject,
    ),
    [GC.ORGANIZE_BY_FIRST_EVENT_DATE]: R.pathOr(
      G.getWindowLocale('titles:no-group', 'No Group'),
      [GC.FIELD_DATE],
      groupingObject,
    ),
    [GC.ORGANIZE_BY_ROUTE]: R.pathOr(
      G.getWindowLocale('titles:no-route', 'No Route'),
      [GC.FIELD_ROUTE_NAME],
      groupingObject,
    ),
    [GC.ORGANIZE_BY_BRANCH]: G.ifElse(
      G.isString(groupingObject),
      groupingObject,
      G.getWindowLocale('titles:no-group', 'No Group'),
    ),
    [GC.ORGANIZE_BY_BILL_TO]: G.ifElse(
      G.isString(groupingObject),
      groupingObject,
      G.getWindowLocale('titles:no-group', 'No Group'),
    ),
    [GC.ORGANIZE_BY_DISPATCHED_BY]: getUserTitleName(groupingObject),
    [GC.ORGANIZE_BY_STATUS]: G.ifElse(
      G.isString(groupingObject),
      G.getEnumLocale(groupingObject),
      G.getWindowLocale('titles:no-group', 'No Group'),
    ),
  };

  return R.path([groupBy], titleForGroups);
};

export const getTabs = (tabName: string) => {
  const isEDITab = R.equals(tabName, GC.FIELD_EDI);
  const isTelTab = R.equals(tabName, GC.LOAD_TYPE_TEL);
  const isCloTab = R.equals(tabName, GC.LOAD_TYPE_CLO);
  const isPublicCLOTab = R.equals(tabName, 'publicCLO');
  const isQuotesTab = R.equals(tabName, GC.FIELD_QUOTES);
  const isCarrierEdi = R.equals(tabName, GC.FIELD_CARRIER_EDI);
  const isOrderQuotesTab = R.equals(tabName, GC.FIELD_ORDER_QUOTES);
  const hiddenTabs = G.getHiddenTabsConfigFromWindow();

  const tabs = [
    {
      isActive: isTelTab,
      withCount: isTelTab,
      key: 'tabs:dispatch-tel',
      permissions: [PC.TEL_READ, PC.TEL_WRITE],
      text: G.getWindowLocale('titles:tel', 'TEL'),
      clickHandler: () => G.goToRoute(routesMap.routeListLoad),
    },
    {
      isActive: isCloTab,
      withCount: isCloTab,
      key: 'tabs:dispatch-clo',
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      text: G.getWindowLocale('titles:clo', 'CLO'),
      clickHandler: () => G.goToRoute(routesMap.routeListOrder),
    },
    {
      isActive: isEDITab,
      withCount: isEDITab,
      key: 'tabs:dispatch-edi',
      text: G.getWindowLocale('titles:edi', 'EDI'),
      permissions: [PC.CLO_EDI_READ, PC.CLO_EDI_WRITE],
      clickHandler: () => G.goToRoute(routesMap.ediList),
    },
    {
      isActive: isCarrierEdi,
      withCount: isCarrierEdi,
      key: 'tabs:dispatch-carrier-edi',
      permissions: [PC.CARRIER_EDI_READ, PC.CARRIER_EDI_WRITE],
      clickHandler: () => G.goToRoute(routesMap.carrierEdiList),
      text: G.getWindowLocale('titles:carrier-edi', 'Carrier EDI'),
    },
    {
      isActive: isPublicCLOTab,
      withCount: isPublicCLOTab,
      key: 'tabs:dispatch-order-api',
      text: G.getWindowLocale('titles:oder-api', 'Order API'),
      clickHandler: () => G.goToRoute(routesMap.publicCLOList),
      permissions: [PC.PUBLIC_API_ORDER_READ, PC.PUBLIC_API_ORDER_WRITE],
    },
    {
      isActive: isOrderQuotesTab,
      withCount: isOrderQuotesTab,
      key: 'tabs:dispatch-order-quotes',
      text: G.getWindowLocale('titles:quotes', 'Quotes'),
      clickHandler: () => G.goToRoute(routesMap.orderQuotes),
      permissions: [PC.ORDER_QUOTE_READ, PC.ORDER_QUOTE_WRITE],
    },
    {
      isActive: isQuotesTab,
      withCount: isQuotesTab,
      permissions: [PC.CLO_READ, PC.CLO_WRITE],
      key: 'tabs:dispatch-order-external-quotes',
      clickHandler: () => G.goToRoute(routesMap.getQuoteList),
      text: G.getWindowLocale('titles:external-quotes', 'External Quotes'),
    },
  ];

  return R.filter(
    R.compose(R.curry(G.notInList)(hiddenTabs), R.prop('key')), tabs,
  );
};

// SUMMARY
export const getSummaryLocale = (type: string) => {
  const groupByLocale = {
    [C.SUMMARY_TRUCK]: G.getWindowLocale('titles:truck', 'Truck'),
    [C.SUMMARY_BRANCH]: G.getWindowLocale('titles:branch', 'Branch'),
    [C.SUMMARY_STATUS]: G.getWindowLocale('titles:status', 'Status'),
    [C.SUMMARY_DRIVER]: G.getWindowLocale('titles:driver', 'Driver'),
    [C.SUMMARY_TRAILER]: G.getWindowLocale('titles:trailer', 'Trailer'),
    [C.SUMMARY_CARRIER]: G.getWindowLocale('titles:carrier', 'Carrier'),
    [C.SUMMARY_BILL_TO]: G.getWindowLocale('titles:bill-to', 'Bill To'),
    [C.SUMMARY_LAST_EVENT_DATE]: G.getWindowLocale('titles:last-stop-date', 'Last Stop Date'),
    [C.SUMMARY_FIRST_EVENT_DATE]: G.getWindowLocale('titles:first-stop-date', 'First Stop Date'),
  };
  return R.pathOr('No Summary', [type], groupByLocale);
};

export const getSummaryGroupRenderFunctions = (entries: Array) => ({
  [C.SUMMARY_DRIVER]: (value: any) => {
    const noEntity = G.getWindowLocale('titles:no-driver-carrier', 'No Driver/Carrier');
    if (G.isNilOrEmpty(value)) return noEntity;
    const entity = R.find(R.pathEq(value, ['key', 'guid']), entries);
    return G.ifElse(G.isNotNilAndNotEmpty(entity), R.path(['key', 'fullName'], entity), noEntity);
  },
  [C.SUMMARY_TRUCK]: (value: any) => {
    const noEntity = G.getWindowLocale('titles:no-truck', 'No Truck');
    if (G.isNilOrEmpty(value)) return noEntity;
    const entity = R.find(R.pathEq(value, ['key', 'guid']), entries);
    return G.ifElse(G.isNotNilAndNotEmpty(entity), R.path(['key', 'unitId'], entity), noEntity);
  },
  [C.SUMMARY_CARRIER]: (value: any) => {
    const noEntity = G.getWindowLocale('titles:no-driver-carrier', 'No Driver/Carrier');
    if (G.isNilOrEmpty(value)) return noEntity;
    const entity = R.find(R.pathEq(value, ['key', 'guid']), entries);
    return G.ifElse(G.isNotNilAndNotEmpty(entity), R.path(['key', 'fullName'], entity), noEntity);
  },
  [C.SUMMARY_BRANCH]: (value: any) => {
    if (G.isNilOrEmpty(value)) return G.getWindowLocale('titles:no-branch', 'No Branch');
    const entity = R.find(R.pathEq(value, ['key', 'guid']), entries);
    return G.ifElse(
      G.isNotNilAndNotEmpty(entity),
      R.path(['key', GC.FIELD_BRANCH_NAME], entity),
      G.getWindowLocale('titles:no-branch', 'No Branch'),
    );
  },
  [C.SUMMARY_STATUS]: (value: string) => (
    G.getWindowLocale(GC.statusLocaleMap[value], value)
  ),
  [C.SUMMARY_TRAILER]: (value: any) => {
    const noEntity = G.getWindowLocale('titles:no-trailer', 'No Trailer');
    if (G.isNilOrEmpty(value)) return noEntity;
    const entity = R.find(R.pathEq(value, ['key', 'guid']), entries);
    return G.ifElse(G.isNotNilAndNotEmpty(entity), R.path(['key', 'unitId'], entity), noEntity);
  },
});

export const getFilterItems = (entries: Array) => (
  R.reduce((prev: Object, item: Object) => {
    const items = R.pathOr([], ['entries'], item);
    const newItems = [R.omit(['entries'], item), ...items];
    return R.concat(prev, newItems);
  }, [], entries)
);

export const getDisplayValue = (selectedReport: Object, value: string) => (
  getSummaryLocale(selectedReport[value])
);

export const getSummaryGroupTitle = (selectedReport: Object, entry: Object) => {
  let title = null;
  if (R.and(
    R.or(
      R.equals(selectedReport.summaryGroup, C.SUMMARY_DRIVER),
      R.equals(selectedReport.summaryGroup, C.SUMMARY_CARRIER),
    ),
    G.isNotNilAndNotEmpty(R.path(['key', 'type'], entry)),
  )) title = getSummaryLocale(R.path(['key', 'type'], entry));
  if (R.and(
    R.equals(selectedReport.summaryGroup, GC.ORGANIZE_BY_DISPATCHED_BY),
    G.isNotNilAndNotEmpty(R.path(['key'], entry)),
  )) title = G.getWindowLocale('titles:user', 'User');
  if (R.isNil(title)) return getDisplayValue(selectedReport, 'summaryGroup');
  return title;
};

export const getGroupHeaderText = (selectedReport: Object) => {
  if (selectedReport.summarySubgroup) {
    return `Group: ${getDisplayValue(selectedReport, 'summaryGroup')};
            Sub Group: ${getDisplayValue(selectedReport, 'summarySubgroup')}`;
  }
  return `Group: ${getDisplayValue(selectedReport, 'summaryGroup')}`;
};

const setSummaryFilters = (group: string, value: string) => {
  const searchCriteria = {
    summaryGroup: group,
  };
  if (G.stringContains(group, 'EVENT_DATE')) {
    searchCriteria.dateValue = moment(value).endOf('day').format(GC.DEFAULT_DATE_FORMAT);
  } else if (R.equals(group, GC.ORGANIZE_BY_DISPATCHED_BY)) {
    searchCriteria.stringValue = R.pathOr(value, [GC.FIELD_USER_LOGIN_ID], value);
  } else {
    searchCriteria.stringValue = R.pathOr(value, [GC.FIELD_GUID], value);
  }
  return searchCriteria;
};

export const getReportWithSummary = (group: string, groupValue: string, parent: Object, report: Object) => {
  let summaryFilters = [];
  if (G.isNilOrEmpty(parent)) {
    summaryFilters = R.of(Array, setSummaryFilters(group, groupValue.key));
  } else {
    summaryFilters = [
      setSummaryFilters(report.summaryGroup, parent.key),
      setSummaryFilters(group, groupValue.key),
    ];
  }
  return R.assoc('summaryFilters', summaryFilters, report);
};
// SUMMARY

// UI TABLE
export const getRouteRowBgColor = (notifications: Array) => {
  if (R.isEmpty(notifications)) return null;
  const criticalVisible = R.any(
    (notification: Object) => R.and(
      R.not(notification.warningHidden),
      R.equals(notification.warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_CRITICAL),
    ),
    notifications,
  );
  if (criticalVisible) return G.getTheme('colors.light.criticalBackground');
  const warningVisible = R.any(
    (notification: Object) => R.and(
      R.not(notification.warningHidden),
      R.equals(notification.warningLevel, GC.NOTIFICATION_WARNING_LEVEL_TYPE_WARNING),
    ),
    notifications,
  );
  if (warningVisible) return G.getTheme('colors.light.warningBackground');
  return null;
};
// UI TABLE

// UI
export const asyncGetRate = async (rateGuid: string, successCallback: Function) => {
  try {
    const res = await sendRequest('get', endpointsMap.getTelRateItemEndpoint(rateGuid));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(successCallback, data);
    }
  } catch (error) {
    G.handleException(error, 'asyncGetRate');
  }
};

export const asyncGetCloRate = async (rateGuid: string, successCallback: Function) => {
  try {
    const res = await sendRequest('get', endpointsMap.getCloRateItemEndpoint(rateGuid));
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      G.callFunctionWithArgs(successCallback, data);
    }
  } catch (error) {
    G.handleException(error, 'asyncGetRate');
  }
};

export const getCLODataRequest = async (guid: string) => {
  try {
    const endpoint = endpointsMap.getCloEndpoint(guid);
    const res = await sendRequest('GET', endpoint);
    const { data, status } = res;
    if (G.isResponseSuccess(status, data)) {
      if (G.isNotNil(data)) return data;
    } else {
      G.handleFailResponseSimple(
        res,
        true,
        'getCLODataRequest -> getCLODataRequest',
      );
    }
  } catch (error) {
    G.handleException(error, 'getCLODataRequest');
  }
};

export const getItemListGuids = R.compose(
  R.map(G.getGuidFromObject),
  R.reduce((acc: Array, group: Object) => R.concat(acc, R.values(group.loads)), []),
);
// UI

// SAGA
export const getGuidsFromRouteListResults = (results: Array) => R.compose(
  R.map(({ guid }: Object) => guid),
  R.reduce((acc: Array, route: Object) => R.concat(acc, route.loads), []),
)(results);

export const getSelectedRateGuidFromDispatchBoardByTelGuid = (itemList: Array, telGuid: string) => {
  let load;

  itemList.forEach((group: Object) => {
    if (G.isNotNil(R.path(['loads', telGuid], group))) {
      load = R.path(['loads', telGuid], group);
    }
  });

  return R.pathOr(R.path([GC.FIELD_SELECTED_RATE_GUID], load), ['details', GC.FIELD_SELECTED_RATE_GUID], load);
};

const firstEventStringFieldNames = [
  GC.GRC.FIRST_EVENT_LOCATION_TEMPLATE_ID,
  GC.GRC.FIRST_EVENT_LOCATION_NAME,
  GC.GRC.FIRST_EVENT_LOCATION_ADDRESS1,
  GC.GRC.FIRST_EVENT_LOCATION_CITY,
  GC.GRC.FIRST_EVENT_LOCATION_STATE,
  GC.GRC.FIRST_EVENT_LOCATION_COUNTRY,
  GC.GRC.FIRST_EVENT_LOCATION_ZIP,
];

const lastEventStringFieldNames = [
  GC.GRC.LAST_EVENT_LOCATION_TEMPLATE_ID,
  GC.GRC.LAST_EVENT_LOCATION_NAME,
  GC.GRC.LAST_EVENT_LOCATION_ADDRESS1,
  GC.GRC.LAST_EVENT_LOCATION_CITY,
  GC.GRC.LAST_EVENT_LOCATION_STATE,
  GC.GRC.LAST_EVENT_LOCATION_COUNTRY,
  GC.GRC.LAST_EVENT_LOCATION_ZIP,
];

const carrierFleetFieldNames = [
  GC.GRC.CARRIER_ASSIGNMENT_NAME,
  GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
  GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_FULL_NAME,
  // TODO: remove or uncomment after testing
  // GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
  // GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
  // GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
  // GC.GRC.FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
  GC.GRC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
  GC.GRC.FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
];

const carrierFleetFieldNamesForClo = [
  GC.GRC.TELS_CARRIER_NAME,
  GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
  GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME,
  // TODO: remove or uncomment after testing
  // GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
  // GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
  // GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME,
  // GC.GRC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME,
  GC.GRC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID,
  GC.GRC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID,
];

const commonSortingFields = {
  [GC.FIELD_BRANCH]: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
  [GC.FIELD_LAST_DROP]: GC.GRC.LAST_EVENT_EARLY_DATE,
  [GC.FIELD_FIRST_PICKUP]: GC.GRC.FIRST_EVENT_EARLY_DATE,
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: GC.FIELD_PRIMARY_REFERENCE_VALUE,
};

const sortingFieldsMapTel = {
  ...commonSortingFields,
  [GC.FIELD_LOAD_CARRIER_FLEET]: [
    GC.GRC.CARRIER_ASSIGNMENT_NAME,
    GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
    GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
  ],
};

const sortingFieldsMapClo = {
  ...commonSortingFields,
  [GC.FIELD_LOAD_CARRIER_FLEET]: [
    GC.GRC.TELS_CARRIER_NAME,
    GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FIRST_NAME,
    GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_LAST_NAME,
  ],
};

export const getTitleOrderFields = (titleOrderFields: Object, isClo: boolean = false) => {
  const fieldsArray = R.values(titleOrderFields);
  const mapToUse = G.ifElse(isClo, sortingFieldsMapClo, sortingFieldsMapTel);
  let sortingFields = [];
  R.forEach(
    (field: Object) => {
      const { name } = field;
      const mapValue = R.propOr(name, name, mapToUse);

      if (G.isArray(mapValue)) {
        R.forEach(
          (value: string) => {
            sortingFields = R.append(R.assoc(GC.FIELD_NAME, value, field), sortingFields);
          },
          mapValue,
        );
      } else {
        sortingFields = R.append(R.assoc(GC.FIELD_NAME, mapValue, field), sortingFields);
      }
    },
    fieldsArray,
  );

  return G.mapIndexed(
    (entity: Object, i: number) => R.assoc('sequence', R.inc(i), entity),
    sortingFields,
  );
};

export const getTitleFilterParams = (titleFilterParams: Object, isClo: boolean = false) => {
  const paramsArray = R.values(titleFilterParams);
  let filterParams = [];
  R.forEach(
    (filter: Object) => {
      const { stringValue, propertyName } = filter;
      const isFirstPickup = R.equals(propertyName, GC.FIELD_FIRST_PICKUP);
      const isLastDrop = R.equals(propertyName, GC.FIELD_LAST_DROP);
      if (R.or(isFirstPickup, isLastDrop)) {
        const date = moment(stringValue);
        if (R.and(date.isValid(), date.isBetween('01/01/2000', '01/01/2050'))) {
          const filterData = G.createReportFilterFromTitleSearch({
            value: date.format(GC.DEFAULT_DATE_FORMAT),
            fieldProps: {
              type: 'date',
              value: G.ifElse(isFirstPickup, GC.GRC.FIRST_EVENT_EARLY_DATE, GC.GRC.LAST_EVENT_EARLY_DATE),
            },
          });
          filterParams = R.append(
            filterData.filter,
            filterParams,
          );
        } else {
          const groupId = G.generateGuid();
          const fieldsToMap = G.ifElse(isFirstPickup, firstEventStringFieldNames, lastEventStringFieldNames);
          R.forEach(
            (fieldName: string) => {
              const filterData = G.createReportFilterFromTitleSearch({
                groupId,
                value: stringValue,
                fieldProps: {
                  type: 'string',
                  value: fieldName,
                },
              });
              filterParams = R.append(
                filterData.filter,
                filterParams,
              );
            },
            fieldsToMap,
          );
        }
      } else if (R.equals(propertyName, GC.FIELD_BRANCH)) {
        const filterData = G.createReportFilterFromTitleSearch({
          value: stringValue,
          fieldProps: {
            type: 'string',
            value: GC.FIELD_BRANCH_DOT_BRANCH_NAME,
          },
        });
        filterParams = R.append(
          filterData.filter,
          filterParams,
        );
      } else if (R.equals(propertyName, GC.FIELD_LOAD_CARRIER_FLEET)) {
        const groupId = G.generateGuid();
        const fieldsToMap = G.ifElse(isClo, carrierFleetFieldNamesForClo, carrierFleetFieldNames);
        R.forEach(
          (fieldName: string) => {
            const filterData = G.createReportFilterFromTitleSearch({
              groupId,
              value: stringValue,
              fieldProps: {
                type: 'string',
                value: fieldName,
              },
            });
            filterParams = R.append(
              filterData.filter,
              filterParams,
            );
          },
          fieldsToMap,
        );
      } else {
        filterParams = R.append(filter, filterParams);
      }
    },
    paramsArray,
  );

  return filterParams;
};
// SAGA

// REDUCER
const initLoadData = {
  expanded: false,
};

export const getIndexedResults = (results: Array) => R.map(
  (res: Object) => R.assoc(
    'loads',
    R.indexBy(
      R.prop(GC.FIELD_GUID),
      R.map(R.mergeRight(initLoadData), res.loads),
    ),
    res,
  ),
  results,
);

export const setDataToItemListItem = (itemList: Array, guid: string, data: Object) => R.map(
  (group: Object) => {
    const matchLoad = R.path(['loads', guid], group);

    if (G.isNotNil(matchLoad)) {
      return R.assocPath(['loads', guid], R.mergeRight(matchLoad, data), group);
    }

    return group;
  },
  itemList,
);

export const setDataToListItemByPropName = (itemList: Array, guid: string, data: Object, propName: string) => R.map(
  (group: Object) => {
    const matchLoad = R.path(['loads', guid], group);
    const details = R.path(['details'], matchLoad);

    if (R.and(G.isNotNil(details), G.isNotNil(matchLoad))) {
      return R.compose(
        R.assocPath(['loads', guid, 'details', propName], data),
        R.assocPath(['loads', guid, propName], data),
      )(group);
    }

    if (G.isNotNil(matchLoad)) return R.assocPath(['loads', guid, propName], data, group);

    return group;
  },
  R.or(itemList, []),
);

export const setDataToItemListItemDetails = (itemList: Array, guid: string, data: Object) => R.map(
  (group: Object) => {
    const matchLoad = R.path(['loads', guid], group);

    if (G.isNotNil(matchLoad)) {
      return R.assocPath(
        ['loads', guid, 'details'],
        R.mergeRight(R.pathOr({}, ['details'], matchLoad), data),
        group,
      );
    }

    return group;
  },
  R.or(itemList, []),
);

export const getDataFromItemListItemDetails = (itemList: Array, guid: string, propName: string) => {
  let data;
  const matchGroup = R.find(
    (group: Object) => G.isNotNil(R.path(['loads', guid], group)),
    R.or(itemList, []),
  );

  if (G.isNotNil(matchGroup)) data = R.path(['loads', guid, 'details', propName], matchGroup);

  return data;
};

export const getDataFromItemListItem = (itemList: Array, guid: string, propName: string) => {
  let data;

  const matchGroup = R.find(
    (group: Object) => G.isNotNil(R.path(['loads', guid], group)),
    R.or(itemList, []),
  );

  if (G.isNotNil(matchGroup)) data = R.path(['loads', guid, propName], matchGroup);

  return data;
};

export const getDataFromLoadForTableInfo = (data: Object, itemList: Array) => {
  const pickArr = [
    GC.FIELD_STATUS,
    GC.FIELD_CUSTOM_STATUS,
    GC.FIELD_SELECTED_RATE_GUID,
    GC.FIELD_DISTANCE_TO_NEXT_STOP,
    GC.FIELD_PRIMARY_REFERENCE_VALUE,
    GC.FIELD_FINANCE_COST_ALLOCATIONS,
  ];

  const guid = G.getGuidFromObject(data);
  const currentEarnings = getDataFromItemListItem(itemList, guid, GC.FIELD_LOAD_EARNINGS);
  const lastEventOrigin = getDataFromItemListItem(itemList, guid, GC.SYSTEM_OBJECT_LAST_EVENT);
  const firstEventOrigin = getDataFromItemListItem(itemList, guid, GC.SYSTEM_OBJECT_FIRST_EVENT);

  const earningsTotalFromCostAllocations = R.compose(
    R.sum,
    R.map(R.prop(GC.FIELD_MARGIN)),
    R.pathOr([], [GC.FIELD_FINANCE_COST_ALLOCATIONS]),
  )(data);

  const earnings = G.ifElse(
    R.propEq(GC.LOAD_STATUS_UNSCHEDULED, GC.FIELD_STATUS, data),
    currentEarnings,
    {
      total: earningsTotalFromCostAllocations,
      currency: G.getCurrencyFromObject(currentEarnings),
    },
  );

  let loadData = R.pick(pickArr, data);

  const fleetAssignmentGuid = R.prop(GC.FIELD_FLEET_ASSIGNMENT_GUID, data);
  const carrierAssignmentGuid = R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, data);

  if (R.or(G.isNotNil(fleetAssignmentGuid), G.isNotNil(carrierAssignmentGuid))) {
    if (R.isNil(carrierAssignmentGuid)) {
      loadData = R.assoc(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, null, loadData);
    }

    if (R.isNil(fleetAssignmentGuid)) {
      loadData = R.assoc(GC.FIELD_SHOWN_FLEET_ASSIGNMENT, null, loadData);
    }
  }

  if (G.isNilOrEmpty(R.prop(GC.FIELD_LOAD_STOPS, data))) return loadData;

  const firstEvent = R.find(
    R.eqProps(GC.FIELD_GUID, firstEventOrigin),
    data.events,
  );
  const lastEvent = R.find(
    R.eqProps(GC.FIELD_GUID, lastEventOrigin),
    data.events,
  );

  let anyAppointmentRequired = false;
  let dropCount = 0;
  let pickupCount = 0;
  let anyHazardous = false;
  let temperatureHigh = null;
  let temperatureLow = null;
  let items = [];

  R.forEach(
    (event: Object) => {
      items = R.concat(items, R.pathOr([], [GC.FIELD_STOP_ITEM_IDS], event));

      if (R.prop(GC.FIELD_LOAD_APPOINTMENT_REQUIRED, event)) {
        anyAppointmentRequired = true;
      }

      if (G.isStopPickup(event)) {
        pickupCount = R.inc(pickupCount);
      } else {
        dropCount = R.inc(dropCount);
      }

      R.forEach(
        (item: Object) => {
          if (R.prop(GC.FIELD_ITEM_HAZARDOUS, item)) {
            anyHazardous = true;
          }

          const itemTempHigh = G.getPropFromObject('storedTemperatureHigh', item);

          if (G.isNotNil(itemTempHigh)) {
            if (R.or(R.isNil(temperatureHigh), R.lt(itemTempHigh, temperatureHigh))) {
              temperatureHigh = itemTempHigh;
            }
          }
          const itemTempLow = G.getPropFromObject('storedTemperatureLow', item);

          if (G.isNotNil(itemTempLow)) {
            if (R.or(R.isNil(temperatureLow), R.gt(itemTempLow, temperatureLow))) {
              temperatureLow = itemTempLow;
            }
          }
        },
        R.pathOr([], [GC.FIELD_STOP_ITEMS], event),
      );
    },
    R.pathOr([], [GC.FIELD_LOAD_STOPS], data),
  );

  const count = R.compose(
    R.length,
    R.uniq,
  )(items);

  return R.mergeRight(
    loadData,
    {
      earnings,
      lastEvent,
      firstEvent,
      eventsInfo: { dropCount, pickupCount, anyAppointmentRequired },
      itemsInfo: { count, anyHazardous, temperatureLow, temperatureHigh },
    },
  );
};

export const updateTelLoadBoards = (results: Array = [], data: Object) => R.map(
  (group: Object) => R.assoc(
    'loads',
    R.map(
      (load: Object) => {
        const loadGuid = G.getGuidFromObject(load);
        const matchShipment = R.any(
          ({ telGuid }: Object) => R.equals(loadGuid, telGuid),
          data.loadBoardShipments,
        );
        if (G.isNilOrEmpty(matchShipment)) {
          return load;
        }
        let newLoadBoardShipments = R.prop(GC.FIELD_LOAD_BOARD_SHIPMENTS, load);
        data.loadBoardShipments.forEach((incomeShipment: Object) => {
          if (R.equals(incomeShipment.telGuid, loadGuid)) {
            const match = newLoadBoardShipments.find((loadBoardShipment: Object) => R.eqProps(
              GC.FIELD_GUID,
              incomeShipment,
              loadBoardShipment,
            ));
            if (G.isNotNilAndNotEmpty(match)) {
              newLoadBoardShipments[newLoadBoardShipments.indexOf(match)] = incomeShipment;
            } else {
              newLoadBoardShipments = R.append(incomeShipment, newLoadBoardShipments);
            }
          }
        });
        return R.assoc(GC.FIELD_LOAD_BOARD_SHIPMENTS, newLoadBoardShipments, load);
      },
      group.loads,
    ),
    group,
  ),
  results,
);

export const setShipUnitsExpanded = (state: Object, shipUnitsExpanded: boolean = false) => {
  if (shipUnitsExpanded) {
    return P.$set('shipUnitsExpanded', shipUnitsExpanded, state);
  }

  let itemList = R.prop('itemList', state);
  const guids = getItemListGuids(itemList);
  R.forEach(
    (guid: string) => {
      itemList = setDataToItemListItem(itemList, guid, { expanded: false });
    },
    guids,
  );

  return P.$all(
    P.$set('itemList', itemList),
    P.$set('shipUnitsExpanded', shipUnitsExpanded),
    state,
  );
};

export const setDataToItemListItemDetailsReducer = (state: Object, { guid, data }: Object) => (
  P.$set(
    'itemList',
    setDataToItemListItemDetails(state.itemList, guid, data),
    state,
  )
);

export const setDataToListItemByPropNameReducer = (state: Object, { guid, data, propName }: Object) => (
  P.$set(
    'itemList',
    setDataToListItemByPropName(state.itemList, guid, data, propName),
    state,
  )
);
// REDUCER

import * as R from 'ramda';
import { put, call, fork, delay, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { getAllAvailableRefTypesByScopeRequest } from '../reference/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// sagas
import { crudSaga, visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature drivers-card
import * as A from './actions';
import {
  CARD_HEIGHT_90,
  CARD_HEIGHT_170,
  GROUP_TYPE_NONE,
  FILTER_TYPE_NONE,
  GROUP_TYPE_DISPATCHER,
  GROUP_TYPE_DISPATCHING_GROUP,
} from './constants';
import {
  makeSelectFilters,
  makeSelectGroupBy,
  makeSelectTelNotifications,
  makeSelectGlobalFilterValue,
  makeSelectLoadRefFilterValue,
  makeSelectWarningPeriodConfig,
} from './selectors';
import {
  getRequestFilters,
  getDefaultFilterRequestDates,
  getGuidsFromGroupedAssignedLoads,
  getGuidsFromNotGroupedAssignedLoads,
} from './helpers';
//////////////////////////////////////////////////

function* getUnassignedLoadsRequest({ payload }: Object) {
  try {
    // TODO: with pagination
    const showLoader = R.path(['data', 'showLoader'], payload);

    if (G.isTrue(showLoader)) yield put(openLoader());

    const options = {
      data: R.mergeRight(payload.data, {
        offset: 0,
        limit: 200,
        radiusUom: G.getMileOrKmUomByUomSystem(),
      }),
    };

    yield call(crudSaga, {
      options,
      method: 'post',
      endpoint: endpointsMap.unassignedLoads,
      parentSagaName: 'getUnassignedLoadsRequest',
      successAction: A.getUnassignedLoadsSuccess,
    });

    if (G.isTrue(showLoader)) yield put(closeLoader());
  } catch (error) {
    yield call(G.handleException, error, 'getUnassignedLoadsRequest exception');
  }
}

function* getDriverCardsInfoRequest({ payload }: Object) {
  try {
    yield call(crudSaga, {
      method: 'get',
      endpoint: endpointsMap.telDriverCardsInfo,
      parentSagaName: 'getDriverCardsInfoRequest',
      successAction: A.getDriverCardsInfoSuccess,
      options: { params: { enterpriseGuid: payload } },
    });
  } catch (error) {
    yield call(G.handleException, error, 'getDriverCardsInfoRequest exception');
  }
}

function* handleGetTelNotificationsByTelGuidsSaga({ payload }: Object) {
  try {
    const options = {
      data: { elements: payload },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.notificationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTelNotificationsSuccess(data.results));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTelNotificationsByTelGuidsSaga');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetTelNotificationsByTelGuidsSaga');
  }
}

function* handleHideTelNotificationsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const notifications = yield select(makeSelectTelNotifications());

    const data = {
      elements: R.compose(
        R.map(({ userNotificationGuid }: Object) => userNotificationGuid),
        R.values,
        R.path([payload]),
      )(notifications),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.hideWarnings, { data });

    if (G.isResponseSuccess(res.status)) {
      yield put(A.hideTelNotificationsSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleHideTelNotificationsSaga');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleHideTelNotificationsSaga');
  }
}

function* getGroupedAssignedLoadsSaga({ payload }: Object) {
  try {
    const { groupBy } = payload.data;

    if (R.equals(groupBy, GROUP_TYPE_DISPATCHING_GROUP)) {
      return yield put(A.setValueToStore({ value: {}, path: 'driverLoadsByDispatchGroup' }));
    }

    if (R.equals(groupBy, GROUP_TYPE_DISPATCHER)) {
      return yield put(A.setValueToStore({ value: {}, path: 'driverLoadsByDispatcher' }));
    }
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getGroupedAssignedLoadsSaga');
  }
}

function* getDriverLoadsByDispatchGroupRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const dispatchingGroupGuid = payload;

    const groupBy = yield select(makeSelectGroupBy());
    const filtersStore = yield select(makeSelectFilters());
    const referenceValue = yield select(makeSelectLoadRefFilterValue());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const warningPeriodConfig = yield select(makeSelectWarningPeriodConfig());

    const currentEnterprise = G.getAmousCurrentBranchGuidFromWindow();

    const { dateTo, dateFrom } = filtersStore;

    // TODO: with pagination
    const options = {
      data: {
        ...filtersStore,
        groupBy,
        offset: 0,
        limit: 200,
        referenceValue,
        showTeam: true,
        globalFilterValue,
        currentEnterprise,
        dispatchingGroupGuid,
        dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
        dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.assignedLoadsList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverLoadsByDispatchGroupSuccess({ data, dispatchingGroupGuid }));

      const telGuids = getGuidsFromGroupedAssignedLoads(data);

      if (G.isNotEmpty(telGuids)) {
        yield fork(handleGetTelNotificationsByTelGuidsSaga, { payload: telGuids });
      }
    } else {
      yield call(G.handleFailResponse, res, 'getDriverLoadsByDispatchGroupRequest');
    }

    G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getDriverLoadsByDispatchGroupRequest');
  }
}

function* getDriverLoadsByDispatcherRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const dispatcherGuid = payload;

    const groupBy = yield select(makeSelectGroupBy());
    const filtersStore = yield select(makeSelectFilters());
    const referenceValue = yield select(makeSelectLoadRefFilterValue());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const warningPeriodConfig = yield select(makeSelectWarningPeriodConfig());

    const currentEnterprise = G.getAmousCurrentBranchGuidFromWindow();

    const { dateTo, dateFrom } = filtersStore;

    // TODO: with pagination
    const options = {
      data: {
        ...filtersStore,
        groupBy,
        offset: 0,
        limit: 200,
        showTeam: true,
        dispatcherGuid,
        referenceValue,
        globalFilterValue,
        currentEnterprise,
        dateTo: G.createLocalDateTimeFromInstanceOrISOString(dateTo, G.getDateTimeFormat(true)),
        dateFrom: G.createLocalDateTimeFromInstanceOrISOString(dateFrom, G.getDateTimeFormat(true)),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.assignedLoadsList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverLoadsByDispatcherSuccess({ data, dispatcherGuid }));

      const telGuids = getGuidsFromGroupedAssignedLoads(data);

      if (G.isNotEmpty(telGuids)) {
        yield fork(handleGetTelNotificationsByTelGuidsSaga, { payload: telGuids });
      }
    } else {
      yield call(G.handleFailResponse, res, 'getDriverLoadsByDispatcherRequest');
    }

    G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getDriverLoadsByDispatcherRequest');
  }
}

function* getNotGroupedAssignedLoadsSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const referenceValue = yield select(makeSelectLoadRefFilterValue());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());
    const warningPeriodConfig = yield select(makeSelectWarningPeriodConfig());

    const globalFilterValueFromPayload = R.path(['data', 'globalFilterValue'], payload);

    // TODO: with pagination
    const options = {
      data: {
        ...payload.data,
        offset: 0,
        limit: 200,
        referenceValue,
        showTeam: true,
        globalFilterValue: G.ifElse(
          G.isNotNilAndNotEmpty(globalFilterValueFromPayload),
          globalFilterValueFromPayload,
          globalFilterValue,
        ),
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.assignedLoads, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getNotGroupedAssignedLoadsSuccess(data));

      const telGuids = getGuidsFromNotGroupedAssignedLoads(data);

      if (G.isNotEmpty(telGuids)) {
        yield fork(handleGetTelNotificationsByTelGuidsSaga, { payload: telGuids });
      }
    } else {
      yield call(G.handleFailResponse, res, 'getNotGroupedAssignedLoadsSaga');
    }

    G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' });

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getNotGroupedAssignedLoadsSaga');
  }
}

function* handleCreateDriverOnDriversCardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const fleetAssignment = R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, payload);

    const omittedPayload = R.omit([
      GC.FIELD_FLEET_ASSIGNMENT,
      ...GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR,
    ], payload);

    const options = {
      data: {
        ...omittedPayload,
        fleetAssignment,
      },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.telFleetRate, options);

    if (G.isResponseSuccess(res.status)) {
      yield put(closeModal());
      yield call(G.showToastrMessage, 'success', 'messages:success:create');

      const groupBy = yield select(makeSelectGroupBy());
      const filtersStore = yield select(makeSelectFilters());
      const referenceValue = yield select(makeSelectLoadRefFilterValue());
      const globalFilterValue = yield select(makeSelectGlobalFilterValue());

      const { action, options } = getRequestFilters(
        groupBy,
        filtersStore,
        referenceValue,
        globalFilterValue,
        A.getGroupedAssignedLoadsRequest,
        A.getNotGroupedAssignedLoadsRequest,
      );

      yield put(A.createDriverOnDriversCardSuccess(payload));
      yield put(action(options));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateDriverOnDriversCardSaga');
    }

    yield put(closeLoader());
    yield put(closeModal());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateDriverOnDriversCardSaga');
  }
}

function* refreshAssignedLoadsSaga() {
  try {
    yield put(openLoader());

    const groupBy = yield select(makeSelectGroupBy());
    const filtersStore = yield select(makeSelectFilters());
    const referenceValue = yield select(makeSelectLoadRefFilterValue());
    const globalFilterValue = yield select(makeSelectGlobalFilterValue());

    const { action, options } = getRequestFilters(
      groupBy,
      filtersStore,
      referenceValue,
      globalFilterValue,
      A.getGroupedAssignedLoadsRequest,
      A.getNotGroupedAssignedLoadsRequest,
    );

    yield put(action(options));
    yield put(closeLoader());
    yield put(closeModal());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'refreshAssignedLoadsSaga');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const CONFIGS_ARR = [
  GC.UI_DRIVER_CARDS_ZOOM,
  GC.UI_DRIVER_CARDS_CARD_VIEW,
  GC.UI_DRIVER_CARDS_CARD_HEIGHT,
  GC.UI_DRIVER_CARDS_WARNING_PERIOD,
  GC.UI_DRIVER_CARDS_DEFAULT_DAY_FILTER,
  GC.UI_DRIVER_CARDS_DEFAULT_GROUP_FILTER,
  GC.UI_DRIVER_CARDS_DEFAULT_DRIVER_FILTER,
  GC.UI_DRIVER_CARDS_DEFAULT_SEARCH_RADIUS,
  GC.UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS,
];

function* getBranchConfigsAndData() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const enterpriseGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        names: R.join(',', CONFIGS_ARR),
        [GC.FIELD_BRANCH_GUID]: enterpriseGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      const groupFilter = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_DEFAULT_GROUP_FILTER,
        mappedConfigs,
      );

      const driverFilter = R.or(
        G.getConfigValueFromStore(
          GC.UI_DRIVER_CARDS_DEFAULT_DRIVER_FILTER,
          mappedConfigs,
        ),
        FILTER_TYPE_NONE,
      );

      const zoom = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_ZOOM,
        mappedConfigs,
      );

      const warningPeriodConfig = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_WARNING_PERIOD,
        mappedConfigs,
      );

      const searchRadiusValue = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_DEFAULT_SEARCH_RADIUS,
        mappedConfigs,
      );

      const minEmptyHoursValue = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_DEFAULT_MIN_EMPTY_HOURS,
        mappedConfigs,
      );

      const dayFilter = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_DEFAULT_DAY_FILTER,
        mappedConfigs,
      );

      const cardView = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_CARD_VIEW,
        mappedConfigs,
      );

      const cardHeight = G.getConfigValueFromStore(
        GC.UI_DRIVER_CARDS_CARD_HEIGHT,
        mappedConfigs,
      );

      const dayFilterToUse = R.or(dayFilter, 7);
      const filters = getDefaultFilterRequestDates(dayFilterToUse);

      const { dateTo, dateFrom } = filters;

      const groupBy = R.or(groupFilter, GROUP_TYPE_NONE);

      yield put(A.setValueToStore({ value: cardView, path: 'cardView' }));
      yield put(A.setValueToStore({ value: searchRadiusValue, path: 'searchRadiusValue' }));
      yield put(A.setValueToStore({ value: minEmptyHoursValue, path: 'minEmptyHoursValue' }));

      yield put(A.setValueToStore({
        path: 'cardHeight',
        value: G.ifElse(R.equals(cardHeight, ENUMS.ENUM_SMALL), CARD_HEIGHT_90, CARD_HEIGHT_170),
      }));

      yield put(A.setWarningPeriodConfig(warningPeriodConfig));
      yield put(A.setZoomToStore(R.or(zoom, 1)));
      yield put(A.setGroupByToStore(groupBy));
      yield put(A.setDateRangeFilters(filters));
      yield put(A.setFilterToStore(driverFilter));
      yield put(A.setDayFilterToStore(dayFilterToUse));

      const options = {
        data: {
          dateTo,
          groupBy,
          dateFrom,
          warningPeriodConfig,
          filter: driverFilter,
          currentEnterprise: enterpriseGuid,
        },
      };

      yield put(A.getDriverCardsInfoRequest(enterpriseGuid));

      if (R.and(
        R.equals(groupBy, GROUP_TYPE_DISPATCHING_GROUP),
        R.equals(driverFilter, FILTER_TYPE_NONE),
      )) return;

      const action = G.ifElse(
        R.equals(groupBy, GROUP_TYPE_NONE),
        A.getNotGroupedAssignedLoadsRequest,
        A.getGroupedAssignedLoadsRequest,
      );

      yield put(action(options));
    } else {
      yield call(G.handleFailResponse, res, 'getBranchConfigs fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'getBranchConfigs exception');
  }
}

function* handleVisitDriversCardSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_DRIVERS_CARD_PAGE);
    yield call(getBranchConfigsAndData);
    yield put(getAllAvailableRefTypesByScopeRequest(GC.LOAD_TYPE_TEL));
    break;
  }
}

function* onZoomSwitched({ payload }: Object) {
  yield put(openLoader());
  yield delay(0);
  yield put(A.setZoomToStore(payload));
  yield delay(0);

  G.scrollIntoElementView({ elementId: 'driver-card-scroll-to-id' });

  yield put(closeLoader());
}

function* onGroupBySwitched({ payload }: Object) {
  yield put(openLoader());
  yield delay(0);
  yield put(A.setGroupByToStore(payload));
  yield put(closeLoader());
}

function* driversCardWatcherSaga() {
  yield takeLatest(A.onZoomSwitched, onZoomSwitched);
  yield takeLatest(A.onGroupBySwitched, onGroupBySwitched);
  yield takeLatest(GC.VISIT_DRIVERS_CARD_PAGE, handleVisitDriversCardSaga);
  yield takeLatest(A.getUnassignedLoadsRequest, getUnassignedLoadsRequest);
  yield takeLatest(A.getDriverCardsInfoRequest, getDriverCardsInfoRequest);
  yield takeLatest(A.refreshAssignedLoadsRequest, refreshAssignedLoadsSaga);
  yield takeLatest(A.hideTelNotificationsRequest, handleHideTelNotificationsSaga);
  yield takeLatest(A.getGroupedAssignedLoadsRequest, getGroupedAssignedLoadsSaga);
  yield takeLatest(A.getNotGroupedAssignedLoadsRequest, getNotGroupedAssignedLoadsSaga);
  yield takeLatest(A.getTelNotificationsRequest, handleGetTelNotificationsByTelGuidsSaga);
  yield takeLatest(A.getDriverLoadsByDispatcherRequest, getDriverLoadsByDispatcherRequest);
  yield takeLatest(A.createDriverOnDriversCardRequest, handleCreateDriverOnDriversCardSaga);
  yield takeLatest(A.getDriverLoadsByDispatchGroupRequest, getDriverLoadsByDispatchGroupRequest);
}

export default driversCardWatcherSaga;

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { ConfirmComponent } from '../../components/confirm';
// components
import { openModal, closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// hocs
import { withAsyncInitialDataOnDidMount } from '../../hocs';
// features
import { CreateContractForm } from './forms/create-contract-form';
// utilities
import endpointsMap from '../../utilities/endpoints';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

export const enhanceContractFormWithAsyncRequest = compose(
  connect(null, { openLoader, closeLoader, openModal, closeModal }),
  withProps(() => {
    const branchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const asyncOptions = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    return {
      asyncOptions,
      asyncEndpoint: endpointsMap.carrierListForContract,
    };
  }),
  withAsyncInitialDataOnDidMount,
  withProps(({ asyncInitialData }: Object) => {
    const data = R.pathOr([], ['data'], asyncInitialData);

    return {
      optionsForSelect: {
        carrierOptions: G.mapNameGuidObjectPropsToLabelValueObject(data),
        transportationMode: R.indexBy(R.prop(GC.FIELD_GUID), data),
      },
    };
  }),
  pure,
);

const EnhancedCreateContractForm = enhanceContractFormWithAsyncRequest(CreateContractForm);

export const withAddContractAsync = ({ pageType, contractType }: Object) => compose(
  withHandlers({
    handleClickAddContractAsync: (props: Object) => () => {
      const { resetListAndPaginationAndGetItemsRequest } = props;
      const callbackAction = G.ifElse(
        R.or(R.equals(pageType, 'carrierContracts'), R.equals(pageType, 'customerContracts')),
        resetListAndPaginationAndGetItemsRequest,
        G.ifElse(
          G.isContractTypeCarrier(contractType),
          props.handleSendCreateCarrierContractSuccess,
          props.handleSendCreateCustomerContractSuccess,
        ),
      );
      const initialValues = {
        [GC.FIELD_CURRENCY]: G.getAmousConfigByNameFromWindow(GC.GENERAL_BRANCH_DEFAULT_CURRENCY),
      };
      const modalContext = (
        <EnhancedCreateContractForm
          pageType={pageType}
          contractType={contractType}
          initialValues={initialValues}
          callbackAction={callbackAction}
          asyncEndpoint={endpointsMap.cloTemplateListAvailable} />
      );
      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:add:contract', 'Add Contract'),
        },
      };
      props.openModal(modal);
    },
  }),
  pure,
);


export const contractListEnhance = ({ contractType }: Object) => compose(
  withHandlers({
    handleClickAddContract: (props: Object) => () => {
      const options = {
        roleOptions: props.roleOptions,
        transportationMode: R.concat(
          R.of(Array, { label: '', value: '' }),
          R.filter(
            ({ value }: Object) => (props.selectedTrMode.includes(value)),
            props.carrierConfigs.transportationMode),
        ),
      };
      const callbackAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        props.handleSendCreateCarrierContractSuccess,
        props.handleSendCreateCustomerContractSuccess,
      );
      const initialValues = {
        [GC.FIELD_CURRENCY]: R.path(['carrierConfigs', 'defaultBranchCurrency'], props),
      };
      const modalContext = (
        <CreateContractForm
          optionsForSelect={options}
          contractType={contractType}
          closeModal={props.closeModal}
          initialValues={initialValues}
          openLoader={props.openLoader}
          closeLoader={props.closeLoader}
          callbackAction={callbackAction}
          carrierGuid={props.initialValues.guid} />
      );
      const modal = {
        p: '0px',
        component: modalContext,
        options: {
          width: 400,
          height: 'auto',
          title: G.getWindowLocale('titles:add:contract', 'Add Contract'),
        },
      };
      props.openModal(modal);
    },
    handleDeleteContract: (props: Object) => (entity: Object) => {
      const confirmationContent = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('messages:carrier:delete-contract', 'Are you sure you want delete contract ?')} />
      );
      const deleteAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        props.deleteCarrierContractRequest,
        props.deleteCustomerContractRequest,
      );
      const modal = {
        component: confirmationContent,
        options: {
          width: 600,
          controlButtons: [
            {
              type: 'button',
              name: G.getWindowLocale('actions:delete', 'Delete'),
              action: () => {
                deleteAction(entity.guid);
                props.closeModal();
              },
            },
          ],
        },
      };
      props.openModal(modal);
    },
    handleEditContract: (props: Object) => (contract: Object) => {
      const tabName = G.ifElse(
        G.isContractTypeCarrier(contractType),
        'carrierContract',
        'customerContract',
      );
      const contractGuid = G.getGuidFromObject(contract);
      const tabAction = G.ifElse(
        G.isContractTypeCarrier(contractType),
        () => {
          props.resetCarrierContractListAndPagination();
          props.getCarrierContractRequest(contractGuid);
          props.getCarrierContractRatesRequest({ contractGuid });
          props.getCarrierContractAssessorialsRequest(contractGuid);
          props.getCarrierContractAwardedLinesRequest(contractGuid);
          props.getContractBillToByContractTypeRequest({
            [GC.FIELD_CARRIER_CONTRACT_GUID]: contractGuid,
          });
          if (G.isNilOrEmpty(props.carrierIntegrationOptions)) {
            props.getAvailableCarrierIntegrationsRequest(R.path(['formValues', GC.BRANCH_GUID], props));
          }
        },
        () => {
          props.getCustomerContractRequest(contractGuid);
          props.getCustomerContractAwardedLinesRequest(contractGuid);
          props.getCustomerContractAssessorialsRequest(contractGuid);
          props.getContractBillToByContractTypeRequest({
            [GC.FIELD_CUSTOMER_CONTRACT_GUID]: contractGuid,
          });
        },
      );
      props.setActiveTab({ tabName, tabAction });
    },
  }),
  pure,
);

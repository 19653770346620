import * as R from 'ramda';
import { createSelector } from 'reselect';
// features
// feature new-do
import { makeLocation, getDataFromStops } from '../new-do/helpers';
import { locationFieldsToPick } from '../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const selectBranchStore = ({ branch }: object) => branch;
const selectNewDOStore = ({ liteNewDO }: object) => liteNewDO;
const selectNewDOBranchConfigs = ({ liteNewDO }: Object) => liteNewDO.branchConfigs;

const makeSelectFullDOStore = () => createSelector(
  selectNewDOStore,
  (store: Object) => store,
);

const makeSelectPageType = () => createSelector(
  selectNewDOStore,
  ({ pageType }: Object) => pageType,
);

const makeSelectStops = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => stops,
);

const makeSelectLoadType = () => createSelector(
  selectNewDOStore,
  ({ loadType }: Object) => loadType,
);

const makeSelectStopsData = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => getDataFromStops(stops),
);

const makeSelectRate = () => createSelector(
  selectNewDOStore,
  ({ rate }: Object) => rate,
);

const makeSelectNumberOfLoads = () => createSelector(
  selectNewDOStore,
  ({ numberOfLoads }: Object) => numberOfLoads,
);

const makeSelectBranchList = () => createSelector(
  selectNewDOStore,
  ({ branchList }: Object) => branchList,
);

const makeSelectBranchInfo = () => createSelector(
  selectNewDOStore,
  ({ branchInfo }: Object) => branchInfo,
);

const makeSelectBranchGuid = () => createSelector(
  selectNewDOStore,
  ({ branchInfo }: Object) => R.path([GC.FIELD_VALUE], branchInfo),
);

const makeSelectDivisionGuid = () => createSelector(
  selectNewDOStore,
  ({ divisionGuid }: Object) => divisionGuid,
);

const makeSelectBranchConfigs = () => createSelector(
  selectNewDOStore,
  ({ branchConfigs }: Object) => branchConfigs,
);

const makeSelectBranchRefTypes = () => createSelector(
  selectNewDOStore,
  ({ branchRefTypes }: Object) => branchRefTypes,
);

const makeSelectPrimaryReference = () => createSelector(
  selectNewDOStore,
  ({ primaryReference }: Object) => primaryReference,
);

const makeSelectCustomerLoadNumber = () => createSelector(
  selectNewDOStore,
  ({ customerLoadNumber }: Object) => customerLoadNumber,
);

const makeSelectLeftActiveTad = () => createSelector(
  selectNewDOStore,
  ({ leftActiveTad }: Object) => leftActiveTad,
);

const makeSelectRightActiveTad = () => createSelector(
  selectNewDOStore,
  ({ rightActiveTad }: Object) => rightActiveTad,
);

const makeSelectReferenceFormData = () => createSelector(
  selectNewDOStore,
  ({ referenceFormData }: Object) => referenceFormData,
);

const makeSelectBillTo = () => createSelector(
  selectNewDOStore,
  ({ billTo }: Object) => billTo,
);

const makeSelectValidationErrors = () => createSelector(
  selectNewDOStore,
  ({ validationErrors }: Object) => validationErrors,
);

const makeSelectValidationWarnings = () => createSelector(
  selectNewDOStore,
  ({ validationWarnings }: Object) => validationWarnings,
);

const makeSelectRatePreviewFiles = () => createSelector(
  selectNewDOStore,
  ({ ratePreviewFiles }: Object) => ratePreviewFiles,
);

const makeSelectTemplateData = () => createSelector(
  selectNewDOStore,
  ({ templateData }: Object) => templateData,
);

const makeSelectTelCreationMode = () => createSelector(
  selectNewDOStore,
  ({ telCreationMode }: Object) => telCreationMode,
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectNewDOBranchConfigs,
  (configs: Object) => G.getRouteTelConfigsForCreateClo(configs),
);

export const createPrimaryReferenceDisplayValue = (sequence: Object, configPrefix: string, divisionPrefix: string) => {
  const arrayOfValues = [divisionPrefix, configPrefix, sequence.prefix, sequence.approxValue, sequence.suffix];

  return G.createStringFromArray(arrayOfValues, '');
};

const makeSelectPrimaryRefSequenceValue = () => createSelector(
  selectNewDOStore,
  selectBranchStore,
  (doStore: Object, branchStore: Object) => {
    const { branchConfigs, divisionGuid, primaryRefSequence } = doStore;
    const { branchesConfigs } = branchStore;
    const autogenerated = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_AUTOGENERATED,
      branchConfigs,
    );

    if (R.and(G.isTrue(autogenerated), G.isNotNil(primaryRefSequence))) {
      const configPrefix = G.getConfigValueFromStore(
        GC.CLO_PRIMARY_REFERENCE_PREFIX,
        branchConfigs,
      );
      const configsForDivisionPrefix = G.ifElse(
        G.isNotNilAndNotEmpty(divisionGuid),
        R.pathOr({}, [divisionGuid], branchesConfigs),
        branchConfigs,
      );
      const divisionPrefix = G.getConfigValueFromStore(
        GC.CLO_PRIMARY_REFERENCE_DIVISION_PREFIX,
        configsForDivisionPrefix,
      );

      return createPrimaryReferenceDisplayValue(primaryRefSequence, configPrefix, divisionPrefix);
    }

    return '';
  },
);

const makeSelectPrimaryRefTypeGuid = () => createSelector(
  selectNewDOBranchConfigs,
  (configs: Object) => G.getConfigValueFromStore(
    GC.CLO_PRIMARY_REFERENCE_TYPE,
    configs,
  ),
);

const makeSelectPrimaryRefTypeName = () => createSelector(
  selectNewDOStore,
  (doStore: Object) => {
    const { branchConfigs, branchRefTypes } = doStore;
    const primaryRefTypeGuid = G.getConfigValueFromStore(
      GC.CLO_PRIMARY_REFERENCE_TYPE,
      branchConfigs,
    );

    return R.compose(
      R.path([GC.FIELD_NAME]),
      R.find(R.propEq(primaryRefTypeGuid, GC.FIELD_GUID)),
      R.pathOr([], [GC.LOAD_TYPE_CLO]),
    )(branchRefTypes);
  },
);

const makeSelectCustomerRefTypeName = () => createSelector(
  selectNewDOStore,
  (doStore: Object) => {
    const { branchConfigs, branchRefTypes } = doStore;
    const primaryRefTypeGuid = G.getConfigValueFromStore(
      GC.CLO_GENERAL_CUSTOMER_REFERENCE_TYPE,
      branchConfigs,
    );

    return R.compose(
      R.path([GC.FIELD_NAME]),
      R.find(R.propEq(primaryRefTypeGuid, GC.FIELD_GUID)),
      R.pathOr([], [GC.LOAD_TYPE_CLO]),
    )(branchRefTypes);
  },
);

const getTotalWeight = (stopsData: Object) => {
  const { pickedItems, pickedUpContainers } = stopsData;

  if (G.isNotNilAndNotEmpty(pickedItems)) {
    return G.renameKeys({
      [GC.FIELD_ITEM_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
      [GC.FIELD_ITEM_WEIGHT_TYPE]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
    }, G.calcItemsTotalWeightWithoutQty(pickedItems));
  }

  if (G.isNotNilAndNotEmpty(pickedUpContainers)) {
    return G.renameKeys({
      [GC.FIELD_WEIGHT_UOM]: GC.FIELD_TOTAL_TRIP_WEIGHT_UOM,
      [GC.FIELD_FULL_CONTAINER_WEIGHT]: GC.FIELD_TOTAL_TRIP_WEIGHT,
    }, G.calcContainersTotalWeightWithoutQty(pickedUpContainers));
  }

  return {
    [GC.FIELD_TOTAL_TRIP_WEIGHT]: '',
    [GC.FIELD_TOTAL_TRIP_WEIGHT_UOM]: '',
  };
};

const makeSelectLoadTotals = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => {
    const stopsData = getDataFromStops(stops);
    const { sortedStops, pickedItems } = stopsData;
    const totalWeight = getTotalWeight(stopsData);
    const totalQuantity = G.calculateTotalQuantity(pickedItems);
    const totalQuantities = {
      [R.prop(GC.FIELD_ITEM_PACKAGE_TYPE, totalQuantity)]: R.prop(GC.FIELD_ITEM_QUANTITY, totalQuantity),
    };
    const totalVolume = G.calculateTotalVolumeWithoutQty(pickedItems);
    const totalDistance = G.getLoadTotalDistance({ [GC.FIELD_LOAD_STOPS]: sortedStops }, GC.FIELD_DISTANCE_CLO);

    return {
      totalWeight,
      totalVolume,
      totalDistance,
      [GC.FIELD_TOTAL_QUANTITIES]: totalQuantities,
    };
  },
);

const makeSelectMapLocations = () => createSelector(
  selectNewDOStore,
  ({ stops }: Object) => {
    const { sortedStops } = getDataFromStops(stops);

    const mappedStops = R.map((stop: Object) => {
      const { id, order, formData, eventType } = stop;

      const location = makeLocation(R.pick(locationFieldsToPick, formData));
      const contact = R.pick(GC.GROUPED_FIELDS.EVENT_CONTACT_ARR, location);
      const hasNotLatLng = R.or(
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LATITUDE], location)),
        G.isNilOrEmpty(R.pathOr(null, [GC.FIELD_LONGITUDE], location)),
      );

      if (hasNotLatLng) return null;

      return {
        stop,
        contact,
        location,
        guid: id,
        title: `${G.toTitleCase(eventType)} ${order}`,
        latLng: { lat: R.prop(GC.FIELD_LATITUDE, location), lng: R.prop(GC.FIELD_LONGITUDE, location) },
      };
    }, sortedStops);

    return R.compose(R.values(), R.filter((stop: Object) => G.isNotNil(stop)))(mappedStops);
  },
);

const makeSelectDivision = () => createSelector(
  selectNewDOStore,
  selectBranchStore,
  (doStore: Object, branchStore: Object) => {
    const { divisionGuid } = doStore;
    const { treeBranches } = branchStore;

    if (R.or(G.isNilOrEmpty(divisionGuid), G.isNilOrEmpty(treeBranches))) return null;

    return G.getBranchFromTreeByGuid(divisionGuid, treeBranches);
  },
);

const makeSelectSourceType = () => createSelector(
  selectNewDOStore,
  R.pathOr(GC.CREATE_DO_SOURCE_TYPE_MANUAL, [GC.FIELD_SOURCE_TYPE]),
);

const makeSelectSaveAndDuplicateQty = () => createSelector(
  selectNewDOStore,
  ({ saveAndDuplicateQty }: Object) => saveAndDuplicateQty,
);

const makeSelectHotOrder = () => createSelector(
  selectNewDOStore,
  ({ hotOrder }: Object) => hotOrder,
);

const makeSelectDriverRate = () => createSelector(
  selectNewDOStore,
  ({ telDriverRate }: Object) => telDriverRate,
);

const makeSelectRouteTemplateGuid = () => createSelector(
  selectNewDOStore,
  ({ routeTemplateGuid }: Object) => routeTemplateGuid,
);

const makeSelectStopPointsStrings = () => createSelector(
  selectNewDOStore,
  ({ stopPointsStrings }: Object) => stopPointsStrings,
);

export {
  makeSelectRate,
  makeSelectStops,
  selectNewDOStore,
  makeSelectBillTo,
  makeSelectHotOrder,
  makeSelectDivision,
  makeSelectLoadType,
  makeSelectPageType,
  makeSelectStopsData,
  makeSelectSourceType,
  makeSelectLoadTotals,
  makeSelectBranchList,
  makeSelectBranchInfo,
  makeSelectBranchGuid,
  makeSelectDriverRate,
  makeSelectFullDOStore,
  makeSelectDivisionGuid,
  makeSelectTemplateData,
  makeSelectMapLocations,
  makeSelectNumberOfLoads,
  makeSelectLeftActiveTad,
  makeSelectBranchConfigs,
  makeSelectRightActiveTad,
  makeSelectBranchRefTypes,
  makeSelectTelCreationMode,
  makeSelectRouteTelConfigs,
  makeSelectRatePreviewFiles,
  makeSelectValidationErrors,
  makeSelectPrimaryReference,
  makeSelectStopPointsStrings,
  makeSelectRouteTemplateGuid,
  makeSelectReferenceFormData,
  makeSelectCustomerLoadNumber,
  makeSelectPrimaryRefTypeGuid,
  makeSelectPrimaryRefTypeName,
  makeSelectValidationWarnings,
  makeSelectCustomerRefTypeName,
  makeSelectSaveAndDuplicateQty,
  makeSelectPrimaryRefSequenceValue,
};

import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../components/modal/actions';
import { closeLoader, openLoader } from '../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { socketFleetDocumentGeneratedRequest } from '../sockets/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// report-common
import { getReportSagas } from '../../report-common';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature fuel cards
import * as A from './actions';
import {
  makeSelectTruckGuid,
  makeSelectDriverGuid,
  makeSelectPagination,
  makeSelectUsedReport,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetFuelCardListRequest({ payload }: Object) {
  try {
    yield put(A.setListLoading(true));

    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader());
    }

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const truckGuid = yield select(makeSelectTruckGuid());
    const driverGuid = yield select(makeSelectDriverGuid());
    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());

    const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    let systemFields;

    if (R.isNotNil(guids)) {
      systemFields = [
        { sequence: 0, collection: true, name: GC.FIELD_FUEL_CARDS_LINES },
      ];
    }

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      systemFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    let options = {
      data: G.setSearchCriteria({ reqBody, filterParams: newFilterParams }),
    };

    if (G.isNotNilAndNotEmpty(truckGuid)) {
      const params = { truckGuid };
      options = R.assoc('params', params, options);
    }

    if (G.isNotNilAndNotEmpty(driverGuid)) {
      const params = { driverGuid };
      options = R.assoc('params', params, options);
    }

    const res = yield call(sendRequest, 'post', endpointsMap.fuelCardList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {

      yield put(A.getItemListSuccess({ data, guids }));

      const payrollGuidList = R.compose(
        R.uniq,
        R.map(({ payrollGuid }: Object) => payrollGuid),
        R.filter(({ payrollGuid }: Object) => G.isNotNilAndNotEmpty(payrollGuid)),
      )(R.or(data.results, []));

      if (G.isNotNilAndNotEmpty(payrollGuidList)) {
        yield put(A.getDriverPayrollNumberListByGuidsRequest(payrollGuidList));
      }

      const vendorPayrollGuidList = R.compose(
        R.uniq,
        R.map(({ vendorPayrollGuid }: Object) => vendorPayrollGuid),
        R.filter(({ vendorPayrollGuid }: Object) => G.isNotNilAndNotEmpty(vendorPayrollGuid)),
      )(R.or(data.results, []));

      if (G.isNotNilAndNotEmpty(vendorPayrollGuidList)) {
        yield put(A.getVendorPayrollNumberListByGuidsRequest(vendorPayrollGuidList));
      }

      yield put(A.setListLoading(false));
    } else {
      yield put(A.setListLoading(false));

      yield call(G.handleFailResponse, res, 'handleGetFuelCardListRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield put(A.setListLoading(false));

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleGetFuelCardListRequest exception'));
  }
}

function* handleUpdateDriverFuelCardRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const { guid, driverGuid } = payload;

    const endpoint = endpointsMap.updateDriverFuelCard(guid);

    const params = { driverGuid };

    const res = yield call(sendRequest, 'put', endpoint, { params });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateDriverFuelCardRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleUpdateDriverFuelCardRequest exception'));
  }
}

function* handleUpdateTruckFuelCardRequest({ payload }: Object) {
  try {
    const { guid, truckGuid } = payload;

    yield put(openLoader());

    let options = {};

    const endpoint = endpointsMap.updateTruckFuelCard(guid);

    if (G.isNotNilAndNotEmpty(truckGuid)) options = { params: { truckGuid } };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTruckFuelCardRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleUpdateTruckFuelCardRequest exception'));
  }
}

function* handleGetDriverListRequest() {
  try {
    yield put(openLoader());

    const options = {
      params: {
        showUnemployed: true,
        [GC.FIELD_BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow(),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listFleetDriversFullNameInTree, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedDriverListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetDriverListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleGetDriverListRequest exception'));
  }
}

function* handleGetTruckListRequest() {
  try {
    yield put(openLoader());

    const options = {
      params: {
        showIsNotInService: true,
        [GC.FIELD_BRANCH_GUID]: G.getAmousCurrentBranchGuidFromWindow(),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.listFleetTrucks, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTruckListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTruckListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleGetTruckListRequest exception'));
  }
}

function* getDriverPayrollNumberListByGuidsRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.getDriverPayrollListByGuids, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) return;
      yield put(A.receivedDriverPayrollNumberListByGuidsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getDriverPayrollNumberListByGuidsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDriverPayrollNumberListByGuidsRequest exception');
  }
}

function* getVendorPayrollNumberListByGuidsRequest({ payload }: Object) {
  try {
    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.vendorPayrollListByGuids, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNilOrEmpty(data)) return;

      yield put(A.receivedVendorPayrollNumberListByGuidsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getVendorPayrollNumberListByGuidsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getVendorPayrollNumberListByGuidsRequest exception');
  }
}

function* handleCreateFuelCardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const { data, resetForm } = payload;

    const requestData = R.assoc(GC.BRANCH_GUID, G.getAmousCurrentBranchGuidFromWindow(), data);

    const options = { data: requestData };

    const res = yield call(sendRequest, 'post', endpointsMap.fuelCard, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      // yield put(A.createFuelCardSuccess(res.data));
      yield put(A.getItemListRequest({ guids: R.of(Array, R.path(['data', GC.FIELD_GUID], res)) }));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');

      if (G.isFunction(resetForm)) {
        resetForm();
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateFuelCardSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleCreateFuelCardSaga exception'));
  }
}

function* handleUpdateFuelCardSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'put', endpointsMap.fuelCard, { data: payload });

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getItemListRequest({ guids: R.of(Array, G.getGuidFromObject(data)) }));

      yield call(G.showToastrMessage, 'success', 'toastr:success:201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateFuelCardSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleUpdateFuelCardSaga exception'));
  }
}

function* handleDeleteFuelCardsSaga({ payload }: Object) {
  try {
    const res = yield call(sendRequest, 'delete', endpointsMap.fuelCardMassDelete, { data: payload });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteFuelCardsSaga fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException(error, 'handleDeleteFuelCardsSaga exception'));
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const truckGuid = yield select(makeSelectTruckGuid());
    const driverGuid = yield select(makeSelectDriverGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    let searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    if (R.isNotNil(driverGuid)) {
      searchCriteria = R.append(
        {
          dataType: 'string',
          operation: 'equal',
          stringValue: driverGuid,
          propertyName: GC.GRC.DRIVER_GUID,
        },
        searchCriteria,
      );
    }

    if (R.isNotNil(truckGuid)) {
      searchCriteria = R.append(
        {
          dataType: 'string',
          operation: 'equal',
          stringValue: truckGuid,
          propertyName: GC.GRC.FLEET_TRUCK_GUID,
        },
        searchCriteria,
      );
    }

    const fields = R.reject(({ name }: Object) => R.includes(
      name,
      [
        GC.FIELD_FUEL_CARDS_PAYROLL_GUID,
        GC.FIELD_FUEL_CARDS_VENDOR_PAYROLL_GUID,
      ],
    ), R.propOr([], 'fields', payload));

    const reqBody = {
      fields,
      searchCriteria,
      orderFields: payload.orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.fuelCardExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.FUEL_CARD_REPORT,
  A,
  handleGetFuelCardListRequest,
  { createUpdateReportSuccessCallback },
);

function* visitFuelCardsOnFleetPage({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader());
    yield put(A.setInitialState());

    const { truckGuid, driverGuid } = payload;

    yield put(A.setReportPending());
    yield put(A.setTruckGuid(truckGuid));
    yield put(A.setDriverGuid(driverGuid));

    if (G.isNilOrEmpty(truckGuid)) yield call(handleGetTruckListRequest);
    if (G.isNilOrEmpty(driverGuid)) yield call(handleGetDriverListRequest);

    yield call(handleAvailableReportsRequest, { payload: { [GC.REPORT_TYPE]: GC.FUEL_CARD_REPORT } });
    yield call(handleGetFuelCardListRequest, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

export function* handleVisitFuelCardsSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(A.setInitialState());

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_FUEL_CARDS_PAGE);

    yield put(openLoader({ showDimmer: true }));
    yield put(A.setReportPending());
    yield put(socketFleetDocumentGeneratedRequest(GC.FUEL_CARD_REPORT));

    yield call(handleGetTruckListRequest);
    yield call(handleGetDriverListRequest);
    yield call(handleAvailableReportsRequest, { payload });
    yield call(handleGetFuelCardListRequest, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

export function* fuelCardsWatcherSaga() {
  yield takeLatest(A.createFuelCardRequest, handleCreateFuelCardSaga);
  yield takeLatest(A.updateFuelCardRequest, handleUpdateFuelCardSaga);
  yield takeLatest(GC.VISIT_FUEL_CARDS_PAGE, handleVisitFuelCardsSaga);
  yield takeLatest(A.getItemListRequest, handleGetFuelCardListRequest);
  yield takeLatest(A.deleteFuelCardsRequest, handleDeleteFuelCardsSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.visitFuelCardsOnFleetPage, visitFuelCardsOnFleetPage);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.updateTruckFuelCardRequest, handleUpdateTruckFuelCardRequest);
  yield takeLatest(A.updateDriverFuelCardRequest, handleUpdateDriverFuelCardRequest);
  yield takeLatest(A.getDriverPayrollNumberListByGuidsRequest, getDriverPayrollNumberListByGuidsRequest);
  yield takeLatest(A.getVendorPayrollNumberListByGuidsRequest, getVendorPayrollNumberListByGuidsRequest);
}

export default fuelCardsWatcherSaga;

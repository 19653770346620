import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import {
  Box,
  Flex,
  Text,
  StyledLink,
  IconWrapper,
  } from '../../../ui';
// feature load-board
import * as H from '../helpers';
import { CommentUI } from './ui';
import { Email } from './table-cells';
//////////////////////////////////////////////////

const greyColor = G.getTheme('colors.titleGrey');
const blueColor = G.getTheme('colors.dark.blue');
const textColor = G.getTheme('colors.dark.mainDark');

const LocationField = ({
  date,
  title,
  location,
}: Object) => {
  const locationDate = date && G.convertDateTimeToConfigFormat(date);

  return (
    <Flex flexDirection='column' alignItems='flex-start' gap='6px'>
      <Box color={greyColor}>{`${title}:`}</Box>
      <Flex gap={6}>
        <IconWrapper>
          {I.locationMarker(textColor, 12, 12)}
        </IconWrapper>
        <Text>{H.getLocationDisplayValue(location)}</Text>
      </Flex>
      {G.isNotNilAndNotEmpty(locationDate) && <Box ml={20}>{locationDate}</Box>}
    </Flex>
  );
};

const Comments = ({ comments }: Object) => (
  <Flex
    mt='8px'
    gap='4px'
    width='100%'
    fontSize={12}
    flexDirection='column'
    alignItems='flex-start'
  >
    <Box color={greyColor} fontWeight='bold'>{G.getWindowLocale('titles:comments', 'Comments')}:</Box>
    <Flex
      gap='3px'
      maxHeight={70}
      overflowY='auto'
      overflowX='hidden'
      flexDirection='column'
      alignItems='flex-start'
    >
      {comments.map((option: string, i: number) => (
        <CommentUI
          key={i}
          pl={10}
          width={480}
          title={option}
          maxWidth='100%'
        >
          {`${R.inc(i)}. ${option}`}
        </CommentUI>
      ))}
    </Flex>
  </Flex>
);

const TripInfo = ({
  data,
  searchFilters,
}: Object) => {
  const filterGuid = R.prop('searchFilterGuid', data);
  const shipment = R.prop(GC.FIELD_LOAD_BOARD_SHIPMENT, data);
  const searchFilterOrigin = R.path([filterGuid, GC.FIELD_ORIGIN], searchFilters);

  const {
    origin,
    contact,
    comments,
    pickupDate,
    destination,
    creditScore,
    referenceId,
    deliveryDate,
    startLocation,
    brokerMcNumbers,
  } = shipment;

  const email = R.prop(GC.FIELD_LOAD_BOARD_EMAIL, contact);
  const phone = R.prop(GC.FIELD_LOAD_BOARD_PHONE, contact);
  const phoneExt = R.prop(GC.FIELD_LOAD_BOARD_PHONE_EXT, contact);
  const contactName = R.propOr('-', GC.FIELD_CONTACT_NAME, contact);
  const equipmentLocation = R.or(startLocation, searchFilterOrigin);
  const score = R.prop(GC.FIELD_LOAD_BOARD_CREDIT_SCORE_SCORE, creditScore);
  const phoneExtToUse = G.ifElse(G.isNotNilAndNotEmpty(phoneExt), `,,${phoneExt}`, '');

  return (
    <React.Fragment>
      <Flex fontSize={12} color={textColor} alignItems='flex-start' gap='20px'>
        <Box width={250}>
          <Box fontSize={14} fontWeight={600} pb='16px'>
            {G.getWindowLocale('titles:mc-number', 'MC Number')}: {R.pathOr('-', [0], brokerMcNumbers)}
          </Box>
          <Flex flexDirection='column' alignItems='flex-start' gap={8}>
            <Box>
              {G.getWindowLocale('titles:load-number', 'Load Number')}: {R.or(referenceId, '-')}
            </Box>
            {
              G.isNotNilAndNotEmpty(score) &&
              <Box>
                {G.getWindowLocale('titles:credit-score', 'CS')}: {score}
              </Box>
            }
            <TextComponent
              color={textColor}
              title={contactName}
              withEllipsis={true}
              display='inline-block'
              maxWidth='calc(100% - 14px)'
            >
              {G.getWindowLocale('titles:contact')}: {contactName}
            </TextComponent>
            {
              G.isNotNilAndNotEmpty(email) &&
              <Box maxWidth='calc(100% - 14px)'>
                <Email data={shipment} width='100%' />
              </Box>
            }
            {
              G.isNotNilAndNotEmpty(phone) &&
              <StyledLink
                mr={20}
                fontSize={11}
                display='flex'
                color={blueColor}
                wordBreak='break-all'
                href={G.getCallUrl(null, `${phone}${phoneExtToUse}`)}
              >
                <Box mr='4px'>{I.phone(blueColor, 10, 10)}</Box>
                {phone} {G.isNotNilAndNotEmpty(phoneExt) && `(${phoneExt})`}
              </StyledLink>
            }
          </Flex>
        </Box>
        <Flex width={250} flexDirection='column' alignItems='flex-start' gap={12}>
          { G.isNotNilAndNotEmpty(equipmentLocation) && (
            <LocationField title='Start Location' location={equipmentLocation} />
          )}
          <LocationField title='Origin' location={origin} date={pickupDate} />
          <LocationField title='Destination' location={destination} date={deliveryDate} />
        </Flex>
      </Flex>
      {
        G.isNotNilAndNotEmpty(comments) &&
        <Comments comments={comments} />
      }
    </React.Fragment>
  );
};

export default TripInfo;

import React from 'react';
import * as R from 'ramda';
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  CartesianGrid,
} from 'recharts';
// feature dashboards
import { CHART_COLORS } from '../constants';
import {
  xAxisCommonProps,
  yAxisCommonProps,
  legendCommonProps,
  cartesianGridCommonProps,
  largeChartWrapperCommonProps,
} from '../settings';
//////////////////////////////////////////////////

const { BAR_COLORS } = CHART_COLORS;

const ticks = [0, 5, 10, 15, 20, 25];

const domain = [0, 25];

const BarChartComponent = (props: Object) => {
  const { data, ticksY, domainY, tickFormatter, reverseColors } = props;

  const COLORS = reverseColors ? R.reverse(BAR_COLORS) : BAR_COLORS;

  const barSet = new Set();

  const dataToUse = R.map(({ name, groups }: Object) => {
    const ret = { name };

    groups.forEach((g: Object) => {
      ret[g.name] = g.value;

      barSet.add(g.name);
    });

    return ret;
  }, data);

  return (
    <BarChart
      data={dataToUse}
      barSize={40}
      {...largeChartWrapperCommonProps}
    >
      <CartesianGrid {...cartesianGridCommonProps} />
      <XAxis
        dataKey='name'
        {...xAxisCommonProps}
      />
      <YAxis
        ticks={ticksY || ticks}
        allowDataOverflow={true}
        domain={domainY || domain}
        tickFormatter={tickFormatter}
        {...yAxisCommonProps}
      />
      <Tooltip />
      <Legend {...legendCommonProps} />
      {Array.from(barSet).map((k: string, index: number) => (
        <Bar
          key={k}
          dataKey={k}
          stackId='a'
          fill={COLORS[index % COLORS.length]}
        />
      ))}
    </BarChart>
  );
};

export default BarChartComponent;


import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { EmptyList } from '../../../components/list';
import { FormFooter } from '../../../components/form-footer';
// features
import StopFormWithItems, { StopActions } from '../../new-do/outside-use/stop-form-with-items';
import {
  getDataFromStops,
  getStopIconColor,
  getItemsForDropOptions,
  makeStopsForSaveAndUpdate,
} from '../../new-do/helpers';
import {
  locationFieldsToPick,
  getDefaultDropFields,
  getDefaultPickupFields,
} from '../../new-do/settings/fields-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box } from '../../../ui';
//////////////////////////////////////////////////

const createNewDOPickup = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultPickupFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
});

const createNewDODrop = (order: number) => ({
  order,
  isValid: false,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  formData: getDefaultDropFields(),
  [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
});

const createNewStop = (stopType: string, stopOrder: string, store: Object) => {
  if (R.equals(stopType, GC.EVENT_TYPE_PICKUP)) {
    const newStop = createNewDOPickup(stopOrder);
    const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
    const newFormData = R.mergeRight(newStop.formData, withDates);
    return R.assoc('formData', newFormData, newStop);
  }
  const newStop = createNewDODrop(stopOrder);
  const withDates = G.getBranchNewStopDateTimeDefaultValues(stopType, store);
  const newFormData = R.mergeRight(newStop.formData, withDates);
  return R.assoc('formData', newFormData, newStop);
};

const mapStopToFormData = (stop: Object, order: number) => ({
  order,
  isValid: true,
  id: G.generateGuid(),
  [GC.FIELD_DISTANCE_CLO]: null,
  [GC.FIELD_EVENT_TYPE]: R.prop(GC.FIELD_EVENT_TYPE, stop),
  formData: {
    ...stop,
    ...R.pathOr({}, [GC.FIELD_LOCATION, GC.FIELD_CONTACTS, 0], stop),
    ...R.pick(locationFieldsToPick, R.prop(GC.SYSTEM_OBJECT_LOCATION, stop)),
    [GC.FIELD_GUID]: R.prop(GC.FIELD_GUID, stop),
    [GC.FIELD_LOAD_ITEMS]: R.prop(G.ifElse(G.isStopPickup(stop), GC.FIELD_LOAD_ITEMS, GC.FIELD_STOP_ITEM_IDS), stop),
    [GC.FIELD_LOCATION_TYPE]: R.pathOr(
      R.pathOr(null, [GC.FIELD_LOCATION, GC.FIELD_LOCATION_TYPE], stop),
      [GC.FIELD_LOCATION, GC.FIELD_LOCATION_TYPE, GC.FIELD_DROPDOWN_OPTION_GUID],
      stop,
    ),
  },
});

const isDataValid = (stops: Object) => {
  let isValid = R.compose(
    R.all(R.equals(true)),
    R.map(({ isValid, formData }: Object) => R.and(
      isValid,
      G.isNotNilAndNotEmpty(R.path([GC.FIELD_LOAD_ITEMS], formData)),
    )),
    R.values,
  )(stops);

  if (R.not(isValid)) {
    return { isValid, message: 'Please, check all stop forms' };
  }

  const pickupItems = R.compose(
    R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.reduce((acc: Array, stop: Object) => R.concat(
      acc,
      R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], stop),
    ), []),
    R.filter(G.isStopPickup),
    R.values,
  )(stops);
  const dropItems = R.compose(
    R.reduce((acc: Array, stop: Object) => R.concat(
      acc,
      R.pathOr([], ['formData', GC.FIELD_LOAD_ITEMS], stop),
    ), []),
    R.filter(G.isStopDrop),
    R.values,
  )(stops);
  isValid = R.compose(
    R.isEmpty,
    R.difference(pickupItems),
  )(dropItems);

  if (R.not(isValid)) {
    return { isValid, message: 'Some items where not dropped' };
  }

  return { isValid };
};

const enhance = compose(
  withState('activeTab', 'setActiveTab', 1),
  withState('stops', 'setStops', ({ load }: Object) => R.compose(
    R.indexBy(R.prop(GC.FIELD_ORDER)),
    G.mapIndexed((stop: Object, i: number) => mapStopToFormData(stop, R.inc(i))),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(load)),
  withState('stopsToRemove', 'setStopsToRemove', []),
  withHandlers({
    handleSaveStops: (props: Object) => (stops: Object) => {
      const { load, saveStops, closeModal, stopsToRemove } = props;
      const { isValid, message } = isDataValid(stops);
      if (isValid) {
        const { pickedItems } = getDataFromStops(stops);
        const indexedPrevEvents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.prop(GC.FIELD_LOAD_STOPS),
        )(load);
        const stopsToSave = makeStopsForSaveAndUpdate(stops, [], pickedItems, indexedPrevEvents);
        saveStops({ stopsToSave, stopsToRemove });
        closeModal();
      } else {
        G.showToastrMessageSimple('info', message);
      }
    },
  }),
  withHandlers({
    handleAddNewStopToStore: ({ stops, setStops, branchConfigs }: Object) => (stopType: string) => {
      const stopOrder = R.inc(R.length(R.values(stops)));
      const stop = createNewStop(stopType, stopOrder, { stops, branchConfigs });
      setStops(R.assoc(stopOrder, stop, stops));
    },
    handleSetFormDataToStop: ({ stops, setStops, handleSaveStops }: Object) => (data: Object) => {
      const { isValid, formData, stopOrder, shouldSave } = data;
      const stop = R.mergeRight(
        R.prop(stopOrder, stops),
        { isValid, formData },
      );
      const newStops = R.assoc(stopOrder, stop, stops);
      setStops(newStops);
      if (shouldSave) handleSaveStops(newStops);
    },
    handleRemoveStop: (props: Object) => (index: number) => {
      const stopToRemove = R.path(['stops', index], props);
      const eventGuid = R.path(['formData', GC.FIELD_GUID], stopToRemove);
      if (G.isNotNil(eventGuid)) {
        props.setStopsToRemove(R.append(eventGuid, props.stopsToRemove));
      }
      const newStops = R.compose(
        R.indexBy(R.prop(GC.FIELD_ORDER)),
        G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_ORDER, R.inc(i), stop)),
        R.values,
        R.dissoc(index),
      )(props.stops);
      props.setActiveTab(1);
      props.setStops(newStops);
    },
  }),
  pure,
);

const createTabSettings = (stops: Array) => R.map((stop: Object) => ({
  name: R.prop(GC.FIELD_ORDER, stop),
  textColor: getStopIconColor(R.path(['isValid'], stop)),
  text: `${R.head(R.prop(GC.FIELD_EVENT_TYPE, stop))} ${R.prop(GC.FIELD_ORDER, stop)}`,
}), R.values(stops));

const StopComponent = (props: Object) => {
  const {
    stops,
    activeTab,
    closeModal,
    setActiveTab,
    handleSaveStops,
    handleRemoveStop,
    handleSetFormDataToStop,
    handleAddNewStopToStore } = props;
  const stop = stops[activeTab];
  if (G.isNotNilAndNotEmpty(stop)) {
    const tabOptions = createTabSettings(stops);
    const { id, order, formData, eventType } = stop;
    const stopProps = {
      stop,
      eventType,
      stopId: id,
      tabOptions,
      closeModal,
      withTabs: true,
      handleSaveStops,
      stopOrder: order,
      initialValues: formData,
      setActiveLeftTab: setActiveTab,
      removeStopFromStore: handleRemoveStop,
      addNewStopToStore: handleAddNewStopToStore,
      setFormDataToStop: handleSetFormDataToStop,
    };
    if (R.equals(eventType, GC.EVENT_TYPE_PICKUP)) {
      return (
        <StopFormWithItems {...props} {...stopProps} />
      );
    }
    const items = getItemsForDropOptions(stops, formData.items);
    return (
      <StopFormWithItems {...props} {...stopProps} items={items} />
    );
  }
  return (
    <Box width={700}>
      <Box p='8px' ml='auto' width='fit-content'>
        <StopActions addNewStopToStore={handleAddNewStopToStore} />
      </Box>
      <EmptyList>No Stops</EmptyList>
      <FormFooter
        boxStyles={{ p: 15 }}
        closeModal={closeModal}
        submitAction={handleSaveStops} />
    </Box>
  );
};

const AddCloStops = enhance((props: Object) => (
  <Box overflow='auto' maxHeight='calc(90vh - 30px)'>
    <StopComponent {...props} />
  </Box>
));

export default AddCloStops;

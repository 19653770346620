import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import React, { memo, useState, useCallback } from 'react';
// components
import { ConfirmComponent } from '../../../components/confirm';
import DimmerComponent from '../../../components/loader/dimmer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hooks
import { useModalAndLoaderActions } from '../../../hooks';
// utilities
import routesMap from '../../../utilities/routes';
// feature fleet-profile
import GeneralDetails from '../forms/general-details';
import DriverTabsGroups from './components/tabs-group';
import { generalDetailsSettings } from './settings/general-details';
import FleetProfileWrapper from '../components/fleet-profile-wrapper';
import { useFleetProfileTabsView } from '../hooks/use-fleet-profile-tabs-view';
import { setInitialState, updateDriverGeneralDetailsRequest } from '../actions';
import { useFleetProfileWrapperActions } from '../hooks/use-fleet-profile-wrapper-actions';
import {
  makeSelectConfigGroup,
  makeSelectCollapsedGroup,
  selectDriverGeneralDetails,
  makeSelectGeneralUsersAndDispatchingGroup,
} from '../selectors';
//////////////////////////////////////////////////

const DriverGeneralDetails = memo((props: Object) => {
  const { configGroup, initialValues } = props;

  const { guid, nickName, employed, lastName, firstName, teamDriver, primaryDriverGuid } = initialValues;

  const title = `${G.getWindowLocale('titles:fleet-driver', 'Fleet: Driver')} ${firstName} ${lastName} ${
    R.or(nickName, '')}`;

  const { generalUserList, dispatchingGroup } = useSelector(makeSelectGeneralUsersAndDispatchingGroup());

  const usersGeneral = G.addEmptyOptionToDropDown(R.map((item: Object) => ({
    [GC.FIELD_VALUE]: G.getGuidFromObject(item),
    [GC.FIELD_LABEL]: G.getPropFromObject('fullText', G.getUserInfo(item)),
  }), R.or(generalUserList, [])));

  const dispatchingGroupOptions = G.addEmptyOptionToDropDown(
    G.mapNameGuidObjectPropsToLabelValueObject(R.or(dispatchingGroup, [])),
  );

  const optionsForSelect = {
    teamDriver,
    usersGeneral,
    dispatchingGroupOptions,
    assignedDivision: R.path(['dropdownOptions', GC.DRIVER_ASSIGNED_DIVISION], configGroup),
    domicileTerminal: R.path(['dropdownOptions', GC.DRIVER_DOMICILE_TERMINAL], configGroup),
    unemploymentReason: R.path(['dropdownOptions', GC.DRIVER_UNEMPLOYED_REASON], configGroup),
  };

  const driverProps = {
    ...generalDetailsSettings,
    title,
    teamDriver,
    type: 'driver',
    hasPhoto: true,
    optionsForSelect,
    active: employed,
    showStatus: true,
    hasAssignment: employed,
    isPrimaryDriver: R.isNil(primaryDriverGuid),
    driverAssignmentGuid: R.or(primaryDriverGuid, guid),
  };

  return <GeneralDetails {...props} {...driverProps} />;
});

const FleetProfileDriver = (props: Object) => {
  const { handleGoBack, expandedContainer, closeExpandedContainer } = props;

  const [saveAndClose, setSaveAndClose] = useState(false);

  const configGroup = useSelector(makeSelectConfigGroup());
  const collapsedGroup = useSelector(makeSelectCollapsedGroup());
  const generalDetails = useSelector(selectDriverGeneralDetails);

  const dispatch = useDispatch();

  const commonActions = useModalAndLoaderActions();

  const { openModal, closeModal } = commonActions;

  const handleClose = useCallback(() => {
    dispatch(setInitialState());

    if (R.and(G.isFunction(handleGoBack), G.isArray(saveAndClose))) return handleGoBack();

    if (G.isFunction(closeExpandedContainer)) return closeExpandedContainer();

    G.goToRoute(routesMap.fleetDriverList);
  }, [saveAndClose]);

  const handleUpdateGeneralDetailsRequest = useCallback(({ values, options }: Object) => {
    if (R.equals(R.prop(GC.FIELD_TEAM_DRIVER, values), R.prop(GC.FIELD_TEAM_DRIVER, generalDetails))) {
      return dispatch(updateDriverGeneralDetailsRequest({ values, options, handleClose, saveAndClose }));
    }

    const text = G.getWindowLocale(
      'messages:driver-becomes-team-driver',
      'If a driver becomes a Team Driver, related equipment will be unassigned',
    );

    const component = <ConfirmComponent textLocale={text} />;

    const modal = {
      component,
      options: {
        width: 600,
        controlButtons: [
          {
            type: 'button',
            name: G.getWindowLocale('actions:update', 'Update'),
            action: () =>
              dispatch(updateDriverGeneralDetailsRequest({
                values,
                handleClose,
                saveAndClose,
                options: { ...options, shouldCloseModal: true },
              })),
          },
        ],
      },
    };

    openModal(modal);
  }, [saveAndClose, generalDetails]);

  const { handleToggleFormGroupTable, handleUpdateGeneralDetails } = useFleetProfileWrapperActions({
    dispatch,
    closeModal,
    handleClose,
    saveAndClose,
    generalDetails,
    setSaveAndClose,
    handleUpdateGeneralDetailsRequest,
  });

  const { setTabsView, tabsCollapsedView } = useFleetProfileTabsView();

  const defaultProps = {
    ...commonActions,
    configGroup,
    tabsCollapsedView,
    fleetType: 'driver',
  };

  const guid = G.getGuidFromObject(generalDetails);

  if (G.isNilOrEmpty(guid)) return <DimmerComponent tableCount={3} rectangleCount={4} />;

  return (
    <FleetProfileWrapper
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
    >
      <DriverGeneralDetails
        {...defaultProps}
        saveAndClose={saveAndClose}
        initialValues={generalDetails}
        setSaveAndClose={setSaveAndClose}
        submitAction={handleUpdateGeneralDetails}
      />
      <DriverTabsGroups
        {...defaultProps}
        primaryObjectGuid={guid}
        setTabsView={setTabsView}
        collapsedGroup={collapsedGroup}
        handleToggleFormGroupTable={handleToggleFormGroupTable}
        handleUpdateGeneralDetails={handleUpdateGeneralDetails}
        driverBranchGuid={G.getBranchGuidFromObject(generalDetails)}
      />
    </FleetProfileWrapper>
  );
};

export const Component = FleetProfileDriver;

export default FleetProfileDriver;

import * as R from 'ramda';
import React from 'react';
// components
import { TextComponent } from '../../../components/text';
import { ConnectToMailIntegration } from '../../../components';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { DEFAULT_REPLY_TO } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StyledLink } from '../../../ui';
// feature configs
import { EmailsComponent, MultiselectFieldComponent } from '../ui';
import AllowedDivisions from '../config-pages/carrier/components/allowed-divisions';
//////////////////////////////////////////////////

const CARRIER_GENERAL = [
  {
    title: 'titles:general',
    fields: [
      {
        zIndex: 14,
        type: 'list',
        component: MultiselectFieldComponent,
        nameForAttribute: GC.CARRIER_REQUIRED_FIELDS,
        name: 'titles:create-carrier-required-fields',
        shouldGetInheritedValueFromConfigOptions: true,
        options: [
          {
            value: GC.FIELD_MC_NUMBER,
            label: G.getWindowLocale('titles:mc-number', 'MC Number'),
          },
          {
            value: GC.FIELD_US_DOT_NUMBER,
            label: G.getWindowLocale('titles:usdot-number', 'USDOT Number'),
          },
        ],
      },
      {
        zIndex: 13,
        type: 'list',
        options: 'availableDocumentTypes',
        name: 'titles:shown-document-types',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CARRIER_GENERAL_SHOWN_DOCUMENT_TYPES,
        infoText: G.getWindowLocale(
          'messages:general-shown-document-types',
          'Used for documents that are shown to the carrier on the portal.',
        ),
      },
      {
        zIndex: 12,
        type: 'list',
        options: 'telReferenceTypes',
        component: MultiselectFieldComponent,
        name: 'titles:shown-trip-references',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.CARRIER_GENERAL_SHOWN_TRIP_REFERENCES,
        infoText: G.getWindowLocale(
          'messages:general-shown-trip-references',
          'Used for references that are shown to the carrier on the portal.',
        ),
      },
      {
        type: 'switcher',
        name: 'titles:use-fleet-trailer',
        nameForAttribute: GC.CARRIER_GENERAL_USE_FLEET_TRAILER,
        infoText: G.getWindowLocale(
          'messages:activate-use-fleet-trailer-on-carrier',
          'Allows users to manage Fleet Trailer Issues, Inspections and Documents directly within the Amous Carrier Tracking Mobile App. To activate this feature navigate  to Configuration → Mobile Application → Carrier → "Use Fleet Trailer" → switch to "Yes"',
        ),
      },
      {
        type: 'switcher',
        name: 'titles:synchronization-required',
        nameForAttribute: GC.CARRIER_GENERAL_SAFER_WATCH_SYNC_REQUIRED,
      },
    ],
  },
  {
    title: 'titles:branch',
    fields: [
      {
        type: 'customComponent',
        name: 'titles:allow-carrier-editing',
        nameForAttribute: GC.CARRIER_ALLOW_EDITING_FOR_DIVISIONS,
        component: (props: Object) => <AllowedDivisions {...props} />,
      },
      {
        type: 'select',
        options: 'parentBranches',
        name: 'titles:create-on-branch',
        nameForAttribute: GC.CARRIER_CREATE_ON_BRANCH,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:terms-and-conditions-document',
    configName: GC.CARRIER_TERMS_AND_CONDITIONS_DOCUMENT,
    customConfigName: GC.CARRIER_TERMS_AND_CONDITIONS_DOCUMENT,
    hideTitleActions: ({ termsAndConditionsDocument }: Object) =>
      G.isNotNilAndNotEmpty(termsAndConditionsDocument),
    fields: [
      {
        tableColumnWith: '100%',
        name: 'titles:document-name',
        nameForAttribute: GC.FIELD_FILE_NAME,
        customLogic: (field: Object, { fileUrl, fileName }: Object) => (
          <StyledLink
            href={fileUrl}
            display='flex'
            alignItems='center'
            textDecoration='underline'
            color={G.getTheme('colors.light.blue')}
          >
            {I.pdf()}
            <Box ml={10}>{fileName}</Box>
          </StyledLink>
        ),
      },
    ],
  },
];

const statusCheckSection = [{
  title: 'titles:status-check',
  fields: [
    {
      type: 'switcher',
      name: 'titles:load-check-process-enabled',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_ENABLED,
    },
    {
      type: 'text',
      name: 'titles:subject',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_SUBJECT,
    },
    {
      width: 400,
      type: 'textarea',
      name: 'titles:message',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_MESSAGE,
    },
    {
      type: 'switcher',
      name: 'titles:auto-status-check-enabled',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_AUTO_STATUS_CHECK_ENABLED,
    },
    {
      type: 'text',
      name: 'titles:status-check-frequency',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_FREQUENCY,
    },
    {
      type: 'text',
      name: 'titles:api-hours-before-first-event',
      nameForAttribute: GC.CARRIER_LOAD_STATUS_CHECK_API_HOURS_BEFORE_FIRST_EVENT,
    },
    {
      type: 'select',
      options: 'pickupCompletedStatusCodes',
      name: 'titles:default-complete-pickup-status-code',
      nameForAttribute: GC.CARRIER_CARRIER_STATUS_CHECK_DEFAULT_PICKUP_STATUS_CODE,
    },
    {
      type: 'select',
      options: 'dropCompletedStatusCodes',
      name: 'titles:default-complete-drop-status-code',
      nameForAttribute: GC.CARRIER_CARRIER_STATUS_CHECK_DEFAULT_DELIVERY_STATUS_CODE,
    },
  ],
}];

const dispatchProcessSection = [{
  title: 'titles:dispatch-process',
  fields: [
    {
      type: 'switcher',
      name: 'titles:auto-accept',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_AUTO_ACCEPT,
    },
    {
      type: 'switcher',
      name: 'titles:auto-dispatch-on-order-create',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_AUTO_DISPATCH_ON_ORDER_CREATE,
    },
    {
      type: 'switcher',
      name: 'titles:auto-dispatch-on-order-create-from-template',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_AUTO_DISPATCH_ON_ORDER_CREATE_FROM_TEMPLATE,
    },
    {
      type: 'text',
      name: 'titles:subject',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_SUBJECT,
    },
    {
      width: 400,
      type: 'textarea',
      name: 'titles:message',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_MESSAGE,
    },
    {
      type: 'select',
      name: 'titles:reply-to',
      options: DEFAULT_REPLY_TO,
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_REPLY_TO,
    },
    {
      type: 'select',
      name: 'titles:default-document-template',
      options: 'carrierDispatchDocumentTemplates',
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_DEFAULT_DOCUMENT_TEMPLATE,
    },
    {
      type: 'switcher',
      name: 'titles:email-validate-pickup-date',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_EMAIL_VALIDATE_PICKUP_DATE,
    },
    {
      type: 'switcher',
      name: 'titles:api-validate-pickup-date',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_API_VALIDATE_PICKUP_DATE,
    },
    {
      type: 'switcher',
      name: 'titles:edi-validate-pickup-date',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_EDI_VALIDATE_PICKUP_DATE,
    },
    {
      type: 'switcher',
      name: 'titles:internal-validate-pickup-date',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_INTERNAL_VALIDATE_PICKUP_DATE,
    },
    {
      type: 'switcher',
      name: 'titles:auto-update-pickup-date',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_AUTO_UPDATE_PICKUP_DATE,
    },
    {
      type: 'text',
      name: 'titles:validate-pickup-date-min-hours',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_MIN_HOURS,
    },
    {
      type: 'text',
      name: 'titles:auto-update-pickup-date-max-days',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_AUTO_UPDATE_PICKUP_DATE_MAX_DAYS,
    },
    {
      type: 'text',
      name: 'titles:validate-pickup-date-working-hours-start-hour',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_WORKING_HOURS_START_HOUR,
    },
    {
      type: 'text',
      name: 'titles:validate-pickup-date-working-hours-end-hour',
      nameForAttribute: GC.CARRIER_DISPATCH_PROCESS_VALIDATE_PICKUP_DATE_WORKING_HOURS_END_HOUR,
    },
  ],
}];

const quoteProcessSection = [
  {
    title: 'titles:quote-process',
    fields: [
      {
        type: 'text',
        name: 'titles:email-division-name',
        nameForAttribute: GC.CARRIER_QUOTE_PROCESS_EMAIL_DIVISION_NAME,
      },
      {
        type: 'text',
        name: 'titles:subject',
        nameForAttribute: GC.CARRIER_QUOTE_PROCESS_DEFAULT_SUBJECT,
      },
      {
        width: 400,
        height: '200',
        type: 'textarea',
        name: 'titles:message',
        nameForAttribute: GC.CARRIER_QUOTE_PROCESS_DEFAULT_MESSAGE,
      },
    ],
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    title: 'titles:email-group',
    editPopupTitle: 'titles:edit',
    configName: GC.CARRIER_EMAIL_GROUP,
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:decline-reason',
    configName: GC.CARRIER_QUOTE_PROCESS_DECLINE_REASON_CODE,
  },
];

const acceptRateSection = [{
  title: 'titles:accept-rate',
  fields: [
    {
      type: 'switcher',
      name: 'titles:show-full-address',
      nameForAttribute: GC.CARRIER_EMAIL_TEMPLATE_SHOWN_FULL_ADDRESS,
    },
    {
      type: 'switcher',
      name: 'titles:show-rate-breakdown',
      nameForAttribute: GC.CARRIER_EMAIL_TEMPLATE_SHOW_RATE_BREAKDOWN,
    },
    {
      type: 'switcher',
      name: 'titles:show-update-in-booked-email',
      nameForAttribute: GC.CARRIER_EMAIL_TEMPLATE_SHOW_UPDATE_LINK_IN_BOOKED_EMAIL,
    },
    {
      zIndex: 13,
      type: 'list',
      name: 'titles:show-fields',
      component: MultiselectFieldComponent,
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.CARRIER_EMAIL_TEMPLATE_SHOWN_FIELDS,
      options: [
        {
          value: GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_RATE,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_RATE),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_BILL_TO,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_BILL_TO),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_SERVICES,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_SERVICES),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_EQUIPMENT,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_EQUIPMENT),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_INSTRUCTION,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_SHOW_FIELD_INSTRUCTION),
        },
      ],
    },
    {
      zIndex: 12,
      type: 'list',
      name: 'titles:required-fields',
      component: MultiselectFieldComponent,
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELDS,
      options: [
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_APPROVED_BY,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_APPROVED_BY),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_EMAIL,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_EMAIL),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_FULL_NAME,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_FULL_NAME),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_PHONE_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DISPATCHER_PHONE_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_INTERNAL_LOAD_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_INTERNAL_LOAD_NUMBER),
        },
      ],
    },
    {
      type: 'list',
      name: 'titles:lock-populated-fields',
      component: MultiselectFieldComponent,
      shouldGetInheritedValueFromConfigOptions: true,
      nameForAttribute: GC.CARRIER_ACCEPT_RATE_LOCK_POPULATED_FIELDS,
      options: [
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRUCK_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_TRAILER_NUMBER),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_FULL_NAME),
        },
        {
          value: GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER,
          label: G.getEnumLocale(GC.CARRIER_ACCEPT_RATE_REQUIRED_FIELD_DRIVER_PHONE_NUMBER),
        },
      ],
      infoText: G.getWindowLocale(
        'messages:general-shown-lock-if-populated',
        'If data is entered in these fields before dispatching carrier, they will be locked on the carrier accept form.',
      ),
    },
  ],
},
];

const insuranceRuleSection = [
  {
    title: 'titles:default-deactivation-rule',
    fields: [
      {
        type: 'text',
        name: 'titles:minimum-load-count',
        nameForAttribute: GC.CARRIER_DEFAULT_DEACTIVATION_RULE_STATISTIC_MINIMUM_LOAD_COUNT,
      },
      {
        type: 'text',
        name: 'titles:period-in-days',
        nameForAttribute: GC.CARRIER_DEFAULT_DEACTIVATION_RULE_STATISTIC_PERIOD,
      },
    ],
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:insurance-type',
    configName: GC.CARRIER_INSURANCE_TYPE,
  },
  {
    configType: 'custom',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:deactivation-rule',
    configName: GC.CARRIER_DEACTIVATION_RULES,
    customConfigName: GC.CARRIER_DEACTIVATION_RULES,
    fields: [
      {
        name: 'titles:trigger',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER,
        customLogic: (field: Object, entity: Object) => G.getEnumLocale(G.getPropFromObject(
          GC.FIELD_CARRIER_DEACTIVATION_RULE_TRIGGER,
          entity,
        )),
      },
      {
        name: 'titles:deactivation-days',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_DEACTIVATION_DAYS,
      },
      {
        name: 'titles:insurance-types',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS,
        customLogic: (field: Object, entity: Object, props: Object) => {
          let insurances = [];

          props.insuranceTypes.forEach((insurance: Object) => {
            const value = R.pathOr(
              [],
              [GC.FIELD_CARRIER_DEACTIVATION_RULE_INSURANCE_TYPE_GUIDS],
              entity,
            );

            const condition = R.or(
              R.includes(G.getPropFromObject(GC.FIELD_PARENT_GUID, insurance), value),
              R.includes(G.getGuidFromObject(insurance), R.pathOr([], [field.nameForAttribute], entity)),
            );

            if (condition) {
              insurances = R.concat(insurances, R.of(Array, `${insurance.displayedValue}(${insurance.storedValue})`));
            }
          });

          return R.join(', ', insurances);
        },
      },
      {
        name: 'titles:minimum-load-count',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_MINIMUM_LOAD_COUNT,
      },
      {
        name: 'titles:period-in-days',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERIOD_IN_DAYS,
      },
      {
        name: 'titles:percent',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_PERCENT,
      },
      {
        name: 'titles:average-hours',
        nameForAttribute: GC.FIELD_CARRIER_DEACTIVATION_RULE_AVERAGE_HOURS,
      },
    ],
  },
];

const CARRIER_ONBOARDING = [
  {
    configType: 'custom',
    title: 'titles:onboarding-package',
    configName: GC.CARRIER_ONBOARDING_PACKAGE,
    addPopupTitle: 'titles:add-onboarding-package',
    customConfigName: GC.CARRIER_ONBOARDING_PACKAGE,
    editPopupTitle: 'titles:edit-onboarding-package',
    fields: [
      {
        name: 'titles:name',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_NAME,
      },
      {
        name: 'titles:reply-to',
        tableColumnWith: '440px',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_REPLY_TO,
        customLogic: (_: any, { replyTo }: Object) => (
          <Flex>
            <TextComponent
              title={replyTo}
              display='block'
              withEllipsis={true}
              maxWidth='calc(100% - 175px)'
            >
              {replyTo}
            </TextComponent>
            <ConnectToMailIntegration
              ml={15}
              email={replyTo}
              fromConfig={true}
              currentEmail={replyTo}
            />
          </Flex>
        ),
      },
      {
        name: 'titles:copy-to',
        tableColumnWith: '400px',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_COPY_TO,
        customLogic: (_: Object, { copyTo }: Object) =>
          G.isNotNilAndNotEmpty(copyTo) && <EmailsComponent p='5px 0px' emails={copyTo} />,
      },
      {
        name: 'titles:subject',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_SUBJECT,
      },
      {
        name: 'titles:message',
        tableColumnWith: '400px',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_MESSAGE,
      },
    ],
  },
  {
    hideInherited: true,
    configType: 'custom',
    title: 'titles:onboarding-package-documents',
    configName: GC.CARRIER_ONBOARDING_PACKAGE_DOCUMENT,
    customConfigName: GC.CARRIER_ONBOARDING_PACKAGE_DOCUMENT,
    hideTitleActions: ({ onboardingPackageList }: Object) => R.compose(
      R.not,
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID),
      R.head,
    )(onboardingPackageList),
    fields: [
      {
        tableColumnWith: '300px',
        name: 'titles:document-name',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DOCUMENT_FILE_NAME,
      },
      {
        name: 'titles:url',
        tableColumnWith: '300px',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_URL,
      },
      {
        tableColumnWith: '400px',
        name: 'titles:description',
        nameForAttribute: GC.FIELD_CARRIER_ONBOARDING_PACKAGE_DOCUMENT_DESCRIPTION,
      },
    ],
  },
];

const CARRIER_DROPDOWNS = [
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:document-type',
    configName: GC.CARRIER_DOCUMENT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    addPopupTitle: 'titles:add',
    editPopupTitle: 'titles:edit',
    title: 'titles:status-reason',
    configName: GC.CARRIER_DEACTIVATED_TYPE,
  },
];

const CARRIER_SCORECARDS = [
  {
    configType: 'custom',
    title: 'titles:score-cards',
    configName: GC.CUSTOM_CARRIER_SCORECARD_CONFIG,
    customConfigName: GC.CUSTOM_CARRIER_SCORECARD_CONFIG,
    fields: [
      {
        tableColumnWith: 400,
        name: 'titles:transportation-modes',
        nameForAttribute: GC.FIELD_TRANSPORTATION_MODE_GUIDS,
        customLogic: (_: any, { transportationModeGuids }: Object, props: Object) => R.compose(
          R.join(', '),
          R.filter(G.isNotNilAndNotEmpty),
          R.map((item: string) => R.compose(
            R.path([GC.FIELD_LABEL]),
            R.find(R.propEq(item, GC.FIELD_VALUE)),
            R.pathOr([], ['configOptions', 'transportationModes']),
          )(props)),
        )(transportationModeGuids),
      },
      {
        tableColumnWith: 400,
        name: 'titles:upload-time-invoice-statuses',
        nameForAttribute: GC.FIELD_UPLOAD_TIME_INVOICE_STATUS_GUIDS,
        customLogic: (_: any, { uploadTimeInvoiceStatusGuids }: Object, props: Object) => R.compose(
          R.join(', '),
          R.filter(G.isNotNilAndNotEmpty),
          R.map((invoiceStatusGuid: string) => R.compose(
            G.getDisplayedValueFromObject,
            R.find(R.propEq(invoiceStatusGuid, GC.FIELD_ORIGINAL_CONFIG_GUID)),
            R.pathOr([], ['carrierInvoiceStatuses']),
          )(props)),
        )(R.or(uploadTimeInvoiceStatusGuids, [])),
      },
      {
        name: 'titles:upload-time-document-type',
        nameForAttribute: GC.FIELD_UPLOAD_TIME_DOCUMENT_TYPE_GUID,
        customLogic: (_: any, { uploadTimeDocumentTypeGuid }: Object, props: Object) => R.compose(
          G.getDisplayedValueFromObject,
          R.find(R.propEq(uploadTimeDocumentTypeGuid, GC.FIELD_VALUE)),
          R.pathOr([], ['configOptions', 'availableDocumentTypes']),
        )(props),
      },
      {
        name: 'titles:late-after-minutes',
        nameForAttribute: GC.FIELD_LATE_AFTER_MINUTES,
      },
    ],
  },
];

const ConfigCarrierGroup = {
  CARRIER_GENERAL,
  statusCheckSection,
  dispatchProcessSection,
  quoteProcessSection,
  acceptRateSection,
  insuranceRuleSection,
  CARRIER_ONBOARDING,
  CARRIER_DROPDOWNS,
  CARRIER_SCORECARDS,
};

export {
  ConfigCarrierGroup,
  insuranceRuleSection,
};

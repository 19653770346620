import React from 'react';
// features
import TrailersCharts from '../dashboards/components/trailers-charts';
// helpers/constants
import * as G from '../../helpers';
// ui
import { ListWrapper } from '../../ui';
//////////////////////////////////////////////////

const TrailerDashboards = (props: Object) => {
  const { tabs } = props;

  return (
    <ListWrapper
      pl='0px'
      p='30px 15px 60px 0px'
      bgColor={G.getTheme('colors.bgGrey')}
    >
      {tabs}
      <TrailersCharts {...props} />
    </ListWrapper>
  );
};

export {
  TrailerDashboards,
};

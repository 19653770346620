import { compose, withState, withPropsOnChange } from 'react-recompose';
//////////////////////////////////////////////////

export const withRerenderMap = compose(
  withState('isActiveMap', 'setRenderedMap', true),
  withPropsOnChange(
    ['activeMap', 'selectedDriver'],
    (props: Object) => {
      props.setRenderedMap(false);
      setTimeout(() => {
        props.setRenderedMap(true);
      }, 0);
    },
  ),
);

export const withRerenderLNLMap = compose(
  withState('isLNLActiveMap', 'setLNLRenderedMap', true),
  withPropsOnChange(
    ['drivers', 'trucks', 'trailers'],
    (props: Object) => {
      props.setLNLRenderedMap(false);
      setTimeout(() => {
        props.setLNLRenderedMap(true);
      }, 0);
    },
  ),
);

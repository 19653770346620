import { createAction } from 'redux-act';
//////////////////////////////////////////////////

const setValueToStore = createAction('setValueToStore');
const getDashboardRequest = createAction('getDashboardRequest');
const getDashboardSuccess = createAction('getDashboardSuccess');
const setDateFiltersToStore = createAction('setDateFiltersToStore');
const setDashboardTypeToStore = createAction('setDashboardTypeToStore');
const getChartByFiltersRequest = createAction('getChartByFiltersRequest');
const getChartByFiltersSuccess = createAction('getChartByFiltersSuccess');
const setEnterpriseFilterToStore = createAction('setEnterpriseFilterToStore');
const getNormalizedCurrencyRequest = createAction('getNormalizedCurrencyRequest');
const getNormalizedCurrencySuccess = createAction('getNormalizedCurrencySuccess');

export {
  setValueToStore,
  getDashboardRequest,
  getDashboardSuccess,
  setDateFiltersToStore,
  setDashboardTypeToStore,
  getChartByFiltersRequest,
  getChartByFiltersSuccess,
  setEnterpriseFilterToStore,
  getNormalizedCurrencyRequest,
  getNormalizedCurrencySuccess,
};


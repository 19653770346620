import * as R from 'ramda';
import * as P from 'plow-js';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// feature dispatch-planner
import * as C from './constants';
//////////////////////////////////////////////////

// COMMON
export const getLoadName = (load: Object, newNameText: string = '') => R.pathOr(
  newNameText,
  ['primaryReference', 'value'],
  load,
);

export const mergeArrayItemsProp = (arr: Array = [], prop: string) => (
  R.reduce(
    R.mergeRight,
    {},
    R.map((entity: Object = {}) => entity[prop], arr),
  )
);

export const concatArrayItemsProp = (arr: Array = [], prop: string) => (
  R.reduce(
    R.concat,
    [],
    R.map((entity: Object = {}) => entity[prop], arr),
  )
);

export const indexObjectArrayPropByPropName = (
  propName: string,
  pathToProp: string,
  obj: Object,
) => R.assoc(
  pathToProp,
  R.indexBy(R.prop(propName), R.prop(pathToProp, obj)),
  obj,
);

export const getCloItemsInTelByEventType = (tel: Object, eventType: string) => R.compose(
  R.filter(G.isLoadTypeClo),
  (events: Array) => concatArrayItemsProp(events, 'items'),
  R.filter((event: Object) => R.propEq(eventType, 'eventType', event)),
  R.values,
)(tel[GC.FIELD_LOAD_STOPS]);

export const filterPickedDroppedItems = (arr1: Array=[], arr2: Array=[]) => R.filter(
  (item: Object) => G.isNilOrEmpty(R.find(
    (filterItem: Object) => R.and(
      R.eqProps(GC.FIELD_LOAD_GUID, item, filterItem),
      R.eqProps(GC.FIELD_ITEM_INTERNAL_ID, item, filterItem),
    ),
    arr2,
  )),
  arr1,
);

const getTelCloItemsByType = (tel: Object, type: string) => R.compose(
  R.filter(G.isLoadTypeClo),
  R.reduce((acc: Array, stop: Object) => R.concat(acc, stop.items), []),
  R.filter(R.propEq(type, GC.FIELD_EVENT_TYPE)),
  R.values,
  R.prop(GC.FIELD_LOAD_STOPS),
)(tel);

const getTerminalCloItemsByType = (tels: Array, telGuid: string, templateId: string, type: string) => {
  let telsToUse = tels;

  if (R.equals(type, GC.EVENT_TYPE_DROP)) {
    telsToUse = R.filter(({ guid }: Object) => G.notEquals(guid, telGuid), tels);
  }

  return R.compose(
    R.filter(G.isLoadTypeClo),
    R.reduce((acc: Array, { items }: Object) => R.concat(acc, items), []),
    R.filter((stop: Object) => G.isAllTrue(
      R.propEq(type, GC.FIELD_EVENT_TYPE, stop),
      G.isStopTypeTerminal(G.getPropFromObject(GC.FIELD_STOP_TYPE, stop)),
      R.pathEq(templateId, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_TEMPLATE_ID], stop),
    )),
    R.reduce((acc: Array, { events }: Object) => R.concat(acc, R.values(events)), []),
  )(telsToUse);
};

export const getAvailableTerminalPickupClos = (tel: Object, tels: Array, templateId: string) => {
  const telGuid = G.getGuidFromObject(tel);

  const terminalDropedItems = getTerminalCloItemsByType(tels, telGuid, templateId, GC.EVENT_TYPE_DROP);
  const terminalPickedItems = getTerminalCloItemsByType(tels, telGuid, templateId, GC.EVENT_TYPE_PICKUP);
  const availableTerminalItems = filterPickedDroppedItems(terminalDropedItems, terminalPickedItems);

  const pickedTelItems = getTelCloItemsByType(tel, GC.EVENT_TYPE_PICKUP);
  const droppedTelItems = getTelCloItemsByType(tel, GC.EVENT_TYPE_DROP);
  const availableTelItems = filterPickedDroppedItems(droppedTelItems, pickedTelItems);

  const availableItems = R.compose(
    R.values,
    R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.concat(availableTerminalItems),
  )(availableTelItems);

  if (G.isNilOrEmpty(availableItems)) return null;

  const clos = {};

  availableItems.forEach((item: string) => {
    const cloGuid = R.path([GC.FIELD_LOAD_GUID], item);

    if (R.not(R.has(cloGuid, clos))) clos[cloGuid] = [];

    clos[cloGuid].push(item);
  });

  return clos;
};

export const getAvailableDropClosWithItems = (tel: Object) => {
  const droppedItems = getCloItemsInTelByEventType(tel, GC.EVENT_TYPE_DROP);
  const pickedItems = getCloItemsInTelByEventType(tel, GC.EVENT_TYPE_PICKUP);
  const availableItems = filterPickedDroppedItems(pickedItems, droppedItems);

  if (G.isNilOrEmpty(availableItems)) return null;

  const clos = {};
  availableItems.forEach((item: string) => {
    const cloGuid = R.path([GC.FIELD_LOAD_GUID], item);
    if (R.not(R.has(cloGuid, clos))) clos[cloGuid] = [];
    clos[cloGuid].push(item);
  });

  return clos;
};

export const getAvailableItemsForPickup = (stop: Object, items: Array) => (
  R.filter(
    (item: Object) => G.isNilOrEmpty(
      R.find(
        (id: Object) => R.equals(R.prop('itemInternalId', item), id),
        stop.itemIds,
      ),
    ),
    items,
  )
);

export const getTelItemsForDrop = (props: Object) => {
  const { tel, items } = props;

  const getFilteredEvents = (eventType: string) => R.filter(
    (event: Object) => R.and(
      R.not(R.path([GC.FIELD_CLO_EVENT], event)),
      R.propEq(eventType, GC.FIELD_EVENT_TYPE, event),
    ),
    R.values(R.pathOr([], [GC.FIELD_LOAD_STOPS], tel)),
  );

  const pickups = getFilteredEvents(GC.EVENT_TYPE_PICKUP);
  const drops = getFilteredEvents(GC.EVENT_TYPE_DROP);

  const getEventItemIds = (events: Array) => R.reduce(
    (acc: Array, { itemIds }: Object) => R.concat(acc, itemIds), [], events,
  );

  const pickedItemIds = getEventItemIds(pickups);
  const droppedItemIds = getEventItemIds(drops);

  const itemIds = R.difference(pickedItemIds, droppedItemIds);

  return R.compose(
    R.values,
    R.pick(itemIds),
    R.filter(({ loadType }: Object) => R.not(G.isLoadTypeClo(loadType))),
  )(items);
};

const getItemsByEventTypeAndLoadGuid = (tels: Object, dropStop: Object, eventType: string) => R.compose(
  R.reduce((acc: Array, stop: Object) => R.concat(acc, stop.itemIds), []),
  R.filter((stop: Object) => R.and(
    R.eqProps(GC.FIELD_LOAD_GUID, stop, dropStop),
    R.equals(eventType, stop.eventType),
  )),
  R.values,
  R.reduce((acc: Object, { events }: Array) => R.mergeRight(acc, events), {}),
  R.values,
)(tels);

export const getCloItemsForDrop = (props: Object) => {
  const dropStop = props.stop;
  const tels = R.path(['currentRoute', GC.SYSTEM_LIST_TELS], props);
  const pickedItems = getItemsByEventTypeAndLoadGuid(tels, dropStop, GC.EVENT_TYPE_PICKUP);
  const droppedItems = getItemsByEventTypeAndLoadGuid(tels, dropStop, GC.EVENT_TYPE_DROP);
  const itemIds = R.difference(pickedItems, droppedItems);

  return R.map((id: string) => R.path(['items', id], props), itemIds);
};

export const getTerminalInitialDates = ({ dropTels, pickupTels }: Object = {}, tels: Object = {}) => {
  const currentDateTime = G.getCurrentDateWithFormat(GC.DEFAULT_DATE_TIME_FORMAT);
  let dates = {
    eventLateDate: currentDateTime,
    eventEarlyDate: currentDateTime,
  };

  if (G.isNotNilAndNotEmpty(dropTels) && G.isNotNilAndNotEmpty(tels[R.head(dropTels)])) {
    const lastPickup = R.compose(
      R.last,
      R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
      R.filter((event: Object) => R.and(
        R.or(event.cloEvent, R.equals(R.prop('stopType', event), GC.STOP_TYPE_TERMINAL)),
        R.equals(event.eventType, GC.EVENT_TYPE_PICKUP),
      )),
      R.values,
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tels[R.head(dropTels)]);
    if (G.isNotNilAndNotEmpty(lastPickup)) {
      const lastEventDate = R.or(G.getStopLateDate(lastPickup), G.getStopEarlyDate(lastPickup));
      dates = {
        eventLateDate: G.addMomentTimeWithFormat(lastEventDate, 8),
        eventEarlyDate: G.addMomentTimeWithFormat(lastEventDate, 8),
      };
    }
  }

  return dates;
};

export const sortTelEvents = (events: Object) => (
  R.sort(
    (prevStop: Object, stop: Object) => R.subtract(prevStop.telEventIndex, stop.telEventIndex),
    R.values(events),
  )
);

export const sortTelEventsByDate = (events: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_GUID)),
  G.mapIndexed((item: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), item)),
  R.sort((prevEvent: Object, event: Object) => (
    G.getDateRange(G.getStopEarlyDate(event), G.getStopEarlyDate(prevEvent), GC.DEFAULT_DATE_TIME_FORMAT)
  )),
  R.values,
)(events);

export const isEventItemsPickedUp = (event: Object, tel: Object) => R.compose(
  R.equals(R.prop(GC.FIELD_STOP_ITEM_IDS, event)),
  R.filter((id: string) => G.isNotNil(R.find(
    R.equals(id),
    R.prop(GC.FIELD_STOP_ITEM_IDS, event),
  ))),
  R.reduce((acc: Array, stop: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_ITEM_IDS, stop)), []),
  R.filter((stop: Object) => G.isStopPickup(stop)),
  R.values,
  R.prop(GC.FIELD_LOAD_STOPS),
)(tel);

export const isEventItemsDropped = (event: Object, tel: Object) => R.compose(
  R.equals(R.prop(GC.FIELD_STOP_ITEM_IDS, event)),
  R.filter((id: string) => G.isNotNil(R.find(
    R.equals(id),
    R.prop(GC.FIELD_STOP_ITEM_IDS, event),
  ))),
  R.reduce((acc: Array, stop: Object) => R.concat(acc, R.prop(GC.FIELD_STOP_ITEM_IDS, stop)), []),
  R.filter((stop: Object) => G.isStopDrop(stop)),
  R.values,
  R.prop(GC.FIELD_LOAD_STOPS),
)(tel);

export const isPlannerEmpty = (currentRoute: Object) => {
  const { tels, deletedTelGuids } = currentRoute;
  const telsArray = R.values(tels);

  if (G.isNotNilAndNotEmpty(deletedTelGuids)) return false;

  if (R.isEmpty(telsArray)) return true;

  return R.and(
    R.equals(R.length(telsArray), 1),
    R.isEmpty(R.values(R.prop(GC.FIELD_LOAD_STOPS, R.head(telsArray)))),
  );
};
// COMMON

// SAGA
export const getInitialReportFromResponse = (reports: Array, guid: string, routeGuid: string) => {
  const defaultReport = R.or(R.find(R.pathEq(true, ['defaultReport']))(reports), reports[0]);

  return {
    withSearchCriteria: G.ifElse(
      G.isNilOrEmpty(routeGuid),
      false,
      R.assoc(
        'searchCriteria',
        R.of(Array, {
          dataType: 'string',
          operation: 'equal',
          stringValue: routeGuid,
          propertyName: 'route.guid',
        }),
        defaultReport,
      ),
    ),
    empty: defaultReport,
    report: defaultReport,
  };
};

export const getReportFromReportsResponse = (reports: Array) => R.or(
  R.find(R.pathEq(true, ['defaultReport']))(reports),
  R.head(reports),
);

export const getItemsByIds = (ids: Array, items: Object, isNewEvent: boolean) => {
  const commonFields = ['isNew', 'selected', 'telGuid', 'cloGuid', GC.FIELD_BRANCH_GUID];
  const omitFields = G.ifElse(
    isNewEvent,
    ['eventGuid', ...commonFields],
    commonFields,
  );

  return R.map((id: string) => {
    const item = items[id];

    if (G.isTrue(R.path(['isNew'], item))) {
      return R.omit([GC.FIELD_GUID, ...omitFields], item);
    }

    return R.omit(omitFields, item);
  }, ids);
};

export const getEventWithItems = (event: Object, currentRouteItems: Object) => R.assoc(
  'items',
  getItemsByIds(event.itemIds, currentRouteItems, R.prop('isNew', event)),
  event,
);

export const transformEventsForRequest = (tel: Object, currentRouteItems: Object) => R.assoc(
  GC.FIELD_LOAD_STOPS,
  R.map(
    (stop: Object) => {
      const event = G.formatStopDateTimeValues(G.mapObjectEmptyStringFieldsToNull(stop));
      const pathToOperationHour = [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_OPERATION_HOUR];
      const eventWithOperationHour = R.assocPath(
        pathToOperationHour,
        R.compose(
          G.convertOperationHoursToDefaultFormat,
          R.pathOr([], pathToOperationHour),
        )(event),
        event,
      );
      let eventWithItems = getEventWithItems(eventWithOperationHour, currentRouteItems);

      if (G.isTrue(R.prop('isNew', tel))) {
        eventWithItems = R.omit([GC.FIELD_TEL_GUID], eventWithItems);
      }

      if (G.isTrue(R.prop('isNew', eventWithItems))) {
        return R.omit(['isNew', GC.FIELD_GUID], eventWithItems);
      }

      return eventWithItems;
    },
    R.values(tel[GC.FIELD_LOAD_STOPS]),
  ),
  tel,
);

export const transformTelRateForRequest = (tel: Object, initRate: any) => {
  const rate = R.prop('rate', tel);

  if (R.equals(rate, initRate)) return R.omit([GC.FIELD_RATE], tel);

  if (G.isNilOrEmpty(rate)) return tel;

  if (G.isNotNilAndNotEmpty(R.prop('carrierAssignment', rate))) {
    return G.renameKeys({ rate: 'carrierRate' }, tel);
  }

  if (G.isNilOrEmpty(R.prop('fleetAssignment', rate))) {
    return R.assoc(
      'fleetRate',
      R.assoc(
        'fleetAssignment',
        R.pick(GC.GROUPED_FIELDS.FLEET_ASSIGNMENT_PICK_ARR, rate),
        rate,
      ),
      R.omit('rate', tel),
    );
  }

  return G.renameKeys({ rate: 'fleetRate' }, tel);
};

const getPrimaryReferenceValue = (telWithRate: Object, telPrimaryRefAuto: any, primaryReferenceTypeGuid: any) => {
  if (R.and(G.isTrue(telPrimaryRefAuto), G.isNilOrEmpty(primaryReferenceTypeGuid))) return null;

  return R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_VALUE], telWithRate);
};

export const transformTelsForRequest = (
  route: Object,
  currentRouteItems: Object,
  telPrimaryRefAuto: any,
  initialRoute: Object,
) => R.assoc(
  GC.SYSTEM_LIST_TELS,
  R.compose(
    R.map((tel: Object) => {
      const telWithEvents = transformEventsForRequest(tel, currentRouteItems);
      const telWithRate = transformTelRateForRequest(
        telWithEvents,
        R.path([GC.SYSTEM_LIST_TELS, G.getGuidFromObject(tel), GC.FIELD_RATE], initialRoute),
      );
      const primaryReferenceTypeGuid = R.path([GC.FIELD_PRIMARY_REFERENCE, GC.FIELD_REFERENCE_TYPE_GUID], telWithRate);
      const telWithPrimaryReference = R.mergeRight(
        telWithRate,
        {
          primaryReferenceTypeGuid,
          [C.TEL_INDEX_IN_ROUTE]: tel.order,
          primaryReferenceValue: getPrimaryReferenceValue(telWithRate, telPrimaryRefAuto, primaryReferenceTypeGuid),
        },
      );

      if (G.isTrue(R.prop('isNew', telWithPrimaryReference))) {
        return R.omit(['isNew', GC.FIELD_GUID], telWithPrimaryReference);
      }

      return telWithPrimaryReference;
    }),
    R.filter((tel: Object) => G.isNotNilAndNotEmpty(R.values(tel[GC.FIELD_LOAD_STOPS]))),
  )(R.values(route.tels)),
  route,
);

export const setTelEventItemsForRateAssignRequest = (telEvents: Array, currentRouteItems: Object) => R.map(
  (event: Object) => getEventWithItems(event, currentRouteItems),
  telEvents,
);

export const transformCurrentRouteForRequest = (
  route: Object,
  currentRouteItems: Object,
  telPrimaryRefAuto: any,
  initialRoute: Object,
) => (
  R.omit(
    ['isNew', GC.SYSTEM_LIST_CLOS],
    transformTelsForRequest(route, currentRouteItems, telPrimaryRefAuto, initialRoute),
  )
);

const keysMap = {
  distance: GC.FIELD_DISTANCE_SYSTEM,
  unit: GC.FIELD_DISTANCE_SYSTEM_UOM,
};

const emptyDistance = {
  [GC.FIELD_DISTANCE_SYSTEM]: null,
  [GC.FIELD_DISTANCE_SYSTEM_UOM]: null,
};

export const mapLoadEventsWithDistances = (
  load: Object,
  loadType: string,
  distanceRes: Object,
) => {
  const distancesArr = R.compose(
    R.append(emptyDistance),
    R.map((item: Object) => G.renameKeys(keysMap, item)),
    R.map((item: Object) => R.pick(['distance', 'unit'], item)),
    R.prop('stopResults'),
  )(distanceRes);
  const events = R.compose(
    R.sortBy(R.prop(G.getEventIndexPropByLoadType(loadType))),
    R.values(),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(load);
  const zippedEvents = R.zipWith((event: Object, distance: Object) => {
    const distanceToNextStop = R.prop(G.getDistancePropByLoadType(loadType), event);
    const newDistanceToNextStop = R.mergeRight(distanceToNextStop, distance);

    return R.assoc(G.getDistancePropByLoadType(loadType), newDistanceToNextStop, event);
  }, events, distancesArr);

  return R.indexBy(R.prop(GC.FIELD_GUID), zippedEvents);
};

export const getLoadEditModeStopsGeodata = (load: Object) => {
  const { events, loadType } = load;

  return R.compose(
    R.reduce((acc: Array, event: Object) => {
      if (R.isNil(acc)) return null;

      return G.getStopPointsDataAccForDistanceCalculator(event, acc);
    }, []),
    R.sortBy(R.prop(G.getEventIndexPropByLoadType(loadType))),
    R.values(),
  )(events);
};

export const mapRouteItems = (tels: Array = []) => {
  const cloPrimaryReferenceValues = R.reduce(
    (acc: Array, tel: Object) => R.mergeRight(acc, tel.cloPrimaryReferenceValues),
    {},
    tels,
  );
  const items = R.compose(
    R.map((item: Object) => {
      const updatedItem = R.mergeRight(item, {
        isNew: false,
        selected: true,
      });

      if (G.isLoadTypeClo(updatedItem)) {
        return R.assoc(
          C.CLO_NAME,
          R.prop(R.prop(GC.FIELD_LOAD_GUID, updatedItem), cloPrimaryReferenceValues),
          updatedItem,
        );
      }

      return updatedItem;
    }),
    R.uniqBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
  )(concatArrayItemsProp(concatArrayItemsProp(tels, GC.FIELD_LOAD_STOPS), GC.FIELD_LOAD_ITEMS));

  return R.indexBy(
    R.prop(GC.FIELD_ITEM_INTERNAL_ID),
    items,
  );
};

const mapTelEventsWithCloPrimaryRef = (tel: Object) => R.map(
  (event: Object) => {
    if (G.isLoadTypeClo(event)) {
      const cloName = R.path(['cloPrimaryReferenceValues', R.prop(GC.FIELD_LOAD_GUID, event)], tel);

      return R.mergeRight(
        event,
        {
          [C.CLO_NAME]: cloName,
          [GC.FIELD_LOAD_ITEMS]: R.map(R.assoc(C.CLO_NAME, cloName), R.prop(GC.FIELD_LOAD_ITEMS, event)),
        },
      );
    }

    return event;
  },
  tel.events,
);

const extendRouteTel = (tel: Object, index: number) => {
  const { order, references, primaryReferenceValue } = tel;

  return R.mergeRight(
    tel,
    {
      [GC.FIELD_LOAD_STOPS]: mapTelEventsWithCloPrimaryRef(tel),
      [GC.FIELD_ORDER]: G.ifElse(G.isNumber(order), order, index),
      [GC.FIELD_PRIMARY_REFERENCE]: R.pathOr(
        R.find(R.prop(GC.FIELD_PRIMARY), R.or(references, [])),
        [GC.FIELD_PRIMARY_REFERENCE],
        tel,
      ),
      loadName: G.ifElse(
        G.isNotNilAndNotEmpty(R.prop(GC.FIELD_PRIMARY_REFERENCE_VALUE, tel)),
        primaryReferenceValue,
        getLoadName(tel),
      ),
    },
  );
};

export const convertTelResponse = ({ tel, indexAdditional = 0 }: Object) => {
  const tels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((tel: Object, index: number) => indexObjectArrayPropByPropName(
      GC.FIELD_GUID,
      GC.FIELD_LOAD_STOPS,
      extendRouteTel(tel, R.add(index, indexAdditional)),
    )),
    R.of(Array),
  )(tel);

  return { tels };
};

export const getCloEventGuidsFromCurrentRouteWithoutSelected = (currentRoute: Object, guids: Array) => R.compose(
  R.without(guids),
  R.map(G.getGuidFromObject),
  R.filter((stop: Object) => G.isTrue(R.path([GC.FIELD_CLO_EVENT], stop))),
  R.reduce((acc: Array, tel: Object) => R.concat(acc, R.values(R.prop(GC.FIELD_LOAD_STOPS, tel))), []),
  R.values,
  R.prop(GC.SYSTEM_LIST_TELS),
)(currentRoute);

export const getEventGuidsFromListAndRoute = (events: Array, currentRoute: Object) => R.compose(
  R.uniq,
  R.concat(R.map(R.prop(GC.FIELD_GUID), events)),
  R.map(R.prop(GC.FIELD_GUID)),
  R.filter((stop: Object) => G.isTrue(R.path([GC.FIELD_CLO_EVENT], stop))),
  R.reduce((acc: Array, tel: Object) => R.concat(acc, R.values(R.prop(GC.FIELD_LOAD_STOPS, tel))), []),
  R.values,
  R.prop(GC.SYSTEM_LIST_TELS),
)(currentRoute);

export const getRateAndEventGuidsFromCurrentRoute = ({ tels }: Object) => {
  const eventGuids = R.compose(
    R.map(G.getGuidFromObject),
    R.filter((event: Object) => R.not(R.prop('isNew', event))),
    R.reduce((acc: Array, tel: Object) => R.concat(acc, R.values(R.prop(GC.FIELD_LOAD_STOPS, tel))), []),
    R.values,
  )(tels);
  const rateGuids = R.compose(
    R.map(({ rate }: Object) => G.getGuidFromObject(rate)),
    R.filter((tel: Object) => R.and(
      G.isNotNilAndNotEmpty(R.path([GC.SYSTEM_OBJECT_RATE, GC.FIELD_GUID], tel)),
      R.not(R.path([GC.SYSTEM_OBJECT_RATE, 'isNew'], tel)),
    )),
    R.values,
  )(tels);

  return { rateGuids, eventGuids };
};
// SAGA

// REDUCER
export const setSelectedPropIfExist = (tels: any, data: Array) => {
  if (G.isNilOrEmpty(tels)) return tels;

  return tels.map((tel: Object) => R.assoc(
    'selected',
    G.ifElse(R.includes(R.prop(GC.FIELD_GUID, tel), data), true, G.getPropFromObject('selected', tel)),
    tel,
  ));
};

export const selectTelItems = (tels: Array, ids: Array) => (
  R.map((tel: Object) => (
    P.$set(
      'selected',
      G.isNotNilAndNotEmpty(R.find(
        (id: string) => R.equals(R.prop('guid', tel), id),
        ids,
      )),
      tel,
    )
  ), tels)
);

export const selectItem = (list: Array, id: string, value: boolean = false) => (
  R.map((item: Object) => (
    P.$set(
      'selected',
      G.ifElse(
        R.equals(R.prop('guid', item), id),
        R.or(value, R.not(R.prop('selected', item))),
        R.prop('selected', item),
      ),
      item,
    )
  ), list)
);

export const filterCurrentRouteItems = (items: Object={}, eventItems: Array=[]) => R.omit(
  R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), eventItems),
  items,
);

export const resetCloListToInitialRoute = (currentRoute: Object, cloList: Array) => (
  R.map(
    (clo: Object) => R.assoc(
      'selected',
      G.isNotNilAndNotEmpty(R.path(['clos', R.prop('guid', clo)], currentRoute)),
      clo,
    ),
    cloList,
  )
);

export const createNewRouteTel = (options: Object) => (
  R.mergeRight(
    {
      rate: null,
      events: {},
      isNew: true,
      guid: G.generateGuid(),
      loadType: GC.LOAD_TYPE_TEL,
    },
    options,
  )
);

const getLocationType = ({ locationType }: Object) => {
  if (G.isNilOrEmpty(locationType)) return null;

  if (G.isString(locationType)) return locationType;

  return R.omit([GC.FIELD_GUID], locationType);
};

export const omitLocationFields = (location: Object) => R.omit(
  R.concat(GC.GROUPED_FIELDS.EVENT_DATES_ARR, GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
  R.mergeRight(location, {
    [GC.FIELD_LOCATION_TYPE]: getLocationType(location),
    [GC.FIELD_CONTACTS]: R.map(
      R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR),
      R.pathOr([], [GC.FIELD_CONTACTS], location),
    ),
    [GC.FIELD_OPERATION_HOUR]: R.map(
      R.omit([GC.FIELD_GUID]),
      R.pathOr([], [GC.FIELD_OPERATION_HOUR], location),
    ),
  }),
);

export const createNewStopOnRouteTel = (options: Object) => ({
  items: [],
  itemIds: [],
  isNew: true, // TODO: check it
  cloGuid: null,
  comments: null,
  cloEvent: false,
  completed: false,
  trailerGuids: [],
  drop: options.drop,
  cloEventIndex: null,
  appointmentDate: null,
  pickup: options.pickup,
  guid: G.generateGuid(),
  eventCompleteDate: null,
  appointmentNumber: null,
  telGuid: options.telGuid,
  appointmentLateTime: null,
  appointmentEarlyTime: null,
  cloDistanceToNextStop: null,
  telDistanceToNextStop: null, // TODO: with Object
  eventType: options.eventType,
  telEventIndex: options.telEventIndex,
  eventLateDate: options.eventLateDate,
  eventEarlyDate: options.eventEarlyDate,
  location: omitLocationFields(options.location),
  stopType: R.or(options.stopType, GC.STOP_TYPE_INDEPENDENT),
  // trailers
  [GC.FIELD_STOP_DROPPED_TRAILERS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILERS]: [],
  [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]: [],
});

export const createNewTerminalOnRouteTel = (options: Object) => {
  const newStop = createNewStopOnRouteTel(options);
  const guid = G.generateGuid();

  return {
    ...newStop,
    guid,
    stopId: options.stopId,
    stopType: R.or(options.stopType, GC.STOP_TYPE_TERMINAL),
  };
};

export const getNextTelEventIndex = (telGuid: string, state: Object) => {
  const length = R.length(
    R.values(R.pathOr({}, ['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS], state),
  ));

  return R.add(length, 1);
};

export const getLocationByStopType = (location: Object, stopType: string) => {
  if (R.path(['isNew'], location)) {
    return omitLocationFields(location);
  }

  return R.omit(GC.GROUPED_FIELDS.EVENT_DATES_ARR, location);
};

export const updateEventByStopType = (event: Object) => {
  if (R.pathEq(GC.STOP_TYPE_TERMINAL, [GC.FIELD_STOP_TYPE], event)) {
    return R.assoc(
      'location',
      R.omit(GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR, event.location),
      event,
    );
  }

  return event;
};

export const generateDataForNewTelStopFromStopForm = (data: Object, state: Object) => {
  const { location, loadGuid, eventType } = data;

  const telGuid = loadGuid;

  const drop = G.isEventTypeDrop(eventType);
  const pickup = G.isEventTypePickup(eventType);
  const telEventIndex = getNextTelEventIndex(telGuid, state);
  const eventLateDate = R.propOr(null, GC.FIELD_LOAD_EVENT_LATE_DATE, location);
  const eventEarlyDate = R.propOr(null, GC.FIELD_LOAD_EVENT_EARLY_DATE, location);

  return {
    drop,
    pickup,
    telGuid,
    location,
    eventType,
    telEventIndex,
    eventLateDate,
    eventEarlyDate,
  };
};

export const createNewCloStopOnRouteTel = (options: Object) => ({
  isNew: true,
  [GC.FIELD_STOP_ITEMS]: [],
  [GC.FIELD_COMMENTS]: null,
  [GC.FIELD_CLO_EVENT]: true,
  [GC.FIELD_STOP_TYPE]: null,
  [GC.FIELD_COMPLETED]: false,
  [GC.FIELD_STOP_ITEM_IDS]: [],
  [GC.FIELD_TRAILER_GUIDS]: [],
  [GC.FIELD_DISTANCE_CLO]: null,
  [GC.FIELD_DISTANCE_TEL]: null,
  [GC.FIELD_CLO_EVENT_INDEX]: null,
  [GC.FIELD_GUID]: G.generateGuid(),
  [GC.FIELD_EVENT_COMPLETE_DATE]: null,
  [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  [GC.FIELD_LOAD_APPOINTMENT_DATE]: null,
  [GC.FIELD_LOAD_APPOINTMENT_NUMBER]: null,
  [GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]: null,
  [GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME]: null,
  [GC.FIELD_LOCATION]: R.prop(GC.FIELD_LOCATION, options),
  [GC.FIELD_CLO_GUID]: R.prop(GC.FIELD_CLO_GUID, options),
  [GC.FIELD_TEL_GUID]: R.prop(GC.FIELD_TEL_GUID, options),
  [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_CLO_GUID, options),
  [GC.FIELD_STOP_DROP]: R.prop(GC.FIELD_STOP_DROP, options),
  [GC.FIELD_EVENT_TYPE]: R.prop(GC.FIELD_EVENT_TYPE, options),
  [GC.FIELD_STOP_PICKUP]: R.prop(GC.FIELD_STOP_PICKUP, options),
  [GC.FIELD_TEL_EVENT_INDEX]: R.prop(GC.FIELD_TEL_EVENT_INDEX, options),
  [GC.FIELD_LOAD_EVENT_LATE_DATE]: R.prop(GC.FIELD_LOAD_EVENT_LATE_DATE, options),
  [GC.FIELD_LOAD_EVENT_EARLY_DATE]: R.prop(GC.FIELD_LOAD_EVENT_EARLY_DATE, options),
  // trailers
  [GC.FIELD_STOP_DROPPED_TRAILERS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILERS]: [],
  [GC.FIELD_STOP_DROPPED_TRAILER_GUIDS]: [],
  [GC.FIELD_STOP_PICKED_UP_TRAILER_GUIDS]: [],
});

export const generateDataForNewCloStopFromStopForm = (data: Object, state: Object) => {
  const { telGuid, loadGuid, location, eventType } = data;
  const telEventIndex = getNextTelEventIndex(telGuid, state);
  const drop = G.isEventTypeDrop(eventType);
  const pickup = G.isEventTypePickup(eventType);
  const locationData = omitLocationFields(location);
  const eventEarlyDate = R.propOr(null, GC.FIELD_LOAD_EVENT_EARLY_DATE, location);
  const eventLateDate = R.propOr(null, GC.FIELD_LOAD_EVENT_LATE_DATE, location);

  return {
    drop,
    pickup,
    telGuid,
    loadGuid,
    eventType,
    telEventIndex,
    eventLateDate,
    eventEarlyDate,
    cloGuid: loadGuid,
    location: locationData,
  };
};

const getCloItemsFromStoreByEventTypeAndTelGuid = (eventType: string, telGuid: string, state: Object) => R.compose(
  R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
  R.filter((event: Object) => R.and(
    R.propEq(eventType, GC.FIELD_EVENT_TYPE, event),
    R.propEq(GC.LOAD_TYPE_CLO, GC.FIELD_LOAD_TYPE, event),
  )),
  R.values,
  R.path(['currentRoute', 'tels', telGuid, 'events']),
)(state);

const getItemsFromStoreByEventTypeAndTelGuid = (eventType: string, telGuid: string, state: Object) => R.compose(
  R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
  R.filter(R.propEq(eventType, GC.FIELD_EVENT_TYPE)),
  R.values,
  R.path(['currentRoute', 'tels', telGuid, 'events']),
)(state);

const filterItems = (items1: Array=[], items2: Array=[]) => R.filter(
  (item: Object) => R.isNil(R.find(R.eqProps(GC.FIELD_GUID, item), items2)),
  items1,
);

export const addTerminalDropsToCurrentRouteTels = (
  stopId: string,
  data: Object,
  state: Object,
) => {
  const dropTelsGuids = R.pathOr([], ['returnObject', 'dropTels'], data);
  const stopData = R.compose(
    R.assoc('eventType', GC.EVENT_TYPE_DROP),
    R.omit('returnObject'),
  )(data);
  const terminalDrops = R.compose(
    R.indexBy(R.prop(GC.FIELD_TEL_GUID)),
    R.filter(G.isNotNil),
    R.map((telGuid: string) => {
      const terminalDropExists = R.compose(
        R.find((telEvent: Object) => G.isAllTrue(
          G.isStopDrop(telEvent),
          G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
          R.equals(
            R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], data),
            R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent),
          ),
        )),
        R.values,
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS]),
      )(state);

      if (G.isNotNil(terminalDropExists)) return null;

      const stopDataWithTelGuid = R.assoc('loadGuid', telGuid, stopData);
      const stopOptions = generateDataForNewTelStopFromStopForm(stopDataWithTelGuid, state);
      let newTerminal = createNewTerminalOnRouteTel(R.assoc('stopId', stopId, stopOptions));
      const cloPickupItems = getCloItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_PICKUP, telGuid, state);
      const cloDropItems = getItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_DROP, telGuid, state);
      const availableItems = filterItems(cloPickupItems, cloDropItems);

      if (G.isNotNilAndNotEmpty(availableItems)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            items: availableItems,
            itemIds: R.map((item: Object) => item.itemInternalId, availableItems),
          },
        );
      }

      return newTerminal;
    }),
  )(dropTelsGuids);
  const newCurrentRouteTels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((tel: Object) => {
      const telGuid = R.prop(GC.FIELD_GUID, tel);

      if (R.includes(telGuid, dropTelsGuids)) {
        const terminalDrop = R.pathOr(null, [telGuid], terminalDrops);

        if (R.isNil(terminalDrop)) return tel;

        return R.assocPath([GC.FIELD_LOAD_STOPS, R.prop(GC.FIELD_GUID, terminalDrop)], terminalDrop, tel);
      }

      return tel;
    }),
    R.values,
    R.path(['currentRoute', 'tels']),
  )(state);

  return P.$set('currentRoute.tels', newCurrentRouteTels, state);
};


export const addTerminalPickupsToCurrentRouteTels = (
  stopId: string,
  data: Object,
  state: Object,
) => {
  const pickupTelsGuids = R.pathOr([], ['returnObject', 'pickupTels'], data);
  const stopData = R.compose(
    R.assoc('eventType', GC.EVENT_TYPE_PICKUP),
    R.omit('returnObject'),
  )(data);
  const terminalPickups = R.compose(
    R.indexBy(R.prop(GC.FIELD_TEL_GUID)),
    R.filter(G.isNotNil),
    R.map((telGuid: string) => {
      const terminalPickupExists = R.compose(
        R.find((telEvent: Object) => G.isAllTrue(
          G.isStopPickup(telEvent),
          G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
          R.equals(
            R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], data),
            R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent),
          ),
        )),
        R.values,
        R.path(['currentRoute', 'tels', telGuid, GC.FIELD_LOAD_STOPS]),
      )(state);

      if (G.isNotNil(terminalPickupExists)) return null;

      const stopDataWithTelGuid = R.assoc('loadGuid', telGuid, stopData);
      const stopOptions = generateDataForNewTelStopFromStopForm(stopDataWithTelGuid, state);
      let newTerminal = createNewTerminalOnRouteTel(R.assoc('stopId', stopId, stopOptions));
      const cloDropItems = getCloItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_DROP, telGuid, state);
      const cloPickupItems = getItemsFromStoreByEventTypeAndTelGuid(GC.EVENT_TYPE_PICKUP, telGuid, state);
      const availableItems = filterItems(cloDropItems, cloPickupItems);

      if (G.isNotNilAndNotEmpty(availableItems)) {
        newTerminal = R.mergeRight(
          newTerminal,
          {
            items: availableItems,
            itemIds: R.map((item: Object) => item.itemInternalId, availableItems),
          },
        );
      }

      return newTerminal;
    }),
  )(pickupTelsGuids);
  const newCurrentRouteTels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.map((tel: Object) => {
      const telGuid = R.prop(GC.FIELD_GUID, tel);

      if (R.includes(telGuid, pickupTelsGuids)) {
        const terminalPickup = R.pathOr(null, [telGuid], terminalPickups);

        if (R.isNil(terminalPickup)) return tel;
        const newTel = R.assocPath([GC.FIELD_LOAD_STOPS, R.prop(GC.FIELD_GUID, terminalPickup)], terminalPickup, tel);
        const newTelEvents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
          R.map((event: Object) => {
            if (R.equals(event.guid, terminalPickup.guid)) {
              return R.assoc(GC.FIELD_TEL_EVENT_INDEX, 1, event);
            }

            return R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(event.telEventIndex), event);
          }),
          R.values,
          R.prop(GC.FIELD_LOAD_STOPS),
        )(newTel);

        return R.assoc(GC.FIELD_LOAD_STOPS, newTelEvents, newTel);
      }

      return tel;
    }),
    R.values,
    R.path(['currentRoute', 'tels']),
  )(state);

  return P.$set('currentRoute.tels', newCurrentRouteTels, state);
};

export const updateCurrentRouteTelsTerminalStops = (
  eventToUpdate: Object,
  newLocation: Object,
  state: Object,
) => {
  const { stopType, stopId } = eventToUpdate;

  if (R.equals(stopType, GC.STOP_TYPE_TERMINAL)) {
    const newCurrentRouteTels = R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      R.map((tel: Object) => {
        const newTelEvents = R.compose(
          R.indexBy(R.prop(GC.FIELD_GUID)),
          R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
          R.map((event: Object) => {
            if (R.equals(stopId, R.prop('stopId', event))) {
              return R.assoc('location', R.omit(['contacts', 'comments'], newLocation), event);
            }

            return event;
          }),
          R.values(),
          R.prop(GC.FIELD_LOAD_STOPS),
        )(tel);

        return R.assoc(GC.FIELD_LOAD_STOPS, newTelEvents, tel);
      }),
      R.values(),
      R.path(['currentRoute', 'tels']),
    )(state);

    return P.$set('currentRoute.tels', newCurrentRouteTels, state);
  }

  return state;
};

export const createTerminalStopWithExistedStopId = (options: Object) => {
  const newStop = createNewStopOnRouteTel(options);

  return {
    ...newStop,
    stopId: options.stopId,
    stopType: GC.STOP_TYPE_TERMINAL,
  };
};

export const getFirstTerminalEventLocationByStopIdFromState = (stopId: Object, state: Object) => R.compose(
  R.prop('location'),
  R.head(),
  R.reduce((acc: Array, item: Array) => R.concat(acc, item), []),
  R.map((tel: Object) => R.compose(
    R.filter(R.propEq(stopId, 'stopId')),
    R.values(),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel)),
  R.values(),
  R.path(['currentRoute', 'tels']),
)(state);

export const isEqualSourceAndDestinationLoad = (result: Object) => (
  R.equals(
    R.path(['source', 'droppableId'], result),
    R.path(['destination', 'droppableId'], result),
  )
);

export const recreateTelEventsAfterDnD = R.compose(
  R.indexBy(R.prop(GC.FIELD_GUID)),
  R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
  G.mapIndexed((event: Object, index: number) => R.assoc('telEventIndex', R.inc(index), event)),
);

export const getIndexesFromDnDResult = (result: Object) => {
  const sourceIndex = R.path(['source', 'index'], result);
  const destinationIndex = R.path(['destination', 'index'], result);
  const destinationIndexChecked = G.ifElse(
    R.equals(destinationIndex, -1),
    0,
    destinationIndex,
  );

  return {
    sourceIndex,
    destinationIndexChecked,
  };
};

export const reorderTelEvents = (
  sourceIndex: number,
  destinationIndex: number,
  events: Object,
) => {
  const eventsArray = R.values(events);
  const [removedEvent] = eventsArray.splice(sourceIndex, 1);
  eventsArray.splice(destinationIndex, 0, removedEvent);

  return recreateTelEventsAfterDnD(eventsArray);
};

export const reorderTelEventsInState = (state: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const telEventsState = R.path(['currentRoute', 'tels', sourceTelGuid, GC.FIELD_LOAD_STOPS], state);
  const newTelEvents = reorderTelEvents(sourceIndex, destinationIndexChecked, telEventsState);

  return P.$set(
    `currentRoute.tels.${sourceTelGuid}.events`,
    newTelEvents,
    state,
  );
};

export const reorderTelEventsInCurrentRoute = (currentRoute: Object, result: Object) => {
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const telEventsState = R.path(['tels', sourceTelGuid, GC.FIELD_LOAD_STOPS], currentRoute);
  const newTelEvents = reorderTelEvents(sourceIndex, destinationIndexChecked, telEventsState);

  return P.$set(
    `tels.${sourceTelGuid}.events`,
    newTelEvents,
    currentRoute,
  );
};

const getCloItemsFromEventsByEventType = (events: Array, eventType: string) => R.compose(
  R.reduce((acc: Array, event: Object) => R.concat(acc, event.items), []),
  R.filter((event: Object) => R.and(
    R.propEq(eventType, GC.FIELD_EVENT_TYPE, event),
    R.propEq(GC.LOAD_TYPE_CLO, GC.FIELD_LOAD_TYPE, event),
  )),
)(events);

const checkTelEventsItems = (telEvents: Array) => {
  const terminals = R.compose(
    R.map((terminal: Object) => {
      const cloPickupItems = getCloItemsFromEventsByEventType(telEvents, GC.EVENT_TYPE_PICKUP);
      const cloDropItems = getCloItemsFromEventsByEventType(telEvents, GC.EVENT_TYPE_DROP);
      let availableItems;
      if (G.isStopPickup(terminal)) {
        availableItems = filterItems(cloDropItems, cloPickupItems);
      } else {
        availableItems = filterItems(cloPickupItems, cloDropItems);
      }

      return R.mergeRight(
        terminal,
        {
          items: availableItems,
          itemIds: R.map((item: Object) => item.itemInternalId, availableItems),
        },
      );
    }),
    R.filter(R.propEq(GC.STOP_TYPE_TERMINAL, GC.FIELD_STOP_TYPE)),
  )(telEvents);

  if (R.isEmpty(terminals)) return telEvents;

  return R.map(
    (event: Object) => {
      const existInTerminals = R.find(R.eqProps(GC.FIELD_GUID, event), terminals);

      if (existInTerminals) return existInTerminals;

      return event;
    },
    telEvents,
  );
};

const checkDestinationTelEventsItems = (telEvents: Array, { items, eventType }: Object) => {
  const isPickup = G.isEventTypePickup(eventType);
  const terminalsCount = R.length(R.filter(
    (stop: Object) => R.and(
      (R.propEq(GC.STOP_TYPE_TERMINAL, GC.FIELD_STOP_TYPE), stop),
      G.ifElse(isPickup, G.isStopDrop(stop), G.isStopPickup(stop)),
    ),
    telEvents,
  ));

  if ((G.notEquals(terminalsCount, 1))) return telEvents;

  const terminals = R.compose(
    R.map((terminal: Object) => {
      if (R.equals(isPickup, G.isStopPickup(terminal))) return terminal;

      const newItems = R.concat(terminal.items, items);

      return R.mergeRight(
        terminal,
        {
          items: newItems,
          itemIds: R.map((item: Object) => item.itemInternalId, newItems),
        },
      );
    }),
    R.filter(R.propEq(GC.STOP_TYPE_TERMINAL, GC.FIELD_STOP_TYPE)),
  )(telEvents);

  if ((G.notEquals(R.length(terminals), 1))) return telEvents;

  return R.map(
    (event: Object) => {
      const existInTerminals = R.find(R.eqProps(GC.FIELD_GUID, event), terminals);

      if (existInTerminals) return existInTerminals;

      return event;
    },
    telEvents,
  );
};

const checkSourceTelEventsItems = (events: Array, movedStop: Object) => R.map(
  (event: Object) => {
    const { stopType } = event;

    if (R.not(G.isStopTypeTerminal(stopType))) return event;

    const { itemIds } = movedStop;

    const items = R.filter(
      ({ itemInternalId }: Object) => G.notContain(itemInternalId, itemIds),
    )(event.items);

    return R.mergeRight(event, {
      items,
      itemIds: R.map((item: Object) => item.itemInternalId, items),
    });
  },
)(events);

export const moveEventFromTelToTel = (state: Object, result: Object, movedStop: Object) => {
  const sourceTelGuid = R.path(['source', 'droppableId'], result);
  const destinationTelGuid = R.path(['destination', 'droppableId'], result);
  const sourceTelEventsArray = R.values(R.path(
    ['currentRoute', 'tels', sourceTelGuid, GC.FIELD_LOAD_STOPS],
    state,
  ));
  const destinationTelEventsArray = R.values(R.path(
    ['currentRoute', 'tels', destinationTelGuid, GC.FIELD_LOAD_STOPS],
    state,
  ));
  const { sourceIndex, destinationIndexChecked } = getIndexesFromDnDResult(result);
  const [removedEvent] = sourceTelEventsArray.splice(sourceIndex, 1);
  destinationTelEventsArray.splice(destinationIndexChecked, 0, removedEvent);
  const sourceEvents = checkSourceTelEventsItems(sourceTelEventsArray, movedStop);
  const destinationEvents = checkDestinationTelEventsItems(destinationTelEventsArray, movedStop);

  return P.$all(
    P.$set(
      `currentRoute.tels.${sourceTelGuid}.events`,
      recreateTelEventsAfterDnD(sourceEvents),
    ),
    P.$set(
      `currentRoute.tels.${destinationTelGuid}.events`,
      recreateTelEventsAfterDnD(R.map(
        R.assoc('telGuid', destinationTelGuid),
        destinationEvents,
      )),
    ),
    state,
  );
};

const mapCloEvents = (eventsCount: number, events: Array, telGuid: string) => G.mapIndexed(
  (event: Object, index: number) => R.assoc(
    GC.FIELD_TEL_GUID,
    telGuid,
    R.assoc(
      GC.FIELD_TEL_EVENT_INDEX,
      R.add(
        R.inc(index),
        eventsCount,
      ),
      event,
    ),
  ), events);

export const createNewEventsForTel = (cloEvents: Object, tel: Object) => {
  const telEvents = R.values(R.prop(GC.FIELD_LOAD_STOPS, tel));
  const telEventsCount = R.length(telEvents);
  const mappedCloEvents = mapCloEvents(
    telEventsCount,
    cloEvents,
    R.prop(GC.FIELD_GUID, tel),
  );

  return R.indexBy(R.prop(GC.FIELD_GUID), R.concat(telEvents, mappedCloEvents));
};

export const addTelItems = (currentItems: Object = {}, items: Object = []) => (
  R.mergeRight(
    currentItems,
    R.indexBy(
      R.prop(GC.FIELD_ITEM_INTERNAL_ID),
      R.map(
        (item: Object) => R.mergeRight({
          isNew: false,
          selected: true,
        }, item),
        items,
      ),
    ),
  )
);

export const getItemsToRemove = (itemsToFilter: Array, tels: Object) => R.filter(
  (itemId: string) => R.not(R.any(
    (tel: Object) => R.any(
      (event: Object) => R.any(
        (id: string) => R.equals(id, itemId),
        event.itemIds,
      ),
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    ),
    R.values(tels),
  )),
  itemsToFilter,
);

const filterAndMergeTelEventItems = (telEvent: Object, itemsToFilter: Array) => R.mergeRight(telEvent, {
  itemIds: R.difference(telEvent.itemIds, itemsToFilter),
  items: R.filter(
    (item: Object) => R.all(
      (id: string) => R.not(R.propEq(id, GC.FIELD_ITEM_INTERNAL_ID, item)),
      itemsToFilter,
    ),
    telEvent.items,
  ),
});

export const getFilteredTels = (
  tels: Object,
  stopTel: Object,
  stop: Object,
  itemsToFilter: Array,
) => {
  const isCloStop = G.isLoadTypeClo(stop);
  const isCloPickup = R.and(isCloStop, G.isStopPickup(stop));
  const isTerminal = G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop));
  const templateId = R.path([GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_TEMPLATE_ID], stop);

  if (isCloStop) {
    return R.map(
      (routeTel: Object) => R.assoc(
        GC.FIELD_LOAD_STOPS,
        R.map(
          (telEvent: Object) => {
            if (R.or(isCloPickup, R.eqProps(GC.FIELD_GUID, telEvent, stop))) {
              return filterAndMergeTelEventItems(telEvent, itemsToFilter);
            }

            return telEvent;
          },
          routeTel[GC.FIELD_LOAD_STOPS],
        ),
        routeTel,
      ),
      tels,
    );
  }

  return R.map(
    (routeTel: Object) => {
      const hasSameTerminal = R.compose(
        R.find(R.pathEq(templateId, [GC.SYSTEM_OBJECT_LOCATION, GC.FIELD_TEMPLATE_ID])),
        R.values,
        R.prop(GC.FIELD_LOAD_STOPS),
      )(routeTel);

      if (R.gte(routeTel.order, stopTel.order)) {
        return R.assoc(
          GC.FIELD_LOAD_STOPS,
          R.map(
            (telEvent: Object) => {
              if (R.or(
                G.isLoadTypeClo(telEvent),
                R.and(
                  R.eqProps(GC.FIELD_GUID, routeTel, stopTel),
                  R.lt(telEvent.telEventIndex, stop.telEventIndex),
                ),
              )) return telEvent;

              if (R.or(
                R.and(isTerminal, hasSameTerminal),
                R.propEq(GC.STOP_TYPE_INDEPENDENT, GC.FIELD_STOP_TYPE, stop),
              )) {
                return filterAndMergeTelEventItems(telEvent, itemsToFilter);
              }

              return telEvent;
            },
            routeTel[GC.FIELD_LOAD_STOPS],
          ),
          routeTel,
        );
      }

      return routeTel;
    },
    tels,
  );
};

export const removeEventItemsFromEvent = (event: Object, removeEvent: string) => R.mergeRight(
  event,
  {
    itemIds: R.without(removeEvent.itemIds, event.itemIds),
    items: R.filter(
      (item: Object) => R.isNil(R.find(R.eqProps(GC.FIELD_GUID, item), removeEvent.items)),
      event.items,
    ),
  },
);

export const getRemoveEventByGuid = (eventGuid: string, tels: Object) => {
  let removeEvent;
  R.forEach(
    (tel: Object) => R.forEach(
      (telEvent: Object) => {
        if (R.propEq(eventGuid, GC.FIELD_GUID, telEvent)) removeEvent = telEvent;
      },
      R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)),
    ),
    R.values(tels),
  );

  return removeEvent;
};

export const removeCloEventFromTels = (removeEvent: Object, tels: Object) => R.mapObjIndexed((tel: Object) => (
  R.assoc(
    GC.FIELD_LOAD_STOPS,
    R.indexBy(
      R.prop(GC.FIELD_GUID),
      sortTelEvents(
        R.filter(
          (event: Object) => R.not(R.eqProps(GC.FIELD_GUID, removeEvent, event)),
          R.prop(GC.FIELD_LOAD_STOPS, tel),
        ),
      ).map(
        (event: Object, index: number) => {
          let eventToUse;

          if (G.isLoadTypeClo(event)) {
            eventToUse = event;
          } else {
            eventToUse = removeEventItemsFromEvent(event, removeEvent);
          }

          return R.assoc(
            GC.FIELD_TEL_EVENT_INDEX,
            R.inc(index),
            eventToUse,
          );
        },
      ),
    ),
    tel,
  )
), tels);

export const addCloEventToInterTerminal = (state: Object, data: Object) => {
  const { event, telGuid, matchEvent } = data;
  const tel = R.path(['currentRoute', GC.SYSTEM_LIST_TELS, telGuid], state);
  const singleTerminalDrop = R.compose(
    R.equals(1),
    R.length,
    R.filter((telEvent: Object) => R.and(
      G.isStopDrop(telEvent),
      G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
    )),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const telEvents = R.compose(
    R.map((telEvent: Object) => {
      const isTerminalDrop = R.and(
        G.isStopDrop(telEvent),
        G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
      );
      const isMatchedTerminalPickup = R.and(
        G.isStopPickup(telEvent),
        R.equals(
          R.prop(GC.GRC.LAST_TERMINAL_DROP_TEMPLATE_ID, event),
          R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent),
        ),
      );
      const isItemsDropped = isEventItemsDropped(matchEvent, tel);

      if (R.or(
        isMatchedTerminalPickup,
        G.isAllTrue(singleTerminalDrop, isTerminalDrop, R.not(isItemsDropped)),
      )) {
        return R.mergeRight(telEvent, {
          [GC.FIELD_STOP_ITEM_IDS]: R.uniq(R.concat(telEvent.itemIds, matchEvent.itemIds)),
          [GC.FIELD_STOP_ITEMS]: R.uniqBy(
            R.prop(GC.FIELD_ITEM_INTERNAL_ID),
            R.concat(telEvent.items, matchEvent.items),
          ),
          [GC.FIELD_RELATED_DROP_GUIDS]: R.uniq(R.append(
            G.getGuidFromObject(event),
            R.prop(GC.FIELD_RELATED_DROP_GUIDS, telEvent),
          )),
        });
      }

      return telEvent;
    }),
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);

  return P.$all(
    P.$set(`currentRoute.tels.${data.telGuid}`, R.assoc(GC.FIELD_LOAD_STOPS, telEvents, tel)),
    P.$set('currentRouteItems', addTelItems(state.currentRouteItems, R.path([GC.FIELD_STOP_ITEMS], matchEvent))),
    state,
  );
};

const getTelEventsWithAddedEvent = (state: Object, matchEvent: Object, tel: Object, templateId: string) => {
  let telEvents;

  if (G.isStopDrop(matchEvent)) {
    telEvents = R.compose(
      R.assoc(R.prop(GC.FIELD_GUID, matchEvent), matchEvent),
      R.map((telEvent: Object) => {
        if (G.isStopDrop(telEvent)) {
          return R.mergeRight(telEvent, {
            itemIds: R.without(matchEvent.itemIds, telEvent.itemIds),
            items: R.filter(
              (item: Object) => R.isNil(R.find(
                R.equals(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item)),
                R.prop(GC.FIELD_STOP_ITEM_IDS, matchEvent),
              )),
              telEvent.items,
            ),
          });
        }

        if (G.isAllTrue(
          G.isNotNil(templateId),
          G.isStopPickup(telEvent),
          R.equals(templateId, R.path([GC.FIELD_LOCATION, GC.FIELD_TEMPLATE_ID], telEvent)),
        )) {
          return R.mergeRight(telEvent, {
            itemIds: R.uniq(R.concat(telEvent.itemIds, matchEvent.itemIds)),
            items: R.uniqBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), R.concat(telEvent.items, matchEvent.items)),
          });
        }

        return telEvent;
      }),
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel);
  } else {
    const singleTerminalDrop = R.compose(
      R.equals(1),
      R.length,
      R.filter((telEvent: Object) => R.and(
        G.isStopDrop(telEvent),
        G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, telEvent)),
      )),
      R.values,
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel);
    const isItemsDropped = isEventItemsDropped(matchEvent, tel);
    telEvents = R.compose(
      R.assoc(R.prop(GC.FIELD_GUID, matchEvent), matchEvent),
      R.map((telEvent: Object) => {
        const condition = G.isAllTrue(
          G.isStopDrop(telEvent),
          singleTerminalDrop,
          R.not(isItemsDropped),
          R.not(G.isLoadTypeClo(telEvent)),
        );

        if (condition) {
          return R.mergeRight(telEvent, {
            itemIds: R.uniq(R.concat(telEvent.itemIds, matchEvent.itemIds)),
            items: R.uniqBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), R.concat(telEvent.items, matchEvent.items)),
          });
        }

        if (G.isStopPickup(telEvent)) {
          return R.mergeRight(telEvent, {
            itemIds: R.without(matchEvent.itemIds, telEvent.itemIds),
            items: R.filter(
              (item: Object) => R.isNil(R.find(
                R.equals(R.prop(GC.FIELD_ITEM_INTERNAL_ID, item)),
                R.prop(GC.FIELD_STOP_ITEM_IDS, matchEvent),
              )),
              telEvent.items,
            ),
          });
        }

        return telEvent;
      }),
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel);
  }

  return telEvents;
};

export const selectCloEventForTel = (state: Object, data: Object) => {
  const { event, telGuid, templateId, matchEvent } = data;

  const tel = R.path(['currentRoute', GC.SYSTEM_LIST_TELS, telGuid], state);
  const telEvents = getTelEventsWithAddedEvent(state, matchEvent, tel, templateId);

  return P.$all(
    P.$set('cloEventList', selectItem(state.cloEventList, R.prop(GC.FIELD_GUID, event), true)),
    P.$set(`currentRoute.tels.${telGuid}`, R.assoc(GC.FIELD_LOAD_STOPS, sortTelEventsByDate(telEvents), tel)),
    P.$set('currentRouteItems', addTelItems(state.currentRouteItems, R.path([GC.FIELD_STOP_ITEMS], matchEvent))),
    state,
  );
};

export const addUnassignedEventToPlanner = (state: Object, data: Object) => {
  const telGuid = R.path(['selectedValue', 'value'], data);
  const event = R.path(['returnObject', 'event'], data);
  const tel = R.path(['currentRoute', GC.SYSTEM_LIST_TELS, telGuid], state);
  const telEvents = getTelEventsWithAddedEvent(state, event, tel);
  const unassignedCloEvents = R.compose(
    R.filter((unassignedEvent: Object) => R.not(R.eqProps(GC.FIELD_GUID, event, unassignedEvent))),
    R.prop('unassignedCloEvents'),
  )(state);

  return P.$all(
    P.$set('unassignedCloEvents', unassignedCloEvents),
    P.$set('currentRouteItems', addTelItems(state.currentRouteItems, R.path([GC.FIELD_STOP_ITEMS], event))),
    P.$set(`currentRoute.tels.${telGuid}`, R.assoc(GC.FIELD_LOAD_STOPS, sortTelEventsByDate(telEvents), tel)),
    state,
  );
};

const concatObjectItemsIndexedProp = (obj: Object = {}, prop: string) => R.reduce(
  R.concat,
  [],
  R.map((entity: Object = {}) => R.values(entity[prop]), obj),
);

export const refreshRouteItems = (tels: Object) => R.indexBy(
  R.prop(GC.FIELD_ITEM_INTERNAL_ID),
  R.compose(
    R.reverse,
    R.sortBy(R.prop(C.CLO_NAME)),
  )(concatArrayItemsProp(
    concatObjectItemsIndexedProp(R.values(tels), GC.FIELD_LOAD_STOPS),
    GC.FIELD_LOAD_ITEMS,
  )),
);

const getItemsToFilter = (tel: Object) => R.uniq(R.reduce(
  (arr: Array = [], event: Object) => R.concat(arr, event.itemIds),
  [],
  R.values(R.prop(GC.FIELD_LOAD_STOPS, tel)),
));

const getTelsAndItemsToRemoveAfterRemoveTel = (state: Object, tel: Object) => {
  const itemsToFilter = getItemsToFilter(tel);
  const tels = R.dissoc(G.getGuidFromObject(tel), R.path(['currentRoute', GC.SYSTEM_LIST_TELS], state));
  const itemsToRemove = getItemsToRemove(itemsToFilter, tels);

  return { tels, itemsToRemove };
};

export const handleDeleteTel = (state: Object, guid: string, unassign: boolean = false) => {
  const tel = R.path(['currentRoute', 'tels', guid], state);
  const cloEventList = R.compose(
    R.map((cloEvent: Object) => {
      const existsInTel = R.compose(
        R.find(R.eqProps(GC.FIELD_GUID, cloEvent)),
        R.values,
        R.prop(GC.FIELD_LOAD_STOPS),
      )(tel);

      if (existsInTel) return R.assoc('selected', false, cloEvent);

      return cloEvent;
    }),
    R.prop('cloEventList'),
  )(state);
  const { tels, itemsToRemove } = getTelsAndItemsToRemoveAfterRemoveTel(state, tel);
  const newState = P.$all(
    P.$set('cloEventList', cloEventList),
    P.$set('currentRouteItems', refreshRouteItems(tels)),
    P.$set('currentRoute.tels', R.omit(R.of(Array, tel.guid), tels)),
    state,
  );

  if (R.or(G.isTrue(R.prop('isNew', tel)), unassign)) return newState;

  const deletedTelGuids = R.compose(
    R.append(R.prop(GC.FIELD_GUID, tel)),
    R.pathOr([], ['currentRoute', 'deletedTelGuids']),
  )(newState);

  return P.$set('currentRoute.deletedTelGuids', deletedTelGuids, newState);
};

const unselectTelInList = (state: Object, guid: string, listName: string) => R.compose(
  R.map((telItem: Object) => {
    if (R.propEq(guid, GC.FIELD_GUID, telItem)) return R.assoc('selected', false, telItem);

    return telItem;
  }),
  R.prop(listName),
)(state);

export const removeTelFromPlanner = (state: Object, guid: string) => {
  const { currentRoute, selectedTels, cloEventList, deletedEventGuids } = state;

  const isInDeletedList = R.compose(
    G.isNotNil,
    R.find(R.equals(guid)),
    R.pathOr([], [C.DELETED_TEL_GUIDS]),
  )(currentRoute);

  if (isInDeletedList) {
    let tels = R.prop(GC.SYSTEM_LIST_TELS, currentRoute);

    const telEvents = R.compose(
      R.pathOr([], ['details', GC.FIELD_LOAD_STOPS]),
      // TODO: upgrade
      R.find(R.propEq(guid, GC.FIELD_GUID)),
      R.prop('telList'),
    )(state);

    const telEventGuids = R.map(G.getGuidFromObject, telEvents);

    const newCloEventList = R.map(
      (event: Object) => {
        const { guid } = event;

        if (R.includes(guid, telEventGuids)) {
          return R.assoc('selected', false, event);
        }

        return event;
      },
      cloEventList,
    );

    R.forEach(
      (removeEvent: Object) => {
        tels = removeCloEventFromTels(removeEvent, tels);
      },
      telEvents,
    );

    return P.$all(
      P.$set('currentRoute.tels', tels),
      P.$set('cloEventList', newCloEventList),
      P.$set('currentRouteItems', refreshRouteItems(tels)),
      P.$set('telList', unselectTelInList(state, guid, 'telList')),
      P.$set('selectedTels', R.omit(R.of(Array, guid), selectedTels)),
      P.$set('deletedEventGuids', R.without(telEventGuids, deletedEventGuids)),
      P.$set('inboundTelList', unselectTelInList(state, guid, 'inboundTelList')),
      P.$set('outboundTelList', unselectTelInList(state, guid, 'outboundTelList')),
      P.$set('currentRoute.deletedTelGuids', R.without(R.of(Array, guid), R.prop(C.DELETED_TEL_GUIDS, currentRoute))),
      state,
    );
  }

  const newState = handleDeleteTel(state, guid, true);

  return P.$all(
    P.$set('selectedTels', R.omit(R.of(Array, guid), selectedTels)),
    P.$set('telList', unselectTelInList(newState, guid, 'telList')),
    P.$set('inboundTelList', unselectTelInList(newState, guid, 'inboundTelList')),
    P.$set('outboundTelList', unselectTelInList(newState, guid, 'outboundTelList')),
    newState,
  );
};

export const getCurrentRouteWithExchangeTerminal = (tel: Object, currentRoute: Object, terminal: Object) => {
  const order = R.length(R.values(R.path(['tels'], currentRoute)));
  const telGuid = G.generateGuid();
  const telInfo = {
    order,
    [GC.FIELD_GUID]: telGuid,
    [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, tel),
    [GC.FIELD_PRIMARY_REFERENCE]: { value: `Tel-${R.inc(order)}` },
  };
  const telDrops = R.compose(
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
    R.map((stop: Object) => R.mergeRight(stop, {
      [GC.FIELD_TEL_GUID]: telGuid,
      [GC.FIELD_LOAD_GUID]: telGuid,
    })),
    R.filter(G.isStopDrop),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const dates = R.pick(
    [GC.FIELD_LOAD_EVENT_EARLY_DATE, GC.FIELD_LOAD_EVENT_LATE_DATE],
    R.head(telDrops),
  );
  const exchangeItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_LOAD_ITEMS)),
  )(telDrops);
  const itemsObject = {
    items: exchangeItems,
    itemIds: R.map((item: Object) => item.itemInternalId, exchangeItems),
  };
  const newTerminalDrop = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    [GC.FIELD_LOCATION]: terminal,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
    [GC.FIELD_TEL_GUID]: R.prop(GC.FIELD_GUID, tel),
    [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_GUID, tel),
  }), itemsObject);
  const newTerminalPickup = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    [GC.FIELD_LOCATION]: terminal,
    [GC.FIELD_TEL_GUID]: telGuid,
    [GC.FIELD_LOAD_GUID]: telGuid,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
  }), itemsObject);
  const newTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.prepend(newTerminalPickup),
  )(telDrops);
  const newTel = R.assoc(GC.FIELD_LOAD_STOPS, newTelStops, createNewRouteTel(telInfo));
  const splitedTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.append(newTerminalDrop),
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
    R.filter((stop: Object) => R.not(G.isStopDrop(stop))),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const splitedTel = R.assoc(GC.FIELD_LOAD_STOPS, splitedTelStops, tel);
  const newRoute = R.compose(
    R.assocPath([GC.SYSTEM_LIST_TELS, R.prop(GC.FIELD_GUID, tel)], splitedTel),
    R.assocPath([GC.SYSTEM_LIST_TELS, telGuid], newTel),
  )(currentRoute);

  return newRoute;
};

export const getCurrentRouteWithEventsOnExchangeTerminal = ({
  tel,
  events,
  currentRoute,
  terminalWithoutDates,
}: Object) => {
  const order = R.length(R.values(R.path(['tels'], currentRoute)));
  const telGuid = G.generateGuid();
  const telInfo = {
    order: R.dec(order),
    [GC.FIELD_GUID]: telGuid,
    [GC.FIELD_PRIMARY_REFERENCE]: { value: `Tel-${order}` },
    [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, tel),
  };
  const exchangeItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_LOAD_ITEMS)),
  )(events);
  const exchangeItemIds = R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), exchangeItems);
  const itemsObject = {
    [GC.FIELD_STOP_ITEMS]: exchangeItems,
    [GC.FIELD_STOP_ITEM_IDS]: R.map((item: Object) => item.itemInternalId, exchangeItems),
  };
  const telDrops = R.compose(
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
    R.map((stop: Object) => R.mergeRight(stop, {
      [GC.FIELD_TEL_GUID]: telGuid,
      [GC.FIELD_LOAD_GUID]: telGuid,
    })),
    R.filter(G.isStopDrop),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const dates = R.pick(
    [GC.FIELD_LOAD_EVENT_EARLY_DATE, GC.FIELD_LOAD_EVENT_LATE_DATE],
    R.head(telDrops),
  );
  const newTerminalDrop = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    [GC.FIELD_LOCATION]: terminalWithoutDates,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
    [GC.FIELD_TEL_GUID]: R.prop(GC.FIELD_GUID, tel),
    [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_GUID, tel),
  }), itemsObject);
  const newTerminalPickup = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    [GC.FIELD_TEL_GUID]: telGuid,
    [GC.FIELD_LOAD_GUID]: telGuid,
    [GC.FIELD_LOCATION]: terminalWithoutDates,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
  }), itemsObject);
  const splitedTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.prepend(newTerminalPickup),
    R.filter((stop: Object) => G.isNotEmpty(R.prop(GC.FIELD_STOP_ITEM_IDS, stop))),
    R.map((stop: Object) => {
      if (G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop))) {
        const stopItems = R.compose(
          R.omit(exchangeItemIds),
          R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
          R.prop(GC.FIELD_STOP_ITEMS),
        )(stop);

        return R.mergeRight(stop, {
          [GC.FIELD_STOP_ITEMS]: R.values(stopItems),
          [GC.FIELD_STOP_ITEM_IDS]: R.keys(stopItems),
        });
      }

      return stop;
    }),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const newTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.append(newTerminalDrop),
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
    R.filter((stop: Object) => G.isNotEmpty(R.prop(GC.FIELD_STOP_ITEM_IDS, stop))),
    R.map((stop: Object) => {
      const stopItems = R.compose(
        R.pick(exchangeItemIds),
        R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
        R.prop(GC.FIELD_STOP_ITEMS),
      )(stop);

      return R.mergeRight(stop, {
        [GC.FIELD_STOP_ITEMS]: R.values(stopItems),
        [GC.FIELD_STOP_ITEM_IDS]: R.keys(stopItems),
      });
    }),
    R.filter((stop: Object) => R.and(
      G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop)),
      R.any(
        R.flip(R.includes(exchangeItemIds)),
        R.prop(GC.FIELD_STOP_ITEM_IDS, stop),
      ),
    )),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const newTel = R.assoc(GC.FIELD_LOAD_STOPS, newTelStops, createNewRouteTel(telInfo));
  const splitedTel = R.mergeRight(tel, {
    order,
    [GC.FIELD_LOAD_STOPS]: splitedTelStops,
  });
  const newRoute = R.compose(
    R.assocPath([GC.SYSTEM_LIST_TELS, R.prop(GC.FIELD_GUID, tel)], splitedTel),
    R.assocPath([GC.SYSTEM_LIST_TELS, telGuid], newTel),
  )(currentRoute);

  return newRoute;
};

export const getCurrentRouteWithSplitEvents = ({
  tel,
  events,
  currentRoute,
  terminalWithoutDates,
}: Object) => {
  const order = R.length(R.values(R.path(['tels'], currentRoute)));
  const telGuid = G.generateGuid();
  const splitedTelGuid = G.getGuidFromObject(tel);
  const telInfo = {
    order,
    [GC.FIELD_GUID]: telGuid,
    [GC.FIELD_BRANCH_GUID]: R.prop(GC.FIELD_BRANCH_GUID, tel),
    [GC.FIELD_PRIMARY_REFERENCE]: { value: `Tel-${R.inc(order)}` },
  };
  const exchangeItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_LOAD_ITEMS)),
  )(events);
  const itemsObject = {
    [GC.FIELD_STOP_ITEMS]: exchangeItems,
    [GC.FIELD_STOP_ITEM_IDS]: R.map((item: Object) => item.itemInternalId, exchangeItems),
  };
  const telEvents = R.compose(
    R.filter((stop: Object) => R.isNil(R.find(R.eqProps(GC.FIELD_GUID, stop), events))),
    R.values,
    R.prop(GC.FIELD_LOAD_STOPS),
  )(tel);
  const dates = R.compose(
    R.pick([GC.FIELD_LOAD_EVENT_EARLY_DATE, GC.FIELD_LOAD_EVENT_LATE_DATE]),
    R.head,
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
  )(events);
  const newTerminalDrop = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    stopId: G.generateGuid(),
    [GC.FIELD_TEL_GUID]: splitedTelGuid,
    [GC.FIELD_LOAD_GUID]: splitedTelGuid,
    [GC.FIELD_LOCATION]: terminalWithoutDates,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_DROP,
  }), itemsObject);
  const newTerminalPickup = R.mergeRight(createNewTerminalOnRouteTel({
    ...dates,
    stopId: G.generateGuid(),
    [GC.FIELD_TEL_GUID]: telGuid,
    [GC.FIELD_LOAD_GUID]: telGuid,
    [GC.FIELD_LOCATION]: terminalWithoutDates,
    [GC.FIELD_EVENT_TYPE]: GC.EVENT_TYPE_PICKUP,
  }), itemsObject);
  const newTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.prepend(newTerminalPickup),
    R.map((event: Object) => R.mergeRight(event, {
      [GC.FIELD_TEL_GUID]: telGuid,
      [GC.FIELD_LOAD_GUID]: telGuid,
    })),
  )(events);
  const newTel = R.assoc(GC.FIELD_LOAD_STOPS, newTelStops, createNewRouteTel(telInfo));
  const splitedTelStops = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((stop: Object, i: number) => R.assoc(GC.FIELD_TEL_EVENT_INDEX, R.inc(i), stop)),
    R.append(newTerminalDrop),
    R.sortBy(R.prop(GC.FIELD_TEL_EVENT_INDEX)),
  )(telEvents);
  const splitedTel = R.assoc(GC.FIELD_LOAD_STOPS, splitedTelStops, tel);
  const newRoute = R.compose(
    R.assocPath([GC.SYSTEM_LIST_TELS, R.prop(GC.FIELD_GUID, tel)], splitedTel),
    R.assocPath([GC.SYSTEM_LIST_TELS, telGuid], newTel),
  )(currentRoute);

  return newRoute;
};

export const getNewTelEventsFromRouteTemplate = ({ tel, telGuid, locations, firstEventWithInitDates }: Object) => R.map(
  (event: Object) => {
    const { type, eventLateDate, eventEarlyDate, locationTemplateGuid } = event;

    let dates = {};

    if (G.isNotNilAndNotEmpty(firstEventWithInitDates)) {
      const firstEventInitLateDate = G.getPropFromObject('initLateDate', firstEventWithInitDates);
      const firstEventInitEarlyDate = G.getPropFromObject('initEarlyDate', firstEventWithInitDates);

      dates = {
        [GC.FIELD_LOAD_EVENT_LATE_DATE]: firstEventInitLateDate,
        [GC.FIELD_LOAD_EVENT_EARLY_DATE]: firstEventInitEarlyDate,
      };

      if (G.notEquals(event, R.omit(['initLateDate', 'initEarlyDate'], firstEventWithInitDates))) {
        const firstEventEarlyDate = G.getPropFromObject(GC.FIELD_LOAD_EVENT_EARLY_DATE, firstEventWithInitDates);

        const lateDateRange = G.getDateRange(eventEarlyDate, eventLateDate, 'm');
        const earlyDateRange = G.getDateRange(firstEventEarlyDate, eventEarlyDate, 'm');
        const newEventEarlyDate = G.addMomentTimeWithFormat(firstEventInitEarlyDate, earlyDateRange, 'm');
        const newEventLateDate = G.addMomentTimeWithFormat(newEventEarlyDate, lateDateRange, 'm');

        dates = {
          [GC.FIELD_LOAD_EVENT_LATE_DATE]: newEventLateDate,
          [GC.FIELD_LOAD_EVENT_EARLY_DATE]: newEventEarlyDate,
        };
      }
    }

    const options = R.mergeAll([
      event,
      dates,
      {
        [GC.FIELD_EVENT_TYPE]: type,
        [GC.FIELD_TEL_GUID]: telGuid,
        [GC.FIELD_LOAD_GUID]: telGuid,
        [GC.FIELD_STOP_DROP]: G.isEventTypeDrop(type),
        [GC.FIELD_STOP_PICKUP]: G.isEventTypePickup(type),
        [GC.FIELD_LOCATION]: R.pathOr({}, [locationTemplateGuid], locations),
      },
    ]);

    const newStop = createNewTerminalOnRouteTel(options);

    return R.assoc(GC.FIELD_STOP_ID, G.generateGuid(), newStop);
  },
  R.pathOr([], [GC.FIELD_LOAD_STOPS], tel),
);

const getFirstEventWithInitDates = ({ lateDate, earlyDate, templateTels }: Object) => {
  const firstEvent = R.compose(
    R.head,
    R.sort((prevEvent: Object, event: Object) => (
      G.getDateRange(G.getStopEarlyDate(event), G.getStopEarlyDate(prevEvent), GC.DEFAULT_DATE_TIME_FORMAT)
    )),
    R.flatten,
    R.map(R.prop(GC.FIELD_LOAD_STOPS)),
  )(templateTels);

  return R.mergeRight(firstEvent, {
    initLateDate: lateDate,
    initEarlyDate: earlyDate,
  });
};

export const getCurrentRouteWithRouteTemplateEvents = ({
  tel,
  locations,
  currentRoute,
  routeTemplate,
  currentBranchGuid,
  firstEventDates: { lateDate, earlyDate },
}: Object) => {
  const { guid, isNew, events } = tel;

  const templateTels = R.pathOr([], [GC.SYSTEM_LIST_TELS], routeTemplate);
  const currentRouteTels = R.pathOr([], [GC.SYSTEM_LIST_TELS], currentRoute);

  const order = R.length(R.values(currentRouteTels));

  const shouldUpdateCurrentTel = G.isAllTrue(isNew, R.equals(1, order), G.isNilOrEmpty(events));

  const routeTemplateTels = G.ifElse(
    shouldUpdateCurrentTel,
    R.tail(templateTels),
    templateTels,
  );

  const firstEventWithInitDates = getFirstEventWithInitDates({ lateDate, earlyDate, templateTels });

  const newTels = routeTemplateTels.map((tel: Object, i: number) => {
    const telGuid = G.generateGuid();

    const newEvents = getNewTelEventsFromRouteTemplate({
      tel,
      telGuid,
      locations,
      firstEventWithInitDates,
    });

    const telInfo = {
      order: R.add(order, i),
      [GC.FIELD_GUID]: telGuid,
      [GC.FIELD_BRANCH_GUID]: currentBranchGuid,
      [GC.FIELD_LOAD_STOPS]: sortTelEventsByDate(newEvents),
      [GC.FIELD_PRIMARY_REFERENCE]: { value: `Tel-${R.add(R.inc(order), i)}` },
    };

    return createNewRouteTel(telInfo);
  });

  let routeTels = currentRouteTels;

  if (shouldUpdateCurrentTel) {
    const currentTelEvents = getNewTelEventsFromRouteTemplate({
      locations,
      firstEventWithInitDates,
      [GC.FIELD_TEL_GUID]: guid,
      [GC.FIELD_TEL]: R.head(templateTels),
    });

    const currentTelWithNewEvents = R.assoc(GC.FIELD_LOAD_STOPS, sortTelEventsByDate(currentTelEvents), tel);

    routeTels = R.assocPath([guid], currentTelWithNewEvents, currentRouteTels);
  }

  const newCurrentRouteTels = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.concat(R.values(routeTels)),
  )(newTels);

  return R.assoc(GC.SYSTEM_LIST_TELS, newCurrentRouteTels, currentRoute);
};

const getCloItemsToRemoveByStopGuids = (tel: Object, stopGuids: Array) => R.compose(
  R.reduce(R.concat, []),
  R.map(R.prop(GC.FIELD_STOP_ITEM_IDS)),
  R.values,
  R.pick(stopGuids),
  R.path([GC.FIELD_LOAD_STOPS]),
)(tel);

const getUpdatedEvents = ({
  tel,
  cloName,
  cloGuid,
  allItems,
  commonData,
  stopsToSave,
  stopsToRemove,
  commonItemsData,
}: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_GUID)),
  R.map((event: Object) => {
    if (R.and(G.isLoadTypeClo(event), G.notEquals(R.prop(GC.FIELD_CLO_GUID, event), cloGuid))) {
      return event;
    }

    const items = R.compose(
      R.map(R.mergeRight(commonItemsData)),
      R.filter(G.isNotNil),
      R.map((item: Object) => {
        if (R.not(G.isLoadTypeClo(item))) return item;

        const itemToSave = R.path([R.prop(GC.FIELD_ITEM_INTERNAL_ID, item)], allItems);

        if (G.isNotNil(itemToSave) && R.isNil(G.getGuidFromObject(itemToSave))) {
          return R.mergeRight(itemToSave, {
            isNew: true,
            [GC.FIELD_GUID]: G.generateGuid(),
          });
        }

        return itemToSave;
      }),
      R.prop(GC.FIELD_LOAD_ITEMS),
    )(event);

    return R.mergeRight(
      event,
      {
        [GC.FIELD_LOAD_ITEMS]: items,
        [GC.FIELD_STOP_ITEM_IDS]: R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), items),
      },
    );
  }),
  R.values,
  R.omit(stopsToRemove),
  R.map((prevEvent: Object) => {
    const eventData = R.find(
      R.eqProps(GC.FIELD_GUID, prevEvent),
      stopsToSave,
    );

    if (G.isNotNil(eventData)) {
      const mergeData = R.assoc(
        GC.FIELD_LOAD_ITEMS,
        R.map(
          R.mergeRight({
            [C.CLO_NAME]: cloName,
            [GC.FIELD_LOAD_GUID]: cloGuid,
            [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
          }),
          R.prop(GC.FIELD_LOAD_ITEMS, eventData),
        ),
        eventData,
      );

      return R.mergeAll([prevEvent, mergeData, commonData]);
    }

    return prevEvent;
  }),
  R.prop(GC.FIELD_LOAD_STOPS),
)(tel);

export const updateCloStopsOnPlanner = (state: Object, data: Object) => {
  const { cloName, cloGuid, stopsData } = data;
  const { stopsToSave, stopsToRemove } = stopsData;
  const commonItemsData = {
    [C.CLO_NAME]: cloName,
    [GC.FIELD_LOAD_GUID]: cloGuid,
    [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_CLO,
  };
  const commonData = {
    ...commonItemsData,
    [GC.FIELD_CLO_GUID]: cloGuid,
  };
  const editedCloEvents = R.map(
    (stop: Object) => {
      if (R.isNil(R.path([GC.FIELD_GUID], stop))) {
        return R.mergeRight(stop, {
          ...commonData,
          isNew: true,
          [GC.FIELD_GUID]: G.generateGuid(),
          [GC.FIELD_LOAD_ITEMS]: R.map(
            R.mergeRight(commonItemsData),
            R.prop(GC.FIELD_LOAD_ITEMS, stop),
          ),
        });
      }

      return R.mergeRight(stop, {
        ...commonData,
        [GC.FIELD_LOAD_ITEMS]: R.map(
          R.mergeRight(commonItemsData),
          R.prop(GC.FIELD_LOAD_ITEMS, stop),
        ),
      });
    },
    stopsToSave,
  );
  const plannerEvents = R.reduce(
    R.mergeRight,
    {},
    R.map(
      R.prop(GC.FIELD_LOAD_STOPS),
      R.values(R.path(['currentRoute', GC.SYSTEM_LIST_TELS], state)),
    ),
  );

  const unassignedCloEvents = R.compose(
    R.uniqBy(R.prop(GC.FIELD_GUID)),
    R.concat(R.filter(
      (event: Object) => R.not(R.propEq(cloGuid, GC.FIELD_CLO_GUID, event)),
      state.unassignedCloEvents,
    )),
    R.filter(
      ({ guid, isNew }: Object) => {
        if (isNew) return true;

        return R.isNil(R.prop(guid, plannerEvents));
      },
    ),
  )(editedCloEvents);

  const terminalsWithoutEditedCloItems = R.compose(
    R.map((event: Object) => R.assoc(
      GC.FIELD_STOP_ITEMS,
      R.filter(
        ({ loadGuid }: Object) => G.notEquals(loadGuid, cloGuid),
        R.prop(GC.FIELD_STOP_ITEMS, event),
      ),
      event,
    )),
    R.filter((event: Object) => R.not(G.isLoadTypeClo(event))),
    R.values,
    R.reduce(R.mergeRight, {}),
    R.map(R.prop(GC.FIELD_LOAD_STOPS)),
    R.values,
    R.path(['currentRoute', 'tels']),
  )(state);
  const notEditedCloEvents = R.compose(
    R.filter((event: Object) => R.and(
      G.isLoadTypeClo(event),
      G.notEquals(R.prop(GC.FIELD_CLO_GUID, event), cloGuid),
    )),
    R.values,
  )(plannerEvents);
  const eventsWithAllItems = [...terminalsWithoutEditedCloItems, ...stopsToSave, ...notEditedCloEvents];
  const allItems = R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID), R.reduce(
    R.concat,
    [],
    R.map(R.prop(GC.FIELD_STOP_ITEMS), eventsWithAllItems),
  ));
  const newTels = R.compose(
    R.map((tel: Object) => {
      const updatedEvents = getUpdatedEvents({
        tel,
        cloName,
        cloGuid,
        allItems,
        commonData,
        stopsToSave,
        stopsToRemove,
        commonItemsData,
      });

      return R.assoc(GC.FIELD_LOAD_STOPS, updatedEvents, tel);
    }),
    R.path(['currentRoute', GC.SYSTEM_LIST_TELS]),
  )(state);

  return P.$all(
    P.$set('currentRoute.tels', newTels),
    P.$set('deletedEventGuids', stopsToRemove),
    P.$set(`editedClos.${cloGuid}`, editedCloEvents),
    P.$set('unassignedCloEvents', unassignedCloEvents),
    P.$set('currentRouteItems', refreshRouteItems(newTels)),
    state,
  );
};

export const uncheckAllItemsAndRemoveDetails = R.map(R.compose(
  R.dissoc('details'),
  R.assoc('expanded', false),
  R.assoc('selected', false),
));

export const uncheckAllItemsRemoveDetailsAndRemovePlannedEvents = (list: Array, hidePlanned: boolean) => {
  const newList = uncheckAllItemsAndRemoveDetails(list);

  if (R.not(hidePlanned)) return newList;

  return R.filter(
    ({ telGuid }: Object) => R.isNil(telGuid),
    newList,
  );
};
// REDUCER

// SELECTORS
export const getRouteTotalDistance = (loads: Object, distanceName: string) => {
  const loadsDistances = R.map(
    (load: Object) => G.getLoadTotalDistance(load, distanceName),
    R.values(loads),
  );
  const totalTripDistance = R.sum(R.map(R.prop('totalTripDistance'), loadsDistances));
  const totalTripDistanceUom = G.ifElse(
    R.equals(NaN, totalTripDistance),
    '',
    R.pathOr(GC.UOM_MILE, [0, 'totalTripDistanceUom'], loadsDistances),
  );

  return {
    totalTripDistanceUom,
    totalTripDistance: G.ifElse(
      R.equals(NaN, totalTripDistance),
      'N/A',
      totalTripDistance.toFixed(2),
    ),
  };
};

export const getRouteClosOptions = (route: Object, items: Object) => {
  const closByEvents = R.compose(
    R.map((stop: Object) => ({
      label: R.path([C.CLO_NAME], stop),
      value: R.prop(GC.FIELD_CLO_GUID, stop),
    })),
    R.uniqBy(R.prop(GC.FIELD_CLO_GUID)),
    R.filter(G.isLoadTypeClo),
    R.reduce(
      (acc: Array, tel: Object) => R.concat(
        acc,
        R.compose(
          R.values,
          R.pathOr({}, [GC.FIELD_LOAD_STOPS]),
        )(tel),
      ),
      [],
    ),
    R.values,
    R.pathOr({}, [GC.SYSTEM_LIST_TELS]),
  )(route);

  const closByItems = R.compose(
    R.map(({ CLO_NAME, loadGuid }: Object) => ({
      label: CLO_NAME,
      value: loadGuid,
    })),
    R.uniqBy(R.prop(GC.FIELD_LOAD_GUID)),
    R.filter(G.isLoadTypeClo),
    R.values,
  )(items);

  return R.compose(
    R.uniqBy(R.prop(GC.FIELD_VALUE)),
    R.concat(closByItems),
  )(closByEvents);
};
// SELECTORS

// UI
export const sortIndexedListByProp = (list: any, prop: string) => (
  R.compose(
    R.sortBy(R.prop(prop)),
    R.values(),
    R.filter((item: Object) => G.isNotNil(item)),
  )(list)
);

export const convertLoadEventsForMap = (events: Array) => {
  const stops = R.map((stop: Object) => {
    const lat = R.path(['location', 'latitude'], stop);
    const lng = R.path(['location', 'longitude'], stop);

    if (R.or(R.isNil(lat), R.isNil(lng))) return null;

    return {
      ...stop,
      guid: stop.guid,
      title: `${G.toTitleCase(stop.eventType)} ${stop.telEventIndex}`,
      latLng: { lat, lng },
    };
  }, events);

  return sortIndexedListByProp(stops, 'telEventIndex');
};

export const getTelTotalInfo = (tel: Object, items: Object) => {
  if (G.isNilOrEmpty(tel[GC.FIELD_LOAD_STOPS])) return false;

  const itemIds = R.uniq(R.reduce(
    R.concat,
    [],
    R.map(
      (event: Object) => event.itemIds,
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    ),
  ));
  const telItems = R.filter(
    (item: any) => G.isNotNilAndNotEmpty(item),
    R.map(
      (id: string) => R.prop(id, items),
      itemIds,
    ),
  );

  if (G.isNilOrEmpty(telItems)) return false;

  return {
    itemsTotal: telItems.length,
    totalQuantity: G.calculateTotalQuantity(telItems),
    totalWeight: G.calcItemsTotalWeightWithoutQty(telItems),
    totalDistance: G.getLoadTotalDistance(tel, 'telDistanceToNextStop'),
  };
};

export const getRelatedTelTotalInfo = (tel: Object) => {
  if (G.isNilOrEmpty(tel[GC.FIELD_LOAD_STOPS])) return false;

  const items = R.uniq(R.reduce(
    R.concat,
    [],
    R.map(
      G.getPropFromObject(GC.FIELD_LOAD_ITEMS),
      R.values(tel[GC.FIELD_LOAD_STOPS]),
    ),
  ));

  if (G.isNilOrEmpty(items)) return false;

  return {
    itemsTotal: items.length,
    totalQuantity: G.calculateTotalQuantity(items),
    totalWeight: G.calcItemsTotalWeightWithoutQty(items),
    totalDistance: G.getLoadTotalDistance(tel, 'telDistanceToNextStop'),
  };
};

export const getAvailableExistedTerminalOptions = (options: Array, events: Object) => {
  const stopIds = R.compose(
    R.map((event: Object) => event.stopId),
    R.filter((event: Object) => R.equals(event.stopType, GC.STOP_TYPE_TERMINAL)),
    R.values,
  )(events);

  if (G.isNilOrEmpty(stopIds)) return options;

  return R.filter(
    (option: Object) => R.not(R.any(
      (stopId: string) => R.equals(option.value, stopId),
      stopIds,
    )),
    options,
  );
};

export const getStopDistanceInfo = (manual: string, distance: Object) => {
  if (G.isNotNil(manual)) {
    return `
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL], distance)}
      ${R.pathOr('', [GC.FIELD_DISTANCE_MANUAL_UOM], distance)}
    `;
  }

  return `
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM], distance)}
    ${R.pathOr('', [GC.FIELD_DISTANCE_SYSTEM_UOM], distance)}
  `;
};

export const getStopItemsCount = (stop: Object={}) => {
  if (R.or(
    G.isTrue(R.path(['cloEvent'], stop)),
    G.notEquals(R.path(['stopType'], stop), GC.STOP_TYPE_TERMINAL),
  )) {
    return R.length(stop.itemIds);
  }

  return R.compose(
    R.add(R.length(stop.items)),
    R.length,
    R.filter(
      (id: string) => G.isNilOrEmpty(R.find(
        ({ itemInternalId }: Object) => R.equals(itemInternalId, id),
        stop.items,
      )),
    ),
  )(stop.itemIds);
};

export const checkTelExpandedOnPlanner = (stops: Array, mode: string, visibility: string) => {
  if (G.isNotNilAndNotEmpty(visibility)) {
    return G.ifElse(R.equals(visibility, 'visible'), true, false);
  }

  if (R.equals(mode, C.PLANNER_TEL_MODES.ALL_EXPANDED)) return true;

  if (R.equals(mode, C.PLANNER_TEL_MODES.ALL_COLLAPSED)) return false;

  const typeMap = {
    [C.PLANNER_TEL_MODES.INBOUND]: GC.EVENT_TYPE_DROP,
    [C.PLANNER_TEL_MODES.OUTBOUND]: GC.EVENT_TYPE_PICKUP,
  };
  const terminals = R.filter(
    (stop: Object) => R.equals(stop.stopType, GC.STOP_TYPE_TERMINAL),
    stops,
  );

  return R.all(
    R.propEq(typeMap[mode], GC.FIELD_EVENT_TYPE),
    terminals,
  );
};

export const createTelOptions = (currentRoute: Object) => R.compose(
  R.map((tel: Object) => ({ label: getLoadName(tel, 'new tel'), value: G.getGuidFromObject(tel) })),
  R.values,
  R.propOr({}, GC.SYSTEM_LIST_TELS),
)(currentRoute);

export const isInPlanner = (currentRoute: Object, itemIds: Array) => R.compose(
  R.any(G.isTrue),
  R.map((tel: Object) => {
    const events = R.compose(
      R.filter((stop: Object) => G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop))),
      R.values,
      R.prop(GC.FIELD_LOAD_STOPS),
    )(tel);
    const pickedTerminalItems = R.map(
      R.prop(GC.FIELD_ITEM_INTERNAL_ID),
      getTelCloItemsByType({ events }, GC.EVENT_TYPE_PICKUP),
    );
    const droppedTerminalItems = R.map(
      R.prop(GC.FIELD_ITEM_INTERNAL_ID),
      getTelCloItemsByType({ events }, GC.EVENT_TYPE_DROP),
    );
    const picked = R.includes(R.head(itemIds), pickedTerminalItems);
    const dropped = R.includes(R.head(itemIds), droppedTerminalItems);

    return R.and(picked, dropped);
  }),
  R.values,
  R.prop(GC.SYSTEM_LIST_TELS),
)(currentRoute);

export const getCurrentRouteCloEventGuids = R.compose(
  R.map(G.getGuidFromObject),
  R.filter(G.isLoadTypeClo),
    R.reduce(
      (acc: Array, tel: Object) => R.concat(
        acc,
        R.compose(
          R.values,
          R.pathOr({}, [GC.FIELD_LOAD_STOPS]),
        )(tel),
      ),
      [],
    ),
    R.values,
    R.pathOr({}, [GC.SYSTEM_LIST_TELS]),
);

export const getTelsWithTerminalEvents = (tels: Array, locations: Array) => R.map(
  (tel: Object) => {
    const events = R.compose(
      R.map((event: Object) => {
        const { location, eventType, eventLateDate, eventEarlyDate } = event;

        const { templateId, locationName } = location;

        return {
          locationName,
          eventLateDate,
          eventEarlyDate,
          type: eventType,
          locationTemplateGuid: R.path([templateId, GC.FIELD_GUID], locations),
        };
      }),
      R.filter(({ stopType }: Object) => G.isStopTypeTerminal(stopType)),
      R.values,
      R.pathOr([], [GC.FIELD_LOAD_STOPS]),
    )(tel);

    return { events };
  },
  tels,
);

export const getRouteTemplateOptions = (routeTemplates: Array) => R.map(({ guid, name, tels }: Object) => {
  const events = R.compose(
    R.sort((prevEvent: Object, event: Object) => (
      G.getDateRange(G.getStopEarlyDate(event), G.getStopEarlyDate(prevEvent), GC.DEFAULT_DATE_TIME_FORMAT)
    )),
    R.uniqBy(R.prop(GC.FIELD_LOCATION_NAME)),
    R.flatten,
    R.map(R.prop(GC.FIELD_LOAD_STOPS)),
  )(tels);

  const eventLocationNames = R.compose(
    R.join(' - '),
    R.map(R.prop(GC.FIELD_LOCATION_NAME)),
  )(events);

  return ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: `${name} (${eventLocationNames})`,
  });
}, routeTemplates);
// UI

// logic
const isWrongEventDate = (event: Object, index: number, events: Array) => {
  const eventAppointment = G.ifElse(
    R.and(
      G.isNotNilAndNotEmpty(event[GC.FIELD_LOAD_APPOINTMENT_DATE]),
      G.isNotNilAndNotEmpty(event[GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]),
    ),
    `${event[GC.FIELD_LOAD_APPOINTMENT_DATE]} ${event[GC.FIELD_LOAD_APPOINTMENT_LATE_TIME]}`,
    null,
  );
  const prevEventAppointment = G.ifElse(
    R.and(
      G.isNotNilAndNotEmpty(R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_DATE], events)),
      G.isNotNilAndNotEmpty(R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], events)),
    ),
    `${R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_DATE], events)} ${
      R.path([R.dec(index), GC.FIELD_LOAD_APPOINTMENT_EARLY_TIME], events)}`,
    null,
  );

  return R.and(
    R.gt(index, 0),
    G.isBefore(
      R.or(eventAppointment, event[GC.FIELD_LOAD_EVENT_LATE_DATE]),
      R.or(prevEventAppointment, R.path([R.dec(index), GC.FIELD_LOAD_EVENT_EARLY_DATE], events)),
    ),
  );
};

const getFilteredItems = (event: Object, items: Array) => R.filter(
  (item: Object) => G.isNilOrEmpty(R.find(
    R.eqProps(GC.FIELD_ITEM_INTERNAL_ID, item),
    items,
  )),
  R.pathOr([], [GC.FIELD_STOP_ITEMS], event),
);

const getWrongPickupItems = (event: Object, index: number, events: Array) => {
  const dropItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_STOP_ITEMS)),
    R.filter(R.propEq(GC.EVENT_TYPE_DROP, GC.FIELD_EVENT_TYPE)),
    R.slice(R.inc(index), Infinity),
  )(events);

  return getFilteredItems(event, dropItems);
};

const getWrongDropItems = (event: Object, index: number, events: Array) => {
  const pickupItems = R.compose(
    R.reduce(R.concat, []),
    R.map(R.prop(GC.FIELD_STOP_ITEMS)),
    R.filter(R.propEq(GC.EVENT_TYPE_PICKUP, GC.FIELD_EVENT_TYPE)),
    R.take(index),
  )(events);

  return getFilteredItems(event, pickupItems);
};

export const getEventsErrors = (events: Array) => {
  let errors = {};
  events.forEach((event: Object, index: number) => {
    const isPickup = G.isEventTypePickup(R.prop(GC.FIELD_EVENT_TYPE, event));
    const isWrongDate = isWrongEventDate(event, index, events);

    if (isWrongDate) {
      errors = R.assocPath([R.prop(GC.FIELD_GUID, event), GC.FIELD_DATE], true, errors);
    }
    const wrongItems = G.ifElse(
      isPickup,
      getWrongPickupItems,
      getWrongDropItems,
    )(event, index, events);

    if (G.isNotEmpty(wrongItems)) {
      errors = R.assocPath([R.prop(GC.FIELD_GUID, event), GC.FIELD_LOAD_ITEMS], wrongItems, errors);
    }
  });

  return errors;
};

export const getItemsMergeData = ({ cloName, eventItems, matchEventItems }: Object) => {
  const matchEventItemsWithCloGuid = R.map((matchEventItem: Object) => {
    if (R.and(G.isNotNil(cloName), G.isLoadTypeClo(matchEventItem))) {
      return R.assoc(C.CLO_NAME, cloName, matchEventItem);
    }

    return matchEventItem;
  }, matchEventItems);

  const newEventItems = R.compose(
    R.uniqBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
    R.concat(matchEventItemsWithCloGuid),
  )(eventItems);

  return {
    [GC.FIELD_STOP_ITEMS]: newEventItems,
    [GC.FIELD_STOP_ITEM_IDS]: R.map(R.prop(GC.FIELD_ITEM_INTERNAL_ID), newEventItems),
  };
};

const getUpdatedItems = (currentItems: Array, newItems: Object) => R.compose(
  R.indexBy(R.prop(GC.FIELD_ITEM_INTERNAL_ID)),
  R.map((currentItem: Object) => {
    const itemId = G.getPropFromObject(GC.FIELD_ITEM_INTERNAL_ID, currentItem);
    const matchItem = G.getPropFromObject(itemId, newItems);

    if (G.isNotNil(matchItem) && R.not(R.eqProps(GC.FIELD_VERSION, matchItem, currentItem))) {
      let fieldsFromCurrentItem = ['isNew', GC.FIELD_SELECTED];

      if (G.isLoadTypeClo(matchItem)) {
        fieldsFromCurrentItem = R.append(C.CLO_NAME, fieldsFromCurrentItem);
      }

      return R.mergeRight(matchItem, R.pick(fieldsFromCurrentItem, currentItem));
    }

    return currentItem;
  }),
)(currentItems);

const getAddedItems = ({ addedItemIds, cloEventList, itemsFromEvents }: Object) => {
  if (G.isNilOrEmpty(addedItemIds)) return {};

  return R.map((item: Object) => {
    let mergeData = { isNew: false, [GC.FIELD_SELECTED]: true };

    if (G.isLoadTypeClo(item)) {
      const { loadGuid } = item;

      const event = R.find(R.propEq(loadGuid, GC.FIELD_CLO_GUID), cloEventList);

      mergeData = R.assoc(C.CLO_NAME, G.getPropFromObject(GC.GRC.CLO_PRIMARY_REFERENCE_VALUE, event), mergeData);
    }

    return R.mergeRight(item, mergeData);
  }, R.pick(addedItemIds, itemsFromEvents));
};

export const getCurrentRouteItems = ({
  cloEventList,
  itemsFromEvents,
  currentRouteItems,
  deletedRouteItemIds,
}: Object) => {
  const currentItemsWithoutDeleted = R.compose(
    R.values,
    R.omit(deletedRouteItemIds),
  )(currentRouteItems);

  const updatedRouteItems = getUpdatedItems(currentItemsWithoutDeleted, itemsFromEvents);

  const addedRouteItems = getAddedItems({
    cloEventList,
    itemsFromEvents,
    addedItemIds: R.difference(R.keys(itemsFromEvents), R.keys(currentRouteItems)),
  });

  return R.mergeRight(updatedRouteItems, addedRouteItems);
};

export const getMaxTelOrder = (tels: Object) => R.compose(
  R.head,
  R.sort((prevOrder: number, order: number) => R.subtract(order, prevOrder)),
  R.map(R.prop(GC.FIELD_ORDER)),
  R.values,
)(tels);
// logic

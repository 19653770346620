import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// components
import { EmptyList } from '../../../components/list';
import { TextComponent } from '../../../components/text';
import { closeModal } from '../../../components/modal/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, RelativeBox } from '../../../ui';
// feature load-board
import { deleteCompanyFilterRequest } from '../actions';
import { makeSelectCompanyFilterList } from '../selectors';
//////////////////////////////////////////////////

const CompanyFilters = ({ companyFilters, deleteCompanyFilterRequest }: Object) => (
  <RelativeBox bg={G.getTheme('colors.light.mainLight')}>
    {
      R.isEmpty(companyFilters) &&
      <EmptyList>
        {G.getWindowLocale('titles:empty-list', 'Empty List')}
      </EmptyList>
    }
    <Flex
      width='100%'
      overflow='auto'
      maxHeight='80vh'
      flexDirection='column'
      alignItems='flex-start'
    >
      {companyFilters.map(({ guid, name, type }: Object, i: number) => {
        const source = R.prop('label', R.find(R.propEq(type, 'value'), GC.EXTERNAL_LOAD_BOARD_LIST_OPTIONS));

        return (
          <Flex
            key={i}
            p='8px 16px'
            width='100%'
          >
            <TextComponent
              withEllipsis={true}
              width='calc(100% - 20px)'
              title={`${source}: ${name}`}
            >
              {source}: {name}
            </TextComponent>
            <Box
              cursor='pointer'
              onClick={() => deleteCompanyFilterRequest(guid)}
            >
              {I.trash()}
            </Box>
          </Flex>
        );
      })}
    </Flex>
  </RelativeBox>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  companyFilters: makeSelectCompanyFilterList(state),
});

export default connect(mapStateToProps, {
  closeModal,
  deleteCompanyFilterRequest,
})(CompanyFilters);

import * as R from 'ramda';
import React from 'react';
import { pure, compose, withState } from 'react-recompose';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, PageWrapper, StickedFlex, CancelButton } from '../../../ui';
// feature dispatch details new
import DocumentsTab from './components/documents-tab';
import GeneralTabComponent from './components/general-tab';
//////////////////////////////////////////////////

const enhance = compose(
  withState('activeTab', 'setActiveTab', 'general'),
  pure,
);

const commonBtnStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const LoadDetailsNewComponent = ({
  activeTab,
  setActiveTab,
  closeExpandedContainer,
  expandedContainer = false,
}: Object) => (
  <PageWrapper
    bgColor={G.getTheme('colors.bgGrey')}
    p={G.ifElse(G.isTrue(expandedContainer), '0px', 20)}
    pb={G.ifElse(G.isTrue(expandedContainer), '0px', 60)}
    pl={G.ifElse(G.isTrue(expandedContainer), '0px', 75)}
  >
    <Box
      p={G.ifElse(G.isTrue(expandedContainer), 25, '0px')}
      overflow={G.ifElse(G.isTrue(expandedContainer), 'auto', 'unset')}
      height={G.ifElse(G.isTrue(expandedContainer), 'calc(100vh - 60px)', 'unset')}
    >
      {
        R.equals(activeTab, 'documents') && <DocumentsTab setActiveTab={setActiveTab} />
      }
      {
        R.equals(activeTab, 'general') && <GeneralTabComponent setActiveTab={setActiveTab} />
      }
    </Box>
    {
      G.isTrue(expandedContainer) &&
      <StickedFlex
        bottom='0px'
        p='14px 20px'
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
        background={G.getTheme('colors.white')}
      >
        <CancelButton {...cancelStyles} onClick={closeExpandedContainer}>
          {G.getWindowLocale('actions:close', 'Close')}
        </CancelButton>
      </StickedFlex>
    }
  </PageWrapper>
);

export const Component = enhance(LoadDetailsNewComponent);

export default enhance(LoadDetailsNewComponent);

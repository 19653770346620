import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

const validationSchema = Yup.object().shape({
  [GC.FIELD_COMMISSION_ASSIGNMENTS]: G.yupArrayRequired,
});

const inputWrapperStyles = {
  mb: 25,
  width: 300,
};

const agentField = {
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  options: 'optionsForSelect',
  fieldName: GC.FIELD_COMMISSION_ASSIGNMENTS,
  label: ['titles:broker-agents', 'Broker Agents'],
};

const salesField = {
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  options: 'optionsForSelect',
  fieldName: GC.FIELD_COMMISSION_ASSIGNMENTS,
  label: ['titles:sale-persons', 'Sale Persons'],
};

const getFields = (isAgent: boolean = false) => {
  if (isAgent) return R.of(Array, agentField);

  return R.of(Array, salesField);
};

const enhance = compose(
  withFormik({
    validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      { [GC.FIELD_COMMISSION_ASSIGNMENTS]: null },
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const SalesAgentsForm = (props: Object) => {
  const { isAgent, handleSubmit, optionsForSelect } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        fields={getFields(isAgent)}
        fieldsWrapperStyles={{ mt: 15 }}
        optionsForSelect={R.path(['availableCommissionAssigneeOptions'], optionsForSelect)}
      />
      <FormFooter2 />
    </form>
  );
};

export default enhance(SalesAgentsForm);

import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';
// components
import { openModal, closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// features
import { makeSelectExpandedContainerOpened } from '../../expanded-container/selectors';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Box, PageWrapper, StickedFlex, CancelButton } from '../../../ui';
// feature claim-management
import Header from './components/header';
import TabsGroup from './components/tabs-group';
import PinnedMessage from './components/pinned-message';
import GeneralDetails from './components/general-details';
import { makeSelectClaimGuidAndClaimBranchGuid } from '../selectors';
import {
  getRelatedTelsRequest,
  setDetailsInitialState,
  getClaimGeneralDetailsRequest,
} from '../actions';
//////////////////////////////////////////////////

const commonBtnStyles = {
  height: 32,
  width: 150,
  p: '4px 8px',
  fontSize: 14,
  bgColor: 'none',
  background: 'none',
  border: '1px solid',
  borderRadius: '5px',
  textTransform: 'uppercase',
};

const cancelStyles = {
  ...commonBtnStyles,
  textColor: G.getTheme('colors.greyMatterhorn'),
  borderColor: G.getTheme('colors.greyMatterhorn'),
};

const ClaimManagementClaimDetails = (props: Object) => {
  const { expandedContainer, closeExpandedContainer } = props;

  const [height, setHeight] = useState(28);

  const dispatch = useDispatch();

  const commonActions = useMemo(() => ({
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    openModal: (...args: Array) => dispatch(openModal(...args)),
  }), [dispatch]);

  const expandedContainerOpened = useSelector(makeSelectExpandedContainerOpened());

  const { claimGuid, claimBranchGuid } = useSelector(makeSelectClaimGuidAndClaimBranchGuid());

  useEffect(() => {
    return () => dispatch(setDetailsInitialState());
  }, []);

  useEffect(() => {
    if (R.and(G.isFalse(expandedContainerOpened), R.isNotNil(claimGuid))) {
      dispatch(getRelatedTelsRequest());
      dispatch(getClaimGeneralDetailsRequest(claimGuid));
    }
  }, [expandedContainerOpened]);

  if (R.isNil(claimGuid)) return <Box width='100%' height='100vh' bg='bgGrey' />;

  return (
    <PageWrapper
      pt='0px'
      pr='0px'
      pb='0px'
      bgColor={G.getTheme('colors.bgGrey')}
      pl={G.ifElse(expandedContainer, '0px')}
    >
      <Header
        {...commonActions}
        dispatch={dispatch}
        claimGuid={claimGuid}
        setHeight={setHeight}
        claimBranchGuid={claimBranchGuid}
      />
      <Box
        py={25}
        overflow='auto'
        p={G.ifElse(expandedContainer, 25)}
        height={G.ifElse(
          expandedContainer,
          `calc(100vh - ${R.add(110, height)}px)`,
          `calc(100vh - ${R.add(50, height)}px)`,
        )}
      >
        <Box px={25} mx='auto' maxWidth={1250}>
          <PinnedMessage dispatch={dispatch} />
          <GeneralDetails
            {...commonActions}
            dispatch={dispatch}
            claimGuid={claimGuid}
            claimBranchGuid={claimBranchGuid}
          />
          <TabsGroup
            {...commonActions}
            dispatch={dispatch}
            claimGuid={claimGuid}
            claimBranchGuid={claimBranchGuid}
          />
        </Box>
      </Box>
      {
        expandedContainer &&
        <StickedFlex
          bottom='0px'
          p='14px 20px'
          boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
          background={G.getTheme('colors.white')}
        >
          <CancelButton {...cancelStyles} onClick={closeExpandedContainer}>
            {G.getWindowLocale('actions:close', 'Close')}
          </CancelButton>
        </StickedFlex>
      }
    </PageWrapper>
  );
};

export const Component = ClaimManagementClaimDetails;

export default ClaimManagementClaimDetails;

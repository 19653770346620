import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature fleet-map
import * as Actions from './actions';
//////////////////////////////////////////////////

function* getFleetListSaga({ endpoint, fleetType }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpoint, options);
    const { status, data } = res;
    if (G.isResponseSuccess(status)) {
      yield put(Actions.setFleetsToStore({ fleetType, data }));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getFleetListSaga exception');
  }
}

function* handleVisitAnalyticMapPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_ANALYTIC_MAPS_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield call(getFleetListSaga, {
      endpoint: endpointsMap.driverWithLastLocationList,
      fleetType: 'drivers',
    });
    yield call(getFleetListSaga, {
      endpoint: endpointsMap.truckWithLastLocationList,
      fleetType: 'trucks',
    });
    yield call(getFleetListSaga, {
      endpoint: endpointsMap.trailerWithLastLocationList,
      fleetType: 'trailers',
    });
    yield put(closeLoader());
    break;
  }
}

function* fleetMapWatcherSaga() {
  yield takeLatest(GC.VISIT_ANALYTIC_MAPS_PAGE, handleVisitAnalyticMapPageSaga);
}

export default fleetMapWatcherSaga;

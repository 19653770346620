import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  pure,
  branch,
  compose,
  lifecycle,
  withState,
  withProps,
  withHandlers,
  renderNothing,
} from 'react-recompose';
import { createStructuredSelector } from 'reselect';
// components
import { Switcher } from '../../components/switcher';
import { Tabs2, withTabs2 } from '../../components/tabs-mui';
import { HoveringTitle } from '../../components/hovering-title';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withWindowSize, withIsOpenedModalStatus } from '../../hocs';
// ui
import {
  Box,
  Text,
  Flex,
  PageWrapper,
  RelativeBox,
  SimpleWrapper,
  PageTitleWrapper,
  PageHeaderWrapper,
} from '../../ui';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature dispatch-planner
import TelList from './components/loads';
import CloList from './components/orders';
import Planner from './components/planner';
import DefaultFooter from './components/defult-footer';
import InboundTelList from './components/inbound-loads';
import OutboundTelList from './components/outbound-loads';
import CrossDockCloList from './components/crossdock-orders';
import { PLANNER_TEL_MODES, TABS_MODE_OPTIONS } from './constants';
import { pageSizes, mainSettings, PAGE_HEADER_WRAPPER_HEIGHT } from './settings/page-settings';
import {
  setPlannerMode,
  getTelNextPage,
  setEditTemplateRoute,
  getInboundTelNextPage,
  getOutboundTelNextPage,
  getCrossDockCloNextPage,
  setRouteBuilderInitialState,
} from './actions';
import {
  makeSelectTelTotal,
  makeSelectCloTotal,
  makeSelectCloReport,
  makeSelectTelReport,
  makeSelectDraftsTotal,
  makeSelectPlannerMode,
  makeSelectBottomTableMode,
  makeSelectCloFilterParams,
  makeSelectInboundTelReport,
  makeSelectCurrentRouteGuid,
  makeSelectEditTemplateRoute,
  makeSelectOutboundTelReport,
  makeSelectCrossDockCloReport,
  makeSelectAvailableCloReports,
  makeSelectAvailableTelReports,
  makeSelectCurrentBranchConfigs,
  makeSelectCrossDockCloFilterParams,
} from './selectors';
//////////////////////////////////////////////////

const tabs = [
  { text: G.getWindowLocale('titles:clo', 'CLO') },
  { text: G.getWindowLocale('titles:existing-tels', 'Existing TELs') },
  { text: G.getWindowLocale('titles:inbound-tels', 'Inbound TELs') },
  { text: G.getWindowLocale('titles:outbound-tels', 'Outbound TELs') },
];

const getTabs = () => {
  const config = G.getAmousConfigByNameFromWindow(GC.CLO_GENERAL_USE_LINKED_ORDERS);

  if (config) return R.append({ text: G.getWindowLocale('titles:crossdock-clos', 'Cross Dock Orders') }, tabs);

  return tabs;
};

const COLLAPSED = 'COLLAPSED';
const FULL_SCREEN = 'FULL_SCREEN';
const HALF_SCREEN = 'HALF_SCREEN';

const topValuesMap = {
  [HALF_SCREEN]: '30%',
  [COLLAPSED]: 'calc(100% - 25px)',
};

const switcherOptions = [
  {
    width: 55,
    value: PLANNER_TEL_MODES.INBOUND,
    name: G.getWindowLocale('titles:inbound', 'Inbound'),
  },
  {
    width: 55,
    value: PLANNER_TEL_MODES.OUTBOUND,
    name: G.getWindowLocale('titles:outbound', 'Outbound'),
  },
  {
    width: 40,
    value: PLANNER_TEL_MODES.ALL_EXPANDED,
    name: G.getWindowLocale('titles:all', 'All'),
  },
];

const switcherIndexMap = {
  [PLANNER_TEL_MODES.INBOUND]: 0,
  [PLANNER_TEL_MODES.OUTBOUND]: 1,
  [PLANNER_TEL_MODES.ALL_EXPANDED]: 2,
  [PLANNER_TEL_MODES.ALL_COLLAPSED]: 2,
};

const actionNamesMap = {
  1: 'getTelNextPage',
  2: 'getInboundTelNextPage',
  3: 'getOutboundTelNextPage',
  4: 'getCrossDockCloNextPage',
};

const deleteTemplateRoute = (guid: string) => {
  try {
    sendRequest(
      'delete',
      endpointsMap.deleteWithoutUpdateRelations(guid),
    );
  } catch (error) {
    G.handleException('error', 'deleteTemplateRoute exception');
  }
};

const enhance = compose(
  withTabs2,
  withWindowSize,
  withProps((props: Object) => ({ settings: R.mergeRight(mainSettings, props.settings) })),
  withState('visitedTabs', 'setVisitedTabs', [0]),
  withState('plannerView', 'setPlannerView', HALF_SCREEN),
  withState('itemsExpanded', 'toggleItemsExpanded', false),
  withIsOpenedModalStatus,
  withHandlers({
    handleSetActiveTabAndPlannerMode: (props: Object) => (index: number) => {
      const {
        mode,
        visitedTabs,
        setVisitedTabs,
        setPlannerMode,
        handleSetActiveTab,
      } = props;

      if (G.notContain(index, visitedTabs)) {
        G.callFunctionWithArgs(R.prop(actionNamesMap[index], props), true);

        setVisitedTabs(R.append(index, visitedTabs));
      }

      handleSetActiveTab(index);

      if (G.notEquals(TABS_MODE_OPTIONS[index], mode)) {
        setPlannerMode(TABS_MODE_OPTIONS[index]);
      }
    },
  }),
  branch(
    ({ initialDataLoaded }: Object) => R.not(initialDataLoaded),
    renderNothing,
  ),
  lifecycle({
    componentWillUnmount() {
      const { currentRouteGuid, editTemplateRoute, setEditTemplateRoute, setRouteBuilderInitialState } = this.props;

      setRouteBuilderInitialState();

      setEditTemplateRoute(false);

      if (G.isTrue(editTemplateRoute)) deleteTemplateRoute(currentRouteGuid);
    },
  }),
  pure,
);

const Content = (props: Object) => {
  const {
    mode,
    settings,
    activeTab,
    windowWidth,
    plannerView,
    itemsExpanded,
    cloReportList,
    telReportList,
    setPlannerView,
    cloFilterParams,
    currentBranchGuid,
    selectedCloReport,
    selectedTelReport,
    toggleItemsExpanded,
    currentBranchConfigs,
    crossDockFilterParams,
    selectedInboundTelReport,
    selectedOutboundTelReport,
    selectedCrossDockCloReport,
    handleRecalculateTelDistances,
  } = props;

  const sideBarWidth = 75;

  const { rightBarWidth, routeSummaryWidth } = pageSizes;

  const loadsContentMaxWidth = R.subtract(
    windowWidth,
    R.sum([
      sideBarWidth,
      rightBarWidth,
      routeSummaryWidth,
    ]),
  );

  const plannerResizeBtnsStyles = {
    ml: 10,
    width: 16,
    cursor: 'pointer',
    borderTop: '3px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: G.getTheme('colors.light.black'),
  };

  const isFullScreen = R.equals(plannerView, FULL_SCREEN);

  const tabReportArray = [
    selectedCloReport,
    selectedTelReport,
    selectedInboundTelReport,
    selectedOutboundTelReport,
    selectedCrossDockCloReport,
  ];

  const filterParamsMap = {
    0: cloFilterParams,
    4: crossDockFilterParams,
  };

  const filterParams = R.pathOr(null, [activeTab], filterParamsMap);

  const tabReports = G.ifElse(R.includes(activeTab, [0, 4]), cloReportList, telReportList);

  const hasSearchCriteria = R.or(
    G.isNotNilAndNotEmpty(filterParams),
    G.isNotNilAndNotEmpty(R.path([activeTab, 'searchCriteria'], tabReportArray)),
  );
  const hasPinned = G.hasPinnedReports(R.or(tabReports, []));

  let heightSubtract = 0;

  if (hasPinned) heightSubtract = R.add(heightSubtract, 32);

  if (hasSearchCriteria) heightSubtract = R.add(heightSubtract, 34);

  const listMaxHeight = G.ifElse(
    R.equals(plannerView, HALF_SCREEN),
    `calc(30vh - ${R.add(heightSubtract, 57)}px)`,
    `calc(100vh - ${R.add(heightSubtract, 124)}px)`,
  );

  const toggleItemsText = G.ifElse(
    itemsExpanded,
    G.getWindowLocale('titles:collapse-items', 'Collapse Items'),
    G.getWindowLocale('titles:expand-items', 'Expand Items'),
  );

  return (
    <RelativeBox height='calc(100vh - 60px)'>
      {
        R.equals(activeTab, 0) &&
        <CloList
          maxHeight={listMaxHeight}
          shouldNotUnselectLastClo={R.path(['shouldNotUnselectLastClo'], settings)}
        />
      }
      { R.equals(activeTab, 1) && <TelList maxHeight={listMaxHeight} /> }
      { R.equals(activeTab, 2) && <InboundTelList maxHeight={listMaxHeight} /> }
      { R.equals(activeTab, 3) && <OutboundTelList maxHeight={listMaxHeight} /> }
      { R.equals(activeTab, 4) && <CrossDockCloList maxHeight={listMaxHeight} /> }
      <Box
        right='0px'
        bottom='0px'
        bg={G.getTheme('colors.white')}
        zIndex={G.ifElse(isFullScreen, 2, '0')}
        left={G.ifElse(isFullScreen, 55, '0px')}
        position={G.ifElse(isFullScreen, 'fixed', 'absolute')}
        top={G.ifElse(isFullScreen, '0px', topValuesMap[plannerView])}
      >
        <Flex height='100%' flexDirection='column'>
          <Flex
            ml='auto'
            height={25}
            p='3px 10px'
            width='100%'
            borderTop='1px solid'
            justifyContent='flex-end'
            bg={G.getTheme('colors.poloBlue')}
            borderColor={G.getTheme('colors.lightGrey')}
          >
            <Box
              fontSize={12}
              cursor='pointer'
              color={G.getTheme('colors.light.blue')}
              onClick={() => toggleItemsExpanded(R.not(itemsExpanded))}
            >
              {toggleItemsText}
            </Box>
            <Box mx={20}>
              <Switcher
                version={2}
                options={switcherOptions}
                selectedOptionIndex={switcherIndexMap[mode]}
                onSwitch={(mode: string) => setPlannerMode(mode)}
              />
            </Box>
            {
              G.notEquals(plannerView, COLLAPSED) &&
              <HoveringTitle
                handleClick={() => setPlannerView(COLLAPSED)}
                title={G.getWindowLocale('actions:expand-list', 'Expand List')}
                positionConfig={{
                  zIndex: 14,
                  right: '70%',
                  width: 'max-content',
                  bottom: G.ifElse(R.equals(plannerView, COLLAPSED), '150%', 0),
                }}
              >
                <Box
                  {...R.omit(['borderTop', 'borderLeft', 'borderRight'], plannerResizeBtnsStyles)}
                  height='16px'
                  borderBottom='4px solid'
                  onClick={() => setPlannerView(COLLAPSED)}
                />
              </HoveringTitle>
            }
            {
              G.notEquals(plannerView, HALF_SCREEN) &&
              <HoveringTitle
                handleClick={() => setPlannerView(HALF_SCREEN)}
                positionConfig={{
                  zIndex: 14,
                  right: '70%',
                  width: 'max-content',
                  bottom: G.ifElse(R.equals(plannerView, COLLAPSED), '150%', '-150%'),
                }}
                title={G.ifElse(
                  R.equals(plannerView, COLLAPSED),
                  G.getWindowLocale('actions:collapse-list', 'Collapse List'),
                  G.getWindowLocale('actions:collapse-canvas', 'Collapse Canvas'))
                }
              >
                <Box
                  {...plannerResizeBtnsStyles}
                  height='10px'
                  onClick={() => setPlannerView(HALF_SCREEN)}
                />
              </HoveringTitle>
            }
            {
              R.not(isFullScreen) &&
              <HoveringTitle
                handleClick={() => setPlannerView(FULL_SCREEN)}
                title={G.getWindowLocale('actions:expand-canvas', 'Expand Canvas')}
                positionConfig={{
                  zIndex: 14,
                  right: '70%',
                  bottom: '150%',
                  width: 'max-content',
                }}
              >
                <Box
                  {...plannerResizeBtnsStyles}
                  height='16px'
                  onClick={() => setPlannerView(FULL_SCREEN)}
                />
              </HoveringTitle>
            }
          </Flex>
          <Planner
            settings={settings}
            pageSizes={pageSizes}
            itemsExpanded={itemsExpanded}
            currentBranchGuid={currentBranchGuid}
            loadsContentMaxWidth={loadsContentMaxWidth}
            currentBranchConfigs={currentBranchConfigs}
            collapsed={R.equals((plannerView, COLLAPSED))}
            handleRecalculateTelDistances={handleRecalculateTelDistances}
            maxHeight={R.or(settings.builderMaxHeight, 'calc(100% - 20px)')}
          />
        </Flex>
      </Box>
    </RelativeBox>
  );
};

const Content2 = (props: Object) => {
  const sideBarWidth = 75;
  const { rightBarWidth, routeSummaryWidth } = pageSizes;
  const loadsContentMaxWidth = R.subtract(
    props.windowWidth,
    R.sum([
      sideBarWidth,
      rightBarWidth,
      routeSummaryWidth,
    ]),
  );

  return (
    <RelativeBox height='100%'>
      <Box
        top='0px'
        left={70}
        right='0px'
        zIndex={13}
        bottom='0px'
        position='fixed'
        bg={G.getTheme('colors.white')}
      >
        <Flex height='100%' flexDirection='column'>
          <Planner
            maxHeight='100%'
            collapsed={false}
            pageSizes={pageSizes}
            settings={props.settings}
            currentBranchGuid={props.currentBranchGuid}
            loadsContentMaxWidth={loadsContentMaxWidth}
            currentBranchConfigs={props.currentBranchConfigs}
            handleRecalculateTelDistances={props.handleRecalculateTelDistances}
          />
        </Flex>
      </Box>
    </RelativeBox>
  );
};

const DispatchPlanner = enhance((props: Object) => (
  <Box
    maxWidth='100%'
    overflow='auto'
    maxHeight='calc(100% - 40px)'
  >
    <PageWrapper
      zi={1}
      pt={16}
      pb='0px'
      overflow='hidden'
      mt={props.settings.pageWrapperMt}
      height={props.settings.pageHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
      withoutPageMargin={props.settings.withoutPageMargin}
    >
      {
        R.and(R.not(props.fullScreenView), R.not(props.settings.withoutPageHeader)) &&
        <PageHeaderWrapper
          pt='0px'
          position='relative'
          height={PAGE_HEADER_WRAPPER_HEIGHT}
        >
          <SimpleWrapper>
            <PageTitleWrapper height={40}>
              <Flex justifyContent='center' flexDirection='column'>
                <Text fontSize='20px'>
                  {G.getWindowLocale('titles:routebuilder', 'Route Builder')}
                </Text>
              </Flex>
            </PageTitleWrapper>
          </SimpleWrapper>
          <Tabs2
            tabs={getTabs()}
            activeTab={props.activeTab}
            tabStyles={GC.COMMON_MUI_TAB_STYLES}
            tabsStyles={GC.COMMON_MUI_TABS_STYLES}
            setActiveTab={props.handleSetActiveTabAndPlannerMode}
          />
        </PageHeaderWrapper>
      }
      <Content {...props} />
    </PageWrapper>
    {
      props.settings.withDefaultFooter &&
      <DefaultFooter
        height={props.maxHeight}
        onSaveCallback={props.onSaveCallback}
        saveRouteWithoutRates={props.settings.saveRouteWithoutRates}
      />
    }
  </Box>
));

const DispatchPlanner2 = enhance((props: Object) => (
  <Box
    maxWidth='100%'
    overflow='auto'
    maxHeight='100%'
  >
    <PageWrapper
      zi={1}
      pt={16}
      pb='0px'
      mt={props.settings.pageWrapperMt}
      height={props.settings.pageHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
      withoutPageMargin={props.settings.withoutPageMargin}
    >
      <Content2 {...props} />
    </PageWrapper>
  </Box>
));

const mapStateToProps = (state: Object) => createStructuredSelector({
  mode: makeSelectPlannerMode(state),
  telTotal: makeSelectTelTotal(state),
  cloTotal: makeSelectCloTotal(state),
  draftsTotal: makeSelectDraftsTotal(state),
  selectedCloReport: makeSelectCloReport(state),
  selectedTelReport: makeSelectTelReport(state),
  bottomTableMode: makeSelectBottomTableMode(state),
  cloFilterParams: makeSelectCloFilterParams(state),
  currentRouteGuid: makeSelectCurrentRouteGuid(state),
  cloReportList: makeSelectAvailableCloReports(state),
  telReportList: makeSelectAvailableTelReports(state),
  currentBranchGuid: makeSelectCurrentBranchGuid(state),
  editTemplateRoute: makeSelectEditTemplateRoute(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
  currentBranchConfigs: makeSelectCurrentBranchConfigs(state),
  selectedInboundTelReport: makeSelectInboundTelReport(state),
  selectedOutboundTelReport: makeSelectOutboundTelReport(state),
  selectedCrossDockCloReport: makeSelectCrossDockCloReport(state),
  crossDockFilterParams: makeSelectCrossDockCloFilterParams(state),
});

const withConnect = connect(
  mapStateToProps,
  {
    setPlannerMode,
    getTelNextPage,
    setEditTemplateRoute,
    getInboundTelNextPage,
    getOutboundTelNextPage,
    getCrossDockCloNextPage,
    setRouteBuilderInitialState,
  },
);

export const Component = withConnect(DispatchPlanner);

export default withConnect(DispatchPlanner);

export const DispatchPlannerForTelDetails = connect(
  mapStateToProps,
  { setPlannerMode, setEditTemplateRoute, setRouteBuilderInitialState },
)(DispatchPlanner2);

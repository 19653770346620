import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// features
import {
  defaultCreateContractFields,
  validationSchemaCreateContract } from '../../carrier/settings/fields-settings';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { FieldsetComponent } from '../../../forms';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature contracts
import { createContractFormFields, createContractFormFieldsAsync } from '../settings/fields-settings';
//////////////////////////////////////////////////

const enhanceContractForm = withHandlers({
  handleSendCreateCarrierContract: (props: Object) => async (data: Object) => {
    try {
      const { closeModal, openLoader, closeLoader, callbackAction } = props;
      openLoader({ showDimmer: true });
      const res = await sendRequest('post', endpointsMap.carrierContract, { data });
      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:200-201', 'The request has succeeded'));
        callbackAction(res.data);
        closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    } catch (error) {
      G.catchSendRequestSimple(error, 'handleCreateCarrierContract', props.closeLoader);
    }
  },
  handleSendCreateCustomerContract: (props: Object) => async (data: Object) => {
    try {
      const { closeModal, openLoader, closeLoader, callbackAction } = props;
      openLoader({ showDimmer: true });
      const res = await sendRequest('post', endpointsMap.customerContract, { data });
      if (G.isResponseSuccess(res.status)) {
        G.showToastrMessageSimple('success', G.getWindowLocale('messages:success:200-201', 'The request has succeeded'));
        callbackAction(res.data);
        closeModal();
      } else {
        G.handleFailResponseSimple(res);
      }
      closeLoader();
    } catch (error) {
      G.catchSendRequestSimple(error, 'handleSendCreateCustomerContract', props.closeLoader);
    }
  },
});

const setCarrierGuidToValues = (pageType: string, carrierGuid: string, values: Object) => {
  if (R.or(R.equals(pageType, 'carrierContracts'), R.equals(pageType, 'customerContracts'))) return values;

  return R.assoc(GC.FIELD_CARRIER_GUID, carrierGuid, values);
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCreateContract,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultCreateContractFields,
      initialValues,
    ),
    handleSubmit: (values: Object, { props }: Object) => {
      const {
        pageType,
        carrierGuid,
        contractType,
        handleSendCreateCarrierContract,
        handleSendCreateCustomerContract } = props;

      const data = setCarrierGuidToValues(pageType, carrierGuid, values);
      if (G.isContractTypeCustomer(contractType)) {
        return handleSendCreateCustomerContract(data);
      }
      handleSendCreateCarrierContract(data);
    },
    displayName: 'CREATE_CONTRACT_FORM',
  }),
  pure,
);

const getFields = (pageType: string) => G.ifElse(
  R.or(R.equals(pageType, 'carrierContracts'), R.equals(pageType, 'customerContracts')),
  createContractFormFieldsAsync,
  createContractFormFields,
);

export const CreateContractForm = enhanceContractForm(enhance((props: Object) => (
  <Box>
    <form onSubmit={props.handleSubmit}>
      <Flex p='0 15px' zIndex='13' display='block'>
        <FieldsetComponent
          {...props}
          flexDirection='column'
          fields={getFields(props.pageType)} />
      </Flex>
      <FormFooter
        closeModal={props.closeModal}
        isSubmitting={props.isSubmitting}
        boxStyles={{ p: 10, justifyContent: 'space-around' }} />
    </form>
  </Box>
)));

import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { closeLoader, openLoader } from '../../../components/loader/actions';
// features
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { socketCarrierDocumentGeneratedRequest } from '../../sockets/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature contracts
import * as A from './actions';
import { FIELDS_AND_FILTER_PARAMS } from './settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

// NOTE: check all feature NOTES to create common solution with carrier contracts

const addCarrierGuidToFields = (fields: Object) => {
  const field = {
    freezed: false,
    reference: false,
    collection: false,
    name: GC.GRC.CARRIER_GUID,
    sequence: R.inc(R.length(fields)),
  };

  return R.append(field, fields);
};

function* handleGetItemListSaga({ payload }: Object) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('itemDefault', G.getGuidFromObject(reportParams)))
    ) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    // NOTE: not common newFilterParams
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    const { limit, offset } = pagination;

    // NOTE: not common reqBody
    const reqBody = {
      guids,
      limit,
      offset,
      orderFields,
      currentEnterprise,
      fields: addCarrierGuidToFields(G.getOrElse(reportParams, 'fields', [])),
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    // NOTE: not common endpoint
    const res = yield call(sendRequest, 'post', endpointsMap.customersContract, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    const { fields, fileType, orderFields } = payload;

    const reqBody = {
      fields,
      orderFields,
      searchCriteria,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    // NOTE: not common endpoint
    const res = yield call(sendRequest, 'post', endpointsMap.customerContractsExport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

export function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
    };

    // NOTE: not common endpoint
    const res = yield call(sendRequest, 'delete', endpointsMap.customerContract, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status, data)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetListAndPagination());

      yield call(handleGetItemListSaga, { payload: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeModal());
    yield put(closeLoader());
  } catch (error) {
    yield put(closeModal());
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

export function* handleSetFilterParams() {
  yield put(A.setFilterProps(FIELDS_AND_FILTER_PARAMS));
}

export function* handleResetListAndPaginationAndGetItemsRequest() {
  yield put(A.resetListAndPagination());
  yield put(A.getItemListRequest(true));
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.CUSTOMER_CONTRACT_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

function* handleVisitPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CUSTOMER_CONTRACTS_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield put(socketCarrierDocumentGeneratedRequest(G.getAmousCurrentUserFromWindow()));

    yield put(A.setIgnorePromptStatus(false)); // TODO: check if we need it
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    yield call(handleSetFilterParams);

    yield put(closeLoader());

    break;
  }
}

function* pageWatcherSaga() {
  yield takeLatest(GC.VISIT_CUSTOMER_CONTRACTS_PAGE, handleVisitPageSaga);
  // NOTE: start common
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.resetListAndPaginationAndGetItemsRequest, handleResetListAndPaginationAndGetItemsRequest);
  // NOTE: finish common
}

export default pageWatcherSaga;

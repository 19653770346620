import React from 'react';
import * as R from 'ramda';
import { pure, compose, withProps, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// features
import { AuthWrapper } from '../../permission';
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import { withAsyncGetCommissionAssigneeListByLoadType } from '../../../hocs';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature dispatch-details-new
import SalesAgentsForm from '../forms/sales-agents';
//////////////////////////////////////////////////

const iconColor = G.getTheme('colors.light.blue');

const enhance = compose(
  withProps(({ load }: Object) => {
    const isSalesOrAgents = G.ifElse(
      G.isLoadTypeTel(load),
      R.pathOr([], [GC.FIELD_BROKER_AGENTS], load),
      R.pathOr([], [GC.FIELD_SALE_PERSONS], load),
    );
    const mappedSalesOrAgents = R.compose(
      R.map(({ id, name }: Object) => `${name} (${id})`),
      R.indexBy(R.prop(GC.FIELD_GUID)),
    )(isSalesOrAgents);

    return { isAgent: G.isLoadTypeTel(load), salesOrAgents: mappedSalesOrAgents };
  }),
  withAsyncGetCommissionAssigneeListByLoadType,
  withHandlers({
    handleOpenSalesAgentsForm: (props: Object) => () => {
      const {
        load,
        isAgent,
        fromPage,
        openModal,
        tableIndex,
        closeModal,
        salesOrAgents,
        changeSalesOrAgentsRequest,
        availableCommissionAssigneeOptions } = props;

      const initialValues = { [GC.FIELD_COMMISSION_ASSIGNMENTS]: R.keys(salesOrAgents) };
      const optionsForSelect = { availableCommissionAssigneeOptions };

      const submitAction = ({ commissionAssignments }: Object) => {
        const data = {
          fromPage,
          tableIndex,
          commissionAssignments,
          [GC.FIELD_LOAD_GUID]: G.getGuidFromObject(load),
          [GC.FIELD_LOAD_TYPE]: G.getPropFromObject(GC.FIELD_LOAD_TYPE, load),
        };

        changeSalesOrAgentsRequest(data);
      };

      const component = (
        <SalesAgentsForm
          isAgent={isAgent}
          closeModal={closeModal}
          submitAction={submitAction}
          initialValues={initialValues}
          optionsForSelect={optionsForSelect}
          availableCommissionAssigneeOptions={availableCommissionAssigneeOptions}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 'auto',
          height: 'auto',
          title: G.ifElse(
            isAgent,
            G.getWindowLocale('titles:broker-agents', 'Broker Agents'),
            G.getWindowLocale('titles:sale-persons', 'Sale Persons'),
          ),
        },
      };

      openModal(modal);
    },
  }),
  pure,
);

const SalesAgentsComponent = (props: Object) => {
  const { isAgent, salesOrAgents, handleOpenSalesAgentsForm } = props;

  const labelTxtLocale = G.ifElse(
    isAgent,
    G.getWindowLocale('titles:broker-agents', 'Broker Agents'),
    G.getWindowLocale('titles:sale-persons', 'Sale Persons'),
  );
  const salesOrAgentsString = R.join(', ', R.values(salesOrAgents));

  if (R.and(G.isTrue(isAgent), G.isCurrentUserTypeCarrier())) return null;

  return (
    <Flex mr={10}>
      <TextComponent
        maxWidth={190}
        withEllipsis={true}
        title={`${labelTxtLocale}: ${salesOrAgentsString}`}
      >
        {labelTxtLocale}: {salesOrAgentsString}
      </TextComponent>
      <AuthWrapper has={[PC.TEL_WRITE]}>
        <Box ml='5px' cursor='pointer' onClick={handleOpenSalesAgentsForm}>
          {I.pencil(iconColor)}
        </Box>
      </AuthWrapper>
    </Flex>
  );
};

export default enhance(SalesAgentsComponent);

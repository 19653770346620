import * as R from 'ramda';
import React from 'react';
import { createPortal } from 'react-dom';
import { Draggable } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ENUMS } from '../../../constants/enums';
// ui
import { Flex } from '../../../ui';
// feature dispatch-planner-events
import Stop from './stop';
import Dimmer from './dimmer';
import * as H from '../helpers';
//////////////////////////////////////////////////

const renderDraggable = (draggableProps: Object, element: HTMLElement) => {
  if (R.pathEq('fixed', ['style', 'position'], draggableProps)) {
    return createPortal(element, document.body);
  }

  return element;
};

const renderStops = (props: Object) => {
  const { tel, noDimmer, showComponent, useStopTrailers } = props;

  if (R.and(showComponent, G.isNotNilAndNotEmpty(R.path(['tel', GC.FIELD_LOAD_STOPS], props)))) {
    const telGuid = G.getGuidFromObject(tel);

    const events = H.sortTelEvents(tel[GC.FIELD_LOAD_STOPS]);

    return (
      events.map((stop: Object, index: number) => {
        let cloName = null;

        const isCloStop = G.isLoadTypeClo(stop);

        if (isCloStop) {
          cloName = H.getLoadName(R.path([GC.SYSTEM_LIST_CLOS, stop[GC.FIELD_CLO_GUID]], props));
        }

        return (
          <Draggable
            index={index}
            key={stop.guid}
            draggableId={`${telGuid}_${G.getGuidFromObject(stop)}`}
            isDragDisabled={R.equals(stop.stopType, GC.STOP_TYPE_TERMINAL)}
          >
            {(provided: Object) => (
              <div>
                {
                  renderDraggable(provided.draggableProps, (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Stop
                        {...props}
                        stop={stop}
                        cloName={cloName}
                        useStopTrailers={useStopTrailers}
                        lastStopInTel={R.equals(R.length(events), R.inc(index))}
                        error={R.pathOr(null, ['error', 'eventsErrors', G.getGuidFromObject(stop)], props)}
                      />
                    </div>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Draggable>
        );
      })
    );
  }

  return R.not(noDimmer) && <Dimmer count={6} />;
};

export const LoadInner = (props: Object) => {
  const manageTrailersFrom = G.getAmousConfigByNameFromWindow(GC.TRAILER_MANAGE_TRAILERS_FROM);
  const useStopTrailers = R.equals(manageTrailersFrom, ENUMS.ENUM_EVENTS);

  return (
    <Flex
      pt={20}
      pb='8px'
      width='100%'
      overflow='auto'
      minHeight={200}
      alignItems='normal'
    >
      {renderStops({ ...props, useStopTrailers })}
    </Flex>
  );
};

export default LoadInner;

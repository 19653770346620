import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withProps, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
import {
  callStatusCheckTelRequest,
  createLoadDocumentsRequest,
  sendTelToExternalSystemRequest,
  getTelIntegrationDocumentsRequest,
} from '../../../common/actions';
// components
import { LocalLoader } from '../../../components/local-loader';
import { ConfirmComponent } from '../../../components/confirm';
import { openModal, closeModal } from '../../../components/modal/actions';
import SendQuoteRequestForm from '../../../components/send-quote-request-form';
import MultipleStatusMessageForm from '../../../components/multiple-status-message';
// features
import { enhanceRateCharges } from '../../rate/hocs';
import { withDispatchCarrierRate } from '../../mail-sending/hocs';
import { setInitialRouteGuid } from '../../dispatch-planner/actions';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { withOrderRateSyncWithTrips } from '../../rate/customer/hocs';
import DriverRateForm, { EditDriverRateForm } from '../../rate/driver';
import CarrierRateForm, { EditCarrierRateForm } from '../../rate/carrier';
import { setInitialPlannerData } from '../../dispatch-planner-events/actions';
import { makeSelectAvailableDocumentTypes } from '../../configurations/selectors';
import StatusMessageForm from '../../dispatch-details-new/forms/status-message-form';
import { makeSelectDeclineReasonOptions } from '../../dispatch-details-new/load/selectors';
import { withApplyOrderRateUpliftForm } from '../../rate/components/apply-order-rate-uplift-form';
import CrossBorderIntegrationForm from '../../dispatch-details-new/forms/cross-border-integration-form';
import EditCarrierRateForUserTypeCarrier from '../../rate/carrier/edit-carrier-rate-for-user-type-carrier';
// forms
import AppointmentsForm from '../../../forms/forms/appointments-form';
import { ApproveRateForm } from '../../../forms/forms/approve-rate-form';
import AddDocumentsForm from '../../../forms/forms/add-load-documents-form';
import { SelectDropdownForm } from '../../../forms/forms/select-dropdown-form';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncConfigs,
  withAsyncTelEvents,
  withReasonCodeConfigs,
  withAsyncStatusMessages,
} from '../../../hocs';
// ui
import { Box } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// dispatch-board-new
import * as H from '../helpers';
import withCarrierFleetInvoices from './with-carrier-fleet-invoices';
import { withAsyncModeAndServiceType } from './with-async-mode-and-service-type';
import { withSendTelsToExternalSystem } from './with-send-tels-to-external-system';
import {
  makeSelectConfigsByGroup,
  makeSelectDefaultUomFields,
  makeSelectDeclineLoadReasons,
} from '../selectors';
import {
  acceptTelRateRequest,
  declineTelRateRequest,
  dispatchTelRateRequest,
  approveLoadRateRequest,
  updateAppointmentRequest,
  createFleetInvoiceRequest,
  sendUpdateEdiOrApiRequest,
  createCarrierInvoiceRequest,
  dispatchCarrierTelRateRequest,
  createLoadStatusMessageRequest,
  createFleetInvoiceByTelGuidRequest,
  createCrossBorderIntegrationRequest,
  createCarrierInvoiceByTelGuidRequest,
  suspendResumeCarrierRateEmailsRequest,
  createLoadMultipleStatusMessageRequest,
} from '../load/actions';
//////////////////////////////////////////////////

const getStopOptions = (stops: Array = []) => {
  const filteredStops = R.filter(
    R.propEq(GC.STOP_STATUS_TYPE_PENDING, GC.FIELD_STATUS),
    stops,
  );

  return G.createStopOptions(filteredStops, true);
};

const SelectDropdownFormWithAsyncEvents = withAsyncTelEvents((props: Object) => {
  const options = getStopOptions(R.or(props.asyncEvents, []));
  const indexedStops = R.indexBy(R.prop(GC.FIELD_GUID), R.or(props.asyncEvents, []));

  return (
    <LocalLoader width={400} localLoaderOpen={R.isNil(props.asyncEvents)}>
      <SelectDropdownForm
        {...props}
        options={options}
        submitAction={(value: string) => (
          props.handleOpenAddAppointmentForm(R.prop(value, indexedStops))
        )}
      />
    </LocalLoader>
  );
});

const DriverRateFormWithAsyncData = withAsyncModeAndServiceType((props: Object) => (
  <LocalLoader bg='white' width={950} localLoaderOpen={R.isNil(props.asyncData)}>
    <DriverRateForm
      {...props}
      transportationMode={R.path(['asyncData', GC.FIELD_MODE], props)}
      serviceType={R.path(['asyncData', GC.FIELD_SERVICE_TYPE], props)}
    />
  </LocalLoader>
));

const DocumentFormWithAsyncEvents = withAsyncTelEvents((props: Object) => (
  <LocalLoader width={400} localLoaderOpen={R.isNil(props.asyncEvents)}>
    <AddDocumentsForm
      {...props}
      load={R.assoc(GC.FIELD_LOAD_STOPS, props.asyncEvents, props.load)}
    />
  </LocalLoader>
));

const enhanceStatusMessageForm = compose(
  withReasonCodeConfigs,
  withAsyncStatusMessages,
  withAsyncTelEvents,
);

const enhanceMultipleStatusMessageForm = compose(
  withAsyncConfigs,
  withAsyncStatusMessages,
  withAsyncTelEvents,
);

const StatusMessageFormWithAsyncEvents = enhanceStatusMessageForm((props: Object) => {
  const { load, asyncEvents } = props;
  const loading = R.isNil(asyncEvents);
  const width = G.ifElse(loading, 500, 'auto');

  return (
    <LocalLoader p={15} width={width} localLoaderOpen={loading}>
      <StatusMessageForm
        {...props}
        load={R.assoc(GC.FIELD_LOAD_STOPS, asyncEvents, load)}
        mapLocations={G.mapTelEventsForMap(R.or(asyncEvents, []))}
      />
    </LocalLoader>
  );
});

const MultipleStatusMessageFormWithAsyncEvents = enhanceMultipleStatusMessageForm((props: Object) => {
  const { load, asyncEvents, asyncConfigs, statusMessagesConfigs } = props;

  const loading = G.isAnyNilOrEmpty([asyncEvents, statusMessagesConfigs]);
  const allowStopCompleteWithoutArrival = G.getConfigValueFromStore(
    GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL,
    asyncConfigs,
  );
  const events = G.mapTelEventsForMap(R.or(asyncEvents, []));

  return (
    <LocalLoader minWidth={933} localLoaderOpen={loading}>
      <MultipleStatusMessageForm
        {...props}
        events={events}
        load={R.assoc(GC.FIELD_LOAD_STOPS, asyncEvents, load)}
        useArrivedDepartedForm={G.isFalse(allowStopCompleteWithoutArrival)}
      />
    </LocalLoader>
  );
});

const approveRateHandler = (props: Object, initialValues: Object, telGuid: string) => {
  const {
    openModal,
    closeModal,
    approveLoadRateRequest,
  } = props;

  const submitAction = (values: Object) => approveLoadRateRequest({
    ...values,
    [GC.FIELD_TEL_GUID]: telGuid,
  });

  const component = (
    <ApproveRateForm
      closeModal={closeModal}
      submitAction={submitAction}
      initialValues={initialValues}
    />
  );
  const modal = {
    p: 15,
    component,
    options: {
      width: 380,
    },
  };
  openModal(modal);
};

const dispatchHandler = (props: Object) => (data: Object) => {
  const {
    load,
    fromPage,
    dispatchTelRateRequest,
    handleDispatchCarrierRate,
    selectedCarrierDriverRate,
  } = props;

  const { rateMarginViolationInfo } = data;

  const isCarrierRate = R.or(
    G.isNotNil(R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, data)),
    G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data)),
  );

  const isDispatchDetails = R.equals(fromPage, GC.PAGE_DISPATCH_DETAILS_NEW_LOAD);

  const isApproveNeeded = (data: Object = {}) => R.and(
    G.isFalse(G.getPropFromObject(GC.FIELD_MARGIN_VIOLATION_APPROVED, data)),
    G.isAnyTrue(
      G.isTrue(G.getPropFromObject(GC.FIELD_MAX_PAY_VIOLATED, data)),
      G.isTrue(G.getPropFromObject(GC.FIELD_MIN_MARGIN_VIOLATED, data)),
      G.isTrue(G.getPropFromObject(GC.FIELD_CRITICAL_MARGIN_VIOLATED, data)),
    ),
  );

  const conditions = R.or(
    R.and(isDispatchDetails, isApproveNeeded(selectedCarrierDriverRate)),
    R.and(isApproveNeeded(rateMarginViolationInfo), R.equals(fromPage, GC.PAGE_DISPATCH_BOARD_NEW)),
  );

  if (conditions) {
    const objectForInitialValues = G.ifElse(isDispatchDetails, selectedCarrierDriverRate, rateMarginViolationInfo);

    const { marginViolationNote, marginViolationReason } = objectForInitialValues;

    const initialValues = {
      marginViolationNote,
      marginViolationReason: G.getPropFromObject(GC.FIELD_DROPDOWN_OPTION_GUID, marginViolationReason),
    };
    const telGuid = G.getGuidFromObject(G.ifElse(isDispatchDetails, load, data));

    return approveRateHandler(props, initialValues, telGuid);
  }

  if (isCarrierRate) {
    handleDispatchCarrierRate(data);
  } else {
    dispatchTelRateRequest({
      action: GC.DISPATCH_ACTION,
      telGuid: R.prop(GC.FIELD_GUID, data),
    });
  }
};

const addStatusMessageHandler = (props: Object) => (tel: Object) => {
  const loadGuid = R.prop(GC.FIELD_GUID, tel);
  const handleCreateLoadStatusMessage = (values: Object) => {
    const data = {
      ...R.pick(GC.GROUPED_FIELDS.STATUS_MESSAGE_PICK_ARR, values),
      loadGuid,
      telStatusMessageLocation: R.omit(GC.GROUPED_FIELDS.STATUS_MESSAGE_OMIT_ARR, values),
    };
    props.createLoadStatusMessageRequest(data);
  };
  let component;

  if (G.isNotNilAndNotEmpty(tel.events)) {
    component = (
      <Box p={15}>
        <StatusMessageForm
          {...props}
          load={tel}
          entityName={GC.FIELD_TEL}
          mapLocations={G.mapTelEventsForMap(tel.events)}
          handleActionLoadStatusMessage={handleCreateLoadStatusMessage}
          initialValues={{ [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE' }}
        />
      </Box>
    );
  } else {
    component = (
      <StatusMessageFormWithAsyncEvents
        {...props}
        load={tel}
        telGuid={loadGuid}
        entityName={GC.FIELD_TEL}
        mapLocations={props.mappedEventsForMap}
        branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
        handleActionLoadStatusMessage={handleCreateLoadStatusMessage}
        initialValues={{ [GC.UI_FIELD_STATUS_MESSAGE_PROOF_TYPE]: 'NONE' }}
      />
    );
  }
  const modal = {
    p: '0px',
    component,
    options: {
      width: 'min-content',
      height: 'max-content',
    },
  };
  props.openModal(modal);
};

const addMultipleStatusMessageHandler = (props: Object) => (tel: Object) => {
  const { handleAddMultipleStatusMessage } = props;

  if (G.isFunction(handleAddMultipleStatusMessage)) return handleAddMultipleStatusMessage();

  const {
    openModal,
    closeModal,
    createLoadMultipleStatusMessageRequest,
  } = props;

  const telGuid = G.getGuidFromObject(tel);

  const component = (
    <MultipleStatusMessageFormWithAsyncEvents
      load={tel}
      telGuid={telGuid}
      closeModal={closeModal}
      loadType={GC.LOAD_TYPE_TEL}
      branchGuid={R.prop(GC.FIELD_BRANCH_GUID, tel)}
      configsNamesArray={[GC.TEL_GENERAL_ALLOW_STOP_COMPLETE_WITHOUT_ARRIVAL]}
      createMultipleStatusMessage={(data: Object) => createLoadMultipleStatusMessageRequest(R.assoc(
        'loadGuid',
        telGuid,
        data,
      ))}
    />
  );
  const modal = {
    p: 15,
    component,
    options: {
      title: null,
      height: 'auto',
      width: 'max-content',
    },
  };
  openModal(modal);
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectCurrentBranchGuid(state),
  configsByGroup: makeSelectConfigsByGroup(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  declineLoadReasons: makeSelectDeclineLoadReasons(state),
  documentTemplateOptions: makeSelectDocumentTemplates(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
});

const mapStateToProps2 = (state: Object) => createStructuredSelector({
  branchGuid: makeSelectCurrentBranchGuid(state),
  defaultUomFields: makeSelectDefaultUomFields(state),
  declineLoadReasons: makeSelectDeclineReasonOptions(state),
  documentTemplateOptions: makeSelectDocumentTemplates(state),
  availableDocumentTypes: makeSelectAvailableDocumentTypes(state),
});

const withLoadActionsWithoutConnectAndRateEnhance = compose(
  withDispatchCarrierRate,
  withHandlers({
    handleCreateDocument: (props: Object) => (defaultProof: string, tel: Object) => {
      const telGuid = R.prop(GC.FIELD_GUID, tel);
      const handleCreateLoadDocument = (values: Object, callback: Function) => {
        const valuesToUse = G.mapObjectEmptyStringFieldsToNull(values);
        let data;

        if (G.isNotNilAndNotEmpty(R.prop(GC.FIELD_DOCUMENT_UPLOAD, valuesToUse))) {
          const commonProps = R.omit([GC.FIELD_DOCUMENT_UPLOAD], valuesToUse);
          data = R.compose(
            R.map((file: Object) => R.mergeRight({
              [GC.FIELD_DOCUMENT_UPLOAD]: file,
              [GC.FIELD_PRIMARY_OBJECT_GUID]: telGuid,
            }, commonProps)),
            R.prop(GC.FIELD_DOCUMENT_UPLOAD),
          )(valuesToUse);
        } else {
          data = R.of(Array, R.assoc(GC.FIELD_PRIMARY_OBJECT_GUID, telGuid, valuesToUse));
        }

        props.createLoadDocumentsRequest({ data, callback, loadType: GC.LOAD_TYPE_TEL });
      };
      let component;

      if (G.isNotNilAndNotEmpty(tel.events)) {
        component = (
          <AddDocumentsForm
            {...props}
            load={tel}
            telGuid={telGuid}
            entityName={GC.FIELD_TEL}
            handleActionLoadDocument={handleCreateLoadDocument}
            initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(defaultProof, 'NONE') }}
          />
        );
      } else {
        component = (
          <DocumentFormWithAsyncEvents
            {...props}
            load={tel}
            telGuid={telGuid}
            entityName={GC.FIELD_TEL}
            handleActionLoadDocument={handleCreateLoadDocument}
            initialValues={{ [GC.FIELD_DOCUMENT_PROOF_TYPE]: R.or(defaultProof, 'NONE') }}
          />
        );
      }

      const modal = {
        p: 15,
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
          title: G.getWindowLocale('titles:add-doc', 'Add Doc'),
        },
      };
      props.openModal(modal);
    },
    handleDispatchCarrierRate: (props: Object) => (tel: Object) => {
      const {
        dispatchTelRateRequest,
        documentTemplateOptions,
        handleDispatchCarrierRate,
        dispatchCarrierTelRateRequest,
      } = props;

      const telGuid = G.getPropFromObject(GC.FIELD_GUID, tel);
      const branchGuid = G.getPropFromObject(GC.BRANCH_GUID, tel);

      const dispatchCarrierRate = () => {
        const dispatchData = {
          telGuid,
          action: GC.DISPATCH_ACTION,
        };

        dispatchTelRateRequest(dispatchData);
      };

      const callback = (data: Object) => {
        const emailsSuspended = R.prop(GC.FIELD_EMAILS_SUSPENDED, data);

        if (emailsSuspended) {
          G.callFunction(dispatchCarrierRate);
        } else {
          const customHandleSubmit = (values: Object) => {
            const data = R.compose(
              R.mergeRight({ telGuid, mailTo: G.getPropFromObject(GC.FIELD_TO, values) }),
              R.dissoc(GC.FIELD_TO),
            )(values);

            dispatchCarrierTelRateRequest(data);
          };

          const asyncOptions = {
            params: {
              [GC.CURRENT_BRANCH]: branchGuid,
              [GC.FIELD_PRIMARY_OBJECT_GUID]: telGuid,
            },
          };

          const carrierDispatchDocumentTemplateOptions = R.compose(
            R.map(({ guid, name }: Object) => ({
              [GC.FIELD_VALUE]: guid,
              [GC.FIELD_LABEL]: name,
            })),
            R.pathOr([], [GC.DOCUMENT_PRINTABLE_SECTION_CARRIER_DISPATCH_DOCUMENTS]),
          )(documentTemplateOptions);

          const options = {
            tel,
            isMulti: true,
            asyncOptions,
            selectedRate: data,
            customHandleSubmit,
            asyncMethod: 'get',
            withDispatchDocs: true,
            asyncInitialData: { data: tel.documents },
            asyncEndpoint: endpointsMap.telDocumentList,
            branchGuid: G.getPropFromObject(GC.BRANCH_GUID, tel),
            templateOptions: carrierDispatchDocumentTemplateOptions,
            handleDispatchCarrierRateWithTerminalData: dispatchCarrierRate,
          };

          handleDispatchCarrierRate(options);
        }
      };

      const rateGuid = R.prop(GC.FIELD_SELECTED_RATE_GUID, tel);

      H.asyncGetRate(rateGuid, callback);
    },
    handleOpenAddAppointmentForm: (props: Object) => (stop: Object) => {
      const { openModal, closeModal, updateAppointmentRequest } = props;

      const submitAction = (data: Object) => (
        updateAppointmentRequest(R.mergeRight(
          data,
          {
            [GC.FIELD_LOAD_TYPE]: GC.LOAD_TYPE_TEL,
            [GC.FIELD_LOAD_GUID]: R.prop(GC.FIELD_TEL_GUID, stop),
          },
        ))
      );

      const component = (
        <AppointmentsForm
          {...props}
          initialValues={stop}
          submitAction={submitAction}
        />
      );

      const modal = {
        p: '0',
        component,
        options: {
          width: 'min-content',
          height: 'max-content',
        },
      };

      closeModal();
      openModal(modal);
    },
    handleSendToCrossBorderIntegration: (props: Object) => async (data: Object) => {
      const { openModal, createCrossBorderIntegrationRequest } = props;

      const { clos, guid } = data;

      let cloList = clos;

      if (G.isNilOrEmpty(cloList)) {
        try {
          const endpoint = endpointsMap.getTelCloList(guid);

          const res = await sendRequest('get', endpoint);

          const { data, status } = res;

          if (G.isResponseSuccess(status)) {
            cloList = data;
          }
        } catch (error) {
          G.handleException(error, 'handleGetCloList exception');
        }
      }

      const handleCreateCrossBorderIntegration = (props: Object) => {
        const { clos, values, saveAndSend } = props;

        const requestData = {
          clos,
          guid,
          values,
          saveAndSend,
        };

        createCrossBorderIntegrationRequest(requestData);
      };

      const component = (
        <CrossBorderIntegrationForm
          cloList={cloList}
          submitAction={handleCreateCrossBorderIntegration}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          width: 890,
          title: G.getWindowLocale('titles:send-to-cross-border', 'Send To Cross Border'),
        },
      };

      openModal(modal);
    },
  }),
  withCarrierFleetInvoices,
  withSendTelsToExternalSystem,
  withHandlers({
    [GC.OPEN_IN_ROUTE_BUILDER_ACTION]: ({ setInitialRouteGuid, setInitialPlannerData }: Object) => (data: Object) => {
      const { guid, status, events, routeGuid } = data;

      if (routeGuid) {
        setInitialRouteGuid(routeGuid);
        setTimeout(() => G.goToRoute(routesMap.routeBuilder), 0);
      } else {
        // TODO: skip it after adding containers logic to load planner
        if (G.isTrue(G.hasLoadEventsContainer(events))) {
          const message = G.getWindowLocale(
            'messages:cant-use-containers-on-load-planner',
            'Sorry, you can not use the Load Planner with containers load',
          );

          return G.showToastrMessageSimple('info', message);
        }

        if (R.includes(status, [GC.LOAD_STATUS_CANCELED, GC.LOAD_STATUS_DELIVERED])) {
          const message = G.getWindowLocale(
            'messages:cant-use-load-planner-for-trip-in-status',
            'Sorry, you can not use the Load Planner for a trip in this status',
          );

          return G.showToastrMessageSimple('info', message);
        }

        setInitialPlannerData({ telGuid: guid });
        setTimeout(() => G.goToRoute(routesMap.routeBuilderEvents), 0);
      }
    },
    [GC.ACCEPT_LOAD_ACTION]: ({ acceptTelRateRequest }: Object) => (data: Object) => {
      const isCarrierRate = R.or(
        G.isNotNil(R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, data)),
        G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data)),
      );
      acceptTelRateRequest({
        isCarrierRate,
        loadGuid: G.getGuidFromObject(data),
        guid: R.prop(GC.FIELD_SELECTED_RATE_GUID, data),
      });
    },
    [GC.DECLINE_LOAD_ACTION]: (props: Object) => (data: Object) => {
      const {
        openModal,
        closeModal,
        declineLoadReasons,
        declineTelRateRequest,
      } = props;

      const isCarrierRate = R.or(
        G.isNotNil(R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, data)),
        G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, data)),
      );

      const component = (
        <SelectDropdownForm
          optionRequired={false}
          cancelAction={closeModal}
          options={declineLoadReasons}
          fieldLabel={G.getWindowLocale('titles:select-reason', 'Select Reason')}
          submitAction={(value: string) => {
            declineTelRateRequest({
              isCarrierRate,
              telGuid: G.getGuidFromObject(data),
              [GC.FIELD_DECLINE_REASON_CODE_GUID]: value,
              guid: R.prop(GC.FIELD_SELECTED_RATE_GUID, data),
            });
            closeModal();
          }}
        />
      );

      const modal = { p: 15, component, options: {} };

      openModal(modal);
    },
    [GC.DISPATCH_LOAD_ACTION]: dispatchHandler,
    [GC.RE_DISPATCH_LOAD_ACTION]: dispatchHandler,
    [GC.CANCEL_DISPATCHED_LOAD_ACTION]: ({ dispatchTelRateRequest }: Object) => (data: Object) => (
      dispatchTelRateRequest({
        action: GC.CANCEL_DISPATCH_ACTION,
        telGuid: R.prop(GC.FIELD_GUID, data),
      })
    ),
    [GC.CALL_STATUS_CHECK_ACTION]: (props: Object) => (data: Object) => {
      const { openModal, closeModal, callStatusCheckTelRequest } = props;

      const list = R.compose(
        R.of(Array),
        R.prop(GC.FIELD_GUID),
      )(data);
      const component = (
        <ConfirmComponent
          textLocale={G.getWindowLocale('message:common-confirmation', 'Are you sure?')}
        />
      );
      const actions = () => {
        callStatusCheckTelRequest(list);
        closeModal();
      };
      const modal = {
        component,
        options: {
          width: '440px',
          height: 'auto',
          movable: false,
          title: G.getWindowLocale('titles:check-status', 'Check Status'),
          controlButtons: [
            {
              type: 'button',
              action: actions,
              margin: '0 12px 0 0',
              name: G.getWindowLocale('actions:send', 'Send'),
            },
          ],
        },
      };
      openModal(modal);
    },
    [GC.ADD_DRIVER_RATE_ACTION]: (props: Object) => (data: Object) => {
      const { fromPage, openModal, handleCreateTelRate } = props;

      const telGuid = R.prop(GC.FIELD_GUID, data);
      const branchGuid = R.pathOr('', [GC.FIELD_BRANCH_GUID], data);
      const cloGuids = R.compose(
        R.map(R.path([GC.FIELD_FINANCE_RELATION, GC.FIELD_CLO_GUID])),
        R.prop(GC.SYSTEM_LIST_COST_ALLOCATIONS),
      )(data);
      const stopCount = G.ifElse(
        R.equals(fromPage, GC.PAGE_DISPATCH_BOARD_NEW),
        R.add(R.pathOr(0, ['eventsInfo', 'pickupCount'], data), R.pathOr(0, ['eventsInfo', 'dropCount'], data)),
        R.length(R.pathOr([], [GC.FIELD_LOAD_STOPS], data)),
      );

      let modalContent;

      const formOptions = {
        telGuid,
        tel: data,
        stopCount,
        branchGuid,
        shouldSelect: true,
        configsNamesArray: GC.ADD_RATE_CONFIGS_ARRAY,
        handleSendTelRate: (values: Object) => (
          handleCreateTelRate(R.assoc(GC.FIELD_TEL_GUID, telGuid, values))
        ),
      };

      if (R.equals(R.length(cloGuids), 1)) {
        modalContent = (
          <DriverRateFormWithAsyncData cloGuids={cloGuids} {...formOptions} />
        );
      } else {
        modalContent = (
          <DriverRateForm {...formOptions} />
        );
      }

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    [GC.GET_INTEGRATION_DOCUMENTS_ACTION]: ({ getTelIntegrationDocumentsRequest }: Object) => (data: Object) =>
      getTelIntegrationDocumentsRequest(G.getGuidFromObject(data)),
    [GC.SEND_QUOTE_REQUEST_ACTION]: ({ openModal }: Object) => (data: Object) => {
      const component = (
        <SendQuoteRequestForm
          branchGuid={G.getBranchGuidFromObject(data)}
          telGuids={R.of(Array, G.getGuidFromObject(data))}
        />
      );

      const modal = {
        p: 15,
        component,
        options: {
          title: G.getWindowLocale('titles:send-carriers-bids', 'Send Carriers Bids'),
        },
      };

      openModal(modal);
    },
    [GC.ADD_CARRIER_RATE_ACTION]: (props: Object) => (data: Object) => {
      const { openModal, handleCreateCarrierTelRate } = props;

      const { lastEvent, firstEvent, eventsInfo } = data;

      const telGuid = R.prop(GC.FIELD_GUID, data);
      const branchGuid = R.pathOr('', [GC.FIELD_BRANCH_GUID], data);

      let stops = R.pathOr([], [GC.FIELD_LOAD_STOPS], data);
      let stopCount = R.length(stops);

      if (G.isZero(stopCount)) {
        stops = [firstEvent, lastEvent];
        stopCount = R.sum(R.values(R.pick([GC.FIELD_DROP_COUNT, GC.FIELD_PICKUP_COUNT], R.or(eventsInfo, {}))));
      }

      const modalContent = (
        <CarrierRateForm
          stops={stops}
          telGuid={telGuid}
          shouldSelect={true}
          branchGuid={branchGuid}
          stopCount={R.length(stops)}
          configsNamesArray={GC.ADD_CARRIER_RATE_CONFIGS_ARRAY}
          equipmentGuid={R.path([GC.FIELD_LOAD_EQUIPMENT, GC.FIELD_DROPDOWN_OPTION_GUID], data)}
          handleSendTelRate={(values: Object) => (
            handleCreateCarrierTelRate(R.assoc(GC.FIELD_TEL_GUID, telGuid, values))
          )}
        />
      );

      const modal = G.getRateModalWithContent(modalContent);

      openModal(modal);
    },
    [GC.EDIT_DRIVER_CARRIER_RATE_ACTION]: (props: Object) => (tel: Object) => {
      const { openModal, closeModal, handleCreateCarrierTelRate, handleUpdateCarrierTelRate } = props;

      const callback = (data: Object) => {
        let modalContent = null;

        const telGuid = R.prop(GC.FIELD_GUID, tel);
        const telStatus = R.prop(GC.FIELD_STATUS, tel);
        const branchGuid = R.pathOr('', [GC.FIELD_BRANCH_GUID], tel);

        if (G.isNotNil(data.fleetAssignment)) {
          modalContent = (
            <EditDriverRateForm
              telGuid={telGuid}
              telStatus={telStatus}
              initialValues={data}
              branchGuid={branchGuid}
              closeModal={props.closeModal}
              handleSendTelRate={props.handleUpdateTelRate}
              configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
              title={G.getWindowLocale('titles:edit-driver-rate', 'Edit Driver Rate')}
            />
          );
        } else if (G.isCurrentUserTypeCarrier()) {
          modalContent = (
            <EditCarrierRateForUserTypeCarrier
              rate={data}
              openModal={openModal}
              branchGuid={branchGuid}
              closeModal={closeModal}
              handleSendTelRate={handleCreateCarrierTelRate}
              title={G.getWindowLocale('titles:edit-carrier-rate', 'Edit Carrier Rate')}
            />
          );
        } else {
          modalContent = (
            <EditCarrierRateForm
              telGuid={telGuid}
              initialValues={data}
              branchGuid={branchGuid}
              configsNamesArray={GC.EDIT_RATE_CONFIGS_ARRAY}
              handleSendTelRate={handleUpdateCarrierTelRate}
              title={G.getWindowLocale('titles:edit-carrier-rate', 'Edit Carrier Rate')}
            />
          );
        }

        const modal = G.getRateModalWithContent(modalContent);

        openModal(modal);
      };

      const rateGuid = R.prop(GC.FIELD_SELECTED_RATE_GUID, tel);

      H.asyncGetRate(rateGuid, callback);
    },
    [GC.ADD_DRIVER_CARRIER_INVOICE]: (props: Object) => (tel: Object) => {
      const callback = (selectedRate: Object) => {
        const isCarrierRate = R.or(
          G.isNotNil(R.prop(GC.FIELD_CARRIER_ASSIGNMENT_GUID, tel)),
          G.isNotNil(R.prop(GC.FIELD_SHOWN_CARRIER_ASSIGNMENT, tel)),
        );

        if (isCarrierRate) {
          props.handleAddCarrierInvoice({ tel, selectedRate });
        } else {
          props.handleAddFleetInvoice({ tel, selectedRate });
        }
      };
      const rateGuid = R.prop(GC.FIELD_SELECTED_RATE_GUID, tel);
      H.asyncGetRate(rateGuid, callback);
    },
    [GC.ADD_DOCUMENT_ACTION]: (props: Object) => (data: Object) => (
      props.handleCreateDocument(null, data)
    ),
    [GC.ADD_DOCUMENT_POP_ACTION]: (props: Object) => (data: Object) => (
      props.handleCreateDocument(GC.DOCUMENT_PROOF_TYPE_POP, data)
    ),
    [GC.ADD_DOCUMENT_POD_ACTION]: (props: Object) => (data: Object) => (
      props.handleCreateDocument(GC.DOCUMENT_PROOF_TYPE_POD, data)
    ),
    [GC.ADD_APPOINTMENT_ACTION]: (props: Object) => (data: Object) => {
      const { openModal, closeModal, handleOpenAddAppointmentForm } = props;
      const telGuid = G.getGuidFromObject(data);
      let component;

      if (G.isNotNil(R.prop(GC.FIELD_LOAD_STOPS, data))) {
        const stops = R.prop(GC.FIELD_LOAD_STOPS, data);
        const options = getStopOptions(stops);
        const indexedStops = R.indexBy(R.prop(GC.FIELD_GUID), stops);
        component = (
          <SelectDropdownForm
            fieldWidth={400}
            options={options}
            optionRequired={true}
            cancelAction={closeModal}
            fieldLabel={G.getWindowLocale('titles:select-stop', 'Select Stop')}
            submitAction={(value: string) => (
              props.handleOpenAddAppointmentForm(R.prop(value, indexedStops))
            )}
          />
        );
      } else {
        component = (
          <SelectDropdownFormWithAsyncEvents
            fieldWidth={400}
            telGuid={telGuid}
            optionRequired={true}
            cancelAction={closeModal}
            handleOpenAddAppointmentForm={handleOpenAddAppointmentForm}
            fieldLabel={G.getWindowLocale('titles:select-stop', 'Select Stop')}
          />
        );
      }

      const modal = {
        p: 15,
        component,
        options: { width: 430 },
      };
      openModal(modal);
    },
    [GC.SEND_TO_EXTERNAL_SYSTEM_ACTION]: (props: Object) => (data: Object) => {
      const {
        fromPage,
        handleSendTelToExternalSystem,
      } = props;

      const telGuid = G.getGuidFromObject(data);

      handleSendTelToExternalSystem({ telGuid, fromPage });
    },
    [GC.APPLY_ORDER_RATE_UPLIFT_ACTION]: (props: Object) => (data: Object) => {
      const { handleApplyOrderRateUpliftForm } = props;

      const telGuid = G.getGuidFromObject(data);
      handleApplyOrderRateUpliftForm({ telGuid });
    },
    [GC.CHECK_IN_ACTION]: addStatusMessageHandler,
    [GC.COMPLETE_ACTION]: addMultipleStatusMessageHandler,
    [GC.COMPLETE_ACTION]: addMultipleStatusMessageHandler,
    [GC.SEND_UPDATE_EDI_OR_API_ACTION]: (props: Object) => () => {
      const { sendUpdateEdiOrApiRequest } = props;
      const rateGuid = R.path(['data', GC.FIELD_SELECTED_RATE_GUID], props);
      sendUpdateEdiOrApiRequest(rateGuid);
    },
    [GC.SEND_TO_CROSS_BORDER_INTEGRATION_ACTION]: (props: Object) => (data: Object) =>
      props.handleSendToCrossBorderIntegration(data),
  }),
  withHandlers({
    handleActionClick: (props: Object) => (actionName: string, data: Object) => (
      props[actionName](data)
    ),
  }),
);

const commonActions = {
  openModal,
  closeModal,
  setInitialRouteGuid,
  setInitialPlannerData,
  callStatusCheckTelRequest,
  dispatchCarrierTelRateRequest, // TODO: clarify dispatch details
  getTelIntegrationDocumentsRequest,
  createFleetInvoiceByTelGuidRequest,
  createCrossBorderIntegrationRequest,
  createCarrierInvoiceByTelGuidRequest,
  suspendResumeCarrierRateEmailsRequest,
  createLoadMultipleStatusMessageRequest,
};

const actionsProps = {
  ...commonActions,
  acceptTelRateRequest,
  declineTelRateRequest,
  dispatchTelRateRequest,
  approveLoadRateRequest,
  updateAppointmentRequest,
  createFleetInvoiceRequest,
  sendUpdateEdiOrApiRequest,
  createLoadDocumentsRequest,
  createCarrierInvoiceRequest,
  dispatchCarrierTelRateRequest,
  sendTelToExternalSystemRequest,
  createLoadStatusMessageRequest,
  createLoadMultipleStatusMessageRequest,
};

export const withDispatchDetailsLoadActions = compose(
  withProps({ fromPage: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  connect(mapStateToProps2, commonActions),
  withOrderRateSyncWithTrips({ updateValues: false, fromPage: GC.PAGE_DISPATCH_DETAILS_NEW_LOAD }),
  withApplyOrderRateUpliftForm({ submitActionName: 'handleSyncWithTrips' }),
  enhanceRateCharges('rateLoadDetailsNew'),
  withLoadActionsWithoutConnectAndRateEnhance,
);

const withDispatchBoardLoadActions = compose(
  withProps({ fromPage: GC.PAGE_DISPATCH_BOARD_NEW }),
  connect(mapStateToProps, actionsProps),
  withOrderRateSyncWithTrips({ updateValues: false, fromPage: GC.PAGE_DISPATCH_BOARD_NEW }),
  withApplyOrderRateUpliftForm({ submitActionName: 'handleSyncWithTrips' }),
  enhanceRateCharges(),
  withLoadActionsWithoutConnectAndRateEnhance,
);

export default withDispatchBoardLoadActions;

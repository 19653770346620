import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
import { PopperComponent } from '../../../components/popper';
import { TextCollapse } from '../../../components/text-collapse';
// features
import { Ranges } from '../../dispatch-planner/components/stop';
import StopTrailers from '../../dispatch-planner/components/stop-trailers';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, AbsoluteBox, RelativeFlex } from '../../../ui';
// feature dispatch-planner-events
import * as H from '../helpers';
import StopItems from './stop-items';
import { CLO_NAME } from '../constants';
//////////////////////////////////////////////////

const renderErrors = (items: Array, tel: Object) => items.map((item: Object, i: number) => {
  const loadName = G.ifElse(
    G.isLoadTypeClo(item),
    R.pathOr('', [CLO_NAME], item),
    H.getLoadName(tel, G.getWindowLocale('titles:unknown', 'Unknown')),
  );

  return `${R.prop(GC.FIELD_ITEM_ID, item)} (${loadName})${G.ifElse(R.lt(i, R.dec(R.length(items))), ', ', '')}`;
});

const ErrorContent = ({ tel, error }: Object) => {
  const color = G.getTheme('colors.light.mainRed');
  const items = R.prop(GC.FIELD_LOAD_ITEMS, error);

  return (
    <Box p='10px' maxWidth={240} color={color}>
      {
        G.isNotNil(R.prop(GC.FIELD_DATE, error)) &&
        <Box p='4px 0' borderBottom='1px solid' borderColor={color}>
          {G.getWindowLocale(
            'validation:event-wrong-date',
            'The date for this event is not in order with the other events',
          )}
        </Box>
      }
      {
        G.isNotNil(items) &&
        <Flex
          p='4px 0'
          borderColor={color}
          flexDirection='column'
          alignItems='flex-start'
          borderBottom='1px solid'
        >
          <Box>
            {G.getWindowLocale(
              'validation:cant-drop-items-before-pickup',
              'Items can\'t be dropped before they\'ve been picked up',
            )}:
          </Box>
          <Box>{renderErrors(items, tel)}</Box>
        </Flex>
      }
    </Box>
  );
};

const StopErrors = (props: Object) => (
  <PopperComponent
    zi={21}
    type='hover'
    position='right'
    content={<ErrorContent {...props} />}
    borderColor={G.getTheme('listActions.borderColor')}
  >
    <Box cursor='pointer'>
      {I.warningIcon()}
    </Box>
  </PopperComponent>
);

const DistanceToStop = ({ stop, loadType }: Object) => {
  const distanceObject = R.prop(G.getDistancePropByLoadType(loadType), stop);
  const manualDistance = R.pathOr(null, [GC.FIELD_DISTANCE_MANUAL], distanceObject);

  return (
    <Box>
      <Flex>
        <TextComponent
          fontSize={11}
          whiteSpace='normal'
          wordBreak='break-word'
        >
          {G.getWindowLocale('titles:distance-to-next-stop', 'Distance to next stop')}:
        </TextComponent>
        <Box ml='4px'>
          <TextComponent
            fontSize={11}
            fontWeight='bold'
          >
            {R.isNil(distanceObject) && G.getWindowLocale('titles:not-calculated', 'Not Calculated')}
            {G.isNotNil(distanceObject) && H.getStopDistanceInfo(manualDistance, distanceObject)}
          </TextComponent>
          {
            G.isNotNil(manualDistance) &&
            <TextComponent
              ml='3px'
              p='0 3px'
              bg={G.getTheme('colors.dark.blue')}
              color={G.getTheme('colors.light.mainLight')}
            >
              M
            </TextComponent>
          }
        </Box>
      </Flex>
    </Box>
  );
};

const Header = (props: Object) => {
  const {
    tel,
    stop,
    error,
    lastStopInTel,
    handleClickEditStop,
    handleOpenCloDetails,
    handleClickRemoveStop,
    handleRemoveCloStopFromPlanner,
  } = props;

  const isDrop = G.isStopDrop(stop);
  const isClo = G.isLoadTypeClo(stop);
  const cloName = R.pathOr('', [CLO_NAME], stop);
  const blueColor = G.getTheme('colors.dark.blue');

  return (
    <Box height={80} bg={G.getTheme('colors.light.lightGrey')}>
      <Flex mx='8px' height={45} borderBottom='1px solid' borderColor={G.getTheme('colors.dark.grey')}>
        <Flex
          width={30}
          height={30}
          borderRadius='50%'
          justifyContent='center'
          bg={R.prop('headerBg', G.renderStopColor(stop))}
          color={G.getTheme(G.ifElse(isDrop, 'colors.white', 'colors.light.black'))}
        >
          {
            G.ifElse(isClo, '', G.ifElse(G.isStopTypeTerminal(R.prop(GC.FIELD_STOP_TYPE, stop)), 'T', 'I'), '')
          }{
            R.pathOr('', ['eventType', 0], stop)
          }{R.prop(GC.FIELD_TEL_EVENT_INDEX, stop)}
        </Flex>
        <Ranges stop={stop} />
        <Flex ml='auto'>
          <RelativeFlex>
            {
              G.isNotNil(error) &&
              <AbsoluteBox right={295} zIndex={11}>
                <StopErrors tel={tel} error={error} />
              </AbsoluteBox>
            }
            {
              isClo &&
              <Box
                mr='6px'
                width={65}
                fontSize={10}
                cursor='pointer'
                color={blueColor}
                onClick={() => handleRemoveCloStopFromPlanner(stop)}
              >
                {G.getWindowLocale('actions:remove-from-planner', 'Remove From Planner')}
              </Box>
            }
            {
              R.not(isClo) &&
              <Box
                mr='3px'
                width={15}
                height={18}
                cursor='pointer'
                onClick={() => handleClickRemoveStop(stop)}
              >
                {I.trash(blueColor)}
              </Box>
            }
            <Box
              mr='3px'
              width={15}
              height={18}
              cursor='pointer'
              onClick={() => handleClickEditStop(stop)}
            >
              {I.pencil(blueColor)}
            </Box>
          </RelativeFlex>
        </Flex>
      </Flex>
      <Flex mx='8px' height={34} fontSize={11} justifyContent='space-between'>
        {
          isClo &&
          <Flex>
            <Box>{G.getWindowLocale('titles:clo', 'CLO')}:</Box>
            <TextComponent
              ml='4px'
              maxWidth={150}
              title={cloName}
              display='block'
              cursor='pointer'
              fontWeight='bold'
              color={blueColor}
              withEllipsis={true}
              onClick={() => handleOpenCloDetails(G.getPropFromObject(GC.FIELD_CLO_GUID, stop))}
            >
              {cloName}
            </TextComponent>
          </Flex>
        }
        {
          R.not(lastStopInTel) && <DistanceToStop stop={stop} loadType={GC.FIELD_TEL} />
        }
        {
          lastStopInTel &&
          <TextComponent
            fontSize={11}
            fontWeight='bold'
            whiteSpace='normal'
            wordBreak='break-word'
          >
            {G.getWindowLocale('titles:end-of-trip', 'End of Trip')}
          </TextComponent>
        }
      </Flex>
    </Box>
  );
};

export const LocationInfo = ({ width, status, location, withComments }: Object) => (
  <Box
    p='8px'
    fontSize={11}
    fontWeight='bold'
    width={R.or(width, '50%')}
  >
    {
      G.isNotNilAndNotEmpty(status) &&
      <Flex mb='6px'>
        <Box mr='6px'>{G.getWindowLocale('titles:status', 'Status')}:</Box>
        <Box fontWeight='normal'>{G.getEnumLocale(status)}</Box>
      </Flex>
    }
    <Box mb='3px'>{R.path([GC.FIELD_LOCATION_NAME], location)}</Box>
    <Box mb='3px'>{G.concatLocationFirstAndSecondAddresses(location)}</Box>
    <Box mb='8px'>{G.concatLocationFields(location)}</Box>
    {
      withComments &&
      <Box>
        <TextComponent fontWeight='normal'>
          {G.getWindowLocale('titles:comments', 'Comments')}:
        </TextComponent>
        <TextCollapse
          lessHeight={100}
          rootProps={{ maxHeight: 400, overflow: 'auto' }}
          text={R.pathOr('', [GC.FIELD_COMMENTS], location)}
        />
      </Box>
    }
  </Box>
);

export const MainInfo = (props: Object) => {
  const { stop } = props;

  const integration = G.createEventIntegrationString(stop);

  return (
    <Flex position='relative' alignItems='stretch' bg={G.getTheme('colors.white')}>
      <LocationInfo
        withComments={true}
        status={R.path(['stop', GC.FIELD_STATUS], props)}
        location={R.path(['stop', GC.SYSTEM_OBJECT_LOCATION], props)}
      />
      <Box
        p='8px'
        width='50%'
        fontSize={11}
        bg={G.getTheme('colors.white')}
        borderColor={G.getTheme('colors.light.darkGrey')}
      >
        <Box mb='3px' wordBreak='break-all'>
          {G.getWindowLocale('titles:appointment-number', 'Appointment Number')}:
        </Box>
        <Box mb='8px' fontWeight='bold'>{R.path(['stop', GC.FIELD_LOAD_APPOINTMENT_NUMBER], props)}</Box>
        <Box mb='3px'>{G.getWindowLocale('titles:appointment-date', 'Appointment Date')}:</Box>
        <Box mb='8px' fontWeight='bold'>{G.createAppointmentDateTimeString(stop)}</Box>
        { integration && <Box mb='3px'>{G.getWindowLocale('titles:integration', 'Integration')}:</Box> }
        { integration && <Box fontWeight='bold'>{integration}</Box> }
      </Box>
    </Flex>
  );
};

const Stop = (props: Object) => {
  const {
    tel,
    stop,
    error,
    cloName,
    lastStopInTel,
    expandedEvents,
    useStopTrailers,
    handleClickEditStop,
    handleOpenCloDetails,
    handleClickRemoveStop,
    handleRemoveCloStopFromPlanner,
  } = props;

  return (
    <Box
      m='0 10px'
      width={340}
      borderRadius='4px'
      order={props.order}
      boxShadow={`0 2px 5px 0 ${G.getTheme('colors.boxShadowGrey')}`}
    >
      <Header
        tel={tel}
        stop={stop}
        error={error}
        lastStopInTel={lastStopInTel}
        handleClickEditStop={handleClickEditStop}
        handleOpenCloDetails={handleOpenCloDetails}
        handleClickRemoveStop={handleClickRemoveStop}
        handleRemoveCloStopFromPlanner={handleRemoveCloStopFromPlanner}
      />
      <MainInfo
        stop={stop}
        cloName={cloName}
        loadType={GC.FIELD_TEL}
        expanded={expandedEvents}
      />
      { useStopTrailers && <StopTrailers {...props} /> }
      <StopItems {...props} />
    </Box>
  );
};

export default Stop;

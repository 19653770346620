import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// common
import { changeSalesOrAgentsSuccess, toggleMarkAsHotLoadByLoadTypeSuccess } from '../../../common/actions';
// features
import { approveCloSuccess } from '../../dispatch-details-new/order/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// feature dispatch-report/load
import * as A from './actions';
import * as H from '../helpers';
//////////////////////////////////////////////////

const initialState = {
  summary: {},
  groupBy: null,
  totalCount: 0,
  loading: false,
  itemList: null,
  usedReport: null,
  filterParams: {},
  titleSortValues: {},
  reportPending: false,
  globalFilterValue: '',
  tableTitleFilters: {},
  availableReports: null,
  shipUnitsExpanded: false,
  globalFilterType: GC.GLOBAL_SEARCH_TYPE_ALL,
  pagination: {
    limit: 15,
    offset: 0,
  },
};

const setGlobalFilterType = (state: Object, data: string) => (
  P.$set('globalFilterType', data, state)
);

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const setReportPending = (state: Object) => (
  P.$set('reportPending', true, state)
);

const setReports = (state: Object, data: Array) => (
  P.$set('availableReports', data, state)
);

const resetListAndPagination = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setUsedReport = (state: Object, data: Object) => (
  P.$all(
    P.$set('totalCount', 0),
    P.$set('itemList', null),
    P.$set('usedReport', data),
    P.$set('titleSortValues', {}),
    P.$set('reportPending', false),
    P.$set('tableTitleFilters', {}),
    P.$set('globalFilterValue', ''),
    P.$set('pagination', initialState.pagination),
    P.$set('groupBy', R.pathOr(GC.ORGANIZE_BY_NO_GROUPING, [GC.FIELD_GROUP_BY], data)),
    state,
  )
);

const setUsedReportOnly = (state: Object, data: Object) => (
  P.$all(
    P.$set('usedReport', data),
    P.$set('globalFilterValue', ''),
    state,
  )
);

const setGroupBy = (state: Object, groupBy: string) => (
  P.$all(
    P.$set('groupBy', groupBy),
    P.$set('globalFilterValue', ''),
    state,
  )
);

const getItemListSuccess = (state: Object, data: Object) => {
  const results = H.getIndexedResults(data.results);
  const firstIncomeElem = R.head(results);
  if (R.isNil(firstIncomeElem)) return state;
  const { itemList, pagination } = state;
  let newItemList = results;
  if (G.isNotNil(itemList)) {
    newItemList = [...itemList, ...results];
    const lastListElem = R.last(itemList);
    if (R.equals(firstIncomeElem.groupingObject, lastListElem.groupingObject)) {
      const newLoads = R.mergeRight(lastListElem.loads, firstIncomeElem.loads);
      const newMergedRoute = R.assoc('loads', newLoads, firstIncomeElem);
      const init = R.init(itemList);
      const tail = R.tail(results);
      newItemList = init.concat(R.of(Array, newMergedRoute), tail);
    }
  }
  const newOffset = R.add(pagination.offset, pagination.limit);
  return P.$all(
    P.$set('itemList', newItemList),
    P.$set('totalCount', data.totalCount),
    P.$set(
      'pagination.offset',
      G.ifElse(
        R.gt(data.totalCount, newOffset),
        newOffset,
        data.totalCount,
      ),
    ),
    state,
  );
};

const selectItem = (state: Object, data: Object) => {
  const { itemList } = state;
  if (R.equals(data, 'all')) {
    const value = R.not(R.all(
      (item: Object) => item.selected,
      R.values(itemList),
    ));
    return P.$set(
      'itemList',
      R.map(
        (item: Object) => R.assoc(
          'selected',
          value,
          item,
        ),
        itemList,
      ),
      state,
    );
  }
  return P.$toggle(`itemList.${data}.selected`, state);
};

const cleanQuickFilter = (state: Object) => (
  P.$all(
    P.$set('itemList', null),
    P.$set('filterParams', {}),
    P.$set('pagination', initialState.pagination),
    state,
  )
);

const setQuickFilterParams = (state: Object, data: Object) => (
  P.$set('filterParams', data, state)
);

const deleteItemSuccess = (state: Object, data: string) => {
  const { guid, tableIndex } = data;
  const totalCount = G.ifElse(R.equals(state.totalCount, 0), 0, R.dec(state.totalCount));
  const condition = R.compose(
    R.equals(1),
    R.length,
    R.values,
    R.path(['itemList', tableIndex, 'loads']),
  )(state);
  if (condition) {
    return P.$all(
      P.$set('totalCount', totalCount),
      P.$drop(`itemList.${tableIndex}`),
      state,
    );
  }
  return P.$all(
    P.$set('totalCount', totalCount),
    P.$drop(`itemList.${tableIndex}.loads.${guid}`),
    state,
  );
};

const setGlobalFilterValue = (state: Object, data: string) => (
  P.$set('globalFilterValue', data, state)
);

const getSummarySuccess = (state: Object, data: Object) => (
  P.$set('summary', data, state)
);

const toggleOrderDetailsSuccess = (state: Object, { guid, data }: Object) => (
  P.$set('itemList', H.setDataToItemListItem(state.itemList, guid, data), state)
);

const getOrderDetailsSuccess = (state: Object, data: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItem(
      H.setDataToItemListItemDetails(state.itemList, G.getGuidFromObject(data), data),
      G.getGuidFromObject(data),
      H.getDataFromLoadForTableInfo(data, state.itemList),
    ),
    state,
  )
);

const getOrderDispatchBoardDetailsSuccess = (state: Object, data: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItem(
      G.getPropFromObject('itemList', state),
      G.getGuidFromObject(data),
      R.mergeRight(data, { details: {}, expanded: false, shownDetails: null }),
    ),
    state,
  )
);

const getOrderSelectedRateSuccess = (state: Object, data: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, G.getGuidFromObject(data), { selectedRate: data.rateData }),
    state,
  )
);

const getOrdersShipUnitSuccess = (state: Object, { data, guids }: Object) => {
  let itemList = R.prop('itemList', state);
  R.forEach(
    (guid: string) => {
      itemList = H.setDataToItemListItem(
        itemList,
        guid,
        { expanded: true, shownDetails: 'SHIP_UNIT' },
      );

      if (G.isNotNilAndNotEmpty(R.prop(guid, data))) {
        itemList = H.setDataToItemListItemDetails(itemList, guid, R.prop(guid, data));
      }
    },
    guids,
  );

  return P.$set('itemList', itemList, state);
};

const getOrderDocumentListSuccess = (state: Object, { guid, documents }: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, guid, { documents }),
    state,
  )
);

const updateOrderDocumentSuccess = (state: Object, data: Object) => {
  const { loadGuid } = data;
  const oldDocuments = H.getDataFromItemListItemDetails(state.itemList, loadGuid, 'documents');
  const documents = R.map((document: Object) => {
    if (R.eqProps(GC.FIELD_GUID, document, data)) return data;
    return document;
  }, oldDocuments);
  return P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, loadGuid, { documents }),
    state,
  );
};

const removeOrderDocumentSuccess = (state: Object, data: Object) => {
  const { loadGuid } = data;
  const oldDocuments = H.getDataFromItemListItemDetails(state.itemList, loadGuid, 'documents');
  const documents = R.filter((document: Object) => R.not(R.eqProps(GC.FIELD_GUID, document, data)), oldDocuments);
  return P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, loadGuid, { documents }),
    state,
  );
};

const getOrderInvoicesSuccess = (state: Object, { guid, invoices }: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, guid, { invoices }),
    state,
  )
);

const getOrderInvoiceTotalsSuccess = (state: Object, { guid, invoiceTotals }: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, guid, { invoiceTotals }),
    state,
  )
);

const toggleMarkAsHotLoadByLoadType = (state: Object, data: Object) => {
  const { guid, loadType, fromPage, tableIndex } = data;

  if (R.or(G.notEquals(fromPage, 'dispatchBoard'), G.isFalse(G.isLoadTypeClo(loadType)))) return state;

  return P.$toggle(`itemList.${tableIndex}.loads.${guid}.hot`, state);
};

const changeSalePersonsSuccess = (state: Object, { data, fromPage, loadType, loadGuid, tableIndex }: Object) => {
  if (R.or(G.notEquals(fromPage, 'dispatchBoard'), G.isFalse(G.isLoadTypeClo(loadType)))) return state;

  return P.$set(`itemList.${tableIndex}.loads.${loadGuid}.details.salePersons`, data, state);
};

const updateContainerCallback = (state: Object, { item, container }: Object) => {
  const { itemList } = state;

  const { loadGuid } = item;

  const oldInfo = H.getDataFromItemListItem(itemList, loadGuid, 'containersInfo');

  const fieldsToPick = [
    GC.FIELD_GUID,
    GC.FIELD_LOAD_GUID,
    GC.FIELD_CONTAINER_NUMBER,
    GC.FIELD_CONTAINER_INITIAL,
    GC.FIELD_RAIL_BILLING_STATUS,
  ];

  const containersInfo = R.map((item: Object) => {
    if (R.eqProps(GC.FIELD_GUID, item, container)) return R.pick(fieldsToPick, container);

    return item;
  }, oldInfo);

  return P.$set(
    'itemList',
    H.setDataToListItemByPropName(itemList, loadGuid, containersInfo, 'containersInfo'),
    state,
  );
};

const getBranchConfigsByCloBranchGuidSuccess = (state: Object, { guid, loadConfigs }: Object) => (
  P.$set(
    'itemList',
    H.setDataToItemListItemDetails(state.itemList, guid, { loadConfigs }),
    state,
  )
);

const approveCloByCloGuidSuccess = (state: Object, { guid, fromPage, tableIndex }: Object) => {
  if (G.notEquals(fromPage, 'dispatchBoard')) return state;

  return P.$all(
    P.$set(`itemList.${tableIndex}.loads.${guid}.details.approved`, true),
    P.$set(`itemList.${tableIndex}.loads.${guid}.details.approvedBy`, G.getAmousCurrentUserLoginIdFromWindow()),
    state,
  );
};

export default createReducer({
  [A.setReports]: setReports,
  [A.selectItem]: selectItem,
  [A.setGroupBy]: setGroupBy,
  [A.setUsedReport]: setUsedReport,
  [A.setListLoading]: setListLoading,
  [A.setInitialState]: setInitialState,
  [A.setReportPending]: setReportPending,
  [A.cleanQuickFilter]: cleanQuickFilter,
  [A.deleteItemSuccess]: deleteItemSuccess,
  [A.getSummarySuccess]: getSummarySuccess,
  [A.setUsedReportOnly]: setUsedReportOnly,
  [A.getItemListSuccess]: getItemListSuccess,
  [A.setTableTitleSort]: G.setTableTitleSort,
  [A.setGlobalFilterType]: setGlobalFilterType,
  [A.setTableTitleFilter]: G.setTableTitleFilter,
  [A.setQuickFilterParams]: setQuickFilterParams,
  [A.setGlobalFilterValue]: setGlobalFilterValue,
  [A.setShipUnitsExpanded]: H.setShipUnitsExpanded,
  [A.resetListAndPagination]: resetListAndPagination,
  [A.getOrderDetailsSuccess]: getOrderDetailsSuccess,
  [A.updateContainerCallback]: updateContainerCallback,
  [A.getOrderInvoicesSuccess]: getOrderInvoicesSuccess,
  [A.getOrdersShipUnitSuccess]: getOrdersShipUnitSuccess,
  [A.toggleOrderDetailsSuccess]: toggleOrderDetailsSuccess,
  [A.updateOrderDocumentSuccess]: updateOrderDocumentSuccess,
  [A.removeOrderDocumentSuccess]: removeOrderDocumentSuccess,
  [A.getOrderSelectedRateSuccess]: getOrderSelectedRateSuccess,
  [A.getOrderDocumentListSuccess]: getOrderDocumentListSuccess,
  [A.getOrderInvoiceTotalsSuccess]: getOrderInvoiceTotalsSuccess,
  [A.setDataToListItemByPropName]: H.setDataToListItemByPropNameReducer,
  [A.setDataToItemListItemDetails]: H.setDataToItemListItemDetailsReducer,
  [A.getOrderDispatchBoardDetailsSuccess]: getOrderDispatchBoardDetailsSuccess,
  [A.getBranchConfigsByCloBranchGuidSuccess]: getBranchConfigsByCloBranchGuidSuccess,
  //
  [approveCloSuccess]: approveCloByCloGuidSuccess,
  [changeSalesOrAgentsSuccess]: changeSalePersonsSuccess,
  [toggleMarkAsHotLoadByLoadTypeSuccess]: toggleMarkAsHotLoadByLoadType,
}, initialState);

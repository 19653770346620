import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { ENUMS } from '../../constants/enums';
// features
import { SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED } from '../sockets/constants';
import {
  socketAvailableDriversNoteReceived,
  socketAvailableDriversStatusReceived,
  socketAvailableDriversLocationReceived,
  socketAvailableDriversReservationReceived,
} from '../sockets/actions';
// feature available-driver
import * as A from './actions';
//////////////////////////////////////////////////

const commonStateProps = {
  totalCount: 0,
  itemList: null,
  loading: false,
};

const initialState = {
  ...commonStateProps,
  truckTypes: [],
  searchRadius: '',
  locationValue: '',
  pageVisited: false,
  driverInitials: '',
  searchLocation: null,
  searchDate: G.getCurrentDay(),
  activeList: 'availableDrivers',
  allDrivers: {
    ...commonStateProps,
    pagination: {
      limit: 20,
      offset: 0,
    },
  },
};

const setInitialState = () => (
  initialState
);

const setListLoading = (state: Object, data: boolean) => (
  P.$set('loading', data, state)
);

const getItemListSuccess = (state: Object, { driverList }: Object) => {
  const list = R.indexBy(R.prop(GC.FIELD_GUID), driverList);

  return P.$all(
    P.$set('itemList', list),
    P.$set('pageVisited', true),
    P.$set('totalCount', R.length(driverList)),
    state,
  );
};

const setSearchDate = (state: Object, data: string) => (
  P.$set('searchDate', data, state)
);

const setTruckTypes = (state: Object, data: Array) => (
  P.$set('truckTypes', data, state)
);

const setSearchRadius = (state: Object, data: number) => (
  P.$set('searchRadius', data, state)
);

const setLocationValue = (state: Object, data: string) => (
  P.$set('locationValue', data, state)
);

const setDriverInitials = (state: Object, data: string) => (
  P.$set('driverInitials', data, state)
);

const setSearchLocation = (state: Object, data: any) => (
  P.$set('searchLocation', data, state)
);

const setActiveList = (state: Object, data: string) => (
  P.$set('activeList', data, state)
);

// all drivers
const getAllDriversListSuccess = (state: Object, data: Object) => {
  const { allDrivers } = state;

  const { itemList, totalCount, pagination } = allDrivers;

  const { limit, offset } = pagination;

  const newOffset = R.add(offset, limit);

  const newList = R.mergeRight(itemList, R.indexBy(R.prop(GC.FIELD_GUID), data));

  return P.$all(
    P.$set('allDrivers.itemList', newList),
    P.$set('allDrivers.pagination.limit', 10),
    P.$set(
      'allDrivers.pagination.offset',
      G.ifElse(
        R.gt(totalCount, newOffset),
        newOffset,
        totalCount,
      ),
    ),
    state,
  );
};

const setAllDriversListLoading = (state: Object, data: boolean) => (
  P.$set('allDrivers.loading', data, state)
);

const resetAllDriversListAndPagination = (state: Object) => (
  P.$all(
    P.$set('allDrivers.totalCount', 0),
    P.$set('allDrivers.itemList', null),
    P.$set('allDrivers.pagination', R.path(['allDrivers', 'pagination'], initialState)),
    state,
  )
);

const getDriverAvailabilityTotalCountSuccess = (state: Object, data: number) => (
  P.$set('allDrivers.totalCount', data, state)
);

// sockets
const getDriverPath = (type: string, driverGuid: string) => G.ifElse(
  R.equals(type, SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED),
  `allDrivers.itemList.${driverGuid}`,
  `itemList.${driverGuid}`,
);

const handleWSAvailableDriversReservationReceived = (state: Object, { data, type, driverGuid }: Object) => {
  const driver = getDriverPath(type, driverGuid);

  return P.$all(
    P.$set(`${driver}.reserved`, R.path(['data', GC.FIELD_RESERVED], data)),
    P.$set(`${driver}.reservedBy`, R.path(['data', GC.FIELD_RESERVED_BY], data)),
    P.$set(`${driver}.reservationEndDate`, R.path(['data', 'reservationEndDate'], data)),
    state,
  );
};
const handleWSAvailableDriversNoteReceived = (state: Object, { type, driverGuid, data: { data: newNote } }: Object) => {
  const driver = getDriverPath(type, driverGuid);

  return P.$set(`${driver}.note`, newNote, state);
};

const handleWSAvailableDriversLocationReceived = (state: Object, { data, type, driverGuid }: Object) => {
  const { itemList, allDrivers, searchLocation } = state;

  const { itemList: allDriversList } = allDrivers;

  const itemListToUse = G.ifElse(
    R.equals(type, SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED),
    allDriversList,
    itemList,
  );

  if (G.isNotNilAndNotEmpty(R.path([driverGuid, GC.FIELD_TEL_GUID], itemListToUse))) return state;

  const { data: newLocation } = data;

  let distance = R.path([driverGuid, GC.FIELD_DISTANCE], itemListToUse);

  if (G.isNotNilAndNotEmpty(searchLocation)) {
    distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, newLocation));
  }

  const driver = getDriverPath(type, driverGuid);

  return P.$all(
    P.$set(`${driver}.distance`, distance),
    P.$set(`${driver}.location`, newLocation),
    state,
  );
};

const handleWSAvailableDriversStatusReceived = (state: Object, { data, type, driverGuid }: Object) => {
  const { itemList, allDrivers, searchLocation } = state;

  const { itemList: allDriversList } = allDrivers;

  const itemListToUse = G.ifElse(
    R.equals(type, SOCKET_CHANNEL_AVAILABLE_DRIVERS_ALL_DRIVERS_RECEIVED),
    allDriversList,
    itemList,
  );

  const driver = getDriverPath(type, driverGuid);

  if (G.isNotNilAndNotEmpty(R.path([driverGuid, GC.FIELD_TEL_GUID], itemListToUse))) {
    return P.$set(`${driver}.status`, R.path(['data', GC.FIELD_STATUS], data), state);
  }

  let distance = R.path([driverGuid, GC.FIELD_DISTANCE], itemListToUse);
  const futureAvailabilityLocation = R.path([driverGuid, 'futureAvailabilityLocation'], itemListToUse);

  const status = R.path(['data', GC.FIELD_STATUS], data);
  const availableAtLocation = R.path(['data', 'availableAtLocation'], data);

  if (G.isNotNilAndNotEmpty(searchLocation)) {
    if (R.equals(status, ENUMS.ENUM_AVAILABLE_IN_FUTURE)) {
      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, availableAtLocation));
    }

    if (R.and(R.equals(status, ENUMS.ENUM_UNAVAILABLE), G.isNotNilAndNotEmpty(futureAvailabilityLocation))) {
      const location = R.path([driverGuid, GC.FIELD_LOCATION], itemListToUse);

      distance = G.mathRoundNumber(G.getDistanceBetweenTwoPoints(searchLocation, location));
    }
  }

  return P.$all(
    P.$set(`${driver}.distance`, distance),
    P.$set(`${driver}.status`, R.path(['data', GC.FIELD_STATUS], data)),
    P.$set(`${driver}.futureAvailabilityLocation`, availableAtLocation),
    P.$set(`${driver}.statusSource`, R.path(['data', 'statusSource'], data)),
    P.$set(`${driver}.futureAvailabilityDate`, R.path(['data', 'availableAtDate'], data)),
    state,
  );
};

export default createReducer({
  [A.setSearchDate]: setSearchDate,
  [A.setTruckTypes]: setTruckTypes,
  [A.setActiveList]: setActiveList,
  [A.setListLoading]: setListLoading,
  [A.setSearchRadius]: setSearchRadius,
  [A.setInitialState]: setInitialState,
  [A.setLocationValue]: setLocationValue,
  [A.setDriverInitials]: setDriverInitials,
  [A.setSearchLocation]: setSearchLocation,
  [A.getItemListSuccess]: getItemListSuccess,
  // all drivers
  [A.setAllDriversListLoading]: setAllDriversListLoading,
  [A.getAllDriversListSuccess]: getAllDriversListSuccess,
  [A.resetAllDriversListAndPagination]: resetAllDriversListAndPagination,
  [A.getDriverAvailabilityTotalCountSuccess]: getDriverAvailabilityTotalCountSuccess,
  // sockets
  [socketAvailableDriversNoteReceived]: handleWSAvailableDriversNoteReceived,
  [socketAvailableDriversStatusReceived]: handleWSAvailableDriversStatusReceived,
  [socketAvailableDriversLocationReceived]: handleWSAvailableDriversLocationReceived,
  [socketAvailableDriversReservationReceived]: handleWSAvailableDriversReservationReceived,
}, initialState);

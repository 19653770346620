import * as R from 'ramda';
import { connect } from 'react-redux';
import React, { useCallback } from 'react';
import { createStructuredSelector } from 'reselect';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// features
import { makeSelectLoadBoardConfigList } from '../../configurations/selectors';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
// feature load-board
import * as H from '../helpers';
import { makeSelectAuthorizedLoadBoards } from '../selectors';
//////////////////////////////////////////////////

const black = G.getTheme('colors.black');
const white = G.getTheme('colors.white');
const darkGrey = G.getTheme('colors.darkGrey');
const yellow = G.getTheme('colors.light.yellow');
const blueColor = G.getTheme('colors.light.blue');
const lightGrey = G.getTheme('colors.dark.lightGrey');

const Configurations = ({ configuredLoadBoards }: Object) => {
  const configurationTabUri = `${routesMap.configIntegration}?tab=${GC.TAB_INTEGRATION_LOAD_BOARDS}`;

  const goToConfiguration = useCallback(() => G.goToRoute(configurationTabUri), []);

  return (
    <Flex
      p={10}
      flex='1'
      bg={white}
      borderRadius='4px'
      flexDirection='column'
      alignItems='flex-start'
    >
      <Box
        mb={10}
        color={darkGrey}
        textAlign='center'
        width='-webkit-fill-available'
      >
        {G.getWindowLocale('titles:configurations', 'Configurations')}
      </Box>
      <Flex flexDirection='column' gap={16} alignItems='flex-start'>
        {H.getLoadBoardConfigurations(configuredLoadBoards).map((loadBoard: Object) => {
          const { type, label, title, status } = loadBoard;

          const failed = R.equals(GC.LB_STATUS_FAILED, status);
          const disabled = R.equals(GC.LB_STATUS_DISABLED, status);
          const lbColor = G.ifElse(R.not(disabled), black, lightGrey);

          return (
            <Flex
              key={type}
              title={title}
              color={lbColor}
              justifyContent='space-between'
            >
              <Box>{label}</Box>
              {
                failed &&
                <Box mx={10}>
                  {I.warningIcon(black, 14, 14, yellow)}
                </Box>
              }
            </Flex>
          );
        })}
      </Flex>
      <Box
        cursor='pointer'
        color={blueColor}
        m='10px 20px 0 auto'
        onClick={goToConfiguration}
      >
        {G.getWindowLocale('actions:go-to-configuration', 'Go to configuration')}
      </Box>
    </Flex>
  );
};

const Authorizations = ({
  handleLogin,
  handleLogout,
  authorizedLoadBoards,
  configuredLoadBoards,
}: Object) => (
  <Flex
    p={10}
    ml={10}
    flex='1'
    gap='24px'
    bg={white}
    borderRadius='4px'
    flexDirection='column'
    alignItems='flex-start'
  >
    <Box
      mb={10}
      color={darkGrey}
      textAlign='center'
      width='-webkit-fill-available'
    >
      {G.getWindowLocale('titles:logins', 'Logins')}
    </Box>
    <Flex
      gap={16}
      width='100%'
      flexDirection='column'
      alignItems='flex-start'
    >
      {H.getLoadBoardLogins(authorizedLoadBoards, configuredLoadBoards).map((loadBoard: Object) => {
        const { type, label, title, status } = loadBoard;

        const failed = R.equals(GC.LB_STATUS_FAILED, status);
        const disabled = R.equals(GC.LB_STATUS_DISABLED, status);
        const mustBeConfigured = R.equals(GC.LB_STATUS_MUST_BE_CONFIGURED, status);
        const lbColor = G.ifElse(R.and(R.not(disabled), R.not(mustBeConfigured)), black, lightGrey);

        return (
          <Flex
            gap={16}
            key={type}
            width='100%'
            title={title}
            color={lbColor}
            justifyContent='space-between'
          >
            <Flex>
              <Box>{label}</Box>
              {
                failed &&
                <Box mx={10}>
                  {I.warningIcon(black, 14, 14, yellow)}
                </Box>
              }
            </Flex>
            {
              R.and(disabled, R.not(mustBeConfigured)) &&
              <Box
                cursor='pointer'
                color={blueColor}
                onClick={() => handleLogin(type)}
              >
                {G.getWindowLocale('actions:login', 'Login')}
              </Box>
            }
            {
              R.and(R.not(disabled), R.not(mustBeConfigured)) &&
              <Box
                cursor='pointer'
                color={blueColor}
                onClick={() => handleLogout(type)}
              >
                {G.getWindowLocale('actions:logout', 'Logout')}
              </Box>
            }
          </Flex>
        );
      })}
    </Flex>
  </Flex>
);

const LoadBoardsConfig = (props: Object) => {
  const {
    handleLogin,
    handleLogout,
    authorizedLoadBoards,
    configuredLoadBoards,
  } = props;

  return (
    <Flex
      p={10}
      width='100%'
      height='100%'
      alignItems='unset'
      justifyContent='space-between'
      bg={G.getTheme('colors.bgGrey')}
    >
      <Configurations configuredLoadBoards={configuredLoadBoards} />
      <Authorizations
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        authorizedLoadBoards={authorizedLoadBoards}
        configuredLoadBoards={configuredLoadBoards}
      />
    </Flex>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  configuredLoadBoards: makeSelectLoadBoardConfigList(state),
  authorizedLoadBoards: makeSelectAuthorizedLoadBoards(state),
});

export default connect(mapStateToProps, {})(LoadBoardsConfig);

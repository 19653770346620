import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useCallback } from 'react';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// ui
import { Box } from '../../../../ui';
// feature carrier-profile
import GeneralDetails from './general-details';
import { useProfile } from '../../hooks/use-profile';
import CarrierProfileWrapper from '../../components/wrapper';
import { carrierRatePriceExportRequest } from '../../actions';
import FormGroupTable, { ReportFormGroupTable } from '../../components/form-group-table';
import {
  AccessorialFormGroupTable,
  carrierContractBillToSettings,
  carrierContractRatePriceSettings,
  carrierContractAwardedLaneSettings,
} from '../../settings';
import {
  makeSelectConfigGroup,
  makeSelectGeneralDetails,
  makeSelectGeoFencingZoneList,
  makeSelectCarrierContractGeneralDetails,
} from '../../selectors';
//////////////////////////////////////////////////

const RatePriceFormGroupTable = (props: Object) => {
  const geoFencingZoneList = useSelector(makeSelectGeoFencingZoneList());

  if (R.isNil(geoFencingZoneList)) return null;

  return (
    <ReportFormGroupTable
      {...props}
      filterSelectMaxMenuHeight={120}
      reportSelectMaxMenuHeight={120}
      geoFencingZoneList={geoFencingZoneList}
    />
  );
};

const CarrierContract = (props: Object) => {
  const { handleGoBack, expandedContainer, closeExpandedContainer } = props;

  const configGroup = useSelector(makeSelectConfigGroup());
  const carrierGeneralDetails = useSelector(makeSelectGeneralDetails());
  const carrierContractDetails = useSelector(makeSelectCarrierContractGeneralDetails());

  const {
    dispatch,
    handleClose,
    commonActions,
    setSaveAndClose,
    handleGoToCarrier,
    handleUpdateGeneralDetails,
  } = useProfile({
    handleGoBack,
    expandedContainer,
    closeExpandedContainer,
    type: 'carrierContract',
    generalDetails: carrierContractDetails,
  });

  const { guid, carrierGuid, integrationConfigGuid } = carrierContractDetails;

  const { name, equipments, [GC.BRANCH_GUID]: carrierBranchGuid } = carrierGeneralDetails;

  const initialValues = {
    ...carrierContractDetails,
    [GC.FIELD_MODE]: R.path([GC.FIELD_MODE, GC.FIELD_DROPDOWN_OPTION_GUID], carrierContractDetails),
  };

  const handleExportCarrierRatePriceReport = useCallback(() => {
    dispatch(carrierRatePriceExportRequest(guid));
  }, [guid]);

  if (G.isAnyNilOrEmpty([guid, configGroup, carrierBranchGuid])) return <Box width='100%' height='100vh' bg='bgGrey' />;

  const commonProps = {
    ...commonActions,
    dispatch,
    configGroup,
    carrierGuid,
    primaryObjectGuid: guid,
    primaryObjectBranchGuid: carrierBranchGuid,
  };

  return (
    <CarrierProfileWrapper
      pb={120}
      handleClose={handleClose}
      handleGoBack={handleGoBack}
      id='carrier_contract_wrapper'
      setSaveAndClose={setSaveAndClose}
      expandedContainer={expandedContainer}
      form='carrier_contract_general_details'
    >
      <GeneralDetails
        {...commonActions}
        dispatch={dispatch}
        carrierName={name}
        initialValues={initialValues}
        handleGoToCarrier={handleGoToCarrier}
        submitAction={handleUpdateGeneralDetails}
      />
      <AccessorialFormGroupTable
        {...commonProps}
        groupName='carrierContract.accessorial'
      />
      <FormGroupTable {...commonProps} group={carrierContractBillToSettings} />
      <ReportFormGroupTable
        {...commonProps}
        dispatch={dispatch}
        group={carrierContractAwardedLaneSettings}
        groupName={R.prop('groupName', carrierContractAwardedLaneSettings)}
      />
      {
        R.isNil(integrationConfigGuid) &&
        <RatePriceFormGroupTable
          {...commonProps}
          dispatch={dispatch}
          carrierEquipments={equipments}
          group={carrierContractRatePriceSettings}
          handleExportReport={handleExportCarrierRatePriceReport}
          groupName={R.prop('groupName', carrierContractRatePriceSettings)}
        />
      }
    </CarrierProfileWrapper>
  );
};

export const Component = CarrierContract;

export default CarrierContract;

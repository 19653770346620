import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const selectDashboardsStore = ({ dashboards }: Object) => dashboards;

const makeSelectDashboardsStore = () => createSelector(
  selectDashboardsStore,
  (store: Object) => store,
);

const makeSelectDashboard = () => createSelector(
  selectDashboardsStore,
  ({ dashboard }: Object) => dashboard,
);

const makeSelectFilters = () => createSelector(
  selectDashboardsStore,
  ({ filters }: Object) => filters,
);

const makeSelectDashboardType = () => createSelector(
  selectDashboardsStore,
  ({ dashboardType }: Object) => dashboardType,
);

const makeSelectCurrencySymbol = () => createSelector(
  selectDashboardsStore,
  (store: Object) => {
    const { filters, normalizedCurrency } = store;

    const { enterprise } = filters;

    const enterpriseGuid = R.or(enterprise, G.getAmousCurrentBranchGuidFromWindow());

    return G.getCurrencySymbol2(G.getPropFromObject(enterpriseGuid, normalizedCurrency));
  },
);

export {
  makeSelectFilters,
  makeSelectDashboard,
  makeSelectDashboardType,
  makeSelectCurrencySymbol,
  makeSelectDashboardsStore,
};

// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const DASHBOARD_TYPE_FLEET_GENERAL = 'FLEET_GENERAL';
const DASHBOARD_TYPE_FLEET_ACCOUNTING = 'FLEET_ACCOUNTING';

const DAY_3 = 3;
const DAY_7 = 7;
const DAY_14 = 14;
const DAY_30 = 30;

const CHART_COLORS = {
  white: '#ffffff',
  grey1: '#a6a5a9',
  grey2: '#989898',
  grey3: '#EAEAEA',
  blue1: '#4880FF',
  blue2: '#8280FF',
  blue3: '#8884d8',
  green1: '#00B69B',
  PIE_BAR_COLORS: [
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
  ],
  RADIAL_BAR_COLORS: [
    '#8884d8',
    '#83a6ed',
    '#8dd1e1',
    '#82ca9d',
    '#a4de6c',
    '#d0ed57',
    '#ffc658',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
  ],
  BAR_COLORS: [
    '#67b7dc',
    '#6794dc',
    '#6770dc',
    '#8067dc',
    '#a367dc',
    '#c767dc',
    '#dc67ce',
    '#dc67ab',
    '#dc6788',
    '#dc6967',
    '#dc8c67',
    '#dcaf67',
    '#dcd267',
    '#c3dc67',
    '#a0dc67',
    '#7ddc66',
    '#67dc75',
    '#67dc98',
    '#67dcbb',
    '#67dadc',
  ],
};

// TODO: not added to locale API
const TITLES_MAP = {
  own: G.getWindowLocale('titles:own', 'Own'),
  trip: G.getWindowLocale('titles:trip', 'Trip'),
  toll: G.getWindowLocale('titles:toll', 'Toll'),
  fuel: G.getWindowLocale('titles:fuel', 'Fuel'),
  order: G.getWindowLocale('titles:order', 'Order'),
  drops: G.getWindowLocale('titles:drops', 'Drops'),
  month: G.getWindowLocale('titles:month', 'Month'),
  cents: G.getWindowLocale('titles:cents', 'Cents'),
  vendor: G.getWindowLocale('titles:vendor', 'Vendor'),
  booked: G.getWindowLocale('titles:booked', 'Booked'),
  created: G.getWindowLocale('titles:created', 'Created'),
  pickups: G.getWindowLocale('titles:pickups', 'Pickups'),
  payroll: G.getWindowLocale('titles:payroll', 'Payroll'),
  covered: G.getWindowLocale('titles:covered', 'Covered'),
  resolved: G.getWindowLocale('titles:resolved', 'Resolved'),
  vacation: G.getWindowLocale('titles:vacation', 'Vacation'),
  canceled: G.getWindowLocale('titles:canceled', 'Canceled'),
  noDriver: G.getWindowLocale('titles:noDriver', 'No Driver'),
  newLoads: G.getWindowLocale('titles:newLoads', 'New Loads'),
  accidents: G.getWindowLocale('titles:accidents', 'Accidents'),
  incidents: G.getWindowLocale('titles:incidents', 'Incidents'),
  delivered: G.getWindowLocale('titles:delivered', 'Delivered'),
  totalCost: G.getWindowLocale('titles:totalCost', 'Total Cost'),
  orderTrip: G.getWindowLocale('titles:orderTrip', 'Order/Trip'),
  inTransit: G.getWindowLocale('titles:inTransit', 'In Transit'),
  inService: G.getWindowLocale('titles:inService', 'In Service'),
  latePercent: G.getWindowLocale('titles:latePercent', 'Late %'),
  needALoad: G.getWindowLocale('titles:needALoad', 'Need a Load'),
  tripStatus: G.getWindowLocale('titles:tripStatus', 'Trip Status'),
  serviceCost: G.getWindowLocale('titles:serviceCost', 'Service Cost'),
  ordersTotal: G.getWindowLocale('titles:ordersTotal', 'Orders Total'),
  orderStatus: G.getWindowLocale('titles:orderStatus', 'Order Status'),
  marginTotal: G.getWindowLocale('titles:marginTotal', 'Margin Total'),
  costPerMile: G.getWindowLocale('titles:costPerMile', 'Cost Per Mile'),
  issuesFound: G.getWindowLocale('titles:issues-found', 'Issues Found'),
  lateForDrop: G.getWindowLocale('titles:lateForDrop', 'Late for Drop'),
  onTimePercentS: G.getWindowLocale('titles:onTimePercentS', 'On Time %'),
  outOfService: G.getWindowLocale('titles:outOfService', 'Out Of Service'),
  expensesTotal: G.getWindowLocale('titles:expensesTotal', 'Expenses Total'),
  activeDrivers: G.getWindowLocale('titles:activeDrivers', 'Active Drivers'),
  lateForPickUp: G.getWindowLocale('titles:lateForPickUp', 'Late for PickUp'),
  fuelTollSpend: G.getWindowLocale('titles:fuelTollSpend', 'Fuel/Toll Spend'),
  days: G.getWindowLocale('titles:days', 'days', { caseAction: 'lowerCase' }),
  top5services: G.getWindowLocale('titles:top3ServicesCost', 'Top 5 Services'),
  budgetForecast: G.getWindowLocale('titles:budgetForecast', 'Budget Forecast'),
  expectedProfit: G.getWindowLocale('titles:expectedProfit', 'Expected Profit'),
  expectedIncome: G.getWindowLocale('titles:expectedIncome', 'Expected Income'),
  ordersZeroRate: G.getWindowLocale('titles:ordersZeroRate', 'Orders Zero Rate'),
  trailersByType: G.getWindowLocale('titles:trailersByType', 'Trailers By Type'),
  nextMaintenance: G.getWindowLocale('titles:nextMaintenance', 'Next Maintenance'),
  serviceByVendor: G.getWindowLocale('titles:serviceByVendor', 'Service By Vendor'),
  orderRatesTotal: G.getWindowLocale('titles:orderRatesTotal', 'Order Rates Total'),
  payrollPerCharge: G.getWindowLocale('titles:payrollPerCharge', 'Payroll Per Charge'),
  averageIssuesAge: G.getWindowLocale('titles:averageIssuesAge', 'Average Issues Age'),
  top3servicesCost: G.getWindowLocale('titles:top3ServicesCost', 'Top 3 Services Cost'),
  trailersByCarrier: G.getWindowLocale('titles:trailersByCarrier', 'Trailers By Carrier'),
  availableTrailers: G.getWindowLocale('titles:available-trailers', 'Available Trailers'),
  ordersNotInvoiced: G.getWindowLocale('titles:ordersNotInvoiced', 'Orders Not Invoiced'),
  fleetServiceTotal: G.getWindowLocale('titles:fleetServiceTotal', 'Fleet Service Total'),
  inServiceTrailers: G.getWindowLocale('titles:inServiceTrailers', 'In Service Trailers'),
  fromLast: G.getWindowLocale('titles:from-last', 'from last', { caseAction: 'lowerCase' }),
  driversAvailability: G.getWindowLocale('titles:driversAvailability', 'Drivers Availability'),
  carrierInvoiceTotal: G.getWindowLocale('titles:carrierInvoiceTotal', 'Carrier Invoice Total'),
  completedInspections: G.getWindowLocale('titles:completedInspections', 'Completed Inspections'),
  carrierInvoiceStatus: G.getWindowLocale('titles:carrierInvoiceStatus', 'Carrier Invoice Status'),
  mostServicedTrailers: G.getWindowLocale('titles:mostServicedTrailers', 'Most Serviced Trailers'),
  customerInvoiceStatus: G.getWindowLocale('titles:customerInvoiceStatus', 'Customer Invoice Status'),
  createdResolvedIssues: G.getWindowLocale('titles:createdResolvedIssues', 'Created/Resolved Issues'),
  averageInspectionTime: G.getWindowLocale('titles:averageInspectionTime', 'Average Inspection Time'),
  averageCostPerTrailer: G.getWindowLocale('titles:averageCostPerTrailer', 'Average Cost Per Trailer'),
  carrierInvoicePerMode: G.getWindowLocale('titles:carrierInvoicePerMode', 'Carrier Invoice per Mode'),
  customerInvoicePerMode: G.getWindowLocale('titles:customerInvoicePerMode', 'Customer Invoice per Mode'),
  fromYesterday: G.getWindowLocale('titles:fromYesterday', 'from yesterday', { caseAction: 'lowerCase' }),
  openedIssuesBySeverity: G.getWindowLocale('titles:openedIssuesBySeverity', 'Opened Issues By Severity'),
};

export {
  DAY_3,
  DAY_7,
  DAY_14,
  DAY_30,
  TITLES_MAP,
  CHART_COLORS,
  DASHBOARD_TYPE_FLEET_GENERAL,
  DASHBOARD_TYPE_FLEET_ACCOUNTING,
};

import React from 'react';
// feature fleet-profile
import { ServiceIssueReport } from '../components/service-issue-report';
//////////////////////////////////////////////////

const Component = ({
  shared,
  isOpened,
  fleetType,
  withoutAddition,
  primaryObjectGuid,
  primaryObjectUnitId,
  handleToggleFormGroupTable,
  changeFleetServiceIssueStatusRequest,
}: Object) => (
  <ServiceIssueReport
    shared={shared}
    isOpened={isOpened}
    entityType={fleetType}
    withoutAddition={withoutAddition}
    primaryObjectGuid={primaryObjectGuid}
    primaryObjectUnitId={primaryObjectUnitId}
    changeFleetServiceIssueStatusRequest={changeFleetServiceIssueStatusRequest}
    handleToggleFormGroup={() => handleToggleFormGroupTable('fleetServiceIssue')}
  />
);

export const fleetServiceIssueSettings = {
  Component,
  groupName: 'fleetServiceIssue',
};

import * as R from 'ramda';
import { createSelector } from 'reselect';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
//////////////////////////////////////////////////

const dispatchBoardStore = (state: Object) => state.dispatchBoard;
const selectConfigsByNamesStore = (state: Object) => state.dispatchBoard.configsByNames;
const selectUiConfigStore = (state: Object) => state.dispatchBoard.configs[GC.UI_CONFIG_GROUP];
const selectCLOConfigStore = (state: Object) => state.dispatchBoard.configs[GC.CLO_CONFIG_GROUP];
const selectGeneralConfigStore = (state: Object) => state.dispatchBoard.configs[GC.GENERAL_CONFIG_GROUP];
const selectCommunicationConfigStore = (state: Object) => state.dispatchBoard.configs[GC.COMMUNICATION_CONFIG_GROUP];

const makeSelectConfigAutodialName = () => createSelector(
  selectUiConfigStore,
  (configs: Object) => G.getConfigValueFromStore(
    GC.UI_LOADBOARD_AUTODIALAPPNAME,
    configs,
  ),
);

const makeSelectConfigShowLocationInsteadLateDate = () => createSelector(
  selectUiConfigStore,
  (configs: Object) => G.getConfigValueFromStore(
    GC.UI_SHOW_LOCATION_INSTEAD_LATE_DATE,
    configs,
  ),
);

const makeSelectConfigCurrency = () => createSelector(
  selectGeneralConfigStore,
  (configs: Object) => G.getConfigValueFromStore(
    GC.GENERAL_BRANCH_DEFAULT_CURRENCY,
    configs,
  ),
);

const makeSelectConfigDefaultUOMSystem = () => createSelector(
  selectGeneralConfigStore,
  (configs: Object) => G.getConfigValueFromStore(
    GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
    configs,
  ),
);

const makeSelectServiceOptions = () => createSelector(
  selectGeneralConfigStore,
  (configs: Object) => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(configs, GC.GENERAL_SERVICES),
);

const makeSelectModeOptions = () => createSelector(
  selectGeneralConfigStore,
  (configs: Object) => G.createOptionsFromDropdownConfigWithGuidOrParentGuid(configs, GC.GENERAL_MODE_TRANSPORTATION),
);

const makeSelectCrossDockLocationOptions = () => createSelector(
  dispatchBoardStore,
  ({ crossDockLocationList }: Object) => G.getLocationOptions(crossDockLocationList),
);

const makeSelectDeclineLoadReasons = () => createSelector(
  selectCommunicationConfigStore,
  (configStore: Object) =>
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      configStore,
      GC.COMMUNICATION_DISPATCH_DECLINE_REASON_CODE,
    ),
);

const makeSelectUseBillToContactEmail = () => createSelector(
  selectCommunicationConfigStore,
  (configs: Object) => G.getConfigValueFromStore(
    GC.COMMUNICATION_EMAIL_USE_BILL_TO_CONTACT_EMAIL,
    configs,
  ),
);

const makeSelectDefaultUomFields = () => createSelector(
  selectGeneralConfigStore,
  (configStore: Object) => {
    let defaultValue = {};
    const uomSystem = G.getConfigValueFromStore(
      GC.GENERAL_UOM_CALC_DEFAULT_UOM_SYSTEM,
      configStore,
    );
    if (G.isNotNilAndNotEmpty(uomSystem)) {
      defaultValue = GC.defaultSystemUoms[uomSystem];
    }
    return defaultValue;
  },
);

const makeSelectRouteTelConfigs = () => createSelector(
  selectConfigsByNamesStore,
  (configs: Object) => G.getRouteTelConfigsForCreateClo(configs),
);

const makeSelectConfigsForTelGlobalSearch = () => createSelector(
  selectConfigsByNamesStore,
  (configStore: Object) => {
    const defaultSearchType = G.getConfigValueFromStore(
      GC.TEL_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE,
      configStore,
      GC.GLOBAL_SEARCH_TYPE_ALL,
    );
    const usedGlobalSearchTypes = G.getConfigValueFromStore(
      GC.TEL_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES,
      configStore,
      [],
    );

    return { defaultSearchType, usedGlobalSearchTypes };
  },
);

const makeSelectConfigsForCloGlobalSearch = () => createSelector(
  selectCLOConfigStore,
  (configStore: Object) => {
    const defaultSearchType = G.getConfigValueFromStore(
      GC.CLO_DISPATCH_BOARD_DEFAULT_GLOBAL_SEARCH_TYPE,
      configStore,
      GC.GLOBAL_SEARCH_TYPE_ALL,
    );
    const usedGlobalSearchTypes = G.getConfigValueFromStore(
      GC.CLO_DISPATCH_BOARD_USED_GLOBAL_SEARCH_TYPES,
      configStore,
      [],
    );

    return { defaultSearchType, usedGlobalSearchTypes };
  },
);

const makeSelectConfigsByGroup = () => createSelector(
  dispatchBoardStore,
  ({ configs }: Object) => configs,
);

export {
  makeSelectModeOptions,
  makeSelectConfigCurrency,
  makeSelectConfigsByGroup,
  makeSelectServiceOptions,
  makeSelectRouteTelConfigs,
  makeSelectDefaultUomFields,
  makeSelectConfigAutodialName,
  makeSelectDeclineLoadReasons,
  makeSelectUseBillToContactEmail,
  makeSelectConfigDefaultUOMSystem,
  makeSelectCrossDockLocationOptions,
  makeSelectConfigsForTelGlobalSearch,
  makeSelectConfigsForCloGlobalSearch,
  makeSelectConfigShowLocationInsteadLateDate,
};

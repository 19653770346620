import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withProps, lifecycle, withHandlers } from 'react-recompose';
// features
import CheckList from '../../../inspections/components/check-list';
import { visitFuelCardsOnFleetPage } from '../../../fuel-cards/actions';
import { visitIftaReportOnFleetPage } from '../../../ifta-report/actions';
import { visitFleetListOnFleetProfilePage, changeFleetServiceIssueStatusRequest } from '../../../fleet-list/actions';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// feature fleet-profile
import AddFuelTransaction from '../../components/add-fuel-transaction';
import { withDocumentActions } from '../../hocs/with-document-actions';
import { visitTollChargesOnFleetPage } from '../../../toll-charges/actions';
import { getTruckBranchConfigsRequest, getFleetProfileConfigsRequest } from '../../actions';
import { withHeightProps, withDocumentAdditionalTableSettings } from '../../hocs/with-report-common-props';
import {
  makeSelectBranchConfigs,
  selectTruckGeneralDetails,
  makeSelectTruckGeneralTabEntities,
  makeSelectTruckDocumentsTabEntities,
  makeSelectTruckMaintenanceTabEntities,
  makeSelectTruckInspectionsTabEntities,
  makeSelectTruckSpecificationsTabEntities,
} from '../../selectors';
import {
  referenceSettings,
  truckLocationSettings,
  truckDocumentSettings,
  truckOwnershipSettings,
  truckInsuranceSettings,
  getInspectionsSettings,
  truckIntegrationOptions,
  equipmentServiceSettings,
  fleetServiceIssueSettings,
  truckSpecificationSettings,
  truckLastKnownLocationSettings,
  truckEquipmentComponentSettings,
} from '../../settings';
// feature fleet-profile/truck
import { dailyLogSettings } from '../settings/daily-log';
//////////////////////////////////////////////////

const withTabGroupSettings = (groupsSettings: Object) => compose(
  withProps((props: Object) => {
    const { entities, collapsedGroup } = props;

    const groups = R.map((item: Object) => {
      const { groupName, primaryObjectGuidKey = GC.FIELD_TRUCK_GUID } = item;

      return {
        ...item,
        primaryObjectGuidKey,
        isOpened: R.pathOr(true, [groupName], collapsedGroup),
        itemList: R.or(R.prop(groupName, entities), R.prop(groupName, props)),
      };
    }, groupsSettings);

    return { groups };
  }),
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTruckGeneralTabEntities(state),
});

export const withGeneralSettings = compose(
  connect(mapStateToProps),
  withTabGroupSettings([
    truckIntegrationOptions,
    truckLastKnownLocationSettings,
    truckInsuranceSettings,
    referenceSettings,
  ]),
  pure,
);

const documentsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTruckDocumentsTabEntities(state),
});

export const withDocumentsSettings = compose(
  connect(documentsMapStateToProps),
  withTabGroupSettings([truckDocumentSettings]),
  withDocumentAdditionalTableSettings,
  withDocumentActions,
  pure,
);

const specificationsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTruckSpecificationsTabEntities(state),
});

export const withSpecificationsTab = compose(
  connect(specificationsMapStateToProps),
  withTabGroupSettings([truckSpecificationSettings, truckOwnershipSettings, truckLocationSettings]),
);

const maintenanceMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTruckMaintenanceTabEntities(state),
});

export const withMaintenanceSettings = compose(
  connect(maintenanceMapStateToProps, { getFleetProfileConfigsRequest, visitFleetListOnFleetProfilePage }),
  withTabGroupSettings([equipmentServiceSettings, truckEquipmentComponentSettings]),
  lifecycle({
    componentDidMount() {
      const {
        entities,
        primaryObjectGuid,
        primaryObjectBranchGuid,
        getFleetProfileConfigsRequest,
        visitFleetListOnFleetProfilePage,
      } = this.props;

      const equipmentComponent = G.getPropFromObject('equipmentComponent', entities);

      if (R.isNil(equipmentComponent)) {
        getFleetProfileConfigsRequest({
          group: GC.FLEET_GENERAL_CONFIG_GROUP,
          [GC.BRANCH_GUID]: primaryObjectBranchGuid,
        });
      }

      visitFleetListOnFleetProfilePage({
        fleetProfileType: GC.FIELD_TRUCK,
        fleetProfileGuid: primaryObjectGuid,
        fleetProfileGuidType: GC.FIELD_TRUCK_GUID,
        reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
        isPageVisited: G.isNotNil(equipmentComponent),
      });
    },
  }),
  pure,
);

export const withServiceIssueSettings = compose(
  connect(null, { visitFleetListOnFleetProfilePage, changeFleetServiceIssueStatusRequest }),
  withTabGroupSettings([fleetServiceIssueSettings]),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitFleetListOnFleetProfilePage } = this.props;

      visitFleetListOnFleetProfilePage({
        fleetProfileType: GC.FIELD_TRUCK,
        fleetProfileGuid: primaryObjectGuid,
        reportType: GC.FLEET_SERVICE_ISSUE_REPORT,
        fleetProfileGuidType: GC.FIELD_TRUCK_GUID,
      });
    },
  }),
  pure,
);

const fuelTransactionsMapStateToProps = (state: Object) => createStructuredSelector({
  generalDetails: selectTruckGeneralDetails,
  branchConfigs: makeSelectBranchConfigs(state),
});

export const withFuelTransactionsTab = compose(
  connect(fuelTransactionsMapStateToProps, { visitFuelCardsOnFleetPage, getTruckBranchConfigsRequest }),
  withHeightProps,
  withProps((props: Object) => {
    const { generalDetails, branchConfigs } = props;

    const { unitId, driverGuid } = generalDetails;

    return {
      pl: 15,
      truckUnitId: unitId,
      pageActionsBottom: 70,
      withoutPageActions: true,
      additionalComponent: (
        <AddFuelTransaction
          truckUnitId={unitId}
          assignedDriverGuid={driverGuid}
          defaultBranchCurrency={G.getConfigValueFromStore(GC.GENERAL_BRANCH_DEFAULT_CURRENCY, branchConfigs)}
        />
      ),
    };
  }),
  lifecycle({
    componentDidMount() {
      const {
        branchConfigs,
        primaryObjectGuid,
        visitFuelCardsOnFleetPage,
        getTruckBranchConfigsRequest,
      } = this.props;

      if (R.isNil(branchConfigs)) {
        getTruckBranchConfigsRequest();
        visitFuelCardsOnFleetPage({ [GC.FIELD_DRIVER_GUID]: null, [GC.FIELD_TRUCK_GUID]: primaryObjectGuid });
      }
    },
  }),
  pure,
);

export const withTollChargesTab = compose(
  connect(null, { visitTollChargesOnFleetPage }),
  withHeightProps,
  withProps(() => ({ pl: 15 })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitTollChargesOnFleetPage } = this.props;

      visitTollChargesOnFleetPage({ [GC.FIELD_DRIVER_GUID]: null, [GC.FIELD_TRUCK_GUID]: primaryObjectGuid });
    },
  }),
  pure,
);

export const withIftaReportTab = compose(
  connect(null, { visitIftaReportOnFleetPage }),
  withHeightProps,
  withProps(() => ({
    pl: 15,
    fromTruckDetailPage: true,
  })),
  lifecycle({
    componentDidMount() {
      const { primaryObjectGuid, visitIftaReportOnFleetPage } = this.props;

      visitIftaReportOnFleetPage({ [GC.FIELD_TRUCK_GUID]: primaryObjectGuid });
    },
  }),
  pure,
);

export const withDailyLogSettings = compose(
  withTabGroupSettings([dailyLogSettings]),
  pure,
);

export const withAuditTab = compose(
  withProps(({ primaryObjectGuid, tabsCollapsedView }: Object) => ({
    useFullScreen: true,
    maxHeight: `calc(100vh - ${G.ifElse(tabsCollapsedView, 600, 215)}px)`,
    requestOptions: {
      [GC.FIELD_TYPE]: GC.AUDIT_TYPE_TRUCK,
      [GC.FIELD_OBJECT_GUID]: primaryObjectGuid,
    },
  })),
  pure,
);

const inspectionsMapStateToProps = (state: Object) => createStructuredSelector({
  entities: makeSelectTruckInspectionsTabEntities(state),
});

export const withInspectionsTab = compose(
  connect(inspectionsMapStateToProps),
  withTabGroupSettings([getInspectionsSettings(true)]),
  withHandlers({
    handleShowDetails: (props: Object) => (guid: string, truckGuid: string) => {
      const { openModal, closeModal } = props;

      const width = 'calc(100vw - 100px)';

      const modal = {
        fixedWidth: true,
        wrapperStyles: { width },
        isExpandedContainer: true,
        component: (
          <CheckList
            guid={guid}
            closeModal={closeModal}
            truckGuid={truckGuid}
          />
        ),
        options: {
          minWidth: width,
          minHeight: '100vh',
          enableResizing: false,
          default: { x: 0, y: 0, width, height: '100vh' },
          style: { backgroundColor: G.getTheme('colors.white') },
        },
      };

      openModal(modal);
    },
  }),
);

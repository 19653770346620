
/* eslint-disable */

export const mockResData= [
  {
      "chartType": "CREATED_RESOLVED_ISSUES",
      "groups": [
          {
              "name": "08/05/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 5
                  },
                  {
                      "name": "resolved",
                      "value": 5
                  },
              ]
          },
          {
              "name": "08/06/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 2
                  },
                  {
                      "name": "resolved",
                      "value": 7
                  },
              ]
          },
          {
              "name": "08/07/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 0
                  },
              ]
          },
          {
              "name": "08/08/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 2
                  },
                  {
                      "name": "resolved",
                      "value": 3
                  },
              ]
          },
          {
              "name": "08/10/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 0
                  },
              ]
          },
          {
              "name": "08/12/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 0
                  },
              ]
          },
          {
              "name": "08/13/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 8
                  },
                  {
                      "name": "resolved",
                      "value": 2
                  },
              ]
          },
          {
              "name": "08/14/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 5
                  },
              ]
          },
          {
              "name": "08/18/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 3
                  },
              ]
          },
          {
              "name": "08/19/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 3
                  },
                  {
                      "name": "resolved",
                      "value": 5
                  },
              ]
          },
          {
              "name": "08/20/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 8
                  },
                  {
                      "name": "resolved",
                      "value": 12
                  },
              ]
          },
          {
              "name": "08/21/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 0
                  },
                  {
                      "name": "resolved",
                      "value": 4
                  },
              ]
          },
          {
              "name": "08/24/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 1
                  },
                  {
                      "name": "resolved",
                      "value": 0
                  },
              ]
          },
          {
              "name": "08/25/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 0
                  },
                  {
                      "name": "resolved",
                      "value": 6
                  },
              ]
          },
          {
              "name": "08/26/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 2
                  },
                  {
                      "name": "resolved",
                      "value": 8
                  },
              ]
          },
          {
              "name": "08/27/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 4
                  },
                  {
                      "name": "resolved",
                      "value": 4
                  },
              ]
          },
          {
              "name": "08/28/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 4
                  },
                  {
                      "name": "resolved",
                      "value": 6
                  },
              ]
          },
          {
              "name": "08/29/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 6
                  },
                  {
                      "name": "resolved",
                      "value": 5
                  },
              ]
          },
          {
              "name": "09/01/2024",
              "groups": [
                  {
                      "name": "created",
                      "value": 2
                  },
                  {
                      "name": "resolved",
                      "value": 7
                  },
              ]
          },
      ]
  },
  {
      "chartType": "TOP_SERVICES_COST",
      "groups": [
          {
              "name": "08/05/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 620
                  }
              ]
          },
          {
              "name": "08/06/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 240
                  },
                  {
                    "name": "Brakes",
                    "value": 310
                  },
                  {
                    "name": "Lighting",
                    "value": 110
                  }
              ]
          },
          {
              "name": "08/07/2024",
              "groups": [
                  {
                      "name": "Brakes",
                      "value": 450
                  }
              ]
          },
          {
              "name": "08/08/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 2100
                  }
              ]
          },
          {
              "name": "08/09/2024",
              "groups": [
                  {
                      "name": "Lighting",
                      "value": 335
                  }
              ]
          },
          {
              "name": "08/10/2024",
              "groups": [
                {
                  "name": "Tires",
                  "value": 830
                },
                {
                  "name": "Brakes",
                  "value": 520
                },
                {
                  "name": "Lighting",
                  "value": 425
                }
              ]
          },
          {
              "name": "08/12/2024",
              "groups": [
                  {
                      "name": "Brakes",
                      "value": 380
                  }
              ]
          },
          {
              "name": "08/15/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 965
                  }
              ]
          },
          {
              "name": "08/19/2024",
              "groups": [
                  {
                      "name": "Lighting",
                      "value": 385
                  }
              ]
          },
          {
              "name": "08/20/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 640
                  }
              ]
          },
          {
              "name": "08/21/2024",
              "groups": [
                {
                  "name": "Tires",
                  "value": 340
                },
                {
                  "name": "Brakes",
                  "value": 270
                },
                {
                  "name": "Lighting",
                  "value": 210
                }
              ]
          },
          {
              "name": "08/22/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 500
                  }
              ]
          },
          {
              "name": "08/23/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 210
                  }
              ]
          },
          {
              "name": "08/24/2024",
              "groups": [
                {
                  "name": "Tires",
                  "value": 540
                },
                {
                  "name": "Brakes",
                  "value": 310
                },
                {
                  "name": "Lighting",
                  "value": 290
                }
              ]
          },
          {
              "name": "08/26/2024",
              "groups": [
                  {
                      "name": "Brakes",
                      "value": 330
                  }
              ]
          },
          {
              "name": "08/28/2024",
              "groups": [
                  {
                      "name": "Lighting",
                      "value": 270
                  }
              ]
          },
          {
              "name": "08/29/2024",
              "groups": [
                  {
                      "name": "Tires",
                      "value": 390
                  }
              ]
          },
      ]
  },
  {
    "chartType": "SERVICE_COST",
    "groups": [
        {
            "name": "08/05/2024",
            "value": 620
        },
        {
            "name": "08/06/2024",
            "value": 240
        },
        {
            "name": "08/07/2024",
            "value": 750
        },
        {
            "name": "08/08/2024",
            "value": 2100
        },
        {
            "name": "08/09/2024",
            "value": 1335
        },
        {
            "name": "08/10/2024",
            "value": 830
        },
        {
            "name": "08/12/2024",
            "value": 380
        },
        {
            "name": "08/15/2024",
            "value": 965
        },
        {
            "name": "08/19/2024",
            "value": 485
        },
        {
            "name": "08/20/2024",
            "value": 640
        },
        {
            "name": "08/21/2024",
            "value": 910
        },
        {
            "name": "08/22/2024",
            "value": 500
        },
        {
            "name": "08/23/2024",
            "value": 210
        },
        {
            "name": "08/24/2024",
            "value": 540
        },
        {
            "name": "08/26/2024",
            "value": 330
        },
        {
            "name": "08/28/2024",
            "value": 1270
        },
        {
            "name": "08/29/2024",
            "value": 390
        },
    ]
  },
  {
    "chartType": "AVAILABLE_TRAILERS",
    "value": 30,
    "previousPeriodValue": 28
  },
  {
      "chartType": "ACCIDENTS",
      "value": 2,
      "previousPeriodValue": 3
  },
  {
      "chartType": "INCIDENTS",
      "value": 1,
      "previousPeriodValue": 3
  },
  {
      "chartType": "NEXT_MAINTENANCE",
      "value": 5
  },
  {
    "chartType": "COMPLETED_INSPECTIONS",
    "value": 32,
    "previousPeriodValue": 28
  },
  {
    "chartType": "AVERAGE_INSPECTION_TIME",
    "value": "11m 42s",
    "previousPeriodValue": "10m 11s"
  },
  {
    "chartType": "ISSUES_FOUND",
    "value": 23,
    "previousPeriodValue": 19
  },
  {
    "chartType": "AVERAGE_ISSUE_AGE",
    "value": 7,
  },

  {
    "chartType": "TOTAL_COST",
    "value": 15670,
    "previousPeriodValue": 13240
  },
  {
    "chartType": "AVERAGE_COST_PER_TRAILER",
    "value": 1045,
    "previousPeriodValue": 883
  },
  {
    "chartType": "COST_PER_MILE",
    "value": 18,
    "previousPeriodValue": 16
  },
  {
    "chartType": "BUDGET_FORECAST",
    "value": 14560,
  },
  {
      "chartType": "TRAILERS_BY_TYPE",
      "groups": [
          {
              "name": "Dry Van",
              "value": 11
          },
          {
              "name": "Flat Bed",
              "value": 3
          },
          {
              "name": "Step Deck",
              "value": 2
          }
      ]
  },
  {
    "chartType": "OPENED_ISSUES_BY_SEVERITY",
    "groups": [
        {
            "name": "High",
            "value": 2
        },
        {
            "name": "Medium",
            "value": 1
        },
        {
            "name": "Low",
            "value": 3
        }
    ]
  },
  {
      "chartType": "IN_SERVICE_TRAILERS",
      "groups": [
          {
              "name": "inService",
              "value": 25
          },
          {
            "name": "outOfService",
            "value": 3
          },
      ]
  },
  {
    "chartType": "TOP_5_SERVICES",
    "groups": [
        {
            "name": "Generic Maintenance",
            "value": 9
        },
        {
            "name": "Tires",
            "value": 7
        },
        {
          "name": "Braces",
          "value": 5
        },
        {
          "name": "Lighting",
          "value": 5
        },
        {
          "name": "Inspections",
          "value": 2
      },
    ]
  },
  {
    "chartType": "TRAILERS_BY_CARRIER",
    "groups": [
        {
          "name": "Traffix USPS Demo",
          "value": 5
        },
        {
            "name": "Pure Freight Lines MC334497",
            "value": 5
        },
        {
            "name": "Southern Transport MC984293",
            "value": 5
        },
        {
            "name": "Two Brothers Freight MC99720",
            "value": 5
        },
        {
            "name": "RakMark MC938465",
            "value": 5
        },
        {
          "name": "DualShore Transport MC189232",
          "value": 5
        },
      ]
  },
  {
    "chartType": "MOST_SERVICED_TRAILERS",
    "groups": [
        {
          "name": "533793",
          "value": "$1 597"
        },
        {
          "name": "P728",
          "value": "$1 454"
        },
        {
          "name": "P744",
          "value": "$1 010"
        },
        {
          "name": "537527",
          "value": "$967"
        },
        {
          "name": "535377",
          "value": "$930"
        },
      ]
  },
  {
    "chartType": "SERVICE_BY_VENDOR",
    "groups": [
        {
          "name": "A&A Repair",
          "value": 13
        },
        {
          "name": "TA",
          "value": 6
        },
        {
          "name": "Pure Mechanic",
          "value": 5
        },
        {
          "name": "XTruck Care",
          "value": 3
        },
        {
          "name": "Other",
          "value": 4
        },
    ]
  },
];

import React from 'react';
import * as R from 'ramda';
// components
import { TextComponent } from '../../../components/text';
// features
import PC from '../../permission/role-permission';
import { AuthWrapper } from '../../permission/index';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { ITEM_TEMPERATURE_OPTIONS } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// utilities
import routesMap from '../../../utilities/routes';
// feature dispatch-report
import { RC } from './filter-params';
import { FIELD_CLO_TOTAL_WEIGHT } from '../constants';
import { TableField, ExpandedDetails, UomLocalesTableField } from '../components/table-fields';
//////////////////////////////////////////////////

// TODO: check all pivotTypes (date etc.)

export const columnSettings = {
  orderDetails: {
    name: '',
    width: 35,
    notResizable: true,
    customComponent: ({ data }: Object) => <ExpandedDetails {...data} loadType={GC.LOAD_TYPE_CLO} />,
  },
  loadDetails: {
    name: '',
    width: 35,
    notResizable: true,
    customComponent: ({ data }: Object) => <ExpandedDetails {...data} loadType={GC.LOAD_TYPE_TEL} />,
  },
  [GC.FIELD_LOAD_STATUS]: {
    width: 180,
    type: 'enum',
    searchable: true,
    name: 'titles:status',
  },
  [GC.GRC.CUSTOM_STATUS_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:custom-status',
  },
  [GC.FIELD_HOT]: {
    width: 150,
    type: 'boolean',
    searchable: true,
    name: 'titles:hot-load',
  },
  [RC.MASTER_INVOICE_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:master-invoice-number',
  },
  [RC.ITEMS_TEMPERATURE_TYPE]: {
    width: 300,
    type: 'enum',
    searchable: true,
    name: 'titles:temperature-type',
  },
  [GC.GRC.ITEMS_TEMPERATURE_SENSOR]: {
    width: 150,
    searchable: true,
    type: 'collectionOfEnums',
    name: 'titles:temperature-sensor',
  },
  [RC.EVENTS_ITEMS_ITEM_DIMENSIONS_LENGTH]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:dimensions-length'],
  },
  [RC.EVENTS_ITEMS_ITEM_DIMENSIONS_WIDTH]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:dimensions-width'],
  },
  [RC.EVENTS_ITEMS_ITEM_DIMENSIONS_HEIGHT]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:dimensions-height'],
  },
  [RC.EVENTS_ITEMS_ITEM_DIMENSIONS_UOM]: {
    width: 200,
    name: ['titles:items', 'titles:dimensions-uom'],
    customComponent: ({ data }: Object) => (
      <UomLocalesTableField data={data} field={RC.EVENTS_ITEMS_ITEM_DIMENSIONS_UOM} />
    ),
  },
  [RC.EVENTS_ITEMS_ITEM_FREIGHT_CLASS]: {
    width: 200,
    type: 'freightClass',
    name: ['titles:items', 'titles:freight-class'],
  },
  [RC.ITEMS_MANUFACTURER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:manufacturer'],
  },
  [RC.ITEMS_YEAR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:year'],
  },
  [RC.ITEMS_MAKE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:make'],
  },
  [RC.ITEMS_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:model'],
  },
  [RC.ITEMS_SUB_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:sub-model'],
  },
  [RC.ITEMS_WHEELBASE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase'],
  },
  [RC.ITEMS_WHEELBASE_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={RC.ITEMS_WHEELBASE_UOM} />,
  },
  [RC.ITEMS_ODOMETER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer'],
  },
  [RC.ITEMS_ODOMETER_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={RC.ITEMS_ODOMETER_UOM} />,
  },
  [RC.ITEMS_COLOR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:color'],
  },
  [RC.ITEMS_LICENSE_PLATE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:license-plate'],
  },
  [RC.ITEMS_VIN]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:vin'],
  },
  [RC.ITEMS_BAY]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:bay'],
  },
  [RC.ITEMS_ITEM_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:items', 'titles:item-type'],
  },
  [RC.ITEMS_ITEM_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:item-id'],
  },
  [RC.ITEMS_ORIGIN_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-state'],
  },
  [RC.ITEMS_ORIGIN_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-country'],
  },
  [RC.LOAD_CANCEL_REASON]: {
    width: 200,
    searchable: true,
    name: 'titles:load-cancel-reason',
  },
  [GC.FIELD_CREATED_BY]: {
    width: 250,
    searchable: true,
    name: 'titles:created-by',
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    searchable: true,
    name: 'titles:created-date',
    pivotType: GC.PIVOT_TYPE_YQMD,
  },
  [GC.FIELD_LOAD_SPECIAL_INSTRUCTIONS]: {
    width: 250,
    searchable: true,
    name: 'titles:special-instructions',
  },
  [GC.FIELD_LOAD_INTERNAL_INSTRUCTIONS]: {
    width: 250,
    searchable: true,
    name: 'titles:internal-instructions',
  },
  [GC.FIELD_BRANCH_DOT_ACCOUNT_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:account-number',
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:branch',
  },
  [RC.ROUTE_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:route-name',
  },
  [RC.BILL_TO_LOCATION_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:bill-to-name',
  },
  [RC.BILL_TO_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:country'],
  },
  [RC.BILL_TO_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:state'],
  },
  [RC.BILL_TO_CITY]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:city'],
  },
  [RC.BILL_TO_ADDRESS1]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:address1'],
  },
  [RC.BILL_TO_ADDRESS2]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:address2'],
  },
  [RC.BILL_TO_ZIP]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:zip'],
  },
  [RC.BILL_TO_PAYMENT_TERM]: {
    width: 250,
    type: 'enum',
    searchable: true,
    name: ['titles:bill-to', 'titles:payment-terms'],
  },
  [RC.BILL_TO_COMMENTS]: {
    width: 300,
    searchable: true,
    name: ['titles:bill-to', 'titles:comments'],
  },
  [RC.BILL_TO_LOCATION_TYPE_VALUE]: {
    width: 250,
    searchable: true,
    name: ['titles:bill-to', 'titles:type'],
  },
  [RC.PRIMARY_REFERENCE_NAME]: {
    width: 250,
    searchable: true,
    name: 'titles:primary-ref-name',
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: 'titles:primary-ref-value',
  },
  [RC.PINNED_NOTE_TEXT]: {
    width: 250,
    searchable: true,
    name: ['titles:pinned-note', 'titles:text'],
  },
  [RC.PINNED_NOTE_NOTE_TYPE]: {
    width: 250,
    searchable: true,
    name: ['titles:pinned-note', 'titles:type'],
  },
  [GC.GRC.RATE_SERVICE_DAYS]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: 'titles:service-days',
  },
  [GC.GRC.RATE_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-rate',
    customComponent: ({ data }: Object) => {
      const isClo = R.has(RC.TELS_GUID, data);

      const permissions = G.ifElse(
        isClo,
        [PC.CLO_RATE_READ, PC.CLO_RATE_WRITE],
        [PC.FLEET_RATE_READ, PC.FLEET_RATE_WRITE, PC.CARRIER_RATE_READ, PC.CARRIER_RATE_WRITE],
      );

      return (
        <AuthWrapper has={permissions}>{R.prop(RC.RATE_TOTAL, data)}</AuthWrapper>
      );
    },
  },
  [GC.GRC.RATE_CURRENCY]: {
    width: 180,
    searchable: true,
    name: ['titles:rate', 'titles:currency'],
  },
  [GC.GRC.RATE_MODE]: {
    width: 180,
    searchable: true,
    name: 'titles:mode',
  },
  [GC.GRC.RATE_CUSTOMER_TOTAL]: {
    width: 160,
    searchable: true,
    pivotType: 'number',
    name: ['titles:rate', 'titles:customer-total'],
  },
  [RC.FIRST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:location-type'],
  },
  [RC.FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:template-id'],
  },
  [RC.FIRST_EVENT_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:location-name'],
  },
  [RC.FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:address1'],
  },
  [RC.FIRST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:address2'],
  },
  [RC.FIRST_EVENT_LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:city'],
  },
  [RC.FIRST_EVENT_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:state'],
  },
  [RC.FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:country'],
  },
  [RC.FIRST_EVENT_LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:zip'],
  },
  [RC.FIRST_EVENT_EARLY_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:early-date'],
  },
  [RC.FIRST_EVENT_LATE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:late-date'],
  },
  [RC.FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.FIRST_EVENT_CHECK_IN_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:check-in-date'],
  },
  [RC.FIRST_EVENT_COMPLETE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:first-event', 'titles:complete-date'],
  },
  [GC.GRC.FIRST_EVENT_PICKUP_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:first-event', 'titles:pick-up-number'],
  },
  [RC.LAST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:location-type'],
  },
  [RC.LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:template-id'],
  },
  [RC.LAST_EVENT_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:location-name'],
  },
  [RC.LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:address1'],
  },
  [RC.LAST_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:address2'],
  },
  [RC.LAST_EVENT_LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:city'],
  },
  [RC.LAST_EVENT_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:state'],
  },
  [RC.LAST_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:country'],
  },
  [RC.LAST_EVENT_LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:last-event', 'titles:zip'],
  },
  [RC.LAST_EVENT_EARLY_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:early-date'],
  },
  [RC.LAST_EVENT_LATE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:late-date'],
  },
  [RC.LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:appointment-early-date'],
  },
  [RC.LAST_EVENT_COMPLETE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:complete-date'],
  },
  [GC.GRC.LAST_EVENT_CHECK_IN_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:last-event', 'titles:check-in-date'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:location-type'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:template-id'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:location-name'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_ADDRESS1]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:address1'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_ADDRESS2]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:address2'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_CITY]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:city'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:state'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_COUNTRY]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:country'],
  },
  [GC.GRC.NEXT_EVENT_LOCATION_ZIP]: {
    width: 200,
    searchable: true,
    name: ['titles:next-event', 'titles:zip'],
  },
  [GC.GRC.NEXT_EVENT_EARLY_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:next-event', 'titles:early-date'],
  },
  [GC.GRC.NEXT_EVENT_LATE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:next-event', 'titles:late-date'],
  },
  [GC.GRC.NEXT_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:next-event', 'titles:appointment-early-date'],
  },
  [GC.GRC.NEXT_EVENT_CHECK_IN_DATE]: {
    width: 220,
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:next-event', 'titles:check-in-date'],
  },
  [GC.FIELD_MARGIN]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:margin',
    customComponent: ({ data }: Object) =>
      <AuthWrapper has={[PC.TEL_READ, PC.TEL_WRITE]}>{R.prop(GC.FIELD_MARGIN, data)}</AuthWrapper>,
  },
  [RC.TEL_COUNT]: {
    width: 150,
    searchable: true,
    pivotType: 'number',
    name: 'titles:tel-count',
    styles: { titleInputMaxWidth: 100 },
  },
  [RC.CLO_COUNT]: {
    width: 150,
    searchable: true,
    pivotType: 'number',
    name: 'titles:clo-count',
    styles: { titleInputMaxWidth: 100 },
  },
  [GC.FIELD_EVENT_COUNT]: {
    width: 150,
    searchable: true,
    pivotType: 'number',
    name: 'titles:event-count',
    styles: { titleInputMaxWidth: 100 },
  },
  [GC.GRC.RATE_MAIN_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:linehaul',
  },
  [GC.GRC.RATE_DISCOUNT_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:discount',
  },
  [GC.GRC.RATE_FUEL_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:fuel',
  },
  [GC.GRC.RATE_ADDITIONAL_CHARGES_TOTAL]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:other-accessorials',
  },
  [GC.GRC.RATE_FUEL_CHARGES_RATE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: ['titles:fuel', 'titles:rate'],
  },
  [GC.GRC.RATE_FUEL_CHARGES_QUANTITY]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: ['titles:fuel', 'titles:quantity'],
  },
  [GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: ['titles:fuel-index', 'titles:price'],
  },
  [GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_PRICE_DATE]: {
    type: 'date',
    searchable: true,
    pivotType: 'date',
    name: ['titles:fuel-index', 'titles:date'],
  },
  [GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_RATE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: ['titles:fuel-index', 'titles:rate'],
  },
  [GC.GRC.RATE_FUEL_CHARGES_FUEL_INDEX_INFO_DISTRICT]: {
    width: 200,
    searchable: true,
    name: ['titles:fuel-index', 'titles:district'],
  },
  [GC.FIELD_INVOICE_COUNT]: {
    width: 180,
    searchable: true,
    pivotType: 'number',
    name: 'titles:invoice-count',
    styles: { titleInputMaxWidth: 100 },
  },
  [GC.FIELD_STORED_TOTAL_DISTANCE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:trip-distance',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [GC.GRC.RATE_DISPATCH_OR_CANCEL_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: 'titles:dispatched-date',
  },
  [GC.GRC.RATE_DISPATCHED_OR_CANCELED_BY]: {
    width: 300,
    searchable: true,
    name: 'titles:dispatched-by',
  },
  [GC.GRC.RATE_TRIP_MAX_PAY]: {
    width: 200,
    type: 'number',
    searchable: true,
    pivotType: 'number',
    name: 'titles:trip-max-pay',
  },
  [GC.GRC.RATE_NORMALIZED_TRIP_MAX_PAY]: {
    width: 200,
    type: 'number',
    searchable: true,
    pivotType: 'number',
    name: 'titles:normalized-trip-max-pay',
  },
  [GC.GRC.RATE_MAX_PAY_VIOLATED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: ['titles:trip-max-pay', 'titles:violated'],
  },
  [GC.GRC.RATE_MIN_MARGIN_VIOLATED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: ['titles:min-margin', 'titles:violated'],
  },
  [GC.GRC.RATE_CRITICAL_MARGIN_VIOLATED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: ['titles:critical-margin', 'titles:violated'],
  },
  [GC.GRC.RATE_MARGIN_VIOLATION_APPROVED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: 'titles:violation-approved',
  },
  [GC.GRC.RATE_MARGIN_VIOLATION_NOTE]: {
    width: 300,
    searchable: true,
    name: 'titles:violation-note',
  },
  [GC.GRC.RATE_MARGIN_VIOLATION_REASON]: {
    width: 200,
    searchable: true,
    name: 'titles:violation-reason',
  },
  [GC.FIELD_STORED_TOTAL_WEIGHT]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-weight',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_WEIGHT} />,
  },
  [GC.FIELD_STORED_TOTAL_VOLUME]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-volume',
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_ITEM_VOLUME} />,
  },
  [GC.FIELD_TOTAL_PICKUP_QUANTITY]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:quantity',
  },
  [GC.FIELD_PACKAGE_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: 'titles:package-type',
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:email'],
  },
  [GC.GRC.FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:email'],
  },
  [GC.GRC.FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:full-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:trailers',
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:vendor'],
  },
  [RC.FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [RC.FLEET_ASSIGNMENT_PR_DRIVER_DRIVER_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:driver-type'],
  },
  [RC.FLEET_ASSIGNMENT_PR_DRIVER_EMAIL]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:email'],
  },
  [RC.FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [RC.FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [RC.FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [RC.FLEET_ASSIGNMENT_SEC_DRIVER_DRIVER_TYPE]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:driver-type'],
  },
  [RC.FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:email'],
  },
  [RC.FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [RC.FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [RC.FLEET_ASSIGNMENT_TRAILERS_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: 'titles:trailers',
  },
  [RC.FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [RC.FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:vendor'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:name'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:scac'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:mc-number'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_ADDRESS]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:address'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_EMAILS_STRING]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:emails'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_FEDERAL_EIN]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:federal-ein'],
  },
  [RC.CARRIER_ASSIGNMENT_SNAPSHOT_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:name'],
  },
  [RC.CARRIER_ASSIGNMENT_SNAPSHOT_SCAC]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:scac'],
  },
  [RC.CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:mc-number'],
  },
  [RC.CARRIER_ASSIGNMENT_SNAPSHOT_US_DOT_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:usdot-number'],
  },
  [GC.GRC.RATE_NORMALIZED_TOTAL_CURRENCY]: {
    width: 200,
    searchable: true,
    name: ['titles:rate', 'titles:normalized-currency'],
  },
  [GC.GRC.RATE_NORMALIZED_TOTAL]: {
    width: 200,
    searchable: true,
    name: ['titles:rate', 'titles:normalized-total'],
  },
  [GC.GRC.SERVICES]: {
    width: 200,
    searchable: true,
    name: 'titles:services',
  },
  [RC.FIRST_EVENT_CONTAINER_NUMBER]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:container-number'],
  },
  [RC.LAST_EVENT_CONTAINER_NUMBER]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:container-number'],
  },
  [RC.NEXT_EVENT_CONTAINER_NUMBER]: {
    width: 250,
    searchable: true,
    name: ['titles:next-event', 'titles:container-number'],
  },
  [RC.FIRST_EVENT_CONTAINER_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:container-initial'],
  },
  [RC.LAST_EVENT_CONTAINER_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:container-initial'],
  },
  [RC.NEXT_EVENT_CONTAINER_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:next-event', 'titles:container-initial'],
  },
  [RC.FIRST_EVENT_CONTAINER_NUMBER_WITH_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:container-number-with-initial'],
  },
  [RC.LAST_EVENT_CONTAINER_NUMBER_WITH_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:container-number-with-initial'],
  },
  [RC.NEXT_EVENT_CONTAINER_NUMBER_WITH_INITIAL]: {
    width: 250,
    searchable: true,
    name: ['titles:next-event', 'titles:container-number-with-initial'],
  },
};

export const loadColumnSettings = {
  [RC.EQUIPMENT]: {
    width: 200,
    searchable: true,
    name: 'titles:ordered-equipment',
  },
  [GC.GRC.RATE_SOURCE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:rate', 'titles:source'],
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: 'titles:primary-ref-value',
    customComponent: ({ name, data }: Object) => (
      <TextComponent
        display='block'
        maxWidth='100%'
        cursor='pointer'
        withEllipsis={true}
        color={G.getTheme('colors.light.blue')}
        title={G.getWindowLocale('actions:go-to-details', 'Need locale')}
        onClick={() => G.goToRoute(routesMap.dispatchDetailsLoad(R.prop(GC.FIELD_GUID, data)))}
      >
        {name}
      </TextComponent>
    ),
  },
  [GC.GRC.RATE_STORED_DEADHEAD_DISTANCE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_DISTANCE,
    name: 'titles:deadhead-distance',
  },
  [GC.FIELD_TOTAL_DISTANCE_WITH_DEAD_HEAD]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_DISTANCE,
    name: 'titles:distance-with-deadhead',
  },
  [GC.GRC.RATE_STORED_TOTAL_TRIP_DISTANCE]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_DISTANCE,
    name: 'titles:total-trip-distance',
  },
  [GC.GRC.BROKER_AGENTS_NAME]: {
    width: 300,
    searchable: true,
    name: ['titles:broker-agents', 'titles:last-name'],
  },
  [GC.GRC.BROKER_AGENTS_ID]: {
    width: 300,
    searchable: true,
    name: ['titles:broker-agents', 'titles:id'],
  },
  [RC.CARRIER_ASSIGNMENT_TRACKING_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:tracking-number',
  },
  [RC.CARRIER_ASSIGNMENT_PICKUP_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:pickup-number',
  },
  [RC.CARRIER_ASSIGNMENT_INTEGRATION_ACCOUNT_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:rate', 'titles:integration-account-number'],
  },
  [RC.CARRIER_ASSIGNMENT_TRUCK]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:truck'],
  },
  [RC.CARRIER_ASSIGNMENT_TRAILER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:trailer'],
  },
  [RC.CARRIER_ASSIGNMENT_PRIMARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver'],
  },
  [RC.CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver', 'titles:phone'],
  },
  [RC.CARRIER_ASSIGNMENT_SECONDARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver'],
  },
  [RC.CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver', 'titles:phone'],
  },
  [RC.CARRIER_ASSIGNMENT_DRIVER_TRACKING_APP_INSTALLED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: 'titles:driver-tracking-app-installed',
  },
  [GC.GRC.FLEET_ASSIGNMENT_CURRENT_TRAILERS_UNIT_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:current-trailers', 'titles:unit-id'],
  },
  [GC.GRC.FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:fleet-vendor-mc-number',
  },
  ...columnSettings,
  [RC.EVENTS_LOCATION_STATE]: {
    width: 200,
    searchable: true,
    name: ['titles:all-events', 'titles:states'],
  },
  [RC.EVENTS_LOCATION_NAME]: {
    width: 250,
    searchable: true,
    name: ['titles:all-events', 'titles:location-names'],
  },
  [RC.EVENTS_ITEMS_TEMPERATURE_TYPE]: {
    width: 300,
    type: 'enum',
    searchable: true,
    name: 'titles:temperature-type',
  },
  [GC.GRC.EVENTS_ITEMS_TEMPERATURE_SENSOR]: {
    width: 200,
    searchable: true,
    type: 'collectionOfEnums',
    name: ['titles:items', 'titles:temperature-sensor'],
  },
  [RC.EVENTS_ITEMS_MANUFACTURER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:manufacturer'],
  },
  [RC.EVENTS_ITEMS_YEAR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:year'],
  },
  [RC.EVENTS_ITEMS_MAKE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:make'],
  },
  [RC.EVENTS_ITEMS_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:model'],
  },
  [RC.EVENTS_ITEMS_SUB_MODEL]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:sub-model'],
  },
  [RC.EVENTS_ITEMS_WHEELBASE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase'],
  },
  [RC.EVENTS_ITEMS_WHEELBASE_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:wheelbase-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={RC.EVENTS_ITEMS_WHEELBASE_UOM} />,
  },
  [RC.EVENTS_ITEMS_ODOMETER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer'],
  },
  [RC.EVENTS_ITEMS_ODOMETER_UOM]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:odometer-uom'],
    customComponent: ({ data }: Object) => <UomLocalesTableField data={data} field={RC.EVENTS_ITEMS_ODOMETER_UOM} />,
  },
  [RC.EVENTS_ITEMS_COLOR]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:color'],
  },
  [RC.EVENTS_ITEMS_LICENSE_PLATE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:license-plate'],
  },
  [RC.EVENTS_ITEMS_VIN]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:vin'],
  },
  [RC.EVENTS_ITEMS_BAY]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:bay'],
  },
  [RC.EVENTS_ITEMS_ITEM_TYPE]: {
    width: 200,
    type: 'enum',
    searchable: true,
    name: ['titles:items', 'titles:item-type'],
  },
  [RC.EVENTS_ITEMS_ITEM_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:item-id'],
  },
  [RC.EVENTS_ITEMS_ORIGIN_STATE]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-state'],
  },
  [RC.EVENTS_ITEMS_ORIGIN_COUNTRY]: {
    width: 250,
    searchable: true,
    name: ['titles:items', 'titles:origin-country'],
  },
  [RC.CLOS_TEMPLATE_NAME]: {
    width: 250,
    searchable: true,
    name: ['titles:clo', 'titles:template-name'],
  },
  [GC.GRC.LAST_STATUS_MESSAGE_STATUS_REASON_CODE]: {
    width: 200,
    searchable: true,
    name: 'titles:status-reason-code',
  },
  [RC.LOAD_BOARD_SHIPMENTS_STATUS]: {
    width: 200,
    searchable: true,
    type: 'collectionOfEnums',
    name: ['titles:load-board-shipments', 'titles:status'],
  },
  [RC.LOAD_BOARD_SHIPMENTS_SOURCE]: {
    width: 200,
    searchable: true,
    type: 'collectionOfEnums',
    name: ['titles:load-board-shipments', 'titles:source'],
  },
  [RC.LOAD_BOARD_SHIPMENTS_CREATED_BY]: {
    width: 300,
    searchable: true,
    name: ['titles:load-board-shipments', 'titles:created-by'],
  },
  [RC.LOAD_BOARD_SHIPMENTS_CREATED_DATE]: {
    width: 300,
    searchable: true,
    name: ['titles:load-board-shipments', 'titles:created-date'],
  },
  [RC.LOAD_BOARD_SHIPMENTS_LAST_MODIFIED_BY]: {
    width: 300,
    type: 'enum',
    searchable: true,
    name: ['titles:load-board-shipments', 'titles:last-modified-by'],
  },
  [GC.GRC.FIRST_EVENT_ARRIVE_DATE]: {
    width: 250,
    searchable: true,
    name: ['titles:first-event', 'titles:arrive-date'],
  },
  [GC.GRC.LAST_EVENT_ARRIVE_DATE]: {
    width: 250,
    searchable: true,
    name: ['titles:last-event', 'titles:arrive-date'],
  },
  [GC.GRC.NEXT_EVENT_ARRIVE_DATE]: {
    width: 250,
    searchable: true,
    name: ['titles:next-event', 'titles:arrive-date'],
  },
  [GC.GRC.NEXT_EVENT_COMPLETE_DATE]: {
    searchable: true,
    pivotType: GC.PIVOT_TYPE_YQMD,
    name: ['titles:next-event', 'titles:complete-date'],
  },
  // carrier invoices
  [GC.GRC.CARRIER_INVOICES_NUMBER]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-invoice-number',
  },
  [GC.GRC.CARRIER_INVOICES_TOTAL]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: 'titles:carrier-invoice-total',
  },
  [GC.GRC.CARRIER_INVOICES_STATUS]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-invoice-status',
  },
  [GC.GRC.CARRIER_INVOICES_DATE]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-invoice-date',
  },
  [GC.GRC.CARRIER_INVOICES_DEPOSIT_DATE]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-deposit-date',
  },
  [GC.GRC.CARRIER_INVOICES_CHECK_DATE]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-check-date',
  },
  [GC.GRC.CARRIER_INVOICES_CHECK_NUMBER]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-check-number',
  },
  [GC.GRC.CARRIER_INVOICES_INTEGRATION_DATE]: {
    width: 250,
    searchable: true,
    name: 'titles:carrier-integrated-date',
  },
  [GC.GRC.CARRIER_INVOICES_NORMALIZED_TOTAL]: {
    width: 250,
    searchable: true,
    name: ['titles:carrier-invoice', 'titles:normalized-total'],
  },
  [GC.GRC.CARRIER_INVOICES_NORMALIZED_CURRENCY]: {
    width: 250,
    searchable: true,
    name: ['titles:carrier-invoice', 'titles:normalized-currency'],
  },
  // fleet invoices
  [GC.GRC.FLEET_INVOICES_NORMALIZED_TOTAL]: {
    width: 250,
    searchable: true,
    name: ['titles:fleet-invoice', 'titles:normalized-total'],
  },
  [GC.GRC.FLEET_INVOICES_NORMALIZED_CURRENCY]: {
    width: 250,
    searchable: true,
    name: ['titles:fleet-invoice', 'titles:normalized-currency'],
  },
  //
  [GC.FIELD_INVOICE_TOTAL]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: ['titles:invoices', 'titles:total'],
  },
  [GC.FIELD_INVOICE_CHECK_AMOUNT]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: ['titles:invoices', 'titles:check-amount'],
  },
  [GC.GRC.CLOS_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: ['titles:clos', 'titles:primary-ref-value'],
  },
  [GC.TOTAL_INCOME]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: ['titles:clos', 'titles:total'],
  },
  [GC.GRC.CLOS_SALE_PERSONS_NAME]: {
    width: 300,
    name: 'titles:sale-persons',
  },
  [GC.GRC.MACRO_POINT_INFO_STATUS]: {
    width: 200,
    name: 'titles:macropoint-status',
  },
  [GC.GRC.CLOS_INVOICES_STATUS]: {
    width: 200,
    name: ['titles:customer-invoice', 'titles:status'],
  },
  [GC.GRC.CLOS_INVOICES_CHECK_DATE]: {
    width: 250,
    name: ['titles:customer-invoice', 'titles:check-date'],
  },
  [GC.GRC.CLOS_INVOICES_DEPOSIT_DATE]: {
    width: 250,
    name: ['titles:customer-invoice', 'titles:deposit-date'],
  },
  [GC.FIELD_PRIMARY_DIVISION_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:primary-division-name',
  },
  [RC.CLOS_CLAIM_CLAIM_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-number',
  },
  [RC.CLOS_CLAIM_CLAIM_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:claim-date',
  },
  [RC.CLOS_CLAIM_CLAIM_STATUS]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-status',
  },
  [RC.CLOS_CLAIM_CLAIM_SUB_STATUS]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-sub-status',
  },
  [RC.CLOS_CLAIM_CLAIM_REASON]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-reason',
  },
  [RC.CLOS_CLAIM_CLAIM_TYPE]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-type',
  },
  [RC.ROUTE_TEMPLATE_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:route', 'titles:template-name'],
  },
};

export const orderColumnSettings = {
  [GC.GRC.TELS_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: ['titles:tels', 'titles:primary-ref-value'],
  },
  [GC.FIELD_PRIMARY_REFERENCE_VALUE]: {
    width: 250,
    searchable: true,
    name: 'titles:primary-ref-value',
    customComponent: ({ name, data }: Object) => (
      <TextComponent
        display='block'
        maxWidth='100%'
        cursor='pointer'
        withEllipsis={true}
        color={G.getTheme('colors.light.blue')}
        title={G.getWindowLocale('actions:go-to-details', 'Need locale')}
        onClick={() => G.goToRoute(routesMap.dispatchDetailsOrder(R.prop(GC.FIELD_GUID, data)))}
      >
        {name}
      </TextComponent>
    ),
  },
  [GC.FIELD_LOAD_APPOINTMENT_REQUIRED]: {
    width: 150,
    type: 'boolean',
    searchable: true,
    name: 'titles:appointment-required',
  },
  [GC.GRC.SALE_PERSONS_NAME]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:name'],
  },
  [GC.GRC.SALE_PERSONS_ID]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:id'],
  },
  [GC.GRC.ORDER_TYPE_DISPLAYED_VALUE]: {
    width: 200,
    searchable: true,
    name: 'titles:order-type',
  },
  [RC.TELS_CARRIER_ASSIGNMENT_TRUCK]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:truck'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_TRAILER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:trailer'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver', 'titles:phone'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver', 'titles:phone'],
  },
  [GC.GRC.TELS_CARRIER_TRACKING_NUMBER]: {
    width: 250,
    searchable: true,
    name: ['titles:carrier', 'titles:tracking-number'],
  },
  [RC.TELS_BRANCH_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:current-divisions',
  },
  [GC.FIELD_TEMPLATE_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:template-name',
  },
  [RC.EQUIPMENT]: {
    width: 200,
    searchable: true,
    value: RC.EQUIPMENT,
    name: 'titles:ordered-equipment',
  },
  [GC.GRC.ORIGINATING_DIVISION_BRANCH_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:originating-division',
  },
  [RC.ITEMS_ITEM_HAZMAT_UN_OR_NA_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-un-or-na-number'],
  },
  [RC.ITEMS_ITEM_HAZMAT_PACKAGE_GROUP]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-package-group'],
  },
  [RC.ITEMS_ITEM_HAZMAT_CLASS]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-class'],
  },
  [RC.ITEMS_ITEM_HAZMAT_SHIPPING_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-shipping-name'],
  },
  [RC.ITEMS_ITEM_HAZMAT_CONTACT_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-contact-name'],
  },
  [RC.ITEMS_ITEM_HAZMAT_CONTACT_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-contact-phone'],
  },
  [RC.ITEMS_ITEM_HAZMAT_EMS_NUMBER]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-ems-number'],
  },
  [RC.ITEMS_ITEM_HAZMAT_PLACARDS_DETAILS]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-placard-details'],
  },
  [RC.ITEMS_ITEM_HAZMAT_FLASHPOINT]: {
    width: 200,
    searchable: true,
    name: ['titles:items', 'titles:hazmat-flashpoint'],
  },
  [RC.ITEMS_ITEM_HAZMAT_FLASHPOINT_UOM]: {
    width: 200,
    searchable: true,
    options: ITEM_TEMPERATURE_OPTIONS,
    name: ['titles:items', 'titles:hazmat-flashpoint-uom'],
  },
  [RC.ITEMS_ITEM_HAZMAT_COMMENT]: {
    width: 400,
    searchable: true,
    options: ITEM_TEMPERATURE_OPTIONS,
    name: ['titles:items', 'titles:hazmat-comments'],
  },
  [RC.ITEMS_ITEM_HAZARDOUS]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: ['titles:items', 'titles:hazardous'],
    customComponent: (props: Object) => {
      const hazardous = R.any(G.isTrue, R.pathOr([], ['data', RC.ITEMS_ITEM_HAZARDOUS], props));

      return G.ifElse(
        hazardous,
        I.uiTrue,
        I.uiFalse,
      )();
    },
  },
  [GC.GRC.ITEMS_STORED_WEIGHT]: {
    width: 200,
    collection: true,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: ['titles:items', 'titles:weight'],
  },
  [GC.GRC.HAZMAT_ITEMS_STORED_WEIGHT]: {
    width: 200,
    collection: true,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: ['titles:hazmat-items', 'titles:weight'],
  },
  [GC.FIELD_STORED_HAZMAT_TOTAL_WEIGHT]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: ['titles:hazmat-items', 'titles:total-weight'],
  },
  [GC.GRC.HAZMAT_ITEMS_ITEM_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:hazmat-items', 'titles:item-id'],
  },
  [GC.GRC.NON_HAZMAT_ITEMS_STORED_WEIGHT]: {
    width: 200,
    collection: true,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: ['titles:non-hazmat-items', 'titles:weight'],
  },
  [GC.FIELD_STORED_NON_HAZMAT_TOTAL_WEIGHT]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    type: GC.FIELD_WEIGHT,
    name: ['titles:non-hazmat-items', 'titles:total-weight'],
  },
  [GC.GRC.NON_HAZMAT_ITEMS_ITEM_ID]: {
    width: 200,
    searchable: true,
    name: ['titles:non-hazmat-items', 'titles:item-id'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME]: {
    width: 220,
    searchable: true,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME]: {
    width: 220,
    searchable: true,
    name: ['titles:secondary-driver', 'titles:full-name'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:fleet-vendor-mc-number',
  },
  [GC.FIELD_APPROVED]: {
    width: 200,
    type: 'boolean',
    searchable: true,
    name: 'titles:approved',
  },
  [GC.FIELD_APPROVED_BY]: {
    width: 200,
    searchable: true,
    name: 'titles:approved-by',
  },
  [GC.FIELD_TOTAL_SPENDING]: {
    width: 200,
    searchable: true,
    pivotType: 'number',
    name: 'titles:total-spend',
  },
  [GC.GRC.INVOICES_NUMBER]: {
    width: 250,
    searchable: true,
    name: 'titles:invoice-number',
  },
  [GC.GRC.INVOICES_TOTAL]: {
    width: 250,
    searchable: true,
    pivotType: 'number',
    name: 'titles:invoice-total',
  },
  [GC.GRC.INVOICES_STATUS]: {
    width: 250,
    searchable: true,
    name: 'titles:invoice-status',
  },
  [GC.GRC.CLO_TELS_FLEET_VENDOR_INVOICES_PAY_TYPE]: {
    width: 300,
    searchable: true,
    name: ['titles:vendor-invoices', 'titles:pay-type'],
  },
  [GC.FIELD_PRIMARY_DIVISION_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:primary-division-name',
  },
  [RC.CLAIM_CLAIM_NUMBER]: {
    width: 200,
    searchable: true,
    useExpandedContainer: true,
    name: 'titles:claim-number',
    guidPropName: RC.CLAIM_GUID,
    type: GC.PAGE_TYPE_CLAIM__DETAILS,
    cellPermissions: [PC.CLAIM_READ, PC.CLAIM_WRITE],
  },
  [RC.CLAIM_CLAIM_DATE]: {
    width: 200,
    type: 'date',
    searchable: true,
    name: 'titles:claim-date',
  },
  [RC.CLAIM_CLAIM_STATUS]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-status',
  },
  [RC.CLAIM_CLAIM_SUB_STATUS]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-sub-status',
  },
  [RC.CLAIM_CLAIM_REASON]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-reason',
  },
  [RC.CLAIM_CLAIM_TYPE]: {
    width: 200,
    searchable: true,
    name: 'titles:claim-type',
  },
  ...columnSettings,
};

export const routeColumnSettings = {
  [GC.FIELD_NAME]: {
    width: 200,
    name: ['titles:route', 'titles:name'],
  },
  [RC.CLO_CONTAINERS_NUMBER]: {
    width: 300,
    searchable: true,
    name: ['titles:containers', 'titles:container-number'],
  },
  [GC.FIELD_BRANCH_DOT_BRANCH_NAME]: {
    width: 200,
    name: ['titles:route', 'titles:branch'],
  },
  [GC.FIELD_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:route', 'titles:status'],
  },
  [GC.FIELD_MARGIN]: {
    width: 200,
    name: ['titles:route', 'titles:margin'],
  },
  [GC.FIELD_CLO_COUNT]: {
    width: 150,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:route', 'titles:clo-count'],
  },
  [GC.FIELD_TEL_COUNT]: {
    width: 150,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:route', 'titles:tel-count'],
  },
  [GC.TOTAL_INCOME]: {
    width: 200,
    name: ['titles:route', 'titles:total-income'],
  },
  [GC.FIELD_TOTAL_SPEND]: {
    width: 200,
    name: ['titles:route', 'titles:total-spend'],
  },
  [GC.FIELD_CREATED_BY]: {
    width: 200,
    name: ['titles:route', 'titles:created-by'],
  },
  [GC.FIELD_CREATED_DATE]: {
    type: 'date',
    name: ['titles:route', 'titles:created-date'],
  },
  [GC.FIELD_LAST_MODIFIED_BY]: {
    width: 200,
    name: ['titles:route', 'titles:last-modified-by'],
  },
  [GC.FIELD_LAST_MODIFIED_DATE]: {
    type: 'date',
    name: ['titles:route', 'titles:last-modified-date'],
  },
  [RC.CLO_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:clo', 'titles:status'],
  },
  [RC.CLO_EXTERNAL_ID]: {
    width: 200,
    name: ['titles:clo', 'titles:external-id'],
  },
  [RC.CLO_SENDER_ID]: {
    width: 200,
    name: ['titles:clo', 'titles:sender-id'],
  },
  [RC.CLO_QUOTE_REQUEST_NUMBER]: {
    width: 200,
    name: ['titles:clo', 'titles:quote-request-number'],
  },
  [RC.CLO_EQUIPMENT]: {
    width: 200,
    name: ['titles:clo', 'titles:equipments'],
  },
  [RC.CLO_SERVICES]: {
    width: 200,
    name: ['titles:clo', 'titles:services'],
  },
  [RC.CLO_PRIMARY_REFERENCE_VALUE]: {
    width: 220,
    name: ['titles:clo', 'titles:primary-ref-value'],
  },
  [RC.CLO_TIME_TO_NEXT_STOP]: {
    width: 220,
    name: ['titles:clo', 'titles:time-to-next-stop'],
  },
  [RC.CLO_PACKAGE_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:clo', 'titles:package-type'],
  },
  [FIELD_CLO_TOTAL_WEIGHT]: {
    width: 200,
    name: ['titles:clo', 'titles:total-weight'],
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_WEIGHT} />,
  },
  [RC.CLO_TOTAL_PICKUP_QUANTITY]: {
    width: 200,
    name: ['titles:clo', 'titles:quantity'],
  },
  [GC.FIELD_CLO_TOTAL_VOLUME]: {
    width: 200,
    name: ['titles:clo', 'titles:total-volume'],
  },
  [RC.TEL_STATUS]: {
    width: 200,
    type: 'enum',
    name: ['titles:tel', 'titles:status'],
  },
  [RC.TEL_EQUIPMENT]: {
    width: 200,
    name: ['titles:tel', 'titles:equipments'],
  },
  [RC.TEL_SERVICES]: {
    width: 200,
    name: ['titles:tel', 'titles:services'],
  },
  [RC.TEL_PRIMARY_REFERENCE_VALUE]: {
    width: 220,
    name: ['titles:tel', 'titles:primary-ref-value'],
  },
  [RC.TEL_TIME_TO_NEXT_STOP]: {
    width: 220,
    name: ['titles:tel', 'titles:time-to-next-stop'],
  },
  [GC.FIELD_TEL_TOTAL_DISTANCE]: {
    width: 220,
    name: ['titles:tel', 'titles:trip-distance'],
    customComponent: (data: Object) => <TableField data={data} type={GC.FIELD_DISTANCE} />,
  },
  [RC.TEL_PACKAGE_TYPE]: {
    width: 200,
    type: 'enum',
    name: ['titles:tel', 'titles:package-type'],
  },
  [RC.TEL_TOTAL_PICKUP_QUANTITY]: {
    width: 200,
    name: ['titles:tel', 'titles:quantity'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LOGIN_ID]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:login-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_EMAIL]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:email'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FIRST_NAME]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:first-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_LAST_NAME]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:last-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LOGIN_ID]: {
    width: 220,
    name: ['titles:secondary-driver', 'titles:login-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_EMAIL]: {
    width: 220,
    name: ['titles:secondary-driver', 'titles:email'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_FIRST_NAME]: {
    width: 220,
    name: ['titles:secondary-driver', 'titles:first-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_SEC_DRIVER_LAST_NAME]: {
    width: 220,
    name: ['titles:secondary-driver', 'titles:last-name'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_PRIMARY_DRIVER_FULL_NAME]: {
    width: 220,
    name: ['titles:primary-driver', 'titles:full-name'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_SECONDARY_DRIVER_FULL_NAME]: {
    width: 220,
    name: ['titles:secondary-driver', 'titles:full-name'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRUCK_UNIT_ID]: {
    width: 220,
    name: ['titles:truck', 'titles:unit-id'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRUCK_FLEET_VENDOR_NAME]: {
    width: 200,
    searchable: true,
    name: ['titles:truck', 'titles:vendor'],
  },
  [RC.TELS_FLEET_ASSIGNMENT_TRAILERS_UNIT_ID]: {
    width: 220,
    name: ['titles:trailers', 'titles:unit-id'],
  },
  [GC.GRC.TELS_FLEET_ASSIGNMENT_PR_DRIVER_FLEET_VENDOR_MC_NUMBER]: {
    width: 200,
    searchable: true,
    name: 'titles:fleet-vendor-mc-number',
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_NAME]: {
    width: 200,
    name: ['titles:carrier', 'titles:name'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_SCAC]: {
    width: 200,
    name: ['titles:carrier', 'titles:scac'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SNAPSHOT_MC_NUMBER]: {
    width: 200,
    name: ['titles:carrier', 'titles:mc-number'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_TRUCK]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:truck'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_TRAILER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:trailer'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_PRIMARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:primary-driver', 'titles:phone'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver'],
  },
  [RC.TELS_CARRIER_ASSIGNMENT_SECONDARY_DRIVER_PHONE]: {
    width: 200,
    searchable: true,
    name: ['titles:carrier', 'titles:secondary-driver', 'titles:phone'],
  },
  [`${GC.SYSTEM_LIST_TELS}.${GC.GRC.RATE_DISPATCH_OR_CANCEL_DATE}`]: {
    type: 'date',
    name: 'titles:dispatched-date',
  },
  [`${GC.SYSTEM_LIST_TELS}.${GC.GRC.RATE_DISPATCHED_OR_CANCELED_BY}`]: {
    width: 200,
    name: 'titles:dispatched-by',
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:location-type'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:location-name'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:template-id'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:address1'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:city'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:state'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:country'],
  },
  [RC.CLOS_FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:zip'],
  },
  [RC.CLOS_FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:complete-date'],
  },
  [GC.GRC.FIRST_EVENT_PICKUP_NUMBER]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:pick-up-number'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:location-type'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:template-id'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:location-name'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:address1'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:city'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:state'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:country'],
  },
  [RC.CLOS_LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:zip'],
  },
  [RC.CLOS_LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:complete-date'],
  },
  [RC.CLOS_FIRST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:early-date'],
  },
  [RC.CLOS_FIRST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:late-date'],
  },
  [RC.CLOS_FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:clo-first-event', 'titles:appointment-early-date'],
  },
  [RC.CLOS_LAST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:early-date'],
  },
  [RC.CLOS_LAST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:clo-last-event'],
  },
  [RC.CLOS_LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:clo-last-event', 'titles:appointment-early-date'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:location-type'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:location-name'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:template-id'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:address1'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:city'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:state'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:country'],
  },
  [RC.TELS_FIRST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:zip'],
  },
  [RC.TELS_FIRST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:complete-date'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_LOCATION_TYPE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:location-type'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_TEMPLATE_ID]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:template-id'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_NAME]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:location-name'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_ADDRESS1]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:address1'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_CITY]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:city'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_STATE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:state'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_COUNTRY]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:country'],
  },
  [RC.TELS_LAST_EVENT_LOCATION_ZIP]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:zip'],
  },
  [RC.TELS_LAST_EVENT_COMPLETE_DATE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:complete-date'],
  },
  [RC.TELS_FIRST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:early-date'],
  },
  [RC.TELS_FIRST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:late-date'],
  },
  [RC.TELS_FIRST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:tel-first-event', 'titles:appointment-early-date'],
  },
  [RC.TELS_LAST_EVENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:early-date'],
  },
  [RC.TELS_LAST_EVENT_LATE_DATE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:clo-last-event'],
  },
  [RC.TELS_LAST_EVENT_APPOINTMENT_EARLY_DATE]: {
    width: 250,
    name: ['titles:tel-last-event', 'titles:appointment-early-date'],
  },
  [RC.TEL_BRANCH_ACCOUNT_NAME]: {
    width: 200,
    name: ['titles:tel', 'titles:account-number'],
  },
  [RC.TEL_BRANCH_NAME]: {
    width: 200,
    name: ['titles:tel', 'titles:branch'],
  },
  [RC.CLO_BRANCH_ACCOUNT_NAME]: {
    width: 200,
    name: ['titles:clo', 'titles:account-number'],
  },
  [RC.CLO_BRANCH_NAME]: {
    width: 200,
    name: ['titles:clo', 'titles:branch'],
  },
  [RC.CLO_BILL_TO_LOCATION_NAME]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:name'],
  },
  [RC.CLO_BILL_TO_COUNTRY]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:country'],
  },
  [RC.CLO_BILL_TO_STATE]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:state'],
  },
  [RC.CLO_BILL_TO_CITY]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:city'],
  },
  [RC.CLO_BILL_TO_ADDRESS1]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:address1'],
  },
  [RC.CLO_BILL_TO_ADDRESS2]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:address2'],
  },
  [RC.CLO_BILL_TO_ZIP]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:zip'],
  },
  [RC.CLO_BILL_TO_COMMENTS]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:comments'],
  },
  [RC.CLO_BILL_TO_PAYMENT_TERM]: {
    width: 250,
    type: 'enum',
    name: ['titles:clo-bill-to', 'titles:payment-terms'],
  },
  [RC.CLO_BILL_TO_LOCATION_TYPE_VALUE]: {
    width: 250,
    name: ['titles:clo-bill-to', 'titles:type'],
  },
  [RC.TEL_BILL_TO_LOCATION_NAME]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:name'],
  },
  [RC.TEL_BILL_TO_COUNTRY]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:country'],
  },
  [RC.TEL_BILL_TO_STATE]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:state'],
  },
  [RC.TEL_BILL_TO_CITY]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:city'],
  },
  [RC.TEL_BILL_TO_ADDRESS1]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:address1'],
  },
  [RC.TEL_BILL_TO_ADDRESS2]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:address2'],
  },
  [RC.TEL_BILL_TO_ZIP]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:zip'],
  },
  [RC.TEL_BILL_TO_COMMENTS]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:comments'],
  },
  [RC.TEL_BILL_TO_PAYMENT_TERM]: {
    width: 250,
    type: 'enum',
    name: ['titles:tel-bill-to', 'titles:payment-terms'],
  },
  [RC.TEL_BILL_TO_LOCATION_TYPE_VALUE]: {
    width: 250,
    name: ['titles:tel-bill-to', 'titles:type'],
  },
  [RC.TEL_PRIMARY_REFERENCE_NAME]: {
    width: 220,
    name: ['titles:tel', 'titles:primary-ref-name'],
  },
  [RC.CLO_PRIMARY_REFERENCE_NAME]: {
    width: 220,
    name: ['titles:clo', 'titles:primary-ref-name'],
  },
  [RC.TEL_EVENT_COUNT]: {
    width: 150,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:tel', 'titles:event-count'],
  },
  [RC.TEL_RATE_MAIN_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:tel', 'titles:main-charges-total'],
  },
  [RC.TEL_RATE_ADDITIONAL_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:tel', 'titles:additional-charges-total'],
  },
  [RC.TEL_RATE_FUEL_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:tel', 'titles:fuel-charges-total'],
  },
  [RC.TEL_RATE_TOTAL]: {
    width: 220,
    name: ['titles:tel', 'titles:total-rate'],
  },
  [RC.TELS_RATE_NORMALIZED_TOTAL_TOTAL]: {
    width: 220,
    name: ['titles:tel', 'titles:normalized-total-rate'],
  },
  [RC.TEL_INVOICE_COUNT]: {
    width: 180,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:tel', 'titles:invoice-count'],
  },
  [RC.CLO_EVENT_COUNT]: {
    width: 150,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:clo', 'titles:event-count'],
  },
  [RC.CLO_RATE_MAIN_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:clo', 'titles:main-charges-total'],
  },
  [RC.CLO_RATE_ADDITIONAL_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:clo', 'titles:additional-charges-total'],
  },
  [RC.CLO_RATE_FUEL_CHARGES_TOTAL]: {
    width: 220,
    name: ['titles:clo', 'titles:fuel-charges-total'],
  },
  [RC.CLO_RATE_TOTAL]: {
    width: 220,
    name: ['titles:clo', 'titles:total-rate'],
  },
  [RC.CLOS_RATE_NORMALIZED_TOTAL_TOTAL]: {
    width: 220,
    name: ['titles:clo', 'titles:normalized-total-rate'],
  },
  [RC.CLO_INVOICE_COUNT]: {
    width: 180,
    styles: { titleInputMaxWidth: 100 },
    name: ['titles:clo', 'titles:invoice-count'],
  },
  [GC.GRC.CLOS_SALE_PERSONS_NAME]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:name'],
  },
  [GC.GRC.CLOS_SALE_PERSONS_ID]: {
    width: 300,
    name: ['titles:sale-persons', 'titles:id'],
  },
  [GC.GRC.TELS_BROKER_AGENTS_NAME]: {
    width: 300,
    name: ['titles:broker-agents', 'titles:last-name'],
  },
  [GC.GRC.TELS_BROKER_AGENTS_ID]: {
    width: 300,
    name: ['titles:broker-agents', 'titles:id'],
  },
  [RC.CLO_ITEMS_FREIGHT_CLASS]: {
    width: 200,
    type: 'freightClass',
    name: ['titles:clo', 'titles:items', 'titles:freight-class'],
  },
  [GC.GRC.TELS_RATE_MODE]: {
    width: 200,
    name: ['titles:tel', 'titles:mode'],
  },
  [GC.GRC.TELS_CARRIER_TRACKING_NUMBER]: {
    width: 200,
    name: ['titles:tel', 'titles:tracking-number'],
  },
  [RC.TELS_CARRIER_INTEGRATION_ACCOUNT_NUMBER]: {
    width: 200,
    name: ['titles:tel', 'titles:rate', 'titles:integration-account-number'],
  },
  [GC.GRC.TELS_CARRIER_BOL_NUMBER]: {
    width: 200,
    name: ['titles:tel', 'titles:bol-number'],
  },
  [GC.GRC.TELS_CARRIER_PICKUP_NUMBER]: {
    width: 200,
    name: ['titles:tel', 'titles:pickup-number'],
  },
  [GC.GRC.CLOS_RATE_MODE]: {
    width: 200,
    name: ['titles:clo', 'titles:mode'],
  },
  [RC.CLO_INVOICES_INTEGRATED_DATE]: {
    width: 250,
    name: ['titles:customer-invoices', 'titles:integrated-date'],
  },
  [RC.TEL_FLEET_INVOICES_INTEGRATION_DATE]: {
    width: 250,
    name: ['titles:driver-invoices', 'titles:integrated-date'],
  },
  [RC.TEL_CARRIER_INVOICES_INTEGRATION_DATE]: {
    width: 250,
    name: ['titles:carrier-invoices', 'titles:integrated-date'],
  },
  [RC.TEL_FLEET_VENDOR_INVOICES_INTEGRATION_DATE]: {
    width: 250,
    name: ['titles:vendor-invoices', 'titles:integrated-date'],
  },
  [GC.FIELD_TEMPLATE_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:template-name',
  },
  [GC.FIELD_PRIMARY_DIVISION_NAME]: {
    width: 200,
    searchable: true,
    name: 'titles:primary-division-name',
  },
};

import * as R from 'ramda';
import * as Yup from 'yup';
import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, compose, withHandlers } from 'react-recompose';
// common
import { makeSelectDocumentTemplates } from '../../../common/selectors';
// components
import { FormFooter2 } from '../../../components/form-footer';
import { openLoader, closeLoader } from '../../../components/loader/actions';
// forms
import { Fieldset2 } from '../../../forms';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// hocs
import {
  withAsyncCLODocuments,
  withAsyncDocumentTemplates,
  withComponentDidUpdatePropCallback,
  withAsyncDocumentTemplatesByTelGuid,
  withAsyncGetVendorDocumentsByCloGuid,
} from '../../../hocs';
// feature dispatch-details-new
import { setDocumentTemplatesToStore } from '../load/actions';
import { makeSelectTelDocumentTemplates } from '../load/selectors';
//////////////////////////////////////////////////

export const validationSchemaPrintDocumentObject = {
  [GC.FIELD_TEMPLATE_GUID]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const validationSchemaPrintDocumentsObject = {
  [GC.FIELD_DOCUMENTS]: G.yupArrayRequired,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
  [GC.FIELD_FILE_NAME]: Yup.string()
    .nullable(true)
    .required(G.getRequiredLocaleTxt()),
};

const defaultPrintDocumentsFields = {
  [GC.FIELD_DOCUMENTS]: null,
  [GC.FIELD_FILE_NAME]: null,
  [GC.FIELD_DOCUMENT_DOCUMENT_TYPE]: null,
};

const inputWrapperStyles = {
  mb: 25,
  width: 270,
};

const templatesField = {
  type: 'select',
  isRequired: true,
  inputWrapperStyles,
  options: 'templates',
  fieldName: GC.FIELD_TEMPLATE_GUID,
  label: ['titles:template', 'Template'],
};

const eventsField = {
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  options: 'eventOptions',
  fieldName: 'eventGuids',
  label: ['titles:stops', 'Stops'],
};

const documentsField = {
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  closeMenuOnSelect: false,
  options: 'documentsOptions',
  fieldName: GC.FIELD_DOCUMENTS,
  label: ['titles:documents', 'Documents'],
};

const printDocumentsFields = [
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_FILE_NAME,
    label: ['titles:name', 'Name'],
  },
  {
    type: 'select',
    isRequired: true,
    inputWrapperStyles,
    options: 'documentTypeOptions',
    label: ['titles:type', 'Type'],
    fieldName: GC.FIELD_DOCUMENT_DOCUMENT_TYPE,
  },
  {
    isMulti: true,
    isRequired: true,
    inputWrapperStyles,
    type: 'reactSelect',
    closeMenuOnSelect: false,
    options: 'documentsOptions',
    fieldName: GC.FIELD_DOCUMENTS,
    label: ['titles:documents', 'Documents'],
  },
];

export const vendorDocumentsField = {
  isMulti: true,
  inputWrapperStyles,
  type: 'reactSelect',
  closeMenuOnSelect: false,
  options: 'vendorDocuments',
  fieldName: GC.FIELD_VENDOR_DOCUMENT_GUIDS,
  label: ['titles:vendor-documents', 'Vendor Documents'],
};

const getFields = ({
  values,
  withType,
  withDocuments,
  vendorDocuments,
  printableSection,
  documentTemplates,
}: Object) => {
  if (withType) return printDocumentsFields;

  if (withDocuments) {
    const fields = [templatesField, documentsField];

    return G.ifElse(
      G.isNotNilAndNotEmpty(vendorDocuments),
      R.append(vendorDocumentsField, fields),
      fields,
    );
  }

  const isEventRelatedTemplate = R.compose(
    R.includes(GC.DOCUMENT_TEMPLATE_SPECIFIC_FEATURE_EVENT_RELATED),
    R.pathOr([], [
      R.prop(GC.FIELD_TEMPLATE_GUID, values),
      GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_SPECIFIC_DOCUMENT_FEATURES,
    ]),
    R.indexBy(R.prop(GC.FIELD_GUID)),
    R.pathOr([], [printableSection]),
  )(documentTemplates);

  if (isEventRelatedTemplate) {
    return [templatesField, eventsField];
  }

  if (G.isNotNilAndNotEmpty(vendorDocuments)) return [templatesField, vendorDocumentsField];

  return R.of(Array, templatesField);
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  documentTemplates: makeSelectDocumentTemplates(state),
});

const telDocumentTemplatesMapStateToProps = (state: Object) => createStructuredSelector({
  telDocumentTemplates: makeSelectTelDocumentTemplates(state),
});

const enhanceWithoutConnect = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ withType }: Object) => (G.ifElse(
      withType,
      defaultPrintDocumentsFields,
      { [GC.FIELD_TEMPLATE_GUID]: null },
    )),
    validationSchema: ({ withType }: Object) => Yup.object().shape(G.ifElse(
      withType,
      validationSchemaPrintDocumentsObject,
      validationSchemaPrintDocumentObject,
    )),
    handleSubmit: (values: Object, { props, setSubmitting }: Object) => {
      const { submitAction, selectedEntity } = props;

      const data = R.assoc('guids', R.of(Array, selectedEntity), values);
      submitAction(data);
      setTimeout(() => setSubmitting(false), 3000);
    },
  }),
  withHandlers({
    handleSetFieldTouchedDocuments: ({ touched, setFieldTouched }: Object) => () => {
      if (G.isArray(G.getPropFromObject(GC.FIELD_DOCUMENTS, touched))) {
        setFieldTouched(GC.FIELD_DOCUMENTS, true, true);
      }
    },
  }),
  withComponentDidUpdatePropCallback({
    propName: 'touched',
    callbackName: 'handleSetFieldTouchedDocuments',
  }),
  pure,
);

const enhance = compose(
  connect(mapStateToProps),
  withAsyncDocumentTemplates,
  enhanceWithoutConnect,
  withAsyncGetVendorDocumentsByCloGuid,
);

const enhanceForPrintTelDocuments = compose(
  connect(telDocumentTemplatesMapStateToProps, { openLoader, closeLoader, setDocumentTemplatesToStore }),
  withAsyncDocumentTemplatesByTelGuid,
  enhanceWithoutConnect,
);

const Component = (props: Object) => {
  const {
    values,
    withType,
    handleSubmit,
    eventOptions,
    withDocuments,
    vendorDocuments,
    documentsOptions,
    printableSection,
    documentTemplates,
    useVendorDocuments,
    documentTypeOptions,
    asyncDocumentTemplates,
    asyncTelDocumentsOptions,
    availableDocumentTypesOptionsAsync,
  } = props;

  const documentTemplatesToUse = R.or(asyncDocumentTemplates, documentTemplates);

  const documentOptionsToUse = G.ifElse(
    G.isNotNilAndNotEmpty(asyncTelDocumentsOptions),
    asyncTelDocumentsOptions,
    documentsOptions,
  );

  const documentTypeOptionsToUse = G.ifElse(
    G.isNotNilAndNotEmpty(availableDocumentTypesOptionsAsync),
    availableDocumentTypesOptionsAsync,
    documentTypeOptions,
  );

  const optionsForSelect = {
    eventOptions,
    vendorDocuments,
    documentsOptions: documentOptionsToUse,
    documentTypeOptions: G.addEmptyOptionToDropDown(documentTypeOptionsToUse),
    templates: G.addEmptyOptionToDropDown(
      G.mapNameGuidToLabelValue(R.pathOr([], [printableSection], documentTemplatesToUse)),
    ),
  };

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...optionsForSelect}
        {...G.getFormikProps(props)}
        fieldsWrapperStyles={{ mt: 10 }}
        fields={getFields({
          values,
          withType,
          withDocuments,
          vendorDocuments,
          printableSection,
          useVendorDocuments,
          documentTemplates: documentTemplatesToUse,
        })}
      />
      <FormFooter2 />
    </form>
  );
};

const PrintForm = enhance((props: Object) => (
  <Component {...props} />
));

export const PrintFormWithCLODocuments = withAsyncCLODocuments((props: Object) =>
  <PrintForm {...props} withDocuments={true} />,
);

export const PrintTelDocumentsForm = enhanceForPrintTelDocuments(Component);

export default PrintForm;

import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// feature load-board
import AutodialButton from './autodial-button';
//////////////////////////////////////////////////

const maxCount = 10;

const HeaderActions = (props: Object) => {
  const {
    filtersCount,
    availableLoadBoards,
    handleCreateSearchFilter,
    handleConfigureLoadBoards,
  } = props;

  const blueColor = G.getTheme('colors.dark.blue');
  const greyColor = G.getTheme('colors.lightGrey');
  const activeCount = R.length(availableLoadBoards);
  const allCount = R.length(GC.EXTERNAL_LOAD_BOARDS_TO_SEARCH);
  const maxFiltersCount = R.equals(filtersCount, maxCount);

  const postProps = G.ifElse(
    maxFiltersCount,
    { cursor: 'not-allowed', color: greyColor, onClick: () => {} },
    { cursor: 'pointer', color: blueColor, onClick: handleCreateSearchFilter },
  );

  return (
    <Flex>
      <AutodialButton />
      <Box
        mr='8px'
        cursor='pointer'
        color={blueColor}
        onClick={handleConfigureLoadBoards}
      >
        {`${G.getWindowLocale('actions:configure-load-boards', 'Load Boards')}(${activeCount}/${allCount})`}
      </Box>
      <Flex {...postProps} ml={16}>
        <Box mr='8px'>
          {`${G.getWindowLocale('actions:post-equipment', 'Post Equipment')} (${filtersCount}/${maxCount})`}
        </Box>
        {I.createTruckIcon(G.ifElse(maxFiltersCount, greyColor, blueColor), 30, 30)}
      </Flex>
    </Flex>
  );
};

export default HeaderActions;

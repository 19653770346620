import * as R from 'ramda';
import FileDownload from 'js-file-download';
import { change, initialize } from 'redux-form';
import {
  put,
  all,
  call,
  race,
  take,
  fork,
  select,
  takeLatest,
} from 'redux-saga/effects';
// common
import {
  initialDataLoadFail,
  initialDataLoadSuccess,
  showRequestStatusModal,
} from '../../common/actions';
// components
import { closeModal } from '../../components/modal/actions';
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { ROOT_BRANCH_GUID } from '../branch/constants';
import { getConfigByGroupSaga } from '../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { socketCarrierDocumentGeneratedRequest } from '../sockets/actions';
import { makeSelectExpandedContainerOpened } from '../expanded-container/selectors';
import { getSequencesByTypeRequest, getSequencesByTypeSuccess } from '../sequence/actions';
import { makeSelectAccessToPagesList, makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import {
  getAllAvailableRefTypesByScopeRequest,
  getAllAvailableRefTypesByScopeRequestSuccess,
} from '../reference/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../components/edit-report/helpers';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { getCarrierRateIntegrationOptions } from '../../helpers/options';
// report-common
import { getReportSagas } from '../../report-common';
// utilities
import routesMap from '../../utilities/routes';
import endpointsMap from '../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../utilities/http';
// feature carrier
import * as A from './actions';
import * as S from './selectors';
import { EDIT_GENERAL_DETAILS_FORM } from './constants';
import CarrierContractSaga from './sagas/carrier-contract-saga';
import CustomerContractSaga from './sagas/customer-contract-saga';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectCarrierDocs,
  makeSelectCarrierGuid,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectCarrierTerminals,
  makeSelectAvailableReports,
  makeSelectTerminalContacts,
  makeSelectTableTitleFilters,
  makeSelectCarrierBranchGuid,
  makeSelectCarrierInsurances,
  makeSelectCarrierFormValues,
  makeSelectCarrierAssessorials,
  makeSelectCarrierPayLocations,
  makeSelectCarrierCertificates,
  makeSelectTerminalPayLocations,
  makeSelectCarrierAssocCompanies,
  makeSelectLoadBoardSynchronizationParams,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetCarrierOnboardingPackageRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierOnboardingPackageList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedCarrierOnboardingPackageSuccess(G.isNotNilAndNotEmpty(data)));
    } else {
      yield call(G.handleException, 'error', 'handleGetCarrierOnboardingPackageRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleGetCarrierOnboardingPackageRequest exception');
  }
}

function* handleGetCarrierSafetyInfoRequest({ payload }: Object) {
  try {
    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierSafety, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedSafetySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierSafetyInfoRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, 'handleGetCarrierSafetyInfoRequest exception');
  }
}

function* handleDownloadCarrierMailRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.downloadCarrierMail(payload);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      FileDownload(data, 'mail.eml');

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadCarrierMailRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'handleDownloadCarrierMailRequestSaga exception');
  }
}

function* handleGetCarrierMailListRequestSaga() {
  try {
    const carrierGuid = yield select(makeSelectCarrierGuid());

    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierMailList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.receivedMailListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierMailListRequestSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, 'handleGetCarrierMailListRequestSaga exception');
  }
}

function* handleGetCarrierSaga({ guid }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.getCarrierEndpoint(guid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const branchGuid = G.getBranchGuidFromObject(data);

      yield call(handleGetCarrierOnboardingPackageRequest);

      yield all([
        put(A.getCarrierSuccess(data)),
        put(A.getCarrierRefRequest(guid)),
        put(A.getCarrierFinanceRequest(guid)),
        put(A.getCarrierTerminalsRequest(guid)),
        put(A.getCarrierInsuranceRequest(guid)),
        put(A.getCarrierSafetyInfoRequest(guid)),
        put(A.getCarrierAssocCompanyRequest(guid)),
        put(A.getCarrierConfigsRequest(branchGuid)),
        put(A.getGeneralConfigsRequest(branchGuid)),
        put(A.getTemplateConfigsRequest(branchGuid)),
        put(A.getCarrierIntegrationListRequest(guid)),
        put(A.getCarrierPortalLoginUsersRequest(guid)),
        put(A.getCarrierInternalDispatchSettings(guid)),
        put(A.getLoadBoardIntegrationListRequest(guid)),
        put(A.getCarrierIntegrationAuditListRequest(guid)),
      ]);
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierSaga exception');
  }
}

function* handleGetCarrierConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const params = {
      group: GC.CARRIER_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      { params },
    );

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierConfigsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierConfigsSaga exception');
  }
}

function* handleGetGeneralConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const params = {
      group: GC.GENERAL_CONFIG_GROUP,
      [GC.FIELD_BRANCH_GUID]: payload,
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      { params },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getGeneralConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetGeneralConfigsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetGeneralConfigsSaga exception');
  }
}

function* handleGetTemplateConfigsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const params = {
      [GC.FIELD_BRANCH_GUID]: payload,
      group: GC.TEMPLATES_CONFIG_GROUP,
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.branchConfigsEndpoint,
      { params },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTemplateConfigsSuccess(G.mapConfigValuesByName(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTemplateConfigsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetTemplateConfigsSaga exception');
  }
}

function* handleGetCarrierRefSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierReferenceList;

    const options = {
      params: { guid: payload },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierRefSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierRefSaga exception');
  }
}

function* handleGetCarrierAssocCompanySaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierAssocCompanyList;

    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierAssocCompanySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierAssocCompanySaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierAssocCompanySaga exception');
  }
}

function* handleGetCarrierTerminalsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierTerminalList;

    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierTerminalsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierTerminalsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierTerminalsSaga exception');
  }
}

function* handleGetCarrierInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierInsuranceList;

    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierInsuranceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierInsuranceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierInsuranceSaga exception');
  }
}

function* handleGetCarrierDocsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { primaryObjectGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierDocList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierDocsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierDocsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierDocsSaga exception');
  }
}

function* handleGetTerminalContactsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { terminalGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierTerminalContactList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTerminalContactsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTerminalContactsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetTerminalContactsSaga exception');
  }
}

function* handleGetTerminalFinanceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { terminalGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierTerminalFinance, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTerminalFinanceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTerminalFinanceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetTerminalFinanceSaga exception');
  }
}

function* handleGetTerminalPayLocationsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { terminalFinancialGuid: payload },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.carrierTerminalPayLocationList,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getTerminalPayLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetTerminalPayLocationsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetTerminalPayLocationsSaga exception');
  }
}

function* handleGetCarrierFinanceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierFinance, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierFinanceSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierFinanceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierFinanceSaga exception');
  }
}

function* handleGetCarrierPayLocationsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { carrierFinancialGuid: payload },
    };

    const res = yield call(
      sendRequest,
      'get',
      endpointsMap.carrierPayLocationList,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierPayLocationsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierPayLocationsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierPayLocationsSaga exception');
  }
}

function* handleGetCarrierCertificatesSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));
    const options = {
      params: { carrierGuid: payload },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.carrierCertificate, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierCertificatesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierCertificatesSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.handleException, error, 'handleGetCarrierCertificatesSaga exception');
  }
}

function* getAccessorialConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.accessorialsListEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAccessorialConfigListSuccess(data));
    } else {
      yield call(G.handleFailResponseSimple, res, 'getAccessorialConfigListRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getAccessorialConfigListRequest exception');
  }
}

function* handleGetCarrierAssessorialsSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierAssessorialList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierAssessorialsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetCarrierAssessorialsSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetCarrierAssessorialsSaga exception');
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
  }
}

function* handleCreateCarrierAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.carrierAssessorial, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:201');

      yield put(A.createCarrierAssessorialSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierAssessorialSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierAssessorialSaga exception');
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
  }
}

function* handleUpdateCarrierAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'put',
      endpointsMap.carrierAssessorial,
      { data: payload },
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCarrierAssessorials());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:200');

      yield put(A.updateCarrierAssessorialSuccess({ data: res.data, index}));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierAssessorialSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierAssessorialSaga exception');
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
  }
}

function* handleDeleteCarrierAssessorialSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierAssessorialEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const accessorials = yield select(makeSelectCarrierAssessorials());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), accessorials);
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(A.deleteCarrierAssessorialSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierAssessorialSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierAssessorialSaga exception');
    yield call(G.showToastrMessage, 'error', 'toastr:error:unknown');
  }
}

function* handleCreateCarrierSaferWatchMassUpdateByReportRequestSaga({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.CURRENT_BRANCH, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierSaferWatchMassUpdateByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierSaferWatchMassUpdateByReportRequestSaga fail');
    }
  } catch (error) {
    yield call(error, 'handleCreateCarrierSaferWatchMassUpdateByReportRequestSaga exception');
  }
}

function* handleCreateCarrierSaferWatchMassUpdateRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: payload,
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierSaferWatchMassUpdate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.invalidCarriers)) {
        if (R.gt(data.requestedToUpdate, data.updated)) {
          const updatedCarriersMessage = R.join(
            ' ',
            [
              G.getWindowLocale('titles:updated', 'Updated'),
              data.updated,
              G.getWindowLocale('titles:of', 'of'),
              data.requestedToUpdate,
              G.getWindowLocale('titles:carriers', 'Carriers'),
            ],
          );

          yield call(G.showToastrMessage, 'success', updatedCarriersMessage);
        }

        R.forEach(
          ({ carrierName, errorMessage }: Object) =>
            G.showToastrMessageSimple('error', `${carrierName}: ${errorMessage}`),
          data.invalidCarriers,
        );
      } else {
        yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      }
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierSaferWatchMassUpdateRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierSaferWatchMassUpdateRequestSaga exception');
  }
}

function* createSendOnboardingPackageRequestSaga({ payload }: Object) {
  try {
    const { editMode, carrierGuid } = payload;

    if (editMode) yield put(openLoader());

    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendOnboardingPackage, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      if (editMode) {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'createSendOnboardingPackageRequestSaga fail');
    }

    if (editMode) yield put(closeLoader());
  } catch (error) {
    if (payload.editMode) yield put(closeLoader());

    yield call(G.handleException, error, 'createSendOnboardingPackageRequestSaga exception');
  }
}

function* handleCreateCarrierSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqData = {
      ...payload,
      [GC.FIELD_BRANCH_GUID]: branchGuid,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrier, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { guid } = data;

      yield put(closeModal());

      yield call(G.goToCarrierProfileByConfig, guid);

      if (R.prop(GC.FIELD_CARRIER_SEND_PACKAGE, payload)) {
        yield put(A.createSendOnboardingPackageRequest({ editMode: false, carrierGuid: guid }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierSaga exception');
  }
}

function* handleCreateCarrierTerminalSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'post', endpointsMap.carrierTerminal, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isTrue(payload.primary)) {
        const terminals = yield select(makeSelectCarrierTerminals());

        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_TERMINAL_PRIMARY),
          terminals,
        );

        if (G.notEquals(index, -1)) {
          const noPrimary = R.assoc(
            GC.FIELD_CARRIER_TERMINAL_PRIMARY,
            false,
            terminals[index],
          );

          yield put(A.updateTerminalSuccess({ data: noPrimary, index }));
        }
      }
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createCarrierTerminalSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierTerminalSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierTerminalSaga exception');
  }
}

function* handleCreateCarrierRefSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierReference, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
      yield put(A.createCarrierRefSuccess(R.indexBy(R.prop('guid'), data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierRefSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierRefSaga exception');
  }
}

function* handleCreateCarrierAssocCompanySaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierAssocCompany;

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createCarrierAssocCompanySuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierAssocCompanySaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierAssocCompanySaga exception');
  }
}

function* handleCreateCarrierInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierInsurance;

    const { values, resetForm } = payload;

    const options = { data: values };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createCarrierInsuranceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isFunction(resetForm)) {
        resetForm();
      } else {
        yield put(closeModal());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierInsuranceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierInsuranceSaga exception');
  }
}

function* handleCreateCarrierDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const data = new window.FormData();

    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierDoc,
      { data },
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createCarrierDocSuccess(res.data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierDocSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierDocSaga exception');
  }
}

function* handleCreateTerminalContactSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierTerminalContact;

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      let contacts = yield select(makeSelectTerminalContacts());

      if (G.isTrue(payload.primary)) {
        contacts = R.map((contact: Object) => (
          G.ifElse(
            G.isTrue(contact.primary),
            R.assoc('primary', false, contact),
            contact,
          )
        ), contacts);
      }
      const newContacts = R.concat(contacts, R.of(Array, data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createTerminalContactSuccess(newContacts));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTerminalContactSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateTerminalContactSaga exception');
  }
}

function* handleCreateTerminalPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierTerminalPayLocation, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isTrue(payload.selected)) {
        const payLocations = yield select(makeSelectTerminalPayLocations());

        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED),
          payLocations,
        );

        if (G.notEquals(index, -1)) {
          const noSelected = R.assoc(
            GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED,
            false,
            payLocations[index],
          );

          yield put(A.updateTerminalPayLocationSuccess({ data: noSelected, index }));
        }
      }
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createTerminalPayLocationSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateTerminalPayLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateTerminalPayLocationSaga exception');
  }
}

function* handleCreateCarrierPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierPayLocation, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isTrue(payload.selected)) {
        const payLocations = yield select(makeSelectCarrierPayLocations());

        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED),
          payLocations,
        );

        if (G.notEquals(index, -1)) {
          const noSelected = R.assoc(
            GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED,
            false,
            payLocations[index],
          );

          yield put(A.updateCarrierPayLocationSuccess({ data: noSelected, index }));
        }
      }
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createCarrierPayLocationSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierPayLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierPayLocationSaga exception');
  }
}

function* handleCreateCarrierCertificateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierCertificate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.createCarrierCertificateSuccess(data));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateCarrierCertificateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateCarrierCertificateSaga exception');
  }
}

function* handleSyncWithSaferWatchRequest() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const formValues = yield select(makeSelectCarrierFormValues());

    const carrierGuid = R.prop(GC.FIELD_GUID, formValues);

    const endpoint = G.ifElse(
      G.showCarrierSynchronization(),
      endpointsMap.syncCarrier,
      endpointsMap.carrierSaferWatch,
    );

    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.error)) {
        yield put(closeLoader());

        return yield call(G.showToastrMessage, 'error', data.error);
      }

      yield put(A.updateCarrierSuccess(data));
      yield put(A.getCarrierInsuranceRequest(carrierGuid));
      yield put(A.getCarrierSafetyInfoRequest(carrierGuid));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      yield put(initialize('EDIT_GENERAL_DETAILS_FORM', R.mergeRight(formValues, data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleSyncWithSaferWatchRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleSyncWithSaferWatchRequest exeption');
  }
}

function* handleUpdateCarrierGeneralDetailsRequest() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const formValues = yield select(makeSelectCarrierFormValues());

    const options = { data: formValues };

    const res = yield call(sendRequest, 'put', endpointsMap.carrier, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(handleSyncWithSaferWatchRequest);
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierGeneralDetails fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierGeneralDetails exeption');
  }
}

function* handleUpdateCarrierSaga({ payload }: Object) {
  try {
    const { name, usDotNumber } = payload;

    const { addedToWatchList } = yield select(makeSelectLoadBoardSynchronizationParams());

    yield put(openLoader({ showDimmer: true }));

    const requestData = G.mapObjectEmptyStringFieldsToNull(R.dissoc('closeExpandedContainer', payload));

    if (R.and(G.isNilOrEmpty(usDotNumber), addedToWatchList)) {
      const message = `${G.getWindowLocale('titles:carrier', 'Carrier')} ${name} ${
        G.getWindowLocale('messages:removed-from-watch-list')
      }`;

      yield call(G.showToastrMessage, 'info', message);
    }

    const options = { data: requestData };

    const res = yield call(sendRequest, 'put', endpointsMap.carrier, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierSuccess(data));
      yield put(A.updateCarrierFinanceRequest(payload));

      if (payload.sendPackage) {
        yield put(A.createSendOnboardingPackageRequest({ editMode: true, carrierGuid: G.getGuidFromObject(data) }));
      }

      if (R.propEq(false, GC.FIELD_ACTIVE, data)) {
        yield put(A.getLoadBoardIntegrationListRequest());
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierSaga exception');
  }
}

function* handleUpdateCarrierRefSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierReference, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierRefSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierRefSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierRefSaga exception');
  }
}

function* handleUpdateCarrierAssocCompanySaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierAssocCompany;

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const companies = yield select(makeSelectCarrierAssocCompanies());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), companies);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierAssocCompanySuccess({ data: res.data, index}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierAssocCompanySaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierAssocCompanySaga exception');
  }
}

function* handleUpdateCarrierInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierInsurance;

    const { values } = payload;

    const options = { data: values };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.updateCarrierInsuranceSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierInsuranceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierInsuranceSaga exception');
  }
}

function* handleUpdateCarrierDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const data = new window.FormData();

    data.append('file', payload.file);
    data.append('data', JSON.stringify(payload));

    const res = yield call(sendRequest, 'post', endpointsMap.carrierDocUpdate, { data });

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const docs = yield select(makeSelectCarrierDocs());
      const index = R.findIndex(R.propEq(payload.guid, 'guid'), docs);
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierDocSuccess({ index, data: res.data}));
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierDocSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierDocSaga exception');
  }
}

function* handleUpdateTerminalContactSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const endpoint = endpointsMap.carrierTerminalContact;

    const options = {
      data: payload,
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      let contacts = yield select(makeSelectTerminalContacts());

      contacts = R.map((contact: Object) => (
        G.ifElse(
          R.equals(contact.guid, data.guid),
          res.data,
          R.assoc('primary', false, contact),
        )
      ), contacts);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateTerminalContactSuccess(contacts));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTerminalContactSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateTerminalContactSaga exception');
  }
}

function* handleUpdateTerminalSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierTerminal, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const terminals = yield select(makeSelectCarrierTerminals());

      if (G.isTrue(payload.primary)) {
        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_TERMINAL_PRIMARY),
          terminals,
        );

        if (G.notEquals(index, -1)) {
          const noPrimary = R.assoc(
            GC.FIELD_CARRIER_TERMINAL_PRIMARY,
            false,
            terminals[index],
          );

          yield put(A.updateTerminalSuccess({ data: noPrimary, index }));
        }
      }

      const terminalIndex = R.findIndex(
        R.propEq(payload.guid, GC.FIELD_GUID),
        terminals,
      );

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateTerminalFinanceRequest(payload));
      yield put(A.updateTerminalSuccess({ data, index: terminalIndex}));

      yield put(A.setActiveTab({
        tabName: 'general',
        isCancel: true,
      }));

      yield put(A.setActivePage('general'));
      yield put(change(EDIT_GENERAL_DETAILS_FORM, 'terminal', {}));
      yield put(change(EDIT_GENERAL_DETAILS_FORM, 'terminalFinance', {}));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTerminalSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateTerminalSaga exception');
  }
}

function* handleUpdateTerminalFinanceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload.terminalFinance };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierTerminalFinance, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTerminalFinanceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateTerminalFinanceSaga exception');
  }
}

function* handleUpdateTerminalPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierTerminalPayLocation, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const payLocations = yield select(makeSelectTerminalPayLocations());

      if (G.isTrue(payload.selected)) {
        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED),
          payLocations,
        );

        if (G.notEquals(index, -1)) {
          const noSelected = R.assoc(
            GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED,
            false,
            payLocations[index],
          );

          yield put(A.updateTerminalPayLocationSuccess({ data: noSelected, index }));
        }
      }

      const payLocationIndex = R.findIndex(
        R.propEq(payload.guid, GC.FIELD_GUID),
        payLocations,
      );

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateTerminalPayLocationSuccess({
        data,
        index: payLocationIndex,
      }));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateTerminalPayLocationSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateTerminalPayLocationSaga exception');
  }
}

function* handleUpdateCarrierFinanceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload.carrierFinance };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierFinance, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierFinanceSuccess(data));

      if (R.not(payload.stayOnPage)) {
        const expandedContainerOpened = yield select(makeSelectExpandedContainerOpened());

        if (G.isTrue(expandedContainerOpened)) {
          G.callFunction(payload.closeExpandedContainer);
        } else {
          G.historyGoBack();
        }
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierFinanceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierFinanceSaga exception');
  }
}

function* handleUpdateCarrierPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierPayLocation, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const payLocations = yield select(makeSelectCarrierPayLocations());
      if (G.isTrue(payload.selected)) {
        const index = R.findIndex(
          R.propEq(true, GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED),
          payLocations,
        );

        if (G.notEquals(index, -1)) {
          const noSelected = R.assoc(
            GC.FIELD_CARRIER_FINANCE_LOCATION_SELECTED,
            false,
            payLocations[index],
          );

          yield put(A.updateCarrierPayLocationSuccess({ data: noSelected, index }));
        }
      }

      const payLocationIndex = R.findIndex(
        R.propEq(payload.guid, GC.FIELD_GUID),
        payLocations,
      );

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierPayLocationSuccess({
        data,
        index: payLocationIndex,
      }));

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierPayLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierPayLocationSaga exception');
  }
}

function* handleUpdateCarrierCertificateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierCertificate, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const certificates = yield select(makeSelectCarrierCertificates());

      const index = R.findIndex(R.propEq(G.getGuidFromObject(payload), GC.FIELD_GUID), certificates);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.updateCarrierCertificateSuccess({ data, index }));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleUpdateCarrierCertificateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleUpdateCarrierCertificateSaga exception');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.getCarrierEndpoint(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(A.deleteItemSuccess(payload));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* handleDeleteCarrierRefSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierRefEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierRefSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierRefSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierRefSaga exception');
  }
}

function* handleDeleteCarrierAssocCompanySaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierAssocCompanyEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const companies = yield select(makeSelectCarrierAssocCompanies());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), companies);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierAssocCompanySuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierAssocCompanySaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierAssocCompanySaga exception');
  }
}

function* handleDeleteCarrierInsuranceSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierInsuranceEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      const insurances = yield select(makeSelectCarrierInsurances());

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
      const deletedIndex = R.findIndex(R.propEq(payload, GC.FIELD_GUID), insurances);
      yield put(A.deleteCarrierInsuranceSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierInsuranceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierInsuranceSaga exception');
  }
}

function* handleDeleteCarrierDocSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierDocEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const docs = yield select(makeSelectCarrierDocs());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), docs);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierDocSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierDocSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierDocSaga exception');
  }
}

function* handleDeleteCarrierTerminalSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierTerminalEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const terminals = yield select(makeSelectCarrierTerminals());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), terminals);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierTerminalSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierTerminalSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierTerminalSaga exception');
  }
}

function* handleDeleteTerminalContactSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getTerminalContactEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const contacts = yield select(makeSelectTerminalContacts());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), contacts);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteTerminalContactSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTerminalContactSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteTerminalContactSaga exception');
  }
}

function* handleDeleteTerminalPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getTerminalPayLocationEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const payLocations = yield select(makeSelectTerminalPayLocations());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), payLocations);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteTerminalPayLocationSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteTerminalPayLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteTerminalPayLocationSaga exception');
  }
}

function* handleDeleteCarrierCertificateSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierCertificateEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const certificates = yield select(makeSelectCarrierCertificates());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), certificates);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierCertificateSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierCertificateSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierCertificateSaga exception');
  }
}

function* handleDeleteCarrierPayLocationSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(
      sendRequest,
      'delete',
      endpointsMap.getCarrierPayLocationEndpoint(payload),
    );

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      const payLocations = yield select(makeSelectCarrierPayLocations());
      const deletedIndex = R.findIndex(R.propEq(payload, 'guid'), payLocations);
      yield call(G.showToastrMessage, 'success', 'messages:success:204');

      yield put(A.deleteCarrierPayLocationSuccess(deletedIndex));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierPayLocationSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteCarrierPayLocationSaga exception');
  }
}

function* handleDownloadCarrierSaga({ payload }: Object) {
  try {
    const options = {
      params: {
        primaryObjectGuid: payload.primaryObjectGuid,
        fileName: payload.documentFilename,
      },
      resType: 'arraybuffer',
    };

    const res = yield call(
      sendRequestWithQSParamsSerializer,
      'get',
      endpointsMap.carrierDownloadDoc,
      options,
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      FileDownload(data, payload.documentFilename);
    } else {
      yield call(G.handleFailResponse, res, 'handleDownloadCarrierSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'handleDownloadCarrierSaga exception');
  }
}

function* getReferenceTypesByScopeForOptionsSaga() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    yield put(getAllAvailableRefTypesByScopeRequest({ [GC.FIELD_BRANCH_GUID]: branchGuid, scopeName: 'carrier'}));

    const res = yield take(getAllAvailableRefTypesByScopeRequestSuccess);

    const { payload } = res;

    yield put(A.getReferenceTypesByScopeForOptionsSuccess(payload));
  } catch (error) {
    yield call(G.handleException, error, 'getReferenceTypesByScopeForOptionsSaga exception');
  }
}

function* getSequencesByScopeForOptionsSaga() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    yield put(getSequencesByTypeRequest({ branchGuid, type: GC.SEQUENCE_TYPE_CARRIER}));

    const res = yield take(getSequencesByTypeSuccess);

    const { payload } = res;

    yield put(A.getSequencesByTypeForOptionsSuccess(payload));
  } catch (error) {
    yield call(G.handleException, error, 'getSequencesByScopeForOptionsSaga exception');
  }
}

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const reportParams = yield select(makeSelectUsedReport());
    const availableReports = yield select(makeSelectAvailableReports());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('carrierDefault', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    const guids = R.pathOr(null, ['guids'], payload);

    let fields = G.getOrElse(reportParams, 'fields', []);

    let requiredFields = [
      { name: GC.FIELD_NAME, sequence: 100, reference: false },
      { name: GC.FIELD_BRANCH_GUID, sequence: 101, reference: false },
    ];

    if (G.isTrue(reportParams.showIssues)) {
      const issueFields = [
        { name: GC.FIELD_ISSUES_COUNT, freezed: false, sequence: 201, reference: false },
        { name: GC.FIELD_MAX_ISSUE_WARNING_LEVEL, freezed: false, sequence: 202, reference: false },
        { name: GC.FIELD_ISSUES, freezed: false, sequence: 200, reference: false, collection: true },
      ];

      requiredFields = R.concat(requiredFields, issueFields);
    }

    requiredFields.forEach((field: Object) => {
      if (R.not(R.any(R.propEq(field.name, 'name'), fields))) {
        fields = R.concat(
          fields,
          R.of(Array, field),
        );
      }
    });

    const { limit, offset } = pagination;

    const reqBody = {
      guids,
      limit,
      offset,
      fields,
      orderFields,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(
      sendRequest,
      'post',
      endpointsMap.carrierList,
      { data: reqData },
    );

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield call(G.handleException, err, 'handleGetItemListSaga exception');

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(
  GC.CARRIER_REPORT,
  A,
  handleGetItemListSaga,
  { S, createUpdateReportSuccessCallback },
);

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const filterParams = yield select(makeSelectFilterParams());
    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const { fields, fileType, orderFields, searchCriteria } = payload;

    const reqBody = {
      fields,
      orderFields,
      searchCriteria,
      currentEnterprise,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const params = { format: fileType };

    const sendData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const options = {
      params,
      data: sendData,
      resType: 'arraybuffer',
    };

    const res = yield call(sendRequest, 'post', endpointsMap.carrierExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
  }
}

function* handleGetCarrierPortalLoginUsersRequest() {
  try {
    const carrierGuid = yield select(makeSelectCarrierGuid());

    const options = { params: { carrierGuid } };

    const res = yield call(sendRequest, 'get', endpointsMap.userListByCarrier, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierPortalLoginUsersSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'handleGetCarrierPortalLoginUsersRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'handleGetCarrierPortalLoginUsersRequest exception');
  }
}

function* handleCreateWatchRequestSaga({ payload }: Object) {
  try {
    const { watch, hideMessage, usDotNumber } = payload;

    const endpointName = G.ifElse(
      watch,
      'addToWatchList',
      'removeFromWatchList',
    );

    if (R.and(watch, G.isNilOrEmpty(usDotNumber))) {
      return yield call(G.showToastrMessage, 'error', 'validation:usdot-number');
    }

    yield put(openLoader());

    const endpoint = R.prop(endpointName, endpointsMap);

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        usDotNumber,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(change(EDIT_GENERAL_DETAILS_FORM, GC.FIELD_CARRIER_ADDED_TO_WATCH_LIST, watch));

      if (R.not(hideMessage)) {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateWatchRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'handleCreateWatchRequestSaga exeption');
  }
}

function* handleAddCarriersToWatchListRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: payload,
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.addAllToWatchList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data.invalidCarriesNames)) {
        const carrierNames = R.join(', ', data.invalidCarriesNames);
        const message = `${G.getWindowLocale('titles:invalid-carriers', 'Invalid Carriers')}: ${carrierNames}`;

        yield call(G.showToastrMessage, 'error', message);
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAddCarriersToWatchListRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'handleAddCarriersToWatchListRequestSaga exeption');
  }
}

function* handleAddCarriersToWatchListByReportRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = { data: R.assoc(GC.CURRENT_BRANCH, branchGuid, payload) };

    const res = yield call(sendRequest, 'post', endpointsMap.addToWatchListByReport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data.invalidCarriesNames)) {
        const carrierNames = R.join(', ', data.invalidCarriesNames);
        const message = `${G.getWindowLocale('titles:invalid-carriers', 'Invalid Carriers')}: ${carrierNames}`;

        yield call(G.showToastrMessage, 'error', message);

        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest({ openLoader: true }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'handleAddCarriersToWatchListByReportRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'handleAddCarriersToWatchListByReportRequestSaga exeption');
  }
}

function* handleDeleteCarrierPortalLoginUserRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.getRemoveUserEndpoint(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.deleteCarrierPortalLoginUserSuccess(payload));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteCarrierPortalLoginUserRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteCarrierPortalLoginUserRequest exception');
  }
}

function* createCarrierRatePriceExportRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const options = {
      resType: 'arraybuffer',
      params: { contractGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.exportRatePrice, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      G.saveFileFromResponse(res);

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createCarrierRatePriceExportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createCarrierRatePriceExportRequest exception');
  }
}

function* changeBranchRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierChangeBranch, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
    } else {
      yield call(G.handleFailResponse, res, 'changeBranchRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'changeBranchRequest exception');
  }
}

function* addOrRemoveSharedAccessorialsRequest({ payload }: Object) {
  try {
    const { data, endpoint, successSharedAccessorialsAction } = payload;

    yield put(openLoader());

    const options = { data };

    const res = yield call(sendRequest, 'post', R.prop(endpoint, endpointsMap), options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      successSharedAccessorialsAction(R.prop(GC.FIELD_PRIMARY_OBJECT_GUID, data));
    } else {
      yield call(G.handleFailResponse, res, 'addOrRemoveSharedAccessorialsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'addOrRemoveSharedAccessorialsRequest');
  }
}

// carrier load board integration
function* getLoadBoardIntegrationListRequest({ payload }: Object) {
  try {
    const carrierGuid = yield select(makeSelectCarrierGuid());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
        [GC.FIELD_CARRIER_GUID]: R.or(payload, carrierGuid),
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierLoadBoardIntegrationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getLoadBoardIntegrationListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getLoadBoardIntegrationListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getLoadBoardIntegrationListRequest exception');
  }
}

function* getParentBranchGuidsByCurrentBranchGuidRequest() {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const endpoint = endpointsMap.getParentBranchGuidsByBranchGuid(currentBranchGuid);

    const res = yield call(sendRequest, 'get', endpoint);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const branchGuids = R.without([ROOT_BRANCH_GUID], R.prepend(currentBranchGuid, R.or(data, [])));

      yield put(A.getParentBranchGuidsByCurrentBranchGuidSuccess(branchGuids));
    } else {
      yield call(G.handleFailResponse, res, 'getParentBranchGuidsByCurrentBranchGuidRequest fail');
    }
  } catch (error) {
    yield call(G.handleException, 'getParentBranchGuidsByCurrentBranchGuidRequest exception');
  }
}

function* getAvailableForCarrierLoadBoardsRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.availableForCarrierLoadBoards, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAvailableForCarrierLoadBoardsSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getAvailableForCarrierLoadBoardsRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getAvailableForCarrierLoadBoardsRequest exception');
  }
}

// carrier load board synchronization
function* addToNetworkCarriersRequest({ payload }: Object) {
  try {
    const { source, carrierGuids } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: carrierGuids,
      params: { source, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.addToNetwork, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      if (G.isNotNilAndNotEmpty(data)) {
        const failedCarriersString = R.compose(
          R.join(', '),
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', failedCarriersString);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'addToNetworkCarriersRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addToNetworkCarriersRequest exeption');
  }
}

function* addToNetworkCarriersByReportRequest({ payload }: Object) {
  try {
    const { source, fields, orderFields, searchCriteria } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { source },
      data: { fields, orderFields, searchCriteria, [GC.CURRENT_BRANCH]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.addToNetworkByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
    } else {
      yield call(G.handleFailResponse, res, 'addToNetworkCarriersByReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addToNetworkCarriersByReportRequest exeption');
  }
}

function* allowBookItNowCarriersRequest({ payload }: Object) {
  try {
    const { source, carrierGuids } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: carrierGuids,
      params: { source, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.allowBookItNow, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      if (G.isNotNilAndNotEmpty(data)) {
        const failedCarriersString = R.compose(
          R.join(', '),
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', failedCarriersString);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'allowBookItNowCarriersRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'allowBookItNowCarriersRequest exeption');
  }
}

function* allowBookItNowCarriersByReportRequest({ payload }: Object) {
  try {
    const { source, fields, orderFields, searchCriteria } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { source },
      data: { fields, orderFields, searchCriteria, [GC.CURRENT_BRANCH]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.allowBookItNowByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
    } else {
      yield call(G.handleFailResponse, res, 'allowBookItNowCarriersByReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'allowBookItNowCarriersByReportRequest exeption');
  }
}

function* forbidBookItNowCarriersRequest({ payload }: Object) {
  try {
    const { source, carrierGuids } = payload;

    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: carrierGuids,
      params: { source, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.forbidBookItNow, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      if (G.isNotNilAndNotEmpty(data)) {
        const failedCarriersString = R.compose(
          R.join(', '),
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', failedCarriersString);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'forbidBookItNowCarriersRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'forbidBookItNowCarriersRequest exeption');
  }
}

function* removeFromNetworkCarriersRequest({ payload }: Object) {
  try {
    const { source, carrierGuids } = payload;

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(openLoader());

    const options = {
      data: carrierGuids,
      params: { source, [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.removeFromNetwork, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      if (G.isNotNilAndNotEmpty(data)) {
        const failedCarriersString = R.compose(
          R.join(', '),
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', failedCarriersString);
      } else {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'removeFromNetworkCarriersRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'removeFromNetworkCarriersRequest exeption');
  }
}

function* addOrRemoveToNetworkCarrierRequest({ payload }: Object) {
  try {
    const { source, carrierGuid, addedToNetwork } = payload;

    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.of(Array, carrierGuid),
      params: { source, [GC.BRANCH_GUID]: currentBranchGuid },
    };

    const endpoint = G.ifElse(
      addedToNetwork,
      endpointsMap.addToNetwork,
      endpointsMap.removeFromNetwork,
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.head,
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', message);
      } else {
        yield put(A.getLoadBoardIntegrationListRequest());

        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'addOrRemoveToNetworkCarrierRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addOrRemoveToNetworkCarrierRequest exeption');
  }
}

function* allowOrForbidBookItNowCarrierRequest({ payload }: Object) {
  try {
    const { source, carrierGuid, allowedBookItNow } = payload;

    yield put(openLoader());

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.of(Array, carrierGuid),
      params: { source, [GC.BRANCH_GUID]: currentBranchGuid },
    };

    const endpoint = G.ifElse(
      allowedBookItNow,
      endpointsMap.allowBookItNow,
      endpointsMap.forbidBookItNow,
    );

    const res = yield call(sendRequest, 'post', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const message = R.compose(
          R.head,
          R.map(({ name, errorMessage }: Object) => `${name} - ${errorMessage}`),
        )(data);

        yield call(G.showToastrMessage, 'error', message);
      } else {
        yield put(A.getLoadBoardIntegrationListRequest());

        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'allowOrForbidBookItNowCarrierRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'allowOrForbidBookItNowCarrierRequest exeption');
  }
}

function* getDispatchConfigListRequest() {
  try {
    const branchGuid = yield select(makeSelectCarrierBranchGuid());

    const options = {
      params: {
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierIntegrationDispatchConfigList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data)) {
        const mappedDispatchConfigs = R.map((item: Object) => {
          const { guid, name, integrationType, supportDispatch, supportStatusCheck } = item;

          const integrationTypeDisplayedValue = R.compose(
            R.pathOr('', [GC.FIELD_LABEL]),
            R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
          )(getCarrierRateIntegrationOptions());

          const label = G.ifElse(
            G.isNilOrEmpty(name),
            integrationTypeDisplayedValue,
            `${name} (${integrationTypeDisplayedValue})`,
          );

          return { label, supportDispatch, supportStatusCheck, [GC.FIELD_VALUE]: guid };
        }, data);

        yield put(A.getDispatchConfigListSuccess(mappedDispatchConfigs));
      }
    } else {
      yield call(G.handleException, 'error', 'getDispatchConfigListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, 'error', 'getDispatchConfigListRequest exception');
  }
}

// carrier synchronization
function* syncCarrierByReportRequest({ payload }: Object) {
  try {
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: R.assoc(GC.CURRENT_BRANCH, currentBranchGuid, payload),
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncCarrierByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'syncCarrierByReportRequest fail');
    }
  } catch (error) {
    yield call(error, 'syncCarrierByReportRequest exception');
  }
}

function* syncCarrierListRequest({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: payload,
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.syncSelectedCarriers, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      if (G.isNotNilAndNotEmpty(data.invalidCarriers)) {
        if (R.gt(data.requestedToUpdate, data.updated)) {
          const updatedCarriersMessage = R.join(
            ' ',
            [
              G.getWindowLocale('titles:updated', 'Updated'),
              data.updated,
              G.getWindowLocale('titles:of', 'of'),
              data.requestedToUpdate,
              G.getWindowLocale('titles:carriers', 'Carriers'),
            ],
          );

          yield call(G.showToastrMessage, 'success', updatedCarriersMessage);
        }

        R.forEach(
          ({ carrierName, errorMessage }: Object) =>
            G.showToastrMessageSimple('error', `${carrierName}: ${errorMessage}`),
          data.invalidCarriers,
        );
      } else {
        yield call(G.showToastrMessage, 'success', 'toastr:success:200');
      }
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
    } else {
      yield call(G.handleFailResponse, res, 'syncCarrierListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'syncCarrierListRequest exception');
  }
}

function* addSyncCarriersToWatchListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: payload,
      params: { [GC.BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierSynchronizationAddAllToWatchList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data.invalidCarriesNames)) {
        const carrierNames = R.join(', ', data.invalidCarriesNames);
        const message = `${G.getWindowLocale('titles:invalid-carriers', 'Invalid Carriers')}: ${carrierNames}`;

        yield call(G.showToastrMessage, 'error', message);
      }
    } else {
      yield call(G.handleFailResponse, res, 'addSyncCarriersToWatchListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addSyncCarriersToWatchListRequest exeption');
  }
}

function* addSyncCarriersToWatchListByReportRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = { data: R.assoc(GC.CURRENT_BRANCH, branchGuid, payload) };

    const res = yield call(sendRequest, 'put', endpointsMap.carrierSynchronizationAddToWatchListByReport, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');

      if (G.isNotNilAndNotEmpty(data.invalidCarriesNames)) {
        const carrierNames = R.join(', ', data.invalidCarriesNames);
        const message = `${G.getWindowLocale('titles:invalid-carriers', 'Invalid Carriers')}: ${carrierNames}`;

        yield call(G.showToastrMessage, 'error', message);

        yield put(A.resetListAndPagination());
        yield put(A.getItemListRequest({ openLoader: true }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'addSyncCarriersToWatchListByReportRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addSyncCarriersToWatchListByReportRequest exeption');
  }
}

function* addOrRemoveCarrierWatchRequest({ payload }: Object) {
  try {
    const { guid, watch, hideMessage } = payload;

    const endpointName = G.ifElse(
      watch,
      'carrierSynchronizationAddToWatchList',
      'carrierSynchronizationRemoveFromWatchList',
    );

    yield put(openLoader());

    const endpoint = R.prop(endpointName, endpointsMap);

    const options = {
      params: {
        [GC.FIELD_CARRIER_GUID]: guid,
      },
    };

    const res = yield call(sendRequest, 'put', endpoint, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(change(EDIT_GENERAL_DETAILS_FORM, GC.FIELD_CARRIER_ADDED_TO_WATCH_LIST, watch));

      if (R.not(hideMessage)) {
        yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
      }
    } else {
      yield call(G.handleFailResponse, res, 'addOrRemoveCarrierWatchRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'addOrRemoveCarrierWatchRequest exeption');
  }
}

// additional contacts
function* getAdditionalContactListRequest() {
  try {
    yield put(openLoader());

    const carrierGuid = yield select(makeSelectCarrierGuid());

    const options = {
      params: { carrierGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.additionalCarrierContactList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getAdditionalContactListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getAdditionalContactListRequest exception');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getAdditionalContactListRequest exception');
  }
}

function* createOrUpdateAdditionalContactRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const res = yield call(sendRequest, method, endpointsMap.additionalCarrierContact, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateAdditionalContactSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateAdditionalContactRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'createOrUpdateAdditionalContactRequest exception');
  }
}

function* removeAdditionalContactRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.removeAdditionalCarrierContact(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeAdditionalContactSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeAdditionalContactRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'removeAdditionalContactRequest exception');
  }
}

// carrier integration
function* sendCarrierListToIntegrationRequest({ payload }: Object) {
  try {
    const { carrierGuids, integrationType } = payload;

    yield put(openLoader());
    const options = {
      data: carrierGuids,
      params: { integrationType },
    };

    const res = yield call(sendRequest, 'post', endpointsMap.sendCarrierListToIntegration, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const { totalCount, successCount, sentToDivisionNames } = data;

      yield put(A.sendCarrierListToIntegrationSuccess(sentToDivisionNames));
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest({ openLoader: true }));
      yield put(closeModal());

      const errors = R.compose(
        R.values,
        R.mapObjIndexed((messageArray: array, key: string) => ({ key, messageArray })),
        R.pathOr({}, ['errors']),
      )(data);

      const title = `${G.getWindowLocale('titles:accounting', 'Accounting')} ${
        G.getWindowLocale('titles:export-result', 'Export Result')}`;

      yield put(showRequestStatusModal({
        title,
        errors,
        totalCount,
        successCount,
        status: G.getPropFromObject(GC.FIELD_STATUS, data),
      }));
    } else {
      yield call(G.handleFailResponse, res, 'sendCarrierListToIntegrationRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'sendCarrierListToIntegrationRequest exception');
  }
}

function* getCarrierIntegrationListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierIntegrationInfoList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierIntegrationListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierIntegrationListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getCarrierIntegrationListRequest exception');
  }
}

function* getCarrierIntegrationAuditListRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.FIELD_CARRIER_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierIntegrationAuditList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierIntegrationAuditListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierIntegrationAuditListRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getCarrierIntegrationAuditListRequest exception');
  }
}

function* getAvailableCarrierIntegrationsRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = {
      params: { [GC.BRANCH_GUID]: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.availableCarrierIntegrationList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const carrierIntegrationOptions = R.compose(
        G.addEmptyOptionToDropDown,
        R.map(({ guid, name, integrationType }: Object) => {
          const integrationTypeDisplayedValue = R.compose(
            R.pathOr('', [GC.FIELD_LABEL]),
            R.find(R.propEq(integrationType, GC.FIELD_VALUE)),
          )(getCarrierRateIntegrationOptions());

          const label = G.ifElse(
            G.isNilOrEmpty(name),
            integrationTypeDisplayedValue,
            `${name} (${integrationTypeDisplayedValue})`,
          );

          return { label, [GC.FIELD_VALUE]: guid };
        }),
      )(R.or(data, []));

      yield put(A.getAvailableCarrierIntegrationsSuccess(carrierIntegrationOptions));
    } else {
      yield call(G.handleFailResponse, res, 'getAvailableCarrierIntegrationsRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'getAvailableCarrierIntegrationsRequest exception');
  }
}

// contract bill to
function* getContractBillToByContractTypeRequest({ payload }: Object) {
  try {
    const { carrierContractGuid, customerContractGuid } = payload;

    let endpoint = endpointsMap.carrierContractBillTo;

    let options = {
      params: { carrierContractGuid },
    };

    if (G.isNotNilAndNotEmpty(customerContractGuid)) {
      options = {
        params: { customerContractGuid },
      };

      endpoint = endpointsMap.customerContractBillTo;
    }

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getContractBillToByContractTypeSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getContractBillToByContractTypeRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getContractBillToByContractTypeRequest exception');
  }
}

function* createOrUpdateContractBillToByContractTypeRequest({ payload }: Object) {
  try {
    const { type, values } = payload;

    yield put(openLoader());

    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, values)),
      'post',
      'put',
    );

    const endpoint = G.ifElse(
      G.isContractTypeCarrier(type),
      endpointsMap.carrierContractBillTo,
      endpointsMap.customerContractBillTo,
    );

    const res = yield call(sendRequest, method, endpoint, { data: values });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getContractBillToByContractTypeSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateContractBillToByContractTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'createOrUpdateContractBillToByContractTypeRequest exception');
  }
}

function* removeContractBillToByContractTypeRequest({ payload }: Object) {
  try {
    const { type, guid } = payload;

    yield put(openLoader());

    const endpoint = G.ifElse(
      G.isContractTypeCarrier(type),
      endpointsMap.removeCarrierContractBillTo,
      endpointsMap.removeCustomerContractBillTo,
    )(guid);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getContractBillToByContractTypeSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'removeContractBillToByContractTypeRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeContractBillToByContractTypeRequest exception');
  }
}

// carrier payment term
function* getCarrierPaymentTermListRequest({ payload }: Object) {
  try {
    const options = {
      params: {
        [GC.FIELD_CARRIER_GUID]: payload,
      },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierDefaultFactoringPaymentList, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierPaymentTermListSuccess(data));
    } else {
      yield call(G.handleException, 'error', 'getCarrierPaymentTermListRequest exception');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierPaymentTermListRequest exception');
  }
}

function* createOrUpdateCarrierPaymentTermRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );

    const res = yield call(sendRequest, method, endpointsMap.carrierDefaultFactoringPaymentTerm, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateCarrierPaymentTermSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateCarrierPaymentTermRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, 'error', 'createOrUpdateCarrierPaymentTermRequest exception');
  }
}

function* removeCarrierPaymentTermRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const endpoint = endpointsMap.removeCarrierDefaultFactoringPaymentTerm(payload);

    const res = yield call(sendRequest, 'delete', endpoint);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.removeCarrierPaymentTermSuccess());
    } else {
      yield call(G.handleFailResponse, res, 'removeCarrierPaymentTermRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeCarrierPaymentTermRequest exception');
  }
}

// carrier internal dispatch settings
function* getCarrierInternalDispatchSettings({ payload }: Object) {
  try {
    const options = {
      params: { carrierGuid: payload },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.carrierInternalDispatchSettings, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierInternalDispatchSettingsSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierInternalDispatchSettings fail');
    }
  } catch (error) {
    yield call(G.handleException, 'getCarrierInternalDispatchSettings exception');
  }
}

// visit
function* handlerVisitEditCarrierPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    const { pathname } = payload;

    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());
    const accessToPagesList = yield select(makeSelectAccessToPagesList());

    const isAllowed = R.includes(pathname, accessToPagesList);

    if (R.not(isInitialDataLoaded)) {
      yield race({
        success: take(initialDataLoadSuccess),
        fail: take(initialDataLoadFail),
      });
    }

    // TODO: with success/fail cond
    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_CARRIER_EDIT_PAGE, payload: pathname });

      yield take(GC.CHECK_VISIT_CARRIER_EDIT_PAGE_ALLOWED);
    }

    yield put(A.setInitialStateOmitReport(['itemList', 'pagination', 'totalCount']));
    yield put(A.getAvailableForCarrierLoadBoardsRequest());
    yield put(A.getParentBranchGuidsByCurrentBranchGuidRequest());

    yield call(handleGetCarrierSaga, payload);
    yield call(getSequencesByScopeForOptionsSaga);
    yield call(getReferenceTypesByScopeForOptionsSaga);

    break;
  }
}

function* handlerVisitListOfCarriersPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    const route = payload.pathname;

    const pageVisited = yield select(makeSelectPageVisited());
    const accessToPagesList = yield select(makeSelectAccessToPagesList());
    const isInitialDataLoaded = yield select(makeSelectInitialDataLoadedStatus());

    const isAllowed = R.includes(route, accessToPagesList);

    if (R.not(isInitialDataLoaded)) {
      yield race({
        success: take(initialDataLoadSuccess),
        fail: take(initialDataLoadFail),
      });
    }

    if (R.not(isAllowed)) {
      yield put({ type: GC.CHECK_VISIT_CARRIER_LIST_PAGE, payload: route });

      yield take(GC.CHECK_VISIT_CARRIER_LIST_PAGE_ALLOWED);
    }

    yield put(A.resetListAndPagination());
    yield put(socketCarrierDocumentGeneratedRequest(G.getAmousCurrentUserFromWindow()));

    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);

    yield put(A.setIgnorePromptStatus(false));
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CARRIER));
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });
    yield call(handleGetCarrierOnboardingPackageRequest);

    yield put(closeLoader());

    break;
  }
}

export function* CarrierWatcherSaga() {
  yield fork(CarrierContractSaga);
  yield fork(CustomerContractSaga);
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.changeBranchRequest, changeBranchRequest);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getCarrierRefRequest, handleGetCarrierRefSaga);
  yield takeLatest(A.createCarrierRequest, handleCreateCarrierSaga);
  yield takeLatest(A.updateCarrierRequest, handleUpdateCarrierSaga);
  yield takeLatest(A.updateTerminalRequest, handleUpdateTerminalSaga);
  yield takeLatest(A.getCarrierDocsRequest, handleGetCarrierDocsSaga);
  yield takeLatest(A.createWatchRequest, handleCreateWatchRequestSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.createCarrierDocRequest, handleCreateCarrierDocSaga);
  yield takeLatest(A.createCarrierRefRequest, handleCreateCarrierRefSaga);
  yield takeLatest(A.deleteCarrierRefRequest, handleDeleteCarrierRefSaga);
  yield takeLatest(A.deleteCarrierDocRequest, handleDeleteCarrierDocSaga);
  yield takeLatest(A.updateCarrierDocRequest, handleUpdateCarrierDocSaga);
  yield takeLatest(A.updateCarrierRefRequest, handleUpdateCarrierRefSaga);
  yield takeLatest(A.downloadCarrierDocRequest, handleDownloadCarrierSaga);
  yield takeLatest(A.getCarrierConfigsRequest, handleGetCarrierConfigsSaga);
  yield takeLatest(A.getCarrierFinanceRequest, handleGetCarrierFinanceSaga);
  yield takeLatest(A.getGeneralConfigsRequest, handleGetGeneralConfigsSaga);
  yield takeLatest(A.getTemplateConfigsRequest, handleGetTemplateConfigsSaga);
  yield takeLatest(A.getTerminalFinanceRequest, handleGetTerminalFinanceSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.getCarrierTerminalsRequest, handleGetCarrierTerminalsSaga);
  yield takeLatest(A.getCarrierInsuranceRequest, handleGetCarrierInsuranceSaga);
  yield takeLatest(A.getTerminalContactsRequest, handleGetTerminalContactsSaga);
  yield takeLatest(GC.VISIT_CARRIER_EDIT_PAGE, handlerVisitEditCarrierPageSaga);
  yield takeLatest(A.getDispatchConfigListRequest, getDispatchConfigListRequest);
  yield takeLatest(A.updateCarrierFinanceRequest, handleUpdateCarrierFinanceSaga);
  yield takeLatest(GC.VISIT_CARRIER_LIST_PAGE, handlerVisitListOfCarriersPageSaga);
  yield takeLatest(A.deleteCarrierTerminalRequest, handleDeleteCarrierTerminalSaga);
  yield takeLatest(A.deleteTerminalContactRequest, handleDeleteTerminalContactSaga);
  yield takeLatest(A.createTerminalContactRequest, handleCreateTerminalContactSaga);
  yield takeLatest(A.createCarrierTerminalRequest, handleCreateCarrierTerminalSaga);
  yield takeLatest(A.updateTerminalContactRequest, handleUpdateTerminalContactSaga);
  yield takeLatest(A.updateTerminalFinanceRequest, handleUpdateTerminalFinanceSaga);
  yield takeLatest(A.getCarrierSafetyInfoRequest, handleGetCarrierSafetyInfoRequest);
  yield takeLatest(A.getCarrierMailListRequest, handleGetCarrierMailListRequestSaga);
  yield takeLatest(A.createCarrierInsuranceRequest, handleCreateCarrierInsuranceSaga);
  yield takeLatest(A.getCarrierAssessorialsRequest, handleGetCarrierAssessorialsSaga);
  yield takeLatest(A.getCarrierCertificatesRequest, handleGetCarrierCertificatesSaga);
  yield takeLatest(A.getCarrierAssocCompanyRequest, handleGetCarrierAssocCompanySaga);
  yield takeLatest(A.getCarrierPayLocationsRequest, handleGetCarrierPayLocationsSaga);
  yield takeLatest(A.deleteCarrierInsuranceRequest, handleDeleteCarrierInsuranceSaga);
  yield takeLatest(A.updateCarrierInsuranceRequest, handleUpdateCarrierInsuranceSaga);
  yield takeLatest(A.getAccessorialConfigListRequest, getAccessorialConfigListRequest);
  yield takeLatest(A.downloadCarrierMailRequest, handleDownloadCarrierMailRequestSaga);
  yield takeLatest(A.getTerminalPayLocationsRequest, handleGetTerminalPayLocationsSaga);
  yield takeLatest(A.updateCarrierCertificateRequest, handleUpdateCarrierCertificateSaga);
  yield takeLatest(A.updateCarrierAssessorialRequest, handleUpdateCarrierAssessorialSaga);
  yield takeLatest(A.createCarrierAssessorialRequest, handleCreateCarrierAssessorialSaga);
  yield takeLatest(A.updateCarrierPayLocationRequest, handleUpdateCarrierPayLocationSaga);
  yield takeLatest(A.deleteCarrierAssessorialRequest, handleDeleteCarrierAssessorialSaga);
  yield takeLatest(A.createCarrierCertificateRequest, handleCreateCarrierCertificateSaga);
  yield takeLatest(A.createCarrierPayLocationRequest, handleCreateCarrierPayLocationSaga);
  yield takeLatest(A.deleteCarrierPayLocationRequest, handleDeleteCarrierPayLocationSaga);
  yield takeLatest(A.deleteCarrierCertificateRequest, handleDeleteCarrierCertificateSaga);
  yield takeLatest(A.createCarrierAssocCompanyRequest, handleCreateCarrierAssocCompanySaga);
  yield takeLatest(A.createTerminalPayLocationRequest, handleCreateTerminalPayLocationSaga);
  yield takeLatest(A.updateTerminalPayLocationRequest, handleUpdateTerminalPayLocationSaga);
  yield takeLatest(A.deleteCarrierAssocCompanyRequest, handleDeleteCarrierAssocCompanySaga);
  yield takeLatest(A.updateCarrierAssocCompanyRequest, handleUpdateCarrierAssocCompanySaga);
  yield takeLatest(A.deleteTerminalPayLocationRequest, handleDeleteTerminalPayLocationSaga);
  yield takeLatest(A.addCarriersToWatchListRequest, handleAddCarriersToWatchListRequestSaga);
  yield takeLatest(A.createCarrierRatePriceExportRequest, createCarrierRatePriceExportRequest);
  yield takeLatest(A.addOrRemoveSharedAccessorialsRequest, addOrRemoveSharedAccessorialsRequest);
  yield takeLatest(A.createSendOnboardingPackageRequest, createSendOnboardingPackageRequestSaga);
  yield takeLatest(A.getCarrierPortalLoginUsersRequest, handleGetCarrierPortalLoginUsersRequest);
  yield takeLatest(A.updateCarrierGeneralDetailsRequest, handleUpdateCarrierGeneralDetailsRequest);
  yield takeLatest(A.deleteCarrierPortalLoginUserRequest, handleDeleteCarrierPortalLoginUserRequest);
  yield takeLatest(A.addCarriersToWatchListByReportRequest, handleAddCarriersToWatchListByReportRequestSaga);
  yield takeLatest(A.createCarrierSaferWatchMassUpdateRequest, handleCreateCarrierSaferWatchMassUpdateRequestSaga);
  yield takeLatest(
    A.createCarrierSaferWatchMassUpdateByReportRequest,
    handleCreateCarrierSaferWatchMassUpdateByReportRequestSaga,
  );
  // carrier load board integration
  yield takeLatest(A.getLoadBoardIntegrationListRequest, getLoadBoardIntegrationListRequest);
  yield takeLatest(A.getAvailableForCarrierLoadBoardsRequest, getAvailableForCarrierLoadBoardsRequest);
  yield takeLatest(A.getParentBranchGuidsByCurrentBranchGuidRequest, getParentBranchGuidsByCurrentBranchGuidRequest);
  // carrier load board synchronization
  yield takeLatest(A.addToNetworkCarriersRequest, addToNetworkCarriersRequest);
  yield takeLatest(A.allowBookItNowCarriersRequest, allowBookItNowCarriersRequest);
  yield takeLatest(A.forbidBookItNowCarriersRequest, forbidBookItNowCarriersRequest);
  yield takeLatest(A.removeFromNetworkCarriersRequest, removeFromNetworkCarriersRequest);
  yield takeLatest(A.addOrRemoveToNetworkCarrierRequest, addOrRemoveToNetworkCarrierRequest);
  yield takeLatest(A.addToNetworkCarriersByReportRequest, addToNetworkCarriersByReportRequest);
  yield takeLatest(A.allowOrForbidBookItNowCarrierRequest, allowOrForbidBookItNowCarrierRequest);
  yield takeLatest(A.allowBookItNowCarriersByReportRequest, allowBookItNowCarriersByReportRequest);
  // carrier synchronization
  yield takeLatest(A.syncCarrierListRequest, syncCarrierListRequest);
  yield takeLatest(A.syncCarrierByReportRequest, syncCarrierByReportRequest);
  yield takeLatest(A.addOrRemoveCarrierWatchRequest, addOrRemoveCarrierWatchRequest);
  yield takeLatest(A.addSyncCarriersToWatchListRequest, addSyncCarriersToWatchListRequest);
  yield takeLatest(A.addSyncCarriersToWatchListByReportRequest, addSyncCarriersToWatchListByReportRequest);
  // additional contacts
  yield takeLatest(A.removeAdditionalContactRequest, removeAdditionalContactRequest);
  yield takeLatest(A.getAdditionalContactListRequest, getAdditionalContactListRequest);
  yield takeLatest(A.createOrUpdateAdditionalContactRequest, createOrUpdateAdditionalContactRequest);
  // carrier integration
  yield takeLatest(A.getCarrierIntegrationListRequest, getCarrierIntegrationListRequest);
  yield takeLatest(A.sendCarrierListToIntegrationRequest, sendCarrierListToIntegrationRequest);
  yield takeLatest(A.getCarrierIntegrationAuditListRequest, getCarrierIntegrationAuditListRequest);
  // carrier available integrations
  yield takeLatest(A.getContractBillToByContractTypeRequest, getContractBillToByContractTypeRequest);
  yield takeLatest(A.getAvailableCarrierIntegrationsRequest, getAvailableCarrierIntegrationsRequest);
  yield takeLatest(A.removeContractBillToByContractTypeRequest, removeContractBillToByContractTypeRequest);
  yield takeLatest(
    A.createOrUpdateContractBillToByContractTypeRequest,
    createOrUpdateContractBillToByContractTypeRequest,
  );
  // carrier payment term
  yield takeLatest(A.removeCarrierPaymentTermRequest, removeCarrierPaymentTermRequest);
  yield takeLatest(A.getCarrierPaymentTermListRequest, getCarrierPaymentTermListRequest);
  yield takeLatest(A.createOrUpdateCarrierPaymentTermRequest, createOrUpdateCarrierPaymentTermRequest);
  // carrier internal dispatch settings
  yield takeLatest(A.getCarrierInternalDispatchSettings, getCarrierInternalDispatchSettings);
}

export default CarrierWatcherSaga;

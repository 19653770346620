import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature fleet-map
import * as Actions from './actions';
//////////////////////////////////////////////////

const initialState = {
  fleets: {
    trucks: [],
    drivers: [],
    trailers: [],
    fleetsLoaded: {
      trucks: false,
      drivers: false,
      trailers: false,
    },
  },
};

const setFleetsToStore = (state: Object, { fleetType, data } : Object) => (
  P.$all(
    P.$set(`fleets.${fleetType}`, data),
    P.$set(`fleets.fleetsLoaded.${fleetType}`, true),
    state,
  )
);

const cleanFleetMapStore = () => (
  initialState
);

export default createReducer({
  [Actions.setFleetsToStore]: setFleetsToStore,
  [Actions.cleanFleetMapStore]: cleanFleetMapStore,
}, initialState);

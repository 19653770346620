import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import TextComponent from '../../../components/text';
// features
import { setExpandedContainerOptions } from '../../expanded-container/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { IconWrapper } from '../../../ui';
// utilities
import routesMap from '../../../utilities/routes';
//////////////////////////////////////////////////

// NOTE: name here actually is prop value

const convertors = {
  [GC.FIELD_WEIGHT]: (name: number) => G.fromKgsToPounds(name, 2),
  [GC.FIELD_DISTANCE]: (name: number) => G.fromKmsToMiles(name, 2),
  [GC.FIELD_ITEM_VOLUME]: (name: number) => G.fromCubicMeterToFeet(name, 2),
  [GC.FIELD_TEMPERATURE]: (name: number) => G.fromCelsiusToFahrenheit(name, 2),
};

const getValue = ({ name, type, uomSystem }: Object) => {
  if (R.equals(uomSystem, GC.METRIC_SYSTEM)) return G.mathRoundNumber(name);

  return convertors[type](name);
};

const metricUOMs = {
  [GC.FIELD_WEIGHT]: GC.UOM_KILOGRAM,
  [GC.FIELD_TEMPERATURE]: GC.UOM_CELSIUS,
  [GC.FIELD_DISTANCE]: GC.UOM_KILOMETER_LABEL,
  [GC.FIELD_ITEM_VOLUME]: GC.UOM_CUBIC_METERS,
};

const imperialUOMs = {
  [GC.FIELD_WEIGHT]: GC.UOM_POUND,
  [GC.FIELD_DISTANCE]: GC.UOM_MILE_LABEL,
  [GC.FIELD_ITEM_VOLUME]: GC.UOM_CUBIC_FEET,
  [GC.FIELD_TEMPERATURE]: GC.UOM_FAHRENHEIT,
};

export const BooleanTableField = ({ value }: Object) => G.ifElse(
  G.isTrue(value),
  I.uiTrue,
  I.uiFalse,
)();

export const TableField = ({ data, type }: Object) => {
  const { name } = data;

  const uomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();

  if (G.isNilOrEmpty(name)) return null;

  const value = getValue({ name, type, uomSystem });
  const uom = G.ifElse(R.equals(uomSystem, GC.METRIC_SYSTEM), metricUOMs[type], imperialUOMs[type]);

  return <span>{value} {uom}</span>;
};

export const UomLocalesTableField = ({ data, field }: Object) => {
  const text = R.compose(
    R.join(', '),
    R.map((item: string) => G.getUomLocale(item)),
    R.pathOr([], [field]),
  )(data);

  return (
    <TextComponent title={text} display='block' maxWidth='100%' withEllipsis={true}>
      {text}
    </TextComponent>
  );
};

const expandedDetailsEnhance = compose(
  connect(null, { setExpandedContainerOptions }),
  withHandlers({
    handleOpenDetails: (props: Object) => () => {
      const { guid, loadType, setExpandedContainerOptions} = props;

      const loadTypeClo = G.isLoadTypeClo(loadType);
      const componentType = G.ifElse(
        loadTypeClo,
        GC.PAGE_DISPATCH_DETAILS_NEW_ORDER,
        GC.PAGE_DISPATCH_DETAILS_NEW_LOAD,
      );
      const openLoadInSidebar = G.getAmousConfigByNameFromWindow(GC.UI_OPEN_LOAD_IN_SIDEBAR);

      const route = G.ifElse(loadTypeClo, routesMap.dispatchDetailsOrder, routesMap.dispatchDetailsLoad)({ guid });

      if (G.isFalse(openLoadInSidebar)) return G.goToRoute(route);

      return setExpandedContainerOptions({ componentType, opened: true, visitPageGuid: guid });
    },
  }),
  pure,
);

export const ExpandedDetails = expandedDetailsEnhance(({ handleOpenDetails }: Object) => (
  <IconWrapper
    cursor='pointer'
    onClick={handleOpenDetails}
    title={G.getWindowLocale('actions:go-to-details', 'Go to Details')}
  >
    {I.routesLoads(G.getTheme('colors.dark.blue'))}
  </IconWrapper>
));
